import { ApolloError } from '@apollo/client';

import {
  useReportingMyLearningJourneysLearnersQueryRemote,
  useReportingMyLearningJourneysLearnersTotalCountQueryRemote,
  RLearners_Order_By,
  RReportingMyLearningJourneysLearnersQuery,
} from '@/store/v2';
import { JourneyLearner } from '@/features/journey/admin/types/journeyLearner';
import { FilterStatus } from '@/management/pages/common/filter/types';
import { ArrayElement } from '@/utils/datastructure/use-multi-key-dict';
import { SortOrder } from '@/store/utils/table/types';

type UseJourneyLernersHookResult = {
  journeyLearners: JourneyLearner[];
  loading: boolean;
  error?: ApolloError;
  total: number;
  refetch: () => void;
};

type UseJourneyLernersHookOptions = {
  journeyURN: string;
  /** Ascending/descending sort order */
  sortOrder: SortOrder;
  /** Items to skip (used for pagination) */
  skip: number;
  /** The data property used for sorting */
  sortBy: SortyBy;
  /** Items to retrieve (used for pagination) */
  limit: number;
  /** Phase for searching by assignment title */
  searchPhrase?: string;
  /** Completed | Not Completed */
  status?: FilterStatus;
  /** Team IDs to filter by */
  teamIds?: number[];
};

export type SortyBy = 'name' | 'status' | 'startedAt' | 'progress' | 'lastActivityAt';

export type UseJourneyLernersHook = (options: UseJourneyLernersHookOptions) => UseJourneyLernersHookResult;

const sortByRemoteMap: Record<SortyBy, keyof RLearners_Order_By> = {
  name: 'username',
  status: 'is_completed',
  startedAt: 'join_time',
  progress: 'progress',
  lastActivityAt: 'last_activity_time',
};

export const useJourneyLearners: UseJourneyLernersHook = ({
  journeyURN,
  sortOrder,
  skip,
  limit,
  searchPhrase,
  sortBy,
  status,
  teamIds,
}) => {
  const {
    data: learningJourneyLearnersResult,
    loading: learnersLoading,
    error: learningJourneyLearnersError,
    refetch,
  } = useReportingMyLearningJourneysLearnersQueryRemote({
    variables: {
      args: {
        learning_journey_urn: journeyURN,
        is_complete: status === 'completed' ? true : status === 'not completed' ? false : undefined,
        username: searchPhrase || undefined,
        team_ids: teamIds || undefined,
      },
      limit,
      offset: skip,
      order_by: [{ [sortByRemoteMap[sortBy] || 'username']: sortOrder }],
    },
  });

  const {
    data: totalCountResult,
    loading: totalCountLoading,
    error: learningJourneyLearnersTotalCountError,
  } = useReportingMyLearningJourneysLearnersTotalCountQueryRemote({
    variables: {
      args: {
        learning_journey_urn: journeyURN,
        is_complete: status === 'completed' ? true : status === 'not completed' ? false : undefined,
        username: searchPhrase || undefined,
      },
    },
  });

  const journeyLearners = (learningJourneyLearnersResult?.reportingMyLearningJourneysLearners || []).map(mapToJourneyLearner);

  return {
    journeyLearners,
    loading: learnersLoading || totalCountLoading,
    total: totalCountResult?.reportingMyLearningJourneysLearnersTotalCount?.[0]?.total_count || 0,
    error: learningJourneyLearnersError || learningJourneyLearnersTotalCountError,
    refetch: () => refetch(),
  };
};

export const mapToJourneyLearner = (
  learner: ArrayElement<RReportingMyLearningJourneysLearnersQuery['reportingMyLearningJourneysLearners']>
): JourneyLearner => ({
  id: learner.user_id,
  email: learner.email,
  name: learner.username,
  profileImage: learner.profile_image,
  progress: learner.progress || 0,
  status: learner.is_completed ? 'completed' : 'not completed',
  startedAt: learner?.join_time ? new Date(learner?.join_time).toISOString() : undefined,
  lastActivityAt: learner.last_activity_time ? new Date(learner.last_activity_time).toISOString() : undefined,
  completedAt: learner.completion_time ? new Date(learner.completion_time).toISOString() : undefined,
});
