import { useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';

import { QuizViewContext } from '@/component/customEditorV2/ui/elements/QuizQuestionElement/View/QuizViewContext';
import { Button } from '@/ui/Button';
import { useSubmitLearningActivityQuizAnswersMutationRemote } from '@/store/v2';

const RootStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const WarningStyled = styled.div`
  ${({ theme }) => theme.typography.caption};
  color: ${({ theme }) => theme.colors.error.main};
  margin-bottom: 8px;
`;

export const SubmitSection = ({ onSubmit }: { onSubmit: () => void }): JSX.Element => {
  const { answeredQuestions, learningActivityId } = useContext(QuizViewContext);
  const [submitWarningShown, setSubmitWarningShown] = useState(false);
  const [wasSubmitTriggered, setWasSubmitTriggered] = useState(false);

  const isSumbissionValid = useMemo(() => {
    const allQuestionsCount = answeredQuestions.length;
    const allAnsweredQuestionsCount = answeredQuestions.filter((question) => question.answersIds.size).length;

    return allQuestionsCount === allAnsweredQuestionsCount;
  }, [answeredQuestions]);

  useEffect(() => {
    if (wasSubmitTriggered) {
      setSubmitWarningShown(!isSumbissionValid);
    }
  }, [isSumbissionValid, wasSubmitTriggered]);

  const [submitLearningActivityQuizAnswers, { loading }] = useSubmitLearningActivityQuizAnswersMutationRemote();

  const handleSubmit = async () => {
    if (!isSumbissionValid) {
      setSubmitWarningShown(true);
      setWasSubmitTriggered(true);
      return;
    }

    await submitLearningActivityQuizAnswers({
      variables: {
        input: {
          learningActivityId: learningActivityId,
          version: uuid(),
          answers: answeredQuestions.map((question) => ({
            questionId: question.questionId,
            selectedOptionIds: Array.from(question.answersIds),
          })),
        },
      },
    });
    onSubmit();
  };
  return (
    <RootStyled>
      {submitWarningShown && <WarningStyled>All questions must be answered.</WarningStyled>}
      <Button variant="primary" onClick={handleSubmit} $loading={loading} size="large">
        Submit
      </Button>
    </RootStyled>
  );
};
