import { useEffect } from 'react';

import { useManagementContext } from '../../../context';

export interface UseEffectParams {
  title: string;
  loading: boolean;
  subtitle?: string | JSX.Element;
  lastActive?: string;
  joined?: string;
  useSubHeading?: boolean;
  useSubTitle?: boolean;
}

export function usePageLayoutHeadingUpdated({
  loading,
  title,
  subtitle,
  lastActive,
  joined,
  useSubHeading,
  useSubTitle,
}: UseEffectParams): void {
  const { setTitle, setJoined, setSubtitle, setLastActive, setHasSubHeading, setLoading, setHasSubtitle } =
    useManagementContext();

  useEffect(() => {
    setHasSubHeading && setHasSubHeading(!!useSubHeading);
    setHasSubtitle && setHasSubtitle(!!useSubTitle);
    setLoading && setLoading(loading);

    if (!loading) {
      setTitle && setTitle(title);

      if (useSubTitle) {
        setSubtitle && subtitle && setSubtitle(subtitle);
      } else {
        setSubtitle && setSubtitle('');
      }

      if (useSubHeading) {
        setJoined && joined && setJoined(joined);
        setLastActive && lastActive && setLastActive(lastActive);
      } else {
        setJoined && setJoined('');
        setLastActive && setLastActive('');
      }
    }
  }, [loading, title, subtitle, lastActive, joined, useSubHeading, useSubTitle]);
}
