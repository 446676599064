import styled from 'styled-components';
import React from 'react';

import { CIconProps, IconPropType } from '@/types/icon';
import { Icon } from '../Icon';

export const InfoToolTip = styled.div<{ hint?: boolean; 'aria-describedby': string }>`
  display: inline-block;
  font-size: var(--font-size-8);
  position: relative;

  :hover span {
    visibility: visible;
  }

  ${({ hint }) => hint && 'border-bottom: 1px dashed black;'}
`;

export const InfoToolTipContent = styled.span.attrs((props) => ({
  ...props,
  role: 'infotooltip',
}))<{ id: string }>`
  background-color: var(--color-white);
  border-radius: var(--radius-outer);
  border-top: 35px solid var(--color-primary);
  color: var(--color-dark-gray);
  font-size: var(--font-size-9);
  left: 50%;
  top: 100%;
  margin-left: 10px;
  margin-top: -30px;
  padding: 15px;
  position: absolute;
  text-align: left;
  visibility: hidden;
  z-index: 1;
  min-width: 180px;
`;

export const InfoToolTipLabel = styled.span.attrs((props) => ({
  ...props,
  role: 'infotooltiplabel',
}))<{ id: string }>`
  color: var(--color-black);
  font-size: var(--font-size-9);
  font-weight: bold;
`;

export const InfoToolTipDescription = styled.p.attrs((props) => ({
  ...props,
  role: 'infotooltipdescription',
}))<{ id: string }>`
  color: var(--color-dark-gray);
  font-size: var(--font-size-9);
`;

interface InfoToolTipProps extends Omit<CIconProps, 'icon'> {
  icon?: IconPropType;
  color?: string;
}

export const InfoToolTipIcon = ({ icon, color, ...rest }: InfoToolTipProps): JSX.Element => {
  const showCustomIcon = !!icon;

  let iconToUse: IconPropType = 'info-circle';
  const iconColor: string = !!color ? color : `var(--color-primary)`;

  if (showCustomIcon) {
    iconToUse = icon!;
  }
  return <Icon icon={iconToUse} style={{ color: iconColor }} {...rest} />;
};
