import React from 'react';
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Select, TextFieldSelect } from '@/ui/Select';
import { Space } from '@/types/learning/learning-catalog';

interface ControlledTextFieldProps<T extends FieldValues> {
  control: Control<T>;
  defaultValue: T[keyof T];
}

type FormSpaceProps<T extends FieldValues> = Omit<ControlledTextFieldProps<T>, 'name' | 'defaultValue'> & {
  spacesToUse: Space[];
  fieldName?: string;
  disabled?: boolean;
};

export const FormSpace = <T extends FieldValues>({
  control,
  spacesToUse,
  disabled,
  fieldName = 'spaceId',
}: FormSpaceProps<T>): JSX.Element => {
  const { t } = useTranslation('catalog');

  return (
    <Controller
      control={control}
      rules={{ required: true }}
      name={fieldName as FieldPath<T>}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextFieldSelect
          disabled={disabled}
          label={t('space')}
          value={spacesToUse.find(({ id }) => id === Number(value))?.name || ''}
          onChange={onChange}
          error={
            error && {
              message: t('choose-space'),
            }
          }
          fullWidth
        >
          <Select.List fullWidth>
            {spacesToUse.map(({ id, name }) => (
              <Select.Option value={String(id)} key={id}>
                {name}
              </Select.Option>
            ))}
          </Select.List>
        </TextFieldSelect>
      )}
    />
  );
};
