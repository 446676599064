import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Wrapper, Heading, Subheading } from '../styles';
import image from './image.svg';

const Graphics = styled.img.attrs({ src: image })``;

interface TextProps {
  heading?: string;
  subheading?: string;
}

export const EmptyStateNoData = ({ heading, subheading }: TextProps): JSX.Element => {
  const { t } = useTranslation('managerDashboard');

  return (
    <Wrapper>
      <Graphics />
      <Heading>{heading || t('empty-state-no-data-found')}</Heading>
      {subheading && <Subheading>{subheading}</Subheading>}
    </Wrapper>
  );
};
