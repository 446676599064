import styled from 'styled-components';

import { IconButton } from '@/ui/Button';
import { Select } from '@/ui/Select';
import { OptionTextStyled } from '@/ui/Select/atoms/Option/styles';

export const ActionsSelectButton = styled(IconButton).attrs({ icon: 'ellipsis-vertical' })``;

export const ActionOptionStyled = styled(Select.Option)`
  & ${OptionTextStyled} {
    width: 100%;
  }
`;

export const ActionsListStyled = styled(Select.List)`
  max-width: 164px;
`;
