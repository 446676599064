import { Command as CommandPrimitive } from 'cmdk';
import React from 'react';
import styled from 'styled-components';

import { IndeterminateCircularProgressBar } from '@/ui/ProgressBar';

const CommandLoadingRootStyled = styled(CommandPrimitive.Loading)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
`;

const IndeterminateCircularProgressBarStyled = styled(IndeterminateCircularProgressBar)`
  display: flex;
`;

export const CommandLoading = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Loading>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Loading>
>((props, ref) => (
  <CommandLoadingRootStyled {...props} ref={ref}>
    <IndeterminateCircularProgressBarStyled />
  </CommandLoadingRootStyled>
));

CommandLoading.displayName = CommandPrimitive.Loading.displayName;
