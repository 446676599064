import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  StyledDescriptionContainer,
  StyledDocumentContainer,
  StyledDocumentPDFHeader,
  StyledDocumentPDFObject,
  StyledDocumentPDFTitle,
  StyledHeader,
  StyledSubHeader,
} from '../styles';
import { Button } from '@/ui/Button';
import errorImage from '../../error.svg';
import { FileUploader } from '@/component/FileUpload/types';
import { DocumentVideoUploadButton } from '@/component/FileUpload/DocumentVideoUploadButton';
import { DownloadableResource } from './DownloadableResource';
import { getFileExtensionAndName } from '@/component/FileUpload/helpers';
import { FILE_FORMAT } from '@/component/FileUpload/constants';
import { PowerPointFrameStyled } from './DownloadableResource/styles';

const ErrorImage = styled.img.attrs({ src: errorImage })``;

const FULL_HEIGHT_DOCUMENT_FORMATS = [FILE_FORMAT.PDF, FILE_FORMAT.PPTX];

export const DocumentPreview = ({
  documentUrl,
  documentUpload,
  fileName,
  hideHeader,
}: {
  documentUrl: string;
  documentUpload?: FileUploader<'document'>;
  fileName?: string;
  hideHeader?: boolean;
}): JSX.Element => {
  const { t } = useTranslation('uploaders');

  const { name, extension } = getFileExtensionAndName({
    documentUrl,
    fileName,
  });

  const isFullHeightViewer = FULL_HEIGHT_DOCUMENT_FORMATS.includes(extension);

  const renderPreview = () => {
    switch (extension) {
      case FILE_FORMAT.PDF:
        return (
          <StyledDocumentPDFObject data={documentUrl} type="application/pdf">
            <ErrorImage />
            <StyledDescriptionContainer>
              <StyledHeader>{t('problem-previewing-this-document')}</StyledHeader>
              <StyledSubHeader>{t('try-to-download-or-replace-this-item')}</StyledSubHeader>
            </StyledDescriptionContainer>
            <Button href={documentUrl} target="_blank" variant="primary" size="large">
              {t('download')}
            </Button>
          </StyledDocumentPDFObject>
        );
      case FILE_FORMAT.PPTX:
        return <PowerPointFrameStyled src={`https://view.officeapps.live.com/op/embed.aspx?src=${documentUrl}`} />;
      default:
        return <DownloadableResource downloadUrl={documentUrl} fileExtension={extension} fileName={name} />;
    }
  };

  return (
    <StyledDocumentContainer border="solid" nogap fullHeight={isFullHeightViewer}>
      {!hideHeader && documentUpload && (
        <StyledDocumentPDFHeader>
          <StyledDocumentPDFTitle></StyledDocumentPDFTitle>
          <DocumentVideoUploadButton
            icon={['fas', 'repeat']}
            $loading={!!documentUpload.uploading}
            variant="ghost"
            size="large"
            uploadFile={documentUpload.uploadFile}
            fileType="document"
          >
            {t('replace')}
          </DocumentVideoUploadButton>
        </StyledDocumentPDFHeader>
      )}
      {renderPreview()}
    </StyledDocumentContainer>
  );
};
