import { LearningPartner } from '@/features/catalog/admin/constants/learning';
import * as AllFullColorLogos from '@/assets/catalogLogos/variants/fullColor/index';
import * as AllMonochromeLogos from '@/assets/catalogLogos/variants/monochrome/index';

export enum partnerSVGLogoSize {
  SMALL = '24px',
  LARGE = '44px',
}

export enum SVGLogoVariant {
  MONOCHROME = 'monochrome',
  FULL_COLOR = 'fullColor',
}

type partnerSVGLogo = {
  size: partnerSVGLogoSize;
  partner: LearningPartner;
  SVGFile: string;
  type: SVGLogoVariant;
};

export const partnerSVGLogos: partnerSVGLogo[] = [
  // Small (24px), full color
  {
    size: partnerSVGLogoSize.SMALL,
    partner: LearningPartner.COLLEGIAL,
    SVGFile: AllFullColorLogos.collegial24px,
    type: SVGLogoVariant.FULL_COLOR,
  },
  {
    size: partnerSVGLogoSize.SMALL,
    partner: LearningPartner.COURSERA,
    SVGFile: AllFullColorLogos.coursera24px,
    type: SVGLogoVariant.FULL_COLOR,
  },
  {
    size: partnerSVGLogoSize.SMALL,
    partner: LearningPartner.FINANSKOMPETENS,
    SVGFile: AllFullColorLogos.finanskompetens24px,
    type: SVGLogoVariant.FULL_COLOR,
  },
  {
    size: partnerSVGLogoSize.SMALL,
    partner: LearningPartner.IMPARTA,
    SVGFile: AllFullColorLogos.imparta24px,
    type: SVGLogoVariant.FULL_COLOR,
  },
  {
    size: partnerSVGLogoSize.SMALL,
    partner: LearningPartner.LEARNIFIER,
    SVGFile: AllFullColorLogos.learnifier24px,
    type: SVGLogoVariant.FULL_COLOR,
  },
  {
    size: partnerSVGLogoSize.SMALL,
    partner: LearningPartner.LINKEDIN,
    SVGFile: AllFullColorLogos.linkedinlearning24px,
    type: SVGLogoVariant.FULL_COLOR,
  },
  {
    size: partnerSVGLogoSize.SMALL,
    partner: LearningPartner.PLURALSIGHT,
    SVGFile: AllFullColorLogos.pluralsight24px,
    type: SVGLogoVariant.FULL_COLOR,
  },

  // Large (44px), full color
  {
    size: partnerSVGLogoSize.LARGE,
    partner: LearningPartner.COLLEGIAL,
    SVGFile: AllFullColorLogos.collegial44px,
    type: SVGLogoVariant.FULL_COLOR,
  },
  {
    size: partnerSVGLogoSize.LARGE,
    partner: LearningPartner.COURSERA,
    SVGFile: AllFullColorLogos.coursera44px,
    type: SVGLogoVariant.FULL_COLOR,
  },
  {
    size: partnerSVGLogoSize.LARGE,
    partner: LearningPartner.FINANSKOMPETENS,
    SVGFile: AllFullColorLogos.finanskompetens44px,
    type: SVGLogoVariant.FULL_COLOR,
  },
  {
    size: partnerSVGLogoSize.LARGE,
    partner: LearningPartner.IMPARTA,
    SVGFile: AllFullColorLogos.imparta44px,
    type: SVGLogoVariant.FULL_COLOR,
  },
  {
    size: partnerSVGLogoSize.SMALL,
    partner: LearningPartner.LEARNIFIER,
    SVGFile: AllFullColorLogos.learnifier44px,
    type: SVGLogoVariant.FULL_COLOR,
  },
  {
    size: partnerSVGLogoSize.LARGE,
    partner: LearningPartner.LINKEDIN,
    SVGFile: AllFullColorLogos.linkedinlearning44px,
    type: SVGLogoVariant.FULL_COLOR,
  },
  {
    size: partnerSVGLogoSize.LARGE,
    partner: LearningPartner.PLURALSIGHT,
    SVGFile: AllFullColorLogos.pluralsight44px,
    type: SVGLogoVariant.FULL_COLOR,
  },

  // Small (24px), monochrome
  {
    size: partnerSVGLogoSize.SMALL,
    partner: LearningPartner.COLLEGIAL,
    SVGFile: AllFullColorLogos.collegial24px,
    type: SVGLogoVariant.MONOCHROME,
  },
  {
    size: partnerSVGLogoSize.SMALL,
    partner: LearningPartner.COURSERA,
    SVGFile: AllMonochromeLogos.coursera24px,
    type: SVGLogoVariant.MONOCHROME,
  },
  {
    size: partnerSVGLogoSize.SMALL,
    partner: LearningPartner.FINANSKOMPETENS,
    SVGFile: AllMonochromeLogos.finanskompetens24px,
    type: SVGLogoVariant.MONOCHROME,
  },
  {
    size: partnerSVGLogoSize.SMALL,
    partner: LearningPartner.IMPARTA,
    SVGFile: AllMonochromeLogos.imparta24px,
    type: SVGLogoVariant.FULL_COLOR,
  },
  {
    size: partnerSVGLogoSize.SMALL,
    partner: LearningPartner.LEARNIFIER,
    SVGFile: AllMonochromeLogos.learnifier24px,
    type: SVGLogoVariant.MONOCHROME,
  },
  {
    size: partnerSVGLogoSize.SMALL,
    partner: LearningPartner.LINKEDIN,
    SVGFile: AllMonochromeLogos.linkedinlearning24px,
    type: SVGLogoVariant.MONOCHROME,
  },

  // Large (44px), monochrome
  {
    size: partnerSVGLogoSize.LARGE,
    partner: LearningPartner.COLLEGIAL,
    SVGFile: AllMonochromeLogos.collegial44px,
    type: SVGLogoVariant.MONOCHROME,
  },
  {
    size: partnerSVGLogoSize.LARGE,
    partner: LearningPartner.COURSERA,
    SVGFile: AllMonochromeLogos.coursera44px,
    type: SVGLogoVariant.MONOCHROME,
  },
  {
    size: partnerSVGLogoSize.LARGE,
    partner: LearningPartner.FINANSKOMPETENS,
    SVGFile: AllMonochromeLogos.finanskompetens44px,
    type: SVGLogoVariant.MONOCHROME,
  },
  {
    size: partnerSVGLogoSize.LARGE,
    partner: LearningPartner.IMPARTA,
    SVGFile: AllMonochromeLogos.imparta44px,
    type: SVGLogoVariant.MONOCHROME,
  },
  {
    size: partnerSVGLogoSize.SMALL,
    partner: LearningPartner.LEARNIFIER,
    SVGFile: AllMonochromeLogos.learnifier44px,
    type: SVGLogoVariant.MONOCHROME,
  },
  {
    size: partnerSVGLogoSize.LARGE,
    partner: LearningPartner.LINKEDIN,
    SVGFile: AllMonochromeLogos.linkedinlearning44px,
    type: SVGLogoVariant.MONOCHROME,
  },
];
