import {
  faAngleDown as fasAngleDown,
  faAngleUp as fasAngleUp,
  faAngleLeft as fasAngleLeft,
  faAngleRight as fasAngleRight,
  faArrowDown as fasArrowDown,
  faPoll as fasPoll,
  faArrowLeft as fasArrowLeft,
  faArrowRight as fasArrowRight,
  faGift as fasGift,
  faCheckToSlot as fasCheckToSlot,
  faShare as fasShare,
  faArrowUp as fasArrowUp,
  faArrowTurnDownRight as fasArrowTurnDownRight,
  faAsterisk as fasAsterisk,
  faSquare as fasSquare,
  faBadgeCheck as fasBadgeCheck,
  faWaveformLines as fasWaveformLines,
  faBars as fasBars,
  faFastBackward as fasFastBackward,
  faFastForward as fasFastForward,
  faWindowMaximize as fasWindowMaximize,
  faBarsProgress as fasBarsProgress,
  faBell as fasBell,
  faBold as fasBold,
  faGameBoardSimple as fasGameBoardSimple,
  faBug as fasBug,
  faCalendar as fasCalendar,
  faCaretDown as fasCaretDown,
  faCaretRight as fasCaretRight,
  faCaretUp as fasCaretUp,
  faChalkboardTeacher as fasChalkboardTeacher,
  faChartBar as fasChartBar,
  faChartLine as fasChartLine,
  faCheck as fasCheck,
  faCheckCircle as fasCheckCircle,
  faChevronDown as fasChevronDown,
  faChevronRight as fasChevronRight,
  faChevronUp as fasChevronUp,
  faCircle as fasCircle,
  faClipboard as fasClipboard,
  faClipboardListCheck as fasClipboardListCheck,
  faListCheck as fasListCheck,
  faGripDotsVertical as fasGripDotsVertical,
  faClock as fasClock,
  faCog as fasCog,
  faCommentAlt as fasCommentAlt,
  faComments as fasComments,
  faCopy as fasCopy,
  faDebug as fasDebug,
  faDoorOpen as fasDoorOpen,
  faDotCircle as fasDotCircle,
  faDownload as fasDownload,
  faEdit as fasEdit,
  faEllipsisH as fasEllipsisH,
  faEnvelope as fasEnvelope,
  faExchangeAlt as fasExchangeAlt,
  faExclamationTriangle as fasExclamationTriangle,
  faExpand as fasExpand,
  faExternalLink as fasExternalLink,
  faEye as fasEye,
  faEyeSlash as fasEyeSlash,
  faFile as fasFile,
  faFileAlt as fasFileAlt,
  faFileArchive as fasFileArchive,
  faFileAudio as fasFileAudio,
  faSquareQuestion as fasSquareQuestion,
  faFileExcel as fasFileExcel,
  faFilePdf as fasFilePdf,
  faFileVideo as fasFileVideo,
  faFileUpload as fasFileUpload,
  faFilter as fasFilter,
  faFolder as fasFolder,
  faGear as fasGear,
  faGlobe as fasGlobe,
  faH1 as fasH1,
  faH2 as fasH2,
  faHeart as fasHeart,
  faImage as fasImage,
  faInboxIn as fasInboxIn,
  faInfo as fasInfo,
  faInfoCircle as fasInfoCircle,
  faItalic as fasItalic,
  faKey as fasKey,
  faLanguage as fasLanguage,
  faLaptop as fasLaptop,
  faLayerGroup as fasLayerGroup,
  faLevelDownAlt as fasLevelDownAlt,
  faLink as fasLink,
  faLinkSlash as fasLinkSlash,
  faListOl as fasListOl,
  faListUl as fasListUl,
  faSquareCheck as fasSquareCheck,
  faLock as fasLock,
  faLongArrowAltLeft as fasLongArrowAltLeft,
  faMapMarkerAlt as fasMapMarkerAlt,
  faMicrophone as fasMicrophone,
  faMinus as fasMinus,
  faNewspaper as fasNewspaper,
  faPaperPlane as fasPaperPlane,
  faPage as fasPage,
  faPause as fasPause,
  faPen as fasPen,
  faPlay as fasPlay,
  faPlayCircle as fasPlayCircle,
  faPlus as fasPlus,
  faPlusCircle as fasPlusCircle,
  faPowerOff as fasPowerOff,
  faQuestion as fasQuestion,
  faRedoAlt as fasRedoAlt,
  faReply as fasReply,
  faSave as fasSave,
  faSearch as fasSearch,
  faSignalAlt as fasSignalAlt,
  faSignOut as fasSignOut,
  faSitemap as fasSitemap,
  faSparkles as fasSparkes,
  faSortAlt as fasSortAlt,
  faSplotch as fasSplotch,
  faSpinnerThird as fasSpinnerThird,
  faStrikethrough as fasStrikethrough,
  faSyncAlt as fasSyncAlt,
  faText as fasText,
  faHourglassClock as fasHourglassClock,
  faThLarge as fasThLarge,
  faThumbsUp as fasThumbsUp,
  faThumbtack as fasThumbtack,
  faTimes as fasTimes,
  faTools as fasTools,
  faTrash as fasTrash,
  faTrashRestore as fasTrashRestore,
  faUnderline as fasUnderline,
  faUndoAlt as fasUndoAlt,
  faUniversity as fasUniversity,
  faUpload as fasUpload,
  faUser as fasUser,
  faRepeat as fasRepeat,
  faUserHelmetSafety as fasUserHelmetSafety,
  faUsers as fasUsers,
  faVideo as fasVideo,
  faWrench as fasWrench,
  faHome as fasHome,
  faHourglass as fasHourglass,
  faLightbulb as fasLightbulb,
  faLightbulbExclamation as fasLightbulbExclamation,
  faList as fasList,
  faCompass as fasCompass,
  faExchange as fasExchange,
  faClipboardList as fasClipboardList,
  faExclamationSquare as fasExclamationSquare,
  faEllipsisVertical as fasEllipsisVertical,
  faCircleXmark as fasCircleXmark,
  faCircleCheck as fasCircleCheck,
  faCircleExclamation as fasCircleExclamation,
  faWavePulse as fasWavePulse,
  faTimer as fasTimer,
  faPartyHorn as fasPartyHorn,
  faFlagCheckered as fasFlagCheckered,
  faLoader as fasLoader,
  faExclamation as fasExclamation,
  faWarning as fasWarning,
  faCalendarExclamation as fasCalendarExclamation,
  IconDefinition,
} from '@fortawesome/pro-solid-svg-icons';
import {
  faArrowRight as farArrowRight,
  faArrowRightFromBracket as farArrowRightFromBracket,
  faArrowsRepeat as farArrowsRepeat,
  faArrowsToLine as farArrowsToLine,
  faArrowsFromLine as farArrowsFromLine,
  faArrowDownToLine as farArrowDownToLine,
  faArrowDownTriangleSquare as farArrowDownTriangleSquare,
  faArrowUpShortWide as farArrowUpShortWide,
  faAngleDoubleRight as farAngleDoubleRight,
  faBriefcase as farBriefcase,
  faBold as farBold,
  faBoxArchive as farBoxArchive,
  faFastBackward as farFastBackward,
  faFastForward as farFastForward,
  faPoll as farPoll,
  faWindowMaximize as farWindowMaximize,
  faClipboardList as farClipboardList,
  faWaveformLines as farWaveformLines,
  faSquareQuestion as farSquareQuestion,
  faClipboardUser as farClipboardUser,
  faStarExclamation as farStarExclamation,
  faStar as farStar,
  faStrikethrough as farStrikethrough,
  faShapes as farShapes,
  faCircle as farCircle,
  faGift as farGift,
  faShare as farShare,
  faGauge as farGauge,
  faGripDotsVertical as farGripDotsVertical,
  faDotCircle as farDotCircle,
  faEnvelope as farEnvelope,
  faGameBoardSimple as farGameBoardSimple,
  faSquare as farSquare,
  faEye as farEye,
  faImage as farImage,
  faItalic as farItalic,
  faChevronLeft as farChevronLeft,
  faChevronRight as farChevronRight,
  faCalendar as farCalendar,
  faLocationDot as farLocationDot,
  faCircleXmark as farCircleXmark,
  faLock as farLock,
  faCalendarStar as farCalendarStar,
  faCalendarClock as farCalendarClock,
  faCalendarCheck as farCalendarCheck,
  faCalendarXmark as farCalendarXmark,
  faCalendarRange as farCalendarRange,
  faCoin as farCoin,
  faComments as farComments,
  faCommentLines as farCommentLines,
  faCommentPen as farCommentPen,
  faLayerGroup as farLayerGroup,
  faRectangleAd as farRectangleAd,
  faRectangle as farRectangle,
  faRectangleHistory as farRectangleHistory,
  faRobot as farRobot,
  faRotate as farRotate,
  faRoute as farRoute,
  faFaceSmile as farFaceSmile,
  faUsers as farUsers,
  faFaceThinking as farFaceThinking,
  faRoad as farRoad,
  faParagraph as farParagraph,
  faPaperclipVertical as farPaperclipVertical,
  faPaperclip as farPaperclip,
  faPage as farPage,
  faMemo as farMemo,
  faCheckToSlot as farCheckToSlot,
  faTv as farTv,
  faText as farText,
  faSquareCheck as farSquareCheck,
  faCameraWeb as farCameraWeb,
  faPresentation as farPresentation,
  faPenLine as farPenLine,
  faPen as farPen,
  faComputerMouse as farComputerMouse,
  faFolder as farFolder,
  faFileUpload as farFileUpload,
  faFile as farFile,
  faVideo as farVideo,
  faMicrophone as farMicrophone,
  faEllipsisVertical as farEllipsisVertical,
  faArrowUp as farArrowUp,
  faArrowDown as farArrowDown,
  faArrowsRotate as farArrowsRotate,
  faMinus as farMinus,
  faPencil as farPencil,
  faCircleInfo as farCircleInfo,
  faBolt as farBolt,
  faBooks as farBooks,
  faBookOpenCover as farBookOpenCover,
  faCircleSmall as farCircleSmall,
  faGear as farGear,
  faGlobe as farGlobe,
  faGraduationCap as farGraduationCap,
  faHouse as farHouse,
  faH1 as farH1,
  faH2 as farH2,
  faPlay as farPlay,
  faCircleCheck as farCircleCheck,
  faSignalStrong as farSignalStrong,
  faSquarePollVertical as farSquarePollVertical,
  faClock as farClock,
  faHeart as farHeart,
  faPaperPlaneTop as farPaperPlaneTop,
  faCircleNotch as farCircleNotch,
  faArrowsV as farArrowsV,
  faUserPlus as farUserPlus,
  faUnderline as farUnderline,
  faHourglass as farHourglass,
  faCircleQuestion as farCircleQuestion,
  faLanguage as farLanguage,
  faListOl as farListOl,
  faListUl as farListUl,
  faList as farList,
  faLightbulb as farLightbulb,
  faWandMagicSparkles as farWandMagicSparkles,
  faBullseyeArrow as farBullseyeArrow,
  faTrash as farTrash,
  faTools as farTools,
  faEdit as farEdit,
  faPartyHorn as farPartyHorn,
  faTimer as farTimer,
  faCheck as farCheck,
  faLink as farLink,
  faWarning as farWarning,
  faThumbsUp as farThumbsUp,
  faReply as farReply,
  faSolarSystem as farSolarSystem,
} from '@fortawesome/pro-regular-svg-icons';

export type FontAwesomeIconPath =
  | [prefix: 'fas', iconName: 'arrow-right-arrow-left'] // fasExchange
  | [prefix: 'fas', iconName: 'arrow-down']
  | [prefix: 'fas', iconName: 'angle-down']
  | [prefix: 'fas', iconName: 'angle-left']
  | [prefix: 'fas', iconName: 'angle-right']
  | [prefix: 'fas', iconName: 'angle-up']
  | [prefix: 'fas', iconName: 'arrow-left']
  | [prefix: 'fas', iconName: 'arrow-right']
  | [prefix: 'fas', iconName: 'arrow-up']
  | [prefix: 'fas', iconName: 'arrow-turn-down-right']
  | [prefix: 'fas', iconName: 'asterisk']
  | [prefix: 'fas', iconName: 'badge-check']
  | [prefix: 'fas', iconName: 'bars']
  | [prefix: 'fas', iconName: 'waveform-lines']
  | [prefix: 'fas', iconName: 'fast-backward']
  | [prefix: 'fas', iconName: 'fast-forward']
  | [prefix: 'fas', iconName: 'window-maximize']
  | [prefix: 'fas', iconName: 'square-question']
  | [prefix: 'fas', iconName: 'bars-progress']
  | [prefix: 'fas', iconName: 'bell']
  | [prefix: 'fas', iconName: 'bold']
  | [prefix: 'fas', iconName: 'bug']
  | [prefix: 'fas', iconName: 'game-board-simple']
  | [prefix: 'fas', iconName: 'grip-dots-vertical']
  | [prefix: 'fas', iconName: 'calendar']
  | [prefix: 'fas', iconName: 'caret-up']
  | [prefix: 'fas', iconName: 'caret-down']
  | [prefix: 'fas', iconName: 'caret-right']
  | [prefix: 'fas', iconName: 'chalkboard-teacher']
  | [prefix: 'fas', iconName: 'chart-bar']
  | [prefix: 'fas', iconName: 'gift']
  | [prefix: 'fas', iconName: 'check-to-slot']
  | [prefix: 'fas', iconName: 'share']
  | [prefix: 'fas', iconName: 'chart-line']
  | [prefix: 'fas', iconName: 'check']
  | [prefix: 'fas', iconName: 'hourglass-clock']
  | [prefix: 'fas', iconName: 'circle-check']
  | [prefix: 'fas', iconName: 'chevron-down']
  | [prefix: 'fas', iconName: 'chevron-right']
  | [prefix: 'fas', iconName: 'chevron-up']
  | [prefix: 'fas', iconName: 'circle']
  | [prefix: 'fas', iconName: 'poll']
  | [prefix: 'fas', iconName: 'clipboard']
  | [prefix: 'fas', iconName: 'clipboard-list-check']
  | [prefix: 'fas', iconName: 'list-check']
  | [prefix: 'fas', iconName: 'clock']
  | [prefix: 'fas', iconName: 'cog']
  | [prefix: 'fas', iconName: 'wave-pulse']
  | [prefix: 'fas', iconName: 'timer']
  | [prefix: 'fas', iconName: 'comment-alt']
  | [prefix: 'fas', iconName: 'comments']
  | [prefix: 'fas', iconName: 'copy']
  | [prefix: 'fas', iconName: 'debug']
  | [prefix: 'fas', iconName: 'door-open']
  | [prefix: 'fas', iconName: 'circle-dot']
  | [prefix: 'fas', iconName: 'square-check']
  | [prefix: 'fas', iconName: 'download']
  | [prefix: 'fas', iconName: 'edit']
  | [prefix: 'fas', iconName: 'ellipsis-h']
  | [prefix: 'fas', iconName: 'ellipsis-vertical']
  | [prefix: 'fas', iconName: 'envelope']
  | [prefix: 'fas', iconName: 'exchange-alt']
  | [prefix: 'fas', iconName: 'exclamation-triangle']
  | [prefix: 'fas', iconName: 'circle-exclamation']
  | [prefix: 'fas', iconName: 'expand']
  | [prefix: 'fas', iconName: 'external-link']
  | [prefix: 'fas', iconName: 'eye']
  | [prefix: 'fas', iconName: 'eye-slash']
  | [prefix: 'fas', iconName: 'file']
  | [prefix: 'fas', iconName: 'file-alt']
  | [prefix: 'fas', iconName: 'file-archive']
  | [prefix: 'fas', iconName: 'square']
  | [prefix: 'fas', iconName: 'file-audio']
  | [prefix: 'fas', iconName: 'file-excel']
  | [prefix: 'fas', iconName: 'file-pdf']
  | [prefix: 'fas', iconName: 'file-video']
  | [prefix: 'fas', iconName: 'file-upload']
  | [prefix: 'fas', iconName: 'filter']
  | [prefix: 'fas', iconName: 'flag-checkered']
  | [prefix: 'fas', iconName: 'folder']
  | [prefix: 'fas', iconName: 'gear']
  | [prefix: 'fas', iconName: 'globe']
  | [prefix: 'fas', iconName: 'h1']
  | [prefix: 'fas', iconName: 'h2']
  | [prefix: 'fas', iconName: 'heart']
  | [prefix: 'fas', iconName: 'hourglass']
  | [prefix: 'fas', iconName: 'image']
  | [prefix: 'fas', iconName: 'inbox-in']
  | [prefix: 'fas', iconName: 'info']
  | [prefix: 'fas', iconName: 'info-circle']
  | [prefix: 'fas', iconName: 'circle-info']
  | [prefix: 'fas', iconName: 'italic']
  | [prefix: 'fas', iconName: 'key']
  | [prefix: 'fas', iconName: 'language']
  | [prefix: 'fas', iconName: 'laptop']
  | [prefix: 'fas', iconName: 'layer-group']
  | [prefix: 'fas', iconName: 'level-down-alt']
  | [prefix: 'fas', iconName: 'lightbulb']
  | [prefix: 'fas', iconName: 'lightbulb-exclamation']
  | [prefix: 'fas', iconName: 'link']
  | [prefix: 'fas', iconName: 'link-slash']
  | [prefix: 'fas', iconName: 'list-ol']
  | [prefix: 'fas', iconName: 'list-ul']
  | [prefix: 'fas', iconName: 'lock']
  | [prefix: 'fas', iconName: 'long-arrow-alt-left']
  | [prefix: 'fas', iconName: 'map-marker-alt']
  | [prefix: 'fas', iconName: 'microphone']
  | [prefix: 'fas', iconName: 'minus']
  | [prefix: 'fas', iconName: 'newspaper']
  | [prefix: 'fas', iconName: 'paper-plane']
  | [prefix: 'fas', iconName: 'pause']
  | [prefix: 'fas', iconName: 'pen']
  | [prefix: 'fas', iconName: 'play']
  | [prefix: 'fas', iconName: 'play-circle']
  | [prefix: 'fas', iconName: 'circle-play']
  | [prefix: 'fas', iconName: 'plus']
  | [prefix: 'fas', iconName: 'plus-circle']
  | [prefix: 'fas', iconName: 'circle-plus']
  | [prefix: 'fas', iconName: 'power-off']
  | [prefix: 'fas', iconName: 'page']
  | [prefix: 'fas', iconName: 'question']
  | [prefix: 'fas', iconName: 'redo-alt']
  | [prefix: 'fas', iconName: 'reply']
  | [prefix: 'fas', iconName: 'save']
  | [prefix: 'fas', iconName: 'search']
  | [prefix: 'fas', iconName: 'signal-alt']
  | [prefix: 'fas', iconName: 'sign-out']
  | [prefix: 'fas', iconName: 'sitemap']
  | [prefix: 'fas', iconName: 'sort-alt']
  | [prefix: 'fas', iconName: 'sparkles']
  | [prefix: 'fas', iconName: 'splotch']
  | [prefix: 'fas', iconName: 'spinner-third']
  | [prefix: 'fas', iconName: 'strikethrough']
  | [prefix: 'fas', iconName: 'sync-alt']
  | [prefix: 'fas', iconName: 'text']
  | [prefix: 'fas', iconName: 'th-large']
  | [prefix: 'fas', iconName: 'thumbs-up']
  | [prefix: 'fas', iconName: 'thumbtack']
  | [prefix: 'fas', iconName: 'times']
  | [prefix: 'fas', iconName: 'tools']
  | [prefix: 'fas', iconName: 'trash']
  | [prefix: 'fas', iconName: 'trash-restore']
  | [prefix: 'fas', iconName: 'underline']
  | [prefix: 'fas', iconName: 'undo-alt']
  | [prefix: 'fas', iconName: 'university']
  | [prefix: 'fas', iconName: 'upload']
  | [prefix: 'fas', iconName: 'user']
  | [prefix: 'fas', iconName: 'repeat']
  | [prefix: 'fas', iconName: 'user-helmet-safety']
  | [prefix: 'fas', iconName: 'users']
  | [prefix: 'fas', iconName: 'video']
  | [prefix: 'fas', iconName: 'wrench']
  | [prefix: 'fas', iconName: 'warning']
  | [prefix: 'fas', iconName: 'home']
  | [prefix: 'fas', iconName: 'list']
  | [prefix: 'fas', iconName: 'compass']
  | [prefix: 'fas', iconName: 'clipboard-list']
  | [prefix: 'fas', iconName: 'exclamation-square']
  | [prefix: 'fas', iconName: 'circle-xmark']
  | [prefix: 'fas', iconName: 'exclamation']
  | [prefix: 'fas', iconName: 'xmark']
  | [prefix: 'fas', iconName: 'loader']
  | [prefix: 'far', iconName: 'angle-double-right']
  | [prefix: 'far', iconName: 'arrow-right']
  | [prefix: 'far', iconName: 'arrow-right-from-bracket']
  | [prefix: 'far', iconName: 'arrows-to-line']
  | [prefix: 'far', iconName: 'arrow-up-short-wide']
  | [prefix: 'far', iconName: 'arrows-from-line']
  | [prefix: 'far', iconName: 'arrows-rotate']
  | [prefix: 'far', iconName: 'arrows-repeat']
  | [prefix: 'far', iconName: 'box-archive']
  | [prefix: 'far', iconName: 'clipboard-list']
  | [prefix: 'far', iconName: 'star-exclamation']
  | [prefix: 'far', iconName: 'star']
  | [prefix: 'far', iconName: 'shapes']
  | [prefix: 'far', iconName: 'circle']
  | [prefix: 'far', iconName: 'circle-dot']
  | [prefix: 'far', iconName: 'comment-pen']
  | [prefix: 'far', iconName: 'envelope']
  | [prefix: 'far', iconName: 'eye']
  | [prefix: 'far', iconName: 'image']
  | [prefix: 'far', iconName: 'italic']
  | [prefix: 'far', iconName: 'file']
  | [prefix: 'far', iconName: 'gift']
  | [prefix: 'far', iconName: 'share']
  | [prefix: 'far', iconName: 'file-upload']
  | [prefix: 'far', iconName: 'chevron-left']
  | [prefix: 'far', iconName: 'chevron-right']
  | [prefix: 'far', iconName: 'calendar']
  | [prefix: 'far', IconName: 'language']
  | [prefix: 'far', IconName: 'layer-group']
  | [prefix: 'far', iconName: 'location-dot']
  | [prefix: 'far', iconName: 'lock']
  | [prefix: 'far', iconName: 'list-ol']
  | [prefix: 'far', iconName: 'list-ul']
  | [prefix: 'far', iconName: 'list']
  | [prefix: 'far', iconName: 'briefcase']
  | [prefix: 'far', iconName: 'bold']
  | [prefix: 'far', iconName: 'calendar-star']
  | [prefix: 'far', iconName: 'coin']
  | [prefix: 'far', iconName: 'comments']
  | [prefix: 'far', iconName: 'comment-lines']
  | [prefix: 'far', iconName: 'users']
  | [prefix: 'far', iconName: 'calendar-clock']
  | [prefix: 'far', iconName: 'calendar-check']
  | [prefix: 'far', iconName: 'calendar-xmark']
  | [prefix: 'far', iconName: 'calendar-exclamation']
  | [prefix: 'far', iconName: 'calendar-range']
  | [prefix: 'far', iconName: 'clipboard-user']
  | [prefix: 'far', iconName: 'face-smile']
  | [prefix: 'far', iconName: 'face-thinking']
  | [prefix: 'far', iconName: 'check-to-slot']
  | [prefix: 'far', iconName: 'road']
  | [prefix: 'far', iconName: 'route']
  | [prefix: 'far', iconName: 'robot']
  | [prefix: 'far', iconName: 'rotate']
  | [prefix: 'far', iconName: 'rectangle-ad']
  | [prefix: 'far', iconName: 'rectangle']
  | [prefix: 'far', iconName: 'rectangle-history']
  | [prefix: 'far', iconName: 'house']
  | [prefix: 'far', iconName: 'h1']
  | [prefix: 'far', iconName: 'h2']
  | [prefix: 'far', iconName: 'game-board-simple']
  | [prefix: 'far', iconName: 'paperclip-vertical']
  | [prefix: 'far', iconName: 'grip-dots-vertical']
  | [prefix: 'far', iconName: 'paperclip']
  | [prefix: 'far', iconName: 'waveform-lines']
  | [prefix: 'far', iconName: 'fast-backward']
  | [prefix: 'far', iconName: 'fast-forward']
  | [prefix: 'far', iconName: 'memo']
  | [prefix: 'far', iconName: 'text']
  | [prefix: 'far', iconName: 'tv']
  | [prefix: 'far', iconName: 'poll']
  | [prefix: 'far', iconName: 'pen']
  | [prefix: 'far', iconName: 'trash']
  | [prefix: 'far', iconName: 'edit']
  | [prefix: 'far', iconName: 'camera-web']
  | [prefix: 'far', iconName: 'presentation']
  | [prefix: 'far', iconName: 'pen-line']
  | [prefix: 'far', iconName: 'circle-xmark']
  | [prefix: 'far', iconName: 'computer-mouse']
  | [prefix: 'far', iconName: 'folder']
  | [prefix: 'far', iconName: 'video']
  | [prefix: 'far', iconName: 'microphone']
  | [prefix: 'far', iconName: 'ellipsis-vertical']
  | [prefix: 'far', iconName: 'arrow-up']
  | [prefix: 'far', iconName: 'arrow-down']
  | [prefix: 'far', iconName: 'arrow-down-to-line']
  | [prefix: 'far', iconName: 'arrow-down-triangle-square']
  | [prefix: 'far', iconName: 'minus']
  | [prefix: 'far', iconName: 'pencil']
  | [prefix: 'far', iconName: 'page']
  | [prefix: 'fas', iconName: 'party-horn']
  | [prefix: 'far', iconName: 'window-maximize']
  | [prefix: 'far', iconName: 'wand-magic-sparkles']
  | [prefix: 'far', iconName: 'circle-info']
  | [prefix: 'far', iconName: 'bolt']
  | [prefix: 'far', iconName: 'books']
  | [prefix: 'far', iconName: 'book-open-cover']
  | [prefix: 'far', iconName: 'gear']
  | [prefix: 'far', iconName: 'gauge']
  | [prefix: 'far', iconName: 'globe']
  | [prefix: 'far', iconName: 'graduation-cap']
  | [prefix: 'far', iconName: 'square-question']
  | [prefix: 'far', iconName: 'circle-small']
  | [prefix: 'far', iconName: 'play']
  | [prefix: 'far', iconName: 'circle-check']
  | [prefix: 'far', iconName: 'signal-strong']
  | [prefix: 'far', iconName: 'square-poll-vertical']
  | [prefix: 'far', iconName: 'clock']
  | [prefix: 'far', iconName: 'paragraph']
  | [prefix: 'far', iconName: 'paper-plane-top']
  | [prefix: 'far', iconName: 'heart']
  | [prefix: 'far', iconName: 'circle-notch']
  | [prefix: 'far', iconName: 'square-check']
  | [prefix: 'far', iconName: 'strikethrough']
  | [prefix: 'far', iconName: 'arrows-v']
  | [prefix: 'far', iconName: 'user-plus']
  | [prefix: 'far', iconName: 'hourglass']
  | [prefix: 'far', iconName: 'circle-question']
  | [prefix: 'far', IconName: 'lightbulb']
  | [prefix: 'far', IconName: 'bullseye-arrow']
  | [prefix: 'far', IconName: 'party-horn']
  | [prefix: 'far', IconName: 'timer']
  | [prefix: 'far', IconName: 'tools']
  | [prefix: 'far', IconName: 'check']
  | [prefix: 'far', iconName: 'warning']
  | [prefix: 'far', iconName: 'square']
  | [prefix: 'far', IconName: 'link']
  | [prefix: 'far', IconName: 'thumbs-up']
  | [prefix: 'far', IconName: 'underline']
  | [prefix: 'far', IconName: 'reply']
  | [prefix: 'far', IconName: 'solar-system'];

export const fontAwesomeIcons = [
  fasExchange,
  fasAngleDown,
  fasAngleUp,
  fasAngleLeft,
  fasAngleRight,
  fasSquareQuestion,
  fasArrowDown,
  fasArrowLeft,
  fasArrowRight,
  fasArrowUp,
  fasArrowTurnDownRight,
  fasAsterisk,
  fasBadgeCheck,
  fasSquare,
  fasBars,
  fasBarsProgress,
  fasBell,
  fasBold,
  fasBug,
  fasCalendar,
  fasCaretUp,
  fasPoll,
  fasCaretDown,
  fasCaretRight,
  fasWaveformLines,
  fasChalkboardTeacher,
  fasChartBar,
  fasChartLine,
  fasCheck,
  fasCheckCircle,
  fasChevronDown,
  fasChevronRight,
  fasChevronUp,
  fasCircle,
  fasCircleCheck,
  fasClipboard,
  fasClipboardListCheck,
  fasListCheck,
  fasClock,
  fasCog,
  fasWavePulse,
  fasTimer,
  fasText,
  fasCommentAlt,
  fasComments,
  fasCopy,
  fasDebug,
  fasWindowMaximize,
  fasDoorOpen,
  fasDotCircle,
  fasGameBoardSimple,
  fasDownload,
  fasEdit,
  fasEllipsisH,
  fasEllipsisVertical,
  fasEnvelope,
  fasExchangeAlt,
  fasExclamationTriangle,
  fasCircleExclamation,
  fasExclamation,
  fasExpand,
  fasExternalLink,
  fasEye,
  fasFastForward,
  fasFastBackward,
  fasEyeSlash,
  fasGift,
  fasCheckToSlot,
  fasShare,
  fasFile,
  fasFileAlt,
  fasFileArchive,
  fasFileAudio,
  fasFileExcel,
  fasFilePdf,
  fasFileVideo,
  fasFilter,
  fasFileUpload,
  fasFlagCheckered,
  fasFolder,
  fasGear,
  fasGlobe,
  fasH1,
  fasH2,
  fasHeart,
  fasHourglass,
  fasImage,
  fasInboxIn,
  fasInfo,
  fasInfoCircle,
  fasItalic,
  fasKey,
  fasLanguage,
  fasLaptop,
  fasLayerGroup,
  fasHourglassClock,
  fasLevelDownAlt,
  fasLightbulb,
  fasLightbulbExclamation,
  fasLink,
  fasLinkSlash,
  fasUsers,
  fasListOl,
  fasListUl,
  fasLock,
  fasLongArrowAltLeft,
  fasMapMarkerAlt,
  fasMicrophone,
  fasMinus,
  fasNewspaper,
  fasPaperPlane,
  fasPage,
  fasPause,
  fasPen,
  fasPlay,
  fasPlayCircle,
  fasPlus,
  fasPlusCircle,
  fasSquareCheck,
  fasPowerOff,
  fasQuestion,
  fasCalendarExclamation,
  fasWarning,
  fasRedoAlt,
  fasReply,
  fasSave,
  fasSearch,
  fasSignalAlt,
  fasSignOut,
  fasSitemap,
  fasSortAlt,
  fasSparkes,
  fasSplotch,
  fasSpinnerThird,
  fasStrikethrough,
  fasSyncAlt,
  fasThLarge,
  fasGripDotsVertical,
  fasThumbsUp,
  fasThumbtack,
  fasTimes,
  fasTools,
  fasTrash,
  fasTrashRestore,
  fasUnderline,
  fasUndoAlt,
  fasUniversity,
  fasUpload,
  fasUser,
  fasRepeat,
  fasUserHelmetSafety,
  fasUsers,
  fasVideo,
  fasWrench,
  fasHome,
  fasList,
  fasCompass,
  fasClipboardList,
  fasExclamationSquare,
  fasCircleXmark,
  farCommentLines,
  farCommentPen,
  fasLoader,
  farAngleDoubleRight,
  farArrowRight,
  farArrowRightFromBracket,
  farArrowsRepeat,
  farArrowsToLine,
  farArrowDownToLine,
  farArrowDownTriangleSquare,
  farArrowsRotate,
  farArrowUpShortWide,
  farArrowsFromLine,
  farBriefcase,
  farBooks,
  farBoxArchive,
  farBookOpenCover,
  farClipboardList,
  farUsers,
  farClipboardUser,
  farStarExclamation,
  farStrikethrough,
  farStar,
  farSquarePollVertical,
  farShapes,
  farCircle,
  farDotCircle,
  farEnvelope,
  farEye,
  farImage,
  farGameBoardSimple,
  farGauge,
  farItalic,
  farChevronLeft,
  farChevronRight,
  farGripDotsVertical,
  farCalendar,
  farLanguage,
  farLocationDot,
  farLock,
  farList,
  farListOl,
  farListUl,
  farCalendarStar,
  farSquareQuestion,
  farCalendarClock,
  farCalendarCheck,
  farCalendarXmark,
  farCalendarRange,
  farCoin,
  farComments,
  farSquare,
  farFaceSmile,
  farFaceThinking,
  farWaveformLines,
  farLayerGroup,
  farRectangleAd,
  farRectangle,
  farRectangleHistory,
  farRoad,
  farRobot,
  farRotate,
  farRoute,
  farHouse,
  farH1,
  farH2,
  farPaperclipVertical,
  farWindowMaximize,
  farPaperclip,
  farParagraph,
  farWandMagicSparkles,
  farWarning,
  farPage,
  farMemo,
  farText,
  farTv,
  farTrash,
  farEdit,
  farGift,
  farCheckToSlot,
  farShare,
  farCameraWeb,
  farPresentation,
  farPenLine,
  farPen,
  farComputerMouse,
  farFolder,
  farFileUpload,
  farFile,
  farPoll,
  farVideo,
  farMicrophone,
  farEllipsisVertical,
  farArrowUp,
  farArrowDown,
  farMinus,
  farPencil,
  fasPartyHorn,
  farCircleInfo,
  farBolt,
  farGraduationCap,
  farGlobe,
  farGear,
  farCircleSmall,
  farPlay,
  farCircleCheck,
  farSignalStrong,
  farClock,
  farPaperPlaneTop,
  farHeart,
  farCircleNotch,
  farArrowsV,
  farUserPlus,
  farUnderline,
  farHourglass,
  farSquareCheck,
  farFastBackward,
  farFastForward,
  farCircleQuestion,
  farLightbulb,
  farBullseyeArrow,
  farBold,
  farPartyHorn,
  farTimer,
  farTools,
  farCheck,
  farLink,
  farCircleXmark,
  farThumbsUp,
  farReply,
  farSolarSystem,
] as IconDefinition[];
