import { useTranslation } from 'react-i18next';

import { Button } from '@/ui/Button';

type ClearAllFilterProps = {
  onClick: () => void;
};

export const ClearAllFilter = ({ onClick }: ClearAllFilterProps): JSX.Element => {
  const { t } = useTranslation('managerDashboard');
  return (
    <Button variant="link" size="medium" onClick={onClick}>
      {t('Clear All')}
    </Button>
  );
};
