import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { ButtonList } from '@/components/Button/ButtonList';
import { AutoComplete } from '../../components/Form/types';

interface Props {
  onSubmit: (name: string, title: string, description: string) => void;
  setSectionForm: (visible: boolean) => void;
}

export const NewSectionForm = ({ onSubmit, setSectionForm }: Props): JSX.Element => {
  const { t } = useTranslation('discussions');
  const [name, setName] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  return (
    <form style={{ marginTop: 10 }}>
      <div className="field">
        <input
          type="text"
          autoComplete={AutoComplete.on}
          className="input is-info"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder={t('Channel path')}
        />
      </div>
      <div className="field">
        <input
          type="text"
          autoComplete={AutoComplete.on}
          className="input"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder={t('Channel alias')}
        />
      </div>
      <div className="field">
        <textarea
          className="textarea"
          autoComplete={AutoComplete.on}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder={t('Channel description')}
        />
      </div>
      <ButtonList align="center">
        <Button $type="text" onClick={() => setSectionForm(false)}>
          {t('Cancel')}
        </Button>
        <Button disabled={!name} onClick={() => onSubmit(name, title, description)}>
          {t('Add channel')}
        </Button>
      </ButtonList>
    </form>
  );
};
