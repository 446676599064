import React from 'react';

import { CheckboxStyled, AccessibleInputStyled, RootStyled, CheckmarkIcon } from './styles';

type CheckboxProps = {
  checked: boolean;
  disabled?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const Checkbox = ({ checked, onChange, disabled }: CheckboxProps): JSX.Element => {
  const handleCheckboxClick = () => {
    !disabled &&
      onChange({
        target: {
          checked: !checked
        }
      } as React.ChangeEvent<HTMLInputElement>);
  };

  return (
    <>
      <RootStyled onClick={handleCheckboxClick} disabled={disabled}>
        <AccessibleInputStyled type="checkbox" checked={checked} disabled={disabled} onChange={onChange} />
        <CheckboxStyled aria-hidden="true" checked={checked} tabIndex={0}>
          <CheckmarkIcon />
        </CheckboxStyled>
      </RootStyled>
    </>
  );
};
