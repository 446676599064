import React from 'react';
import styled from 'styled-components';

import { CustomEditorV2 } from '@/component/customEditorV2';

const RootStyled = styled.div`
  min-height: 100%;
  padding: 0 28px;
  overflow-y: scroll;

  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: stretch;
  align-items: stretch;
`;

const TextEditorStyled = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  align-items: flex-start;

  @media (min-width: ${(p) => p.theme.breakpoints.desktopLarge.end}px) {
    align-items: center;
  }
`;

type CoursePageContentTextProps = {
  id: string;
  spaceId: number;
  text: string;
  onTextChange: (text?: string) => void;
};

export const CoursePageContentText = ({ id, onTextChange, text = '', spaceId }: CoursePageContentTextProps): JSX.Element => {
  return (
    <RootStyled>
      <TextEditorStyled>
        <CustomEditorV2
          key={id}
          value={text}
          onChange={(text) => onTextChange(text)}
          style={{ fontSize: 14 }}
          $fullSize
          minimized
          spaceId={spaceId}
          placeholder="Write your content or ‘/’ for options"
          toggledFeatures={{
            dragAndDrop: true,
            draggableInsertHandler: true,
            editorGhostMode: true,
            floatingToolbar: true,
            slashInput: true,
            mediaFiles: true,
          }}
        />
      </TextEditorStyled>
    </RootStyled>
  );
};
