import { useTranslation } from 'react-i18next';

import { FilterSelect, Select } from '@/ui/Select';
import { getStatus } from '../utils';
import { FilterStatus } from './types';

type StatusFilterProps = {
  statuses?: FilterStatus[];
  placeholder?: string;
  selectedStatus?: FilterStatus;
  onChange: (status: FilterStatus) => void;
  onTrailingIconClick?: () => void;
};

export const StatusFilter = ({
  placeholder,
  selectedStatus,
  onChange,
  onTrailingIconClick,
  statuses: customStatuses,
}: StatusFilterProps): JSX.Element => {
  const { t } = useTranslation('managerDashboard');

  const statuses: {
    value: FilterStatus;
    displayValue: string;
  }[] = customStatuses
    ? customStatuses.map((status) => ({
        value: status as FilterStatus,
        displayValue: getStatus(status),
      }))
    : [
        { value: 'completed', displayValue: getStatus('completed') },
        { value: 'not completed', displayValue: getStatus('not completed') },
        { value: 'expired', displayValue: getStatus('expired') },
      ];

  const selectedStatusDisplay = selectedStatus && statuses.find((x) => x.value === selectedStatus)?.displayValue;
  return (
    <FilterSelect
      placeholder={placeholder ?? t('Status')}
      value={selectedStatusDisplay || ''}
      onChange={(m) => onChange(m as FilterStatus)}
      onTrailingIconClick={onTrailingIconClick}
      variant={'dropdown'}
    >
      <Select.List>
        {statuses.map((m) => (
          <Select.Option value={m.value} key={m.displayValue}>
            {m.displayValue}
          </Select.Option>
        ))}
      </Select.List>
    </FilterSelect>
  );
};
