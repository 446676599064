import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Control, Controller, useFormContext } from 'react-hook-form';

import { ColumnStyled, StyledLabel } from '@/features/course/admin/molecules/CourseSettingsModalForm/CourseSettingsModalForm';
import { Command, CommandItem, CommandEmpty, CommandInput, CommandList, CommandLoading } from '@/ui/Command';
import { Popover, PopoverContent, PopoverTrigger } from '@/ui/Popover/Popover';
import { Course } from '@/features/course/admin/types/course';
import { TextFieldName } from '@/features/course/admin/constants/settingsModal';
import { useSpaceMembersQueryRemote } from '@/store/v2';
import { Avatar } from '@/ui/Avatar';
import { shortenName } from '@/utils/text';
import { ComboboxTrigger } from '@/ui/Combobox';
import { InputChip } from '@/ui/Chip';
import { useDebouncedSearchPhrase } from '@/features/course/admin/hooks/useDebouncedSearchPhrase';
import { CourseSettingsModalFieldContentOwnerRow } from '@/features/course/admin/atoms/CourseSettingsModalFieldContentOwnerRow';
import { Skeleton } from '@/ui/Skeleton';

const SPACE_MEMEBERS_FETCH_LIMIT = 50;

const PopoverContentStyled = styled(PopoverContent)`
  width: 336px;
`;

const RootStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
  align-self: stretch;
`;

const ChipContentOwnerContentStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ChipContentOwnerDisplayedName = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
`;

type CourseSettingsModalFieldContentOwnerProps = { control: Control<Course>; spaceId: number };

export const CourseSettingsModalFieldContentOwner = ({
  control,
  spaceId,
}: CourseSettingsModalFieldContentOwnerProps): JSX.Element => {
  const { watch } = useFormContext<Course>();

  const [formStateContentOwnerId] = watch([TextFieldName.ContentOwner]);

  const { searchPhrase, debouncedSearchPhrase, onSearchPhraseChange } = useDebouncedSearchPhrase();

  const [displayedContentOwner, setDisplayedContentOwner] = useState<{
    id: number;
    firstname?: string;
    lastname?: string;
    email: string;
    profileImage?: string;
  }>();

  const searchPhraseTrimmed = debouncedSearchPhrase.trim();

  const { data: users, loading: loadingSearchedUsers } = useSpaceMembersQueryRemote({
    skip: !spaceId,
    variables: {
      filter: { spaceIdIN: [spaceId || 0], searchPhrase: searchPhraseTrimmed || undefined },
      pagination: { limit: SPACE_MEMEBERS_FETCH_LIMIT, page: 1 },
    },
  });

  const { data: savedContentOwnerData, loading: loadingSavedUsers } = useSpaceMembersQueryRemote({
    skip: !formStateContentOwnerId && displayedContentOwner?.id === formStateContentOwnerId,
    variables: {
      filter: { spaceIdIN: [spaceId || 0], idIN: formStateContentOwnerId ? [formStateContentOwnerId] : [] },
      pagination: { limit: -1, page: 1 },
    },
  });

  useEffect(() => {
    const updatedUser = savedContentOwnerData?.spaceMembers?.data?.[0];

    if (!updatedUser) return;

    setDisplayedContentOwner(updatedUser);
  }, [savedContentOwnerData?.spaceMembers?.data?.[0]?.id]);

  const [popoverOpen, setPopoverOpen] = useState(false);

  return (
    <RootStyled onClick={(e) => e.stopPropagation()}>
      <ColumnStyled>
        <StyledLabel>Content owner</StyledLabel>
      </ColumnStyled>
      <ColumnStyled>
        {loadingSavedUsers ? (
          <Skeleton height={44} />
        ) : (
          <Controller
            control={control}
            name={TextFieldName.ContentOwner}
            render={({ field: { onChange, value } }) => {
              const username =
                displayedContentOwner?.firstname || displayedContentOwner?.lastname
                  ? `${displayedContentOwner?.firstname || ''} ${displayedContentOwner?.lastname || ''}`
                  : displayedContentOwner?.email;

              const shortName = shortenName(username || '');

              return (
                <Popover open={popoverOpen} onOpenChange={setPopoverOpen}>
                  <PopoverTrigger asChild>
                    <ComboboxTrigger
                      comboboxOpen={popoverOpen}
                      label="Content owner"
                      onClick={() => {
                        setPopoverOpen(true);
                      }}
                    >
                      {displayedContentOwner ? (
                        <div>
                          <InputChip
                            avatar
                            label={
                              <ChipContentOwnerContentStyled>
                                <Avatar text={shortName} size="small" image={displayedContentOwner.profileImage} />
                                <ChipContentOwnerDisplayedName>{username}</ChipContentOwnerDisplayedName>
                              </ChipContentOwnerContentStyled>
                            }
                            hasCloseIcon
                            onTrailingIconClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();

                              onChange(undefined);
                              setDisplayedContentOwner(undefined);
                              setPopoverOpen(false);
                            }}
                          />
                        </div>
                      ) : null}
                    </ComboboxTrigger>
                  </PopoverTrigger>
                  <PopoverContentStyled align="start">
                    <Command shouldFilter={false}>
                      <CommandInput
                        value={searchPhrase}
                        onValueChange={(value) => onSearchPhraseChange(value)}
                        placeholder="Search name or email"
                      />
                      <CommandList>
                        {!loadingSearchedUsers && <CommandEmpty>No results found.</CommandEmpty>}
                        {loadingSearchedUsers && <CommandLoading />}
                        {users?.spaceMembers.data?.map((user) => {
                          return (
                            <CommandItem
                              key={user.id}
                              value={String(user.id)}
                              onSelect={(value) => {
                                onChange(Number(value));

                                setDisplayedContentOwner({
                                  ...user,
                                });

                                setPopoverOpen(false);
                              }}
                              $selected={user.id === value}
                            >
                              <CourseSettingsModalFieldContentOwnerRow
                                email={user.email}
                                firstname={user.firstname}
                                profileImage={user.profileImage}
                                lastname={user.lastname}
                              />
                            </CommandItem>
                          );
                        })}
                      </CommandList>
                    </Command>
                  </PopoverContentStyled>
                </Popover>
              );
            }}
          />
        )}
      </ColumnStyled>
    </RootStyled>
  );
};
