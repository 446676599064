import * as React from 'react';
import { Command as CommandPrimitive } from 'cmdk';
import styled from 'styled-components';

const CommandPrimitiveStyled = styled(CommandPrimitive)`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
  border-radius: 4px;
  outline: none;
  background: ${({ theme }) => theme.colors.primary.onMain};
  color: ${({ theme }) => theme.colors.neutral.onBackground};
  ${({ theme }) => theme.shadow.primary[8]}
`;

export const Command = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive>
>(({ className, ...props }, ref) => <CommandPrimitiveStyled ref={ref} className={className} {...props} />);

Command.displayName = CommandPrimitive.displayName;
