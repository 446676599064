import styled from 'styled-components';
import { Control, Controller } from 'react-hook-form';

import { Course } from '@/features/course/admin/types/course';
import { attributeGroups } from '@/administration/component/Attributes/attributeGroups';
import { TextFieldName } from '@/features/course/admin/constants/settingsModal';
import { CourseSettingModalFieldAttributesRow } from '@/features/course/admin/atoms/CourseSettingModalFieldAttributesRow';

const RootStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
`;

type CourseSettingsModalFieldAttributesProps = { control: Control<Course> };

export const CourseSettingsModalFieldAttributes = ({ control }: CourseSettingsModalFieldAttributesProps): JSX.Element => {
  const flattenAttributes = attributeGroups
    .reduce(
      (acc, curr) => {
        return [...acc, ...curr.rows];
      },
      [] as {
        attribute: string;
        values: string[];
      }[][]
    )
    .flat(1);

  return (
    <Controller
      control={control}
      name={TextFieldName.Attributes}
      render={({ field: { onChange, value: selectedAttributes } }) => {
        return (
          <RootStyled onClick={(e) => e.stopPropagation()}>
            {flattenAttributes.map(({ attribute: attributeKey, values: options }) => {
              return (
                <CourseSettingModalFieldAttributesRow
                  key={attributeKey}
                  options={options}
                  label={attributeKey}
                  value={selectedAttributes?.[attributeKey]}
                  onAttributeSelect={(selectedValue) => {
                    onChange({
                      ...selectedAttributes,
                      [attributeKey]: selectedValue,
                    });
                  }}
                />
              );
            })}
          </RootStyled>
        );
      }}
    />
  );
};
