import * as React from 'react';
import * as PopoverPrimitive from '@radix-ui/react-popover';
import styled from 'styled-components';

const Popover = PopoverPrimitive.Root;

const PopoverTrigger = PopoverPrimitive.Trigger;

const PopoverAnchor = PopoverPrimitive.Anchor;

const PopoverPrimitiveContentStyled = styled(PopoverPrimitive.Content)`
  z-index: 1400;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.primary.onMain};
  padding: 0;
  ${({ theme }) => theme.shadow.primary[8]};
  color: ${({ theme }) => theme.colors.neutral.onBackground};
  outline: none;
`;

const PopoverContent = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content>
>(({ className, align = 'center', sideOffset = 4, ...props }, ref) => (
  <PopoverPrimitive.Portal>
    <PopoverPrimitiveContentStyled ref={ref} align={align} sideOffset={sideOffset} className={className} {...props} />
  </PopoverPrimitive.Portal>
));
PopoverContent.displayName = PopoverPrimitive.Content.displayName;

export { Popover, PopoverTrigger, PopoverContent, PopoverAnchor };
