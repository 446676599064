import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { Overlay } from '@/components/Navbar/styles';
import { Icon } from '@/components/Icon';
import { Avatar, ImageSize } from '@/component/Avatar';
import { useRootContext } from '@/store/RootContext';
import { useDropdown } from '@/hooks/useDropdown';
import { NavNotificationsList } from '@/components/Navbar/components/NavNotificationsList/NavNotificationsList';
import { ADMIN_MENU_OPITONS, getMenuOptions, NAV_LINKS } from '@/components/Navbar/consts';
import { useNotifications } from '@/store/notifications';
import { getNotificationsCount } from '@/components/Navbar/utils/helpers';
import { checkAdminView } from '@/utils/checkAdminView';
import {
  StyledNavRightSection,
  StyledTabButton,
  StyledTabButtonDivider,
  StyledTabButtonAvatar,
  StyledDesktopMenuButton,
  StyledProfileMenuOption,
  StyledTabButtonWrapperV2,
  StyledProfileMenuOptionNativeLink,
  MenuBody,
} from '@/components/Navbar/components/NavRightSection/styles';
import { useIsFeatureEnabled } from '@/feature-toggles';
import { useCurrentUserAccess } from '@/store/currentUser';
import { useCurrentUserMaxAccessLevel } from '@/store/currentUser/useCurrentUserMaxAccessLevel';

enum MenuTypes {
  NOTIFICATIONS = 'notifications',
  MENU = 'menu',
}

const NOTIFICATIONS_COUNT_POLLING_INTERVAL_SEC = 60 * 5;

type NavRightSectionProps = {
  shouldOverrideAdminView?: boolean;
  isInPreviewModal?: boolean;
};

export const NavRightSection = ({ shouldOverrideAdminView, isInPreviewModal }: NavRightSectionProps): JSX.Element => {
  const { currentUser } = useRootContext();
  const { isAdmin: userIsAdmin, isManager } = useCurrentUserAccess();
  const { maxAccessLevel } = useCurrentUserMaxAccessLevel();

  const isAdmin = shouldOverrideAdminView ? false : userIsAdmin;
  const isAdminView = checkAdminView({ shouldOverrideAdminView });
  const { t } = useTranslation('navbar');
  const { containerRef, isOpen, handleOpenClose, close } = useDropdown<HTMLDivElement>();
  const { notifications } = useNotifications(NOTIFICATIONS_COUNT_POLLING_INTERVAL_SEC);
  const isFeatureEnabled = useIsFeatureEnabled();
  const hasNotifications = notifications.length > 0;
  const menuOptions = isAdminView ? ADMIN_MENU_OPITONS : getMenuOptions(maxAccessLevel);

  const [menuType, setMenuType] = useState<MenuTypes | null>(null);
  const handleOpenFactory = useCallback(
    (type: MenuTypes) => () => {
      setMenuType(type);
      handleOpenClose(menuType === type ? !isOpen : true);
    },
    [isOpen, handleOpenClose, setMenuType, menuType]
  );

  return (
    <>
      <Overlay isOpen={isOpen} isInPreviewModal={isInPreviewModal} />
      <StyledNavRightSection ref={containerRef}>
        {!isAdminView && (
          <StyledTabButtonWrapperV2>
            <StyledTabButton target={isInPreviewModal ? '_blank' : '_self'} as={NavLink} to={NAV_LINKS.ONGOING}>
              {t('My learning')}
            </StyledTabButton>
            <StyledTabButtonDivider>
              <StyledTabButtonAvatar
                target={isInPreviewModal ? '_blank' : '_self'}
                as={NavLink}
                to={NAV_LINKS.PROFILE}
                aria-label={t('Profile')}
                onClick={close}
              >
                <Avatar profileImage={currentUser?.profileImage} imageSize={ImageSize.Small} />
              </StyledTabButtonAvatar>
            </StyledTabButtonDivider>
            <StyledTabButton
              icon
              $selected={menuType === MenuTypes.NOTIFICATIONS && isOpen}
              onClick={handleOpenFactory(MenuTypes.NOTIFICATIONS)}
              hasNotifications={hasNotifications}
            >
              <Icon icon="bell" />
              {hasNotifications && getNotificationsCount(notifications.length)}
            </StyledTabButton>
            {menuType === MenuTypes.NOTIFICATIONS && isOpen && (
              <NavNotificationsList
                isInPreviewModal={isInPreviewModal}
                hasNotifications={hasNotifications}
                closeDropdown={close}
              />
            )}
          </StyledTabButtonWrapperV2>
        )}
        {menuType === MenuTypes.MENU && isOpen && (
          <MenuBody>
            {menuOptions.map(({ link, label, icon, admin, manager, newTab, useNativeLink, linkFactory = () => '' }) => {
              const menuLink = link || linkFactory(isFeatureEnabled);
              if (Boolean(admin) && !isAdmin) return null;
              if (Boolean(manager) && !isManager) return null;

              if (useNativeLink) {
                return (
                  <StyledProfileMenuOptionNativeLink
                    href={menuLink}
                    key={label}
                    target={newTab || isInPreviewModal ? '_blank' : '_self'}
                    onClick={close}
                  >
                    <span>{t(label)}</span>
                    {icon && <Icon icon={icon} fixedWidth />}
                  </StyledProfileMenuOptionNativeLink>
                );
              }
              return (
                <StyledProfileMenuOption
                  to={menuLink}
                  key={label}
                  target={newTab || isInPreviewModal ? '_blank' : '_self'}
                  onClick={close}
                >
                  <span>{t(label)}</span>
                  {icon && <Icon icon={icon} fixedWidth />}
                </StyledProfileMenuOption>
              );
            })}
          </MenuBody>
        )}
        <StyledDesktopMenuButton onClick={handleOpenFactory(MenuTypes.MENU)}>
          <Icon icon={menuType === MenuTypes.MENU && isOpen ? 'times' : 'bars'} fixedWidth />
        </StyledDesktopMenuButton>
      </StyledNavRightSection>
    </>
  );
};
