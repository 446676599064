import React, { ChangeEvent, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Course } from '@/features/course/admin/types/course';
import {
  ColumnStyled,
  StyledCaption,
  StyledLabel,
  WrapStyled,
} from '@/features/course/admin/molecules/CourseSettingsModalForm/CourseSettingsModalForm';
import { ButtonGroup } from '@/ui/ButtonGroup';
import { RadioGroup } from '@/ui/RadioGroup';
import { TextFieldName } from '@/features/course/admin/constants/settingsModal';
import { useCourseVisibilities } from '@/features/course/admin/hooks/useCourseVisibilities';

const DescriptionWrapStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  h4 {
    ${({ theme }) => theme.typography.body2}
  }

  p {
    ${({ theme }) => theme.typography.caption}
    -webkit-appearance: none;
  }
`;

export const CourseSettingsModalFieldUserVisibility = (): JSX.Element => {
  const { getValues: getFormValues, setValue: setFormValue } = useFormContext<Course>();

  const { t } = useTranslation('catalog');

  const [value, setValue] = useState<string>(getFormValues(TextFieldName.Visiblity));

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    setFormValue(TextFieldName.Visiblity, e.target.value as never);
  };

  const VISBILITIES = useCourseVisibilities();

  return (
    <WrapStyled>
      <ColumnStyled>
        <ButtonGroup gap="small" direction="vertical">
          <StyledLabel>{t('visibility')}</StyledLabel>
          <StyledCaption>{t('visibility-caption')}</StyledCaption>
        </ButtonGroup>
      </ColumnStyled>
      <ColumnStyled>
        <ButtonGroup verticalGap="medium" direction="vertical">
          {VISBILITIES.map((x, index) => (
            <RadioGroup value={value} onChange={handleChange} key={index}>
              <RadioGroup.Button
                key={`${x.value}-${index}`}
                value={x.value}
                label={
                  <DescriptionWrapStyled>
                    <h4>{x.title}</h4>
                    <p>{x.subtitle}</p>
                  </DescriptionWrapStyled>
                }
              />
            </RadioGroup>
          ))}
        </ButtonGroup>
      </ColumnStyled>
    </WrapStyled>
  );
};
