import styled from 'styled-components';
import { useState } from 'react';

import { useCurrentUserAccess } from '@/store/currentUser';
import { Button } from '@/ui/Button';
import { RLocationType } from '@/store/v2';
import { LiveEventModal } from '@/administration/pages/Journey/common/modals/LiveEventModal';
import { LiveEvent } from '@/pages/LiveEvent/store/liveEvent/types';
import { useParentJourney } from '@/store/v2/journeys/useParentJourneyId';
import { useJourneyNavigate } from '@/administration/pages/Journey/utils/useJourneyNavigate';

const ButtonsContainer = styled.div`
  display: flex;
  gap: 8px;
  position: absolute;
  top: 0;
  right: 0;
`;

type LiveEventControlButtonsProps = Pick<
  LiveEvent,
  'id' | 'title' | 'teaser' | 'locationId' | 'spaceId' | 'image' | 'startDate' | 'endDate' | 'type' | 'address' | 'timezone'
>;

type ModalProps = {
  LiveEventModal: Omit<React.ComponentProps<typeof LiveEventModal>, 'open'>;
};

export const LiveEventControlButtons = ({
  id,
  locationId,
  title,
  spaceId,
  image,
  startDate,
  endDate,
  teaser,
  type,
  address,
  timezone,
}: LiveEventControlButtonsProps): JSX.Element | null => {
  const { navigatoToEditLearningsLearningAttendance } = useJourneyNavigate();
  const { isAdmin, loading } = useCurrentUserAccess();
  const parentJourney = useParentJourney({
    id: Number(id),
  });
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [liveEventModalProps, setLiveEventModalProps] = useState<ModalProps['LiveEventModal']>({
    onClose: () => {
      handleModalClose();
    },
    spaceId: Number(spaceId) || 0,
    onCreate: () => {},
    onUpdate: () => {},
    defaultValues: undefined,
    mode: 'add',
  });

  const handleModalClose = () => {
    setIsEditModalOpen(false);
  };

  const handleOpenModal = () => {
    setIsEditModalOpen(true);
  };

  const handleEditClick = () => {
    setLiveEventModalProps((prev) => ({
      ...prev,
      mode: 'edit',
      spaceId: Number(spaceId) || 0,
      onUpdate: () => {
        handleModalClose();
      },
      defaultValues: {
        title: title,
        coverImageURL: image,
        startsAtTime: startDate ? new Date(startDate).toISOString() : undefined,
        endsAtTime: endDate ? new Date(endDate).toISOString() : undefined,
        description: teaser,
        format: type,
        location: type === RLocationType.InPerson ? address : undefined,
        calendarURL: type === RLocationType.Online ? address : undefined,
        timezone: timezone ? (timezone as unknown as number) : undefined,
        date: startDate ? new Date(startDate).toISOString() : undefined,
      },
      learningId: Number(id) ? Number(id) : undefined,
      locationId: Number(locationId),
    }));

    handleOpenModal();
  };

  const handleMarkAttendanceClick = () => {
    navigatoToEditLearningsLearningAttendance(Number(parentJourney?.journeyId), Number(id));
  };

  if (!isAdmin || loading) return null;

  return (
    <ButtonsContainer>
      <LiveEventModal {...liveEventModalProps} open={isEditModalOpen} />
      <Button icon={['far', 'pen']} variant="secondary" onClick={handleEditClick}>
        Edit
      </Button>
      <Button icon={['far', 'clipboard-user']} variant="secondary" onClick={handleMarkAttendanceClick}>
        Mark attendance
      </Button>
    </ButtonsContainer>
  );
};
