import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { BodyMd, Header4, Paragraph } from '@/components/Typography/Typography';
import { useModalContext } from './ModalContext';
import { StyledModal, StyledModalBody, StyledModalHeader, StyledModalOverlay } from './styles';
import { Button } from '../Button/Button';
import { ButtonList } from '../Button/ButtonList';
import { useOnEscapePress } from '@/hooks/useOnEscapePress';
import { useActiveElement } from '@/hooks/useActiveElement';
import { Portal } from './components/Portal';

export function ConfirmationModal(): JSX.Element | null {
  const {
    confirmTarget,
    hideConfirmModal,
    blockHide,
    showConfirm,
    confirmProps: { action, message, customHeaderText, warning },
  } = useModalContext();
  const { t } = useTranslation('common');
  const [loading, setLoading] = useState(false);
  const focusedElement = useActiveElement();

  useOnEscapePress(
    (event) => {
      if (showConfirm) {
        event.preventDefault();
        hideConfirmModal();
      }
    },
    [showConfirm]
  );

  const handleClick = async () => {
    if (action) {
      try {
        setLoading(true);
        await action();
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    }
    hideConfirmModal();
  };

  /**
   * We have to clear the focus on modal open,
   * in order to avoid having multiple modal opening attempts
   * for example on Enter hitting, when still focusing the "Open Modal" button.
   *
   * Another approach could be focusing the modal element directly.
   */
  useEffect(() => {
    if (showConfirm && focusedElement instanceof HTMLElement) {
      focusedElement.blur();
    }
  }, [showConfirm]);

  const handleClose = () => !blockHide && hideConfirmModal();
  const headerText = customHeaderText || t('Confirmation');

  return (
    <Portal mount={confirmTarget} show={showConfirm}>
      <StyledModalOverlay onClick={(e) => e.target === e.currentTarget && handleClose()}>
        <StyledModal aria-modal aria-label={headerText} tabIndex={-1} role="dialog" size={'small'} isConfirmation={true}>
          <>
            <StyledModalHeader isConfirmation={true} isWarning={warning}>
              <Header4 unpadded>{headerText}</Header4>
              <Button $type="base" $icon="times" $round onClick={handleClose} />
            </StyledModalHeader>
            <StyledModalBody isConfirmation={true}>
              <>
                <Paragraph as={BodyMd} innerSpacing="large">
                  {message}
                </Paragraph>
                <ButtonList align="center" innerSpacing="large">
                  <Button $type="text" onClick={handleClose}>
                    {t('Cancel')}
                  </Button>
                  <Button $type={warning ? 'default' : 'primary'} onClick={handleClick} $loading={loading}>
                    {t('Confirm')}
                  </Button>
                </ButtonList>
              </>
            </StyledModalBody>
          </>
        </StyledModal>
      </StyledModalOverlay>
    </Portal>
  );
}
