import { DependencyList, useEffect, useRef } from 'react';

export function useDidUpdateEffect(fn: () => void, deps: DependencyList): void {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) fn();
    else didMountRef.current = true;
  }, deps);
}
