import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox } from '@/components/Checkbox/Checkbox';
import { ButtonList } from '@/components/Button/ButtonList';
import { Button } from '@/components/Button/Button';
import { SortParams, SortHandler, TableDeclaration } from '../types';
import { SortableTableHeader } from './SortableTableHeader';
import { SelectionMeta } from '@/components/Table/hooks/useSelection';

export interface HeaderRowProps<T> {
  tableId: string;
  tableDecl: TableDeclaration<T>;
  selection: SelectionMeta<T>;

  /**
   * Sorting
   */
  onSort?: SortHandler<T>;
}

export const HeaderRow = <T extends unknown>({
  tableId,
  tableDecl,
  selection,
  onSort,
}: HeaderRowProps<T>): React.ReactElement => {
  const [sort, setSort] = useState<SortParams<T>>();
  const onSortChanged = (state: SortParams<T>) => {
    if (onSort) onSort(state);

    setSort(state);
  };

  return (
    <tr>
      {selection.isEnabled && (
        <th key="all-checkbox" style={{ width: '42px', paddingLeft: '14px' }}>
          <Checkbox
            dark
            marginless
            id={`${tableId}-all-checkbox`}
            checked={selection.isAllVisibleSelected}
            onChange={selection.onSelectAllVisible}
          />
        </th>
      )}
      {!!selection.selectedItemsCount && (
        <HeaderActions
          colSpan={tableDecl.length}
          length={selection.actions.length}
          buttons={selection.actions.map((action) => (
            <Button
              $loading={action.loading}
              key={action.title}
              $size={'small'}
              $type={'secondary'}
              onClick={() => selection.onActionClick(action)}
            >
              {action.title}
            </Button>
          ))}
        />
      )}
      {!(selection.selectedItemsCount && selection.actions.length) &&
        tableDecl.map((column, index) => (
          <SortableTableHeader key={column.id || index} column={column} index={index} sort={sort} setSort={onSortChanged} />
        ))}
    </tr>
  );
};

interface HeaderActionsProps {
  colSpan: number;
  length: number | undefined;
  buttons: JSX.Element[];
}

function HeaderActions({ colSpan, length, buttons }: HeaderActionsProps) {
  const { t } = useTranslation('autoTable');
  return (
    <th key="actions" colSpan={colSpan}>
      {length ? (
        <ButtonList align="center" style={{ marginRight: 42 }}>
          {buttons}
        </ButtonList>
      ) : (
        t('No available actions')
      )}
    </th>
  );
}
