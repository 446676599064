import styled from 'styled-components';

import { cvar } from '@/styles/GlobalStyle';
import { MixinDropdownMenu } from '@/components/Navbar/styles';
import { CALCULATED_MENU_INSIDE_MODAL_MAX_HEIGHT } from '@/administration/pages/Journey/pages/EditJourney/atoms/Preview/MainModalBox';

export const StyledNotificationsList = styled.div<{isInPreviewModal?: boolean}>`
  max-height: ${({ isInPreviewModal }) => isInPreviewModal ? CALCULATED_MENU_INSIDE_MODAL_MAX_HEIGHT : '500px'};
  overflow: auto;
  padding: 0 ${cvar('gap-sm')} ${cvar('gap-sm')};
  right: 0;
  width: 345px;
  ${MixinDropdownMenu}
`;

export const StyledNavNotificationsListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${cvar('gap-sm')} 0;
  svg {
    color: ${cvar('color-icon')};
    font-size: ${cvar('font-size-7')};
    cursor: pointer;
  }

  &:hover svg {
    color: ${cvar('color-icon-hover')};
  }
`;

export const StyledNavNotificationsItem = styled.div`
  background-color: ${cvar('color-white')};
  width: 100%;
  padding: ${cvar('gap-xs')};
  box-shadow: ${cvar('box-shadow-large')};
  border-radius: ${cvar('radius-inner')};
`;
