import { Direction } from '../../types/direction';
import { EditorBlock } from './types';
import { LearningWithLinks } from '../../../store/learning';

export function decorateReorder<T>(items: T[], direction: Direction, predicate: (i: T) => boolean): T[] {
  const index = items.findIndex(predicate);

  if (index === -1) {
    throw new Error('Couldnt find item matching predicate');
  }
  const newItems = items.map((i) => i);
  const item = newItems[index];

  if (direction === Direction.UP) {
    if (index === 0) return items;
    newItems.splice(index, 1);
    newItems.splice(index - 1, 0, item);
  } else if (direction === Direction.DOWN) {
    if (index === items.length - 1) return items;
    newItems.splice(index, 1);
    newItems.splice(index + 1, 0, item);
  }

  return updateOrder(newItems);
}

export function updateOrder<T>(items: T[]): T[] {
  return items.map((item, i) => ({
    ...item,
    order: i,
  }));
}

export function getBlockMap(learning: LearningWithLinks | null): Map<number, EditorBlock[]> {
  const blockMap = new Map<number, EditorBlock[]>();
  if (!learning) return blockMap;
  learning.modules?.forEach((module) => {
    module.pages?.forEach(({ order, blocks = [] }) => {
      if (isNaN(order)) throw new Error('page is missing order');
      blockMap.set(
        order,
        blocks.map(({ __typename, ...b }) => ({ ...b, editing: false }))
      );
    });
  });
  return blockMap;
}
