import { useTranslation } from 'react-i18next';

import { LearningVisibility } from '@/types/learning';

export const useCourseVisibilities = (): {
  title: string;
  subtitle: string;
  value: LearningVisibility;
}[] => {
  const { t } = useTranslation('catalog');

  return [
    {
      title: t('HIDDEN'),
      subtitle: t('hidden-caption'),
      value: LearningVisibility.HIDDEN,
    },
    {
      title: t('VISIBLE'),
      subtitle: t('visible-caption'),
      value: LearningVisibility.VISIBLE,
    },
    {
      title: t('FEATURED'),
      subtitle: t('featured-caption'),
      value: LearningVisibility.FEATURED,
    },
  ];
};
