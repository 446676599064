import { createPlatePlugin } from '@udecode/plate-common/react';

const EMBEDDED_ELEMENT_DOCUMENT = 'embeddedDocument';

export const EmbeddedDocumentPlugin = createPlatePlugin({
  key: EMBEDDED_ELEMENT_DOCUMENT,
  node: {
    isElement: true,
    isVoid: true,
    type: EMBEDDED_ELEMENT_DOCUMENT,
  },
});
