import { Activity, ActivityContent, ActivityType } from '@/features/course/admin/types/activity';
import { RLearningActivityType } from '@/store/v2';
import { ActivityId } from '@/administration/pages/Course/store/types';
import { ViewQuizPage } from '@/administration/pages/Course/pages/quiz/View/ViewQuizPage/ViewQuizPage';
import { XAPISession } from '@/store/xapi/useXAPISession';
import { ViewTextPage } from '../pages/text';
import { ViewReflectionPage } from '../pages/reflection/variants/View';
import { ViewReactionActivityV2 } from '../pages/reactions/View/indexV2';
import { BlockType } from '../store/block/types';
import { BlockId } from '../store/types';
import { ViewResultPage } from '../pages/result/View';

interface ViewActivityContentV2Props {
  activity: Activity;
  onActivityChange: (id: ActivityId, diff: Partial<Activity>) => void;
  spaceId: number;
  learningId: number;
  title?: string;
  xapiSession?: XAPISession;
}

export const ViewActivityContentV2 = ({
  activity,
  onActivityChange,
  spaceId,
  learningId,
  title,
  xapiSession,
}: ViewActivityContentV2Props): JSX.Element | null => {
  if (activity.type === RLearningActivityType.Page) {
    const richTextActivityContent = activity.content as ActivityContent;
    return (
      <ViewTextPage
        id={activity.id}
        spaceId={spaceId}
        title={activity.content.title}
        text={richTextActivityContent.text || '[]'}
        onCompleted={() => onActivityChange(activity.id, { completed: true })}
      />
    );
  } else if (activity.type === RLearningActivityType.Quiz) {
    return (
      <ViewQuizPage
        id={activity.id}
        text={activity?.content?.text as string}
        spaceId={spaceId}
        remoteId={activity.remoteId || 0}
        onCompleted={() => onActivityChange(activity.id, { completed: true })}
      />
    );
  } else if (activity.type === RLearningActivityType.Reflection) {
    const reflectionActivityContent = activity.content as ActivityContent;
    return (
      <ViewReflectionPage
        pageId={activity.remoteId || 0}
        spaceId={spaceId}
        title={reflectionActivityContent.title}
        description={reflectionActivityContent.description as string}
        postId={reflectionActivityContent.postId}
        sectionPath={reflectionActivityContent.sectionPath}
        onCompleted={(completed) => onActivityChange(activity.id, { completed })}
      />
    );
  } else if ((activity.type as unknown as ActivityType) === 'REACTION') {
    return (
      <ViewReactionActivityV2
        learningId={learningId}
        title={title}
        onCompleted={() =>
          onActivityChange(activity.id, {
            completed: true,
          })
        }
        xapiSession={xapiSession}
        isActive={false}
      />
    );
  } else if ((activity.type as unknown as ActivityType) === ActivityType.Result) {
    const resultActivityContent = activity.content as ActivityContent;
    return (
      <ViewResultPage
        id={activity.id}
        block={{
          id: activity.id as unknown as BlockId,
          type: BlockType.RESULT,
          level: resultActivityContent.level as string,
          effortHours: resultActivityContent.effortHours,
          effortMinutes: resultActivityContent.effortMinutes,
        }}
        isActive={true}
      />
    );
  }

  return null;
};
