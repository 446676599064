import { Button } from '@/ui/Button';
import { Icon } from '@/ui/Icon';
import { Select } from '@/ui/Select';
import { PageVariant } from '@/features/course/admin/types/page';

interface CourseDrawerAddActivityButtonProps {
  onAddClick: (type: PageVariant) => void;
}

export const CourseDrawerAddActivityButton = ({ onAddClick }: CourseDrawerAddActivityButtonProps): JSX.Element => {
  return (
    <Select
      value=""
      onChange={(type) => {
        onAddClick(type as PageVariant);
      }}
    >
      <Select.Header>
        {({ toggleShowOptions }) => (
          <Button variant="neutral" onClick={toggleShowOptions}>
            <Icon icon="plus" size="lg" />
          </Button>
        )}
      </Select.Header>
      <Select.List>
        <Select.Option value={PageVariant.PAGE} prefix={<Icon icon={['far', 'rectangle']} />}>
          Page
        </Select.Option>
        <Select.Option value={PageVariant.REFLECTION} prefix={<Icon icon={['far', 'comment-pen']} />}>
          Reflection
        </Select.Option>
        <Select.Option value={PageVariant.QUIZ} prefix={<Icon icon={['far', 'game-board-simple']} />}>
          Quiz
        </Select.Option>
        <Select.Option value={PageVariant.MODULE} prefix={<Icon icon={['far', 'rectangle-history']} />}>
          Module
        </Select.Option>
      </Select.List>
    </Select>
  );
};
