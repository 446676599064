import { RLearningByIdQuery, RLearningInput, RLearningStatus, RLearningType, RLearningVisibility } from '@/store/v2';
import { Course } from './types';
import { Module } from '../../../../../features/course/admin/types/module';
import { Page } from '@/features/course/admin/types/page';
import { hoursMinutesToMinutes, minutesToHoursMinutes } from '@/utils/time';
import { CourseId } from '@/administration/pages/Course/store/types';
import { LearningVisibility } from '@/types/learning';
import { transformModuleFromRemote, transformModuleToRemote } from '@/features/course/admin/utils/moduleTransform';
import { Progress } from '@/administration/pages/Course/store/progress/types';
import { transformProgressFromRemote } from '@/administration/pages/Course/store/progress/transform';

export const transformFromRemoteLearning = (
  rLearning: RLearningByIdQuery['learning'] | undefined
): [Course | undefined, Module | undefined, Page[] | undefined, Progress | undefined] => {
  if (!rLearning) return [undefined, undefined, undefined, undefined];

  const { hours, minutes } = minutesToHoursMinutes(rLearning?.effort || 0);

  const [module, pages] = transformModuleFromRemote(rLearning?.modules?.[0] || undefined);
  const progress = transformProgressFromRemote(rLearning?.progress ?? undefined);
  return [
    {
      id: rLearning.id as unknown as CourseId,
      spaceId: rLearning.spaceId || 0,
      title: rLearning.title || '',
      effortHours: hours,
      effortMinutes: minutes,
      image: rLearning.image || '',
      language: rLearning.languageId || 0,
      meta: rLearning.meta,
      level: rLearning?.meta?.level || 'Basic',
      partner: '', // tood,
      teaser: rLearning.teaser || '',
      visibility: rLearning.visibility as unknown as LearningVisibility,
      status: rLearning.status as unknown as RLearningStatus,
    },
    module,
    pages,
    progress,
  ];
};

export const transformToRemoteLearning = (
  course: Course,
  module: Module,
  pages: Page[],
  learningType: RLearningType
): RLearningInput =>
  ({
    id: course.id,
    spaceId: course.spaceId,
    title: course.title,
    type: learningType,
    meta: {
      ...(course.meta || {}),
      level: course.level || 'Basic',
    },
    image: course.image,
    teaser: course.teaser,
    effort: hoursMinutesToMinutes({ hours: Number(course.effortHours), minutes: Number(course.effortMinutes) }),
    languageId: course.language,
    visibility: (course.visibility || LearningVisibility.HIDDEN) as unknown as RLearningVisibility,
    subjects: [],
    preamble: '',
    modules: [transformModuleToRemote(module, pages, course.id as unknown as number, 0)],
  } as RLearningInput);

export const isCourseEqual = (
  oldCourse: { course: Course; module: Module; pages: Page[] },
  newCourse: { course: Course; module: Module; pages: Page[] },
  learningType: RLearningType
): boolean => {
  const oldLearning = transformToRemoteLearning(oldCourse.course, oldCourse.module, oldCourse.pages, learningType);
  const newLearning = transformToRemoteLearning(newCourse.course, newCourse.module, newCourse.pages, learningType);

  return JSON.stringify(oldLearning) === JSON.stringify(newLearning);
};
