import { makeNamespacedComponent } from '@/ui/utils';
// ATOMS
import { Select as Select_ } from './atoms/Select';
import { List } from './atoms/List';
import { Option } from './atoms/Option';
import { Header } from './atoms/Header';
// VARIANTS
import { FilterSelect } from './variants/FilterSelect';
import { TextFieldSelect } from './variants/TextFieldSelect';
import { UncontrolledSelect } from './variants/UncontrolledSelect';

const Select = makeNamespacedComponent(Select_, {
  Option: Option,
  Header: Header,
  List: List,
});

export { Select, FilterSelect, TextFieldSelect, UncontrolledSelect };
