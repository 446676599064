import { createPlatePlugin } from '@udecode/plate-common/react';

export const ELEMENT_QUIZ_ACTIONBAR = 'quizActionbar';

export const QuizActionbarPlugin = createPlatePlugin({
  key: ELEMENT_QUIZ_ACTIONBAR,
  node: {
    isElement: true,
    isVoid: true,
    type: ELEMENT_QUIZ_ACTIONBAR,
  },
});
