import styled, { css } from 'styled-components';

import { Icon } from '@/ui/Icon';

export const RootStyled = styled.div<{ disabled?: boolean }>`
  position: relative;
  display: inline-block;
  width: 16px;
  min-width: 16px; // Prevents shrinkage in flex layouts that are "crowded"
  height: 16px;
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.3;
      cursor: not-allowed;
    `}
`;

/**
 * Used hack from @link https://polished.js.org/docs/#hidevisually
 * To make it visible for screen-readers, but also hidden for user
 */
export const AccessibleInputStyled = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const CheckmarkIcon = styled(Icon).attrs({ icon: 'check' })`
  width: 12px;
  height: 12px;

  & path {
    fill: white;
  }
`;

export const CheckboxStyled = styled.div<{ checked?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border-radius: 4px;
  border: 1px solid ${(p) => p.theme.colors.neutral.outline2};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: all 0.3s;

  ${CheckmarkIcon} {
    visibility: ${({ checked }) => (checked ? 'visible' : 'hidden')};
  }
  ${({ checked }) =>
    checked &&
    css`
      background: ${({ theme }) => theme.colors.primary.main};
      border-color: ${({ theme }) => theme.colors.primary.main};
    `}

  &:hover, &:focus-visible {
    box-shadow: 0 0 0 4px ${({ theme, checked }) => (checked ? theme.colors.states.hover1 : theme.colors.neutral.container)};
  }
`;
