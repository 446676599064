import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Input } from '@/ui/TextField';
import { TextFieldName } from '@/features/course/admin/constants/settingsModal';
import { Course } from '@/features/course/admin/types/course';

type CourseSettingsModalFieldTitleProps = {
  control: Control<Course>;
};

export const CourseSettingsModalFieldTitle = ({ control }: CourseSettingsModalFieldTitleProps): JSX.Element => {
  const { t } = useTranslation('catalog');

  return (
    <Controller
      control={control}
      rules={{ required: true }}
      name={TextFieldName.Title}
      render={({ field: { onChange, name, value }, fieldState: { error } }) => (
        <Input
          onChange={onChange}
          name={name}
          value={value}
          fullWidth
          label={t('title')}
          maxLength={80}
          error={
            error && {
              message: t('enter-title'),
            }
          }
        />
      )}
    />
  );
};
