import React from 'react';
import styled from 'styled-components';
import { Composition } from 'atomic-layout';

import { StyledSelect } from '@/components/Form/components/Select/styles';
import { StyledFieldWrapper } from '@/components/Form/styles';
import { deviceUp } from '@/styles/utils';
import { StyledInput } from './Form/components/Input/styles';
import { WithChildrens } from '@/types/helpers/WithChildren';
import { StyledDropdownWrapper } from './Dropdown/StyledDropdownWrapper';
import { cvar } from '@/styles/GlobalStyle';

const StyledAddons = styled.div`
  white-space: nowrap;

  > div > ${StyledFieldWrapper}, > div > ${StyledDropdownWrapper} {
    white-space: nowrap;
    & > * {
      width: calc(100% + ${cvar('border-width')});
    }

    ${deviceUp.md`
      &:not(:first-child) ${StyledInput},
      &:not(:first-child) ${StyledSelect} {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
      }

      &:not(:last-child) ${StyledInput},
      &:not(:last-child) ${StyledSelect} {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        border-right: none;
      }
    `}
  }
`;

export const FormAddons = ({ children }: WithChildrens): JSX.Element => {
  const repeat = (Array.isArray(children) && children.filter((c) => c).length) || 1;
  return (
    <StyledAddons>
      <Composition gap={0.875} gapMd={0} templateCols="minmax(0,1fr)" templateColsMd={`repeat(${repeat}, minmax(0, 1fr))`}>
        {children}
      </Composition>
    </StyledAddons>
  );
};
