import { Maybe, RParticipationV2, useLearningV2ByIdQueryRemote } from '@/store/v2';
import { Activity } from '@/features/course/admin/types/activity';
import { CourseV2 } from '@/features/course/admin/types/course';
import { transformFromRemoteLearningV2 } from '@/features/course/admin/utils/activityTransform';
import { useCurrentUser } from '@/store/currentUser';

type UseCourseV2Result = {
  course?: CourseV2;
  activities?: Activity[];
  userLastParticipation?: Maybe<RParticipationV2>;
};

type UseCourseV2Hook = (id: number) => {
  data: UseCourseV2Result | undefined;
  loading: boolean;
};

export const useCourseV2: UseCourseV2Hook = (id) => {
  const { user } = useCurrentUser();
  const { data, loading } = useLearningV2ByIdQueryRemote({
    variables: {
      id,
      userId: Number(user?.id),
    },
    fetchPolicy: 'network-only',
  });

  const [course, activities] = transformFromRemoteLearningV2(data?.learningV2);

  return {
    loading,
    data: {
      course: course as unknown as CourseV2,
      activities,
    },
  };
};
