import * as React from 'react';
import { Command as CommandPrimitive } from 'cmdk';
import styled from 'styled-components';

import { Icon } from '@/ui/Icon';

const CommandInputStyled = styled(CommandPrimitive.Input)`
  display: flex;
  height: 24px;
  width: 100%;
  ${({ theme }) => theme.typography.body3};
  color: ${({ theme }) => theme.colors.neutral.onBackground};
  background: transparent;
  outline: none;
  border: none;

  ::placeholder {
    color: ${({ theme }) => theme.colors.neutral.outline2};
  }

  :disabled {
    color: ${({ theme }) => theme.colors.neutral.disabled};
    opacity: 0.5;
  }
`;

const CommandInputRootStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.outline1};
  padding: 12px 16px;
  color: ${({ theme }) => theme.colors.neutral.outline2};
`;

const CommandInputIconContainerStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
`;

export const CommandInput = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Input>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Input>
>(({ className, ...props }, ref) => (
  <CommandInputRootStyled cmdk-input-wrapper="">
    <CommandInputIconContainerStyled>
      <Icon size="xs" icon="search" />
    </CommandInputIconContainerStyled>
    <CommandInputStyled ref={ref} className={className} {...props} />
  </CommandInputRootStyled>
));

CommandInput.displayName = CommandPrimitive.Input.displayName;
