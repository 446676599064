import React from 'react';
import { Paginated } from '@makotot/paginated';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ButtonList } from '@/components/Button/ButtonList';
import { Button } from '@/components/Button/Button';
import { noop } from '@/utils/noop';
import { useWindowDimensions } from '@/hooks/useWindowDimensions';
import { screenSizes } from '@/utils/misc';

interface Props {
  isPadded?: boolean;
  pageCount: number;
  page: number;
  onPageChange: (page: number) => void;
}

const Nav = styled.nav`
  padding: 0 10px 10px;
  align-items: center;
  display: flex;
  justify-content: space-around;
  text-align: center;
  width: 100%;
`;

const NavButton = (props: { children: React.ReactNode; active?: boolean; disabled?: boolean; onClick: () => void }) => {
  return (
    <Button
      style={{ marginTop: 10 }}
      $size={'small'}
      disabled={props.disabled}
      $type={props.active ? 'primary' : 'secondary'}
      onClick={props.onClick}
    >
      {props.children}
    </Button>
  );
};

export const Paginator = ({ pageCount, page = 1, onPageChange }: Props): React.ReactElement => {
  const { t } = useTranslation('common');
  const { width } = useWindowDimensions();

  return (
    <Nav>
      <Paginated currentPage={page} totalPage={pageCount} siblingsSize={width < screenSizes.desktopMin ? 1 : 2} boundarySize={1}>
        {({ pages, currentPage, hasPrev, hasNext, getFirstBoundary, getLastBoundary, isPrevTruncated, isNextTruncated }) => (
          <ButtonList>
            <NavButton disabled={!hasPrev()} onClick={() => onPageChange(currentPage - 1)}>
              {t('Previous')}
            </NavButton>
            {getFirstBoundary().map((bound) => (
              <NavButton key={bound} onClick={() => onPageChange(bound)}>
                {bound}
              </NavButton>
            ))}
            {isPrevTruncated && (
              <NavButton disabled={true} onClick={noop}>
                ...
              </NavButton>
            )}
            {pages.map((pageNumber) => (
              <NavButton key={pageNumber} active={pageNumber === currentPage} onClick={() => onPageChange(pageNumber)}>
                {pageNumber}
              </NavButton>
            ))}
            {isNextTruncated && (
              <NavButton disabled={true} onClick={noop}>
                ...
              </NavButton>
            )}
            {getLastBoundary().map((bound) => (
              <NavButton key={bound} onClick={() => onPageChange(bound)}>
                {bound}
              </NavButton>
            ))}
            <NavButton disabled={!hasNext()} onClick={() => onPageChange(currentPage + 1)}>
              {t('Next')}
            </NavButton>
          </ButtonList>
        )}
      </Paginated>
    </Nav>
  );
};
