import { useState } from 'react';
import styled from 'styled-components';

import { CustomEditorV2, EMPTY_VALUE } from '@/component/customEditorV2';
import { Button } from '@/ui/Button';

const RootStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const TitleStyled = styled.div`
  ${({ theme }) => theme.typography.title3};
  color: ${({ theme }) => theme.colors.neutral.onContainer};
`;

const DescriptionStyled = styled.div`
  ${({ theme }) => theme.typography.body1};
  color: ${({ theme }) => theme.colors.neutral.onBackground};
`;

const EditorContainer = styled.div`
  height: 205px;
  display: flex;
  flex-direction: column;
`;

const SubmissionContainerStyled = styled.div`
  padding: 16px 0;
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ActionsStyled = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;
export const WriteReviewForm = ({
  onSubmit,
  spaceId,
}: {
  onSubmit: (reviewText: string) => void;
  spaceId: number;
}): JSX.Element => {
  const [reviewToSubmit, setReviewToSubmit] = useState(EMPTY_VALUE);

  return (
    <RootStyled>
      <TitleStyled>Write a review</TitleStyled>
      <DescriptionStyled>
        Your review will be visible to your peer and the owner of this learning journey. Once submitted, your review cannot be
        edited.
      </DescriptionStyled>
      <SubmissionContainerStyled>
        <EditorContainer>
          <CustomEditorV2
            value={reviewToSubmit}
            onChange={(review) => setReviewToSubmit(review)}
            style={{ fontSize: 14 }}
            $fullSize
            minimized
            placeholder="Write here"
            spaceId={spaceId}
          />
        </EditorContainer>
        <ActionsStyled>
          <Button variant="primary" onClick={() => onSubmit(reviewToSubmit)} $loading={false}>
            Submit
          </Button>
        </ActionsStyled>
      </SubmissionContainerStyled>
    </RootStyled>
  );
};
