import React from 'react';
import styled from 'styled-components';
import { SortableTree, TreeItems } from 'dnd-kit-sortable-tree';
import { UniqueIdentifier } from '@dnd-kit/core';

import { PageVariant } from '@/features/course/admin/types/page';
import { TreeDrawerContext } from '@/features/course/admin/contexts/TreeDrawerContext';
import { ItemData, TreeItemData } from '@/features/course/admin/types/treeDrawer';
import { CourseTreeDrawerItem } from '@/features/course/admin/organisms/CourseTreeDrawerItem';

const ListStyled = styled.div`
  overflow-y: scroll;
  height: 100%;
  height: calc(100vh - 92px);
`;

const RootStyled = styled(ListStyled)`
  // This class defines the whole item container
  & .dnd-sortable-tree_simple_tree-item {
    padding: 0;
    border: none;
  }

  // This class sets styling for ghost (projection) of the dragged element in the list
  & .dnd-sortable-tree_simple_ghost {
    opacity: 0.5;
  }
`;

export const CourseTreeDrawer = ({
  items,
  setItems,
  onAddItem,
  onActivateItem,
  onRemoveItem,
  onRenameItem,
  onItemCollapsedChanged,
}: {
  items: TreeItems<TreeItemData<ItemData>>;
  setItems: React.Dispatch<React.SetStateAction<TreeItems<TreeItemData<ItemData>>>>;
  onAddItem: (type: PageVariant, parentActivityId?: string) => void;
  onRemoveItem: (itemId: string) => void;
  onRenameItem: (id: string, newTitle: string) => void;
  onActivateItem: (id: string) => void;
  onItemCollapsedChanged: (ops: { activityId: string; collapsed: boolean }) => void;
}): JSX.Element => {
  const handleCustomCollapse = ({ targetId, customCollapsed }: { targetId: UniqueIdentifier; customCollapsed: boolean }) => {
    onItemCollapsedChanged({ activityId: String(targetId), collapsed: customCollapsed });
  };

  return (
    <RootStyled>
      <TreeDrawerContext.Provider
        value={{
          onAddPage: onAddItem,
          onRemoveItem: onRemoveItem,
          onRenameItem: onRenameItem,
          onActivateItem: onActivateItem,
          onHandleCustomCollapse: handleCustomCollapse,
        }}
      >
        <SortableTree
          items={items}
          onItemsChanged={setItems}
          TreeItemComponent={CourseTreeDrawerItem}
          // Sets the left padding on the item if it's nested
          indentationWidth={32}
        />
      </TreeDrawerContext.Provider>
    </RootStyled>
  );
};
