import _isEqual from 'lodash.isequal';
import { useState, useEffect } from 'react';
import { DocumentNode, OperationVariables, TypedDocumentNode } from '@apollo/client/core';
import { QueryHookOptions, QueryResult } from '@apollo/client/react/types/types';
import { useQuery } from '@apollo/client';

type DebounceOptions<TVariables = OperationVariables> = {
  debounceDelay?: number;
  shouldDebounce?: (prevVars: TVariables | undefined, nextVars: TVariables | undefined) => boolean;
};

type QueryType<TData, TVariables> = DocumentNode | TypedDocumentNode<TData, TVariables>;
type OptionsType<TData, TVariables extends OperationVariables = OperationVariables> =
  | (QueryHookOptions<TData, TVariables> & DebounceOptions<TVariables>)
  | undefined;

/**
 * useDebouncedQuery debounces changes in certain query variables, before invoking GQL useQuery
 *
 * Not that:
 *  - if options has changed, debouncing is being discarded
 *  - shouldDebounce determines whether change to variables should be debounced
 *
 * @param query
 * @param nextOptions
 */
export function useDebouncedQuery<TData = any, TVariables extends OperationVariables = OperationVariables>(
  query: QueryType<TData, TVariables>,
  nextOptions?: OptionsType<TData, TVariables>
): QueryResult<TData, TVariables> {
  const [currentOptions, setCurrentOptions] = useState<OptionsType<TData, TVariables>>(nextOptions);
  const [isDebouncing, setIsDebouncing] = useState<boolean>(false);
  const queryResult = useQuery(query, currentOptions);

  useEffect(() => {
    const { variables: currentVariables, shouldDebounce: currentShouldDebounce, ...currentOptionsRaw } = currentOptions || {};
    const { variables: nextVariables, shouldDebounce: nextShouldDebounce, ...nextOptionsRaw } = nextOptions || {};

    if (!_isEqual(currentOptionsRaw, nextOptionsRaw)) {
      setIsDebouncing(false);
      setCurrentOptions(nextOptions);

      return;
    }

    if (_isEqual(currentVariables, nextVariables) && !isDebouncing) {
      return;
    }

    if (
      !nextOptionsRaw.debounceDelay ||
      !nextShouldDebounce ||
      (!isDebouncing && !nextShouldDebounce(currentVariables, nextVariables))
    ) {
      setIsDebouncing(false);
      setCurrentOptions(nextOptions);

      return;
    }

    setIsDebouncing(true);
    const timeout = setTimeout(() => {
      setCurrentOptions(nextOptions);
    }, nextOptionsRaw.debounceDelay);

    return () => {
      clearTimeout(timeout);
    };
  }, [nextOptions]);

  useEffect(() => {
    if (isDebouncing) setIsDebouncing(false);
  }, [currentOptions]);

  return { ...queryResult, loading: queryResult.loading || isDebouncing };
}
