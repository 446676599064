import { css, CSSObject, FlattenSimpleInterpolation, SimpleInterpolation } from 'styled-components';

import { px } from '../utils/toPx';
import { BreakpointUnion, AllBreakpoints, BreakpointEnum } from './types';
import { breakpoints } from './GlobalStyle';

type StyledExpression = TemplateStringsArray | CSSObject;

type ModdedTaggedTemplateType = (
  literals: StyledExpression,
  ...placeholders: SimpleInterpolation[]
) => FlattenSimpleInterpolation;

const mediaQuery =
  (literalsOuter: StyledExpression, ...placeholdersOuter: SimpleInterpolation[]) =>
  (literals: StyledExpression, ...placeholders: SimpleInterpolation[]) =>
    css`
      @media ${css(literalsOuter, ...placeholdersOuter)} {
        ${css(literals, ...placeholders)}
      }
    `;

export const deviceUp = AllBreakpoints.reduce((acc, key) => {
  if (isNaN(breakpoints[key])) return acc;
  acc[key] = mediaQuery`(min-width: ${px(breakpoints[key])})`;
  return acc;
}, {} as { [index: string]: ModdedTaggedTemplateType });

export const deviceDown = AllBreakpoints.reduce((acc, key) => {
  if (isNaN(breakpoints[key])) return acc;
  acc[key] = mediaQuery`(max-width: ${px(breakpoints[key] - 1)})`;
  return acc;
}, {} as { [index: string]: ModdedTaggedTemplateType });

export const deviceOnly = AllBreakpoints.reduce((acc, key) => {
  if (isNaN(breakpoints[key])) return acc;
  if (key === BreakpointEnum.xxl) {
    acc[key] = deviceUp.xxl;
  } else {
    const nextBreakpointIndex = AllBreakpoints.indexOf(key) + 1;
    acc[key] = mediaQuery`(min-width: ${px(breakpoints[key])}) and (max-width: ${px(
      breakpoints[AllBreakpoints[nextBreakpointIndex]] - 1
    )})`;
  }
  return acc;
}, {} as { [index: string]: ModdedTaggedTemplateType });

export function getBetweenDevices(firstBreakpoint: BreakpointUnion, secondBreakpoint: BreakpointUnion): ModdedTaggedTemplateType {
  if (firstBreakpoint === 'xxl') return deviceUp.xxl;
  if (firstBreakpoint === 'xs') return deviceDown[secondBreakpoint];
  if (firstBreakpoint === secondBreakpoint) return deviceOnly[firstBreakpoint];
  const firstIsSmaller = breakpoints[firstBreakpoint] < breakpoints[secondBreakpoint];
  return mediaQuery`(min-width: ${px(breakpoints[firstIsSmaller ? firstBreakpoint : secondBreakpoint])}) and max-width: ${px(
    breakpoints[firstIsSmaller ? secondBreakpoint : firstBreakpoint]
  )}`;
}
