import React, { useEffect, useContext } from 'react';
import axios from 'axios';

import { SnackbarType, SharedSnackbarContext } from '../SharedSnackbar/SharedSnackbar';
import { Button } from '@/components/Button/Button';
import { ButtonList } from '@/components/Button/ButtonList';

const INTERVAL = window.REACT_APP_ENV === 'prod' ? 600000 : 60000;

export const BundleChecker = (): JSX.Element => {
  const sharedSnackbarContext = useContext(SharedSnackbarContext);

  useEffect(() => {
    if (window.REACT_APP_ENV === 'local') return;
    const interval = setInterval(() => {
      axios
        .get(`/_VERSION/index.html?${new Date().getTime()}`)
        .then((response) => {
          if (!response || !response.data) return;
          if (response.data !== window.REACT_APP_VERSION)
            sharedSnackbarContext.openSnackbar({
              message: (
                <div>
                  <p>There is a new version available, reload to make sure you are up to date.</p>
                  <ButtonList>
                    <Button onClick={() => window.location.reload()}>Reload page</Button>
                  </ButtonList>
                </div>
              ),
              type: SnackbarType.WARNING,
            });
        })
        .catch(console.error);
    }, INTERVAL);
    return () => clearInterval(interval);
  }, [sharedSnackbarContext]);

  return <></>;
};
