import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { LearningType } from '@/management/store/types';
import { FilterSelect, Select } from '@/ui/Select';
import { Icon } from '@/components/Icon';
import { IconPropType } from '@/ui/Icon';

type CategoryFilterProps = {
  placeholder?: string;
  selectedLearningType?: string;
  onChange: (learningType: LearningType) => void;
  omitTypes?: string[];
  onTrailingIconClick?: () => void;
};

export const LearningTypeFilter = ({
  placeholder,
  selectedLearningType,
  omitTypes = ['survey', 'practicalAssessment'],
  onChange,
  onTrailingIconClick,
}: CategoryFilterProps): JSX.Element => {
  const { t } = useTranslation(['managerDashboard', 'productType']);

  const defaultWidth = '14';
  const commonLearningTypes = [
    { value: 'audio', displayValue: t('audio', { ns: 'productType' }), prefixIcon: ['far', 'microphone'] },
    { value: 'article', displayValue: t('article', { ns: 'productType' }), prefixIcon: ['far', 'memo'] },
    { value: 'e-learning', displayValue: t('e-learning', { ns: 'productType' }), prefixIcon: ['far', 'tv'], width: '15' },
    { value: 'course', displayValue: t('learning-path', { ns: 'productType' }), prefixIcon: ['far', 'road'] },
    { value: 'self-tracked', displayValue: t('self-tracked', { ns: 'productType' }), prefixIcon: ['far', 'clock'], width: '15' },
    {
      value: 'online-learning',
      displayValue: t('online-learning', { ns: 'productType' }),
      prefixIcon: ['far', 'computer-mouse'],
    },
    { value: 'resource', displayValue: t('resource', { ns: 'productType' }), prefixIcon: ['far', 'paperclip-vertical'] },
    { value: 'seminar', displayValue: t('seminar', { ns: 'productType' }), prefixIcon: ['far', 'presentation'] },
    { value: 'video', displayValue: t('video', { ns: 'productType' }), prefixIcon: ['far', 'video'] },
    { value: 'webinar', displayValue: t('webinar', { ns: 'productType' }), prefixIcon: ['far', 'camera-web'] },
    { value: 'survey', displayValue: t('survey', { ns: 'productType' }), prefixIcon: ['far', 'square-poll-vertical'] },
    {
      value: 'practicalAssessment',
      displayValue: t('practicalAssessment', { ns: 'productType' }),
      prefixIcon: ['far', 'pen-line'],
    },
  ].filter((type) => !omitTypes.includes(type.value));

  const selectedLearningTypeDisplay =
    selectedLearningType && commonLearningTypes.find((x) => x.value === selectedLearningType)?.displayValue;
  return (
    <FilterSelect
      placeholder={placeholder ?? t('Learning Type')}
      value={selectedLearningTypeDisplay || ''}
      onChange={(m) => onChange(m as LearningType)}
      onTrailingIconClick={onTrailingIconClick}
      variant={'dropdown'}
    >
      <Select.List>
        {commonLearningTypes
          .sort((a, b) => a.displayValue.toLocaleLowerCase().localeCompare(b.displayValue.toLocaleLowerCase()))
          .map((m) => (
            <Select.Option
              value={m.value}
              key={m.value}
              prefix={<StyledIcon icon={m.prefixIcon as IconPropType} width={m.width ?? defaultWidth} />}
            >
              {m.displayValue}
            </Select.Option>
          ))}
      </Select.List>
    </FilterSelect>
  );
};

const StyledIcon = styled(Icon)<{ width: string }>`
  ${({ width }) => `width: ${width}px;`}
  height: 12px;
`;
