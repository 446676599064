import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Layout } from '@/administration/pages/Journey/pages/ViewJourney/atoms/Layout';
import { useJourneyNavigate } from '@/administration/pages/Journey/utils/useJourneyNavigate';
import { useLearningJourneySession } from '@/administration/pages/Journey/store/journeySession/useLearningJourneySession';
import { ShowDescriptionModal } from '@/administration/pages/Journey/pages/ViewJourney/modals/ShowDescriptionModal';
import { useUserContentLastParticipation } from '@/store/v2/catalog-v2/use-user-last-participation';
import { useCurrentUser } from '@/store/currentUser';
import { useLearningJourney } from '@/store/v2/journeys/useLearningJourney';
import { useJoinLearningJourneyCohortsMutationRemote } from '@/store/v2';
import { useFavouriteJourney } from '@/administration/pages/Journey/store/favouriteJourney/useFavouriteJourney';
import { useUserContentLastParticipations } from '@/store/v2/catalog-v2/use-user-last-participations';
import { useMultiKeyDict } from '@/utils/datastructure/use-multi-key-dict';
import { LearningCardError } from '@/administration/pages/Journey/common/atoms/LearningCard/LearningCard';
import { UnlockLearningModal } from '@/administration/pages/Journey/pages/ViewJourney/modals/UnlockLearningModal';
import { useUnlockUserLearningJourneyItem } from '@/administration/pages/Journey/store/journey/useUnlockUserLearningJourneyItem';
import { learningContentDisplayMeta } from '@/store/v2/catalog-v2/model/learning-content';
import { convertTitle } from '@/utils/misc';

enum ModalType {
  ShowDescriptionModal = 'ShowDescriptionModal',
  UnlockLearningModal = 'UnlockLearningModal',
}

type ModalProps = {
  [ModalType.ShowDescriptionModal]: React.ComponentProps<typeof ShowDescriptionModal>;
  [ModalType.UnlockLearningModal]: React.ComponentProps<typeof UnlockLearningModal>;
};

type Modal = keyof ModalProps;

export const ViewJourney = ({isInPreviewModal} : {isInPreviewModal?: boolean}): JSX.Element | null => {
  const { user, loading: userLoading } = useCurrentUser();
  const { journeyId } = useParams<{ journeyId: string }>();
  const { navigate404 } = useJourneyNavigate();
  const navigate = useNavigate();

  const [activeModal, setModal] = useState<Modal | null>();
  const [targetLearningURNForUnlocking, setTargetLearningURNForUnlocking] = useState<string>();

  const {
    journey,
    items,
    loading: journeyLoading,
    error: journeyError,
  } = useLearningJourney({
    id: Number(journeyId),
    include: {
      items: true,
    },
  });

  const {
    journeySession,
    loading: journeySessionLoading,
    error: journeySessionError,
  } = useLearningJourneySession({ id: Number(journeyId) });

  const {
    participation: journeyParticipation,
    loading: journeyParticipationLoading,
    error: journeyParticipationError,
    refetch: participationRefetch,
  } = useUserContentLastParticipation({
    userId: user?.id,
    contentURN: journey?.urn,
  });

  const {
    participations: journeyParticipations,
    loading: journeyParticipationsLoading,
    error: journeyParticipationsError,
  } = useUserContentLastParticipations({
    userId: user?.id,
    contentURNs: items?.map((item) => item.contentId),
  });

  const { unlockUserLearningJourneyItem } = useUnlockUserLearningJourneyItem();

  const participationsMap = useMultiKeyDict(journeyParticipations || [], [(p) => p.objectId]);

  const learners =
    journeySession?.cohort.learners.map((learner) => ({
      id: learner.user.id,
      email: learner.user.email || '',
      profileImage: learner.user.profileImage || '',
      name: learner.user.username || '',
    })) || [];

    
  const [joinJourney, { loading: onJoinJourneyLoading }] = useJoinLearningJourneyCohortsMutationRemote({
    refetchQueries: ['learningJourneySession'],
  });
  const { isSaved: isSavedFavourite, handleSavedLearnings } = useFavouriteJourney(journey?.id);

  const handleUnlockLearning = () => {
    const userId = user?.id;
    const learningJourneyId = journey?.id;

    if (userId && learningJourneyId && targetLearningURNForUnlocking) {
      unlockUserLearningJourneyItem(
        {
          userId,
          learningJourneyId,
          itemURN: targetLearningURNForUnlocking,
        },
        () => {
          handleModalClose();
        }
      );
    }
  };

  const openUnlockLearningModal = ({ learningItemObjectId }: { learningItemObjectId: string }) => {
    setTargetLearningURNForUnlocking(learningItemObjectId);
    setModal(ModalType.UnlockLearningModal);
  };

  const openShowDescriptionModal = () => {
    setModal(ModalType.ShowDescriptionModal);
  };

  const handleModalClose = () => {
    setModal(null);
    setTargetLearningURNForUnlocking(undefined);
  };

  const handleLearnersViewAll = () => {
    if (!journey || !journeySession) return;
    const insidePreviewModalUrl = `/journey/${journey.id}/${convertTitle(journey.title)}/sessions/${journeySession?.id}/participants`;
    const outsidePreviewModalUrl = `sessions/${journeySession?.id}/participants`;

    if (isInPreviewModal) {
      navigate(insidePreviewModalUrl);
    } else {
      navigate(outsidePreviewModalUrl);
    }
  };

  const onJoinJourney = async () => {
    if (!user?.id || !journeySession?.id) return;

    await joinJourney({ variables: { userId: user.id, sessionId: journeySession.id } });

    // refetch progress
    participationRefetch();
  };

  if (journeyLoading || journeySessionLoading || userLoading || journeyParticipationsLoading) return <Layout.LoadingState />;

  if (journeyError || journeySessionError || journeyError || journeyParticipationError || journeyParticipationsError) {
    navigate404();
    return null;
  }

  if (!journey) return null;

  return (
    <>
      <ShowDescriptionModal
        open={activeModal === ModalType.ShowDescriptionModal}
        onClose={handleModalClose}
        description={journey?.description || ''}
        businessGoal={journey?.businessGoal || ''}
      />
      <UnlockLearningModal
        open={activeModal === ModalType.UnlockLearningModal}
        onClose={handleModalClose}
        onUnlock={handleUnlockLearning}
      />
      <Layout
        journey={journey}
        learnersPage={learners}
        learnersTotal={learners.length}
        onLearnersViewAll={handleLearnersViewAll}
        learningItems={
          items?.map((item, order, initialList) => ({
            ...learningContentDisplayMeta(item.content, journey.spaceId),
            objectId: item.contentId,
            locked: item.isLocked,
            type: item.content?.metadata?.type,
            level: item.content?.metadata?.level,
            disabled: item.isLocked && order !== 0 && initialList[order - 1].isLocked,
            totalEffort: item.content?.plannedDuration,
            orderIndex: order + 1,
            locations: item.content?.locations,
            error: !item.content ? LearningCardError.UNAVAILABLE : undefined,
          })) || []
        }
        learningItemsTotal={items?.length || 0}
        participationsMap={participationsMap}
        onExpandDescription={openShowDescriptionModal}
        isSavedFavourite={isSavedFavourite}
        onSaveFavourite={handleSavedLearnings}
        journeyParticipation={journeyParticipation}
        journeyParticipationLoading={journeyParticipationLoading}
        onJoinJourneyLoading={onJoinJourneyLoading}
        onJoinJourney={onJoinJourney}
        onLockedItemClick={openUnlockLearningModal}
        isInPreviewModal={isInPreviewModal}
      />
    </>
  );
};
