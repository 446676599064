import { useCallback, useEffect, useState } from 'react';

import { toggleItemInSet } from '@/utils/set';
import { BulkAction } from '@/components/Table';

export interface SelectionMeta<T> {
  isEnabled: boolean;
  isSelected: (item: T) => boolean;
  setSelectedItems: (items: T[]) => void;
  toggleSelectedItem: (item: T) => void;
  onSelectAllVisible: () => void;
  onSelectAll: () => void;
  actions: BulkAction<T>[];
  onActionClick: (action: BulkAction<T>) => void;
  isAllVisibleSelected: boolean;
  areAllRowsSelected: boolean;
  selectedItemsCount: number;
}

export const DEFAULT_DISABLED_SELECTION_META: SelectionMeta<any> = {
  isEnabled: false,
  isSelected: () => false,
  setSelectedItems: () => undefined,
  toggleSelectedItem: () => undefined,
  onSelectAllVisible: () => undefined,
  onSelectAll: () => undefined,
  actions: [],
  onActionClick: () => undefined,
  isAllVisibleSelected: false,
  areAllRowsSelected: false,
  selectedItemsCount: 0,
};

export interface SelectionParams<T> {
  allItems: readonly T[];
  visibleItems: readonly T[];
  bulkActions?: BulkAction<T>[];
}

export function useSelection<T>({ allItems, visibleItems, bulkActions }: SelectionParams<T>): SelectionMeta<T> {
  const [selected, setSelected] = useState<Set<T>>(new Set([]));
  const [actions, setActions] = useState<BulkAction<T>[]>([]);

  useEffect(() => {
    if (!bulkActions) return;

    setActions(bulkActions.filter((action) => !action.isEnabled || action.isEnabled([...selected])));
  }, [selected, bulkActions]);

  const toggleSelectedItem = useCallback((item: T) => setSelected(toggleItemInSet(selected, item)), [selected, setSelected]);
  const setSelectedItems = useCallback((items: T[]) => setSelected(new Set(items)), [selected, setSelected]);
  const isSelected = useCallback((item: T) => selected.has(item), [selected]);

  const isAllVisibleSelected = useCallback((): boolean => visibleItems.every(isSelected), [visibleItems]);
  const onSelectAll = useCallback(() => setSelectedItems(allItems.slice()), [allItems, setSelectedItems]);
  const onSelectAllVisible = useCallback(() => {
    // if all visible are already selected, clear the selection
    if (isAllVisibleSelected()) {
      return setSelectedItems([]);
    }

    return setSelectedItems(visibleItems as T[]);
  }, [visibleItems, setSelectedItems]);

  const onActionClick = useCallback((action: BulkAction<T>) => action.action([...selected]), [selected]);

  if (!bulkActions) {
    return DEFAULT_DISABLED_SELECTION_META;
  }

  return {
    isEnabled: true,
    isSelected,
    setSelectedItems,
    toggleSelectedItem,
    onSelectAll,
    onSelectAllVisible,
    actions,
    onActionClick,
    isAllVisibleSelected: isAllVisibleSelected(),
    areAllRowsSelected: selected.size === allItems.length,
    selectedItemsCount: selected.size,
  };
}
