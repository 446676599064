import { AccessLevel, UseCurrentUserMaxAccessLevelHook } from '@/store/currentUser/types';
import { useCurrentUserAccess } from '@/store/currentUser/userCurrentUserAccess';

export const useCurrentUserMaxAccessLevel: UseCurrentUserMaxAccessLevelHook = () => {
  const { isManager, isAdmin, isSuperAdmin, loading } = useCurrentUserAccess();

  let maxAccessLevel: AccessLevel = 'learner';

  if (isManager) maxAccessLevel = 'manager';
  if (isAdmin) maxAccessLevel = 'admin';
  if (isSuperAdmin) maxAccessLevel = 'superAdmin';

  return {
    loading,
    maxAccessLevel,
  };
};
