import * as React from 'react';
import { Command as CommandPrimitive } from 'cmdk';
import styled from 'styled-components';

const CommandEmptyStyled = styled(CommandPrimitive.Empty)`
  ${({ theme }) => theme.typography.body3};
  color: ${({ theme }) => theme.colors.neutral.onBackground};
  padding: 24px 16px;
`;

export const CommandEmpty = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Empty>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Empty>
>((props, ref) => (
  <CommandEmptyStyled ref={ref} {...props}>
    No results found
  </CommandEmptyStyled>
));

CommandEmpty.displayName = CommandPrimitive.Empty.displayName;
