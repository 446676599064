import { IconPropType } from '@/ui/Icon';
import { LearningType } from './types';

export const useLabelState = (
  type: LearningType
):
  | {
      icon: IconPropType;
    }
  | undefined => {
  const state: Record<
    LearningType,
    {
      icon: IconPropType;
    }
  > = {
    COURSE: {
      icon: ['far', 'road'],
    },
    COLLECTION: {
      icon: ['far', 'folder'],
    },
    RESOURCE: {
      icon: ['far', 'paperclip-vertical'],
    },
    ARTICLE: {
      icon: ['far', 'memo'],
    },
    VIDEO: {
      icon: ['far', 'video'],
    },
    AUDIO: {
      icon: ['far', 'microphone'],
    },
    E_LEARNING: {
      icon: ['far', 'tv'],
    },
    WEBINAR: {
      icon: ['far', 'camera-web'],
    },
    SEMINAR: {
      icon: ['far', 'presentation'],
    },
    ONLINE_LEARNING: {
      icon: ['far', 'computer-mouse'],
    },
    SELF_TRACKED: {
      icon: ['far', 'house'],
    },
    JOURNEY: {
      icon: ['far', 'road'],
    },
    PATH: {
      icon: ['far', 'road'],
    },
    SURVEY: {
      icon: ['far', 'square-poll-vertical'],
    },
    PRACTICAL_ASSESSMENT: {
      icon: ['far', 'pen-line'],
    },
    LIVE_EVENT: {
      icon: ['far', 'calendar'],
    },
  };

  return state[type];
};
