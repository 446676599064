import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Layout } from '@/ui/Layout';
import { Button } from '@/ui/Button';
import { useLearningJourneySession } from '@/administration/pages/Journey/store/journeySession/useLearningJourneySession';
import { Participants } from '@/administration/pages/Journey/common/atoms/Participants';
import { HeroStyled, TitleStyled, ContentStyled } from '@/administration/pages/Journey/pages/ViewParticipants/styles';
import { useJourneyNavigate } from '@/administration/pages/Journey/utils/useJourneyNavigate';

const LIST_CHUNK_SIZE = 20;

export const ViewParticipants = (): JSX.Element | null => {
  const navigate = useNavigate();
  const { navigate404 } = useJourneyNavigate();

  const { journeyId } = useParams<{ journeyId: string }>();
  const [, setLimit] = useState<number>(LIST_CHUNK_SIZE); // TODO use for pagination

  const {
    journeySession,
    loading: journeySessionLoading,
    error: journeySessionError,
  } = useLearningJourneySession({ id: Number(journeyId) });

  const learners =
    journeySession?.cohort.learners.map((learner) => ({
      id: learner.user.id,
      email: learner.user.email || '',
      imageSrc: learner.user.profileImage || '',
      name: learner.user.username || '',
    })) || [];

  if ((!journeySession || journeySessionError) && !journeySessionLoading) {
    navigate404();

    return null;
  }

  const onLoadMore = () => {
    setLimit((prev) => prev + LIST_CHUNK_SIZE);
  };

  return (
    <>
      <Layout.ContentSection marginTop>
        <HeroStyled>
          <Button variant="ghost" icon="arrow-left" onClick={() => navigate(-1)}>
            Back
          </Button>
          <TitleStyled>Learners</TitleStyled>
        </HeroStyled>
      </Layout.ContentSection>
      <Layout.ContentSection marginTop marginBottom>
        <ContentStyled>
          {journeySessionLoading ? (
            <Participants.LoadingState />
          ) : (
            <Participants participants={learners} total={learners?.length || 0} onLoadMoreItems={onLoadMore} />
          )}
        </ContentStyled>
      </Layout.ContentSection>
    </>
  );
};
