import * as React from 'react';
import { Command as CommandPrimitive } from 'cmdk';
import styled from 'styled-components';

const CommandListStyled = styled(CommandPrimitive.List)`
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const CommandList = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.List>
>(({ className, ...props }, ref) => <CommandListStyled ref={ref} className={className} {...props} />);

CommandList.displayName = CommandPrimitive.List.displayName;
