import React, { useState, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { Button } from '@/components/Button/Button';
import { Event, buildIcsFile, calendarUrl, isInternetExplorer, CALENDARS_ENUM } from './utils';
import { convertTitle } from '@/utils/misc';
import { Icon } from '@/components/Icon';
import { SharedSnackbarContext, SnackbarType } from '../SharedSnackbar/SharedSnackbar';
import { useOnClickOutside } from '@/hooks/useOnClickOutside';
import { FEATURE, useFeatureEnabled } from '@/feature-toggles';

interface AddToCalendarProps {
  event: Event;
}

export const AddToCalendar = ({ event }: AddToCalendarProps): React.ReactElement => {
  const { t } = useTranslation('learning');
  const { openSnackbar } = useContext(SharedSnackbarContext);
  const [showDropdown, setShowDropdown] = useState(false);
  const filename = convertTitle(event.title);
  const node = useRef<HTMLDivElement>(null);

  const isGoogleDisabled = useFeatureEnabled(FEATURE.UI_CALENDAR_GOOGLE_DISABLED);
  const isMicrosoftDisabled = useFeatureEnabled(FEATURE.UI_CALENDAR_MICROSOFT_DISABLED);
  const isOutlookDisabled = useFeatureEnabled(FEATURE.UI_CALENDAR_OUTLOOK_DISABLED);

  useOnClickOutside(showDropdown, setShowDropdown, node);

  const handleEarlyExit = () => {
    openSnackbar({
      message: t('serverMessages::generic|:error', { message: '' }),
      type: SnackbarType.DANGER,
    });
  };

  const handleWebCalendarClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    const url = e.currentTarget.getAttribute('href');
    if (!url) return handleEarlyExit();

    openSnackbar({
      message: t('Event opened with calendar: {{calendar}}', { calendar: e.currentTarget?.firstChild?.textContent }),
      type: SnackbarType.SUCCESS,
      isDismissive: true,
    });

    window.open(url, '_blank');
    setShowDropdown(false);
  };

  const handleIcalClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    const ical = buildIcsFile(event);
    if (!ical) return handleEarlyExit();
    const blob = new Blob([ical], { type: 'text/calendar;charset=utf-8' });

    openSnackbar({
      message: t('iCal file downloaded successfully.'),
      type: SnackbarType.SUCCESS,
      isDismissive: true,
    });

    if (isInternetExplorer()) {
      window.navigator.msSaveOrOpenBlob(blob, `${filename}.ics`);
    } else {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute('download', `${filename}.ics`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    setShowDropdown(false);
  };

  return (
    <div ref={node} className={clsx('dropdown', showDropdown && 'is-active')}>
      <div className="dropdown-trigger">
        <Button $type="primary" $icon="calendar" onClick={() => setShowDropdown(!showDropdown)}>
          {t('Add to calendar')}
        </Button>
      </div>
      <div className="dropdown-menu" id="dropdown-menu" role="menu">
        <div className="dropdown-content">
          <span className="overline dropdown-item">{t('Web calendars')}</span>
          {!isGoogleDisabled && (
            <a
              href={calendarUrl({ calendar: CALENDARS_ENUM.GOOGLE, event })}
              onClick={handleWebCalendarClick}
              className="dropdown-item has-icon is-flex is-justified-space-between is-aligned-center"
            >
              <span>Google</span> <Icon icon="external-link" />
            </a>
          )}
          {!isMicrosoftDisabled && (
            <a
              href={calendarUrl({ calendar: CALENDARS_ENUM.MICROSOFT, event })}
              onClick={handleWebCalendarClick}
              className="dropdown-item has-icon is-flex is-justified-space-between is-aligned-center"
            >
              <span>Microsoft live</span> <Icon icon="external-link" />
            </a>
          )}
          {!isOutlookDisabled && (
            <a
              href={calendarUrl({ calendar: CALENDARS_ENUM.OUTLOOK365, event })}
              onClick={handleWebCalendarClick}
              className="dropdown-item has-icon is-flex is-justified-space-between is-aligned-center"
            >
              <span>Outlook 365</span> <Icon icon="external-link" />
            </a>
          )}
          <hr className="dropdown-divider" />
          <span className="overline dropdown-item">{t('Download', { ns: 'common' })}</span>
          <a onClick={handleIcalClick} className="dropdown-item has-icon is-flex is-justified-space-between is-aligned-center">
            <span>iCal</span> <Icon icon="download" />
          </a>
        </div>
      </div>
    </div>
  );
};
