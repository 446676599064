import styled from 'styled-components';

import { CustomEditorV2 } from '@/component/customEditorV2';

export const RootStyled = styled.div`
  min-height: 100%;
  padding: 0 28px;
  overflow-y: scroll;

  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;

  gap: 24px;
  justify-content: stretch;
  align-items: stretch;
`;

export const TextEditorStyled = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  align-items: flex-start;

  @media (min-width: ${(p) => p.theme.breakpoints.desktopLarge.end}px) {
    align-items: center;
  }
`;

export type CoursePageContentReflectionProps = {
  id: string;
  spaceId: number;
  description?: string;
  onDescriptionChange: (description: string) => void;
};

export const CoursePageContentReflection = ({
  id,
  spaceId,
  description,
  onDescriptionChange,
}: CoursePageContentReflectionProps): JSX.Element => {
  return (
    <RootStyled>
      <TextEditorStyled>
        <CustomEditorV2
          key={id}
          value={description || ''}
          onChange={(text) => onDescriptionChange(text)}
          style={{ fontSize: 14 }}
          $fullSize
          minimized
          spaceId={spaceId}
          placeholder="Write your content"
          toggledFeatures={{
            dragAndDrop: true,
            draggableInsertHandler: true,
            editorGhostMode: true,
            floatingToolbar: true,
            slashInput: true,
            mediaFiles: true,
          }}
        />
      </TextEditorStyled>
    </RootStyled>
  );
};
