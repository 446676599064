import { createPlatePlugin } from '@udecode/plate-common/react';

const ELEMENT_AUDIO = 'audio';

export const AudioPlugin = createPlatePlugin({
  key: ELEMENT_AUDIO,
  node: {
    isElement: true,
    isVoid: true,
    type: ELEMENT_AUDIO,
  },
});
