import { findNodePath, PlateElementProps, useEditorRef, useRemoveNodeButton } from '@udecode/plate-common/react';
import styled, { css } from 'styled-components';
import { useSelected } from 'slate-react';
import { useContext } from 'react';
import { setNodes } from '@udecode/plate-common';

import { PlateElement } from '@/component/customEditorV2/ui/elements/PlateElement';
import { BaseAudio } from '@/component/customEditorV2/ui/elements/AudioElement/BaseAudio';
import { CustomEditorContext } from '@/component/customEditorV2/store/CustomEditorContext';
import { useFileUploader } from '@/component/FileUpload/useFileUpload';

const AudioStyled = styled.div<{ $selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  cursor: pointer;
  object-fit: cover;
  padding-right: 0;
  padding-left: 0;
  border-radius: 14px;

  ${({ $selected }) =>
    $selected &&
    css`
      border: 2px solid ${({ theme }) => theme.colors.primary.outline};
    `};
`;

export const AudioElement = (props: PlateElementProps): JSX.Element => {
  const selected = useSelected();
  const editor = useEditorRef();
  const { spaceId } = useContext(CustomEditorContext);
  const removeNodeButtonProps = useRemoveNodeButton({ element: props.element })?.props;

  const documentUpload = useFileUploader({
    spaceId,
    type: 'document',

    onUploadSuccess: ({ url, name }) => {
      const path = findNodePath(editor, props.element);

      setNodes(
        editor,
        { audioUrl: url, audioName: name },
        {
          at: path,
        }
      );
    },
  });

  const handleRemoveAudio = () => {
    removeNodeButtonProps.onClick();
  };

  const audioUrl = typeof props.element.audioUrl === 'string' ? props.element.audioUrl : undefined;

  return (
    <PlateElement className={props.className} {...props}>
      <figure contentEditable={false}>
        <AudioStyled $selected={selected}>
          <BaseAudio
            url={audioUrl}
            selected={selected}
            onUpload={documentUpload.uploadFile}
            isUploading={documentUpload.uploading}
            uploadingProgress={documentUpload.uploadingProgress}
            onRemove={handleRemoveAudio}
          />
        </AudioStyled>
      </figure>
      {props.children}
    </PlateElement>
  );
};
