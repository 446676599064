import { ChangeEvent, ChangeEventHandler, useCallback } from 'react';

import { LearningSubject } from '@/types/learning';
import {
  trackCategoryFilterUsed,
  trackDurationFilterUsed,
  trackFreeTextFilterUsed,
  trackProviderFilterUsed,
  trackSortingChanged,
  trackSubjectFilterUsed,
  trackTypeFilterUsed,
  trackLanguageFilterUsed,
  trackAttributeFilterUsed,
} from '@/utils/tracking/learnings';
import { useDebouncedFunction } from '@/hooks/useDebounce';

type TrackNumber = (value: number) => void;
type TrackString = (value: string) => void;
type TrackingFunctions = {
  trackCategory: TrackNumber;
  trackSubject: TrackNumber;
  trackType: TrackString;
  trackDuration: TrackString;
  trackProvider: TrackString;
  trackTitle: ChangeEventHandler<HTMLInputElement>;
  trackLanguage: TrackString;
  trackAttributes: TrackString | TrackNumber;
  trackSort: TrackString;
};

export function useFilterTracking(categories: LearningSubject[], subjects: Array<LearningSubject>): TrackingFunctions {
  const trackCategory = useCallback(
    (value: number) => trackCategoryFilterUsed(categories.find((c) => c.categoryId === value)?.category || 'all'),
    [categories]
  );

  const trackSubject = useCallback(
    (value: number) => trackSubjectFilterUsed(subjects?.find((s) => s.subjectId === value)?.subject || 'all'),
    [subjects]
  );
  const trackType = useCallback((value: string) => trackTypeFilterUsed(value), []);
  const trackDuration = useCallback((value: string) => trackDurationFilterUsed(value), []);
  const trackProvider = useCallback((value: string) => trackProviderFilterUsed(value), []);
  const debouncedTrackFreeTextFilterUsed = useDebouncedFunction(trackFreeTextFilterUsed, 500);
  const trackTitle = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => debouncedTrackFreeTextFilterUsed(e.currentTarget.value),
    []
  );
  const trackLanguage = useCallback((value: string) => trackLanguageFilterUsed(value), []);
  const trackAttributes = useCallback((value: string | number) => trackAttributeFilterUsed(value), []);
  const trackSort = useCallback((value: string) => trackSortingChanged(value), []);
  return {
    trackCategory,
    trackSubject,
    trackType,
    trackDuration,
    trackProvider,
    trackTitle,
    trackLanguage,
    trackAttributes,
    trackSort,
  };
}
