import { ToggleButton } from '@/ui/Button';
import { Icon, IconPropType } from '@/ui/Icon';

interface CommonToolbarButtonProps {
  toggled?: boolean;
  onClick: VoidFunction;
  icon: IconPropType;
  onMouseDown?: VoidFunction;
}

export const CommonToolbarButton = ({ toggled, onClick, icon, onMouseDown }: CommonToolbarButtonProps): JSX.Element => {
  return (
    <ToggleButton variant="default" size="small" toggled={toggled} onClick={onClick} onMouseDown={onMouseDown}>
      <Icon size="xs" icon={icon} />
    </ToggleButton>
  );
};
