import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { NavLink } from 'react-router-dom';

import { deviceUp } from '@/styles/utils';
import { Split } from '../Layout/Split';
import { CssColorVariable, CssFontWeightVariable, cvar } from '@/styles/GlobalStyle';

const HeaderBase = styled.div<{ color?: CssColorVariable; unpadded?: boolean }>`
  color: ${({ color }) => cvar(color || 'color-black')};
  font-weight: ${cvar('font-weight-bold')};
  padding-bottom: ${cvar('gap-sm')};
  ${({ unpadded }) => (unpadded ? '&& {padding-bottom: 0;}' : null)}
  ${Split} && {
    padding-bottom: 0;
  }
`;

export const Header1 = styled(HeaderBase).attrs((props) => ({ ...props, as: 'h1' }))`
  font-size: ${cvar('font-size-2')};
  letter-spacing: -0.2px;
  line-height: 1.25;
  ${deviceUp.md`
    padding-bottom: ${cvar('gap-sm')};
  `}
  ${deviceUp.lg`
    font-size: ${cvar('font-size-1')};
    padding-bottom: ${cvar('gap-md')};
  `}
`;

export const Header2 = styled(HeaderBase).attrs((props) => ({ ...props, as: 'h2' }))`
  font-size: ${cvar('font-size-3')};
  letter-spacing: -0.15px;
  line-height: 1.333;
  padding-bottom: ${({ unpadded }) => (unpadded ? '0;' : 'var(--gap-xs);')};
  ${deviceUp.md`
    padding-bottom: ${cvar('gap-md')};
  `}
  ${deviceUp.lg`
    font-size: ${cvar('font-size-2')};
  `}
`;

export const Header3 = styled(HeaderBase).attrs((props) => ({ ...props, as: 'h3' }))`
  font-size: ${cvar('font-size-4')};
  letter-spacing: -0.1px;
  line-height: 1.2;
  padding-bottom: ${cvar('gap-sm')};
`;

export const Header4 = styled(HeaderBase).attrs((props) => ({ ...props, as: 'h4' }))`
  font-size: ${cvar('font-size-5')};
  letter-spacing: -0.1px;
  line-height: 1.333;
  padding-bottom: ${({ unpadded }) => (unpadded ? '0;' : 'var(--gap-xs);')};
`;

export const Preamble = styled.p<{ color?: CssColorVariable; unpadded?: boolean }>`
  color: ${({ color }) => cvar(color || 'color-black')};
  font-size: var(--font-size-4);
  line-height: 1.5;
  padding-bottom: ${({ unpadded }) => (unpadded ? '0;' : 'var(--gap-sm);')};
`;

export const BodyLg = styled.p<{
  gridArea?: string;
  color?: CssColorVariable;
  noMargin?: boolean;
  fontWeight?: CssFontWeightVariable;
}>`
  color: ${({ color }) => cvar(color || 'color-text')};
  font-size: ${cvar('font-size-5')};
  line-height: 1.556;
  ${({ noMargin }) => noMargin && `margin: 0;`}
  ${({ gridArea }) => gridArea && `grid-area: ${gridArea};`}
  ${({ fontWeight }) => fontWeight && `font-weight: ${cvar(fontWeight)};`}
`;

export const BodyMd = styled.p<{
  gridArea?: string;
  color?: CssColorVariable;
  noMargin?: boolean;
  fontWeight?: CssFontWeightVariable;
}>`
  color: ${({ color }) => cvar(color || 'color-text')};
  font-size: ${cvar('font-size-6')};
  line-height: 1.6;
  ${({ noMargin }) => noMargin && `margin: 0;`}
  ${({ gridArea }) => gridArea && `grid-area: ${gridArea};`}
  ${({ fontWeight }) => fontWeight && `font-weight: ${cvar(fontWeight)};`}
`;

export const BodyXs = styled.p<{
  gridArea?: string;
  color?: CssColorVariable;
  noMargin?: boolean;
  fontWeight?: CssFontWeightVariable;
}>`
  color: ${({ color }) => cvar(color || 'color-text')};
  font-size: ${cvar('font-size-8')};
  line-height: 1.3;
  ${({ noMargin }) => noMargin && `margin: 0;`}
  ${({ gridArea }) => gridArea && `grid-area: ${gridArea};`}
  ${({ fontWeight }) => fontWeight && `font-weight: ${cvar(fontWeight)};`}
`;

export const OverlineMixin = ({ color }: { color?: CssColorVariable } = {}): FlattenSimpleInterpolation => css`
  color: ${cvar(color || 'color-overline')};
  font-size: ${cvar('font-size-8')};
  font-weight: ${cvar('font-weight-bold-extra')};
  letter-spacing: 0.2px;
  line-height: 2.182;
  text-transform: uppercase;
`;

export const Overline = styled.p<{
  color?: CssColorVariable;
  subLevel?: boolean;
}>`
  ${({ color }) => OverlineMixin({ color })}
  ${({ subLevel }) =>
    subLevel &&
    `
    color: ${cvar('color-gray')};
    font-size: ${cvar('font-size-9')};
    font-weight: ${cvar('font-weight-bold')};
  `};
`;

export const OverlineV2 = styled.p<{
  color?: CssColorVariable;
  subLevel?: boolean;
}>`
  ${({ color }) => OverlineMixin({ color })}
  ${({ subLevel }) =>
    subLevel &&
    `
    color: ${cvar('color-gray')};
    font-size: ${cvar('font-size-9')};
    font-weight: ${cvar('font-weight-bold')};
  `};
`;

export const Caption = styled.p`
  color: ${cvar('color-text')};
  font-size: ${cvar('font-size-7')};
  line-height: 1.69;
`;

export const Paragraph = styled.div<{ largeSpacing?: boolean }>`
  &:not(:last-child) {
    padding-bottom: var(${({ largeSpacing }) => (largeSpacing ? '--gap-md' : '--gap-xs')});
  }
`;

export const StyledLink = styled(NavLink)<{ isActive?: boolean; disabled?: boolean }>`
  text-decoration: underline;
  ${({ isActive, disabled }) => {
    let styles = isActive
      ? `
        color: var(--color-black);
        :hover {
          color: var(--color-dove-gray);
        }
      `
      : `
        color: var(--color-dove-gray);
        :hover {
          color: var(--color-black);
        }
      `;
    if (disabled) {
      styles += `
        cursor: pointer;
        pointer-events: none;
      `;
    }
    return styles;
  }}
`;
