import styled, { useTheme } from 'styled-components';
import { useMemo } from 'react';

import { RAttendedType } from '@/store/v2';
import { Icon, IconPropType } from '@/ui/Icon';
import { Divider } from '@/features/course/admin/molecules/CourseSettingsModalForm/CourseSettingsModalForm';
import { LiveEvent } from '@/pages/LiveEvent/store/liveEvent/types';

type LiveEventAttendanceStatusProps = Pick<LiveEvent, 'myEnrollment'>;

const StatusContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const StatusTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StatusTitle = styled.div`
  ${({ theme }) => theme.typography.subheadline};
  color: ${({ theme }) => theme.colors.neutral.onBackground};
`;

const StatusSubtitle = styled.div`
  ${({ theme }) => theme.typography.body3};
  color: ${({ theme }) => theme.colors.neutral.outline2};
`;

const StyledIcon = styled(Icon)`
  padding: 4px;
`;

const StyledDivider = styled(Divider)`
  width: 100%;
  margin: 32px 0;
`;

export const LiveEventAttendanceStatus = ({ myEnrollment }: LiveEventAttendanceStatusProps): JSX.Element | null => {
  const theme = useTheme();

  const renderData = useMemo(() => {
    switch (myEnrollment?.attended) {
      case RAttendedType.Attended: {
        return {
          iconPath: ['far', 'circle-check'],
          title: 'Attended',
          subtitle: 'An admin has confirmed that you attended the event.',
        };
      }
      case RAttendedType.NotAttended: {
        return {
          iconPath: ['far', 'circle-xmark'],
          title: 'Didn’t attend',
          subtitle: 'An admin has marked that you didn’t attend the event.',
        };
      }
      case RAttendedType.Enrolled: {
        return {
          iconPath: 'hourglass-clock',
          title: 'Attendance pending',
          subtitle: 'Your attendance is pending confirmation by an admin.',
        };
      }
      default: {
        return { iconPath: '', subtitle: '', title: '' };
      }
    }
  }, [myEnrollment]);

  if (!renderData?.title || !renderData?.subtitle || !renderData?.iconPath) {
    return null;
  }

  return (
    <>
      <StatusContainer>
        <StyledIcon icon={renderData?.iconPath as IconPropType} color={theme.colors.neutral.onContainer} size="lg" />
        <StatusTextContainer>
          <StatusTitle>{renderData?.title}</StatusTitle>
          <StatusSubtitle>{renderData?.subtitle}</StatusSubtitle>
        </StatusTextContainer>
      </StatusContainer>
      <StyledDivider />
    </>
  );
};
