import { Editor } from 'slate';
import { ReactEditor } from 'slate-react';
import isUrl from 'is-url';

import { insertLink } from '../logic/links';

export const withLinks = (editor: Editor & ReactEditor): Editor & ReactEditor => {
  const { insertText, isInline } = editor;

  editor.isInline = (element) => {
    return element.type === 'link' ? true : isInline(element);
  };

  editor.insertText = (text: string): void => {
    if (text && isUrl(text)) {
      insertLink(editor, text);
    } else {
      insertText(text);
    }
  };

  return editor;
};
