import styled from 'styled-components';

import { Avatar } from '@/ui/Avatar';
import { shortenName } from '@/utils/text';

const RootStyled = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const NameStyled = styled.div`
  ${({ theme }) => theme.typography.body3};
  color: ${({ theme }) => theme.colors.neutral.onBackground};
`;

const EmailStyled = styled.div`
  ${({ theme }) => theme.typography.caption};
  color: ${({ theme }) => theme.colors.neutral.outline2};
`;

export const CourseSettingsModalFieldContentOwnerRow = ({
  firstname,
  lastname,
  profileImage,
  email,
}: {
  firstname?: string;
  lastname?: string;
  profileImage?: string;
  email: string;
}): JSX.Element => {
  const username = `${firstname || ''} ${lastname || ''}`;
  const shortName = shortenName(username || '');

  return (
    <RootStyled>
      <Avatar size="small" text={shortName} image={profileImage} />
      <div>
        <NameStyled>{username}</NameStyled>
        <EmailStyled>{email}</EmailStyled>
      </div>
    </RootStyled>
  );
};
