import React from 'react';
import styled from 'styled-components';
import _isString from 'lodash/isString';

import { ColumnDeclaration, TableDeclaration } from '@/components/Table';

const Col = styled.col<{ width?: string }>`
  ${({ width }) => (_isString(width) ? `width: ${width};` : ``)};
`;

interface Props<T> {
  tableDecl: TableDeclaration<T>;
}

export function AutoColGroup<T>({ tableDecl }: Props<T>): JSX.Element | null {
  if (!tableDecl.some((colDec) => colDec?.columnStyle?.width)) {
    return null;
  }

  return (
    <colgroup>
      {tableDecl.map((colDecl: ColumnDeclaration<T>, index: number) => (
        <Col key={`col-${index}`} width={colDecl.columnStyle?.width} />
      ))}
    </colgroup>
  );
}
