/* eslint-disable import/no-unused-modules */
import { RLearningActivityType } from '@/store/v2';
import { ActivityId } from '../../../../administration/pages/Course/store/types';

export type ActivityContent = {
  title: string;
  text?: string;
  version?: string;
  description?: string;
  sectionPath?: string[];
  postId?: number;
  level?: string;
  effortHours?: number;
  effortMinutes?: number;
};

export enum ActivityType {
  Module = 'MODULE',
  PracticalAssessmentTask = 'PRACTICAL_ASSESSMENT_TASK',
  Question = 'QUESTION',
  Reflection = 'REFLECTION',
  Page = 'PAGE',
  Reaction = 'REACTION',
  Result = 'RESULT',
  Quiz = 'QUIZ',
}

export type Activity = {
  id: ActivityId;
  remoteId?: number;
  remoteParentActivityId?: number;
  parentActivityId?: string;
  order: number;
  type: RLearningActivityType;
  content: ActivityContent;

  collapsed?: boolean;
  active?: boolean;
  hasError?: boolean;
  completed?: boolean;
};

export type TreeActivity = Activity & { children?: Activity[] };
