import styled, { useTheme } from 'styled-components';
import React from 'react';
import { useParams } from 'react-router-dom';

import { LiveEventDescription } from '@/pages/LiveEvent/LiveEventDescription';
import { LiveEventHighlights } from '@/pages/LiveEvent/LiveEventHighlights';
import { Hero } from '@/administration/pages/Journey/pages/EditJourney/atoms/Hero';
import { Layout } from '@/ui/Layout';
import { LiveEvent } from '@/pages/LiveEvent/store/liveEvent/types';
import { SaveToMyLearning } from '@/pages/user/MyLearning/components/SaveToMyLearning';
import { BackToJourney } from '@/pages/LiveEvent/BackToJourney';
import { Icon, IconPropType } from '@/ui/Icon';
import { useJourneyEnhancedNavigation } from '@/store/v2/journeys/useJourneyEnhancedNavigation';
import { UpNextLearning } from '@/administration/pages/Journey/common/atoms/UpNextLearning';
import { LiveEventControlButtons } from '@/pages/LiveEvent/LiveEventControlButtons';
import { LiveEventAttendanceStatus } from '@/pages/LiveEvent/LiveEventAttendanceStatus';

const BackToParentButtonContainer = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 16px;
  min-height: 30px;
`;

const MainContent = styled.div`
  gap: 48px;
  width: 100%;
  margin: 40px 0 64px;
  display: flex;
  justify-content: space-between;
`;

const LeftContentColumn = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 800px;
  align-items: baseline;
  width: 100%;
`;

const EndedEventNotification = styled.div`
  background: ${({ theme }) => theme.colors.neutral.container};
  color: ${(p) => p.theme.colors.neutral.onBackground};
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px;
  margin-bottom: 40px;
  border-radius: 4px;
  width: 100%;
`;

const EndedEventNotificationLabel = styled.div`
  ${({ theme }) => theme.typography.body2};
`;

const RightContentColumn = styled.div`
  width: 100%;
  display: flex;
  max-width: 424px;
  flex-direction: column;
`;

const UpNextContainer = styled.div`
  width: 100%;
`;

export const LiveEventDetails = ({
  id,
  spaceId,
  image,
  title,
  locationId,
  dateAndTime,
  endDate,
  startDate,
  isOnline,
  address,
  myEnrollment,
  room,
  teaser,
  dayOfMonth,
  monthName,
  isStartingSoon,
  hasEnded,
  timezone,
  type,
}: LiveEvent): JSX.Element => {
  const theme = useTheme();
  const { id: courseId } = useParams<{ id: string }>();

  const parsedCourseId = courseId ? parseInt(courseId) : NaN;

  const { nextItem, journeyId } = useJourneyEnhancedNavigation({
    id: parsedCourseId,
  });

  return (
    <Layout.ContentSection marginTop>
      <BackToParentButtonContainer>
        <BackToJourney />
        <LiveEventControlButtons
          id={id}
          image={image}
          spaceId={spaceId}
          locationId={locationId}
          title={title}
          teaser={teaser}
          startDate={startDate}
          endDate={endDate}
          type={type}
          address={address}
          timezone={timezone}
        />
      </BackToParentButtonContainer>
      <Hero
        title={title}
        actions={
          <SaveToMyLearning
            isEvent
            item={{ entity: 'learning', id: Number(id) }}
            minimized
            saveNotificationLabel="Live event saved to My Learning"
            deleteNotificationLabel="Live event removed from My Learning"
          />
        }
        subtitle="Live event"
        withOverlay
        imageUrl={image}
      />
      <MainContent>
        <LeftContentColumn>
          {hasEnded && (
            <>
              <EndedEventNotification>
                <Icon icon={'calendar-exclamation' as IconPropType} color={theme.colors.neutral.onContainer} />
                <EndedEventNotificationLabel>This event has ended.</EndedEventNotificationLabel>
              </EndedEventNotification>
              <LiveEventAttendanceStatus myEnrollment={myEnrollment} />
            </>
          )}
          <LiveEventDescription
            id={id}
            room={room}
            title={title}
            teaser={teaser}
            endDate={endDate}
            address={address}
            isOnline={isOnline}
            startDate={startDate}
            dateAndTime={dateAndTime}
            myEnrollment={myEnrollment}
            hasEnded={hasEnded}
          />
        </LeftContentColumn>
        <RightContentColumn>
          {!hasEnded && (
            <LiveEventHighlights
              id={id}
              room={room}
              title={title}
              teaser={teaser}
              endDate={endDate}
              address={address}
              isOnline={isOnline}
              monthName={monthName}
              startDate={startDate}
              dayOfMonth={dayOfMonth}
              dateAndTime={dateAndTime}
              myEnrollment={myEnrollment}
              isStartingSoon={isStartingSoon}
            />
          )}
          {hasEnded && !!nextItem && (
            <UpNextContainer>
              <UpNextLearning
                learningCardProps={{
                  objectId: nextItem.contentId,
                  title: nextItem.content?.title,
                  imageUrl: nextItem.content?.imageURL,
                  type: nextItem.content?.metadata?.type,
                  level: nextItem.content?.metadata?.level,
                  totalEffort: nextItem.content?.plannedDuration,
                }}
                isActionActive
                actionHref={`${nextItem.content?.curations[0]?.launchURLPath}}`}
                parentJourneyId={journeyId}
              />
            </UpNextContainer>
          )}
        </RightContentColumn>
      </MainContent>
    </Layout.ContentSection>
  );
};
