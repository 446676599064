import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { useSpaceMembersQueryRemote } from '@/store/v2';
import { useDebouncedSearchPhrase } from '@/features/course/admin/hooks/useDebouncedSearchPhrase';
import { SelectedUser } from '@/features/course/admin/atoms/CourseSettingsModalFieldSelectedUserChip/CourseSettingsModalFieldSelectedUserChip';
import {
  ConnectionUserRoleAction,
  CourseSettingsModalFieldConnectionsRowProps,
} from '@/features/course/admin/molecules/CourseSettingsModalFieldConnectionsRow/CourseSettingsModalFieldConnectionsRow';

const SPACE_MEMEBERS_FETCH_LIMIT = 50;

export const useCourseSettingsModalFieldConnectionState = ({
  spaceId,
  selectedUserRolesConnections,
  label,
  onUsersRoleChange,
}: CourseSettingsModalFieldConnectionsRowProps): {
  popoverOpen: boolean;
  selectedUsers: SelectedUser[];
  notSelectedUsers: SelectedUser[] | undefined;
  loadingSearchedUsers: boolean;
  loadingSavedUsers: boolean;
  searchPhrase: string;
  setPopoverOpen: Dispatch<SetStateAction<boolean>>;
  onRemoveUser: (userId: number) => void;
  onSearchPhraseChange: (value: string) => void;
  onAddUser: (user: SelectedUser) => void;
} => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const { searchPhrase, debouncedSearchPhrase, onSearchPhraseChange } = useDebouncedSearchPhrase();

  const [selectedUsers, setSelectedUsers] = useState<SelectedUser[]>([]);

  const searchPhraseTrimmed = debouncedSearchPhrase.trim();

  const { data: users, loading: loadingSearchedUsers } = useSpaceMembersQueryRemote({
    skip: !spaceId,
    variables: {
      filter: { spaceIdIN: [spaceId || 0], searchPhrase: searchPhraseTrimmed || undefined },
      pagination: { limit: SPACE_MEMEBERS_FETCH_LIMIT, page: 1 },
    },
  });

  const selectedUserRolesConnectionsForCurrentRole = selectedUserRolesConnections.filter((i) => i.role === label);
  const selectedUserRolesConnectionsForCurrentRoleUserIds = selectedUserRolesConnectionsForCurrentRole.map((i) =>
    Number(i.userId)
  );

  const { data: savedUsersRollesConnectionsData, loading: loadingSavedUsers } = useSpaceMembersQueryRemote({
    skip: !selectedUserRolesConnectionsForCurrentRole?.length,
    variables: {
      filter: {
        spaceIdIN: [spaceId || 0],
        idIN: selectedUserRolesConnectionsForCurrentRole.length ? selectedUserRolesConnectionsForCurrentRoleUserIds : undefined,
      },
      pagination: { limit: -1, page: 1 },
    },
  });

  const savedUsersRolesConnections = savedUsersRollesConnectionsData?.spaceMembers?.data;

  useEffect(() => {
    if (!savedUsersRolesConnections?.length) return;

    setSelectedUsers(savedUsersRolesConnections);
  }, [savedUsersRolesConnections?.length]);

  const onRemoveUser = (userId: number) => {
    onUsersRoleChange({
      userId,
      action: ConnectionUserRoleAction.Remove,
    });

    setSelectedUsers((prev) => prev.filter((user) => user.id !== userId));
  };

  const onAddUser = (user: SelectedUser) => {
    onUsersRoleChange({
      userId: user.id,
      action: ConnectionUserRoleAction.Add,
    });

    setSelectedUsers((prev) => [
      ...prev,
      {
        ...user,
      },
    ]);
  };

  const notSelectedUsers = users?.spaceMembers.data?.filter(
    (iteratedUser) => !selectedUsers.map((u) => u.id).includes(iteratedUser.id)
  );

  return {
    popoverOpen,
    selectedUsers,
    loadingSearchedUsers,
    loadingSavedUsers,
    searchPhrase,
    notSelectedUsers,
    setPopoverOpen,
    onRemoveUser,
    onSearchPhraseChange,
    onAddUser,
  };
};
