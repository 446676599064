import React, { useContext } from 'react';
import { withRef } from '@udecode/cn';
import { findNodePath, useEditorRef, useRemoveNodeButton, withHOC } from '@udecode/plate-common/react';
import { setNodes } from '@udecode/plate-common';
import { ResizableProvider } from '@udecode/plate-resizable';
import styled, { css } from 'styled-components';
import { useSelected } from 'slate-react';

import { useFileUploader } from '@/component/FileUpload/useFileUpload';
import { BaseDocument } from '@/component/customEditorV2/ui/elements/DocumentElement/BaseDocument';
import { PlateElement } from '@/component/customEditorV2/ui/elements/PlateElement';
import { Resizable, ResizeHandle } from '@/component/customEditorV2/ui/elements/Resizable';
import { CustomEditorContext } from '@/component/customEditorV2/store/CustomEditorContext';

const DocumentStyled = styled.div<{ $selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  cursor: pointer;
  object-fit: cover;
  padding-right: 0;
  padding-left: 0;
  border-radius: 14px;

  ${({ $selected }) =>
    $selected &&
    css`
      border: 2px solid ${({ theme }) => theme.colors.primary.outline};
    `};
`;

export const DocumentElement = withHOC(
  ResizableProvider,
  withRef<typeof PlateElement>(({ className, children, nodeProps, ...props }, ref) => {
    const editor = useEditorRef();
    const selected = useSelected();
    const { spaceId } = useContext(CustomEditorContext);
    const removeNodeButtonProps = useRemoveNodeButton({ element: props.element })?.props;
    const documentUpload = useFileUploader({
      spaceId,
      type: 'document',

      onUploadSuccess: ({ url, name }) => {
        const path = findNodePath(editor, props.element);

        setNodes(
          editor,
          { documentUrl: url, documentName: name },
          {
            at: path,
          }
        );
      },
    });

    const handleRemoveDocument = () => {
      removeNodeButtonProps.onClick();
    };

    const documentUrl = typeof props.element.documentUrl === 'string' ? props.element.documentUrl : undefined;

    const canBeResized = documentUrl && !documentUpload.uploading;

    const renderDocument = () => (
      <DocumentStyled $selected={selected} {...nodeProps}>
        <BaseDocument
          onUpload={documentUpload.uploadFile}
          uploading={documentUpload.uploading}
          url={documentUrl}
          documentName={typeof props.element.documentName === 'string' ? props.element.documentName : undefined}
          uploadingProgress={documentUpload.uploadingProgress}
          onRemoveElement={handleRemoveDocument}
          selected={selected}
        />
      </DocumentStyled>
    );

    return (
      <PlateElement ref={ref} className={className} {...props}>
        <figure contentEditable={false}>
          {canBeResized ? (
            <Resizable
              $align="center"
              options={{
                align: 'center',
              }}
            >
              {selected && <ResizeHandle options={{ direction: 'left' }} />}
              {renderDocument()}
              {selected && <ResizeHandle options={{ direction: 'right' }} />}
            </Resizable>
          ) : (
            <>{renderDocument()}</>
          )}
        </figure>
        {children}
      </PlateElement>
    );
  })
);
