import styled from 'styled-components';
import { PlateElementProps } from '@udecode/plate-common/react';
import { useContext, useEffect } from 'react';

import { PlateElement } from '@/component/customEditorV2/ui/elements/PlateElement';
import { AnswerItem, AnswerStates } from '@/component/customEditorV2/ui/elements/QuizQuestionElement/View/AnswerItem';
import { QuestionAnswer } from '@/component/customEditorV2/ui/elements/QuizQuestionElement/Edit/QuestionAnswers';
import { SelectChipOption } from '@/ui/Chip/variants/SelectChip';
import { QUESTION_TYPES } from '@/component/customEditorV2/ui/elements/QuizQuestionElement/Edit/QuestionTypeChip';
import { QuizViewContext } from '@/component/customEditorV2/ui/elements/QuizQuestionElement/View/QuizViewContext';

const QuestionWrapperStyled = styled.div`
  width: 100%;
  margin: 16px 0;
  display: flex;
  flex-direction: column;
`;

const QuestionTitleStyled = styled.div`
  ${({ theme }) => theme.typography.title3};
  color: ${({ theme }) => theme.colors.neutral.onBackground};
  margin-bottom: 24px;
`;

const QuestionTypeStyled = styled.div`
  ${({ theme }) => theme.typography.body4};
  color: ${({ theme }) => theme.colors.neutral.onBackgroundVariant};
  margin-bottom: 16px;
`;

const AnswersListStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

type BlockElementType = {
  id: string;
  label?: string;
  title?: string;
  answers?: QuestionAnswer[];
  questionType: SelectChipOption;
};

export const QuizQuestionViewElement = (props: PlateElementProps): JSX.Element => {
  const { setAnsweredQuestions, answeredQuestions, submitted } = useContext(QuizViewContext);

  const blockElement = props?.element as unknown as BlockElementType;

  const isMultiSelect = blockElement?.questionType?.value === QUESTION_TYPES.MULTI_SELECT;

  const currentQuestion = answeredQuestions.find((question) => question.questionId === blockElement.id);

  /**
   * Filling all questions with empty answers.
   * It's needed to be able to run validation over answered / not answered questions
   */
  useEffect(() => {
    // If the current question is not presented in the context yet
    if (!currentQuestion) {
      setAnsweredQuestions((prev) => {
        return [
          ...prev,
          // we need to add it to the list
          {
            questionId: blockElement.id,
            answersIds: new Set([]),
          },
        ];
      });
    }
  }, [currentQuestion, answeredQuestions]);

  const handleQuestionAnswered = ({ answerId }: { answerId: string }) => {
    if (submitted || !currentQuestion) return;

    setAnsweredQuestions((prev) =>
      prev.map((question) => {
        if (question.questionId !== currentQuestion.questionId) return question;

        if (isMultiSelect && question.answersIds.has(answerId)) {
          question.answersIds.delete(answerId);

          return {
            ...question,
          };
        }

        return {
          ...question,
          answersIds: isMultiSelect ? question.answersIds.add(answerId) : new Set([answerId]),
        };
      })
    );
  };

  const getAnswerStateBeforeSubmit = ({ answerId }: { answerId?: string }): AnswerStates => {
    if (answerId && currentQuestion?.answersIds?.has(answerId)) {
      return AnswerStates.SELECTED;
    }

    return AnswerStates.UNSELECTED;
  };

  const getAnswerStateAfterSubmit = ({ answerId }: { answerId?: string }): AnswerStates => {
    const targetAnswer = blockElement?.answers?.find((answer) => answer.id === answerId);

    if (!currentQuestion || !answerId || !targetAnswer || !currentQuestion.answersIds.has(answerId)) {
      return AnswerStates.DISABLED;
    }

    const isAnswerCorrect = targetAnswer.isCorrect && currentQuestion.answersIds.has(answerId);

    return isAnswerCorrect ? AnswerStates.CORRECT : AnswerStates.INCORRECT;
  };

  return (
    <PlateElement asChild {...props} className={props.className}>
      <figure contentEditable={false}>
        <QuestionWrapperStyled>
          <QuestionTitleStyled>{blockElement?.title}</QuestionTitleStyled>
          <QuestionTypeStyled>{blockElement?.questionType?.label}</QuestionTypeStyled>
          <AnswersListStyled>
            {blockElement.answers?.map((answerItem) => {
              return (
                <AnswerItem
                  key={answerItem?.id}
                  state={
                    submitted
                      ? getAnswerStateAfterSubmit({ answerId: answerItem?.id })
                      : getAnswerStateBeforeSubmit({ answerId: answerItem?.id })
                  }
                  label={answerItem?.label}
                  isCorrect={answerItem.isCorrect}
                  isMultiSelect={isMultiSelect}
                  correctnessBadgeShown={Boolean(
                    submitted && (answerItem.isCorrect || currentQuestion?.answersIds.has(answerItem?.id || ''))
                  )}
                  onSelect={() => answerItem?.id && handleQuestionAnswered({ answerId: answerItem?.id })}
                />
              );
            })}
          </AnswersListStyled>
        </QuestionWrapperStyled>
      </figure>
    </PlateElement>
  );
};
