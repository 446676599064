import { useEffect, useRef } from 'react';
import { parse } from 'qs';
import { useLocation } from 'react-router-dom';

import { usePost } from '../store/discussion';
import { Reflection } from '../component/Discussions/Reflection';

export const ReflectionInjection = (): JSX.Element => {
  const location = useLocation();
  const { link } = parse(location.search.replace(/^\?/, ''));
  const path = link.replace(/^\//, '').split('/');
  const [postId] = path.splice(-1);

  const el = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const node = el.current;

    if (node) {
      const observer = new MutationObserver(function () {
        window.parent.postMessage(`${node.scrollHeight}px`, '*');
      });

      observer.observe(node, {
        subtree: true,
        childList: true,
      });

      return () => {
        observer.disconnect();
      };
    }
  }, []);

  const { post: reflection, loading } = usePost(path, +postId);

  document.body.style.backgroundColor = 'white';
  document.documentElement.style.overflowY = 'auto';

  if (!loading && postId && !reflection?.id) {
    console.info(`Reflection with ID: ${postId} at path ${path} has not been published`);
  }
  return (
    <div ref={el}>{reflection?.id && <Reflection reflection={reflection} isLearnifier={true} selected={true} path={path} />}</div>
  );
};
