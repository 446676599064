import { RLearningActivityType } from '@/store/v2';
import { ActivityType, Activity } from '@/features/course/admin/types/activity';

export const getAreAllActivitiesCompleted = (activities: Activity[]): boolean => {
  return (
    activities
      // we should filter out for modules and result
      .filter(
        (activity) =>
          ![ActivityType.Result as unknown as RLearningActivityType, RLearningActivityType.Module].includes(activity.type)
      )
      .every((activity) => activity.completed)
  );
};
