import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import image from './image.svg';
import { Wrapper, Heading, Subheading } from '../styles';
import { Button } from '@/ui/Button';

const Graphics = styled.img.attrs({ src: image })``;

const ClearButton = styled(Button).attrs({ variant: 'primary', size: 'large' })`
  margin-top: 32px;
`;

type EmptyStateAfterProcessedProps = {
  onFiltersClear: () => void;
};

export const EmptyStateAfterProcessed = ({ onFiltersClear }: EmptyStateAfterProcessedProps): JSX.Element => {
  const { t } = useTranslation('managerDashboard');

  return (
    <Wrapper>
      <Graphics />
      <Heading>{t('empty-state-after-processed-title')}</Heading>
      <Subheading>{t('empty-state-after-processed-subtitle')}</Subheading>
      <ClearButton onClick={onFiltersClear}>{t('empty-state-clear-filters-action')}</ClearButton>
    </Wrapper>
  );
};
