import { Navigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Fuse from 'fuse.js';

import { useCardCatalog, CARD_SORTING_METHODS } from '@/store/catalog';
import { Hero, Section } from '@/components/Section/Section';
import { CardGrid } from '@/components/CardGrid/CardGrid';
import { Header1 } from '@/components/Typography/Typography';
import { fuzzyOptions } from './fuseOptions';
import { CARDFILTER_PAGETYPE } from '@/components/CardGrid/CardFilter';
import { CatalogSortingEnum } from '@/types/CatalogSortingEnum';
import { useFilterPastEvents } from '@/hooks/useFilterPastEvents';

export enum QueryParamKeys {
  q = 'q',
}

export type QueryParams = {
  q: string;
};

export const SearchPage = (): JSX.Element => {
  const location = useLocation();
  const { t } = useTranslation('search');
  const { catalog, loading } = useCardCatalog();
  const searchParams = new URLSearchParams(location.search);
  const searchStr = searchParams.get(QueryParamKeys.q)?.toLocaleLowerCase();
  const cards = useFilterPastEvents(catalog.cards).filter((card) => card.available);

  if (!searchStr || searchStr.length < 2) return <Navigate to="/learning" replace />;

  const fuse = new Fuse(cards, fuzzyOptions);
  const filteredCards = searchStr ? fuse.search(searchStr).map(({ item }) => item) : [];

  return (
    <>
      <Hero paddedBottom>
        <Header1 unpadded>{t('search-result-for', { searchStr })}</Header1>
      </Hero>
      <Section>
        <CardGrid
          // we want to keep the original order, since it's already sorted by relevance
          sortFunction={CARD_SORTING_METHODS[CatalogSortingEnum.NONE]}
          pageType={CARDFILTER_PAGETYPE.SEARCH}
          cards={filteredCards}
          loading={loading}
          filters={['meta', 'taxonomy', 'attributes']}
        />
      </Section>
    </>
  );
};
