import styled, { css } from 'styled-components';

import { DrawerItemTypeV2 } from '@/administration/pages/Course/MainLayoutView/types';
import { ActivityType } from '@/features/course/admin/types/activity';
import { Icon } from '@/ui/Icon';

const HoverCSS = css<{ active?: boolean }>`
  background: ${({ theme, active }) => !active && theme.colors.states.hover5};

  & [data-icon='trash'] {
    opacity: 1;
  }
`;

const ActiveCSS = css`
  background: ${({ theme }) =>
    `linear-gradient(0deg, ${theme.colors.states.hover6} 0%, ${theme.colors.states.hover6} 100%), ${theme.tones.neutral[99]};`};
`;

const ChildCSS = css`
  padding-left: 32px;
`;

export const RootStyled = styled.div<{ active?: boolean; isChild?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  border-radius: 4px;
  transition: all 0.2s;
  gap: 8px;
  margin-top: 4px;
  min-height: 68px;

  & [data-icon='trash'] {
    opacity: 0;
  }

  &:hover {
    ${HoverCSS}
  }

  ${({ active }) => active && ActiveCSS};

  ${({ isChild }) => isChild && ChildCSS};

  &:active {
    ${ActiveCSS}
  }
`;

export const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.typography.title4};
  color: ${({ theme }) => theme.colors.neutral.onBackground};
  gap: 8px;
  overflow: hidden;
`;

export const TitleText = styled.div`
  display: block;
`;

export const IconWrapStyled = styled.div<{ type: DrawerItemTypeV2 }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme, type }) => {
    switch (type) {
      case 'completed':
        return `none`;
      case ActivityType.Page:
        return theme.colors.tertiary.container;
      case ActivityType.Question:
        return theme.colors.tertiary.main;
      case ActivityType.PracticalAssessmentTask:
        return theme.colors.neutral.background;
      case ActivityType.Reaction:
        return theme.colors.secondary.outline;
      case ActivityType.Reflection:
        return theme.colors.secondary.container;
      case ActivityType.Module:
        return theme.tones.neutral[100];
      case ActivityType.Quiz:
        return '#FFC3E2';
      case ActivityType.Result:
      default:
        return theme.colors.primary.container;
    }
  }};
  color: ${({ theme, type }) => {
    switch (type) {
      case ActivityType.Question:
        return theme.colors.primary.onMain;
      default:
        return theme.colors.neutral.onBackground;
    }
  }};
  flex: 1 0 24px;
  width: 24px;
  height: 24px;
  border-radius: 4px;
`;

export const StyledCollapseIcon = styled(Icon)`
  cursor: pointer;
`;
