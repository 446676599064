/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useState, useRef, useEffect } from 'react';

import {
  useReportingLearningJourneysItemsLazyQueryRemote,
  useReportingLearningJourneysItemsInsightsLazyQueryRemote,
  useReportingLearningJourneysItemsTotalCountLazyQueryRemote,
  RLearning_Journeys_Items_Insights_Order_By,
} from '@/store/v2';
import { SortBy } from '@/features/journey/admin/hooks/useLearningItemsColumnsDeclaration';

type InitialLearningItem = {
  urn: string;
  title: string;
  type: string;
  partner: string;
};

type InsightLearningItem = {
  urn: string;
  not_started_count?: number | null;
  in_progress_count?: number | null;
  completed_count?: number | null;
  completion_rate?: number | null;
};

export type LearningItemType = {
  urn: string;
  title: string;
  type: string;
  partner: string;
  not_started_count?: number | null;
  in_progress_count?: number | null;
  completed_count?: number | null;
  completion_rate?: number | null;
};

enum RootQuery {
  ITEMS = 'items',
  INSIGHTS = 'insights',
}

type MergeLazyLearningItemsProps = {
  initialData?: InitialLearningItem[] | null;
  insightsData?: InsightLearningItem[] | null;
  rootQuery: RootQuery;
};

type UseLazyLearningItemsProps = {
  limit: number;
  offset: number;
  sortBy: SortBy;
  orderBy: RLearning_Journeys_Items_Insights_Order_By;
  args: {
    learning_journey_urn: string;
    team_ids?: number[];
    title?: string;
    type?: string;
  };
};

const mergeItemsWithInsights = ({ initialData, insightsData, rootQuery }: MergeLazyLearningItemsProps) => {
  const itemsWithCorrectOrder = (rootQuery === RootQuery.ITEMS ? initialData : insightsData) as LearningItemType[];
  const itemsToMerge = (rootQuery === RootQuery.ITEMS ? insightsData : initialData) as LearningItemType[];

  return itemsWithCorrectOrder.map((item) => {
    const urn = item?.urn;

    const itemToMerge =
      itemsToMerge?.find((mergeItem) => {
        return mergeItem?.urn === urn;
      }) || {};

    return {
      ...item,
      ...itemToMerge,
    } as LearningItemType;
  });
};

// If active sort field is one of these, we have to call insights query first because getLearningJourneyItems query
// doesn't contain these fields, therefore this query can't sort learning items list on these fields.
const INSIGHTS_SORT_FIELDS: SortBy[] = ['not_started_count', 'in_progress_count', 'completed_count', 'completion_rate'];

export const useLazyLearningItems = (variables: UseLazyLearningItemsProps) => {
  const latestRequestId = useRef(0);
  const rootQuery = INSIGHTS_SORT_FIELDS.includes(variables.sortBy) ? RootQuery.INSIGHTS : RootQuery.ITEMS;
  const [lazyLearningItems, setLazyLearningItems] = useState<LearningItemType[]>([]);
  const [totalCount, setTotalCount] = useState(null);
  const [loading, setLoading] = useState(false);
  const [getLearningJourneyItems, { error: learningJourneyItemsError }] = useReportingLearningJourneysItemsLazyQueryRemote();
  const [getLearningJourneyItemsInsights, { error: learningJourneyItemsInsightsError }] =
    useReportingLearningJourneysItemsInsightsLazyQueryRemote();
  const [getLearningJourneyItemsTotalCount, { error: learningJourneyItemsTotalCountError }] =
    useReportingLearningJourneysItemsTotalCountLazyQueryRemote();

  const fetchItemsInsightsFirst = async (currentRequestId: number) => {
    setLoading(true);

    const { data: insightsData } = await getLearningJourneyItemsInsights({
      variables,
    });

    if (latestRequestId.current !== currentRequestId) {
      return;
    }

    setLazyLearningItems(insightsData?.reportingLearningJourneysItemsInsights as LearningItemType[]);

    const learningUrnsToFetch = insightsData?.reportingLearningJourneysItemsInsights?.map((item) => {
      return item?.urn;
    });

    const { data: itemsData } = await getLearningJourneyItems({
      variables: {
        ...variables,
        args: {
          ...variables?.args,
          learning_urns: learningUrnsToFetch,
        },
        offset: 0,
        orderBy: undefined,
      },
    });

    if (latestRequestId.current !== currentRequestId) {
      return;
    }

    setLazyLearningItems(
      mergeItemsWithInsights({
        initialData: itemsData?.reportingLearningJourneysItems,
        insightsData: insightsData?.reportingLearningJourneysItemsInsights,
        rootQuery: RootQuery.INSIGHTS,
      }) as LearningItemType[]
    );

    await fetchTotalCount(currentRequestId);
  };

  const fetchItemsFirst = async (currentRequestId: number) => {
    setLoading(true);

    const { data: itemsData } = await getLearningJourneyItems({
      variables,
    });

    if (latestRequestId.current !== currentRequestId) {
      return;
    }

    setLazyLearningItems(itemsData?.reportingLearningJourneysItems as LearningItemType[]);

    const learningUrnsToFetch = itemsData?.reportingLearningJourneysItems?.map((item) => {
      return item?.urn;
    });

    const { data: insightsData } = await getLearningJourneyItemsInsights({
      variables: {
        ...variables,
        args: {
          ...variables?.args,
          learning_urns: learningUrnsToFetch,
        },
        offset: 0,
        orderBy: undefined,
      },
    });

    if (latestRequestId.current !== currentRequestId) {
      return;
    }

    setLazyLearningItems(
      mergeItemsWithInsights({
        initialData: itemsData?.reportingLearningJourneysItems,
        insightsData: insightsData?.reportingLearningJourneysItemsInsights,
        rootQuery: RootQuery.ITEMS,
      }) as LearningItemType[]
    );

    await fetchTotalCount(currentRequestId);
  };

  const fetchTotalCount = async (currentRequestId: number) => {
    const { data } = await getLearningJourneyItemsTotalCount({
      variables: {
        ...variables,
        offset: 0,
        orderBy: undefined,
      },
    });

    if (latestRequestId.current === currentRequestId) {
      setTotalCount(data?.reportingLearningJourneysItemsTotalCount?.[0]?.total_count || null);
    }

    setLoading(false);
  };

  useEffect(() => {
    const currentRequestId = latestRequestId.current + 1;
    latestRequestId.current = currentRequestId; // Update to track the current request

    const fetchData = async () => {
      try {
        if (rootQuery === RootQuery.ITEMS) {
          await fetchItemsFirst(currentRequestId);
        } else {
          await fetchItemsInsightsFirst(currentRequestId);
        }
      } catch (error) {
        console.error('Error fetching data', error);
      }
    };

    fetchData();

    // Cleanup: Reset state if component unmounts
    return () => {
      latestRequestId.current = 0; // Reset request tracking on unmount
    };
  }, [JSON.stringify(variables)]);

  return {
    lazyLearningItems,
    loading,
    error: learningJourneyItemsError || learningJourneyItemsInsightsError || learningJourneyItemsTotalCountError,
    totalCount,
  };
};
