import type { TLinkElement } from '@udecode/plate-link';
import { withRef } from '@udecode/cn';
import { useElement } from '@udecode/plate-common/react';
import { useLink } from '@udecode/plate-link/react';

import { PlateElement } from './PlateElement';
import { LinkPlateElement as Link } from '@/component/customEditorV2/ui/Typography';

export const LinkElement = withRef<typeof PlateElement>(({ children, className, ...props }, ref) => {
  const element = useElement<TLinkElement>();
  const { props: linkProps } = useLink({ element });

  return (
    <Link ref={ref} as="a" className={className} {...({ ...linkProps, target: '_blank' } as any)} {...props}>
      {children}
    </Link>
  );
});
