import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Composition } from 'atomic-layout';

import { supportUrl } from '@/config';
import { toHtml } from '@/component/customEditor/logic/serialization';
import { useProduct, usePurchaseProduct } from '@/store/learning-catalog';
import { ProviderAccessStatus, ProviderList } from '@/store/providers';
import { isRealm } from '@/utils/realms';
import { useProviderAccess } from '@/hooks/useProviderAccess';
import { ButtonList } from '@/components/Button/ButtonList';
import { Button } from '@/components/Button/Button';
import { LinkButton } from '@/components/Button/LinkButton';
import { useFeatureEnabled, FEATURE } from '@/feature-toggles';
import { BodyLg, Header1 } from '@/components/Typography/Typography';
import { ProviderLogo } from '@/components/ProviderLogo/ProviderLogo';
import { providerFormAreas, providerFormAreasLg } from './GeneralProvider';
import { PageLoader } from '@/components/Pageloader/Pageloader';

interface Props {
  accessProductId?: number;
}

enum PluralsightTitle {
  SEB = 'Pluralsight',
  ALL = 'Pluralsight',
}

const pluralsightTitle = isRealm('seb') ? PluralsightTitle.SEB : PluralsightTitle.ALL;

export const Pluralsight = ({ accessProductId }: Props): JSX.Element => {
  const provider = ProviderList.PLURALSIGHT;
  const providerStatus = useProviderAccess(provider);
  const { product: productPluralsight, loading: loadingPluralsightProduct } = useProduct(accessProductId?.toString());
  const [purchasePluralsightProduct, { loading: loadingPurchasePluralsight }] = usePurchaseProduct(accessProductId);
  const { t } = useTranslation('providers');
  const isPluralsightFeatureEnabled = useFeatureEnabled(FEATURE.PROVIDER_PLURALSIGHT);

  const techRoomDescription = (() => {
    if (!productPluralsight) return '';

    try {
      return toHtml(productPluralsight.meta.DESCRIPTION);
    } catch (e) {}

    return productPluralsight.meta.DESCRIPTION || '';
  })();

  if (loadingPluralsightProduct) return <PageLoader />;

  const hasPluralsightAccess = providerStatus === ProviderAccessStatus.HAS_ACCESS;
  const hasPluralsightAccessPending = providerStatus === ProviderAccessStatus.PENDING_ACCESS;

  return (
    <Composition
      areas={providerFormAreas}
      areasLg={providerFormAreasLg}
      templateCols="minmax(0, 1fr)"
      templateColsLg="minmax(0, 9fr) minmax(0, 3fr)"
      gap={0.875}
      gapMd={1.75}
    >
      {({ First, Second }) => (
        <>
          <First>
            {isRealm('seb') ? (
              <Header1>
                {hasPluralsightAccess ? t('pluralsight-welcome-seb', { title: pluralsightTitle }) : pluralsightTitle}
              </Header1>
            ) : (
              <Header1>
                {hasPluralsightAccess
                  ? t('pluralsight-welcome', { title: pluralsightTitle })
                  : t('Improve your digital awareness and technology skills with Pluralsight')}
              </Header1>
            )}
            {!hasPluralsightAccessPending && !hasPluralsightAccess && (
              <>
                {techRoomDescription && (
                  <BodyLg className="has-bottom-margin content" dangerouslySetInnerHTML={{ __html: techRoomDescription }} />
                )}
              </>
            )}
            {hasPluralsightAccessPending && !hasPluralsightAccess && (
              <BodyLg>{t('pluralsight-awaiting-approval', { title: pluralsightTitle })}</BodyLg>
            )}
            {!hasPluralsightAccessPending && hasPluralsightAccess && isPluralsightFeatureEnabled && (
              <>
                {/* SEB approval text should be different */}
                {isRealm('seb') ? (
                  <>
                    <BodyLg>
                      <Trans t={t} i18nKey="pluralsight-description-seb-p1" />
                    </BodyLg>
                    <BodyLg>
                      <Trans t={t} i18nKey="pluralsight-description-seb-p2" />
                    </BodyLg>
                    <BodyLg>
                      <Trans t={t} i18nKey="pluralsight-description-seb-p3" />
                    </BodyLg>
                    <Trans t={t} i18nKey="pluralsight-description-seb-p4">
                      <BodyLg>
                        If you have questions, please contact HR Advice via{' '}
                        <a rel="noopener noreferrer" target="_blank" href={supportUrl.seb}>
                          HR Support
                        </a>{' '}
                        on the intranet.
                      </BodyLg>
                    </Trans>
                  </>
                ) : (
                  <>
                    <BodyLg>{t('pluralsight-approved-p1')}</BodyLg>
                    <BodyLg>{t('pluralsight-approved-p2')}</BodyLg>
                  </>
                )}
              </>
            )}
          </First>
          <Second>
            <ButtonList align="right" padded="bottom">
              <ProviderLogo provider={provider} />
            </ButtonList>
            <ButtonList align="right">
              {accessProductId !== undefined && !hasPluralsightAccess && (
                <Button
                  $loading={loadingPurchasePluralsight}
                  $icon="check"
                  disabled={hasPluralsightAccessPending}
                  onClick={() => purchasePluralsightProduct({ variables: { products: [{ id: accessProductId }] } })}
                >
                  {hasPluralsightAccessPending ? t('access-requested') : t('request-access')}
                </Button>
              )}
              {hasPluralsightAccess && (
                <LinkButton
                  $type="primary"
                  href={productPluralsight ? productPluralsight.meta.CUSTOM_LINK || '' : '#'}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('pluralsight-go-to')}
                </LinkButton>
              )}
            </ButtonList>
          </Second>
        </>
      )}
    </Composition>
  );
};
