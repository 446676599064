import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Provider as ProviderType } from '@/store/providers';
import { Icon } from '@/components/Icon';
import {
  ExploreMenu,
  CategoriesContainer,
  CategoriesContent,
  Category,
  ProvidersContainer,
  ProviderContent,
  Provider,
  OverlineTitle,
  ProvidersBox,
  ForwardIconContainer,
  SubjectLink,
  Text,
  CategoryTitleV2,
} from '@/components/Navbar/components/ExploreMenu/ExploreMenuStyles';
import { cvar } from '@/styles/GlobalStyle';
import { RouterLinkButton } from '@/components/Button/RouterLinkButton';
import { useCategoriesFromCatalog } from '@/hooks/useCategoriesFromCatalog';
import { trackCategoryOrSubjectClicked, trackProviderClicked } from '@/utils/tracking/learnings';
import { useCardCatalog } from '@/store/catalog';

type ExploreMenuComponentProps = {
  closeMenu: () => void;
  isInPreviewModal?: boolean;
}

export const ExploreMenuComponent = ({ closeMenu, isInPreviewModal }: ExploreMenuComponentProps): JSX.Element => {
  const { t } = useTranslation(['navbar', 'catalog']);
  const { refetch } = useCardCatalog();
  const {
    selectedCategoryId,
    selectedCategoryName,
    sortedCategoriesFromCatalog,
    setSelectedCategoryId,
    isLoadingCatalog,
    topProviders,
    shouldDisplayAllProvidersButton,
    subjectsFromCatalog,
  } = useCategoriesFromCatalog();

  const exploreMenuRef = useRef<HTMLDivElement>(null);
  const handleCategory = useCallback(
    (categoryId: number) => {
      const currentRef = exploreMenuRef.current;
      if (currentRef && categoryId !== selectedCategoryId) {
        currentRef.scrollTop = 0;
        setSelectedCategoryId(categoryId);
      } else {
        setSelectedCategoryId(null);
      }
    },
    [selectedCategoryId, exploreMenuRef]
  );

  const runRefetch = useCallback(async () => {
    refetch();
  }, []);

  useEffect(() => {
    runRefetch();
  }, []);

  const handleClickSubjectOrCategory = useCallback((subjectName: string) => {
    trackCategoryOrSubjectClicked(subjectName);
    closeMenu();
  }, []);

  const handleClickProvider = useCallback((providerName: ProviderType) => {
    trackProviderClicked(providerName);
    closeMenu();
  }, []);

  return (
    <ExploreMenu ref={exploreMenuRef} isInPreviewModal={isInPreviewModal}>
      {isLoadingCatalog ? (
        <div className="pageloader" />
      ) : (
        <>
          <CategoriesContainer>
            <CategoriesContent>
              <OverlineTitle>{t('Categories', { ns: 'catalog' })}</OverlineTitle>
              {sortedCategoriesFromCatalog.map(({ categoryId, categoryName }, index) => (
                <Category key={index} onClick={() => handleCategory(categoryId)}>
                  <CategoryTitleV2 isSelected={categoryId === selectedCategoryId}>
                    {t(categoryName, { ns: 'categories' })}
                  </CategoryTitleV2>
                  <Icon
                    color={categoryId === selectedCategoryId ? cvar('color-black') : cvar('color-gray')}
                    icon={categoryId === selectedCategoryId ? 'times' : 'chevron-right'}
                  />
                </Category>
              ))}
              <RouterLinkButton
                $type="primary"
                $fullwidth
                style={{ margin: `${cvar('gap-xs')} 0 ${cvar('gap-md')} 0` }}
                to="/learning"
                onClick={closeMenu}
              >
                {t('View all learning items')}
              </RouterLinkButton>
            </CategoriesContent>
          </CategoriesContainer>
          <ProvidersContainer>
            <ProviderContent>
              <>
                {!selectedCategoryId ? (
                  <OverlineTitle>{t('Top providers')}</OverlineTitle>
                ) : (
                  <Link
                    to={`/learning/category/${selectedCategoryId}`}
                    onClick={() => handleClickSubjectOrCategory(selectedCategoryName || '')}
                  >
                    <OverlineTitle isSelected={Boolean(selectedCategoryId)}>
                      {t(selectedCategoryName!, { ns: 'categories' })}
                    </OverlineTitle>
                  </Link>
                )}
                {!selectedCategoryId && (
                  <>
                    <ProvidersBox>
                      {topProviders.map(({ icon, provider, providerName }, index) => (
                        <Provider
                          key={index}
                          to={`/learning/provider/${provider}`}
                          onClick={() => handleClickProvider(providerName as ProviderType)}
                        >
                          <ForwardIconContainer icon={icon} size={providerName === 'IMD' ? 'xs' : '1x'} />
                          <Text>{providerName}</Text>
                        </Provider>
                      ))}
                    </ProvidersBox>
                    {shouldDisplayAllProvidersButton && (
                      <RouterLinkButton
                        $type="secondary"
                        $fullwidth
                        $icon="arrow-right"
                        style={{ borderRadius: `0 0 ${cvar('radius-inner')} ${cvar('radius-inner')}` }}
                        to="/learning/provider"
                        onClick={closeMenu}
                      >
                        {t('View all providers')}
                      </RouterLinkButton>
                    )}
                  </>
                )}
              </>
              {subjectsFromCatalog?.length &&
                subjectsFromCatalog.map(({ subjectName, subjectId }, index) => (
                  <SubjectLink
                    key={index}
                    to={`/learning/category/${selectedCategoryId}/subject/${subjectId}`}
                    onClick={() => handleClickSubjectOrCategory(subjectName)}
                  >
                    <Text>{t(subjectName, { ns: 'subjects' })}</Text>
                    <Icon icon="chevron-right" />
                  </SubjectLink>
                ))}
            </ProviderContent>
          </ProvidersContainer>
        </>
      )}
    </ExploreMenu>
  );
};
