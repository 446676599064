import { useTranslation } from 'react-i18next';

export const GetStatus = (status: string): string => {
  const { t } = useTranslation('managerDashboard');
  const statuses = {
    completed: t('Completed'),
    notCompleted: t('Not Completed'),
    expired: t('Expired'),
  };

  switch (status) {
    case 'completed':
      return statuses.completed;
    case 'not completed':
      return statuses.notCompleted;
    case 'expired':
      return statuses.expired;

    default:
      return status;
  }
};
