import React from 'react';

import {
  StyledLogoContentWrapper,
  StyledNavBanner,
  StyledNavInner,
  StyledNavV2,
  StyledNavWrapper,
  StyledNavWrapperInPreview
} from '@/components/Navbar/styles';
import { NavLogo } from '@/components/Navbar/components/NavLogo';
import { NavProfile } from '@/components/Navbar/components/NavProfile';
import { NavMobileMenu } from '@/components/Navbar/components/mobile/NavMobileMenu';
import { checkAdminView } from '@/utils/checkAdminView';
import { checkManagerView } from '@/utils/checkManagerView';
import { NavAdminContent } from '@/components/Navbar/components/NavAdminContent';
import { NavContent } from '@/components/Navbar/components/NavContent';
import { NavSearchV2 } from '@/components/Navbar/components/NavSearch/NavSearchV2';
import { useCurrentUser, isUserOnboarded, isUserAuthenticated } from '@/store/currentUser';

type NavbarProps = {
  shouldOverrideAdminView?: boolean;
  isInPreviewModal?: boolean;
}

export const Navbar = ({shouldOverrideAdminView, isInPreviewModal}: NavbarProps): JSX.Element => {
  const { user } = useCurrentUser();

  const isAdminView = checkAdminView({shouldOverrideAdminView});
  const isManagerView = checkManagerView();
  const userRealm = user?.realm;
  const windowRealm = window.realm;
  const isDifferentRealms = userRealm !== windowRealm && !!userRealm;

  const isAuthenticated = isUserAuthenticated(user);
  const isOnboarded = isUserOnboarded(user);

  const ConditionalNavWrapper = isInPreviewModal ? StyledNavWrapperInPreview : StyledNavWrapper;
  const shouldDisplayStyledNavBanner = !isInPreviewModal && (!!user?.isDebugging || isDifferentRealms);
  
  return (
    <ConditionalNavWrapper>
      {shouldDisplayStyledNavBanner && (
        <StyledNavBanner>
          <span>
            {!!user?.isDebugging && (
              <span aria-label="eyes" role="img">
                👀 Currently logged in as: {user?.email}.
              </span>
            )}
            {isDifferentRealms && (
              <span>
                {' '}
                You are visiting realm:{' '}
                <em>
                  <b>{windowRealm}</b>
                </em>
                , but your expected realm is:{' '}
                <em>
                  <b>{userRealm}</b>
                </em>
              </span>
            )}
          </span>
        </StyledNavBanner>
      )}
      <StyledNavV2 isInPreviewModal={isInPreviewModal} justifyContent={isAuthenticated ? 'center' : undefined}>
        <StyledNavInner justifyContent={isAuthenticated && isOnboarded ? 'space-between' : 'center'}>
          <StyledLogoContentWrapper css={isAdminView ? `width: 100%` : undefined}>
            <NavLogo shouldOverrideAdminView={shouldOverrideAdminView} isInPreviewModal={isInPreviewModal}/>
            {isAuthenticated && isOnboarded && !isAdminView && !isManagerView && <NavContent isInPreviewModal={isInPreviewModal} />}
          </StyledLogoContentWrapper>
          {isAuthenticated && isOnboarded && <NavMobileMenu isInPreviewModal={isInPreviewModal} />}
          {isAuthenticated && isOnboarded && <NavProfile shouldOverrideAdminView={shouldOverrideAdminView} isInPreviewModal={isInPreviewModal}/>}
        </StyledNavInner>
        {isAuthenticated && isOnboarded && !isAdminView && !isManagerView && <NavSearchV2 isInPreviewModal={isInPreviewModal} />}
        {isAuthenticated && isOnboarded && isAdminView && !isManagerView && <NavAdminContent />}
      </StyledNavV2>  
    </ConditionalNavWrapper>
  );
};
