import { useState, useCallback } from 'react';
import debounce from 'lodash/debounce';

export const useDebouncedSearchPhrase = (
  initialState = ''
): {
  searchPhrase: string;
  debouncedSearchPhrase: string;
  onSearchPhraseChange: (value: string) => void;
} => {
  const [searchPhrase, setSearchPhrase] = useState(initialState);
  const [debouncedSearchPhrase, setDebouncedSearchPhrase] = useState(initialState);

  const handleDebouncedSearchPhraseChange = useCallback(
    debounce((value: string) => {
      setDebouncedSearchPhrase(value);
    }, 300),
    []
  );

  const handleSearchPhraseChange = (value: string) => {
    setSearchPhrase(value);
    handleDebouncedSearchPhraseChange(value);
  };

  return {
    searchPhrase,
    debouncedSearchPhrase,
    onSearchPhraseChange: handleSearchPhraseChange,
  };
};
