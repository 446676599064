import { XAPISession } from '@/store/xapi/useXAPISession';
import { XAPIClient } from '@/store/xapi/useXAPIClient';
import { Activity } from '@/features/course/admin/types/activity';
import { useXAPIReaction } from '@/store/xapi/useXAPIReaction';
import { getNextOrderIndex } from '../../activity/getNextOrderIndex';
import { createReactionActivity } from '@/features/course/admin/utils/activityTransform';

type UsePluginAddReactionActivityProps = {
  xapiSession?: XAPISession;
  xapiClient: XAPIClient;
  course?: {
    id?: string;
    title?: string;
  };
};

export type UsePluginAddReactionActivity = {
  isInitialized: boolean;
  loading: boolean;

  onLoadTransformActivities: (activities: Activity[]) => Activity[];
};

export const usePluginAddReactionActivity = ({
  xapiSession,
  course,
}: UsePluginAddReactionActivityProps): UsePluginAddReactionActivity => {
  const xapiReaction = useXAPIReaction(Number(course?.id), course?.title, xapiSession);

  const onLoadTransformActivities = (activities: Activity[]): Activity[] => {
    return [
      ...activities,
      createReactionActivity({ order: getNextOrderIndex(activities), completed: !!xapiReaction.reactionId }),
    ];
  };

  return {
    isInitialized: xapiReaction.isInitialized,
    loading: xapiReaction.loading,
    onLoadTransformActivities,
  };
};
