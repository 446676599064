import styled from 'styled-components';

import { Caption } from '@/components/Typography/Typography';
import { cvar } from '@/styles/GlobalStyle';

export const StyledError = styled(Caption).attrs((props) => ({ ...props, role: 'alert' }))`
  color: ${cvar('color-monza')};
  padding-top: 4px;
`;

export const StyledFieldWrapper = styled.fieldset`
  display: flex;
  flex-direction: column;
  position: relative;
`;
