import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Select, TextFieldSelect } from '@/ui/Select';
import { LEVELS, TextFieldName } from '@/features/course/admin/constants/settingsModal';
import { Course } from '@/features/course/admin/types/course';
import { ButtonGroup } from '@/ui/ButtonGroup';
import {
  WrapStyled,
  ColumnStyled,
  StyledLabel,
  StyledCaption,
} from '@/features/course/admin/molecules/CourseSettingsModalForm/CourseSettingsModalForm';

type CourseSettingsModalFieldLevelProps = {
  control: Control<Course>;
};

export const CourseSettingsModalFieldLevel = ({ control }: CourseSettingsModalFieldLevelProps): JSX.Element => {
  const { t } = useTranslation('catalog');

  return (
    <WrapStyled>
      <ColumnStyled>
        <ButtonGroup gap="small" direction="vertical">
          <StyledLabel>{t('level')}</StyledLabel>
          <StyledCaption>{t('level-caption')}</StyledCaption>
        </ButtonGroup>
      </ColumnStyled>
      <ColumnStyled>
        <Controller
          control={control}
          name={TextFieldName.Level}
          render={({ field: { onChange, value } }) => (
            <TextFieldSelect label={t('level')} value={value || LEVELS[0]} onChange={onChange} fullWidth>
              <Select.List fullWidth>
                {LEVELS.map((x) => (
                  <Select.Option value={x} key={x}>
                    {x}
                  </Select.Option>
                ))}
              </Select.List>
            </TextFieldSelect>
          )}
        />
      </ColumnStyled>
    </WrapStyled>
  );
};
