import { createContext } from 'react';
import { UniqueIdentifier } from '@dnd-kit/core';

import { PageVariant } from '@/features/course/admin/types/page';

export const TreeDrawerContext = createContext<{
  onAddPage: (type: PageVariant, parentActivityId?: string) => void;
  onRemoveItem: (id: string) => void;
  onRenameItem: (id: string, newTitle: string) => void;
  onActivateItem: (id: string) => void;
  onHandleCustomCollapse: (ops: { targetId: UniqueIdentifier; customCollapsed: boolean }) => void;
}>({
  onAddPage: () => {},
  onRemoveItem: () => {},
  onRenameItem: () => {},
  onActivateItem: () => {},
  onHandleCustomCollapse: () => {},
});
