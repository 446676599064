import { IconPropType } from '@/ui/Icon';
import { PageVariant } from '@/features/course/admin/types/page';

export type DrawerItemType = PageVariant | 'completed';
export const PAGE_TYPE_TO_ICON: Record<DrawerItemType, IconPropType> = {
  [PageVariant.TEXT]: ['far', 'rectangle'],
  [PageVariant.RESULT]: ['far', 'party-horn'],
  completed: ['far', 'circle-check'],
  [PageVariant.REACTION]: ['far', 'angle-double-right'],
  [PageVariant.QUESTION]: ['far', 'square-poll-vertical'],
  [PageVariant.PRACTICAL_ASSESSMENT_TEXT]: ['far', 'pen-line'],
  [PageVariant.PRACTICAL_ASSESSMENT_DOCUMENT]: ['far', 'file-upload'],
  // actual ones go below
  [PageVariant.REFLECTION]: ['far', 'comment-pen'],
  [PageVariant.MODULE]: ['far', 'rectangle-history'],
  [PageVariant.PAGE]: ['far', 'rectangle'],
  [PageVariant.QUIZ]: ['far', 'game-board-simple'],
};
