import { SetStateAction, useEffect, useState } from 'react';

import { Page, PageVariantDeprecated as PageVariant } from '@/features/course/admin/types/page';
import { PageId } from '@/administration/pages/Course/store/types';
import { useQueryBooleanState } from '@/hooks/useQueryBooleanState';

export const useModularLearningViewer = (ops?: {
  onPageCompleted?: (p: Page) => boolean;
  onLearningCompleted?: () => void;
}): {
  pages: Page[];
  isDrawerOpen: boolean;

  prevPageActiveCount: number;
  pageActiveCount: number;
  pageTotalCount: number;

  onSetPageActive: (ops: { pageId: PageId }) => void;
  onSetPrevPageActive: () => void;
  onSetNextPageActive: () => void;
  onSetPageActiveByIndex: (index: number) => void;
  setDrawerOpen: (p: boolean) => void;
  setPages: (value: SetStateAction<Page[]>) => void;
} => {
  const [pages, setPages] = useState<Array<Page>>([]);
  const [isDrawerOpen, setDrawerOpen] = useQueryBooleanState('drawer-open', true);
  const [prevPageActiveCount, setPrevPageActiveCount] = useState(0);

  const handleActivatePage = ({ pageId }: { pageId: PageId }) => {
    const activePageIndex = pages.findIndex((p) => p.active);
    const nextPageIndex = pages.findIndex((p) => p.id === pageId);

    setPrevPageActiveCount(activePageIndex);

    if (nextPageIndex > activePageIndex) {
      const activePage = pages[activePageIndex];

      if (activePage?.remoteId) {
        if (ops?.onPageCompleted) {
          activePage.completed = ops.onPageCompleted(activePage);
        } else {
          // by default mark page as completed
          activePage.completed = true;
        }
      }
    }

    setPages((pages) => pages.map((page) => (page.id === pageId ? { ...page, active: true } : { ...page, active: false })));
  };

  useEffect(() => {
    const lastPage = pages?.[pages?.length - 1];
    if (!lastPage || lastPage.type !== PageVariant.RESULT) return;

    ops?.onLearningCompleted && ops?.onLearningCompleted();
  }, [pages?.[pages?.length - 1]?.type]);

  const handleSetPrevPageActive = () => {
    let indexOf = pages.findIndex((p) => p.active) - 1;
    if (indexOf < 0) indexOf = 0;

    handleActivatePage({ pageId: pages[indexOf].id });
  };

  const handleSetNextPageActive = () => {
    let indexOf = pages.findIndex((p) => p.active) + 1;
    if (indexOf >= pages.length) indexOf = pages.length - 1;

    handleActivatePage({ pageId: pages[indexOf].id });
  };

  const handleSetPageActiveByIndex = (index: number) => {
    const parsedIndex = index >= pages.length ? pages.length - 1 : index;

    handleActivatePage({ pageId: pages[parsedIndex].id });
  };

  return {
    pages,
    isDrawerOpen,
    pageActiveCount: pages.findIndex((p) => p.active),
    prevPageActiveCount,
    pageTotalCount: pages.length,
    onSetPageActive: handleActivatePage,
    onSetPrevPageActive: handleSetPrevPageActive,
    onSetNextPageActive: handleSetNextPageActive,
    onSetPageActiveByIndex: handleSetPageActiveByIndex,
    setDrawerOpen,
    setPages,
  };
};
