import { ApolloError, WatchQueryFetchPolicy } from '@apollo/client';

import { useLearningJourneyQueryRemote } from '@/store/v2';
import { LearningJourney, LearningJourneyItem } from '@/store/v2/journeys/journey';
import { useSpaces } from '@/store/spaces';
import { sortBy } from '@/utils/sort';
import { useCurrentUser } from '@/store/currentUser';

export const useLearningJourney = ({
  id,
  include,
  skip,
  fetchPolicy,
}: {
  id: number;
  include?: { items?: boolean; cohortTeams?: boolean; updateByUser?: boolean };
  skip?: boolean;
  fetchPolicy?: WatchQueryFetchPolicy;
}): {
  journey: LearningJourney | undefined;
  items: LearningJourneyItem[] | undefined;
  loading: boolean;
  error?: ApolloError;
} => {
  const { spaces, loading: loadingSpaces } = useSpaces();
  const { user, loading: loadingUser } = useCurrentUser();
  const userId = Number(user?.id);

  const { data, loading, error } = useLearningJourneyQueryRemote({
    variables: {
      id,
      includeItems: include?.items || false,
      spaceIds: spaces.map((space) => space.id) || [],
      userId,
      includeCohortTeams: include?.cohortTeams || false,
      includeUpdateByUser: include?.updateByUser || false,
    },
    skip: loadingSpaces || loadingUser || !userId || skip,
    fetchPolicy,
  });

  return {
    loading,
    error,
    journey: data?.learningJourney
      ? {
          ...data.learningJourney,
          updateByUser: data.learningJourney.updateByUser || undefined,
          cohortTeams: data.learningJourney.cohortTeams || [],
        }
      : undefined,
    items: !!data?.learningJourney?.items?.data
      ? sortBy(data?.learningJourney?.items?.data, (item) => item.order, 'asc')
      : undefined,
  };
};
