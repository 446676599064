import styled, { css } from 'styled-components';

import { BodyMd, OverlineMixin } from '@/components/Typography/Typography';
import { CheckboxLabel } from '@/components/Checkbox/CheckboxInput';
import { cvar } from '@/styles/GlobalStyle';

export const TableWrapper = styled.div`
  overflow-x: visible;
`;

export const Table = styled.table<{ loading?: boolean; noShadow?: boolean }>`
  border-collapse: separate;
  width: 100%;
  background-color: transparent;
  border-radius: var(--radius-outer);
  ${(props) => (props.noShadow ? '' : `box-shadow: var(--box-shadow-large);`)}
  color: inherit;
  position: relative;

  ${(props) => props.loading && loader}
`;

export const TableStatusRow = styled.tr<{ show?: boolean }>`
  background-color: var(--color-wild-sand);

  td {
    ${BodyMd} {
      box-sizing: border-box;
      max-height: ${(props) => (props.show ? '4.25rem' : '0')};
      transition: max-height 0.2s, padding 0.2s;
      overflow: hidden;
      padding-top: ${(props) => (props.show ? '10px' : '0')};
      padding-bottom: ${(props) => (props.show ? '10px' : '0')};
    }

    border-bottom: ${cvar('border-style')};
    text-align: center !important;

    &:first-child {
      border-left: ${cvar('border-style')};
    }

    &:last-child {
      border-right: ${cvar('border-style')};
    }
  }
`;

export const THead = styled.thead<{ showActions?: boolean }>`
  tr:first-child {
    > th,
    > td {
      vertical-align: middle;
      background-color: ${(props) => (props.showActions ? `var(--color-primary)` : 'var(--color-alto)')};
      padding: 0.5rem 0.75rem;
      ${OverlineMixin({ color: 'color-dusty-gray' })}

      &:first-child {
        border-top-left-radius: var(--radius-outer);
      }

      &:last-child {
        border-top-right-radius: var(--radius-outer);
      }
    }
  }

  ${(props) =>
    props.showActions &&
    `
    ${CheckboxLabel} {
      &:before {
        border-color: var(--color-white);
      }
      > svg > path {
        color: #8bc7bb !important;
      }
    }
  `}
`;

export const TFoot = styled.tfoot`
  > tr > td {
    border-bottom: ${cvar('border-style')};
    background-color: var(--color-wild-sand);

    &:first-child {
      border-left: ${cvar('border-style')};
      border-bottom-left-radius: var(--radius-outer);
    }

    &:last-child {
      border-right: ${cvar('border-style')};
      border-bottom-right-radius: var(--radius-outer);
    }
  }
`;

export const TBody = styled.tbody`
  tr {
    background-color: var(--color-white);
  }

  td {
    vertical-align: middle;
    border: 0;
    border-bottom: ${cvar('border-style')} !important;
    padding: 0.5rem 0.75rem;

    &:first-child {
      border-left: ${cvar('border-style')};
    }

    &:last-child {
      border-right: ${cvar('border-style')};
    }
  }

  &:last-child {
    > tr:last-child > td {
      &:first-child {
        border-bottom-left-radius: var(--radius-outer);
      }

      &:last-child {
        border-bottom-right-radius: var(--radius-outer);
      }
    }
  }
`;

export const TableTitle = styled.p`
  color: ${cvar('color-black')} !important;
  font-size: ${cvar('font-size-6')};
  line-height: 1.6;
`;

export const TableOverline = styled.p`
  ${OverlineMixin({ color: 'color-table-overline' })}
`;

export const TableRow = styled.tr<{ gray: boolean }>`
  ${(props) =>
    props.gray
      ? `
      background-color: var(--color-wild-sand);
      &:hover {
        background-color: var(--color-white);
      }`
      : `
      background-color: var(--color-white);
      &:hover {
        background-color: var(--color-wild-sand);
      }
  `}
  &:last-child > td {
    border-bottom: ${cvar('border-style')};
  }
`;

const loader = css`
  animation: spinAround 500ms infinite linear;
  border: var(--border-style);
  border-radius: 290486px;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  border-left-color: var(--color-dove-gray);
  border-bottom-color: var(--color-dove-gray);
  position: absolute;
  top: calc(50% - 3em);
  left: calc(50% - 3em);
  width: 6em;
  height: 6em;
  border-width: 0.25em;
`;
