import React from 'react';
import { useTheme } from 'styled-components';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';

import { getDraggableItemStyle } from './getDraggableItemStyle';
import { AddPageButton } from './AddPageButton';
import { makeNamespacedComponent } from '@/ui/utils';
import { Skeleton } from '@/ui/Skeleton';
import { RootStyled, ActionsStyled, ListStyled } from '../styles';
import { EditDrawerItemProps, EditItem } from '../../Item';
import { PageVariantDeprecated as PageVariant } from '@/features/course/admin/types/page';
import { AddAssessmentPageButton } from './AddAssessmentPageButton';
import { EditModularLayoutVariant } from '../../../../variants/Edit';
import { RLearningType } from '@/store/v2';

type EditDrawerProps = {
  items: EditDrawerItemProps[];
  creationOptionsVariant: EditModularLayoutVariant;
  onAddClick: (type: PageVariant) => void;
  onReorder: ({ sourceIndex, destinationIndex }: { sourceIndex: number; destinationIndex: number }) => void;
};

const _EditDrawer = ({
  items,
  onReorder,
  onAddClick,
  creationOptionsVariant = RLearningType.Course,
}: EditDrawerProps): JSX.Element => {
  const theme = useTheme();

  const handleDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    onReorder({
      sourceIndex: result.source.index,
      destinationIndex: result.destination.index,
    });
  };

  return (
    <RootStyled>
      <ActionsStyled>
        {creationOptionsVariant === RLearningType.Course && <AddPageButton onAddClick={onAddClick} />}
        {creationOptionsVariant === RLearningType.PracticalAssessment && <AddAssessmentPageButton onAddClick={onAddClick} />}
      </ActionsStyled>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <ListStyled {...provided.droppableProps} ref={provided.innerRef}>
              {items.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, { isDragging }) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getDraggableItemStyle({
                        isDragging,
                        theme,
                        draggableStyle: provided.draggableProps.style,
                      })}
                    >
                      <EditItem {...item} key={item.id} removalForbidden={items.length < 2} />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ListStyled>
          )}
        </Droppable>
      </DragDropContext>
    </RootStyled>
  );
};

const LoadingState = (): JSX.Element => (
  <RootStyled>
    <ActionsStyled>
      <Skeleton width={46} height={36} />
    </ActionsStyled>
    <EditItem.LoadingState />
  </RootStyled>
);

export const EditDrawer = makeNamespacedComponent(_EditDrawer, {
  LoadingState,
});
