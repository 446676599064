import React, { createContext, Dispatch, ReactNode, SetStateAction, useState, useContext } from 'react';

import { factoryUseContext } from '@/utils/factoryUseContext';

const noop = () => {};

interface ManagementContextProps {
  initialized: boolean;
  title: string;
  setTitle?: Dispatch<SetStateAction<string>>;
  subtitle: string | JSX.Element;
  setSubtitle?: Dispatch<SetStateAction<string | JSX.Element>>;
  lastActive: string;
  setLastActive?: Dispatch<SetStateAction<string>>;
  joined?: string;
  setJoined?: Dispatch<SetStateAction<string>>;
  hasSubHeading?: boolean;
  setHasSubHeading?: Dispatch<SetStateAction<boolean>>;
  loading?: boolean;
  setLoading?: Dispatch<SetStateAction<boolean>>;
  hasSubtitle?: boolean;
  setHasSubtitle?: Dispatch<SetStateAction<boolean>>;
}

const ManagementContext = createContext<ManagementContextProps>({
  initialized: false,
  title: '',
  setTitle: noop,
  subtitle: '',
  setSubtitle: noop,
  lastActive: '',
  setLastActive: noop,
  joined: '',
  setJoined: noop,
  hasSubHeading: false,
  setHasSubHeading: noop,
  loading: true,
  setLoading: noop,
  hasSubtitle: false,
  setHasSubtitle: noop,
});

export const useManagementContext = factoryUseContext('Management', ManagementContext);

export const useIsManagementContext = (): boolean => {
  const context = useContext(ManagementContext);
  return !!context && context.initialized;
};

export function ManagementProvider({ children }: { children: ReactNode }): JSX.Element {
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState<string | JSX.Element>('');
  const [lastActive, setLastActive] = useState('');
  const [joined, setJoined] = useState('');
  const [hasSubHeading, setHasSubHeading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [hasSubtitle, setHasSubtitle] = useState(false);

  const defaultContext: ManagementContextProps = {
    initialized: true,
    title,
    setTitle,
    subtitle,
    setSubtitle,
    lastActive,
    setLastActive,
    joined,
    setJoined,
    hasSubHeading,
    setHasSubHeading,
    loading,
    setLoading,
    hasSubtitle,
    setHasSubtitle,
  };

  return <ManagementContext.Provider value={defaultContext}>{children}</ManagementContext.Provider>;
}
