import { useState } from 'react';

import { Modal } from '@/ui/Modal';
import { ParticipantsPicker } from './ParticipantsPicker';
import { useParticipantsPickerState } from './useParticipantsPickerState';
import { NoUsersModalContent } from './NoUsersModalContent';
import { ModalProps } from '@/ui/Modal/types';

type AddParticipantsModalProps = {
  open: boolean;
  participantIdsToSkip?: number[];
  onClose: () => void;
  onSave: (selectedUserIds: number[]) => void;
};

export const AddParticipantsModal = ({
  open,
  participantIdsToSkip = [],
  onClose,
  onSave,
}: AddParticipantsModalProps): JSX.Element => {
  const [selectedUserIds, setSelectedUserIds] = useState<number[]>([]);
  const { onLoadMore, loading, usersList, usersTotal, canShowMore, onSearchPhraseChange, searchPhrase } =
    useParticipantsPickerState({
      participantIdsToSkip,
    });

  const noUsersExist =
    !loading && // we have loaded
    !usersTotal && // no users
    !searchPhrase; // and it's not caused by search filtering

  const modalProps: Omit<ModalProps, 'children'> = { open, onClose, size: 'mobileLandscape' };

  if (noUsersExist)
    return (
      <Modal {...modalProps}>
        <NoUsersModalContent onClose={onClose} />
      </Modal>
    );

  return (
    <Modal {...modalProps}>
      <Modal.Title>Add learners</Modal.Title>

      <Modal.Contents>
        <ParticipantsPicker
          selectedUserIds={selectedUserIds}
          setSelectedUserIds={setSelectedUserIds}
          loading={loading}
          searchPhrase={searchPhrase}
          onSearchPhraseChange={onSearchPhraseChange}
          canShowMore={canShowMore}
          usersList={usersList}
          onLoadMore={onLoadMore}
        />
      </Modal.Contents>
      <Modal.Actions>
        {<Modal.Action action={onClose}>Cancel</Modal.Action>}
        <Modal.Action
          action={() => {
            onSave(selectedUserIds);
            onClose();
          }}
          primary
        >
          Save
        </Modal.Action>
      </Modal.Actions>
    </Modal>
  );
};
