import styled from 'styled-components';

export const RootStyled = styled.div`
  ${({ theme }) => theme.typography.body3};
  height: 48px;
  color: ${({ theme }) => theme.colors.neutral.disabled};
  display: flex;
  align-items: center;
`;

export const CourseDrawerModuleEmptyState = (): JSX.Element => {
  return <RootStyled>Empty module</RootStyled>;
};
