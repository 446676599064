import { gql, ApolloQueryResult } from '@apollo/client';

import { HookResult } from '@/store/apolloClient';
import { RealmRole, UsersPage, UserWithRole } from '../../types/users';
import { AccountStatus, User } from '@/types/group';
import { useDebouncedQuery } from '@/hooks/useDebouncedQuery';

export const USER_FRAGMENT = gql`
  fragment UserFragment on UserV2 {
    id
    email
    realm
    profileImage
    username
    status
    lastActive
    timestamp
  }
`;

export const USERS_QUERY = gql`
  query users($filter: UsersFilter, $pagination: Pagination, $sort: UsersSort, $withRealmRoleAssignments: Boolean!) {
    users(filter: $filter, pagination: $pagination, sort: $sort) {
      data {
        ...UserFragment
        realmRoleAssignments @include(if: $withRealmRoleAssignments) {
          realm
          role
        }
      }
      limit
      page
      total
    }
  }
  ${USER_FRAGMENT}
`;

const DEBOUNCE_TIME = 800;

interface UsersFilter {
  searchPhrase?: string;
  idNIN?: number[];
  status?: AccountStatus[];
  realm?: string;
  realmRole?: RealmRole;
}

interface UsersPagination {
  limit: number;
  page: number;
}

export enum UsersSortField {
  ID = 'ID',
  EMAIL = 'EMAIL',
  USERNAME = 'USERNAME',
  STATUS = 'STATUS',
}

export enum UsersSortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface UsersSort {
  field: UsersSortField;
  order: UsersSortOrder;
}

export interface UsersOptions {
  withRealmRoleAssignments?: boolean;
  skip?: boolean;
}

interface UsersResponse extends HookResult {
  users: readonly UserWithRole[];
  total: number;
  refetch: () => Promise<ApolloQueryResult<{ users: UsersPage }>>;
}

export const useUsers = (
  filter: UsersFilter,
  pagination: UsersPagination,
  sort: UsersSort,
  options?: UsersOptions
): UsersResponse => {
  const variables = {
    filter,
    sort,
    pagination,
    withRealmRoleAssignments: !!options?.withRealmRoleAssignments,
  };
  const { data, error, refetch, loading, networkStatus } = useDebouncedQuery<{ users: UsersPage }, typeof variables>(
    USERS_QUERY,
    {
      variables,
      skip: !!options?.skip,
      debounceDelay: DEBOUNCE_TIME,
      shouldDebounce: (prevVars, nextVars) => prevVars?.filter?.searchPhrase !== nextVars?.filter?.searchPhrase,
    }
  );
  const users: ReadonlyArray<User> = data?.users?.data || [];
  const total = data?.users?.total || 0;

  return {
    users,
    total,
    loading,
    error,
    refetch: () => refetch(variables),
    networkStatus,
  };
};
