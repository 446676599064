import styled from 'styled-components';

import { Button } from '@/ui/Button';
import { ButtonGroup } from '@/ui/ButtonGroup';
import { useJourneyHref } from '@/administration/pages/Journey/utils/useJourneyNavigate';
const ActionBar = styled.div`
  display: flex;
  justify-content: flex-end;
`;

type AdminButtonGroupProps = {
  journeyId: number;
};

export const AdminButtonGroup = ({ journeyId }: AdminButtonGroupProps): JSX.Element => {
  const href = useJourneyHref();
  const editUrlHref = href.adminEditorHref(journeyId);
  const insightsUrlHref = href.adminInsightsHref(journeyId);

  return (
    <ActionBar>
      <ButtonGroup>
        <Button
          variant="secondary"
          to={editUrlHref}
          rel="noopener noreferrer"
          icon={['far', 'edit']}
          $iconPosition="start"
          size="large"
        >
          Edit
        </Button>
        <Button
          variant="secondary"
          to={insightsUrlHref}
          rel="noopener noreferrer"
          icon={['far', 'poll']}
          $iconPosition="start"
          size="large"
        >
          Insights
        </Button>
      </ButtonGroup>
    </ActionBar>
  );
};
