import styled, { css } from 'styled-components';
import { useParams, Link, To } from 'react-router-dom';
import { ReactNode } from 'react';

import { Icon } from '@/components/Icon';
import { LearningCard, LearningCardError, LearningCardProps } from '../../LearningCard';
import { PARENT_JOURNEY_ID_SEARCH_KEY } from '@/store/v2/journeys/useParentJourneyId';

export type OrderedLearningCardProps = LearningCardProps & {
  launchPath?: string;
  onLockedClick?: () => void;
  orderIndex: number;
  systemGenerated?: boolean;
};

const RootStyled = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  padding: 24px 16px 24px 32px;

  &:hover {
    background: ${({ theme }) => theme.colors.states.hover1};
  }
`;

const NavigateIcon = styled(Icon).attrs({ icon: 'angle-right' })`
  display: flex;
  width: 6px;
  margin: 0px 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.neutral.onBackground};
`;

const UnavailableIcon = styled(Icon).attrs({ icon: ['fas', 'circle-exclamation'] })`
  padding: 0 12px;
  color: ${({ theme }) => theme.colors.error.main};
`;

const OrderIndexStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.typography.body4};
  color: ${({ theme }) => theme.colors.neutral.onContainer};
  background: ${({ theme }) => theme.colors.neutral.container};
  border-radius: 100px;
  height: 32px;
  width: 32px;
`;

const ContentStyled = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const LockedContainer = styled.div<{ disabled?: boolean }>`
  ${({ disabled }) =>
    disabled
      ? css`
          pointer-events: none;
        `
      : css`
          cursor: pointer;
        `}
`;

const LockedCardWrapper = ({
  children,
  linkTo,
  locked,
  disabled,
  onLockedClick,
}: {
  children: ReactNode;
  linkTo: To;
  locked: boolean;
  disabled?: boolean;
  onLockedClick?: () => void;
}) => {
  if (locked)
    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <LockedContainer onClick={onLockedClick} role="button" tabIndex={0} disabled={disabled}>
        {children}
      </LockedContainer>
    );

  return <Link to={linkTo}>{children}</Link>;
};
export const OrderedLearningCard = ({
  orderIndex,
  launchPath,
  onLockedClick,
  systemGenerated,
  ...rest
}: OrderedLearningCardProps): JSX.Element => {
  const { journeyId } = useParams<{ journeyId: string }>();

  const linkTo = {
    pathname: launchPath,
    search: `${PARENT_JOURNEY_ID_SEARCH_KEY}=${journeyId}`,
  };

  return (
    <LockedCardWrapper linkTo={linkTo} locked={Boolean(rest.locked)} onLockedClick={onLockedClick} disabled={rest.disabled}>
      <RootStyled>
        <ContentStyled>
          <OrderIndexStyled>{orderIndex}</OrderIndexStyled>
          <LearningCard {...rest} systemGenerated={systemGenerated} />
        </ContentStyled>
        {rest.error === LearningCardError.UNAVAILABLE && <UnavailableIcon />}
        {!rest.error && launchPath && <NavigateIcon />}
      </RootStyled>
    </LockedCardWrapper>
  );
};
