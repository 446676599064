import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import { Participant } from '../../atoms/Participant';
import { Button } from '@/ui/Button';
import { Input } from '@/ui/TextField';
import { FilterChip } from '@/ui/Chip';
import { Skeleton } from '@/ui/Skeleton';
import { EmptyStateAfterProcessed } from '@/management/components/EmptyState'; // TODO (for later PRs) Make it a generic one, so we could use it in both places
import { UserWithRole } from '@/administration/types/users';

const RootStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const LearnersListStyled = styled.div`
  max-height: 600px;
  overflow-y: scroll;
  padding-right: 16px;
`;

const LoadMoreWrap = styled.div`
  display: flex;
  justify-content: center;
`;

const LearnerWrapStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SelectedCounterContainerStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  ${({ theme }) => theme.typography.body3};
`;

const LoadingState = () => (
  <LearnersListStyled>
    {[...Array(10)].map((_, i) => (
      <div key={i} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Participant.LoadingState />
        <Skeleton width={60} height={32} />
      </div>
    ))}
  </LearnersListStyled>
);

const EmptyStateNoSearchResults = ({ onClear }: { onClear: () => void }) => <EmptyStateAfterProcessed onFiltersClear={onClear} />;

export const ParticipantsPicker = ({
  selectedUserIds,
  setSelectedUserIds,
  loading,
  usersList,
  onLoadMore,
  canShowMore,
  onSearchPhraseChange,
  searchPhrase,
}: {
  setSelectedUserIds: Dispatch<SetStateAction<number[]>>;
  selectedUserIds: number[];
  loading: boolean;
  usersList: UserWithRole[];
  onLoadMore: () => void;
  canShowMore: boolean;
  onSearchPhraseChange: (value: string) => void;
  searchPhrase: string;
}): JSX.Element => {
  const renderListWithStates = () => {
    if (loading && !usersList.length) return <LoadingState />;

    if (!usersList?.length) return <EmptyStateNoSearchResults onClear={() => onSearchPhraseChange('')} />;

    return (
      <div>
        <LearnersListStyled>
          {usersList.map((user) => {
            const selected = selectedUserIds.includes(user.id);

            return (
              <React.Fragment key={user.id}>
                <LearnerWrapStyled>
                  <Participant email={user.email} name={user.username || ''} profileImage={user.profileImage} />
                  <FilterChip
                    label={selected ? 'Added' : 'Add'}
                    selected={selected}
                    onClick={() =>
                      setSelectedUserIds((prev) => (selected ? prev.filter((id) => id !== user.id) : [...prev, user.id]))
                    }
                  />
                </LearnerWrapStyled>
              </React.Fragment>
            );
          })}
          {canShowMore && (
            <LoadMoreWrap>
              <Button variant="secondary" onClick={onLoadMore} disabled={false}>
                Show more
              </Button>
            </LoadMoreWrap>
          )}
        </LearnersListStyled>
      </div>
    );
  };

  return (
    <RootStyled>
      <Input
        label="Search"
        leadingIcon={'search'}
        value={searchPhrase}
        onChange={(e) => onSearchPhraseChange(e.currentTarget.value)}
      />
      <div>
        {Boolean(selectedUserIds.length) && (
          <SelectedCounterContainerStyled>
            {selectedUserIds.length} learner{selectedUserIds.length > 1 ? 's' : ''} added
          </SelectedCounterContainerStyled>
        )}

        {renderListWithStates()}
      </div>
    </RootStyled>
  );
};
