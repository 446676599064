import { Path, Range } from 'slate';
import { getNode, isVoid, removeNodes, getNodeString, select } from '@udecode/plate-common';
import { createPlatePlugin } from '@udecode/plate-common/react';

const DELETE_BACKWARD_KEY = 'delete-backward-key';

/**
 * Proper Delete Backward behaviour for Slate js.
 *
 * Give rich-text editor content like:
 *   1 <void element>
 *   2 <empty paragraph>
 * And assuming that user focus is in node 2. By pressing then, Slate js by default
 * removed void element and keeps empty paragraph. This plugins aims to change it.
 *
 * See more info at https://github.com/ianstormtaylor/slate/issues/3991
 */
export const DeleteBackwardPlugin = createPlatePlugin({
  key: DELETE_BACKWARD_KEY,
  extendEditor: (ctx) => {
    const { deleteBackward } = ctx.editor;

    ctx.editor.deleteBackward = (unit) => {
      if (!ctx.editor.selection || !Range.isCollapsed(ctx.editor.selection) || ctx.editor.selection.anchor.offset !== 0) {
        return deleteBackward(unit);
      }

      const parentPath = Path.parent(ctx.editor.selection.anchor.path);

      if (Path.hasPrevious(parentPath)) {
        const prevNodePath = Path.previous(parentPath);
        const prevNode = getNode(ctx.editor, prevNodePath);

        if (isVoid(ctx.editor, prevNode)) {
          const parentNode = getNode(ctx.editor, parentPath);
          const parentIsEmpty = parentNode && getNodeString(parentNode).length === 0;

          if (parentIsEmpty) {
            removeNodes(ctx.editor);
            select(ctx.editor, prevNodePath);
            return;
          } else {
            return select(ctx.editor, prevNodePath);
          }
        }
      }

      deleteBackward(unit);
    };

    return ctx.editor;
  },
});
