/* eslint-disable import/no-unused-modules */
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Modal } from '@/ui/Modal';

type JourneyRemoveLearnerModalProps = {
  open: boolean;
  onCancel: () => void;
  onRemove: (userToRemoveId: number) => void;
  targetParticipantInfo?: {
    name: string;
    id: number;
  };
};

const ContentStyled = styled.div`
  white-space: pre-line;
`;

export const JourneyRemoveLearnerModal = ({
  open,
  onCancel,
  onRemove,
  targetParticipantInfo,
}: JourneyRemoveLearnerModalProps): JSX.Element | null => {
  const { t } = useTranslation('journey');

  if (!targetParticipantInfo) return null;

  return (
    <Modal open={open} onClose={onCancel} size="mobile">
      <Modal.Title>{t('edit-learners-table-remove-modal-title')}</Modal.Title>

      <Modal.Contents>
        <ContentStyled>
          <Trans
            ns="journey"
            i18nKey={'edit-learners-table-remove-modal-content'}
            values={{ name: targetParticipantInfo.name }}
          />
        </ContentStyled>
      </Modal.Contents>
      <Modal.Actions>
        {<Modal.Action action={onCancel}>Cancel</Modal.Action>}
        <Modal.Action
          action={() => {
            onRemove(targetParticipantInfo.id);
            onCancel();
          }}
          primary
        >
          Remove
        </Modal.Action>
      </Modal.Actions>
    </Modal>
  );
};
