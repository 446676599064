import { useTranslation } from 'react-i18next';
import React from 'react';

import { Lng } from '@/i18n/resources';
import collegialLogo from '@/assets/logos/collegial_logo_stack.svg';
import euLogo from '@/assets/logos/eu_socialfund.png';
import { ButtonGroup } from '@/ui/ButtonGroup';
import { LanguagePicker } from '@/component/Footer/LanguagePicker';
import {
  Background,
  FooterWrapper,
  FooterLayout,
  LogoLink,
  CollegialLogo,
  EuLogo,
  Support,
  Copyright,
  LinksTop,
  LinksBottom,
  TextLink,
  AppVersion,
} from '@/component/Footer/styles';
import { Layout } from '@/ui/Layout';
import { CURRENT_YEAR } from '@/utils/currentYear';
import { Button } from '@/ui/Button';

export const Footer = ({
  appVersion,
  showEuLogo,
  showLanguagePicker,
  supportLink,
  supportLinkType = 'url',
  currentLanguage,
  languageChangePending,
  setLanguage,
  isInPreviewModal = false
}: {
  appVersion: string;
  showEuLogo: boolean;
  showLanguagePicker: boolean;
  supportLink: string;
  supportLinkType?: 'url' | 'email';
  currentLanguage: Lng;
  setLanguage: (nextLanguage: Lng) => void;
  languageChangePending: boolean;
  isInPreviewModal?: boolean
}): JSX.Element => {
  const { t } = useTranslation('footer');
  return (
    <Background>
      <Layout.ContentSection>
        <FooterWrapper>
          <FooterLayout.Top>
            <LogoLink target={isInPreviewModal ? "_blank" : "_self"} to="/">
              <CollegialLogo src={collegialLogo} alt={t('Collegial logo')} />
            </LogoLink>
            {showEuLogo && <EuLogo src={euLogo} />}
            <LinksTop>
              <ButtonGroup.Item>
                <TextLink target={isInPreviewModal ? "_blank" : "_self"} to="/">{t('Home', { ns: 'navbar' })}</TextLink>
              </ButtonGroup.Item>
              <ButtonGroup.Item>
                <TextLink target={isInPreviewModal ? "_blank" : "_self"} to="/user/my-learning/ongoing">{t('My learning', { ns: 'navbar' })}</TextLink>
              </ButtonGroup.Item>
            </LinksTop>
            <Support>
              <Button
                size="large"
                icon={['far', 'envelope']}
                $iconPosition="start"
                variant="secondary"
                target={supportLinkType === 'url' ? '_blank' : undefined}
                href={supportLinkType === 'email' ? `mailto:${supportLink}` : supportLink}
              >
                {t('Contact Support')}
              </Button>
            </Support>
          </FooterLayout.Top>
          <FooterLayout.Bottom>
            <Copyright>© {CURRENT_YEAR} Collegial</Copyright>
            <LinksBottom>
              <ButtonGroup.Item>
                <TextLink target={isInPreviewModal ? "_blank" : "_self"} to="/about">{t('About us', { ns: 'about' })}</TextLink>
              </ButtonGroup.Item>
              <ButtonGroup.Item>
                <TextLink target={isInPreviewModal ? "_blank" : "_self"} to="/terms">{t('Terms of Use', { ns: 'onboarding' })}</TextLink>
              </ButtonGroup.Item>
              <ButtonGroup.Item>
                <TextLink target={isInPreviewModal ? "_blank" : "_self"} to="/privacy">{t('Privacy Notes', { ns: 'onboarding' })}</TextLink>
              </ButtonGroup.Item>
              {showLanguagePicker && (
                <ButtonGroup.Item>
                  <LanguagePicker
                    currentLanguage={currentLanguage}
                    setLanguage={setLanguage}
                    languageChangePending={languageChangePending}
                  />
                </ButtonGroup.Item>
              )}
            </LinksBottom>
          </FooterLayout.Bottom>
        </FooterWrapper>
      </Layout.ContentSection>
      <AppVersion>{appVersion}</AppVersion>
    </Background>
  );
};
