import _isEqual from 'lodash.isequal';
import { createPlatePlugin } from '@udecode/plate-common/react';

const KEY = 'trim-end-paragraphs';

const EMPTY_PARAGRAPH_TEMPLATE = {
  type: 'paragraph',
  children: [{ text: '' }],
};

/**
 * Trim empty paragraphs at the end, so that there is no extra padding at the bottom.
 *
 * See more in https://collegial.atlassian.net/browse/PT-4567
 */
export const TrimEndParagraphsPlugin = createPlatePlugin({
  key: KEY,
  normalizeInitialValue: (ctx) => {
    let value = ctx.value;

    if (!value.length || value.length === 1) return value;

    // Iterate from the last to first item, until first item - until all empty paragraphs are not removed.
    // The only exception is the first paragraph
    for (let i = value.length - 1; i > 1; i--) {
      const lastItem = value[i];

      if (lastItem?.type === EMPTY_PARAGRAPH_TEMPLATE.type && _isEqual(lastItem?.children, EMPTY_PARAGRAPH_TEMPLATE.children)) {
        value = value.slice(0, i);
      } else {
        break;
      }
    }
    return value;
  },
});
