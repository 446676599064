/* eslint-disable import/no-unused-modules */
import React from 'react';

import { Select } from '../..';
import { SelectProps } from '../../atoms/Select/types';

type UncontrolledSelectProps = Omit<SelectProps, 'value' | 'onChange'>;

export const UncontrolledSelect = (props: UncontrolledSelectProps): JSX.Element => {
  return <Select value="" onChange={() => {}} {...props} />;
};
