import styled from 'styled-components';
import isNil from 'lodash.isnil';

import { QuestionBlock } from '@/administration/pages/Course/store/block/types';
import { AnswerOptionButton } from './AnswerOptionButton';

export type ViewQuestionPageProps = {
  questionBlock?: QuestionBlock;
  onAnswerSelected: (value: number) => void;
  selectedAnswer?: number;
};

const RootStyled = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.primary.onMain};
  color: ${({ theme }) => theme.colors.neutral.onContainer};
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 72px 84px;
  height: 100%;
  max-width: 920px;
  justify-content: center;
  align-items: center;
`;

const TitleStyled = styled.div`
  ${(p) => p.theme.typography.title2};
  color: ${(p) => p.theme.colors.neutral.onBackground};
`;

const DescriptionStyled = styled.div`
  ${(p) => p.theme.typography.body4};
`;

const HeaderStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
  width: 100%;
`;

const ListStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

export const RadioQuestionPage = ({ questionBlock, onAnswerSelected, selectedAnswer }: ViewQuestionPageProps): JSX.Element => {
  const hasSelectedAnswer = !isNil(selectedAnswer);

  return (
    <RootStyled>
      <HeaderStyled>
        <TitleStyled>{questionBlock?.title}</TitleStyled>
        <DescriptionStyled>{questionBlock?.description}</DescriptionStyled>
      </HeaderStyled>
      <ListStyled>
        {questionBlock?.answerOptions.map((option) => (
          <AnswerOptionButton
            key={option.value}
            onClick={() => onAnswerSelected(option.value)}
            disabled={hasSelectedAnswer && option.value !== selectedAnswer}
            selected={hasSelectedAnswer && option.value === selectedAnswer}
          >
            {option.title}
          </AnswerOptionButton>
        ))}
      </ListStyled>
    </RootStyled>
  );
};
