import React from 'react';
import styled, { useTheme, css } from 'styled-components';

import { Icon } from '@/ui/Icon';
import { makeNamespacedComponent } from '@/ui/utils';
import { Skeleton } from '@/ui/Skeleton';
import { DrawerItemType, PAGE_TYPE_TO_ICON } from '@/features/course/admin/types/drawer';
import { PageVariant } from '@/features/course/admin/types/page';
import { BaseActionsSelect } from '@/administration/pages/Journey/common/atoms/LearningCard/variants/DnDLearningCard/ActionsSelect/BaseActionsSelect';
import { ActionOptionStyled } from '@/administration/pages/Journey/common/atoms/LearningCard/variants/DnDLearningCard/ActionsSelect/styles';
import { useCourseDrawerItemEdit } from '@/features/course/admin/hooks/useCourseDrawerItemEdit';
import { getDrawerItemTitleShorten } from '@/features/course/admin/utils/getDrawerItemTitleShorten';

const HoverCSS = css<{ active?: boolean }>`
  background: ${({ theme, active }) => !active && theme.colors.states.hover5};

  & [data-icon='ellipsis-vertical'] {
    opacity: 1;
  }

  & [data-icon='plus'] {
    opacity: 1;
  }
`;

const ActiveCSS = css`
  background: ${({ theme }) =>
    `linear-gradient(0deg, ${theme.colors.states.hover6} 0%, ${theme.colors.states.hover6} 100%), ${theme.tones.neutral[99]};`};
`;

const IsChildCSS = css`
  padding-left: 32px;
`;

/** TODO Make it a part of a separate layout */
export const RootStyled = styled.div<{ active?: boolean; isChild?: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-radius: 4px;
  transition: all 0.2s;
  gap: 8px;
  height: 68px;

  & [data-icon='ellipsis-vertical'] {
    opacity: 0;
  }

  & [data-icon='plus'] {
    opacity: 0;
  }

  &:hover {
    ${HoverCSS}
  }

  ${({ active }) => active && ActiveCSS};

  ${({ isChild }) => isChild && IsChildCSS};

  &:active {
    ${ActiveCSS}
  }
`;

const ActionsStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & button {
    width: 24px;
    height: 24px;
  }
`;

const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.typography.title4};
  color: ${({ theme }) => theme.colors.neutral.onBackground};
  gap: 8px;
  overflow: hidden;
`;

const TitleText = styled.div`
  display: block;
`;

const IconWrapStyled = styled.div<{ type: DrawerItemType }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme, type }) => {
    switch (type) {
      case 'completed':
        return `none`;
      case PageVariant.TEXT:
        return theme.colors.tertiary.container;
      case PageVariant.QUESTION:
        return theme.colors.tertiary.main;
      case PageVariant.PRACTICAL_ASSESSMENT_TEXT:
        return theme.colors.neutral.background;
      case PageVariant.PRACTICAL_ASSESSMENT_DOCUMENT:
        return theme.colors.neutral.background;
      case PageVariant.REACTION:
        return theme.colors.secondary.outline;
      // actual ones go below
      case PageVariant.MODULE:
        return theme.tones.neutral[100];
      case PageVariant.PAGE:
        return theme.colors.tertiary.container;
      case PageVariant.REFLECTION:
        return theme.colors.secondary.container;
      case PageVariant.QUIZ:
        return '#FFC3E2';
      case PageVariant.RESULT:
      default:
        return theme.colors.primary.container;
    }
  }};
  color: ${({ theme, type }) => {
    switch (type) {
      case PageVariant.QUESTION:
        return theme.colors.primary.onMain;
      default:
        return theme.colors.neutral.onBackground;
    }
  }};
  flex: 1 0 24px;
  width: 24px;
  height: 24px;
  border-radius: 4px;
`;

const RenameTextField = styled.input`
  ${({ theme }) => theme.typography.title4};
  height: 36px;
  background: transparent;
  width: 100%;
  border-radius: 4px;
  border: 2px solid ${({ theme }) => theme.colors.primary.outline};
  outline: none;
`;

const ActionIcon = styled(Icon)`
  width: 14px;
`;

const EditableTitleContainerStyled = styled(TitleWrap)`
  cursor: pointer;
`;

export type EditDrawerItemProps = {
  id: string;
  title: string;
  type: PageVariant;
  active?: boolean;
  onRemoveClick: (id: string) => void;
  onSelect: (id: string) => void;
  onTitleChange: (name: string) => void;
  removalForbidden?: boolean;
  hasError?: boolean;
  isChild?: boolean | null;
};

enum ActionButtonOption {
  Delete = 'Delete',
  Rename = 'Rename',
}

const PARENT_TITLE_LIMIT = 36;
const CHILD_TITLE_LIMIT = 27;

const _CourseDrawerStandaloneItem = ({
  id,
  title,
  active,
  type,
  isChild,
  removalForbidden,
  hasError,
  onRemoveClick,
  onTitleChange,
  onSelect,
}: EditDrawerItemProps): JSX.Element => {
  const theme = useTheme();
  const {
    titleInputRef,
    containerRef,
    updatedTitle,
    titleEditingEnabled,
    onUpdatedTitleChange,
    onEnableTitleEditing,
    onEditorKeyUp,
  } = useCourseDrawerItemEdit({ onTitleChangeSubmit: onTitleChange, title });

  return (
    <RootStyled onClick={() => onSelect(id)} active={!!active} ref={containerRef} isChild={!!isChild}>
      <EditableTitleContainerStyled onDoubleClick={onEnableTitleEditing}>
        <IconWrapStyled type={type}>
          <Icon size="xs" icon={PAGE_TYPE_TO_ICON[type]} />
        </IconWrapStyled>
        {titleEditingEnabled ? (
          <RenameTextField
            ref={titleInputRef}
            onKeyUp={onEditorKeyUp}
            value={updatedTitle}
            onChange={(e) => onUpdatedTitleChange(e.target.value)}
          />
        ) : (
          <TitleText>{getDrawerItemTitleShorten(title, !!isChild, PARENT_TITLE_LIMIT, CHILD_TITLE_LIMIT)}</TitleText>
        )}
      </EditableTitleContainerStyled>
      {!titleEditingEnabled && (
        <ActionsStyled
          onClick={(e) => {
            // preventing click event to trigger click event on the root
            e.stopPropagation();
          }}
        >
          {!!hasError && <Icon icon={['fas', 'circle-exclamation']} color={theme.colors.error.main} />}
          <BaseActionsSelect
            onChange={(value) => {
              switch (value) {
                case ActionButtonOption.Rename:
                  onEnableTitleEditing();
                  return;
                case ActionButtonOption.Delete:
                  onRemoveClick(id);
                  return;
                default:
                  return;
              }
            }}
            forcedPosition="bottom-left"
            buttonVariant="buddySmall"
          >
            <ActionOptionStyled value={ActionButtonOption.Rename} prefix={<ActionIcon icon={['far', 'pen']} />}>
              Rename
            </ActionOptionStyled>
            {!removalForbidden && (
              <ActionOptionStyled value={ActionButtonOption.Delete} prefix={<ActionIcon icon={['far', 'trash']} />}>
                Remove
              </ActionOptionStyled>
            )}
          </BaseActionsSelect>
        </ActionsStyled>
      )}
    </RootStyled>
  );
};

const LoadingState = (): JSX.Element => <Skeleton width={312} height={36} />;

export const CourseDrawerStandaloneItem = makeNamespacedComponent(_CourseDrawerStandaloneItem, {
  LoadingState,
});
