import styled from 'styled-components';

import { Icon } from '@/ui/Icon';
import { IconStyledProps } from './types';

export const IconStyled = styled(Icon)<IconStyledProps>`
  ${({ variant }) => (variant === 'event' ? ` height: 16px;` : `height: 12px;`)}
  path {
    fill: ${({ variant, theme }) => {
      if (variant === 'badge-archived') {
        return `${theme.colors.neutral.backgroundVariant}`;
      }
      return variant === 'meta' ? `${theme.colors.neutral.onContainer}` : `${theme.colors.neutral.onBackground}`;
    }};
  }
`;
