import styled from 'styled-components';

import { cvar } from '@/styles/GlobalStyle';
import { Overline } from '@/components/Typography/Typography';
import { BUTTON_HEIGHT_ENUM } from '@/components/Button/components/BaseButton';

export const Tab = styled.div<{ isSelected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  background-color: ${({ isSelected }) => isSelected && cvar('color-white')};
`;

export const TabsContainer = styled.div`
  margin: ${cvar('gap-sm')};
  border: ${cvar('border-style')};
  display: flex;
  flex-direction: row;
  height: ${BUTTON_HEIGHT_ENUM.default}rem;

  ${Tab} + ${Tab} {
    border-left: ${cvar('border-style')};
  }
`;

export const Title = styled(Overline)<{ isSelected: boolean }>`
  color: ${({ isSelected }) => (isSelected ? cvar('color-black') : cvar('color-text'))};
`;

export const ListElement = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: ${BUTTON_HEIGHT_ENUM.default}rem;
  padding: 0 ${cvar('gap-sm')} 0 ${cvar('gap-sm')};
  color: ${cvar('color-text')};
`;

export const ListContainer = styled.div`
  border-top: ${cvar('border-style')};
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.04) 100%);

  ${ListElement} + ${ListElement} {
    border-top: ${cvar('border-style')};
  }
`;

export const SelectedCategory = styled(ListElement)`
  border-top: ${cvar('border-style')};
`;
