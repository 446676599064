import { ApolloQueryResult, FetchResult, gql, useMutation, useQuery } from '@apollo/client';
import { PureQueryOptions } from '@apollo/client/core';

import { HookResult } from '@/store/apolloClient';
import { FilteredProductOrdersFlat } from '../types/orderHistory';
import { OrderStates, ProductOrderFlat } from '../types/administration';
import { useCurrentUserAccess } from '@/store/currentUser';

const getProductOrdersFlatQuery = gql`
  query getProductOrdersFlat($productId: Int!, $stateIds: [Int]!, $page: Int, $pageSize: Int) {
    getProductOrdersFlat(productId: $productId, stateIds: $stateIds, page: $page, pageSize: $pageSize) {
      total
      totalPages
      productOrders {
        orderId
        productId
        productVariantId
        spaceId
        stateId
        userId
        username
        email
        realm
        profileImage
      }
    }
  }
`;

const filteredOrdersQuery = gql`
  query getAllOrdersFiltered($stateId: Int, $productId: Int, $pageSize: Int, $page: Int, $userId: Int) {
    getAllOrdersFiltered(stateId: $stateId, productId: $productId, pageSize: $pageSize, page: $page, userId: $userId) {
      total
      totalPages
      productOrders {
        created
        orderId
        productId
        productVariantId
        spaceId
        stateId
        stateName
        userId
        user {
          username
          id
          email
        }
        products {
          id
          label
          name
          productId
        }
      }
    }
  }
`;

const filteredApprovalsQuery = gql`
  query getApprovalsFiltered($stateId: Int, $productId: Int, $pageSize: Int, $page: Int, $userId: Int) {
    getApprovalsFiltered(stateId: $stateId, productId: $productId, pageSize: $pageSize, page: $page, userId: $userId) {
      total
      totalPages
      productOrders {
        created
        orderId
        productId
        productVariantId
        spaceId
        stateId
        stateName
        note
        userId
        user {
          username
          id
          email
        }
        products {
          id
          label
          name
          productId
        }
      }
    }
  }
`;

const getAllOrdersByStateQuery = gql`
  query getAllOrdersByState($stateId: Int!) {
    getAllOrdersByState(stateId: $stateId) {
      id
    }
  }
`;

const updateOrderMutation = gql`
  mutation updateOrder($orderId: Int!, $stateId: Int!) {
    updateOrder(orderId: $orderId, stateId: $stateId) {
      orderId
    }
  }
`;

/**
 * Hook used from outside of AdminContext (navbar)
 */
export const useContentRequestsCount = (): number => {
  const { isAdmin } = useCurrentUserAccess();

  const { data } = useQuery(getAllOrdersByStateQuery, {
    variables: {
      stateId: OrderStates.PENDING_APPROVAL,
    },
    skip: !isAdmin,
  });

  return data?.getAllOrdersByState?.length || 0;
};

type ApproveRejectOrderFunction = (orderId: number) => Promise<
  FetchResult<{
    updateOrder: {
      orderId: number;
    };
  }>
>;

/*
  The application counter obtains its value from orders_flat. However, orders_flat doesn't contain information
  about application forms ("note" column), so we still need to fall back to older endpoints for applications themselves.
 */
export const useUpdateOrder = (
  refetchQueries: Array<string | PureQueryOptions> = []
): {
  approve: ApproveRejectOrderFunction;
  reject: ApproveRejectOrderFunction;
  notApproved: ApproveRejectOrderFunction;
  loading: boolean;
} => {
  const [updateOrder, { loading }] = useMutation(updateOrderMutation);

  const updateOrderWrapper = (orderId: number, stateId: number) =>
    updateOrder({
      variables: {
        orderId,
        stateId,
      },
      refetchQueries: [
        ...refetchQueries,
        {
          query: getAllOrdersByStateQuery,
          variables: {
            stateId,
          },
        },
      ],
    });

  return {
    approve: (orderId: number) => updateOrderWrapper(orderId, OrderStates.APPROVED),
    reject: (orderId: number) => updateOrderWrapper(orderId, OrderStates.CANCELLED),
    notApproved: (orderId: number) => updateOrderWrapper(orderId, OrderStates.NOT_APPROVED),
    loading,
  };
};

interface ApplyOrderRulesType {
  loading: boolean;
  applyOrderRules: (orderId: number) => Promise<FetchResult<{ orderId: number }>>;
}
export const useApplyOrderRules = (): ApplyOrderRulesType => {
  const [applyOrderRules, { loading }] = useMutation<{ orderId: number }>(gql`
    mutation applyOrderRules($orderId: Int!) {
      applyOrderRules(orderId: $orderId) {
        orderId
      }
    }
  `);

  return {
    applyOrderRules: (orderId: number) =>
      applyOrderRules({
        variables: {
          orderId,
        },
      }),
    loading,
  };
};

interface ProductOrdersResponse extends HookResult {
  refetch: (variables?: { productId?: number; stateIds?: number[]; page?: number; pageSize?: number }) => Promise<
    ApolloQueryResult<{
      getProductOrdersFlat: {
        total: number;
        totalPages: number;
        productOrders: readonly ProductOrderFlat[];
      };
    }>
  >;
  productOrders: readonly ProductOrderFlat[];
  totalPages: number;
  total: number;
}

export const useProductOrders = ({
  page = 0,
  pageSize = 15,
  productId,
  stateIds,
}: {
  page?: number;
  pageSize?: number;
  productId: number;
  stateIds: number[];
}): ProductOrdersResponse => {
  const { data, error, refetch, loading, networkStatus } = useQuery<{
    getProductOrdersFlat: {
      total: number;
      totalPages: number;
      productOrders: readonly ProductOrderFlat[];
    };
  }>(getProductOrdersFlatQuery, {
    skip: !productId,
    variables: {
      pageSize: pageSize,
      page: page,
      productId,
      stateIds,
    },
  });

  if (data && data.getProductOrdersFlat) {
    return {
      productOrders: data.getProductOrdersFlat.productOrders || [],
      totalPages: data.getProductOrdersFlat.totalPages,
      total: data.getProductOrdersFlat.total,
      loading,
      error,
      refetch,
      networkStatus,
    };
  }

  return {
    productOrders: [],
    totalPages: 0,
    total: 0,
    loading,
    error,
    refetch,
    networkStatus,
  };
};

interface FilteredProductOrdersResponse extends HookResult {
  refetch: (variables?: { stateId?: number; productId?: number; pageSize?: number; page?: number; userId?: number }) => Promise<
    ApolloQueryResult<{
      getAllOrdersFiltered: {
        total: number;
        totalPages: number;
        productOrders: FilteredProductOrdersFlat[];
      };
    }>
  >;
  orders: FilteredProductOrdersFlat[];
  total: number;
  totalPages: number;
}

export const useFilteredProductOrders = ({
  page = 0,
  pageSize = 20,
  productId,
  stateId,
  userId,
}: {
  page?: number;
  pageSize?: number;
  productId?: number;
  stateId?: number;
  userId?: number;
}): FilteredProductOrdersResponse => {
  const { data, refetch, loading, networkStatus } = useQuery<{
    getAllOrdersFiltered: {
      total: number;
      totalPages: number;
      productOrders: FilteredProductOrdersFlat[];
    };
  }>(filteredOrdersQuery, {
    variables: {
      stateId,
      productId,
      page: Math.ceil(page * pageSize),
      pageSize,
      userId,
    },
  });
  if (data && data.getAllOrdersFiltered) {
    return {
      orders: data.getAllOrdersFiltered.productOrders || [],
      total: data.getAllOrdersFiltered.total,
      totalPages: data.getAllOrdersFiltered.totalPages,
      loading,
      networkStatus,
      refetch,
    };
  }

  return {
    orders: [],
    total: 0,
    totalPages: 0,
    loading,
    networkStatus,
    refetch,
  };
};

interface FilteredApprovalsResponse extends HookResult {
  refetch: (variables?: { stateId?: number; productId?: number; pageSize?: number; page?: number; userId?: number }) => Promise<
    ApolloQueryResult<{
      getApprovalsFiltered: {
        total: number;
        totalPages: number;
        productOrders: FilteredProductOrdersFlat[];
      };
    }>
  >;
  productOrders: FilteredProductOrdersFlat[];
  total: number;
  totalPages: number;
}

export const useFilteredApprovals = ({
  page = 0,
  pageSize = 20,
  productId,
  stateId,
  userId,
}: {
  page?: number;
  pageSize?: number;
  productId?: number;
  stateId?: number;
  userId?: number;
}): FilteredApprovalsResponse => {
  const { data, refetch, loading, networkStatus } = useQuery<{
    getApprovalsFiltered: {
      total: number;
      totalPages: number;
      productOrders: FilteredProductOrdersFlat[];
    };
  }>(filteredApprovalsQuery, {
    variables: {
      stateId,
      productId,
      page: Math.ceil(page * pageSize),
      pageSize,
      userId,
    },
  });
  if (data && data.getApprovalsFiltered) {
    return {
      productOrders: data.getApprovalsFiltered.productOrders || [],
      total: data.getApprovalsFiltered.total,
      totalPages: data.getApprovalsFiltered.totalPages,
      loading,
      networkStatus,
      refetch,
    };
  }

  return {
    productOrders: [],
    total: 0,
    totalPages: 0,
    loading,
    networkStatus,
    refetch,
  };
};
