import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextArea } from '@/ui/TextField';
import { TextFieldName } from '@/features/course/admin/constants/settingsModal';
import { Course } from '@/features/course/admin/types/course';

type CourseSettingsModalFieldTeaserProps = {
  control: Control<Course>;
};

export const CourseSettingsModalFieldTeaser = ({ control }: CourseSettingsModalFieldTeaserProps): JSX.Element => {
  const { t } = useTranslation('catalog');

  return (
    <Controller
      control={control}
      rules={{ required: true }}
      name={TextFieldName.Teaser}
      render={({ field: { onChange, name, value }, fieldState: { error } }) => (
        <TextArea
          onChange={onChange}
          name={name}
          value={value || ''}
          fullWidth
          rows={4}
          label={t('teaser')}
          maxLength={160}
          error={
            error && {
              message: t('teaser-error'),
            }
          }
        />
      )}
    />
  );
};
