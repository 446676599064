import { useMutation } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import { getParser } from 'bowser';
import { Trans, useTranslation } from 'react-i18next';
import { Composition } from 'atomic-layout';
import clsx from 'clsx';
import './courseDetailsPage.scss';
import { Navigate, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';

import { LongDescription, MetaWrapper, SectionWithTopBorder } from '@/pages/learning/CourseDetails/components/styles';
import { SpacePermission, Variant as VariantType } from '../../../types/learning/learning-catalog';
import { toHtml } from '@/component/customEditor/logic/serialization';
import { ApplicationForm } from './components/ApplicationForm/ApplicationForm';
import { getProductQuery, useProduct, usePurchaseProduct } from '@/store/learning-catalog';
import { Variant } from '@/component/Variants/Variant';
import { getInventoryQuery, useCancelOrder, useInventory } from '@/store/inventory';
import { CoursePageDiscussion } from '@/component/Discussions/CoursePageDiscussion';
import { SharedSnackbarContext, SnackbarType } from '@/component/SharedSnackbar/SharedSnackbar';
import { courseraPageQuery, requestCourseraLicenseMutation } from '@/pages/Provider/queries';
import { getProviderMeta, isProviderAccessProduct, mapProviderLink, ProviderAccessStatus, ProviderList } from '@/store/providers';
import { Button } from '@/components/Button/Button';
import {
  trackMarkAsCompletedLearningPathClicked,
  trackUnenrollLearningPathItemClicked,
} from '@/utils/tracking/courseDetailsPage';
import { useLearningPathCompleted, useSetLearningCompleted } from '@/store/analytics/learning-path-progress';
import { getTeams } from '@/administration/pages/ProductEditor/component/CourseTeams';
import { OrderUser } from '@/administration/types/administration';
import { useCurrentUser } from '@/store/currentUser';
import { CoursePageParticipants } from './components/CoursePageParticipants';
import { useHasSpacePermission } from '@/store/spaces';
import { MetaBlock } from './components/MetaBlock';
import { LoadingHero } from '@/component/LoadingHero';
import { ReflectionsTab } from './components/ReflectionsTab';
import { CancelButton } from './components/CancelButton';
import { Tab } from '@/component/Tabs/Tab';
import { CoursePageContext } from './CoursePageContext';
import { PostPage } from '../../PostPage';
import { Enroll } from './components/Enroll';
import { replaceNbsps } from '@/utils/text';
import { SaveToMyLearning } from '../../user/MyLearning/components/SaveToMyLearning';
import { useProviderAccess } from '@/hooks/useProviderAccess';
import { LearningStatus } from '@/types/learning';
import { getTranslatedErrorMessages } from '@/utils/gqlErrors';
import { LinkButton } from '@/components/Button/LinkButton';
import { ButtonList } from '@/components/Button/ButtonList';
import { RouterLinkButton } from '@/components/Button/RouterLinkButton';
import { Box } from '@/components/Box/Box';
import { Tag } from '@/components/Tag/Tag';
import { useModalContext } from '@/components/Modal/ModalContext';
import { Header4, Overline } from '@/components/Typography/Typography';
import { trackClickedGoToLearning, trackLearningItemTabClicked } from '@/utils/tracking/learnings';
import { ThumbnailImage } from '@/pages/learning/CourseDetails/components/ThumbnailImage';
import { Icon } from '@/components/Icon';
import { sortVariants } from '@/pages/learning/CourseDetails/util';
import { ADMIN_ROUTE_PATH } from '@/administration/constants/adminRoutePaths';
import { LearningPathFrame } from './components/LearningPathFrame';
import { BackToParentButton } from './components/BackToParentButton';
import { useJourneyEnhancedNavigation } from '@/store/v2/journeys/useJourneyEnhancedNavigation';
import { UpNextLearning } from '@/administration/pages/Journey/common/atoms/UpNextLearning';
import { FEATURE, useFeatureEnabled } from '@/feature-toggles';
import {
  LockedLearningWarningModal,
  useLockedLearningWarningModalProps,
} from '@/administration/pages/Journey/common/modals/LockedLearningWarningModal';

type TParams = 'id' | 'courseName';

function isSafari(): boolean {
  const bowser = getParser(navigator.userAgent);
  return bowser.isBrowser('safari');
}

export const CourseDetailsPage = (): JSX.Element => {
  const { t } = useTranslation(['learning', 'catalog']);
  const navigate = useNavigate();
  const params = useParams<TParams>();
  const location = useLocation();
  const linkInsteadOfIframe = isSafari();
  const parsedId = params.id ? parseInt(params.id, 10) : 0;
  const postPathRegex = /\/(post|reflection)\/(\d+)\//;
  const { openSnackbar } = useContext(SharedSnackbarContext);
  const { product, loading } = useProduct(params.id);
  const [purchaseProduct, { loading: loadingPurchase }] = usePurchaseProduct(product && product.id);
  const isLearnifierNewUiEnabled = useFeatureEnabled(FEATURE.UI_LEARNIFIER_NEW_UI);
  const {
    inventory: { allProgress: inventoryAll, completed: inventoryCompleted },
    loading: loadingInventory,
    refetch: refetchInventory,
  } = useInventory();
  const refetchQueries = [{ query: getProductQuery, variables: { id: product?.id } }, { query: getInventoryQuery }];
  const [requestCourseraLicense, { loading: courseraLicenseLoading }] = useMutation(requestCourseraLicenseMutation, {
    refetchQueries: [{ query: courseraPageQuery, variables: { provider: ProviderList.COURSERA, currentRealm: window.realm } }],
  });
  const {
    isLearningPathCompleted: learningPathCompletionState,
    refetch: refetchLearningPathCompletion,
    loading: learningPathCompletionLoading,
  } = useLearningPathCompleted(parsedId);
  const { setLearningPathCompleted, loading: learningPathCompletedLoading } = useSetLearningCompleted(parsedId, () => {
    refetchLearningPathCompletion();
    refetchInventory();
  });

  const { nextItem, journeyId, journeyTitle, currentItem } = useJourneyEnhancedNavigation({
    id: Number(params.id),
    isProduct: true,
  });

  const learningLockedModalProps = useLockedLearningWarningModalProps({
    parentJourneyId: journeyId,
    parentJourneyTitle: journeyTitle,
    isCurrentItemLock: currentItem?.isLocked,
  });

  const [cancelOrder, { loading: loadingCancel }] = useCancelOrder(product && product.id);
  const { user, loading: loadingCurrentUser } = useCurrentUser();
  const { data: isSpaceAdmin } = useHasSpacePermission(product?.space?.id || 0, SpacePermission.HasWriteAccessToLearningContent);
  const { showConfirmationModal, showModal } = useModalContext();
  const [initialProviderStatus, setInitialProviderStatus] = useState<ProviderAccessStatus | null>(null);
  const providerMeta = getProviderMeta(product?.meta?.PROVIDER || '');
  const isAllowedForManualCompletion = [ProviderList.UDACITY, ProviderList.AUTOLIV, ProviderList.IMD].includes(
    providerMeta?.provider as ProviderList
  );
  const setInitialProviderStatusIfReady = (status: ProviderAccessStatus) => {
    if (providerMeta?.provider === ProviderList.COURSERA && status && !initialProviderStatus) {
      setInitialProviderStatus(status);
    }
  };

  const providerStatus = useProviderAccess(providerMeta?.provider, setInitialProviderStatusIfReady);
  const providerHasAccess = providerStatus === ProviderAccessStatus.HAS_ACCESS;
  const hasAccessBasedOnProvider = providerMeta?.provider === ProviderList.COURSERA ? providerHasAccess : true;

  const inventoryProduct = inventoryAll.find((product) => product.productId === Number(params.id));
  // Use completed inventory as a fallback
  const isLearningPathCompleted =
    learningPathCompletionState || !!inventoryCompleted.find((c) => c.productId === Number(params.id));

  const [selectedVariantId, setSelectedVariantId] = useState<undefined | number>(undefined);
  const [variantExpired, setVariantExpired] = useState(false);

  const isPending = product?.isPending;
  const hasEnrolled = !loadingPurchase && !isPending && Boolean(inventoryProduct) && hasAccessBasedOnProvider;
  const canViewCourseTabs = hasEnrolled || isSpaceAdmin;
  const service = product?.services[0];
  // Hack for LDA 1 + 2
  const customLinkOrPluralsight = !isPending && mapProviderLink(providerMeta?.provider || '', product?.meta?.CUSTOM_LINK || '');
  const learningPathLink = inventoryProduct?.accessLink || null;
  const canViewLearningPath = hasEnrolled && learningPathLink && !customLinkOrPluralsight;
  const isLearningPathIframeVisible = location.pathname.includes('/learning-path') && canViewLearningPath;
  const isManualCompletedButtonVisible = product?.orderId && hasEnrolled && isAllowedForManualCompletion;

  const hasVariants = (product?.variants?.filter(({ enabled }) => enabled).length ?? 0) > 0;
  const customLink = product?.meta?.CUSTOM_LINK;

  const continueMessage =
    providerMeta?.provider !== ProviderList.COLLEGIAL ? `Continue on ${providerMeta?.provider.toString()}` : 'Continue';
  const customLinkText = product?.meta.ENROLL_BUTTON_TEXT || (customLink ? continueMessage : t('Go to course'));
  const conditionalBtnLinkStyle = isManualCompletedButtonVisible && isLearningPathCompleted ? 'secondary' : 'primary';
  const conditionalBtnLinkText = isManualCompletedButtonVisible && isLearningPathCompleted ? t('View Completed') : customLinkText;
  const [hasClickedEnrollButton, setHasClickedEnrollButton] = useState(false);

  useEffect(() => {
    const variant = product?.variants?.find((v) => v.id === selectedVariantId);

    if (variant && variant.validTo) {
      if (Date.parse(variant.validTo) < Date.now()) {
        setVariantExpired(true);
      }
    }
    setVariantExpired(false);
  }, [selectedVariantId]);

  useEffect(() => {
    if (product?.variants?.length === 1) {
      setSelectedVariantId(product.variants[0].id);
    }
  }, [product]);

  useEffect(() => {
    if (
      initialProviderStatus &&
      providerStatus &&
      initialProviderStatus !== providerStatus &&
      providerMeta?.provider === ProviderList.COURSERA
    ) {
      if (providerStatus === ProviderAccessStatus.HAS_ACCESS) {
        openSnackbar({
          type: SnackbarType.SUCCESS,
          message: t('provider-access-approved', { provider: providerMeta.name }),
          isDismissive: true,
        });
      } else if (providerStatus === ProviderAccessStatus.PENDING_ACCESS) {
        openSnackbar({ type: SnackbarType.WARNING, message: t('provider-access-pending', { provider: providerMeta.name }) });
      }
    }
  }, [initialProviderStatus, providerStatus]);

  useEffect(() => {
    if (!postPathRegex.test(location.pathname)) {
      if (
        !['learning-path', 'discussions', 'reflections', 'participants', 'description'].some((m) =>
          location.pathname.includes('/'.concat(m))
        )
      ) {
        if (canViewLearningPath) {
          navigate({ pathname: `learning-path`, search: location.search }, { state: location.state });
        } else {
          navigate({ pathname: 'description', search: location.search }, { state: location.state, replace: true });
        }
      }
    }
  }, [location.pathname, canViewLearningPath]);

  useEffect(() => {
    if (hasEnrolled && canViewLearningPath && hasClickedEnrollButton) {
      navigate('learning-path');
    }
  }, [hasEnrolled, canViewLearningPath, hasClickedEnrollButton]);

  if (loading || loadingInventory || loadingCurrentUser) {
    return <LoadingHero />;
  }

  if (!product) return <Navigate replace to="/404" />;

  if (product.status === LearningStatus.ARCHIVED) {
    return <Navigate replace to={{ pathname: '/404' }} state={{ isArchived: true }} />;
  }

  const providerProductApplyDisabled =
    providerMeta?.requiresExplicitRegistration && !providerHasAccess && !isProviderAccessProduct(product.id, inventoryAll);

  const applyDisabled =
    loadingPurchase ||
    loadingInventory ||
    Boolean(inventoryProduct) ||
    (hasVariants && (!selectedVariantId || variantExpired)) ||
    providerProductApplyDisabled;

  const hasOrderedVariant = (variantId?: number) => product.productVariantId === variantId;

  const promptCancelOrder = () =>
    showConfirmationModal({
      action: () => {
        trackUnenrollLearningPathItemClicked();
        cancelOrder({
          variables: { orderId: product.orderId! },
          refetchQueries,
        });
        navigate('description', { state: location.state });
      },
      message: (
        <Trans t={t} i18nKey="remove-course-prompt" values={{ name: product.name }} components={[<br key="br" />]} ns="catalog" />
      ),
    });

  const handleApplication = async () => {
    if (product.flags.requiresApplication) {
      return showModal({
        children: <ApplicationForm {...{ navigate, product, selectedVariantId }} />,
        size: 'large',
      });
    }

    const purchasedProduct: { id: number; productVariantId?: number } = { id: product.id };
    if (selectedVariantId) purchasedProduct.productVariantId = selectedVariantId;

    const { errors } = await purchaseProduct({ variables: { products: [purchasedProduct] }, refetchQueries });

    if (errors) {
      const messages = getTranslatedErrorMessages(errors);
      openSnackbar({
        message: t('serverMessages::generic|:error-occurred', { message: messages.join('') }),
        type: SnackbarType.DANGER,
      });
    } else if (product.meta.CONFIRM_MESSAGE) {
      openSnackbar({
        message: product.meta.CONFIRM_MESSAGE,
        type: SnackbarType.SUCCESS,
        isDismissive: true,
      });
    }
    setHasClickedEnrollButton(true);
  };

  const handleMarkCompleted = () => {
    trackMarkAsCompletedLearningPathClicked({ learningTitle: product.name, learningType: 'learning-path' });
    setLearningPathCompleted();
  };

  const description = (() => {
    try {
      return toHtml(product.meta.DESCRIPTION);
    } catch (e) {}
    return product.meta.DESCRIPTION || '';
  })();

  const hasMetaData = () => {
    const { LANGUAGE, LOCATION, INSTRUCTORS, INSTITUTION, TOTAL_EFFORT, LEVEL, FORMAT } = product.meta;
    return Boolean(LANGUAGE && LOCATION && INSTRUCTORS && INSTITUTION && TOTAL_EFFORT && LEVEL && FORMAT);
  };

  const teamNo = (() => {
    const parsedTeams = getTeams(product.meta.TEAMS);
    if (!parsedTeams) return undefined;
    for (const [key, value] of parsedTeams.entries()) {
      const member = value.filter((member: OrderUser) => member.id === user?.id);
      if (member.length) return key + 1;
    }

    return undefined;
  })();

  return (
    <CoursePageContext.Provider value={{ spaceId: product?.space.id, productId: product.id, isAdmin: isSpaceAdmin }}>
      {learningLockedModalProps && <LockedLearningWarningModal {...learningLockedModalProps} />}
      <div className="hero is-cls">
        <div className="hero-body">
          <div className="container">
            <BackToParentButton isProduct />
            <Composition templateCols="1fr" templateColsMd="2fr 1fr">
              <div styleName="title-wrapper">
                <h1 className="header1 has-bottom-margin" styleName="course-title">
                  {replaceNbsps(product.name)}
                </h1>
              </div>
              <ThumbnailImage image={product.meta.IMAGE} provider={product.meta.PROVIDER} />
            </Composition>

            <Box boxShadow paddingSize="none">
              <Composition templateCols="1fr" templateColsMd="2fr 1fr">
                <LongDescription hasMeta={hasMetaData()} className="content preamble is-marginless">
                  {product.meta.TEASER || ''}
                </LongDescription>
                <MetaWrapper>
                  <Overline>{t('learning-path', { ns: 'productType' })}</Overline>
                  <MetaBlock meta={product.meta} />
                </MetaWrapper>
              </Composition>
              {hasVariants && !inventoryProduct && (
                <SectionWithTopBorder>
                  <Header4>Sessions</Header4>
                  <div className="level">
                    <div className="level-item" style={{ flexWrap: 'wrap', justifyContent: 'flex-start', gap: 10 }}>
                      {product.variants
                        .filter(({ enabled }: VariantType) => enabled)
                        .sort(sortVariants)
                        .map(({ id, ...variantRest }: VariantType) => (
                          <Variant
                            key={id}
                            selected={id === selectedVariantId || hasOrderedVariant(id)}
                            onClick={() => !hasOrderedVariant(id) && setSelectedVariantId(id)}
                            disabled={hasOrderedVariant(id)}
                            variant={{ ...variantRest, id }}
                          />
                        ))}
                    </div>
                  </div>
                </SectionWithTopBorder>
              )}
              <SectionWithTopBorder>
                <div className="level">
                  <div className="level-left">
                    {hasEnrolled && !isLearningPathCompleted && (
                      <div className="level-item">
                        <div>
                          <Icon icon="sync-alt" /> {t('Ongoing')}
                        </div>
                      </div>
                    )}
                    {isManualCompletedButtonVisible && isLearningPathCompleted && (
                      <div className="level-item">
                        <div>
                          <Icon icon="check" /> {t('completed')}
                        </div>
                      </div>
                    )}
                    <div className="level-item">
                      {hasEnrolled ? (
                        customLinkOrPluralsight && (
                          <LinkButton
                            $type={conditionalBtnLinkStyle}
                            onClick={() => trackClickedGoToLearning(conditionalBtnLinkText ?? '')}
                            href={customLinkOrPluralsight}
                            target={customLinkOrPluralsight.indexOf(window.location.host) === -1 ? '_blank' : undefined}
                          >
                            {conditionalBtnLinkText}
                          </LinkButton>
                        )
                      ) : (
                        <Enroll
                          flags={product.flags}
                          handleApplication={handleApplication}
                          handleProvider={requestCourseraLicense}
                          handleProviderLoading={courseraLicenseLoading}
                          hasAccessToCourse={!isPending && !inventoryProduct}
                          meta={product.meta}
                          providerStatus={providerStatus}
                          isDisabled={applyDisabled || courseraLicenseLoading || loading}
                          providerMeta={providerMeta}
                          isLoading={loadingPurchase}
                        />
                      )}
                    </div>
                    {isPending && (product.flags.approvalRequired || product.flags.requiresApplication) && (
                      <div className="level-item">
                        <Tag>
                          <Icon icon="hourglass" /> {t('pending approval')}
                        </Tag>
                      </div>
                    )}
                  </div>

                  <div className="level-right">
                    <div className="level-item">
                      <ButtonList align="center">
                        {isManualCompletedButtonVisible && !isLearningPathCompleted && (
                          <Button
                            $icon="circle-check"
                            $loading={learningPathCompletionLoading || learningPathCompletedLoading}
                            disabled={learningPathCompletionLoading || learningPathCompletedLoading}
                            onClick={handleMarkCompleted}
                          >
                            {t('mark-complete')}
                          </Button>
                        )}
                        {/* Disable favorites if hidden: Hidden collection items won't show up in My Favorites */}
                        {product.flags.available && <SaveToMyLearning item={{ id: product.id, entity: 'product' }} />}
                        {product.orderId && (isPending || hasEnrolled) && !isLearningPathCompleted && (
                          <CancelButton onClick={promptCancelOrder} disabled={loadingCancel}>
                            {t('Cancel enrollment')}
                          </CancelButton>
                        )}
                        {product.flags.canEdit && (
                          <RouterLinkButton $size="small" to={`${ADMIN_ROUTE_PATH.CATALOG}/course/${product.id}`} $icon="edit" />
                        )}
                      </ButtonList>
                    </div>
                  </div>
                </div>
              </SectionWithTopBorder>
            </Box>
          </div>
        </div>

        <div className="container" style={{ width: '100%' }}>
          <div styleName="course-bottom-row">
            <div className="tabs is-section-bottom is-boxed" styleName="course-tabs">
              <ul>
                {hasEnrolled && learningPathLink && !customLinkOrPluralsight && (
                  <Tab to={`learning-path`}>{t('Learning path')}</Tab>
                )}

                {canViewCourseTabs && product.flags.socialFeaturesEnabled && (
                  <Tab onClick={() => trackLearningItemTabClicked('discussions')} to={`discussions`} isExact={false}>
                    {t('Discussions')}
                  </Tab>
                )}

                {canViewCourseTabs && product.flags.reflectionsEnabled && (
                  <Tab onClick={() => trackLearningItemTabClicked('reflections')} to={`reflections`}>
                    {t('Reflections')}
                  </Tab>
                )}

                {canViewCourseTabs && product.flags.socialFeaturesEnabled && (
                  <Tab onClick={() => trackLearningItemTabClicked('participants')} to={`participants`}>
                    {t('Participants')}
                  </Tab>
                )}
                <Tab onClick={() => trackLearningItemTabClicked('description')} to={`description`}>
                  {t('Description')}
                </Tab>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <section
        className={clsx(
          'section',
          'is-padded',
          isLearnifierNewUiEnabled && isLearningPathIframeVisible ? 'has-background-white' : 'has-background-white-ter'
        )}
      >
        <div className="container">
          <LearningPathFrame
            isHidden={!isLearningPathIframeVisible}
            learningPathLink={learningPathLink}
            trackClickedGoToLearning={trackClickedGoToLearning}
            enrolledWithNoCustomLinkOrPluralsight={hasEnrolled && !customLinkOrPluralsight}
            linkInsteadOfIframe={linkInsteadOfIframe}
          />
          <Routes>
            <Route path={`reflection/:postId/*`} element={<PostPage />} />
            <Route path={`post/:postId/*`} element={<PostPage />} />
            <Route path={`learning-path`} element={<React.Fragment />} />
            <Route
              path={`discussions/*`}
              element={
                <>
                  {hasEnrolled && // We should hide discussions content from not enrolled learners
                    canViewCourseTabs &&
                    product.flags.socialFeaturesEnabled &&
                    product.meta.DISCUSSION_SECTION && (
                      <CoursePageDiscussion
                        spaceId={product.space.id}
                        path={product.meta.DISCUSSION_SECTION.split('/').slice(1)}
                        teamNo={teamNo}
                        teams={getTeams(product.meta.TEAMS)}
                      />
                    )}
                </>
              }
            />
            <Route
              path={`reflections`}
              element={
                <>
                  {canViewCourseTabs && product.flags.reflectionsEnabled && product.meta.REFLECTION_SECTION && (
                    <ReflectionsTab spaceId={product.space.id} path={product.meta.REFLECTION_SECTION} />
                  )}
                </>
              }
            />
            <Route
              path={`participants`}
              element={
                <>
                  {canViewCourseTabs && service && product.flags.socialFeaturesEnabled && (
                    <CoursePageParticipants productId={product.id} />
                  )}
                </>
              }
            />
            <Route
              path={`description`}
              element={
                <div className="grid">
                  <div className="gc gc-8-d gc-8-t gc-12-l gc-12-p">
                    <div
                      dangerouslySetInnerHTML={{ __html: description || t('There is no description') }}
                      style={{ marginBottom: 30 }}
                      className="content is-paddingless is-article"
                    />
                  </div>
                  <div className="gc gc-4-d gc-4-t gc-12-l gc-12-p">
                    {nextItem && (
                      <UpNextLearning
                        learningCardProps={{
                          objectId: nextItem.contentId,
                          title: nextItem.content?.title,
                          imageUrl: nextItem.content?.imageURL,
                          type: nextItem.content?.metadata?.type,
                          level: nextItem.content?.metadata?.level,
                          totalEffort: nextItem.content?.plannedDuration,
                        }}
                        isActionActive={isLearningPathCompleted}
                        actionHref={`${nextItem.content?.curations[0]?.launchURLPath}}`}
                        parentJourneyId={journeyId}
                      />
                    )}
                  </div>
                </div>
              }
            />
          </Routes>
        </div>
      </section>
    </CoursePageContext.Provider>
  );
};
