import { useEffect, useState } from 'react';
import isNil from 'lodash.isnil';

import { Survey, SurveyVariant } from '../survey/types';
import { PageId } from '@/administration/pages/Course/store/types';
import { createResultPage, RESULTS_PAGE_ID, updatePage } from '@/features/course/admin/utils/pageTransform';
import { BlockType, QuestionBlock } from '@/administration/pages/Course/store/block/types';
import { useSurvey } from '../survey/useSurvey';
import { Page, PageVariantDeprecated as PageVariant } from '@/features/course/admin/types/page';
import { useXAPISession } from '@/store/xapi/useXAPISession';
import { useXAPIQuestionAnswers } from '@/store/xapi/useXAPIQuestionAnswers';
import { ActivityDefinitionCollegialType } from '@/store/xapi/model';
import { Progress } from '@/administration/pages/Course/store/progress/types';
import { transformProgressWithRegistration } from '@/administration/pages/Course/store/progress/transform';
import { useXAPIClient } from '@/store/xapi/useXAPIClient';
import { useModularLearningViewer } from '@/pages/ModularLearningDeprecated/useModularLearningViewer';

const AUTO_NAVIGATION_TIMEOUT_DELAY = 1000;

// TODO (once API is updated). Remove it and use template id.
// Until that time, it's THE ONLY way we could distinguish survey templates :(
const getSurveyVariant = (text: string) =>
  String(text).includes('check-out') ? SurveyVariant.CHECK_OUT : SurveyVariant.CHECK_IN;

export type UseSurveyViewerHook = (id: number) => {
  survey?: Survey;
  pages: Page[];
  progress?: Progress;
  loadingLearning: boolean;
  onPageChange: (page: Page) => void;

  onSetPageActive: (pageId: PageId) => void;
  onSetPrevPageActive: () => void;
  onSetNextPageActive: () => void;
  onSetPageActiveByIndex: (index: number) => void;

  prevPageActiveCount: number;
  pageActiveCount: number;
  pageTotalCount: number;

  startSurvey: () => void;
  loadingStartCourse: boolean;
  loadingProgressInitialized: boolean;

  isDrawerOpen: boolean;
  setDrawerOpen: (p: boolean) => void;
};

const isSurveyCompleted = (pages: Page[]): boolean => {
  if (pages.length === 0) return false;

  return pages.every((p) => {
    if (p.type === PageVariant.QUESTION && p.block.type === BlockType.QUESTION && p.block.variant === 'RADIO') {
      return !isNil(p.block.answerValue);
    }

    return true;
  });
};

export const useSurveyViewer: UseSurveyViewerHook = (id: number) => {
  const { data, loading: loadingLearning } = useSurvey(id, { includeProgress: true, includeLocations: true });

  const { session, loadingSession, register, refetchSession } = useXAPISession(Number(data?.survey?.id));
  const [surveyCompletedThisSession, setSurveyCompletedThisSession] = useState(false);
  const xapiClient = useXAPIClient(session);

  const {
    pages,
    isDrawerOpen,
    pageTotalCount,
    pageActiveCount,
    prevPageActiveCount,
    setPages,
    setDrawerOpen,
    onSetPageActive,
    onSetPrevPageActive,
    onSetNextPageActive,
    onSetPageActiveByIndex,
  } = useModularLearningViewer({
    onPageCompleted: (p) => Boolean(p.completed),
    onLearningCompleted: () => {
      if (data?.survey?.id) {
        xapiClient.setLearningCompleted(Number(data.survey.id), data?.module?.name || '');
      }
    },
  });

  const surveyVariant = getSurveyVariant(data?.survey?.title || '');

  const xapiQuestionAnswer = useXAPIQuestionAnswers(
    { id: Number(data?.survey?.id), name: String(data?.survey?.title) },
    data?.pages?.filter((page) => page.type === PageVariant.QUESTION).map((page) => page.remoteId || 0) || [],
    session
  );
  const [loadingStartCourse, setLoadingStartCourse] = useState(false);
  const [loadingProgressInitialized, setLoadingProgressInitialized] = useState(false);

  const isCompleted = isSurveyCompleted(pages || []);

  useEffect(() => {
    if (pages.length === 0 || !isCompleted) return;

    const curResultPage = pages.find((page) => page.id === RESULTS_PAGE_ID);
    if (curResultPage) return;

    const resultPage = createResultPage({ variant: surveyVariant });

    setPages(pages.concat(resultPage));

    if (resultPage && !resultPage.active && surveyCompletedThisSession) {
      setTimeout(() => onSetPageActive({ pageId: resultPage.id }), AUTO_NAVIGATION_TIMEOUT_DELAY);
    }
  }, [isCompleted, surveyCompletedThisSession]);

  useEffect(() => {
    if (!data?.pages || !xapiQuestionAnswer.isInitialized) return;

    const nextPages: Page[] = [
      ...(data?.pages.map((curPage) => {
        const answer = xapiQuestionAnswer.answers[curPage?.remoteId || 0];
        if (!answer) return curPage;

        return updatePage(curPage, { block: { answerValue: answer?.score?.raw }, completed: true } as Partial<Page>);
      }) || []),
    ];

    setPages(nextPages?.map((page, index) => (index === 0 ? { ...page, active: true } : { ...page, active: false })) || []);
  }, [loadingLearning, xapiQuestionAnswer.isInitialized]);

  useEffect(() => {
    if (loadingProgressInitialized || loadingLearning || loadingSession) return;

    setLoadingProgressInitialized(true);
  }, [loadingLearning, loadingSession, loadingProgressInitialized]);

  const onPageChange = (page: Page): void => {
    switch (page?.type) {
      case PageVariant.QUESTION:
        const block = page.block as QuestionBlock;

        const answer = xapiQuestionAnswer.answers[page?.remoteId || 0];

        if (!isNil(answer) || isNil(block.answerValue)) {
          return;
        }

        const min = Math.min(...block.answerOptions.map((option) => option.value));
        const max = Math.max(...block.answerOptions.map((option) => option.value));

        xapiQuestionAnswer.saveAnswer(
          {
            id: Number(page?.remoteId),
            name: String(page?.title),
            type: ActivityDefinitionCollegialType.QUESTION_RELEVANCE,
          },
          {
            score: {
              min,
              max,
              scaled: 1,
              raw: block.answerValue,
            },
          }
        );

        xapiClient.setLearningPageCompleted(Number(page?.remoteId), data?.module?.name || '');

        setPages((pages) =>
          pages.map((curPage) =>
            curPage.id !== page.id
              ? curPage
              : updatePage(curPage, { block: { answerValue: block.answerValue }, completed: true } as Partial<Page>)
          )
        );
        setSurveyCompletedThisSession(true);
        setTimeout(() => onSetNextPageActive(), AUTO_NAVIGATION_TIMEOUT_DELAY);
        break;
      default:
        return;
    }
  };

  const handleStartSurvey = async () => {
    try {
      if (data?.progress?.isCompleted || session) return;

      setLoadingStartCourse(true);

      await refetchSession();

      if (session) return;

      await register();

      await refetchSession();
    } catch (e) {
    } finally {
      setLoadingStartCourse(false);
    }
  };

  return {
    survey: data?.survey,
    pages,
    progress: transformProgressWithRegistration(data?.progress, session?.registration),

    startSurvey: handleStartSurvey,
    onSetPageActive: (pageId: PageId) => onSetPageActive({ pageId }),
    onSetPrevPageActive,
    onSetNextPageActive,
    onSetPageActiveByIndex,
    pageActiveCount,
    prevPageActiveCount,
    pageTotalCount,
    loadingLearning: loadingLearning,
    loadingProgressInitialized: loadingProgressInitialized,
    loadingStartCourse,
    isDrawerOpen,
    setDrawerOpen,
    onPageChange,
  };
};
