import { useMediaState } from '@udecode/plate-media/react';

import { FloatingToolbarButtons } from './FloatingToolbarButtons';
import { useSelectedElementFloatingToolbar } from '@/component/customEditorV2/hooks/elementFloatingToolbar/useSelectedElementFloatingToolbar';
import { FileCard } from '../FileCard';
import { DownloadableResource } from '@/administration/pages/Course/practicalAssessments/blocks/document/components/DownloadableResource';

export const Resource = ({
  onUpload,
  onRemove,
  fileExtension,
  url,
  name,
  shouldShowToolbar,
}: {
  onUpload: (file: File) => void;
  onRemove: () => void;
  url: string;
  name: string;
  fileExtension: string;
  shouldShowToolbar: boolean;
}): JSX.Element => {
  const { setFloating, setReference, style } = useSelectedElementFloatingToolbar({ selected: shouldShowToolbar });
  const { readOnly } = useMediaState();

  const renderPreview = () => {
    if (readOnly) {
      return <DownloadableResource downloadUrl={url} fileExtension={fileExtension} fileName={name} bordered />;
    } else {
      return <FileCard downloadUrl={url} fileExtension={fileExtension} fileTitle={name} />;
    }
  };

  return (
    <>
      <div ref={setReference} style={{ width: '100%' }}>
        {renderPreview()}
      </div>
      <div
        ref={setFloating}
        style={{
          ...style,
        }}
      >
        <FloatingToolbarButtons onRemoveClick={onRemove} onReplaceFile={onUpload} />
      </div>
    </>
  );
};
