import React from 'react';
import clsx from 'clsx';

import { AutoComplete } from '../../../components/Form/types';

interface TextProps {
  label?: string;
  placeholder?: string;
  required?: boolean;
  rows?: number;
  value?: string;
  showCounter?: boolean;
  maxLength?: number;
  help?: string;
  // The 'string'-part is for using keys like 'section-event' or 'section-work' to increase usability
  autoComplete?: AutoComplete | AutoComplete[] | (AutoComplete[] & string[]);
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

export const TextArea = ({
  onChange,
  required,
  label,
  placeholder,
  value,
  help,
  showCounter = false,
  maxLength,
  rows,
  autoComplete,
}: TextProps): JSX.Element => {
  const parsedAutoComplete = Array.isArray(autoComplete) ? autoComplete.join(' ') : autoComplete;

  return (
    <div className="field">
      <label className={clsx('label', required && 'required')}>
        {label}{' '}
        {value && showCounter && (
          <small className="has-text-weight-normal">
            ({value.length}
            {maxLength ? `/${maxLength}` : ''} characters)
          </small>
        )}{' '}
      </label>
      {Boolean(help) && <p className="help">{help}</p>}
      <div className="control">
        <textarea
          autoComplete={parsedAutoComplete}
          className="textarea has-fixed-size"
          rows={rows || 3}
          onChange={onChange}
          placeholder={placeholder}
          value={value || ''}
          maxLength={maxLength}
          aria-label={label}
          aria-required={required ? 'true' : 'false'}
          required={required}
        />
      </div>
    </div>
  );
};
