import { useNavigate, useParams } from 'react-router-dom';

import { Activity, ActivityType, TreeActivity } from '@/features/course/admin/types/activity';
import { useNavigateBack } from '@/hooks/useNavigateBack';
import { useParentJourney, useParentJourneyIdFromSearchParams } from '@/store/v2/journeys/useParentJourneyId';
import { convertTitle } from '@/utils/misc';
import { ViewLayoutV2 } from '@/administration/pages/Course/MainLayoutView/ViewLayoutV2';
import { ViewActivityContentV2 } from '@/administration/pages/Course/MainLayoutView/ViewActivityContentV2';
import { ActivityId } from '@/administration/pages/Course/store/types';
import { XAPISession } from '@/store/xapi/useXAPISession';

type ModularLearningDetailsV2Props = {
  title?: string;
  spaceId?: number;
  loading?: boolean;
  learningId?: number;
  activities: Activity[];
  xapiSession?: XAPISession;
  isDrawerOpen: boolean;
  activitiesTotalCount: number;
  activityActiveCount: number;
  previousActiveActivityIndex: number;
  isSavedToMyLearning?: boolean;
  onNewActivityClick: (activityNumber: number) => void;
  onUpNavigationClick: () => void;
  onDownNavigationClick: () => void;
  onSaveClick?: () => void;
  onSetDrawerOpen: (value: boolean) => void;
  onSetActivityActive: (activityId: ActivityId) => void;
  onActivityChange: (id: ActivityId, activity: Partial<Activity>) => void;
  treeActivities: TreeActivity[];
};

export const ModularLearningDetailsV2 = ({
  title,
  spaceId,
  loading,
  learningId,
  activities,
  isDrawerOpen,
  activitiesTotalCount,
  activityActiveCount,
  previousActiveActivityIndex,
  isSavedToMyLearning,
  onNewActivityClick,
  onUpNavigationClick,
  onDownNavigationClick,
  onSaveClick,
  onSetDrawerOpen,
  onSetActivityActive,
  onActivityChange,
  treeActivities,
  xapiSession,
}: ModularLearningDetailsV2Props): JSX.Element => {
  const navigate = useNavigate();
  const { navigateBack } = useNavigateBack();
  const { id } = useParams<{ id: string }>();
  const parsedCourseId = id ? parseInt(id) : NaN;

  const parentJourneyIdFromSearchParams = useParentJourneyIdFromSearchParams();
  const parentJourney = useParentJourney({
    id: parsedCourseId,
  });

  const handleGoBack = () => {
    if (
      // We SPECIFICALLY check that the search params has parent journey id.
      // We ignore all other cases (when course has parent but was open not from journey).
      parentJourneyIdFromSearchParams &&
      parentJourney?.journeyId &&
      parentJourney.journeyTitle
    ) {
      // If the modular learning has parent journey AND was open via journey page,
      // in this case we make close button behave as a link to that journey.
      navigate(`/journey/${parentJourney.journeyId}/${convertTitle(parentJourney.journeyTitle)}`);
    } else {
      navigateBack();
    }
  };

  return (
    <>
      {!!loading || !spaceId || !learningId ? (
        <ViewLayoutV2.LoadingState />
      ) : (
        <ViewLayoutV2
          title={title || ''}
          content={activities.map((activity) => (
            <ViewActivityContentV2
              key={`activity-${activity.id}`}
              activity={activity}
              onActivityChange={onActivityChange}
              spaceId={spaceId}
              learningId={learningId}
              title={title}
              xapiSession={xapiSession}
            />
          ))}
          drawerItems={treeActivities.map((activity: TreeActivity) => ({
            ...activity,
            id: activity?.id as unknown as ActivityId,
            type: activity?.type as unknown as ActivityType,
            onSelect: (activityId: ActivityId) => onSetActivityActive(activityId),
          }))}
          onShowHideDrawerClick={() => onSetDrawerOpen(!isDrawerOpen)}
          onAddFavoritesClick={onSaveClick}
          onCloseClick={handleGoBack}
          onNewActivityClick={onNewActivityClick}
          onUpNavigationClick={onUpNavigationClick}
          onDownNavigationClick={onDownNavigationClick}
          isDrawerOpen={isDrawerOpen}
          progress={{ value: activityActiveCount, maxValue: activitiesTotalCount - 1, prevValue: previousActiveActivityIndex }}
          isSavedToMyLearning={isSavedToMyLearning}
          activityActiveCount={activityActiveCount}
          activitiesTotalCount={activitiesTotalCount}
        />
      )}
    </>
  );
};
