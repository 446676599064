import React, { useEffect, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components';

import { formatSeconds } from '@/utils/time';
import { Icon } from '@/ui/Icon';
import { LinearProgressBar } from '@/ui/ProgressBar';
import { useSelectedElementFloatingToolbar } from '@/component/customEditorV2/hooks/elementFloatingToolbar/useSelectedElementFloatingToolbar';
import { FloatingToolbarButtons } from '@/component/customEditorV2/ui/elements/AudioElement/FloatingToolbarButtons';

const ContainerStyled = styled.div`
  display: flex;
  padding: 16px;
  align-items: center;
  width: 100%;
  height: 88px;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.outline1};
`;

const PlayIconStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.neutral.container};
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin: 0 16px;
`;

const CurrentTimeStyled = styled.div`
  ${({ theme }) => theme.typography.body3};
  color: ${({ theme }) => theme.colors.neutral.onBackground};
  margin: 0 8px 0 16px;
  min-width: 40px;
`;

const LinearProgressBarStyled = styled(LinearProgressBar)`
  width: calc(100% - 210px);
`;

const DurationStyled = styled.div`
  ${({ theme }) => theme.typography.body3};
  color: ${({ theme }) => theme.colors.neutral.onBackground};
  margin-left: 8px;
`;

const REWIND_TIME_IN_SECONDS = 15;
const LOADING_PLAYER_STATE_INDEX = 3;

type AudioResourceProps = {
  url?: string;
  shouldShowToolbar: boolean;
  onRemove: () => void;
  onUpload: (file: File) => void;
};

export const AudioResource = ({ url, shouldShowToolbar, onRemove, onUpload }: AudioResourceProps): JSX.Element => {
  const theme = useTheme();
  const { setFloating, setReference, style } = useSelectedElementFloatingToolbar({ selected: shouldShowToolbar });
  const { current: player } = useRef(new Audio(url));
  const [canPlay, setCanPlay] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [currentTime, setCurrentTime] = useState('00:00');

  const updateProgress = () => {
    setCurrentTime(formatSeconds(player.currentTime || 0));
    setProgress(player.currentTime ? (player.currentTime / player.duration) * 100 : 0);
  };

  const toggleCanPlay = () => {
    setCanPlay(true);
  };

  const togglePlaying = () => {
    setPlaying(true);
  };

  const togglePause = () => {
    setPlaying(false);
  };

  const handleForwardClick = () => {
    player.currentTime += REWIND_TIME_IN_SECONDS;
  };

  const handleBackwardClick = () => {
    player.currentTime -= REWIND_TIME_IN_SECONDS;
  };

  const handlePlayClick = () => {
    if (!canPlay) {
      return null;
    } else {
      if (playing) {
        player.pause();
      } else {
        player.play();
      }
    }
  };

  useEffect(() => {
    player.addEventListener('canplaythrough', toggleCanPlay);
    player.addEventListener('timeupdate', updateProgress);
    player.addEventListener('play', togglePlaying);
    player.addEventListener('pause', togglePause);

    if (player.readyState > LOADING_PLAYER_STATE_INDEX) toggleCanPlay();

    return () => {
      player.pause();
      player.removeEventListener('canplaythrough', toggleCanPlay);
      player.removeEventListener('timeupdate', updateProgress);
      player.removeEventListener('play', togglePlaying);
      player.removeEventListener('pause', togglePause);
    };
  }, []);

  return (
    <>
      <ContainerStyled ref={setReference}>
        <Icon icon={['far', 'fast-backward']} color={theme.colors.neutral.onContainer} onClick={handleBackwardClick} />
        <PlayIconStyled onClick={handlePlayClick}>
          <Icon icon={playing ? 'pause' : 'play'} color={theme.colors.neutral.onContainer} />
        </PlayIconStyled>
        <Icon icon={['far', 'fast-forward']} color={theme.colors.neutral.onContainer} onClick={handleForwardClick} />
        <CurrentTimeStyled>{currentTime}</CurrentTimeStyled>
        <LinearProgressBarStyled value={progress} />
        <DurationStyled>{formatSeconds(player?.duration || 0)}</DurationStyled>
      </ContainerStyled>
      <div
        ref={setFloating}
        style={{
          ...style,
        }}
      >
        <FloatingToolbarButtons onRemoveClick={onRemove} onReplaceFile={onUpload} />
      </div>
    </>
  );
};
