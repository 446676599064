import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';

import { QuestionAnswerItem } from '@/component/customEditorV2/ui/elements/QuizQuestionElement/Edit/QuestionAnswerItem';
import { AddAnswerButton } from '@/component/customEditorV2/ui/elements/QuizQuestionElement/Edit/AddAnswerButton';
import { SelectChipOption } from '@/ui/Chip/variants/SelectChip';
import { QUESTION_TYPES } from '@/component/customEditorV2/ui/elements/QuizQuestionElement/Edit/QuestionTypeChip';

const AnswersContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

type QuestionAnswersProps = {
  answers?: QuestionAnswer[];
  onAnswersChange: (answers: QuestionAnswer[]) => void;
  questionType: SelectChipOption;
  isQuestionFocused?: boolean;
};

export type QuestionAnswer = {
  label?: string;
  isCorrect: boolean;
  id?: string;
};

export function QuestionAnswers({
  answers,
  onAnswersChange,
  questionType,
  isQuestionFocused,
}: QuestionAnswersProps): JSX.Element | null {
  if (!answers) return null;

  const handleLabelChange = (newLabel: string, index: number) => {
    const changedAnswer = { ...answers[index] };
    changedAnswer.label = newLabel;

    const newAnswers = [...answers];
    newAnswers[index] = changedAnswer;

    onAnswersChange(newAnswers);
  };

  const handleCorrectnessChange = (newCorrectness: SelectChipOption, index: number) => {
    const changedAnswer = { ...answers[index] };
    changedAnswer.isCorrect = newCorrectness?.value === 'correct';

    let newAnswers = [...answers];
    newAnswers[index] = changedAnswer;

    // For single select we should avoid having more than 1 correct answer
    if (questionType?.value === QUESTION_TYPES.SINGLE_SELECT && newCorrectness?.value === 'correct') {
      newAnswers = newAnswers?.map((newAnswerItem, itemIndex) => {
        if (itemIndex === index) return newAnswerItem;

        return {
          ...newAnswerItem,
          isCorrect: false,
        };
      });
    }

    onAnswersChange(newAnswers);
  };

  const handleRemoveClick = (indexToRemove: number) => {
    const newAnswers = [...answers];

    newAnswers.splice(indexToRemove, 1);

    onAnswersChange(newAnswers);
  };

  const handleAddAnswerClick = () => {
    onAnswersChange([...answers, { label: '', isCorrect: false, id: uuid() }]);
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return; // Dropped outside the list

    const reorderedAnswers = [...answers];
    const [removed] = reorderedAnswers.splice(result.source.index, 1);
    reorderedAnswers.splice(result.destination.index, 0, removed);

    onAnswersChange(reorderedAnswers);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="answers">
        {(provided) => (
          <AnswersContainerStyled ref={provided.innerRef} {...provided.droppableProps}>
            {answers.map((answer, index) => (
              <Draggable key={answer.id || index} draggableId={answer.id || index.toString()} index={index}>
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                    <QuestionAnswerItem
                      label={answer.label}
                      isCorrect={!!answer.isCorrect}
                      isQuestionFocused={isQuestionFocused}
                      index={index}
                      questionType={questionType}
                      onRemoveClick={handleRemoveClick}
                      onLabelChange={handleLabelChange}
                      onCorrectnessChange={handleCorrectnessChange}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </AnswersContainerStyled>
        )}
      </Droppable>
      <AddAnswerButton onClick={handleAddAnswerClick} />
    </DragDropContext>
  );
}
