export enum TextFieldName {
  Space = 'spaceId',
  Title = 'title',
  Teaser = 'teaser',
  Image = 'image',
  Category = 'category',
  EffortHours = 'effortHours',
  EffortMinutes = 'effortMinutes',
  Level = 'level',
  Partner = 'partner',
  Visiblity = 'visibility',
  Language = 'language',
  Subject = 'subjectId',
  ContentOwner = 'contentOwnerId',
  Attributes = 'attributes',
  UserRoleConnections = 'userRoleConnections',
}

export const LEVELS = ['Basic', 'Intermediate', 'Advanced'];
