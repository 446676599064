import styled from 'styled-components';
import { useSelected } from 'slate-react';
import { useState } from 'react';

import { Icon } from '@/ui/Icon';
import { SelectChip, SelectChipOption } from '@/ui/Chip/variants/SelectChip';
import { IconButton } from '@/ui/Button';
import { QUESTION_TYPES } from '@/component/customEditorV2/ui/elements/QuizQuestionElement/Edit/QuestionTypeChip';

const AnswerContainerStyled = styled.div<{ isBackgroundFilled?: boolean }>`
  display: flex;
  align-items: center;
  padding: 24px 16px;
  border-radius: 8px;
  min-height: 80px;
  margin-bottom: 8px;
  border: 1px solid ${(p) => p.theme.colors.neutral.outline2};
  ${({ isBackgroundFilled, theme }) => isBackgroundFilled && `background-color: ${theme.colors.neutral.background};`}
`;

const LabelInput = styled.input`
  ${({ theme }) => theme.typography.title4};
  height: 36px;
  background: transparent;
  width: 100%;
  border-radius: 4px;
  outline: none;
  border: none;
  margin-left: 16px;

  &:focus {
    outline: 2px solid ${({ theme }) => theme.colors.primary.outline};
  }

  ::placeholder {
    color: ${({ theme }) => theme.colors.neutral.disabled};
    opacity: 1; /* Firefox */
  }
`;

const SelectChipContainer = styled.div`
  min-width: 137px;
  margin: 0 16px;
`;

const IconButtonStyled = styled(IconButton)`
  height: 24px;
  width: 24px;
  padding: 8px;
`;

type QuestionAnswerItemProps = {
  label?: string;
  isCorrect: boolean;
  onLabelChange: (newLabel: string, index: number) => void;
  onCorrectnessChange: (newCorrectness: SelectChipOption, index: number) => void;
  index: number;
  onRemoveClick: (index: number) => void;
  questionType: SelectChipOption;
  isQuestionFocused?: boolean;
};

const TRUTHFULNESS_OPTIONS: SelectChipOption[] = [
  {
    label: 'Correct',
    value: 'correct',
    icon: 'circle-check',
  },
  {
    label: 'Incorrect',
    value: 'incorrect',
    icon: 'circle-xmark',
  },
];

export function QuestionAnswerItem({
  onLabelChange,
  onCorrectnessChange,
  label,
  isCorrect,
  index,
  onRemoveClick,
  questionType,
  isQuestionFocused,
}: QuestionAnswerItemProps): JSX.Element {
  const selected = useSelected();

  /**
   * Triggering onTitle change directly will cause global re-rendering of the node,
   * leading to input focus loosing it's actual position.
   *
   * The workaround is to keep the "inner state" for the input, and trigger onTitleChange only onBlur
   */
  const [inputValue, setInputValue] = useState(label);

  return (
    <AnswerContainerStyled isBackgroundFilled={isQuestionFocused}>
      <Icon icon={questionType?.value === QUESTION_TYPES.SINGLE_SELECT ? ['far', 'circle'] : ['far', 'square']} />
      <LabelInput
        value={inputValue}
        onChange={(event) => setInputValue(event?.target?.value)}
        onBlur={(event) => {
          onLabelChange(event?.target?.value, index);
        }}
        placeholder={selected ? 'Option' : ''}
      />
      <SelectChipContainer>
        <SelectChip
          mode={isCorrect ? 'success' : 'error-outlined'}
          leadingIcon={isCorrect ? 'circle-check' : 'circle-xmark'}
          options={TRUTHFULNESS_OPTIONS}
          trailingIcon="caret-down"
          onMenuItemClick={(newCorrectness: SelectChipOption) => onCorrectnessChange(newCorrectness, index)}
        >
          {isCorrect ? 'Correct' : 'Incorrect'}
        </SelectChip>
      </SelectChipContainer>
      <IconButtonStyled icon={['far', 'trash']} buttonVariant="buddySmall" onClick={() => onRemoveClick(index)} />
    </AnswerContainerStyled>
  );
}
