import { gql, useMutation, useLazyQuery, QueryLazyOptions, FetchResult } from '@apollo/client';

import { CreateUser, User } from '../types/group';
import { CURRENT_USER, useCurrentUser } from './currentUser';
import { RUpdateUsersMutation, RUserChangeInput, useUpdateUsersMutationRemote } from '@/store/v2';

const getUsersByEmailQuery = gql`
  query getUsersByEmail($emails: [String]) {
    getUsersByEmail(emails: $emails) {
      id
      username
      email
      realm
      profileImage
      status
    }
  }
`;

const CREATE_USERS = gql`
  mutation createUsers($payload: CreateUserInput) {
    createUsers(payload: $payload) {
      userId
      realm
    }
  }
`;

export const useLazyUsersByEmail = (): {
  getUsersByEmail: (options?: QueryLazyOptions<{ emails?: string[] }>) => void;
  users: User[];
  loading: boolean;
} => {
  const [getUsersByEmail, { data, loading }] = useLazyQuery<{ getUsersByEmail: User[] }>(getUsersByEmailQuery, {
    fetchPolicy: 'no-cache',
  });
  const users = data?.getUsersByEmail || [];

  return {
    getUsersByEmail,
    users,
    loading,
  };
};

export const useUpdateCurrentUser = (): {
  updateUser: (input: Omit<RUserChangeInput, 'id'>) => Promise<FetchResult<RUpdateUsersMutation>>;
  loading: boolean;
  error?: Error;
} => {
  const { user } = useCurrentUser();
  const { updateUser, loading, error } = useUpdateUser({ refetchQueries: [{ query: CURRENT_USER }] });

  return {
    updateUser: (input: Omit<RUserChangeInput, 'id'>) => updateUser({ ...input, id: user?.id || 0 }),
    loading,
    error,
  };
};

type UseUpdateUserOpts = {
  refetchQueries?: any;
};

export const useUpdateUser = (
  opts?: UseUpdateUserOpts
): {
  updateUser: (input: RUserChangeInput) => Promise<FetchResult<RUpdateUsersMutation>>;
  loading: boolean;
  error?: Error;
} => {
  const [mutate, { loading }] = useUpdateUsersMutationRemote();

  return {
    updateUser: (input: RUserChangeInput) =>
      mutate({
        variables: { input },
        refetchQueries: opts?.refetchQueries,
      }),
    loading,
  };
};

export const useCreateUsers = (): {
  createUsers: (users: CreateUser[], sendInvites?: boolean) => Promise<FetchResult<{ users: User[]; loading: boolean }>>;
  loading: boolean;
} => {
  const [createUsers, { loading }] = useMutation(CREATE_USERS);

  return {
    createUsers: (users: CreateUser[], sendInvites = true) =>
      createUsers({
        variables: { payload: { users, sendInvites } },
      }),
    loading,
  };
};
