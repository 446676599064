import { useMemo } from 'react';
import { ApolloQueryResult, gql, NetworkStatus, useQuery } from '@apollo/client';

import { useLanguages } from '@/store/languages';
import { Card, LearningCard, ProductCard } from '@/types/learning/card';
import { CatalogItem } from '@/types/learning/learning-catalog';
import { COLLECTION_ITEM_FRAGMENT } from './learning-collection';
import { courseCardBase, learningCardExtras, productCardExtras } from './fragments';
import { HookResult } from './apolloClient';
import { CatalogSortingEnum } from '@/types/CatalogSortingEnum';
import { E_LEARNING_OBJECT_ID_PREFIX } from '@/utils/analyticObjects';
import { FEATURE, useFeatureEnabled } from '@/feature-toggles';

export interface Catalog {
  cards: Card[];
}

export const catalogItemsQuery = gql`
  query catalog {
    catalog(showHidden: true) {
      cards {
        id
        spaceId
        entity
        entityId
        title
        type
      }
    }
  }
`;

/**
 * Temporary workaround to avoid serializing 5MB large GQL response
 */
export const catalogJSONItemsQuery = gql`
  query catalog {
    catalog: catalogJSON(showHidden: true)
  } 
`;

export const getCatalogItemsQuery = (json?: boolean): gql => (!!json ? catalogJSONItemsQuery : catalogItemsQuery);

export const catalogQuery = gql`
  query catalog($showHidden: Boolean, $showArchived: Boolean, $items: [CatalogItems]) {
    catalog(showHidden: $showHidden, showArchived: $showArchived, items: $items) {
      cards {
        ${courseCardBase}
        ${learningCardExtras}
        ${productCardExtras}
        ... on CollectionCard {
          items {
            ...CollectionItemFragment
          }
        }
      }
    }
  }
  ${COLLECTION_ITEM_FRAGMENT}
`;

/**
 * Temporary workaround to avoid serializing 5MB large GQL response
 */
export const catalogJSONQuery = gql`
  query catalog($showHidden: Boolean, $showArchived: Boolean, $items: [CatalogItems]) {
    catalog: catalogJSON(showHidden: $showHidden, showArchived: $showArchived, items: $items)
  }
`;

export const getCatalogQuery = (json?: boolean): gql => (!!json ? catalogJSONQuery : catalogQuery);

export interface CatalogVariables {
  showHidden?: boolean;
  showArchived?: boolean;
  items?: CatalogItem[];
}

interface CatalogOptions extends CatalogVariables {
  noCache?: boolean;
  onlyEvents?: boolean;
  skip?: boolean;
}

export interface CatalogResponse extends HookResult {
  catalog: Catalog;
  getById: (cardId: string) => Card | undefined;
  getLearningCard: (learningId: number) => Card | undefined;
  getProductCard: (productId: number) => Card | undefined;
  getCollectionCard: (collectionId: number) => Card | undefined;
  getJourneyCard: (journeyId: number) => Card | undefined;
  refetch: (variables?: CatalogVariables) => Promise<ApolloQueryResult<{ catalog: Readonly<Catalog> }>>;
  getCardByObjectId: (objectId: string) => Card[] | undefined;
}

export const PROGRESS_CATALOG_OPTIONS = { showHidden: true };
export const ADMIN_CATALOG_OPTIONS = { showHidden: true, showArchived: true };

export const REFETCH_COURSE_CATALOG = (json?: boolean): { query: gql } => ({ query: getCatalogQuery(json) });
export const REFETCH_PROGRESS_CATALOG = (json?: boolean): { query: gql; variables: Record<string, unknown> } => ({
  query: getCatalogQuery(json),
  variables: PROGRESS_CATALOG_OPTIONS,
});
export const REFETCH_ADMIN_CATALOG = (json?: boolean): { query: gql; variables: Record<string, unknown> } => ({
  query: getCatalogQuery(json),
  variables: ADMIN_CATALOG_OPTIONS,
});

export const useCardCatalog = ({ noCache, onlyEvents, ...options }: CatalogOptions = {}): CatalogResponse => {
  const { languages } = useLanguages();
  const isCatalogJSONResponseEnabled = useFeatureEnabled(FEATURE.SYSTEM_CATALOG_GQL_JSON_RESPONSE_ENABLED);
  const { data, error, refetch, networkStatus, loading } = useQuery<{ catalog: Readonly<Catalog> }>(
    getCatalogQuery(isCatalogJSONResponseEnabled),
    {
      variables: options,
      skip: options.items?.length === 0 || options.skip,
      fetchPolicy: noCache ? 'network-only' : undefined,
    }
  );

  const catalog: Catalog = {
    // TODO: Do we want a model for this kind of logic?
    cards:
      data?.catalog?.cards?.map((c) => {
        // Make sure that language exists as it may be empty or hard coded
        const language = c.languageId ? languages.find(({ id }) => id === c.languageId)?.name || c.language : c.language;
        const objectId = c.entity === 'learning' ? `${E_LEARNING_OBJECT_ID_PREFIX}${c.entityId}` : undefined;

        return objectId
          ? {
              objectId,
              ...c,
              language,
            }
          : { ...c, language };
      }) || [],
  };

  if (onlyEvents) catalog.cards = catalog.cards.filter((c) => ['webinar', 'seminar'].includes(c.type));

  // memo saves some but catalog query is called with different variables
  const catalogById = useMemo(() => {
    return catalog.cards.reduce((map, card) => {
      map.set(card.id, card);
      return map;
    }, new Map<string, Card>());
  }, [catalog]);

  const getProductObjectId = (card: Card): string | undefined =>
    card.entity === 'product' ? (card as ProductCard).objectId : undefined;
  const getLearningObjectId = (card: Card): string | undefined =>
    card.entity === 'learning' ? (card as LearningCard).objectId : undefined;

  const catalogByObjectId = useMemo(() => {
    return catalog.cards.reduce((map, card) => {
      const c = getProductObjectId(card) || getLearningObjectId(card);
      if (!c) {
        return map;
      }

      const previousCards = map.get(c) || [];
      map.set(c, [...previousCards, card]);

      return map;
    }, new Map<string, Card[]>());
  }, [catalog]);

  const getById = (id: string): Card | undefined => catalogById.get(id);
  const getLearningCard = (learningId: number): Card | undefined => catalogById.get(`learning-${learningId}`);
  const getProductCard = (productId: number): Card | undefined => catalogById.get(`product-${productId}`);
  const getCollectionCard = (collectionId: number): Card | undefined => catalogById.get(`collection-${collectionId}`);
  const getJourneyCard = (journeyId: number): Card | undefined => catalogById.get(`journey-${journeyId}`);

  const getCardByObjectId = (objectId: string): Card[] | undefined => catalogByObjectId.get(objectId);

  return {
    catalog,
    getById,
    getLearningCard,
    getProductCard,
    getJourneyCard,
    getCollectionCard,
    error,
    refetch,
    networkStatus,
    loading: loading && networkStatus === NetworkStatus.loading,
    getCardByObjectId,
  };
};

export const CARD_SORTING_METHODS = {
  [CatalogSortingEnum.ALPHA_ASC]: (a: Card, b: Card): number =>
    a.title.trim().localeCompare(b.title, undefined, { numeric: true }),
  [CatalogSortingEnum.ALPHA_DESC]: (a: Card, b: Card): number =>
    b.title.trim().localeCompare(a.title, undefined, { numeric: true }),
  [CatalogSortingEnum.FEATURED]: (a: Card, b: Card): number =>
    +b.featured - +a.featured || CARD_SORTING_METHODS[CatalogSortingEnum.ALPHA_ASC](a, b),
  [CatalogSortingEnum.NONE]: (): number => 0, // in case we want to keep the current order (used in SearchPage.tsx)
};
