import { gql, PureQueryOptions, useMutation, useQuery, ApolloQueryResult, FetchResult } from '@apollo/client';

import { HookResult } from './apolloClient';
import { BabelonNotification, BabelonNotificationSingle, isAggregate } from '../pages/user/Notifications/types';

const NOTIFICATION_FRAGMENT = gql`
  fragment NotificationFragment on BabelonNotification {
    id
    notificationType
    respondentName
    replyId
    postId
    topLevelPostId
    topLevelPostTitle
    sectionTitle
    sectionPath
    productId
    productTitle
  }
`;
const GET_NOTIFICATIONS = gql`
  query getNotifications {
    notifications {
      ...NotificationFragment
    }
  }
  ${NOTIFICATION_FRAGMENT}
`;

export const MARK_ALL_AS_SEEN = gql`
  mutation markAllNotificationsAsSeen {
    markAllNotificationsAsSeen {
      ...NotificationFragment
    }
  }
  ${NOTIFICATION_FRAGMENT}
`;

export const MARK_NOTIFICATION_AS_SEEN = gql`
  mutation markNotificationAsSeen($postId: Int!) {
    markNotificationAsSeen(postId: $postId) {
      ...NotificationFragment
    }
  }
  ${NOTIFICATION_FRAGMENT}
`;

function createOrUpdateAggregateNotifications(
  base: BabelonNotification,
  additional: BabelonNotificationSingle
): BabelonNotification {
  const { topLevelPostId, topLevelPostTitle, sectionPath, sectionTitle, productId, productTitle } = base;

  if (isAggregate(base)) {
    return {
      topLevelPostId,
      topLevelPostTitle,
      sectionPath,
      sectionTitle,
      productId,
      productTitle,
      notificationTypes: [...base.notificationTypes, additional.notificationType],
      ids: [...base.ids, additional.id],
      postIds: [...base.postIds, additional.postId],
      replyIds: [...base.replyIds, additional.replyId].filter((value) => !!value) as number[],
      respondentNames: [...base.respondentNames, additional.respondentName],
    };
  } else {
    return {
      topLevelPostId,
      topLevelPostTitle,
      sectionPath,
      sectionTitle,
      productId,
      productTitle,
      notificationTypes: [base.notificationType, additional.notificationType],
      ids: [base.id, additional.id],
      postIds: [base.postId, additional.postId],
      replyIds: [base.replyId, additional.replyId].filter((value) => !!value) as number[],
      respondentNames: [...new Set([base.respondentName, additional.respondentName])],
    };
  }
}

export function useAggregatedNotifications(allNotifications?: BabelonNotificationSingle[]): BabelonNotification[] {
  if (!allNotifications) return [];

  return allNotifications.reduce<BabelonNotification[]>(
    (result: BabelonNotification[], notification: BabelonNotificationSingle) => {
      const index = result.findIndex((bn: BabelonNotification) => bn.topLevelPostId === notification.topLevelPostId);
      if (index > -1) {
        result[index] = createOrUpdateAggregateNotifications(result[index], notification);
        return result;
      }
      return result.concat(notification);
    },
    []
  );
}

interface NotificationsResponse extends HookResult {
  notifications: BabelonNotification[];
  refetch: () => Promise<ApolloQueryResult<{ notifications: BabelonNotificationSingle[] }>>;
}

/**
 * @param {number?} interval The polling interval in seconds
 */
export const useNotifications = (interval?: number): NotificationsResponse => {
  const pollInterval = interval ? interval * 1000 : 0;
  const { data, loading, refetch, networkStatus, error } = useQuery<{ notifications: BabelonNotificationSingle[] }>(
    GET_NOTIFICATIONS,
    {
      pollInterval,
    }
  );

  const notifications = useAggregatedNotifications(data?.notifications);

  return {
    notifications,
    refetch,
    loading,
    networkStatus,
    error,
  };
};

export const useMarkAllNotificationsAsSeen = (): {
  markAll: () => Promise<FetchResult<void, Record<string, unknown>, Record<string, unknown>>>;
  loading: boolean;
} => {
  const refetchQueries: PureQueryOptions[] = [{ query: GET_NOTIFICATIONS }];

  const [markAll, { loading }] = useMutation(MARK_ALL_AS_SEEN, {
    refetchQueries,
  });
  return { markAll, loading };
};

export const useMarkNotificationAsSeen = (): {
  markAsSeen: (options: {
    variables: { postId: number };
  }) => Promise<FetchResult<{ markNotificationAsSeen: { postId: number } }, Record<string, unknown>, Record<string, unknown>>>;
  loading: boolean;
} => {
  const refetchQueries: PureQueryOptions[] = [{ query: GET_NOTIFICATIONS }];

  const [markAsSeen, { loading }] = useMutation(MARK_NOTIFICATION_AS_SEEN, {
    refetchQueries,
  });
  return { markAsSeen, loading };
};
