import { v4 as uuid } from 'uuid';

import { CoursePageContentText } from '@/features/course/admin/atoms/CoursePageContentText';
import { Page, PageVariantDeprecated as PageVariant } from '@/features/course/admin/types/page';
import { CoursePageContentQuiz } from '@/features/course/admin/atoms/CoursePageContentQuiz';
import { CoursePageContentPracticalAssessmentText } from '@/features/course/admin/atoms/CoursePageContentPracticalAssessmentText';
import { CoursePageContentPracticalAssessmentDocument } from '@/features/course/admin/atoms/CoursePageContentPracticalAssessmentDocument';
import { CoursePageContentReflection } from '@/features/course/admin/atoms/CoursePageContentReflection';
import {
  TextBlock,
  QuizBlock,
  PracticalAssessmentTextBlock,
  PracticalAssessmentDocumentBlock,
  ReflectionBlock,
} from '@/features/course/admin/types/block';

type CoursePageContentProps = {
  spaceId: number;
  page: Page;
  onUpdatePage: (updateQuery: { pageId: string; pageChanges: Partial<Page> }) => void;
};

export const CoursePageContent = ({ spaceId, page, onUpdatePage }: CoursePageContentProps): JSX.Element => {
  const handleUpdatePage = (pageChanges: Partial<Page>) => {
    onUpdatePage({ pageId: page.id, pageChanges });
  };

  if (page.type === PageVariant.PRACTICAL_ASSESSMENT_TEXT) {
    const practicalAssessmentTextBlock = page.block as PracticalAssessmentTextBlock;

    return (
      <CoursePageContentPracticalAssessmentText
        id={page.id}
        spaceId={spaceId}
        description={practicalAssessmentTextBlock.description}
        title={page.title}
        peerReviewEnabled={practicalAssessmentTextBlock.peerReviewEnabled}
        onTitleChange={(title) => {
          handleUpdatePage({ title });
        }}
        onDescriptionChange={(description) => {
          handleUpdatePage({ block: { description } } as Partial<Page>);
        }}
        onPeerReviewEnabledChange={(peerReviewEnabled) => {
          handleUpdatePage({ block: { peerReviewEnabled } } as Partial<Page>);
        }}
      />
    );
  } else if (page.type === PageVariant.PRACTICAL_ASSESSMENT_DOCUMENT) {
    const practicalAssessmentDocumentBlock = page.block as PracticalAssessmentDocumentBlock;

    return (
      <CoursePageContentPracticalAssessmentDocument
        id={page.id}
        spaceId={spaceId}
        description={practicalAssessmentDocumentBlock.description}
        title={page.title}
        peerReviewEnabled={practicalAssessmentDocumentBlock.peerReviewEnabled}
        onTitleChange={(title) => {
          handleUpdatePage({ title });
        }}
        onDescriptionChange={(description) => {
          handleUpdatePage({ block: { description } } as Partial<Page>);
        }}
        onPeerReviewEnabledChange={(peerReviewEnabled) => {
          handleUpdatePage({ block: { peerReviewEnabled } } as Partial<Page>);
        }}
      />
    );
  } else if (page.type === PageVariant.REFLECTION) {
    const block = page.block as ReflectionBlock;

    return (
      <CoursePageContentReflection
        id={page.id}
        spaceId={spaceId}
        description={block.description}
        onDescriptionChange={(description) => handleUpdatePage({ block: { description } } as Partial<Page>)}
      />
    );
  } else if (page.type === PageVariant.QUIZ) {
    const block = page.block as QuizBlock;

    return (
      <CoursePageContentQuiz
        id={page.id}
        spaceId={spaceId}
        text={block.text}
        onTextChange={(text, questions) => {
          handleUpdatePage({ block: { text, questions, version: uuid() } } as unknown as Partial<Page>);
        }}
      />
    );
  } else {
    const textBlock = page.block as TextBlock;
    return (
      <CoursePageContentText
        id={page.id}
        spaceId={spaceId}
        text={textBlock.text}
        onTextChange={(text) => {
          handleUpdatePage({ block: { text } } as Partial<Page>);
        }}
      />
    );
  }
};
