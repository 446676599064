import { RLearningActivityType } from '@/store/v2';
import { Activity } from '@/features/course/admin/types/activity';

export const getActiveDefaultActivityIndex = (activities: Activity[]): number => {
  let activityToActivateId: number | undefined;

  // trying first activity on root level that is not a module in the ordered list
  activityToActivateId = activities
    .filter((activity) => !activity.parentActivityId && activity.type !== RLearningActivityType.Module)
    .sort((a, b) => a.order - b.order)?.[0]?.remoteId;

  // if the only root activities are modules, than searching for any not module activity outside of root level in the ordered list
  if (!activityToActivateId) {
    activityToActivateId = activities
      .filter((activity) => activity.type !== RLearningActivityType.Module)
      .sort((a, b) => a.order - b.order)?.[0]?.remoteId;
  }

  // trying to identify the index
  const activityToActivateIndex = activities.findIndex((activity) => activity.remoteId === activityToActivateId);

  return activityToActivateIndex;
};
