import styled from 'styled-components';
import { useState } from "react";

import { Skeleton } from '@/ui/Skeleton';
import { makeNamespacedComponent } from '@/ui/utils';
import { Icon } from '@/ui/Icon';
import { formatToHoursAndMinutesFromSeconds } from '@/utils/time';
import { LearningJourney } from '@/store/v2/journeys/journey';
import { RLearningJourneyCompletionStatus } from '@/store/v2';
import { Button } from '@/ui/Button';

type CompletionCriteriaProps = {
  journey: LearningJourney;
  learningItemsTotal: number;
  hasJoined: boolean;
  onJoin: () => void;
  isInPreviewModal?: boolean;
};

const RootStyled = styled.div`
  padding: 32px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.outline1};
`;

export const Title = styled.h3`
  ${(p) => p.theme.typography.title3}
  color: ${(p) => p.theme.colors.neutral.onBackground};
`;

export const Subtitle = styled.div`
  ${(p) => p.theme.typography.subheadline}
  color: ${(p) => p.theme.colors.neutral.onBackground};
  margin: 24px 0 8px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const Body = styled.div`
  ${(p) => p.theme.typography.body3}
  color: ${(p) => p.theme.colors.neutral.outline2};
`;

export const ButtonWrapper = styled.div`
  margin: 32px 0 0 0;
`;

export const ErrorMessageStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${(p) => p.theme.typography.caption}
  color: ${(p) => p.theme.colors.error.main};
  width: 100%;
  gap: 8px;
  margin-bottom: 8px;
`;

export const ButtonStyled = styled(Button)`
  width: 100%;
  gap: 10px;
`;

const CompletionCriteria = ({ journey, hasJoined, onJoin, learningItemsTotal, isInPreviewModal }: CompletionCriteriaProps): JSX.Element => {
  const [errorMessage, setErrorMessage] = useState(false);
  
  const displayErrorMessage = () => {
    setErrorMessage(true);
  }

  return (
    <RootStyled>
      <Title>Completion criteria</Title>
      {journey.completionCriteria === RLearningJourneyCompletionStatus.CompleteAllItems && (
        <>
          <Subtitle>
            <Icon icon={['far', 'layer-group']} size="sm" />
            {learningItemsTotal} learning items
          </Subtitle>
          <Body>Complete all learning items to finish the journey</Body>
        </>
      )}
      {journey.completionCriteria === RLearningJourneyCompletionStatus.InvestTime && (
        <>
          <Subtitle>
            <Icon icon={['far', 'clock']} size="sm" />
            {formatToHoursAndMinutesFromSeconds(journey.completionInvestTimeSec || 0)} total
          </Subtitle>
          <Body>Invest this amount of time to finish the journey</Body>
        </>
      )}
      <ButtonWrapper>
        {isInPreviewModal && errorMessage && (
          <ErrorMessageStyled>
            <Icon icon={['fas', 'circle-exclamation']} size="1x" />
            Property turned off in preview mode
          </ErrorMessageStyled>)}
        {!hasJoined && (
          <ButtonStyled variant="primary" onClick={isInPreviewModal ? displayErrorMessage : onJoin }>
            JOIN
          </ButtonStyled>
        )}
      </ButtonWrapper>
    </RootStyled>
  );
};

const LoadingState = () => <Skeleton height={166} />;

const _CompletionCriteria = makeNamespacedComponent(CompletionCriteria, {
  LoadingState,
});

export { _CompletionCriteria as CompletionCriteria };
