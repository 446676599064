import React from 'react';
import { useMediaState } from '@udecode/plate-media/react';

import { getFileExtensionAndName } from '@/component/FileUpload/helpers';
import { Placeholder } from './Placeholder';
import { Resource } from './Resource';
import { MediaElementUploadProgress } from '@/component/customEditorV2/ui/elements/MediaElementUploadProgress';

export const BaseEmbeddedDocument = ({
  onUpload,
  onRemoveElement,
  uploading,
  uploadingProgress,
  url,
  documentName,
  selected,
}: {
  onUpload: (file: File) => void;
  onRemoveElement: () => void;
  uploading: boolean;
  uploadingProgress?: number;
  url?: string;
  documentName?: string;
  selected: boolean;
}): JSX.Element | null => {
  const { readOnly } = useMediaState();

  if (readOnly && (uploading || !url)) return null;
  if (uploading) return <MediaElementUploadProgress currentProgress={uploadingProgress || 0} />;
  if (!url) return <Placeholder onUpload={onUpload} shouldShowToolbar={selected} onRemove={onRemoveElement} />;

  const { name, extension } = getFileExtensionAndName({ documentUrl: url || '', fileName: documentName || '' });

  return (
    <Resource
      url={url}
      fileExtension={extension}
      name={name}
      onRemove={onRemoveElement}
      onUpload={onUpload}
      shouldShowToolbar={selected && !readOnly}
    />
  );
};
