import styled, { css } from 'styled-components';

import {
  body1,
  body2,
  body3,
  body4,
  caption,
  headline,
  overline,
  subHeadline,
  title,
} from '@/components/Typography/v2/font-styles';
import { color as colorMixin, Color } from '@/components/Typography/v2/font-color';

export type baseElementProps = {
  color?: Color;
  gridArea?: string;
};

export const baseElement = css<baseElementProps>`
  ${({ gridArea }) => gridArea && `grid-area: ${gridArea};`}

  ${({ color }) => colorMixin(color)}
`;

export const Title1 = styled.h1<baseElementProps>`
  ${baseElement}

  ${title('title-1')}
`;

export const Title2 = styled.h2<baseElementProps>`
  ${baseElement}

  ${title('title-2')}
`;
export const Title3 = styled.h3<baseElementProps>`
  ${baseElement}

  ${title('title-3')}
`;

export const Title4 = styled.h4<baseElementProps>`
  ${baseElement}

  ${title('title-4')}
`;

export const Headline = styled.span<baseElementProps>`
  ${baseElement}

  ${headline}
`;

export const SubHeadline = styled.span<baseElementProps>`
  ${baseElement}

  ${subHeadline}
`;

export const Body1 = styled.p<baseElementProps>`
  ${baseElement}

  ${body1}
`;

export const Body2 = styled.p<baseElementProps>`
  ${baseElement}

  ${body2}
`;

export const Body3 = styled.p<baseElementProps>`
  ${baseElement}

  ${body3}
`;

export const Body4 = styled.p<baseElementProps>`
  ${baseElement}

  ${body4}
`;

export const Overline = styled.p<baseElementProps>`
  ${baseElement}

  ${overline}
`;

export const Caption = styled.p<baseElementProps>`
  ${baseElement}

  ${caption}
`;
