import styled, { useTheme } from 'styled-components';

import { DocumentVideoUploadButton } from '@/component/FileUpload/DocumentVideoUploadButton';
import { Icon } from '@/ui/Icon';
import { FloatingToolbarButtons } from './FloatingToolbarButtons';
import { useSelectedElementFloatingToolbar } from '@/component/customEditorV2/hooks/elementFloatingToolbar/useSelectedElementFloatingToolbar';

const RootStyled = styled.div`
  height: 440px;
  width: 100%;
  background: ${({ theme }) => theme.colors.neutral.container};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  border-radius: 12px;
`;

const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
`;

const TitleStyled = styled.h3`
  ${({ theme }) => theme.typography.title3};
  color: ${({ theme }) => theme.colors.neutral.onBackground};
`;

const SubtitleStyled = styled.div`
  ${({ theme }) => theme.typography.body3};
  color: ${({ theme }) => theme.colors.neutral.onBackground};
`;

const UploadButton = styled(DocumentVideoUploadButton)`
  & {
    background: transparent;
  }
`;

export const Placeholder = ({
  shouldShowToolbar,
  onUpload,
  onRemove,
}: {
  shouldShowToolbar: boolean;
  onUpload: (file: File) => void;
  onRemove: () => void;
}): JSX.Element => {
  const theme = useTheme();
  const { setFloating, setReference, style } = useSelectedElementFloatingToolbar({ selected: shouldShowToolbar });

  return (
    <>
      <RootStyled ref={setReference}>
        <Icon size="3x" icon={['far', 'window-maximize']} color={theme.colors.neutral.onBackground} />
        <TextBlock>
          <TitleStyled>Embed file</TitleStyled>
          <SubtitleStyled>Only .pdf and .ppt</SubtitleStyled>
        </TextBlock>
        <UploadButton
          icon={['far', 'file-upload']}
          variant="secondary"
          size="large"
          disabled={false}
          uploadFile={onUpload}
          fileType={'document'}
          accept=".pdf, .ppt, .pptx"
        >
          Upload
        </UploadButton>
      </RootStyled>
      <div
        ref={setFloating}
        style={{
          ...style,
        }}
      >
        <FloatingToolbarButtons onRemoveClick={onRemove} />
      </div>
    </>
  );
};
