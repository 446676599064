import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Composition } from 'atomic-layout';

import { supportUrl } from '@/config';
import { useProduct, usePurchaseProduct } from '@/store/learning-catalog';
import { ProviderAccessStatus, ProviderList } from '@/store/providers';
import { useProviderAccess } from '@/hooks/useProviderAccess';
import { ButtonList } from '@/components/Button/ButtonList';
import { Button } from '@/components/Button/Button';
import { LinkButton } from '@/components/Button/LinkButton';
import { BodyLg, Header1 } from '@/components/Typography/Typography';
import { ProviderLogo } from '@/components/ProviderLogo/ProviderLogo';
import { providerFormAreas, providerFormAreasLg } from './GeneralProvider';
import { PageLoader } from '@/components/Pageloader/Pageloader';
import { isRealm } from '@/utils/realms';

interface Props {
  accessProductId?: number;
}

export const Imparta = ({ accessProductId }: Props): JSX.Element => {
  const provider = ProviderList.IMPARTA;
  const providerStatus = useProviderAccess(provider);
  const { product: productImparta, loading: loadingImpartaProduct } = useProduct(accessProductId?.toString());
  const [purchaseImpartaProduct, { loading: loadingPurchaseImparta }] = usePurchaseProduct(accessProductId);
  const { t } = useTranslation('providers');

  if (loadingImpartaProduct) return <PageLoader />;

  const hasImpartaAccess = providerStatus === ProviderAccessStatus.HAS_ACCESS;
  const hasImpartaAccessPending = providerStatus === ProviderAccessStatus.PENDING_ACCESS;

  return (
    <Composition
      areas={providerFormAreas}
      areasLg={providerFormAreasLg}
      templateCols="minmax(0, 1fr)"
      templateColsLg="minmax(0, 9fr) minmax(0, 3fr)"
      gap={0.875}
      gapMd={1.75}
    >
      {({ First, Second }) => (
        <>
          <First>
            <Header1>{t('imparta-welcome')}</Header1>

            <BodyLg>{t('imparta-teaser')}</BodyLg>

            {isRealm('seb') && (
              <Trans t={t} i18nKey="imparta-seb-description">
                <BodyLg>
                  If you have questions, please contact HR Advice via
                  <a rel="noopener noreferrer" target="_blank" href={supportUrl.seb}>
                    HR Support
                  </a>
                </BodyLg>
              </Trans>
            )}

            {!isRealm('seb') && (
              <>
                <br />
                {!hasImpartaAccessPending && !hasImpartaAccess && <BodyLg>{t('imparta-not-registered')}</BodyLg>}
                {hasImpartaAccessPending && !hasImpartaAccess && <BodyLg>{t('imparta-pending')}</BodyLg>}
              </>
            )}
          </First>
          <Second>
            <ButtonList align="right" padded="bottom">
              <ProviderLogo provider={provider} />
            </ButtonList>
            <ButtonList align="right">
              {accessProductId !== undefined && !hasImpartaAccess && (
                <Button
                  $loading={loadingPurchaseImparta}
                  $icon="check"
                  disabled={hasImpartaAccessPending}
                  onClick={() => purchaseImpartaProduct({ variables: { products: [{ id: accessProductId }] } })}
                >
                  {hasImpartaAccessPending ? t('access-requested') : t('request-access')}
                </Button>
              )}
              {hasImpartaAccess && (
                <LinkButton
                  $type="primary"
                  href={productImparta ? productImparta.meta.CUSTOM_LINK || '' : '#'}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('imparta-go-to')}
                </LinkButton>
              )}
            </ButtonList>
          </Second>
        </>
      )}
    </Composition>
  );
};
