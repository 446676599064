import React from 'react';

import { ContentSection } from '@/ui/Layout/ContentSection/index';
import {
  Wrapper,
  Header,
  Breadcrumbs,
  HeadingWrapper,
  Heading,
  SubHeading,
  Tabs,
  Contents,
  NavigationWrapper,
} from '@/ui/Layout/PageWithHeader/atoms';
import { LoadingStateHeaderLayout } from '@/ui/Layout/PageWithHeader/LoadingStateHeaderLayout';

type PageLayoutProps = {
  breadcrumbs?: React.ReactNode;
  tabs?: React.ReactNode;
  contents: React.ReactNode;
  title?: string;
  subheading?: React.ReactNode;
  subtitle?: string | JSX.Element;
  loading?: boolean;
  hasHeader?: boolean;
  hasSubHeading?: boolean;
  hasSubtitle?: boolean;
  button?: React.ReactNode;
};

/**
 * Layout component for displaying page contents plus a hero-style header
 * with breadcrumbs, headings and/or tabs.
 */
export const PageWithHeader = ({
  breadcrumbs,
  tabs,
  contents,
  title,
  subtitle,
  subheading,
  loading,
  hasSubHeading,
  hasSubtitle,
  hasHeader,
  button,
}: PageLayoutProps): JSX.Element => {
  return (
    <Wrapper $loading={loading}>
      {!!hasHeader && (
        <Header noTabs={!tabs}>
          <ContentSection>
            <NavigationWrapper>
              {breadcrumbs && <Breadcrumbs>{breadcrumbs}</Breadcrumbs>}
              {button && button}
            </NavigationWrapper>

            {loading ? (
              <LoadingStateHeaderLayout hasSubHeading={hasSubHeading} hasSubtitle={hasSubtitle} />
            ) : (
              <HeadingWrapper>
                <Heading title={title} subtitle={subtitle} />
                {hasSubHeading && subheading && <SubHeading>{subheading}</SubHeading>}
              </HeadingWrapper>
            )}
            {tabs && <Tabs>{tabs}</Tabs>}
          </ContentSection>
        </Header>
      )}
      <ContentSection marginTop marginBottom>
        <Contents>{contents}</Contents>
      </ContentSection>
    </Wrapper>
  );
};
