import { focusEditor, useEditorRef } from '@udecode/plate-common/react';
import { toggleBlock, collapseSelection } from '@udecode/plate-common';
import { useListToolbarButtonState } from '@udecode/plate-list/react';

import { ELEMENT_TO_ICON } from '@/component/customEditorV2/ui/fixedToolbar/constants';
import { ElementType } from '@/component/customEditorV2/ui/fixedToolbar/types';
import { CommonToolbarButton } from '@/component/customEditorV2/ui/fixedToolbar/CommonToolbarButton';

export const ToggleToolbarButton = ({ type }: { type: ElementType }): JSX.Element => {
  const editor = useEditorRef();
  /**
   * TODO (for later PR's) find a better hook to extract pressed prop from
   * OR restrict it for list types only
   */
  const state = useListToolbarButtonState({ nodeType: type });

  const onClick = () => {
    toggleBlock(editor, { type });
    collapseSelection(editor);

    focusEditor(editor);
  };

  return (
    <CommonToolbarButton onClick={onClick} icon={ELEMENT_TO_ICON[type]} toggled={state.pressed} />
  )
};
