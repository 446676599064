/* eslint-disable import/no-unused-modules */
import React from 'react';

import { IconButton } from '@/ui/Button';
import { ButtonGroup } from '@/ui/ButtonGroup';

export const LearnerActions = ({
  row: {
    // optional, since when there is only 1 element in the table, removal action should be ignored (not rendered)
    onRemove,
    id,
    name,
  },
}: {
  row: {
    onRemove?: (ops: { id: number; name: string }) => void;
    id: number;
    name: string;
  };
}): JSX.Element => {
  // Not null, because cell renderer component expects JSX.Element
  if (!onRemove) return <></>;

  return (
    <ButtonGroup align="center">
      <IconButton
        icon={['far', 'trash']}
        onClick={() => {
          onRemove && onRemove({ id, name });
        }}
        buttonVariant="dark"
      />
    </ButtonGroup>
  );
};
