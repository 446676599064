import * as React from 'react';
import { Command as CommandPrimitive } from 'cmdk';
import styled from 'styled-components';

const CommandSeparatorStyled = styled(CommandPrimitive.Separator)`
  margin: 0 -4px;
  height: 1px;
  background: ${({ theme }) => theme.colors.neutral.outline1};
`;

export const CommandSeparator = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Separator>
>(({ className, ...props }, ref) => <CommandSeparatorStyled ref={ref} className={className} {...props} />);

CommandSeparator.displayName = CommandPrimitive.Separator.displayName;
