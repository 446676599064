import { useQuery } from '@apollo/client';

import { InventoryProduct } from '@/types/learning/learning-catalog';
import { Provider, ProviderAccessStatus, ProviderList, providers } from '@/store/providers';
import { useInventory } from '@/store/inventory';
import { LicenseStatus } from '@/administration/pages/Catalog/utils/types';
import { courseraPageQuery } from '@/pages/Provider/queries';

function validateProviderStatus(
  provider: Provider | null,
  inventory: readonly InventoryProduct[],
  status: LicenseStatus
): ProviderAccessStatus {
  if (!provider) return ProviderAccessStatus.NO_ACCESS;

  const meta = providers.find((p) => p.provider === provider);
  if (!meta?.requiresExplicitRegistration) return ProviderAccessStatus.HAS_ACCESS;

  if (provider === ProviderList.COURSERA) {
    if (status === LicenseStatus.PENDING_APPROVAL) {
      return ProviderAccessStatus.PENDING_ACCESS;
    } else if (status === LicenseStatus.NOT_APPROVED) {
      return ProviderAccessStatus.NOT_APPROVED;
    } else if (status === LicenseStatus.APPROVED) {
      return ProviderAccessStatus.HAS_ACCESS;
    }
  } else {
    if (inventory.some((product) => product.providerAccess === provider && 'approved' === product.state)) {
      return ProviderAccessStatus.HAS_ACCESS;
    } else if (
      inventory.some(
        (product) =>
          product.providerAccess === provider &&
          (ProviderAccessStatus.PENDING_ACCESS === product.state || 'approved' === product.state)
      )
    ) {
      return ProviderAccessStatus.PENDING_ACCESS;
    }
  }

  return ProviderAccessStatus.NO_ACCESS;
}

export function useProviderAccess(
  provider: Provider | null = null,
  setInitialProviderStatusIfReady?: (status: ProviderAccessStatus) => void
): ProviderAccessStatus {
  const {
    inventory: { allProgress: inventory },
  } = useInventory();

  const { data: licenseData } = useQuery(courseraPageQuery, {
    variables: { provider: ProviderList.COURSERA, currentRealm: window.realm },
    skip: provider !== ProviderList.COURSERA,
    onCompleted: (data) => {
      const status: LicenseStatus = data?.issuedLicense?.status;
      setInitialProviderStatusIfReady && setInitialProviderStatusIfReady(validateProviderStatus(provider, inventory, status));
    },
  });

  const status: LicenseStatus = licenseData?.issuedLicense?.status;

  return validateProviderStatus(provider, inventory, status);
}
