import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

export type ButtonVariant = 'default' | 'ghost';

const buttonTypeStyle: Record<ButtonVariant, FlattenSimpleInterpolation> = {
  default: css`
    //Selected
    :checked {
      :after {
        opacity: 1;
      }
      :before {
        border-color: #006c4d;
      }
    }
    :checked:focus {
      :before {
        outline: 1px solid #42dca8;
      }
    }
    :checked:disabled {
      :after {
        background: #bec8c8;
      }
      :before {
        border-color: #bec8c8;
      }
    }
    //Unselected
    :not(:checked) {
      :before {
        border-color: #6f797a;
      }
    }
    :not(:checked) :focus {
      :before {
        outline: 1px solid #42dca8;
      }
    }
    :not(:checked):disabled {
      :before {
        border-color: #bec8c8;
      }
    }
  `,
  ghost: css`
    //Selected
    :checked {
      :after {
        background: #ffffff;
        opacity: 1;
        filter: drop-shadow(0px 2.4px 7.4px rgba(0, 0, 0, 0.18)) drop-shadow(0px 12.8px 28.8px rgba(0, 0, 0, 0.22));
      }
      :before {
        outline: none;
        border-color: #ffffff;
      }
    }
    :checked:focus {
      :before {
        outline: 1px solid #42dca8;
      }
    }
    :checked:disabled {
      :after {
        background: #bec8c8;
      }
      :before {
        border-color: #bec8c8;
      }
    }
    //Unselected
    :not(:checked) {
      :before {
        border-color: #ffffff;
        filter: drop-shadow(0px 2.4px 7.4px rgba(0, 0, 0, 0.18)) drop-shadow(0px 12.8px 28.8px rgba(0, 0, 0, 0.22));
        outline: none;
      }
    }
    :not(:checked):focus {
      :before {
        outline: 1px solid #42dca8;
        filter: none;
      }
    }
    :not(:checked):disabled {
      :before {
        border-color: #bec8c8;
      }
    }
  `,
} as const;

export const RadioButton = styled.input.attrs({ type: 'radio' })<{ variant: ButtonVariant }>`
  appearance: none;
  width: 1rem;
  height: 1rem;
  position: relative;
  & {
    box-shadow: none;
    outline: none;
    border: none;
    outline-color: transparent;
  }

  // Border & background
  :before {
    position: absolute;
    display: block;
    content: '';
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background: transparent;
    border: 1px solid #006c4d;
  }

  // Inner dot
  :after {
    position: absolute;
    display: block;
    content: '';
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background: #006c4d;
    opacity: 0;
  }

  ${({ variant }) => buttonTypeStyle[variant || 'default']}
`;
