import { ViewTextPage } from '../../text';
import { Page, PageVariantDeprecated as PageVariant } from '@/features/course/admin/types/page';
import {
  TextBlock,
  ResultBlock,
  BlockType,
  ReactionBlock,
  ReflectionBlock,
  QuestionBlock,
  SurveyResultBlock,
  PracticalAssessmentTextBlock,
  PracticalAssessmentDocumentBlock,
} from '@/administration/pages/Course/store/block/types';
import { ViewResultPage } from '../../result/View';
import { ViewReactionPage } from '../../reactions/View';
import { ViewQuestionPage } from '../../question/View';
import { ViewReflectionPage } from '../../reflection/variants/View';
import { ViewPracticalAssessmentTextPage } from '../../assessments/practicalAssessmentText';
import { ViewPracticalAssessmentDocumentPage } from '../../assessments/practicalAssessmentDocument';

export const ViewPageContent = ({
  page,
  onPageChange,
  spaceId,
  learningId,
}: {
  page: Page;
  onPageChange: (page: Page) => void;
  spaceId: number;
  learningId: number;
}): JSX.Element => {
  if (page.type === PageVariant.RESULT) {
    const resourceBlock =
      page.block.type === BlockType.SURVEY_RESULT ? (page.block as SurveyResultBlock) : (page.block as ResultBlock);
    return <ViewResultPage id={page.id} block={resourceBlock} isActive={page.active || false} />;
  } else if (page.type === PageVariant.REACTION) {
    const reactionBlock = page.block as ReactionBlock;
    return (
      <ViewReactionPage
        id={page.id}
        block={reactionBlock}
        isActive={page.active || false}
        onBlockChange={(block) => onPageChange({ ...page, block })}
      />
    );
  } else if (page.type === PageVariant.QUESTION) {
    const questionBlock = page.block as QuestionBlock;
    return <ViewQuestionPage block={questionBlock} onBlockChange={(block) => onPageChange({ ...page, block })} />;
  } else if (page.type === PageVariant.REFLECTION) {
    const reflectionBlock = page.block as ReflectionBlock;
    return (
      <ViewReflectionPage
        spaceId={spaceId}
        pageId={page.remoteId || 0}
        title={page.title}
        description={reflectionBlock.description}
        postId={reflectionBlock.postId}
        sectionPath={reflectionBlock.sectionPath}
        onCompleted={(completed) => onPageChange({ ...page, completed })}
      />
    );
  } else if (page.type === PageVariant.PRACTICAL_ASSESSMENT_TEXT) {
    const practicalAssessmentTextBlock = page.block as PracticalAssessmentTextBlock;

    return (
      <ViewPracticalAssessmentTextPage
        remoteId={page.remoteId}
        learningId={learningId}
        id={page.id}
        spaceId={spaceId}
        title={page.title}
        peerReviewEnabled={practicalAssessmentTextBlock.peerReviewEnabled}
        description={practicalAssessmentTextBlock.description}
        onSubmitted={() => onPageChange({ ...page })}
      />
    );
  } else if (page.type === PageVariant.PRACTICAL_ASSESSMENT_DOCUMENT) {
    const practicalAssessmentDocumentBlock = page.block as PracticalAssessmentDocumentBlock;

    return (
      <ViewPracticalAssessmentDocumentPage
        id={page.id}
        title={page.title}
        spaceId={spaceId}
        remoteId={page.remoteId}
        learningId={learningId}
        description={practicalAssessmentDocumentBlock.description}
        peerReviewEnabled={practicalAssessmentDocumentBlock.peerReviewEnabled}
        onSubmitted={() => onPageChange({ ...page })}
      />
    );
  } else {
    const textBlock = page.block as TextBlock;
    return <ViewTextPage id={page.id} title={page.title} text={textBlock.text} spaceId={spaceId} />;
  }
};
