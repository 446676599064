import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { Card } from '@/types/learning/card';
import { LearningSubject } from '@/types/learning';
import { FilterType } from '@/components/CardGrid/CardGrid';
import { useUpdateFilterFunction } from '@/components/CardGrid/hooks/useUpdateFilterFunction';
import { useResetFiltersEffects } from '@/components/CardGrid/hooks/useResetFiltersEffects';
import { CatalogSortingEnum } from '@/types/CatalogSortingEnum';
import { filterProviderEnum, filterDurationEnum, filterTypeEnum } from './types';
import { CardFilterBody } from './components/CardFilterBody/CardFilterBody';
import { AvailableMeta } from '@/components/CardGrid/hooks/useAvailableMeta';
import { useCategoriesAndSubjects } from '@/components/CardGrid/hooks/useCategoriesAndSubjects';

export type FilterFn = (card: Card) => boolean;

export enum CARDFILTER_PAGETYPE {
  SEARCH = 'search',
  ALL = 'all',
  CATSUB = 'catsub',
  EVENTS = 'events',
}

const filterSchema = z.object({
  category: z.number().optional(),
  subject: z.number().optional(),
  type: z.string().optional(),
  duration: z.string().optional(),
  provider: z.string().optional(),
  title: z.string().optional(),
  sort: z.nativeEnum(CatalogSortingEnum).optional(),
  language: z.string().optional(),
  attributes: z.string().optional().array(),
});

export type FilterSchema = z.infer<typeof filterSchema>;

interface FilterProps {
  title?: string;
  filterTypes: [FilterType, ...FilterType[]];
  pageType: CARDFILTER_PAGETYPE;
  availableMeta: AvailableMeta;
  setFilterFn: Dispatch<SetStateAction<FilterFn>>;
  subjects?: Array<LearningSubject>;
  defaultValues?: FilterSchema;
}

const DEFAULT_VALUES = {
  provider: undefined,
  duration: undefined,
  type: undefined,
  subject: undefined,
  category: undefined,
  title: '',
  sort: CatalogSortingEnum.FEATURED,
  language: undefined,
  attributes: [],
};

export const CardFilter = ({
  title,
  filterTypes,
  subjects = [],
  setFilterFn,
  availableMeta,
  pageType,
  defaultValues = DEFAULT_VALUES,
}: FilterProps): JSX.Element => {
  const methods = useForm<FilterSchema>({
    mode: 'onChange',
    defaultValues,
    resolver: zodResolver(filterSchema),
  });
  const selectedCategory = methods.watch('category');
  const selectedSubject = methods.watch('subject');
  const filterTitle = methods.watch('title');
  const filterType = methods.watch('type');
  const filterProvider = methods.watch('provider');
  const filterDuration = methods.watch('duration');
  const filterLanguage = methods.watch('language');
  const filterAttributes = useWatch({
    control: methods.control,
    name: 'attributes',
    defaultValue: defaultValues?.attributes,
  });

  const categories: LearningSubject[] = useMemo(() => {
    return Object.values(
      subjects.reduce((acc: Record<number, LearningSubject>, subject) => {
        acc[subject.categoryId] = subject;
        return acc;
      }, {})
    );
  }, [subjects]);

  // Reset selections if selected value no longer available
  useResetFiltersEffects(
    methods,
    availableMeta,
    filterType as filterTypeEnum,
    filterDuration as filterDurationEnum,
    filterProvider as filterProviderEnum
  );

  const { availableCategories, availableSubjects } = useCategoriesAndSubjects({
    categories,
    subjects,
    selectedCategory,
    selectedSubject,
    setCategory: (categoryId) => methods.setValue('category', categoryId),
    setSubject: (subjectId) => methods.setValue('subject', subjectId),
  });

  useUpdateFilterFunction(
    {
      filterTitle,
      selectedCategory,
      selectedSubject,
      filterType,
      filterProvider,
      filterDuration,
      filterLanguage,
      filterAttributes,
    },
    setFilterFn
  );

  return (
    <FormProvider {...methods}>
      <CardFilterBody
        pageType={pageType}
        title={title}
        filterTypes={filterTypes}
        subjects={availableSubjects}
        categories={availableCategories}
        availableMeta={availableMeta}
      />
    </FormProvider>
  );
};
