import React, { FC, useCallback, useContext } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { UserSelfTrackedLearning } from '@/component/UserSelfTrackedLearning/types';
import { UserSelfTrackedLearningForm } from '@/component/UserSelfTrackedLearning/components/UserSelfTrackedLearningForm/UserSelfTrackedLearningForm';
import { SharedSnackbarContext, SnackbarType } from '@/component/SharedSnackbar/SharedSnackbar';
import { getInventoryQuery } from '@/store/inventory';

const getUserSelfTrackedLearningQuery = gql`
  query getUserSelfTrackedLearning($userSelfTrackedLearningId: Int!) {
    selfTrackedLearning(id: $userSelfTrackedLearningId) {
      id
      type
      title
      description
      effort
      link
    }
  }
`;

const updateUserSelfTrackedLearningMutation = gql`
  mutation updateUserSelfTrackedLearning($userSelfTrackedLearning: UpdateSelfTrackedLearningInput!) {
    updateSelfTrackedLearning(input: $userSelfTrackedLearning) {
      success
    }
  }
`;

const removeUserSelfTrackedLearningQuery = gql`
  mutation removeUserSelfTrackedLearning($userSelfTrackedLearningId: Int!) {
    removeSelfTrackedLearning(id: $userSelfTrackedLearningId) {
      success
    }
  }
`;

type EditUserSelfTrackedLearningProps = {
  userSelfTrackedLearningId?: number;
  isEditing?: boolean;
  onClose?: () => void;
};

export const UpdateUserSelfTrackedLearning: FC<EditUserSelfTrackedLearningProps> = ({
  userSelfTrackedLearningId,
  isEditing,
  onClose,
}) => {
  const { t } = useTranslation('userSelfTrackedLearning');
  const { openSnackbar } = useContext(SharedSnackbarContext);

  const [updateUserSelfTrackedLearning, { loading }] = useMutation<{
    updateSelfTrackedLearning: { success: boolean };
  }>(updateUserSelfTrackedLearningMutation, {
    refetchQueries: [{ query: getInventoryQuery }],
  });

  const [removeUserSelfTrackedLearning] = useMutation<{ removeSelfTrackedLearning: { success: boolean } }>(
    removeUserSelfTrackedLearningQuery,
    {
      refetchQueries: [{ query: getInventoryQuery }],
    }
  );

  const { data } = useQuery<{ selfTrackedLearning: UserSelfTrackedLearning }>(getUserSelfTrackedLearningQuery, {
    fetchPolicy: 'no-cache',
    variables: {
      userSelfTrackedLearningId,
    },
  });

  const learning: UserSelfTrackedLearning | undefined = data?.selfTrackedLearning;

  const handleUpdateSelfTrackedLearning = useCallback(async (userSelfTrackedLearning: UserSelfTrackedLearning) => {
    const { data } = await updateUserSelfTrackedLearning({ variables: { userSelfTrackedLearning } });

    if (data?.updateSelfTrackedLearning?.success) {
      openSnackbar({
        type: SnackbarType.SUCCESS,
        message: t('Learning has been updated'),
        isDismissive: true,
      });
    }

    if (onClose) {
      onClose();
    }
  }, []);

  const handleRemoveSelfTrackedLearning = useCallback(async (userSelfTrackedLearningId: number) => {
    const { data } = await removeUserSelfTrackedLearning({ variables: { userSelfTrackedLearningId } });

    if (data?.removeSelfTrackedLearning?.success) {
      openSnackbar({
        type: SnackbarType.SUCCESS,
        message: t('Learning has been removed'),
        isDismissive: true,
      });
    }

    if (onClose) {
      onClose();
    }
  }, []);

  if (!learning) {
    return null;
  }

  return (
    <UserSelfTrackedLearningForm
      save={handleUpdateSelfTrackedLearning}
      handleDelete={handleRemoveSelfTrackedLearning}
      userSelfTrackedLearning={learning}
      isEditing={isEditing}
      loading={loading}
    />
  );
};
