import { useState } from 'react';
import styled from 'styled-components';

import { ColumnStyled, StyledLabel } from '@/features/course/admin/molecules/CourseSettingsModalForm/CourseSettingsModalForm';
import { Command, CommandEmpty, CommandInput, CommandItem, CommandList } from '@/ui/Command';
import { Popover, PopoverContent, PopoverTrigger } from '@/ui/Popover/Popover';
import { ComboboxTrigger } from '@/ui/Combobox';
import { InputChip } from '@/ui/Chip';

const PopoverContentStyled = styled(PopoverContent)`
  width: 336px;
`;

const AttributeFieldRootStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
  align-self: stretch;
`;

const InputChipLabelStyled = styled.div`
  max-width: 240px;
`;

type CourseSettingModalFieldAttributesRowProps = {
  label: string;
  options: string[];
  value?: string;
  onAttributeSelect: (value?: string) => void;
};

export const CourseSettingModalFieldAttributesRow = ({
  label,
  options,
  value: selectedValue,
  onAttributeSelect,
}: CourseSettingModalFieldAttributesRowProps): JSX.Element => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  return (
    <AttributeFieldRootStyled>
      <ColumnStyled>
        <StyledLabel>{label}</StyledLabel>
      </ColumnStyled>
      <ColumnStyled>
        <Popover open={popoverOpen} onOpenChange={setPopoverOpen}>
          <PopoverTrigger asChild>
            <ComboboxTrigger
              comboboxOpen={popoverOpen}
              label={label}
              onClick={() => {
                setPopoverOpen(true);
              }}
            >
              {selectedValue && (
                <div>
                  <InputChip
                    label={<InputChipLabelStyled>{selectedValue}</InputChipLabelStyled>}
                    hasCloseIcon
                    onTrailingIconClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      onAttributeSelect(undefined);
                      setPopoverOpen(false);
                    }}
                  />
                </div>
              )}
            </ComboboxTrigger>
          </PopoverTrigger>
          <PopoverContentStyled align="start">
            <Command>
              <CommandInput placeholder="Search..." />
              <CommandList>
                <CommandEmpty>No results found.</CommandEmpty>
                {options?.map((value) => {
                  return (
                    <CommandItem
                      key={value}
                      value={value}
                      onSelect={(value) => {
                        setPopoverOpen(false);
                        onAttributeSelect(value);
                      }}
                      $selected={value === selectedValue}
                    >
                      {value}
                    </CommandItem>
                  );
                })}
              </CommandList>
            </Command>
          </PopoverContentStyled>
        </Popover>
      </ColumnStyled>
    </AttributeFieldRootStyled>
  );
};
