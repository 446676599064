/* eslint-disable @typescript-eslint/no-explicit-any */
import dayjs from 'dayjs';

import { CellFormatterComponent } from '../types';
import { distanceToNow } from '@/utils/time';

export const DateToNowCellFormatter: CellFormatterComponent = ({ data }) => {
  if (data && dayjs(data as any).isValid()) {
    const date = new Date(data as any);
    return <>{distanceToNow(date)}</>;
  }
  return null;
};
