import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from 'react-router-dom';

import { LoadingHero } from '@/component/LoadingHero';
import { useCardCatalog } from '@/store/catalog';
import { Hero, Section } from '@/components/Section/Section';
import { CardGrid } from '@/components/CardGrid/CardGrid';
import { useCategories } from '@/store/categories';
import { Header1 } from '@/components/Typography/Typography';
import { Breadcrumbs } from '@/components/Breadcrumbs/Breadcrumbs';
import { CARDFILTER_PAGETYPE } from '@/components/CardGrid/CardFilter';
import { useFilterPastEvents } from '@/hooks/useFilterPastEvents';
import { UpcomingEvents } from './components/UpcomingEvents';

type Params = 'category' | 'subject';
export const SubjectPage = (): JSX.Element | null => {
  const { t } = useTranslation('catalog');
  const { category: categoryIdParam, subject: subjectIdParam } = useParams<Params>();
  const { catalog, loading: loadingCatalog } = useCardCatalog();
  const { categoriesById, subjectsById, loading: loadingSubjects } = useCategories();
  const subjectId = Number(subjectIdParam);
  const categoryId = Number(categoryIdParam);

  const cards = useFilterPastEvents(catalog.cards);

  if (loadingSubjects) return <LoadingHero />;
  const subject = subjectsById[subjectId];
  const category = categoriesById[categoryId];
  if (!subject) return <Navigate to="/404" replace />;

  return (
    <>
      <Hero paddedBottom>
        <Breadcrumbs parents={[{ link: `/learning/category/${categoryId}`, title: t(category.name, { ns: 'categories' }) }]} />
        <Header1>{t(subject.name, { ns: 'subjects' })}</Header1>
      </Hero>
      <UpcomingEvents cards={cards} loading={loadingCatalog} />
      <Section>
        <CardGrid
          title={t('Learning items', { ns: 'assignmentGroup' })}
          filters={['title', 'meta', 'attributes']}
          pageType={CARDFILTER_PAGETYPE.CATSUB}
          cards={cards.filter((card) => card.subjects.some((sub) => sub.subjectId === subjectId))}
          loading={loadingCatalog}
          noProviderLogo
        />
      </Section>
    </>
  );
};
