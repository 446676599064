import styled from 'styled-components';

import { Button } from '@/ui/Button';

const RootStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const RetakeSection = ({ onRetakeClick }: { onRetakeClick: () => void }): JSX.Element => {
  return (
    <RootStyled>
      <Button icon={['far', 'arrows-repeat']} variant="secondary" onClick={onRetakeClick} size="large">
        Retake
      </Button>
    </RootStyled>
  );
};
