import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/sv';

import { Card, LearningCard } from '@/types/learning/card';
import { firstLocation } from '@/util';

dayjs.extend(utc);

export const isEvent = ({ type }: Card): boolean => ['webinar', 'seminar'].includes(type);

export const useFilterPastEvents = (cards: Card[]): Card[] => {
  const { i18n } = useTranslation();
  const today = dayjs(new Date());

  return cards.filter((card) => {
    if (isEvent(card)) {
      const location = firstLocation((card as LearningCard).locations);
      return (
        location &&
        dayjs(location.endDate || location.startDate)
          .locale(i18n.language)
          .isAfter(today)
      );
    }
    return true;
  });
};
