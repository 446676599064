import { NavigateFunction, useNavigate } from 'react-router-dom';

import { ADMIN_ROUTE_PATH } from '@/administration/constants/adminRoutePaths';
import { QueryParams, TabQueryParam } from '@/features/journey/admin/organisms/JourneyInsightsTabs/JourneyInsightsTabs';
import { convertTitle } from '@/utils/misc';

export const useJourneyHref = (): {
  adminListHref: string;
  adminEditorHref: (journeyId?: number) => string;
  adminInsightsHref: (journeyId?: number) => string;
  adminPublishHref: (journeyId?: number) => string;
  adminLearnersEditorHref: (journeyId?: number) => string;
  adminEditLearningsHref: (journeyId?: number) => string;
  adminEditLearningsLearningAttendanceHref: (journeyId?: number, learningId?: number) => string;
  adminCatalogHref: string;
  adminUsersHref: string;
  managerRootHref: string;
  managerListHref: string;
  managerInsightsHref: (journeyId?: number) => string;
  learnerViewHref: (ops: { journeyId: number; journeyTitle: string }) => string;
} => {
  return {
    adminListHref: `${ADMIN_ROUTE_PATH.JOURNEYS}/`,
    adminEditorHref: (journeyId?: number) => `${ADMIN_ROUTE_PATH.JOURNEYS}/${journeyId}/edit`,
    adminInsightsHref: (journeyId?: number) => `${ADMIN_ROUTE_PATH.JOURNEYS}/${journeyId}/insights`,
    adminPublishHref: (journeyId?: number) => `${ADMIN_ROUTE_PATH.JOURNEYS}/${journeyId}/publish`,
    adminLearnersEditorHref: (journeyId?: number) =>
      `${ADMIN_ROUTE_PATH.JOURNEYS}/${journeyId}/insights?${QueryParams.Tab}=${TabQueryParam.Participants}&${QueryParams.TabScroll}=true`,
    adminEditLearningsHref: (journeyId?: number) => `${ADMIN_ROUTE_PATH.JOURNEYS}/${journeyId}/edit-learnings`,
    adminEditLearningsLearningAttendanceHref: (journeyId?: number, learningId?: number) =>
      `${ADMIN_ROUTE_PATH.JOURNEYS}/${journeyId}/edit-learnings/learning/${learningId}/attendance`,
    adminCatalogHref: `${ADMIN_ROUTE_PATH.CATALOG}`,
    adminUsersHref: `${ADMIN_ROUTE_PATH.USERS}`,
    managerRootHref: `/management/analyze`,
    managerListHref: `/management/analyze/journeys`,
    managerInsightsHref: (journeyId?: number) => `/management/analyze/journeys/${journeyId}`,
    learnerViewHref: (ops: { journeyId: number; journeyTitle: string }) =>
      `/journey/${ops.journeyId}/${convertTitle(ops.journeyTitle)}`,
  };
};

export const useJourneyNavigate = (): {
  navigate: NavigateFunction;
  navigateToList: () => void;
  navigatoToEditor: (journeyId?: number) => void;
  navigatoToInsights: (journeyId?: number) => void;
  navigatoToPublish: (journeyId?: number) => void;
  navigatoToLearnersEditor: (journeyId?: number) => void;
  navigatoToEditLearnings: (journeyId?: number) => void;
  navigatoToEditLearningsLearningAttendance: (journeyId?: number, learningId?: number) => void;
  navigateToLearnerView: (ops: { journeyId: number; journeyTitle: string }) => void;
  navigate404: () => void;
  navigateToAdminCatalog: () => void;
  navigateToAdminUsers: () => void;
  navigateToManagerList: () => void;
  navigateToManagerInsights: (journeyId?: number) => void;
} => {
  const navigate = useNavigate();
  const href = useJourneyHref();
  return {
    navigate,
    navigate404: () => navigate('/404'),
    navigateToList: () => navigate(href.adminListHref),

    navigatoToEditor: (journeyId?: number) => (journeyId ? navigate(href.adminEditorHref(journeyId)) : () => {}),

    navigatoToPublish: (journeyId?: number) => (journeyId ? navigate(href.adminPublishHref(journeyId)) : () => {}),

    navigatoToLearnersEditor: (journeyId?: number) => (journeyId ? navigate(href.adminLearnersEditorHref(journeyId)) : () => {}),

    navigatoToEditLearnings: (journeyId?: number) => (journeyId ? navigate(href.adminEditLearningsHref(journeyId)) : () => {}),

    navigatoToEditLearningsLearningAttendance: (journeyId?: number, learningId?: number) =>
      journeyId && learningId ? navigate(href.adminEditLearningsLearningAttendanceHref(journeyId, learningId)) : () => {},

    navigatoToInsights: (journeyId?: number) => (journeyId ? navigate(href.adminInsightsHref(journeyId)) : () => {}),

    navigateToLearnerView: (ops: { journeyId: number; journeyTitle: string }) =>
      ops.journeyId ? navigate(href.learnerViewHref(ops)) : () => {},

    navigateToAdminCatalog: () => navigate(href.adminCatalogHref),

    navigateToAdminUsers: () => navigate(href.adminUsersHref),

    navigateToManagerList: () => navigate(href.managerListHref),

    navigateToManagerInsights: (journeyId?: number) => (journeyId ? navigate(href.managerInsightsHref(journeyId)) : () => {}),
  };
};
