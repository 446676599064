import { useMarkToolbarButton, useMarkToolbarButtonState } from '@udecode/plate-common/react';

import { MarkType } from '@/component/customEditorV2/ui/fixedToolbar/types';
import { MARK_TO_ICON } from '@/component/customEditorV2/ui/fixedToolbar/constants';
import { CommonToolbarButton } from '@/component/customEditorV2/ui/fixedToolbar/CommonToolbarButton';

export const MarkToolbarButton = ({ type }: { type: MarkType }): JSX.Element => {
  const state = useMarkToolbarButtonState({ nodeType: type });
  const { props } = useMarkToolbarButton(state);

  return (<CommonToolbarButton onClick={props.onClick} icon={MARK_TO_ICON[type]} toggled={props.pressed} onMouseDown={props.onClick} />);
};
