import { useTranslation } from 'react-i18next';

import { Category } from '@/management/store/types';
import { useCategories } from '@/store/categories';
import { FilterSelect, Select } from '@/ui/Select';

type CategoryFilterProps = {
  placeholder?: string;
  selectedCategory?: string;
  onChange: (category: Category) => void;
  onTrailingIconClick?: () => void;
};

export const CategoryFilter = ({
  placeholder,
  selectedCategory,
  onChange,
  onTrailingIconClick,
}: CategoryFilterProps): JSX.Element => {
  const { t } = useTranslation('categories');
  const { categories } = useCategories();

  const mappedCategories = categories.map((m) => ({ displayValue: t(m.name), value: m.name }));

  const selectedCategoryDisplay = selectedCategory && mappedCategories.find((x) => x.value === selectedCategory)?.displayValue;
  return (
    <FilterSelect
      placeholder={placeholder ?? ''}
      value={selectedCategoryDisplay || ''}
      onChange={(m) => onChange(m as Category)}
      onTrailingIconClick={onTrailingIconClick}
      variant={'dropdown'}
    >
      <Select.List>
        {mappedCategories.map((m) => (
          <Select.Option value={m.value} key={m.value}>
            {m.displayValue}
          </Select.Option>
        ))}
      </Select.List>
    </FilterSelect>
  );
};
