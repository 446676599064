import React from 'react';

import { Input } from '@/ui/TextField';

type SearchPhraseProps = {
  placeholder: string;
  value: string;
  onChange: (searchPhrase: string) => void;
};

export const SearchPhrase = ({ placeholder, value, onChange }: SearchPhraseProps): JSX.Element => (
  <Input
    label=""
    value={value}
    onChange={(e) => onChange(e.currentTarget.value)}
    placeholder={placeholder}
    leadingIcon="search"
  />
);
