import { ReactNode } from 'react';

import { Select } from '@/ui/Select';
import { ActionsListStyled, ActionsSelectButton } from './styles';
import { Position, Value } from '@/ui/Select/types';
import { ButtonVariant } from '@/ui/Button/type/icon';

export const BaseActionsSelect = ({
  onChange,
  children,
  forcedPosition = 'bottom-left',
  buttonVariant,
}: {
  onChange: (value: Value) => void;
  children: ReactNode | ReactNode[];
  forcedPosition?: Position;
  buttonVariant?: ButtonVariant;
}): JSX.Element => {
  return (
    <Select onChange={onChange} value={''}>
      <Select.Header>
        {({ toggleShowOptions }) => <ActionsSelectButton onClick={toggleShowOptions} buttonVariant={buttonVariant} />}
      </Select.Header>
      <ActionsListStyled forcedPosition={forcedPosition}>{children}</ActionsListStyled>
    </Select>
  );
};
