import { PageVariant } from '@/features/course/admin/types/page';
import { IconButton } from '@/ui/Button';
import { Icon } from '@/ui/Icon';
import { Select } from '@/ui/Select';

export const CourseDrawerModuleAddActivityButton = ({ onAddClick }: { onAddClick: (type: PageVariant) => void }): JSX.Element => {
  return (
    <Select
      value=""
      onChange={(type) => {
        onAddClick(type as PageVariant);
      }}
    >
      <Select.Header>
        {({ toggleShowOptions }) => <IconButton icon={'plus'} onClick={toggleShowOptions} buttonVariant="buddySmall" />}
      </Select.Header>
      <Select.List forcedPosition="bottom-left">
        <Select.Option value={PageVariant.PAGE} prefix={<Icon icon={['far', 'rectangle']} />}>
          Page
        </Select.Option>
        <Select.Option value={PageVariant.REFLECTION} prefix={<Icon icon={['far', 'comment-pen']} />}>
          Reflection
        </Select.Option>
        <Select.Option value={PageVariant.QUIZ} prefix={<Icon icon={['far', 'game-board-simple']} />}>
          Quiz
        </Select.Option>
      </Select.List>
    </Select>
  );
};
