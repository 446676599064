import React from 'react';
import { Only } from 'atomic-layout';

import { StyledNavSectionWrapper } from '@/components/Navbar/styles';
import { isRealm } from '@/utils/realms';
import { NavProfileMobileMenu } from '@/components/Navbar/components/mobile/NavProfileMobileMenu';
import { NavRightSection } from '@/components/Navbar/components/NavRightSection';

type NavProfileProps = {
  shouldOverrideAdminView?: boolean;
  isInPreviewModal?: boolean;
  
}
export const NavProfile = ({shouldOverrideAdminView, isInPreviewModal}: NavProfileProps): JSX.Element => {
  return (
    <StyledNavSectionWrapper isKompetens={isRealm('kompetens')} itemsAlign="right">
      <Only to="lg">
        <NavProfileMobileMenu shouldOverrideAdminView={shouldOverrideAdminView} isInPreviewModal={isInPreviewModal}/>
      </Only>
      <Only from="lg">
        <NavRightSection shouldOverrideAdminView={shouldOverrideAdminView} isInPreviewModal={isInPreviewModal}/>
      </Only>
    </StyledNavSectionWrapper>
  );
};
