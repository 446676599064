import React from 'react';
import styled from 'styled-components';

import { cvar } from '@/styles/GlobalStyle';
import { CIconProps } from '@/types/icon';
import { Icon } from '@/components/Icon/Icon';

interface IconWrapperProps {
  description: string;
}
const IconWrapper = styled.span<IconWrapperProps>`
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: column;

  &:hover {
    color: ${cvar('color-black')};

    &:after {
      position: absolute;
      top: 150%;
      content: ${(props) => `'${props.description}'`};
      color: ${cvar('color-white')};
      background-color: ${cvar('color-black')};
      margin-top: ${cvar('gap-xxs')};
      padding: ${cvar('gap-xxs')};
      line-height: ${cvar('gap-xxs')};
      border-radius: ${cvar('radius-inner')};
    }
  }
`;

interface Props extends CIconProps {
  description: string;
}

export const IconHoverDescription = ({ description, ...rest }: Props): JSX.Element => {
  return (
    <IconWrapper description={description}>
      <Icon {...rest} />
    </IconWrapper>
  );
};
