import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { useLearningContentInsightsNavigateWithContext } from '@/features/contentInsights/hooks/useLearningContentNavigate';
import { LearningItemType } from '@/features/journey/admin/hooks/useLazyLearningItems';
import { LearningPartner } from '@/features/catalog/admin/constants/learning';
import { PartnerSVGLogo } from '@/ui/PartnerSVGLogo/index';
import { partnerSVGLogoSize, SVGLogoVariant } from '@/ui/PartnerSVGLogo/partnerSVGLogoDefinitions/partnerSVGLogoDefinitions';

const RootStyled = styled.div`
  display: flex;
  align-items: center;
`;

const ContentTitleStyled = styled(NavLink)`
  ${(p) => p.theme.typography.body3};
  white-space: normal;
  color: ${(p) => p.theme.tones.primary[50]};
  padding-left: 16px;
`;

const LogoWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 48px;
  padding-right: 16px;
`;

type JourneyInsightsLearningItemTitleCellProps = {
  row: LearningItemType;
};

export const JourneyInsightsLearningItemTitleCell = ({
  row: { title, urn, partner },
}: JourneyInsightsLearningItemTitleCellProps): JSX.Element => {
  const [, href] = useLearningContentInsightsNavigateWithContext(urn);

  return (
    <RootStyled>
      {partner && (
        <LogoWrapperStyled>
          <PartnerSVGLogo partner={partner as LearningPartner} size={partnerSVGLogoSize.SMALL} type={SVGLogoVariant.FULL_COLOR} />
        </LogoWrapperStyled>
      )}{' '}
      <ContentTitleStyled to={href}>{title || 'Untitled'}</ContentTitleStyled>
    </RootStyled>
  );
};
