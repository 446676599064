import styled from 'styled-components';
import { PlateElementProps } from '@udecode/plate-common/react';
import { useContext } from 'react';

import { PlateElement } from '@/component/customEditorV2/ui/elements/PlateElement';
import { QuizViewContext } from '@/component/customEditorV2/ui/elements/QuizQuestionElement/View/QuizViewContext';
import { RetakeSection } from '@/administration/pages/Course/pages/quiz/View/ViewQuizPage/RetakeSection';
import { SubmitSection } from '@/administration/pages/Course/pages/quiz/View/ViewQuizPage/SubmitSection';

const RootStyled = styled.div`
  display: flex;
  gap: 8;
  padding: 16px 0;
`;

export const QuizActionbarElement = (props: PlateElementProps): JSX.Element => {
  const { submitted, refetch, setRetakeModalOpen } = useContext(QuizViewContext);

  const handleSubmitted = () => {
    refetch();
  };

  return (
    <PlateElement asChild {...props} className={props.className}>
      <RootStyled>
        {submitted ? (
          <RetakeSection onRetakeClick={() => setRetakeModalOpen(true)} />
        ) : (
          <SubmitSection onSubmit={handleSubmitted} />
        )}
      </RootStyled>
    </PlateElement>
  );
};
