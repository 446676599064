import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from 'react-router-dom';

import { LoadingHero } from '@/component/LoadingHero';
import { Hero, Section } from '@/components/Section/Section';
import { CardGrid } from '@/components/CardGrid/CardGrid';
import { useCategories } from '@/store/categories';
import { Header1, Overline, StyledLink } from '@/components/Typography/Typography';
import { CARDFILTER_PAGETYPE } from '@/components/CardGrid/CardFilter';
import { useFilterPastEvents } from '@/hooks/useFilterPastEvents';
import { UpcomingEvents } from './components/UpcomingEvents';
import { useCategoriesFromCatalog } from '@/hooks/useCategoriesFromCatalog';

type Params = 'category';
export const CategoryPage = (): JSX.Element | null => {
  const { t } = useTranslation('catalog');
  const { setSelectedCategoryId, isLoadingCatalog, subjectsFromCatalog, catalog } = useCategoriesFromCatalog();
  const { categories, loading: loadingCategories } = useCategories();
  const { category: categoryId } = useParams<Params>();

  const cards = useFilterPastEvents(catalog.cards);
  useEffect(() => {
    if (categoryId) {
      setSelectedCategoryId(Number(categoryId));
    }
  }, [categoryId]);

  if (loadingCategories) return <LoadingHero />;
  const category = categories.find(({ id }) => id === Number(categoryId));
  if (!category) return <Navigate to="/404" replace />;

  return (
    <>
      <Hero paddedBottom>
        <Header1>{t(category.name, { ns: 'categories' })}</Header1>
        <Overline>{t('Subjects')}</Overline>
        <div>
          {subjectsFromCatalog?.map((sub, i) => (
            <React.Fragment key={sub.subjectId}>
              <StyledLink to={`/learning/category/${categoryId}/subject/${sub.subjectId}`}>
                {t(sub.subjectName, { ns: 'subjects' })}
              </StyledLink>
              {i < subjectsFromCatalog?.length - 1 && ', '}
            </React.Fragment>
          ))}
        </div>
      </Hero>
      <UpcomingEvents cards={cards} loading={isLoadingCatalog} />
      <Section>
        <CardGrid
          title={t('Learning items', { ns: 'assignmentGroup' })}
          filters={['title', 'meta', 'attributes']}
          cards={cards.filter((card) => card.subjects.some((sub) => sub.categoryId === category.id))}
          loading={isLoadingCatalog}
          pageType={CARDFILTER_PAGETYPE.CATSUB}
        />
      </Section>
    </>
  );
};
