import React, { FocusEvent, FocusEventHandler, useCallback } from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';

import { InputBasic, InputBasicProps } from './InputBasic';

export type InputProps = InputBasicProps & {
  name: string;
  maxLength?: number;
  valueAsNumber?: boolean;
  handleSideEffect?: (val: string | number) => void;
};

export const Input = ({ name, required, valueAsNumber, maxLength, handleSideEffect, ...rest }: InputProps): JSX.Element => {
  const { formState, control } = useFormContext();
  const error = formState.errors[name];
  const registerOptions: RegisterOptions = {
    required,
    valueAsNumber,
    maxLength,
    setValueAs: (v) => v.replace(/\s+/g, ' ').trim(),
  };

  const handleBlur = useCallback(
    (onBlur: FocusEventHandler<HTMLInputElement>) => (e: FocusEvent<HTMLInputElement>) => {
      const val = valueAsNumber ? e.target.valueAsNumber : e.target.value;
      handleSideEffect?.(val);
      onBlur?.(e);
    },
    [handleSideEffect]
  );

  return (
    <Controller
      name={name}
      control={control}
      rules={registerOptions}
      render={({ field: { ref, value, onBlur, ...restField } }) => (
        <InputBasic error={error} {...rest} value={value || ''} onBlur={handleBlur(onBlur)} {...restField} />
      )}
    />
  );
};
