import { useTranslation } from 'react-i18next';

import { Modal } from '@/ui/Modal';
import { EmptyStateNoData } from '@/administration/pages/Journey/common/emptyStates/EmptyStateNoData';
import { Button } from '@/ui/Button';
import { useJourneyNavigate } from '@/administration/pages/Journey/utils/useJourneyNavigate';

export const NoUsersModalContent = ({ onClose }: { onClose: () => void }): JSX.Element => {
  const { navigateToAdminUsers } = useJourneyNavigate();
  const { t } = useTranslation('journey');

  return (
    <>
      <Modal.Contents>
        <EmptyStateNoData
          title={t('add-learners-modal-nodata-title')}
          subtitle={t('add-learners-modal-nodata-subtitle')}
          action={
            <Button variant="primary" icon="arrow-right" $iconPosition="end" onClick={navigateToAdminUsers}>
              {t('add-learners-modal-nodata-action')}
            </Button>
          }
        />
      </Modal.Contents>
      <Modal.Actions>
        <Modal.Action action={onClose}>Close</Modal.Action>
      </Modal.Actions>
    </>
  );
};
