import { HTMLProps } from 'react';
import styled from 'styled-components';

import { deviceUp } from '@/styles/utils';

type InnerSpacingUnion = 'small' | 'default' | 'large';

enum Direction {
  Row = 'row',
  Column = 'column',
}

enum Wrap {
  wrap = 'wrap',
  nowrap = 'nowrap',
}

type ButtonListProps = {
  align?: 'right' | 'center';
  padded?: 'vertical' | 'bottom';
  direction?: Direction;
  $wrap?: keyof typeof Wrap;
  innerSpacing?: InnerSpacingUnion;
} & HTMLProps<HTMLDivElement>;

const handleInnerSpacing: { [key in InnerSpacingUnion]: string } = {
  small: '--gap-xs',
  default: '--gap-sm',
  large: '--gap-md',
};

export const ButtonList = styled.div<ButtonListProps>`
  align-items: center;
  display: flex;
  flex-direction: ${({ direction }) => (direction === Direction.Column ? 'column' : 'row')};
  flex-wrap: ${({ $wrap }) => ($wrap === Wrap.nowrap ? 'nowrap' : 'wrap')};
  justify-content: ${({ align }) => (align === 'right' ? 'flex-end' : align === 'center' ? 'center' : 'flex-start')};
  padding: ${({ padded }) => (padded === 'vertical' ? 'var(--gap-sm) 0' : padded === 'bottom' ? '0 0 var(--gap-md) 0' : 0)};

  ${({ $wrap }) => $wrap === Wrap.nowrap && '> * { flex: 1; }'}

  gap: var(v2--gap-xs);

  ${({ innerSpacing = 'small' }) => deviceUp.md`
    gap: var(v2${handleInnerSpacing[innerSpacing]});
  `}
`;
