import { createPlatePlugin } from '@udecode/plate-common/react';

const LOGGING_KEY = 'logging';

/**
 * Logging plugin used to debug rich text editor operations
 */
export const LoggingPlugin = createPlatePlugin({
  key: LOGGING_KEY,
  extendEditor: (ctx) => {
    const { apply } = ctx.editor;
    ctx.editor.apply = (operation) => {
      console.log('Rich tech edtior operation:', operation);
      apply(operation);
    };

    return ctx.editor;
  },
});
