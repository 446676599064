import * as React from 'react';
import { Command as CommandPrimitive } from 'cmdk';
import styled, { css } from 'styled-components';

const CommandItemStyled = styled(CommandPrimitive.Item)<{ $selected?: boolean }>`
  position: relative;
  display: flex;
  cursor: default;
  gap: 8px;
  user-select: none;
  align-items: center;
  outline: none;
  text-transform: none;

  padding: 14px 16px;
  ${({ theme }) => theme.typography.body3};
  color: ${({ theme }) => theme.colors.neutral.onBackground};

  &:hover {
    background-color: ${({ theme }) => theme.colors.states.hover1};
    cursor: pointer;
  }

  &[data-selected='true'] {
    background-color: ${({ theme }) => theme.colors.states.hover1};
  }

  ${({ $selected, theme }) =>
    $selected &&
    css`
      background: ${theme.colors.neutral.backgroundVariant};
    `}
`;

export const CommandItem = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Item> & {
    $selected?: boolean;
  }
>(({ className, ...props }, ref) => <CommandItemStyled ref={ref} className={className} {...props} />);

CommandItem.displayName = CommandPrimitive.Item.displayName;
