const MAX_TITLE_LENGTH = 55;
const MAX_CHILD_TITLE_LENGTH = 40;

export const getDrawerItemTitleShorten = (
  title?: string,
  isChild?: boolean,
  parentLimit?: number,
  childLimit?: number
): string => {
  if (!title) return '';

  const resultParentLimit = parentLimit || MAX_TITLE_LENGTH;
  const resultChildLimit = childLimit || MAX_CHILD_TITLE_LENGTH;

  const limit = isChild ? resultChildLimit : resultParentLimit;

  if (title?.length < limit) {
    return title;
  } else {
    return `${title?.slice(0, limit)}...`;
  }
};
