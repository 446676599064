import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Only } from 'atomic-layout';

import { Icon } from '@/components/Icon';
import { deviceDown, deviceUp } from '@/styles/utils';
import { mapDirectionToFlexbox } from '@/components/Navbar/utils/helpers';
import { OverlineMixin } from '@/components/Typography/Typography';
import { cvar } from '@/styles/GlobalStyle';
import { BUTTON_HEIGHT_ENUM } from '@/components/Button/components/BaseButton';
import { Badge } from '@/components/Badge';
import { maxContentWidth } from '@/ui/theme/layout';

type StyledNavV2Props = { isInPreviewModal?: boolean, justifyContent?: 'space-between' | 'center' };
type StyledMobileMenuProps = {isInPreviewModal?: boolean};

const LOGO_MAX_WIDTH = '120px';
const MOBILE_EXTREME_SECTION_WIDTH = '70px';

const MixinNavPadding = css`
  padding: 0 ${cvar('gap-xs')};

  ${deviceUp.lg`
    padding: ${cvar('gap-sm')} ${cvar('gap-xs')};
  `}

  ${deviceUp.xl`
    padding: ${cvar('gap-sm')};
  `}
`;

const MixinNavElement = css`
  align-items: center;
  background-color: transparent;
  color: ${cvar('color-text')};
  display: flex;
  font-size: ${cvar('font-size-5')};
  justify-content: center;
  line-height: 1.6;
  ${MixinNavPadding}

  svg {
    color: ${cvar('color-icon')};
    font-size: ${cvar('font-size-7')};
    margin-left: ${cvar('gap-xs')};
  }

  &:hover {
    color: ${cvar('color-black')};
    svg {
      color: ${cvar('color-icon-hover')};
    }
  }
`;

export const MixinDropdownMenu = css`
  position: absolute;
  border-radius: 0 0 ${cvar('radius-outer')} ${cvar('radius-outer')};
  background-color: ${cvar('color-background-light')};
  top: calc(${cvar('navbar-height-desktop')} - (${cvar('navbar-height-desktop')} - ${BUTTON_HEIGHT_ENUM.default}rem) / 2);
  cursor: initial;
  z-index: 35;
`;

export const StyledNavWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 30;
`;

export const StyledNavWrapperInPreview = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 30;
`;

export const StyledNavBanner = styled.div`
  background-color: #3b3b3b;
  color: white;
  height: ${cvar('navbar-banner-height')};
  display: flex;
  align-items: center;
  padding-left: 10px;
  justify-content: center;
`;

export const StyledNavInner = styled.div<StyledNavV2Props>`
  align-items: center;
  display: flex;
  ${({ justifyContent }) => `justify-content: ${justifyContent}`};
  width: 100%;
  max-width: ${(p) => p.theme.sizes.maxContentWidth}px;
`;

export const StyledLogoContentWrapper = styled.div`
  align-items: center;
  display: flex;
`;

export const StyledNavLogoContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: flex-start;
  width: ${MOBILE_EXTREME_SECTION_WIDTH};
  p {
    margin-left: ${cvar('gap-xs')};
    color: ${cvar('color-black')};
  }

  ${MixinNavPadding}
  ${deviceUp.lg`
    justify-content: center;
    width: initial;

    p {
      margin-left: ${cvar('gap-md')};
      color: ${cvar('color-text')};
    }
  `}
  a {
    line-height: 1;
  }
`;

export const StyledLogo = styled.img<{ isKompetens?: boolean }>`
  height: ${cvar('logo-mobile-height')};
  max-width: ${LOGO_MAX_WIDTH};
  ${deviceUp.lg`
    height: ${cvar('logo-height')};
  `}
  ${({ isKompetens }) => isKompetens && `&& {height: 12px;}`}
`;

export const StyledMobileButtonsWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  line-height: 1;
  ${MixinNavPadding}
`;

export const StyledNavMobileIcon = styled(Icon)<{ selected?: boolean }>`
  color: ${({ selected }) => cvar(selected ? 'color-icon-hover' : 'color-icon')};
  font-size: ${cvar('font-size-7')};
  margin: ${cvar('gap-xs')};
`;

export const StyledNavSectionWrapper = styled.div<{
  itemsAlign: keyof typeof mapDirectionToFlexbox;
  isKompetens?: boolean;
}>`
  display: flex;
  justify-content: ${(props) => mapDirectionToFlexbox[props.itemsAlign]};
`;

export const StyledNavItem = styled(NavLink)`
  ${MixinNavElement}

  &.selected {
    color: ${cvar('color-black')};
  }

  ${Badge} {
    margin-left: ${cvar('gap-xs')};
  }
`;

export const StyledNavItemNotification = styled.div`
  width: 1rem;
  height: 100%;
  position: relative;

  &:after {
    position: absolute;
    top: 0;
    right: 0;
    background-color: var(--color-monza);
    content: '';
    width: 0.4375rem;
    height: 0.4375rem;
    border-radius: 50%;
  }
`;

export const StyledNavButton = styled.button`
  border: none;
  cursor: pointer;
  ${MixinNavElement}
`;

export const StyledNavProfile = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${MOBILE_EXTREME_SECTION_WIDTH};
  & > *:first-child {
    height: 30px;
    width: 30px;
  }
  > svg {
    color: ${cvar('color-icon')};
    font-size: ${cvar('font-size-7')};
    margin: 0 ${cvar('gap-xs')};
  }
  &:hover > svg {
    color: ${cvar('color-icon-hover')};
  }
`;

export const Overlay = styled.div<{ isOpen?: boolean, isInPreviewModal?: boolean }>`
  position: absolute;
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  width: ${(props) => (props.isInPreviewModal ? "100%" : "100vw")};
  height: ${(props) => (props.isInPreviewModal ? "calc(100vh - 280px)" : `calc(100vh - ${cvar('navbar-height-desktop')})`)};
  top: ${cvar('navbar-height-desktop')};
  left: 0;
  right: 0;
  z-index: 30;
  background-color: ${cvar('color-overlay')};
  border-radius: ${(props) => (props.isInPreviewModal ? "0 0 12px 12px" : "none")}
`;

export const StyledMobileMenu = styled.div<StyledMobileMenuProps>`
  position: absolute;
  top: ${cvar('navbar-height-mobile')};
  right: 0;
  left: 0;
  width: 100%;
  height: ${(props) => (props.isInPreviewModal ? "calc(100vh - 243px)" : `calc(100vh - ${cvar('navbar-height-mobile')})`)};
  overflow: auto;
  background-color: ${cvar('color-background-light')};
  background-image: ${cvar('gradient-gray')};
  ${({ isInPreviewModal }) =>
   isInPreviewModal &&
   `
    box-sizing: border-box;
    border-radius: 0px 0px 12px 12px;
   `}
`;

const MobileMenuItemPrimary = () => css`
  ${OverlineMixin({ color: 'color-black' })}

  svg {
    color: ${cvar('color-icon')};
    font-size: ${cvar('font-size-7')};
    margin: 0 8px;
  }

  &:hover svg {
    color: ${cvar('color-icon-hover')};
  }

  &:last-child {
    border: none;
  }
`;

export const StyledMobileMenuItem = styled.a<{ type: 'primary' | 'secondary' }>`
  align-items: center;
  border-bottom: ${cvar('border-style')};
  color: ${cvar('color-text')};
  display: flex;
  height: 45px;
  justify-content: space-between;
  padding: 0 ${cvar('gap-sm')} 0 ${({ type }) => cvar(type === 'secondary' ? 'gap-lg' : 'gap-sm')};
  width: 100%;
  ${({ type }) => type === 'primary' && MobileMenuItemPrimary}
`;

export const StyledMobileWrapper = styled.div`
  padding: ${cvar('gap-sm')};
`;

export const StyledDesktopWrapper = styled(Only).attrs<{ admin: boolean }>(() => ({ from: 'lg' }))`
  display: block;
  position: absolute;
  ${deviceDown.xl`width: calc(${maxContentWidth}px / 4);`}
  ${deviceUp.xl`width: calc(${maxContentWidth}px / 3);`};
  ${(props) =>
    props.admin &&
    `
      display: flex;
      /**
       * for admin navigation we need to use initial width 
       * independently from media queries
       */
      width: initial !important;
    `}
`;

export const StyledNavV2 = styled.nav<StyledNavV2Props>`
  height: ${cvar('navbar-height-mobile')};
  background: #ffffff;
  /* Shadow-8 */
  box-shadow: 0px 0.6px 1.8px rgba(0, 0, 0, 0.11), 0px 3.2px 7.2px rgba(0, 0, 0, 0.13);
  display: flex;
  align-items: center;
  ${({ justifyContent }) => `justify-content: ${justifyContent}`};

  ${deviceUp.lg`
    height: ${cvar('navbar-height-desktop')};
  `}

   ${({ isInPreviewModal }) =>
   isInPreviewModal &&
   `
    border-radius: 12px 12px 0px 0px;
    box-shadow: 0px 0.8px 1.8px 0px rgba(0, 0, 0, 0.13), 0px 0.15px 0.45px 0px rgba(0, 0, 0, 0.11);
   `}
`;
