import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { UserSelfTrackedLearning } from '@/component/UserSelfTrackedLearning/UserSelfTrackedLearning';
import { useModalContext } from '@/components/Modal/ModalContext';
import { trackOpeningModalForAddingOtherLearningTime } from '@/utils/tracking/myLearningPage';

type UseUserSelfTrackedLearningLogic = {
  openUserSelfTrackedLearningForm: (userSelfTrackedLearningId?: number) => void;
};

export const useUserSelfTrackedLearningLogic = (): UseUserSelfTrackedLearningLogic => {
  const { t } = useTranslation('myLearning');
  const { showModal, hideModal } = useModalContext();

  const openUserSelfTrackedLearningForm = useCallback((userSelfTrackedLearningId?: number) => {
    const children = userSelfTrackedLearningId ? (
      <UserSelfTrackedLearning userSelfTrackedLearningId={userSelfTrackedLearningId} isEditing onClose={hideModal} />
    ) : (
      <UserSelfTrackedLearning onClose={hideModal} />
    );

    const customHeaderText = userSelfTrackedLearningId ? t('Update other learning') : t('Add other learning');

    trackOpeningModalForAddingOtherLearningTime();
    showModal({
      children,
      size: 'large',
      customHeaderText,
      trackingModalName: 'Self Tracked Learning',
      shouldHideOnClickOutside: false,
    });
  }, []);

  return {
    openUserSelfTrackedLearningForm,
  };
};
