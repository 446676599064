import React from 'react';
import { useTranslation } from 'react-i18next';

import { Loader } from './Loader';
import { TBody } from '../styles';
import { TableDeclaration } from '../types';
import { SelectionMeta } from '@/components/Table/hooks/useSelection';
import { ExpandingMeta } from '@/components/Table/hooks/useExpanding';
import { PaginationMeta } from '@/components/Table/hooks/usePagination';
import { AutoRows } from '@/components/Table/components/AutoRows';

interface Props<T> {
  tableId?: string;
  tableDecl: TableDeclaration<T>;
  keyProp: keyof T;
  loading: boolean;
  colSpan: number;
  itemSlice: T[];
  noDataMessage?: string;

  expanding: ExpandingMeta<T>;
  selection: SelectionMeta<T>;
  pagination: PaginationMeta<T>;
}

export const AutoBody = <T extends unknown>({
  tableId,
  tableDecl,
  keyProp,
  loading,
  colSpan,
  itemSlice,
  noDataMessage,

  expanding,
  selection,
  pagination,
}: Props<T>): React.ReactElement => {
  const { t } = useTranslation('common');

  return (
    <TBody>
      <Loader loading={loading} colSpan={colSpan} loadingMessage={t('Loading...', { ns: 'common' })}>
        <>
          {!itemSlice.length && (
            <tr>
              <td colSpan={colSpan} style={{ textAlign: 'center' }}>
                {noDataMessage ? noDataMessage : t('No data')}
              </td>
            </tr>
          )}
          <AutoRows
            tableId={tableId}
            keyProp={keyProp}
            tableDecl={tableDecl}
            items={itemSlice}
            colSpan={colSpan}
            selection={selection}
            expanding={expanding}
            pagination={pagination}
          />
        </>
      </Loader>
    </TBody>
  );
};
