import { NavigateFunction, useNavigate } from 'react-router-dom';

import { ADMIN_ROUTE_PATH } from '@/administration/constants/adminRoutePaths';
import { convertTitle } from '@/utils/misc';

export const useCollectionHref = (): {
  adminCatalogHref: string;
  adminListHref: string;
  adminEditorNewHref: string;
  adminEditorHref: (collectionId?: number) => string;
  adminInsightsHref: (collectionId?: number) => string;
  adminPreviewHref: (collectionId?: number, title?: string) => string;

  learnerViewHref: (collectionId: number, collectionTitle: string) => string;
} => {
  return {
    adminCatalogHref: `${ADMIN_ROUTE_PATH.CATALOG}/`,
    adminListHref: `${ADMIN_ROUTE_PATH.COLLECTIONS_V2}/`,
    adminEditorNewHref: `${ADMIN_ROUTE_PATH.COLLECTION_V2_NEW}/`,
    adminEditorHref: (collectionId?: number) => `${ADMIN_ROUTE_PATH.COLLECTIONS_V2}/${collectionId}/edit`,
    adminInsightsHref: (collectionId?: number) => `${ADMIN_ROUTE_PATH.COLLECTIONS_V2}/${collectionId}/insights`,
    adminPreviewHref: (collectionId?: number, title?: string) =>
      `${ADMIN_ROUTE_PATH.COLLECTIONS_V2}/${collectionId}/${convertTitle(title) || 'untitled'}/preview`,

    learnerViewHref: (collectionId: number, collectionTitle: string) =>
      `/learning-collection/${collectionId}/${convertTitle(collectionTitle)}`,
  };
};

export const useCollectionNavigate = (): {
  navigate: NavigateFunction;

  navigateToAdminCatalog: () => void;
  navigateToAdminList: () => void;
  navigateToAdminEditorNew: () => void;
  navigateToAdminEditor: (collectionId?: number) => void;
  navigateToAdminInsights: (collectionId?: number) => void;
  navigateToAdminPreview: (collectionId?: number, title?: string) => void;

  navigateToLearnerView: (collectionId?: number, collectionTitle?: string) => void;
} => {
  const navigate = useNavigate();
  const href = useCollectionHref();

  return {
    navigate,

    navigateToAdminCatalog: () => navigate(href.adminCatalogHref),

    navigateToAdminList: () => navigate(href.adminListHref),
    navigateToAdminEditorNew: () => navigate(href.adminEditorNewHref),
    navigateToAdminEditor: (collectionId?: number) => (collectionId ? navigate(href.adminEditorHref(collectionId)) : () => {}),
    navigateToAdminInsights: (collectionId?: number) =>
      collectionId ? navigate(href.adminInsightsHref(collectionId)) : () => {},
    navigateToAdminPreview: (collectionId?: number, title?: string) =>
      collectionId ? navigate(href.adminPreviewHref(collectionId, title)) : () => {},

    navigateToLearnerView: (collectionId?: number, collectionTitle?: string) =>
      collectionId ? navigate(href.learnerViewHref(collectionId, collectionTitle || '')) : () => {},
  };
};
