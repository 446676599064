import React from 'react';
import { useParams } from 'react-router';

import { CollectionRouteParams } from '../../LearningCollection/LearningCollectionPage';
import { BackToCollectionButton } from './BackToCollectionButton';
import { BackToJourneyButton } from './BackToJourneyButton';

export const BackToParentButton = ({ isProduct }: { isProduct?: boolean }): JSX.Element | null => {
  const params = useParams<CollectionRouteParams>();

  return params?.collectionId ? <BackToCollectionButton /> : <BackToJourneyButton isProduct={isProduct} />;
};
