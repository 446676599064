import React from 'react';

import { Provider } from '@/store/providers';
import { getIconSize } from '@/utils/getIconSize';
import { StyledProvider } from './styles';
import { ProviderIcon } from '@/component/ProviderIcon/ProviderIcon';

export const ProviderLogo = ({
  provider,
  isCollection,
  className,
  isNewCourseCard,
}: {
  provider?: Provider;
  isCollection?: boolean;
  className?: string;
  isNewCourseCard?: boolean;
}): JSX.Element | null => {
  if (!provider) return null;

  return (
    <StyledProvider isCollection={isCollection} className={className} isNewCourseCard={isNewCourseCard}>
      <ProviderIcon provider={provider} size={getIconSize(provider)} />
    </StyledProvider>
  );
};
