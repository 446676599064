import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{ $loading?: boolean }>`
  pointer-events: ${(p) => (p.$loading ? 'none' : 'auto')};
`;

export const Header = styled.header<{ noTabs?: boolean }>`
  padding-top: 64px;
  background: ${(p) => p.theme.colors.neutral.backgroundVariant};

  ${(p) =>
    p.noTabs &&
    css`
      padding-bottom: 80px;
    `}
`;

export const Breadcrumbs = styled.div`
  max-width: 1344px;
  margin: 0;
  display: flex;
  align-items: center;
`;

export const NavigationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const HeadingWrapper = styled.div`
  margin: 16px 0 8px 0;
`;

export const SubHeading = styled.div``;
export const Tabs = styled.div``;

export const Contents = styled.main``;
