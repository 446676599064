import { useTranslation } from 'react-i18next';

import { FilterSelect, Select } from '@/ui/Select';
import { getCurrentYear } from '@/management/store/common/utils';

type YearFilterProps = {
  placeholder?: string;
  selectedYear?: number;
  onChange: (year: number) => void;
  onTrailingIconClick?: () => void;
  startYear: number;
};

export const YearFilter = ({
  placeholder,
  selectedYear,
  onChange,
  onTrailingIconClick,
  startYear,
}: YearFilterProps): JSX.Element => {
  const { t } = useTranslation('managerDashboard');
  const currentYear = getCurrentYear();
  const diff = currentYear - startYear;

  // Simply gives it to us in format of [..., 2021, 2020, 2019]
  const yearsOptions = [...Array(diff + 1).keys()].map((i) => i + startYear).reverse();

  return (
    <FilterSelect
      placeholder={placeholder ?? t('Year')}
      value={selectedYear ? String(selectedYear) : ''}
      onChange={(year) => onChange(Number(year))}
      onTrailingIconClick={onTrailingIconClick}
      variant={'dropdown'}
    >
      <Select.List>
        {yearsOptions.map((year) => (
          <Select.Option value={String(year)} key={year}>
            {year}
          </Select.Option>
        ))}
      </Select.List>
    </FilterSelect>
  );
};
