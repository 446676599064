import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';

import { Icon } from '../Icon/Icon';
import { StyledFab, StyledFabWrapper, StyledSmallFab } from './styles';
import { useModalContext } from '../Modal/ModalContext';
import { Select } from '@/component/Input/Select';
import { Header2 } from '../Typography/Typography';
import { Realm } from '@/types/realm';
import { realmChanger } from '@/utils/realms';
import { useIsSimpleModeEnabled } from '@/feature-toggles';
import { useRealms } from '@/store/realms';

const handleChangeRealm = (realm: Realm) => {
  if (!realm) return;
  window.location.href = realmChanger(realm);
};

export const SuperAdminMenu: FC = () => {
  const [isSimpleMode, forceSimpleMode, handleSimpleMode] = useIsSimpleModeEnabled();
  const { showModal } = useModalContext();
  const [show, setShow] = useState(false);
  const { realms, loading } = useRealms();

  const handleChooseRealm = () => {
    !!realms &&
      Array.isArray(realms) &&
      showModal({
        children: (
          <>
            <Header2>Switch realm</Header2>
            {loading && 'Loading....'}
            {!loading && (
              <Select
                onChange={handleChangeRealm}
                placeholderText="Select a realm"
                options={realms.map((realm) => ({ label: realm.name, value: realm.realm }))}
              />
            )}
          </>
        ),
      });
  };

  return (
    <>
      <StyledFabWrapper show={show}>
        <StyledSmallFab data-tooltip="Styleguide" as={Link} to="/super-admin/styleguide" onClick={() => setShow(false)}>
          <Icon icon="layer-group" />
        </StyledSmallFab>
        <StyledSmallFab data-tooltip="Checklist" as={Link} to="/super-admin/checklist" onClick={() => setShow(false)}>
          <Icon icon="clipboard-list-check" />
        </StyledSmallFab>
        <StyledSmallFab
          data-tooltip="Toggle Simple UI"
          $active={forceSimpleMode && isSimpleMode}
          onClick={() => {
            setShow(false);
            handleSimpleMode(!forceSimpleMode);
          }}
        >
          <Icon icon="splotch" />
        </StyledSmallFab>
        <StyledSmallFab data-tooltip="Switch Realm" onClick={handleChooseRealm}>
          <Icon icon="sitemap" />
        </StyledSmallFab>
      </StyledFabWrapper>
      <StyledFab data-tooltip="Show SuperAdmin-tools" onClick={() => setShow(!show)}>
        <Icon icon="cog" />
      </StyledFab>
    </>
  );
};
