import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/sv';

import { EventEnrollment } from '../../../../types/learning/learning-catalog';

dayjs.extend(utc);

export const useLocalizedFilterPastEnrollments = (events: readonly EventEnrollment[]): EventEnrollment[] => {
  const { i18n } = useTranslation();
  const today = dayjs(new Date());

  return events.filter(({ startDate }) => dayjs(startDate).locale(i18n.language).isAfter(today));
};
