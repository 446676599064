import React, { ReactNode } from 'react';

import {
  ContentRootStyled,
  DrawerStyled,
  NAVBAR_HEIGHT,
  NavStyled,
  Progress,
  PROGRESS_HEIGHT,
  RootStyled,
} from '@/administration/pages/Course/MainLayoutDeprecated/atoms/Layout/styles';
import { PageScroll, usePageScroll } from '@/administration/pages/Course/MainLayoutDeprecated/atoms/Page/usePageScroll';
import { CourseDrawer } from '@/features/course/admin/templates/CourseDrawer';
import { CourseNavbar } from '@/features/course/admin/atoms/CourseNavbar';
import { Skeleton } from '@/ui/Skeleton';
import { makeNamespacedComponent } from '@/ui/utils';

type LayoutProps = {
  navbar: ReactNode;
  drawer: ReactNode;
  isDrawerOpen: boolean;
  content: ReactNode;
  contentNav?: ReactNode;
  progress?: {
    value: number;
    prevValue: number;
    maxValue?: number;
  };
  contentScroll?: PageScroll;
};

export const _LayoutV2 = ({
  navbar,
  drawer,
  isDrawerOpen,
  content,
  progress,
  contentNav,
  contentScroll,
}: LayoutProps): JSX.Element => {
  const navbarHeight = !!progress ? NAVBAR_HEIGHT + PROGRESS_HEIGHT : NAVBAR_HEIGHT;
  const { scrollRootRef, handleScroll } = usePageScroll(contentScroll, { clientHeightOffset: NAVBAR_HEIGHT });

  return (
    <RootStyled>
      <NavStyled>{navbar}</NavStyled>
      {progress && (
        <Progress
          animationStartPercentage={(progress.prevValue / (progress?.maxValue || 100)) * 100}
          value={progress.value}
          maxValue={progress.maxValue || 100}
          rounded={false}
          animated
        />
      )}
      <ContentRootStyled drawerOpen={isDrawerOpen} ref={scrollRootRef} onScroll={handleScroll}>
        {content}
      </ContentRootStyled>
      {contentNav}
      <DrawerStyled top={navbarHeight} isOpen={isDrawerOpen}>
        {drawer}
      </DrawerStyled>
    </RootStyled>
  );
};

const LoadingState = ({
  isProgressBarVisible,
  isDrawerOpen,
}: {
  isProgressBarVisible?: boolean;
  isDrawerOpen?: boolean;
}): JSX.Element => (
  <RootStyled>
    <NavStyled>
      <CourseNavbar.LoadingState />
    </NavStyled>
    <DrawerStyled top={isProgressBarVisible ? NAVBAR_HEIGHT + PROGRESS_HEIGHT : NAVBAR_HEIGHT} isOpen={!!isDrawerOpen}>
      <CourseDrawer.LoadingState />
    </DrawerStyled>
    <ContentRootStyled drawerOpen={!!isDrawerOpen}>
      <Skeleton />
    </ContentRootStyled>
  </RootStyled>
);

export const LayoutV2 = makeNamespacedComponent(_LayoutV2, {
  LoadingState,
});
