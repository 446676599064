import React from 'react';

import { Icon } from '@/ui/Icon';
import { ChipStyled, ContentStyled, Divider, DropdownChipStyled, IconStyled, TrailingContentStyled } from './styles';
import { ChipProps } from './types';

export const Chip = ({
  children,
  variant,
  disabled,
  mode,
  elevated,
  leadingIcon,
  selected,
  trailingIcon,
  className,
  onLeadingIconClick,
  onTrailingIconClick,
  onSelectedChange,
  onClick,
}: ChipProps): JSX.Element => {
  const handleClick = () => {
    !disabled && onClick && onClick();
    !!selected && !disabled && onSelectedChange && onSelectedChange(!selected);
  };

  const handleClickParent = () => {
    !selected && !disabled && onSelectedChange && onSelectedChange(!selected);
  };

  const handleIconClick =
    (onIconClick: ((e: React.MouseEvent<Element, MouseEvent>) => void) | undefined) =>
    (e: React.MouseEvent<Element, MouseEvent>) => {
      !disabled && onIconClick && onIconClick(e);
    };

  if (variant === 'dropdown') {
    return (
      <DropdownChipStyled
        variant={variant}
        disabled={disabled}
        mode={mode}
        elevated={elevated}
        selected={selected}
        tabIndex={0}
        onClick={handleClickParent}
        className={className}
      >
        <ContentStyled selected={selected} disabled={disabled} onClick={handleClick}>
          {leadingIcon && <IconStyled icon={leadingIcon} iconPosition="start" onClick={handleIconClick(onLeadingIconClick)} />}
          {children}
        </ContentStyled>
        {!!selected && <Divider disabled={disabled} />}
        <TrailingContentStyled selected={selected} disabled={disabled} onClick={handleIconClick(onTrailingIconClick)}>
          {trailingIcon && !!selected && <Icon icon={trailingIcon} onClick={handleIconClick(onTrailingIconClick)} />}
          {trailingIcon && !selected && (
            <IconStyled icon={trailingIcon} iconPosition="end" onClick={handleIconClick(onLeadingIconClick)} />
          )}
        </TrailingContentStyled>
      </DropdownChipStyled>
    );
  }

  return (
    <ChipStyled
      variant={variant}
      disabled={disabled}
      mode={mode}
      elevated={elevated}
      selected={selected}
      tabIndex={0}
      onClick={handleClick}
      className={className}
    >
      {leadingIcon && <IconStyled icon={leadingIcon} iconPosition="start" onClick={handleIconClick(onLeadingIconClick)} />}
      {children}
      {trailingIcon && <IconStyled icon={trailingIcon} iconPosition="end" onClick={handleIconClick(onTrailingIconClick)} />}
    </ChipStyled>
  );
};
