import { useEffect, useState } from 'react';

import { RealmRole } from '@/administration/types/users';
import { AccountStatus } from '@/types/group';

export type UsersTableDataFilter = {
  searchPhrase: string;
  setSearchPhrase: (searchPhrase: string) => void;
  status: AccountStatus | undefined;
  setStatus: (status: AccountStatus | undefined) => void;
  realm: string | undefined;
  setRealm: (realm: string | undefined) => void;
  realmRole: RealmRole | undefined;
  setRealmRole: (realmRole: RealmRole | undefined) => void;
};

type UseUsersTableDataFilterOpts = {
  defaultRealm: string | undefined;
};

export const useUsersTableDataFilter = (opts: UseUsersTableDataFilterOpts): UsersTableDataFilter => {
  const [searchPhrase, setSearchPhrase] = useState('');
  const [status, setStatus] = useState<AccountStatus | undefined>();
  const [realm, setRealm] = useState<string | undefined>(opts?.defaultRealm);
  const [realmRole, setRealmRole] = useState<RealmRole | undefined>();

  useEffect(() => {
    if (!realm) setRealm(opts?.defaultRealm);
  }, [opts.defaultRealm]);

  return {
    searchPhrase,
    setSearchPhrase,
    status,
    setStatus,
    realm,
    setRealm: (realmInput: string | undefined) => setRealm(realmInput || opts?.defaultRealm),
    realmRole,
    setRealmRole,
  };
};
