import { useMediaState } from '@udecode/plate-media/react';

import { AudioResource } from '@/component/customEditorV2/ui/elements/AudioElement/AudioResource';
import { AudioPlaceholder } from '@/component/customEditorV2/ui/elements/AudioElement/AudioPlaceholder';
import { MediaElementUploadProgress } from '@/component/customEditorV2/ui/elements/MediaElementUploadProgress';

type BaseAudioProps = {
  url?: string;
  selected: boolean;
  onUpload: (file: File) => void;
  onRemove: () => void;
  isUploading: boolean;
  uploadingProgress?: number;
};

export const BaseAudio = ({
  url,
  selected,
  onUpload,
  onRemove,
  isUploading,
  uploadingProgress,
}: BaseAudioProps): JSX.Element | null => {
  const { readOnly } = useMediaState();

  if (readOnly && (isUploading || !url)) return null;
  if (isUploading) return <MediaElementUploadProgress currentProgress={uploadingProgress || 0} isSmall />;
  if (!url) return <AudioPlaceholder shouldShowToolbar={selected} onRemove={onRemove} onUpload={onUpload} />;

  return <AudioResource url={url} onRemove={onRemove} shouldShowToolbar={selected && !readOnly} onUpload={onUpload} />;
};
