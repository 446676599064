import { Control, Controller } from 'react-hook-form';
import styled from 'styled-components';

import { Course } from '@/features/course/admin/types/course';
import { UserRole } from '@/types/learning';
import { TextFieldName } from '@/features/course/admin/constants/settingsModal';
import {
  ConnectionUserRoleAction,
  CourseSettingsModalFieldConnectionsRow,
} from '@/features/course/admin/molecules/CourseSettingsModalFieldConnectionsRow/CourseSettingsModalFieldConnectionsRow';

const RootStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
`;

type CourseSettingsModalFieldConnectionsProps = { control: Control<Course>; spaceId: number };

export const CourseSettingsModalFieldConnections = ({
  control,
  spaceId,
}: CourseSettingsModalFieldConnectionsProps): JSX.Element => {
  const userRoles = Object.values(UserRole);

  return (
    <Controller
      control={control}
      name={TextFieldName.UserRoleConnections}
      render={({ field: { onChange, value: selectedUserRolesConnections = [] } }) => {
        return (
          <RootStyled onClick={(e) => e.stopPropagation()}>
            {userRoles.map((userRole) => {
              return (
                <CourseSettingsModalFieldConnectionsRow
                  key={userRole}
                  label={userRole}
                  spaceId={spaceId}
                  selectedUserRolesConnections={selectedUserRolesConnections}
                  onUsersRoleChange={({ userId, action }) => {
                    if (action === ConnectionUserRoleAction.Add) {
                      onChange([
                        ...selectedUserRolesConnections,
                        {
                          userId,
                          role: userRole,
                        },
                      ]);
                    } else if (action === ConnectionUserRoleAction.Remove) {
                      onChange(selectedUserRolesConnections.filter((connection) => connection.userId !== userId));
                    }
                  }}
                />
              );
            })}
          </RootStyled>
        );
      }}
    />
  );
};
