import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { CollegialContainer } from '@/component/CollegialContainer/CollegialContainer';
import { Checkbox as StandardCheckbox } from '@/components/Checkbox/Checkbox';
import { BodyLg, Header1 } from '@/components/Typography/Typography';
import { Avatar as StandardAvatar } from '@/component/Avatar';

const bezier = 'cubic-bezier(0.25, 0.8, 0.25, 1)';
const matchLargeScreen = '@media screen and (min-width: 768px)';

export const Wrapper = styled(CollegialContainer)`
  margin-top: 3rem;
`;

export const Content = styled.main`
  border-radius: 8px;
  margin: 2rem auto 0 auto;
  display: flex;
  justify-content: center;
  max-width: 480px;

  ${Header1}, ${BodyLg} {
    color: var(--color-moon-crater-gray);
  }

  ${BodyLg}:not (:last-child) {
    margin-bottom: 1rem;
  }
`;

export const AllSteps = styled.div`
  align-self: stretch;
  flex: 1;
  min-height: 400px;
  margin: 0;
  order: 2;
  padding: 0;
  position: relative;
  display: grid;

  ${matchLargeScreen} {
    min-height: 350px;
  }
`;

/**
Note: we use a regular class here because conditional styling using a prop does not work as intended:
  ${props => props.active && css`
    // styles for active item
    & ~ & {
      // styles for all items after active item
    }
  `}
This seems to be due to how SC expands "&":
(see https://github.com/styled-components/styled-components/issues/3265)
  */
export const SingleStep = styled.section`
  height: 100%;
  opacity: 0;
  position: relative;
  transform: translateX(-72px);
  transition: all 0.4s ${bezier};
  width: 100%;
  grid-row: 1;
  grid-column: 1;
  pointer-events: none;

  &.active {
    transform: translateX(0) scale(1);
    opacity: 1;
    transition-delay: 0.4s;
    pointer-events: auto;
  }

  &.active ~ & {
    opacity: 0;
    transform: translateX(72px);
  }
`;

export const Checkbox = styled(StandardCheckbox)`
  && label {
    color: var(--color-moon-crater-gray);
  }
  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }
`;

export const Avatar = styled(StandardAvatar)`
  max-width: 192px;
  max-height: 192px;
  margin: 0 auto 2rem auto;
`;

export const TermsLink = styled(Link)`
  font-weight: bold;
  &:hover {
    text-decoration: underline;
  }
`;

export const Footer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 1rem 0 0 0;
`;

export const Pagination = styled.nav`
  align-items: center;
  border-radius: 20px;
  display: flex;
  height: 30px;
  justify-content: center;
  margin: 2rem auto 0 auto;
  position: relative;
  width: 100px;
  z-index: 20;
`;

export const PaginationDot = styled.button<{ active: boolean }>`
  margin: 0;
  padding: 0;
  background: none;
  border: 2px solid var(--color-alto);
  border-radius: 16px;
  cursor: pointer;
  height: 16px;
  margin: 0 4px;
  width: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  &:after {
    position: absolute;
    content: '';
    display: block;
    border-radius: 6px;
    display: block;
    height: 6px;
    width: 6px;
    background: var(--color-dark-gray);
    transition: transform 0.4s ${bezier};
    transform: scale(${(p) => (p.active ? 1 : 0)});
  }
`;
