import { Nominal } from './nominal';
import { Actor, actorFromUserId } from './actor';
import { Verb, verbAnswered, verbCompleted, verbReacted, verbRegistered, verbProgressed } from './verb';
import { Result, ResultProps } from './result';
import { Context, contextPlatformCollegial, contextPlatformCollegialQuestion } from './context';
import { XObject, objectActivityFromIdAndName } from './object';
import { StatementId, StatementIdNamespace, statementIdFromNameAndNamespace } from './statementId';
import { Timestamp, timestampFromDate } from './timestamp';
import { Version, version1 } from './version';
import { ReactionId } from '@/administration/pages/Course/store/block/types';
import { ActivityDefinitionType } from '@/store/xapi/model/activity';
import { clamp } from '@/utils/number';

export type Statement = Nominal<StatementProps, 'XAPIStatement'>;

type StatementProps = {
  id?: StatementId;
  actor: Actor;
  verb: Verb;
  object: XObject;
  result?: Result;
  context?: Context;
  timestamp?: Timestamp;
  authority?: Actor;
  version?: Version;
};

interface StatementCompletedProps {
  userId: number;
  objectId: string;
  objectName: string;
  timestamp: Date;
  objectDuration?: number; // Object duration value represented in MINUTES
  registration?: string;
}

interface StatementProgressedProps {
  userId: number;
  objectId: string;
  objectName: string;
  timestamp: Date;
  objectDuration?: number; // Object duration value represented in MINUTES
  registration?: string;
  progress: number;
}

export type StatementInput = StatementProps;

export const statementFrom = (input: StatementInput): Statement => <Statement>{ ...input };

interface StatementReactedProps {
  userId: number;
  objectId: string;
  objectName: string;
  timestamp: Date;
  reactionId: ReactionId;
  registration?: string;
}

export const statementCompleted = ({
  userId,
  objectId,
  objectName,
  timestamp,
  objectDuration,
  registration,
}: StatementCompletedProps): Statement => {
  return statementFrom({
    id: statementIdFromNameAndNamespace(
      JSON.stringify({ userId: String(userId), objectId, result: { completion: true } }),
      StatementIdNamespace.COMPLETION
    ),
    version: version1(),
    actor: actorFromUserId(userId),
    verb: verbCompleted(),
    object: objectActivityFromIdAndName(objectId, objectName),
    context: contextPlatformCollegial(objectDuration, registration),
    timestamp: timestampFromDate(timestamp),
  });
};

export const statementProgressed = ({
  userId,
  objectId,
  objectName,
  timestamp,
  objectDuration,
  registration,
  progress,
}: StatementProgressedProps): Statement => {
  const progressClamped = clamp(progress, 0, 100);
  return statementFrom({
    id: statementIdFromNameAndNamespace(
      JSON.stringify({
        userId: String(userId),
        objectId,
        result: { completion: progressClamped === 100, progress: progressClamped },
      }),
      StatementIdNamespace.PROGRESSED
    ),
    version: version1(),
    actor: actorFromUserId(userId),
    verb: verbProgressed(),
    object: objectActivityFromIdAndName(objectId, objectName),
    context: contextPlatformCollegial(objectDuration, registration),
    timestamp: timestampFromDate(timestamp),
    result: {
      completion: progressClamped === 100,
      extensions: [
        {
          key: 'https://w3id.org/xapi/cmi5/result/extensions/progress',
          value: progressClamped,
        },
      ],
    } as Result,
  });
};

export const statementRegistered = ({
  userId,
  objectId,
  objectName,
  timestamp,
  objectDuration,
  registration,
}: StatementCompletedProps): Statement => {
  return statementFrom({
    id: statementIdFromNameAndNamespace(
      JSON.stringify({ userId: String(userId), objectId, registration, timestamp }),
      StatementIdNamespace.REGISTRATION
    ),
    version: version1(),
    actor: actorFromUserId(userId),
    verb: verbRegistered(),
    object: objectActivityFromIdAndName(objectId, objectName),
    context: contextPlatformCollegial(objectDuration, registration),
    timestamp: timestampFromDate(timestamp),
  });
};

export const statementReaction = ({
  userId,
  objectId,
  objectName,
  timestamp,
  reactionId,
  registration,
}: StatementReactedProps): Statement => {
  return statementFrom({
    id: statementIdFromNameAndNamespace(
      JSON.stringify({ userId: String(userId), objectId, reactionId }),
      StatementIdNamespace.REACTION
    ),
    version: version1(),
    actor: actorFromUserId(userId),
    verb: verbReacted(),
    object: objectActivityFromIdAndName(objectId, objectName),
    context: contextPlatformCollegial(undefined, registration, reactionId),
    timestamp: timestampFromDate(timestamp),
  });
};

interface StatementQuestionAnsweredProps {
  userId: number;
  learningObject: {
    id: string;
    name: string;
  };
  questionObject: {
    id: string;
    name: string;
    type: ActivityDefinitionType;
  };
  questionAnswer: ResultProps;
  registration: string;
  timestamp: Date;
}

export const statementQuestionAnswered = ({
  userId,
  questionObject,
  learningObject,
  questionAnswer,
  timestamp,
  registration,
}: StatementQuestionAnsweredProps): Statement => {
  return statementFrom({
    id: statementIdFromNameAndNamespace(
      JSON.stringify({ userId: String(userId), objectId: questionObject.id, verb: 'answered' }),
      StatementIdNamespace.ANSWERED
    ),
    actor: actorFromUserId(userId),
    verb: verbAnswered(),
    object: objectActivityFromIdAndName(questionObject.id, questionObject.name, questionObject.type),
    result: questionAnswer as Result,
    version: version1(),
    timestamp: timestampFromDate(timestamp),
    context: contextPlatformCollegialQuestion(registration, learningObject.id),
  });
};
