import { useTranslation } from 'react-i18next';
import { Fragment } from 'react';

import { ADMIN_LINKS, MobileMenuItem, MOBILE_MENU, MENU_LABELS } from '@/components/Navbar/consts';
import { StyledMobileMenu, StyledMobileMenuItem, StyledNavProfile } from '@/components/Navbar/styles';
import { Icon } from '@/components/Icon';
import { useDropdown } from '@/hooks/useDropdown';
import { useRootContext } from '@/store/RootContext';
import { Avatar } from '@/component/Avatar';
import { useNotifications } from '@/store/notifications';
import { FeatureName, useIsFeatureEnabled } from '@/feature-toggles';
import { Badge, TYPES } from '@/components/Badge';
import { checkAdminView } from '@/utils/checkAdminView';
import { useContentRequestsCount } from '@/administration/store/administration';
import { useCurrentUserAccess } from '@/store/currentUser';
import { useMyLearningCards } from '@/pages/user/MyLearning/hooks';

const MenuItem = (props: {
  type: 'primary' | 'secondary';
  item: MobileMenuItem;
  close: () => void;
  isFeatureEnabled: (f: FeatureName) => boolean;
  count?: number;
  shouldOverrideAdminView?: boolean;
}): JSX.Element => {
  const { t } = useTranslation('navbar');
  const { isAdmin: userIsAdmin, isManager } = useCurrentUserAccess();

  const isAdmin = props.shouldOverrideAdminView ? false : userIsAdmin;

  let link = props.item.link || '';
  if (!link && props.item.linkFactory) {
    link = props.item.linkFactory(props.isFeatureEnabled);
  }

  if (Boolean(props.item.admin) && !isAdmin) return <></>;
  if (Boolean(props.item.manager) && !isManager) return <></>;

  return (
    <StyledMobileMenuItem type={props.type} href={link} target={props.item.newTab ? '_blank' : '_self'} onClick={props.close}>
      <span>{t(props.item.label)}</span>
      {props.item.icon && <Icon icon={props.item.icon} />}
      {props.item.count && Number(props.count) > 0 && <Badge type={TYPES.SECONDARY}>{props.count}</Badge>}
    </StyledMobileMenuItem>
  );
};

const NOTIFICATIONS_COUNT_POLLING_INTERVAL_SEC = 60 * 5;

type NavProfileMobileMenuProps = {
  shouldOverrideAdminView?: boolean;
  isInPreviewModal?: boolean;
};

export const NavProfileMobileMenu = ({ shouldOverrideAdminView, isInPreviewModal }: NavProfileMobileMenuProps): JSX.Element => {
  const { isOpen, containerRef, handleOpenClose, close } = useDropdown<HTMLDivElement>();
  const { currentUser } = useRootContext();
  const { notifications } = useNotifications(NOTIFICATIONS_COUNT_POLLING_INTERVAL_SEC);

  const { ongoingCount, todoCount: assignedCount } = useMyLearningCards({});
  const isFeatureEnabled = useIsFeatureEnabled();

  const approvalsCount = useContentRequestsCount();
  const isAdminView = checkAdminView({ shouldOverrideAdminView });

  const menuItems = isAdminView ? ADMIN_LINKS : MOBILE_MENU;

  const COUNTS: { [key in MENU_LABELS]?: number } = {
    [MENU_LABELS.NOTIFICATIONS]: notifications.length,
    [MENU_LABELS.ONGOING]: ongoingCount,
    [MENU_LABELS.ASSIGNED]: assignedCount,
    [MENU_LABELS.APPROVE]: approvalsCount,
  };

  return (
    <StyledNavProfile ref={containerRef}>
      <Avatar profileImage={currentUser?.profileImage} />
      <Icon icon={!isOpen ? 'bars' : 'times'} onClick={() => handleOpenClose(!isOpen)} />
      {isOpen && (
        <StyledMobileMenu isInPreviewModal={isInPreviewModal}>
          {menuItems.map((primaryItem) => (
            <Fragment key={primaryItem.label}>
              <MenuItem
                type="primary"
                item={primaryItem}
                close={close}
                count={COUNTS[primaryItem.label]}
                isFeatureEnabled={isFeatureEnabled}
                shouldOverrideAdminView={shouldOverrideAdminView}
              />
              {primaryItem.children &&
                primaryItem.children.map((secondaryItem) => (
                  <MenuItem
                    key={secondaryItem.label}
                    type="secondary"
                    item={secondaryItem}
                    close={close}
                    count={COUNTS[secondaryItem.label]}
                    isFeatureEnabled={isFeatureEnabled}
                    shouldOverrideAdminView={shouldOverrideAdminView}
                  />
                ))}
            </Fragment>
          ))}
        </StyledMobileMenu>
      )}
    </StyledNavProfile>
  );
};
