import styled, { css } from 'styled-components';

import { body3, body4 } from '@/components/Typography/v2';

const sizeStyles = {
  sm: css`
    ${body4}

    padding: 0px 4px;
  `,
  md: css`
    ${body3}

    padding: 4px 6px;
  `,
} as const;

type TagSize = keyof typeof sizeStyles;

export type TagProps = { bgColor?: string; size?: TagSize };

export const Tag = styled.span<TagProps>`
  ${({ size = 'md' }) => sizeStyles[size]}

  display: inline-flex;
  align-items: center;
  gap: 4px;
  margin: auto;

  ${({ bgColor = '#DAE4E4' }) => `background-color: ${bgColor};`}

  border-radius: 4px;
`;
