import { useTranslation } from 'react-i18next';
import { useContext } from 'react';

import { RUpsertLearningJourneyCohortsMutation, useUpsertLearningJourneyCohortsMutationRemote } from '@/store/v2';
import { SharedSnackbarContext } from '@/component/SharedSnackbar/SharedSnackbar';

type UseUpsertLearningJourneyCohortsHook = () => {
  upsertLearningJourneyCohorts: (
    journeyId: number,
    learnerIds: number[],
    onSuccess?: () => void
  ) => Promise<{ errors?: unknown | undefined; data?: RUpsertLearningJourneyCohortsMutation | null | undefined }>;
  loading: boolean;
};

export const useUpsertLearningJourneyCohorts: UseUpsertLearningJourneyCohortsHook = () => {
  const [upsertLearningJourneyCohorts, { loading }] = useUpsertLearningJourneyCohortsMutationRemote();
  const { openSnackbar } = useContext(SharedSnackbarContext);
  const { t } = useTranslation('journey');

  return {
    upsertLearningJourneyCohorts: async (journeyId: number, learnerIds: number[], onSuccess?: () => void) => {
      return await upsertLearningJourneyCohorts({
        variables: {
          input: {
            journeyId,
            learnerIds,
          },
        },
        update(cache, { data }) {
          if (data?.upsertLearningJourneyCohorts) {
            openSnackbar({ message: t('notification-journey-updated-learners'), isDismissive: true });

            onSuccess && onSuccess();

            cache.evict({ fieldName: 'learningJourneySession' });
            cache.evict({ fieldName: 'reportingMyLearningJourneysLearners' });
            cache.evict({ fieldName: 'reportingMyLearningJourneysLearnersTotalCount' });
          }
        },
      });
    },
    loading,
  };
};
