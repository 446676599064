import styled from 'styled-components';

import { Card } from '@/ui/Card';
import { Skeleton } from '@/ui/Skeleton';
import { makeNamespacedComponent } from '@/ui/utils';
import {
  LearningCardMeta,
  LearningCardMetaProps,
} from '@/administration/pages/Journey/common/atoms/LearningCard/LearningCardMeta';
import {
  LearningCardProgress,
  LearningCardProgressProps,
} from '@/administration/pages/Journey/common/atoms/LearningCard/LearningCardProgress';
import { Icon } from '@/ui/Icon';
import SomethingWentWrongImage from '@/assets/something-went-wrong.svg';
import { RLearningContentLocationData, RLearningType } from '@/store/v2';

export enum LearningCardError {
  UNAVAILABLE = 'UNAVAILABLE',
}

export type LearningCardProps = {
  objectId: string;
  id?: number;
  imageUrl?: string;
  type?: string;
  title?: string;
  totalEffort?: number;
  totalLearningItems?: number;
  disabled?: boolean;
  locked?: boolean;
  locations?: RLearningContentLocationData[];
  description?: string;
  error?: LearningCardError;
  progress?: LearningCardProgressProps['progress'];
  isInPreviewModal?: boolean;
} & LearningCardMetaProps;

const RootStyled = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const ImageBody = styled.div`
  position: relative;
  width: 96px;
  height: 64px;
  border-radius: 4px;
`;

const ImageStyled = styled.img`
  width: 96px;
  height: 64px;
  object-fit: cover;
  border-radius: 4px;
`;

const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  color: ${({ theme }) => theme.colors.neutral.background};

  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%);

  border-radius: 4px;
`;

const ContentStyled = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
`;

const HeadingStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const CardOverlineStyled = styled(Card.Overline)`
  margin: 0;
`;

const CardTitleStyled = styled(Card.Title)`
  margin: 0;
  max-width: 600px;
`;

const MetaWrapStyled = styled.div`
  display: flex;
  gap: 16px;
`;

const LearningCardUnavailable = (): JSX.Element => {
  return (
    <RootStyled>
      <ImageBody>
        <ImageStyled src={SomethingWentWrongImage} />
      </ImageBody>
      <ContentStyled>
        <HeadingStyled>
          <CardTitleStyled>Learning item not available</CardTitleStyled>
        </HeadingStyled>
      </ContentStyled>
    </RootStyled>
  );
};

const CATALOG_TYPE_TO_CUSTOM_LABEL: Partial<Record<RLearningType, string>> = {
  [RLearningType.PracticalAssessment]: 'Assessment',
  [RLearningType.LiveEvent]: 'Live event',
};

const LearningCard = ({
  imageUrl,
  title,
  type,
  totalEffort,
  totalLearningItems,
  level,
  progress,
  error,
  disabled,
  locked,
  locations,
  systemGenerated,
}: LearningCardProps): JSX.Element => {
  if (error === LearningCardError.UNAVAILABLE) {
    return <LearningCardUnavailable />;
  }

  const shouldShowIcon = locked || progress?.isCompleted;

  return (
    <RootStyled>
      {imageUrl && (
        <ImageBody>
          <ImageStyled src={imageUrl} />
          {shouldShowIcon && (
            <ImageOverlay>
              <Icon icon={['far', progress?.isCompleted ? 'check' : 'lock']} size="2x" />
            </ImageOverlay>
          )}
        </ImageBody>
      )}
      <ContentStyled disabled={disabled}>
        <HeadingStyled>
          {type && <CardOverlineStyled>{CATALOG_TYPE_TO_CUSTOM_LABEL[type as RLearningType] || type}</CardOverlineStyled>}
          <CardTitleStyled>{title}</CardTitleStyled>
        </HeadingStyled>
        {progress && (
          <LearningCardProgress progress={progress} totalEffort={totalEffort} totalLearningItems={totalLearningItems} />
        )}
        {!progress && (
          <LearningCardMeta
            type={type}
            level={level}
            totalEffort={totalEffort}
            totalLearningItems={totalLearningItems}
            locations={locations}
            systemGenerated={systemGenerated}
          />
        )}
      </ContentStyled>
    </RootStyled>
  );
};

const LoadingState = (): JSX.Element => (
  <RootStyled>
    <Skeleton width={96} height={64} />
    <ContentStyled>
      <HeadingStyled>
        <Skeleton width={60} height={16} />
        <Skeleton width={250} height={24} />
      </HeadingStyled>
      <MetaWrapStyled>
        <Skeleton width={70} height={16} />
        <Skeleton width={70} height={16} />
      </MetaWrapStyled>
    </ContentStyled>
  </RootStyled>
);

const _LearningCard = makeNamespacedComponent(LearningCard, {
  LoadingState,
});

export { _LearningCard as LearningCard };
