import { useLinkToolbarButton, useLinkToolbarButtonState } from '@udecode/plate-link/react';

import { CommonToolbarButton } from '@/component/customEditorV2/ui/fixedToolbar/CommonToolbarButton';

export const LinkToolbarButton = (): JSX.Element => {
  const state = useLinkToolbarButtonState();
  const { props } = useLinkToolbarButton(state);

  return (
    <CommonToolbarButton onClick={props.onClick} icon={['far', 'link']} toggled={props.pressed} />
  );
};
