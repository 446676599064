import { useEffect } from 'react';
import { findNodePath, useEditorRef, PlateElementProps, useRemoveNodeButton } from '@udecode/plate-common/react';
import { setNodes, TElement } from '@udecode/plate-common';
import styled, { css } from 'styled-components';
import { useSelected } from 'slate-react';
import { v4 as uuid } from 'uuid';

import { PlateElement } from '@/component/customEditorV2/ui/elements/PlateElement';
import { QuestionTitle } from '@/component/customEditorV2/ui/elements/QuizQuestionElement/Edit/QuestionTitle';
import {
  QuestionTypeChip,
  QUESTION_TYPES_OPTIONS,
  QUESTION_TYPES,
} from '@/component/customEditorV2/ui/elements/QuizQuestionElement/Edit/QuestionTypeChip';
import { SelectChipOption } from '@/ui/Chip/variants/SelectChip';
import { QuestionAnswer, QuestionAnswers } from '@/component/customEditorV2/ui/elements/QuizQuestionElement/Edit/QuestionAnswers';
import { useSelectedElementFloatingToolbar } from '@/component/customEditorV2/hooks/elementFloatingToolbar/useSelectedElementFloatingToolbar';
import { FloatingToolbarButtons } from '@/component/customEditorV2/ui/elements/QuizQuestionElement/Edit/FloatingToolbarButtons';

export type QuizQuestionType = {
  questionType: SelectChipOption;
  title: string;
  answers?: QuestionAnswer[];
  type?: string;
  id?: string;
};

const QuestionContainerStyled = styled.div<{ $selected: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 32px;
  border-radius: 12px;

  ${({ $selected }) =>
    $selected &&
    css`
      outline: 2px solid ${({ theme }) => theme.colors.primary.outline};
      background-color: ${({ theme }) => theme.tones.neutral[99]};
    `};

  &:hover {
    ${({ $selected }) =>
      !$selected &&
      css`
        background: ${({ theme }) => theme.colors.states.hover5};
      `};
  }
`;

type QuizQuestionProps = {
  questionType?: SelectChipOption;
  answers?: QuestionAnswer[];
  title?: string;
};

// eslint-disable-next-line react/display-name
export const QuizQuestionEditElement = (props: PlateElementProps): JSX.Element => {
  const editor = useEditorRef();
  const selected = useSelected();

  const removeNodeButtonProps = useRemoveNodeButton({ element: props.element })?.props;

  useEffect(() => {
    if (!props?.element?.questionType) {
      updateNode({
        ...(props?.element ? props?.element : {}),
        questionType: QUESTION_TYPES_OPTIONS[0],
        title: '',
        answers: [
          { label: '', isCorrect: false, id: uuid() },
          { label: '', isCorrect: false, id: uuid() },
          { label: '', isCorrect: true, id: uuid() },
        ],
      });
    }
  }, [props]);

  const updateNode = (newProps: QuizQuestionProps) => {
    const path = findNodePath(editor, props.element);

    setNodes<TElement>(editor, newProps, {
      at: path,
    });
  };

  const handleTitleChange = (title: string) => {
    updateNode({ ...(props?.element ? props?.element : {}), title });
  };

  const handleQuestionTypeChange = (questionType: SelectChipOption) => {
    let newAnswers = props?.element?.answers;

    // Tricky validation, when creator switches type from multiselect to singleselect
    // we need to have only 1 correct answer
    if (
      (props?.element?.questionType as SelectChipOption)?.value === QUESTION_TYPES.MULTI_SELECT &&
      questionType?.value === QUESTION_TYPES.SINGLE_SELECT
    ) {
      let isFirstCorrectSet = false;

      newAnswers = (props?.element?.answers as QuestionAnswer[])?.map((answerItem) => {
        if (isFirstCorrectSet) {
          return {
            ...answerItem,
            isCorrect: false,
          };
        } else {
          if (answerItem?.isCorrect) {
            isFirstCorrectSet = true;

            return {
              ...answerItem,
              isCorrect: true,
            };
          } else {
            return answerItem;
          }
        }
      });
    }

    updateNode({ ...(props?.element ? props?.element : {}), questionType, answers: newAnswers as QuestionAnswer[] });
  };

  const handleAnswersChange = (answers?: QuestionAnswer[]) => {
    updateNode({ ...(props?.element ? props?.element : {}), answers });
  };

  const handleRemove = () => {
    removeNodeButtonProps.onClick();
  };

  const { setFloating, setReference, style } = useSelectedElementFloatingToolbar({ selected: selected });

  return (
    <PlateElement asChild {...props} className={props.className}>
      <figure contentEditable={false}>
        <QuestionContainerStyled ref={setReference} $selected={selected}>
          <QuestionTitle title={props?.element?.title as string} onTitleChange={handleTitleChange} />
          <QuestionTypeChip onChange={handleQuestionTypeChange} questionType={props?.element?.questionType as SelectChipOption} />
          <QuestionAnswers
            answers={props?.element?.answers as QuestionAnswer[]}
            onAnswersChange={handleAnswersChange}
            questionType={props?.element?.questionType as SelectChipOption}
            isQuestionFocused={selected}
          />
        </QuestionContainerStyled>
        {props?.children}

        <div
          ref={setFloating}
          style={{
            ...style,
          }}
        >
          <FloatingToolbarButtons onRemoveClick={handleRemove} />
        </div>
      </figure>
    </PlateElement>
  );
};
