import { v4 as uuid } from 'uuid';

import { RPage, RPageInput } from '@/store/v2';
import { Page, PageVariantDeprecated as PageVariant } from '@/features/course/admin/types/page';
import {
  createBlock,
  transformBlockFromRemote,
  transformBlockToRemoteInput,
} from '../../../../administration/pages/Course/store/block/transform';
import { BlockType, ReactionId } from '@/administration/pages/Course/store/block/types';
import { PageId } from '@/administration/pages/Course/store/types';
import { Course } from '@/administration/pages/Course/store/course/types';
import { createResultBlock, createSurveyResultBlock } from '@/administration/pages/Course/store/block/transformResult';
import { createReactionBlock } from '../../../../administration/pages/Course/store/block/transformReaction';
import { SurveyVariant } from '@/pages/Survey/store/survey/types';
import { PracticalAssessment } from '@/pages/PracticalAssessment/store/practicalAssessment/types';

export const RESULTS_PAGE_ID = `page-l-result`;
// eslint-disable-next-line import/no-unused-modules
export const transformPageTypeToBlockType = (type: PageVariant): BlockType => {
  switch (type) {
    case PageVariant.RESULT:
      return BlockType.RESULT;
    case PageVariant.REACTION:
      return BlockType.REACTION;
    case PageVariant.PRACTICAL_ASSESSMENT_TEXT:
      return BlockType.PRACTICAL_ASSESSMENT_TEXT;
    case PageVariant.PRACTICAL_ASSESSMENT_DOCUMENT:
      return BlockType.PRACTICAL_ASSESSMENT_DOCUMENT;
    case PageVariant.REFLECTION:
      return BlockType.REFLECTION;
    case PageVariant.TEXT:
    default:
      return BlockType.TEXT;
  }
};

export const transformBlockTypeToPageType = (blockType: BlockType): PageVariant => {
  switch (blockType) {
    case BlockType.QUESTION:
      return PageVariant.QUESTION;
    case BlockType.PRACTICAL_ASSESSMENT_TEXT:
      return PageVariant.PRACTICAL_ASSESSMENT_TEXT;
    case BlockType.PRACTICAL_ASSESSMENT_DOCUMENT:
      return PageVariant.PRACTICAL_ASSESSMENT_DOCUMENT;
    case BlockType.REFLECTION:
      return PageVariant.REFLECTION;
    case BlockType.TEXT:
    default:
      return PageVariant.TEXT;
  }
};

export const createPage = (title: string, type: PageVariant): Page => {
  const page = {
    id: `page-l-${uuid()}` as PageId,
    type,
    title,
    block: createBlock(transformPageTypeToBlockType(type)),
    active: false,
    hasError: false,
  };

  return {
    ...page,
    hasError: validatePage(page),
  };
};

export const createResultPage = (ops: Either<{ course?: Course | PracticalAssessment }, { variant: SurveyVariant }>): Page => {
  return {
    id: RESULTS_PAGE_ID as PageId,
    type: PageVariant.RESULT,
    title: 'Results',
    block: ops.variant ? createSurveyResultBlock(ops.variant) : createResultBlock(ops.course),
    active: false,
    hasError: false,
  };
};

export const createReactionPage = (course?: Course, reactionId?: ReactionId): Page => {
  return {
    id: `page-l-reaction` as PageId,
    type: PageVariant.REACTION,
    title: 'What will you do next?',
    block: createReactionBlock(course, reactionId),
    active: false,
    hasError: false,
    completed: !!reactionId,
  };
};

export const updatePage = (page: Page, update: Partial<Page>): Page => {
  const newPage = <Page>{
    ...page,
    ...update,
    block: {
      ...page.block,
      ...(update?.block || {}),
    },
  };
  const hasErrors = validatePage(newPage);

  return {
    ...newPage,
    hasError: hasErrors,
  };
};

export const transformPageFromRemote = (rPage: RPage): Page => {
  const block = transformBlockFromRemote(rPage?.blocks?.[0] || undefined);

  const page = {
    id: `page-r-${rPage.id}` as PageId,
    remoteId: rPage.id || undefined,
    title: rPage.name || '',
    type: transformBlockTypeToPageType(block.type),
    block,
    active: false,
    hasError: false,
    completed: false, // TODO need completion from api
  };

  return {
    ...page,
    hasError: validatePage(page),
  };
};

export const transformPageToRemote = (page: Page, moduleId?: number, order?: number): RPageInput => {
  return {
    id: page.remoteId,
    moduleId,
    name: page.title,
    order: order || 0,
    blocks: [transformBlockToRemoteInput(page.block, page.remoteId, 0)],
  };
};

export const validatePage = (page?: Page): boolean => {
  if (!page) return true;

  if (page.type === PageVariant.TEXT && page.block.type === BlockType.TEXT) {
    return !page.title || !page.block.text?.length;
  }

  return false;
};
