import { Card } from '../types/learning/card';
import { convertTitle } from './misc';
import { ADMIN_ROUTE_PATH } from '@/administration/constants/adminRoutePaths';

export function getLink(card: Card): string {
  const friendlyTitle = convertTitle(card.title);

  if (card.entity === 'learning') {
    return `/learning/${card.entityId}/${friendlyTitle}`;
  } else if (card.entity === 'journey') {
    return `/journey/${card.entityId}/${friendlyTitle}`;
  } else if (card.entity === 'product') {
    if (card.type === 'journey') return `/journey/${(card as any).journeyId}/${friendlyTitle}`;
    return `/learning/course/details/${card.entityId}/${friendlyTitle}`;
  } else if (card.entity === 'collection') {
    return `/learning-collection/${card.entityId}/${friendlyTitle}`;
  }

  return '';
}

export function getEditLink(card: Card): string {
  const friendlyTitle = convertTitle(card.title);

  if (card.entity === 'learning') {
    // use modular courses path
    if (card.type === 'course') return `${ADMIN_ROUTE_PATH.COURSES_V2}/${card.entityId}/${convertTitle(card.title)}`;

    return `${ADMIN_ROUTE_PATH.CATALOG}/${card.type}/${card.entityId}`;
  } else if (card.entity === 'product') {
    return `${ADMIN_ROUTE_PATH.CATALOG}/course/${card.entityId}`;
  } else if (card.entity === 'collection') {
    return `/administration/learning-collection/${card.entityId}/${friendlyTitle}`;
  } else if (card.entity === 'journey') {
    return `${ADMIN_ROUTE_PATH.JOURNEYS}/${card.entityId}/edit`;
  }

  return '';
}
