import React from 'react';
import styled, { css } from 'styled-components';

import { Mode } from '../../atoms/Chip/types';
import { ChipVariantProps } from '../../types';
import { Chip } from '../../atoms/Chip';
import { CHECKMARK_ICON, CLOSE_ICON } from '../../constants';
import { IconPropType } from '@/ui/Icon';

const ChipStyled = styled(Chip)<{ avatar?: boolean }>`
  min-height: 32px;
  height: 100%;

  ${({ avatar }) =>
    avatar &&
    css`
      height: 32px;
      padding-left: 8px;
      padding-right: 8px;
      border-radius: 8px;
    `}
`;

interface InputChipProps extends ChipVariantProps {
  /**
  Should the chip have avatar styling
  */
  avatar?: boolean;
  /**
  Specific color mode
  */
  mode?: Mode;
  /**
  Should the chip have close icon. Can be overriden by customTrailingIcon
  */
  hasCloseIcon?: boolean;
  /**
  Custom trailing icon for a chip. Overrides the close icon, if hasCloseIcon provided
  */
  customTrailingIcon?: IconPropType;
  /**
  Custom leading icon for a chip. Cannot override checkmark icon for a selected avatar chip
  */
  customLeadingIcon?: IconPropType;
  /**
  Hanlder of the leading icon click event
  */
  onLeadingIconClick?: (e: React.MouseEvent<Element, MouseEvent>) => void;
  /**
  Hanlder of the trailing icon click event
  */
  onTrailingIconClick?: (e: React.MouseEvent<Element, MouseEvent>) => void;
}

export const InputChip = ({
  label,
  avatar,
  mode,
  className,
  disabled,
  hasCloseIcon,
  customTrailingIcon,
  customLeadingIcon,
  onLeadingIconClick,
  onTrailingIconClick,
  onSelectedChange,
  selected,
}: InputChipProps): JSX.Element => {
  // Close trailing icon can be set by props
  const defaultTrailingIcon = hasCloseIcon ? CLOSE_ICON : undefined;
  // But it's overriden by custom trailing icon, if one is provided
  const trailingIcon = customTrailingIcon ? customTrailingIcon : defaultTrailingIcon;

  // Selected avatar chip has a leading icon of checkmark
  const defaultLeadingIcon = avatar && selected ? CHECKMARK_ICON : undefined;
  // If a chip is not avatar, it can have a custom leading icon if one is provided. By default it has no icon
  const leadingIcon = !avatar && customLeadingIcon ? customLeadingIcon : defaultLeadingIcon;

  return (
    <ChipStyled
      avatar={avatar}
      disabled={disabled}
      selected={selected}
      onSelectedChange={onSelectedChange}
      leadingIcon={leadingIcon}
      trailingIcon={trailingIcon}
      className={className}
      variant={avatar ? 'avatar' : 'outline'}
      mode={mode}
      onLeadingIconClick={onLeadingIconClick}
      onTrailingIconClick={onTrailingIconClick}
    >
      <>{label}</>
    </ChipStyled>
  );
};
