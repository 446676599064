import { z, ZodIssueCode } from 'zod';

import i18n from '@/i18n/i18n';

/** Original: https://github.com/colinhacks/zod/blob/200a5bae9d9f49ef2bc4ad2a0746f4275cd6942e/src/ZodError.ts#L268 */
const customErrorMap: z.ZodErrorMap = (issue, ctx) => {
  let message = ctx.defaultError;

  switch (issue.code) {
    case ZodIssueCode.invalid_type:
      if (issue.received === 'undefined') {
        message = i18n.t('validation::required', { field: issue.path[0] });
      } else {
        message = `Expected ${issue.expected}, received ${issue.received}`;
      }
      break;
    case ZodIssueCode.unrecognized_keys:
      message = `Unrecognized key(s) in object: ${issue.keys.map((k) => `'${k}'`).join(', ')}`;
      break;
    case ZodIssueCode.invalid_union:
      message = `Invalid input`;
      break;
    case ZodIssueCode.invalid_enum_value:
      message = `Invalid enum value. Expected ${issue.options
        .map((val) => (typeof val === 'string' ? `'${val}'` : val))
        .join(' | ')}, received ${typeof ctx.data === 'string' ? `'${ctx.data}'` : ctx.data}`;
      break;
    case ZodIssueCode.invalid_arguments:
      message = `Invalid function arguments`;
      break;
    case ZodIssueCode.invalid_return_type:
      message = `Invalid function return type`;
      break;
    case ZodIssueCode.invalid_date:
      message = `Invalid date`;
      break;
    case ZodIssueCode.invalid_string:
      if (issue.validation !== 'regex') message = `Invalid ${issue.validation}`;
      else message = 'Invalid';
      break;
    case ZodIssueCode.too_small:
      if (issue.type === 'array') {
        message = `Should have ${issue.inclusive ? `at least` : `more than`} ${issue.minimum} items`;
      } else if (issue.type === 'string') {
        if (issue.inclusive && issue.minimum === 1) {
          message = i18n.t('validation::required', { field: issue.path[0] });
        } else {
          message = `Should be ${issue.inclusive ? `at least` : `over`} ${issue.minimum} characters`;
        }
      } else if (issue.type === 'number') {
        message = `Value should be greater than ${issue.inclusive ? `or equal to ` : ``}${issue.minimum}`;
      } else {
        message = 'Invalid input';
      }
      break;
    case ZodIssueCode.too_big:
      if (issue.type === 'array') message = `Should have ${issue.inclusive ? `at most` : `less than`} ${issue.maximum} items`;
      else if (issue.type === 'string')
        message = `Should be ${issue.inclusive ? `at most` : `under`} ${issue.maximum} characters long`;
      else if (issue.type === 'number')
        message = `Value should be less than ${issue.inclusive ? `or equal to ` : ``}${issue.maximum}`;
      else message = 'Invalid input';
      break;
    case ZodIssueCode.custom:
      message = `Invalid input.`;
      break;
    default:
      message = `Invalid input.`;
  }
  return { message };
};

z.setErrorMap(customErrorMap);
