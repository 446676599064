import React from 'react';
import styled from 'styled-components';

import { Modal } from '@/ui/Modal';

const BodyStyled = styled.div`
  ${({ theme }) => theme.typography.body2};
`;

type RetakeModalProps = {
  onClose: () => void;
  open: boolean;
  onRetake: () => void;
};

export const RetakeModal = ({ onClose, open, onRetake }: RetakeModalProps): JSX.Element => {
  return (
    <Modal open={open} onClose={onClose} size="mobile">
      <Modal.Title>Give it another go!</Modal.Title>
      <Modal.Contents>
        <BodyStyled>Retake the quiz as many times as you want. Only your latest score will count.</BodyStyled>
      </Modal.Contents>
      <Modal.Actions>
        <Modal.Action action={onClose}>Cancel</Modal.Action>
        <Modal.Action action={onRetake} primary>
          Retake
        </Modal.Action>
      </Modal.Actions>
    </Modal>
  );
};
