/* eslint-disable import/no-unused-modules */
import styled from 'styled-components';
import { ReactNode } from 'react';

import { Button } from '@/ui/Button';
import { BadgeLabel } from '@/ui/Label';
import { Skeleton } from '@/ui/Skeleton';
import { makeNamespacedComponent } from '@/ui/utils';
import { ButtonVariant } from '@/ui/Button/type/default/styles/button';

type HeaderWithActionProps = {
  counter?: number;
  title: string;
  actionProps?: {
    onClick: () => void;
    title: string;
    loading?: boolean;
    variant?: ButtonVariant;
  };
  customAction?: ReactNode;
};

const RootStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TitleStyled = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  ${({ theme }) => theme.typography.title3};
  color: ${({ theme }) => theme.colors.neutral.onBackground};
`;

const HeaderWithAction = ({ counter, title, actionProps, customAction }: HeaderWithActionProps): JSX.Element => {
  const renderAction = () => {
    if (customAction) return <>{customAction}</>;

    if (actionProps)
      return (
        <Button variant={actionProps?.variant || 'primary'} onClick={actionProps.onClick} $loading={actionProps.loading}>
          {actionProps.title}
        </Button>
      );

    return null;
  };

  return (
    <RootStyled>
      <TitleStyled>
        {title} {typeof counter !== 'undefined' && <BadgeLabel badgeVariant="badge-text" text={String(counter)} />}
      </TitleStyled>
      {renderAction()}
    </RootStyled>
  );
};

const LoadingState = ({ actionHidden }: { actionHidden?: boolean }) => (
  <RootStyled>
    <TitleStyled>
      <Skeleton width={144} height={24} />
      <Skeleton width={24} height={16} />
    </TitleStyled>
    {!actionHidden && <Skeleton width={66} height={32} />}
  </RootStyled>
);

const _HeaderWithAction = makeNamespacedComponent(HeaderWithAction, {
  LoadingState,
});

export { _HeaderWithAction as HeaderWithAction };
