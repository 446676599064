import { useMemo } from 'react';

import { RLearningContentContentType } from '@/store/v2';
import { FilterSelect, Select } from '@/ui/Select';

type JourneyInsightsTabsLearningItemsTypeFilterProps = {
  selectedType: string;
  onChange: (selectedType: string) => void;
  onTrailingIconClick: VoidFunction;
};

export const LEARNING_TYPE_TO_DISPLAY_VALUE: Record<string, string> = {
  [RLearningContentContentType.Article]: 'Article',
  [RLearningContentContentType.Audio]: 'Audio',
  [RLearningContentContentType.Collection]: 'Collection',
  [RLearningContentContentType.Course]: 'Course',
  [RLearningContentContentType.ELearning]: 'E-learning',
  [RLearningContentContentType.Journey]: 'Journey',
  [RLearningContentContentType.LiveEvent]: 'Live event',
  [RLearningContentContentType.OnlineLearning]: 'Online learning',
  [RLearningContentContentType.Path]: 'Path',
  [RLearningContentContentType.PracticalAssessment]: 'Practical assessment',
  [RLearningContentContentType.Resource]: 'Resource',
  [RLearningContentContentType.Seminar]: 'Seminar',
  [RLearningContentContentType.Survey]: 'Survey',
  [RLearningContentContentType.Video]: 'Video',
  [RLearningContentContentType.Webinar]: 'Webinar',
};

export const JourneyInsightsTabsLearningItemsTypeFilter = ({
  selectedType,
  onChange,
  onTrailingIconClick,
}: JourneyInsightsTabsLearningItemsTypeFilterProps): React.ReactElement => {
  const options = useMemo(() => {
    const types = Object.entries(RLearningContentContentType);

    return types?.map((typeItem) => {
      return {
        value: typeItem[1],
        displayValue: LEARNING_TYPE_TO_DISPLAY_VALUE[typeItem[1]] || typeItem[0],
      };
    });
  }, []);

  const selectedTypeToDisplay = selectedType && options.find((item) => item.value === selectedType)?.displayValue;

  return (
    <FilterSelect
      placeholder="Learning type"
      value={selectedTypeToDisplay || ''}
      onChange={(newType) => onChange(newType as RLearningContentContentType)}
      onTrailingIconClick={onTrailingIconClick}
      variant={'dropdown'}
    >
      <Select.List>
        {options?.map((optionItem) => (
          <Select.Option value={optionItem.value} key={optionItem.displayValue}>
            {optionItem.displayValue}
          </Select.Option>
        ))}
      </Select.List>
    </FilterSelect>
  );
};
