import styled, { css } from 'styled-components';
import { Children, forwardRef, ReactNode } from 'react';

import {
  InputDefaultCSS,
  TextFieldDefaultCSS,
  TextFieldHoverCSS,
  RootStyled,
  LabelDefaultCSS,
  LabelTransitionCSS,
  IconStyled,
  TextFieldFocusCSS,
} from '@/ui/TextField/base/styles';
import { LabelStyledProps, InputStyledProps } from '@/ui/TextField/base/types';

const TriggerStyled = styled.div<InputStyledProps & { comboboxOpen?: boolean }>`
  ${TextFieldDefaultCSS};
  ${TextFieldHoverCSS};
  ${TextFieldFocusCSS};
  ${InputDefaultCSS};

  min-width: 200px;
  cursor: pointer;

  padding: 7px 16px 7px 16px;

  min-height: 48px;
  height: fit-content;
  box-sizing: border-box;
  position: relative;

  &:focus label {
    ${LabelTransitionCSS};
  }

  ${({ comboboxOpen }) =>
    comboboxOpen &&
    css`
      & label {
        ${LabelTransitionCSS};
      }

      & [data-icon='angle-down'] {
        transform: rotate(180deg) translateY(50%) !important;
      }

      border: ${(p) => p.theme.colors.primary.outline} solid 1px;
      outline: ${(p) => p.theme.colors.primary.outline} solid 1px;
    `}

  & [data-icon='angle-down'] {
    transition: all 0.2s;
  }

  &:focus [data-icon='angle-down'] {
    transform: rotate(180deg) translateY(50%) !important;
  }
`;

const LabelStyled = styled.label<LabelStyledProps>`
  ${LabelDefaultCSS};
`;

const TriggerContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  z-index: 1;
  position: relative;
`;

type ComboboxTriggerProps = Omit<React.HTMLProps<HTMLDivElement>, 'children' | 'as'> & {
  children?: ReactNode;
  label: string;
  comboboxOpen?: boolean;
};

export const ComboboxTrigger = forwardRef<HTMLDivElement, ComboboxTriggerProps>(
  ({ children, label, comboboxOpen, ...rest }, ref) => {
    const hasValue = Boolean(Children.count(children));

    return (
      <RootStyled {...rest} fullWidth ref={ref}>
        <TriggerStyled comboboxOpen={comboboxOpen} hasValue={hasValue} fullWidth tabIndex={0} hasTrailingIcon>
          <TriggerContentStyled>{children}</TriggerContentStyled>
          <LabelStyled hasValue={hasValue}>{label}</LabelStyled>
          <IconStyled icon={'angle-down'} iconPosition="end" visible />
        </TriggerStyled>
      </RootStyled>
    );
  }
);

ComboboxTrigger.displayName = 'ComboboxTrigger';
