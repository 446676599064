import { RefObject, useCallback, useRef, useState } from 'react';

import { useOnEscapePress } from '@/hooks/useOnEscapePress';
import { useOnClickOutside } from '@/hooks/useOnClickOutside';

interface UseDropdown<ContainerElement> {
  isOpen: boolean;
  close: () => void;
  open: () => void;
  handleOpenClose: (value: boolean) => void;
  containerRef: RefObject<ContainerElement>;
}

export const useDropdown = <ContainerElement extends HTMLElement>(config?: {
  defaultOpen?: boolean;
}): UseDropdown<ContainerElement> => {
  const { defaultOpen } = config ?? { defaultOpen: false };
  const [isOpen, setIsOpen] = useState(!!defaultOpen);
  const ref = useRef<ContainerElement>(null);
  const close = useCallback(() => setIsOpen(false), []);
  const open = useCallback(() => setIsOpen(true), []);
  const handleOpenClose = useCallback((value: boolean) => setIsOpen(value), []);

  useOnClickOutside(isOpen, close, ref);
  useOnEscapePress(close);

  return {
    isOpen,
    close,
    open,
    handleOpenClose,
    containerRef: ref,
  };
};
