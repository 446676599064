import { css, FlattenSimpleInterpolation } from 'styled-components';

import { cvar } from '@/styles/GlobalStyle';
import { fontWeight } from '@/components/Typography/v2/font-weight';

export const title1 = css`
  font-size: ${cvar('v2-font-size-1')};
  line-height: ${cvar('v2-line-height-1')};
  ${fontWeight('bold')}
  letter-spacing: -0.2px;
`;

export const title2 = css`
  font-size: ${cvar('v2-font-size-2')};
  line-height: ${cvar('v2-line-height-2')};
  ${fontWeight('bold')}
  letter-spacing: -0.15px;
`;

export const title3 = css`
  font-size: ${cvar('v2-font-size-3')};
  line-height: ${cvar('v2-line-height-4')};
  ${fontWeight('bold')}
  letter-spacing: -0.1px;
`;

export const title4 = css`
  font-size: ${cvar('v2-font-size-5')};
  line-height: ${cvar('v2-line-height-4')};
  ${fontWeight('bold')}
`;

const titles = {
  'title-1': title1,
  'title-2': title2,
  'title-3': title3,
  'title-4': title4,
} as const;

export type TitleType = keyof typeof titles;

export const title = (type: TitleType): FlattenSimpleInterpolation => {
  const titleMixin = titles[type] || title1;
  return titleMixin;
};

export const headline = css`
  font-size: ${cvar('v2-font-size-3')};
  line-height: ${cvar('v2-line-height-3')};
`;

export const subHeadline = css`
  font-size: ${cvar('v2-font-size-4')};
  line-height: ${cvar('v2-line-height-4')};
`;

export const body1 = css`
  font-size: ${cvar('v2-font-size-4')};
  line-height: ${cvar('v2-line-height-3')};
`;

export const body2 = css`
  font-size: ${cvar('v2-font-size-6')};
  line-height: ${cvar('v2-line-height-4')};
`;

export const body3 = css`
  font-size: ${cvar('v2-font-size-7')};
  line-height: ${cvar('v2-line-height-5')};
`;

export const body4 = css`
  font-size: ${cvar('v2-font-size-7')};
  line-height: ${cvar('v2-line-height-5')};
`;

export const overline = css`
  font-size: ${cvar('v2-font-size-8')};
  line-height: ${cvar('v2-line-height-6')};
  ${fontWeight('extra-bold')}
  text-transform: uppercase;
`;

export const caption = css`
  font-size: ${cvar('v2-font-size-8')};
  line-height: ${cvar('v2-line-height-6')};
`;

export const icon0 = css`
  font-size: ${cvar('v2-font-size-10')};
  line-height: ${cvar('v2-line-height-8')};
`;

export const icon1 = css`
  font-size: ${cvar('v2-font-size-5')};
  line-height: ${cvar('v2-line-height-6')};
`;

export const icon2 = css`
  font-size: ${cvar('v2-font-size-8')};
  line-height: ${cvar('v2-line-height-7')};
`;

export const icon3 = css`
  font-size: ${cvar('v2-font-size-9')};
  line-height: ${cvar('v2-line-height-7')};
  ${fontWeight('bold')}
`;

export const fontStyles = {
  title1,
  title2,
  title3,
  title4,
  headline,
  subHeadline,
  body1,
  body2,
  body3,
  body4,
  caption,
  overline,
  icon1,
  icon2,
} as const;
