import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 44px 0;
`;

export const Heading = styled.h3`
  ${(p) => p.theme.typography.title3};
  color: ${(p) => p.theme.colors.neutral.onBackground};
  margin-bottom: 8px;
`;

export const Subheading = styled.span`
  ${(p) => p.theme.typography.body3};
  color: ${(p) => p.theme.colors.neutral.onBackground};
`;
