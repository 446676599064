import { FILE_FORMAT } from '@/component/FileUpload/constants';
import { DownloadableResource } from '@/administration/pages/Course/practicalAssessments/blocks/document/components/DownloadableResource';
import { PDFStyled, PowerPointStyled } from '../Resource/styles';

export const Preview = ({
  fileExtension,
  url,
  fileName,
  isInteractive,
}: {
  fileExtension: string;
  fileName: string;
  url: string;
  isInteractive: boolean;
}): JSX.Element => {
  switch (fileExtension) {
    case FILE_FORMAT.PDF:
      return <PDFStyled data={url} type="application/pdf" isInteractive={isInteractive} />;
    case FILE_FORMAT.PPTX:
      return <PowerPointStyled src={`https://view.officeapps.live.com/op/embed.aspx?src=${url}`} isInteractive={isInteractive} />;
    default:
      return <DownloadableResource downloadUrl={url} fileExtension={fileExtension} fileName={fileName} bordered />;
  }
};
