import styled, { css } from 'styled-components';

export const RootStyled = styled.div<{ bordered?: boolean }>`
  display: flex;
  padding: 16px;
  align-items: center;
  width: 100%;

  justify-content: space-between;
  & svg {
    width: 16px;
    height: 16px;
  }

  ${({ bordered, theme }) =>
    bordered &&
    css`
      border-radius: 12px;
      border: 1px solid ${theme.colors.neutral.outline1};
    `}
`;

export const MetaWrapStyled = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const FormatInformerStyled = styled.div`
  background: ${({ theme }) => theme.colors.secondary.container};
  padding: 18px 14px;
  border-radius: 8px;
  ${({ theme }) => theme.typography.overline};
  color: ${({ theme }) => theme.colors.tertiary.onContainer};
`;

export const HeadingWrapStyled = styled.div`
  display: flex;
  gap: 4px;
`;

export const TitleStyled = styled.div`
  ${({ theme }) => theme.typography.title4};
  color: ${({ theme }) => theme.colors.neutral.onBackground};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
`;

export const ExtensionStyled = styled.div`
  ${({ theme }) => theme.typography.title4};
  color: ${({ theme }) => theme.tones.neutral[60]};
  text-transform: lowercase;
`;

export const PowerPointFrameStyled = styled.iframe.attrs({ frameBorder: '0' })`
  height: 500px;
  width: 100%;
`;
