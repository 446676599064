import { Realm } from '@/types/realm';
import { getRealm } from '@/utils/realms';

const STATIC_DIR = '/react-static';

const links: Record<Realm, string> = {
  kompetens: `${STATIC_DIR}/onboarding/Information - Intyga din tid.pdf`,
};

export const useExtraTermsLink = (): string | undefined => links[getRealm()];
