import { useEffect, useState } from 'react';

import { XAPISession } from './useXAPISession';
import { xapiClient } from '@/store/xapi/client';

export enum StateId {
  LAST_ACTIVITY_SEEN = 'collegial.com:last-activity-seen',
  LAST_PAGE_SEEN = 'collegial.com:last-page-seen',
  REACTION = 'collegial.com:reaction',
  QUESTION_ANSWER = 'collegial.com:question-answer',
  COMPLETED_PAGES = 'collegial.com:completed-pages',
  COMPLETED_ACTIVITIES = 'collegial.com:completed-activities',
  OPENED_ACTIVITIES = 'collegial.com:opened-activities',
  LAST_COURSE_PROGRESS = 'collegial.com:last-course-progress',
}

export type XAPIStateHookResult<T> = {
  data: T | undefined;
  isInitialized: boolean;
  setState: (nextState: T) => Promise<void>;
  loading: boolean;
  error?: Error;
};

export const useXAPIState = <T>(stateId: StateId | string, session?: XAPISession, initialState?: T): XAPIStateHookResult<T> => {
  const [currentState, setCurrentState] = useState<T | undefined>(initialState);
  const [isInitialized, setIsInitialized] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();

  // first state initialization
  useEffect(() => {
    if (!session || isInitialized) return;

    setLoading(true);

    xapiClient
      .getState<T>(session, stateId)
      .then((state) => {
        if (state) setCurrentState(state);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
        setIsInitialized(true);
      });
  }, [session, isInitialized]);

  const setState = async (nextState: T) => {
    if (loading || !isInitialized || !session) return;

    setLoading(true);
    const oldState = currentState;

    setCurrentState(nextState);

    return await xapiClient
      .setState(session, stateId, nextState)
      .catch((err) => {
        setError(err);
        setCurrentState(oldState);
      })
      .finally(() => setLoading(false));
  };

  return {
    data: currentState,
    isInitialized: !!session && isInitialized,
    setState,
    loading,
    error,
  };
};
