export const attributeGroups = [
  {
    name: 'Learning framework',
    isFilterOption: true, // FIXME: Temporary solution to display only allowed options to use in filter
    rows: [
      [
        {
          attribute: 'L&D Strategic Area Model - Area',
          isFilterOption: false,
          values: [
            'Innovation & Digitalization',
            'Leadership & talent development',
            'Professional training',
            'Team & culture development',
          ],
        },
        {
          attribute: 'Cognitive Levels',
          isFilterOption: true,
          values: ['Awareness', 'Understanding', 'Insight', 'Behaviour'],
        },
      ],
    ],
  },
  {
    name: 'School term & year',
    isFilterOption: false,
    rows: [
      [
        {
          attribute: 'School Term',
          isFilterOption: false,
          values: ['Autumn', 'School Start', 'Spring', 'Summer School'],
        },
        {
          attribute: 'School Year',
          isFilterOption: false,
          values: ['2018', '2019', '2020', '2021', '2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029','2030'],
        },
        {
          attribute: 'Yearly Competence Update',
          isFilterOption: false,
          values: [
            '2005',
            '2006',
            '2007',
            '2008',
            '2009',
            '2010',
            '2011',
            '2012',
            '2013',
            '2014',
            '2015',
            '2016',
            '2017',
            '2018',
            '2019',
            '2020',
            '2021',
            '2022',
            '2023',
            '2024',
            '2025',
          ],
        },
      ],
    ],
  },
  {
    name: 'Strategies & Culture',
    isFilterOption: true,
    rows: [
      [
        {
          attribute: 'Strategic People Capabilities',
          isFilterOption: false,
          values: ['Continuous learning', 'Right people', 'Transformative leadership', 'Ways of working'],
        },
        {
          attribute: 'WFP Critical Competencies',
          isFilterOption: true,
          deactivated: [
            'Agile WoW & Service design',
            'Change management',
            'Credit skills',
            'Customer understanding',
            'Digital awareness',
            'End-to-end processing',
            'Leadership skills',
            'Strategic thinking',
            'Sustainability',
            'Technology skills',
            'Advisory skills & Business acumen',
            'Digital Sales & Remote Advisory',
            'Agile WoW & E2E processing',
            'Business Development & Partnership Management',
            'Credit competence',
            'Dev Sec Ops',
            'Flexible & Hybrid WoW',
            'Innovation Skills',
            'Sector & Product knowledge',
            'Technology skills & Digital adoption',
          ],
          values: [
            'Cloud',
            'Data management & Data analytics',
            'Risk & Regulatory skills',
            'Sustainability/Sustainable banking',
            'Transformative leadership & Change management',
            'AI',
            'Digital Literacy & Tech Proficiency',
            'Cybersecurity',
            'Process/ Project Management',
            'Business Acumen',
            'Sales (Remote & Physical)',
            'Collaboration',
            'Communication',
            'AML/Financial Crime sector knowledge',
            'Inclusion & Diversity'
          ],
        },
        {
          attribute: 'SEB Behaviors',
          isFilterOption: false,
          values: ['Create value', 'Act long-term', 'Build positive relationships'],
        },
        {
          attribute: 'Service Style',
          isFilterOption: false,
          values: ['Education', 'Process', 'Motivation', 'Guidance', 'Inspiration', 'Innovation'],
        },
        {
          attribute: 'Keywords',
          isFilterOption: false,
          values: [
            'Agility',
            'Cross collaboration',
            'Engagement',
            'Feedback culture',
            'Inclusion & diversity',
            'Innovation',
            'Speed',
            'Sustainability',
            'Teamwork',
            'Transformation',
          ],
        },
      ],
    ],
  },
  {
    name: 'Leadership',
    isFilterOption: false,
    rows: [
      [
        {
          attribute: 'Transformative Leadership',
          isFilterOption: false,
          values: [
            'Be curious – Listen emphatically',
            'Speak your mind – Create psychological safety',
            'Co-create clarity – Set strategic frames',
            'Release potential – Share power',
            'Smarter together – Learn continuously',
            'Energise – Inspire by a bigger purpose',
          ],
        },
        {
          attribute: 'Situations',
          isFilterOption: false,
          values: [
            'New team/new team member',
            'Remote leadership',
            'Change',
            'Feedback',
            'Coach',
            'Remuneration review',
            'Remuneration dialogue',
            'Competence planning',
            'Employee engagement survey',
            'Performance evaluation',
            'Performance development dialogue',
            'Perspective taking',
            'Business planning',
            'Financial planning',
            'Recruitment',
            'Offer support',
          ],
        },
      ],
      [
        {
          attribute: 'SEB Theory Principles',
          isFilterOption: false,
          values: ['Change handbook', 'Cross collaboration handbook', 'Team development handbook'],
        },
        {
          attribute: 'SEB Theories',
          isFilterOption: false,
          values: [
            'An iterative approach',
            'Appreciative inquiry',
            'Building a high performing team',
            'Change matrix',
            'Developing cross-functional relationships',
            'Formula of change',
            'Four interacting levels of effective teams',
            'Four rooms of change',
            'Guidelines for effective meetings',
            'High performing teams',
            'Integrated model of group development',
            'Interest-based problem solving and collaboration',
            'Openness and trust',
            'Road to commitment',
            'SEB change model',
            'Structure and people',
            'The power of shared intentions',
            'The Stacey matrix',
            'Three generic development needs',
          ],
        },
        {
          attribute: 'SEB Theory Tools',
          isFilterOption: false,
          values: [
            'Achievements and positive effects',
            'Challenge timeline',
            'Change matrix',
            'Change Q&A',
            'Clarified expectation',
            'Collaboration mirror',
            'Collecting achievements',
            'Fishbowl',
            'Force field analysis',
            'Four rooms of change',
            'Ground rules in our team',
            'Improving our ways of working',
            'Individual development',
            'Interest and options',
            'Mapping the challenge',
            'Messenger relay station',
            'Our common agenda',
            'Our common SEB history',
            'Our shared intention',
            'Our team history',
            'Our team mission',
            'Present state in three dimensions',
            'Present state interviews',
            'Progression line',
            'Reflecting team',
            'Retrospective',
            'Road to commitment',
            'Roundtables',
            'SEB change model tools map',
            'SEB timeline',
            'Self-presentations',
            'Stakeholder analysis',
            'Team tasks',
            'World Cafe',
            'Your contribution to the team',
          ],
        },
      ],
    ],
  },
];