/* eslint-disable import/no-unused-modules */
import React from 'react';
import { useTranslation } from 'react-i18next';

import { FilterSelect, Select } from '@/ui/Select';
import { formatMMMD } from '@/utils/dates';

export type Week = {
  startsAt: string;
  endsAt: string;
};

type WeekFilterProps = {
  weeks: Week[];
  selectedWeek?: Week;
  onChange: (week?: Week) => void;
  onTrailingIconClick?: () => void;
};
export const WeekFilter = ({ weeks, selectedWeek, onTrailingIconClick, onChange }: WeekFilterProps): JSX.Element => {
  const { t } = useTranslation('managerDashboard');

  const weeksRanges: {
    range: Week;
    displayValue: string;
  }[] = weeks.map(({ startsAt, endsAt }, weekIndex, { length: totalWeeks }) => ({
    range: {
      startsAt,
      endsAt,
    },
    displayValue: `${weekIndex + 1}/${totalWeeks} · ${formatMMMD(new Date(startsAt))} - ${formatMMMD(
      new Date(endsAt)
    )}, ${new Date(endsAt).getFullYear()}`,
  }));

  const selectedWeekRangeDisplay =
    selectedWeek &&
    weeksRanges.find(({ range: { startsAt, endsAt } }) => selectedWeek.startsAt === startsAt && selectedWeek.endsAt === endsAt)
      ?.displayValue;

  return (
    <FilterSelect
      placeholder={t('Week')}
      value={selectedWeekRangeDisplay || ''}
      onChange={(value) => onChange(weeksRanges.find((range) => range.displayValue === value)?.range)}
      onTrailingIconClick={onTrailingIconClick}
      variant={'dropdown'}
    >
      <Select.List>
        {weeksRanges.map(({ displayValue }) => (
          <Select.Option value={displayValue} key={displayValue}>
            {displayValue}
          </Select.Option>
        ))}
      </Select.List>
    </FilterSelect>
  );
};
