import React from 'react';
import styled from 'styled-components';

import { CourseDrawerAddActivityButton } from '@/features/course/admin/atoms/CourseDrawerAddActivityButton';
import { makeNamespacedComponent } from '@/ui/utils';
import { Skeleton } from '@/ui/Skeleton';
import { PageVariant } from '@/features/course/admin/types/page';
import { EditModularLayoutVariant } from '@/features/course/admin/templates/CourseLayout';
import { DrawerTreeItems, SetDrawerTreeItemsHandler } from '@/features/course/admin/types/treeDrawer';
import { CourseTreeDrawer } from '@/features/course/admin/organisms/CourseTreeDrawer';

const RootStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: calc(100vh - 92px);
`;

const ActionsStyled = styled.div`
  display: flex;
  justify-content: flex-end;
`;

type CourseDrawerProps = {
  drawerTreeItems: DrawerTreeItems;
  setDrawerTreeItems: SetDrawerTreeItemsHandler;
  creationOptionsVariant: EditModularLayoutVariant;
  onAddClick: (type: PageVariant) => void;
  onRemoveItem: (itemId: string) => void;
  onRenameItem: (id: string, newTitle: string) => void;
  onAddItem: (type: PageVariant, parentActivityId?: string) => void;
  onActivateItem: (id: string) => void;
  onItemCollapsedChanged: (ops: { activityId: string; collapsed: boolean }) => void;
};

const _CourseDrawer = ({
  drawerTreeItems,
  setDrawerTreeItems,
  onAddClick,
  onRemoveItem,
  onRenameItem,
  onActivateItem,
  onAddItem,
  onItemCollapsedChanged,
}: CourseDrawerProps): JSX.Element => {
  return (
    <RootStyled>
      <ActionsStyled>
        <CourseDrawerAddActivityButton onAddClick={onAddClick} />
      </ActionsStyled>
      <CourseTreeDrawer
        items={drawerTreeItems}
        setItems={setDrawerTreeItems}
        onActivateItem={onActivateItem}
        onAddItem={onAddItem}
        onRemoveItem={onRemoveItem}
        onRenameItem={onRenameItem}
        onItemCollapsedChanged={onItemCollapsedChanged}
      />
    </RootStyled>
  );
};

const LoadingState = (): JSX.Element => (
  <RootStyled>
    <ActionsStyled>
      <Skeleton width={46} height={36} />
    </ActionsStyled>
    <Skeleton width={312} height={36} />
  </RootStyled>
);

export const CourseDrawer = makeNamespacedComponent(_CourseDrawer, {
  LoadingState,
});
