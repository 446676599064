import styled from 'styled-components';
import { ReactNode } from 'react';

import { UnderlinedTabs } from '@/ui/Tabs';
import { NothingToReviewBanner, SubmitFirstBanner } from '../banners';
import { PeersToReview } from '../reviews/PeersToReview';
import { RUserLearningSubmissionReviewAssignment } from '@/store/v2';

export const TabContentWrapStyled = styled.div`
  margin-top: 40px;
`;

export const TabsWrapperStyled = styled.div`
  width: 100%;
`;

export enum AssessmentTab {
  MySubmission,
  PeersToReview,
}

export const AssessmentViewerTabs = ({
  submissionFormSection,
  submitted,
  assessmentsToReview,
  pageRemoteId,
  type,
  activeTab,
  onActiveTabChange,
  spaceId,
}: {
  submissionFormSection: ReactNode;
  submitted: boolean;
  assessmentsToReview: RUserLearningSubmissionReviewAssignment[];
  pageRemoteId?: number;
  type: 'text' | 'document';
  onActiveTabChange: (tabValue: number) => void;
  activeTab: AssessmentTab;
  spaceId: number;
}): JSX.Element => {
  return (
    <TabsWrapperStyled>
      <UnderlinedTabs activeTab={activeTab} onActiveTabChange={onActiveTabChange}>
        <UnderlinedTabs.TriggersList>
          <UnderlinedTabs.Trigger label="My assessment" value={AssessmentTab.MySubmission} />
          <UnderlinedTabs.Trigger label="Peers to review" value={AssessmentTab.PeersToReview} />
        </UnderlinedTabs.TriggersList>
        <UnderlinedTabs.ContentsList>
          <UnderlinedTabs.Content value={AssessmentTab.MySubmission}>
            <TabContentWrapStyled>{submissionFormSection}</TabContentWrapStyled>
          </UnderlinedTabs.Content>
          <UnderlinedTabs.Content value={AssessmentTab.PeersToReview}>
            <TabContentWrapStyled>
              {!submitted && (
                <SubmitFirstBanner onViewSubmissionFormClick={() => onActiveTabChange(AssessmentTab.MySubmission)} />
              )}
              {submitted &&
                (assessmentsToReview.length ? (
                  <PeersToReview
                    assessmentsToReview={assessmentsToReview}
                    pageRemoteId={pageRemoteId}
                    type={type}
                    spaceId={spaceId}
                  />
                ) : (
                  <NothingToReviewBanner />
                ))}
            </TabContentWrapStyled>
          </UnderlinedTabs.Content>
        </UnderlinedTabs.ContentsList>
      </UnderlinedTabs>
    </TabsWrapperStyled>
  );
};
