import { gql, useQuery, ApolloQueryResult } from '@apollo/client';

export interface RealmConfig {
  languageDefault?: string;
  heroImageUrl?: string;
  heroVideoUrl?: string;
}

export const realmConfigQuery = gql`
  query realmConfig($realm: String!) {
    realmConfig(realm: $realm) {
      heroImageUrl
      heroVideoUrl
    }
  }
`;

export interface Realm {
  realm: string;
  name: string;
}

const realmsQuery = gql`
  query getRealms {
    realms {
      realm
      name
    }
  }
`;

export const useRealms = (
  skip = false
): {
  realms: Realm[];
  loading: boolean;
} => {
  const { data, loading } = useQuery<{ realms: Realm[] }>(realmsQuery, { skip });

  const realms: Realm[] = data?.realms || [];

  return {
    realms,
    loading,
  };
};

export const useRealmConfig = (
  realm: string = window.realm
): {
  realmConfig?: RealmConfig;
  loading: boolean;
  refetch: (params?: { realm: string }) => Promise<ApolloQueryResult<{ realmConfig: RealmConfig }>>;
} => {
  const { data, loading, refetch } = useQuery<{ realmConfig: RealmConfig }, { realm: string }>(realmConfigQuery, {
    variables: { realm },
  });

  const realmConfig: RealmConfig | undefined = data?.realmConfig;

  return {
    realmConfig,
    loading,
    refetch,
  };
};
