import React, { ReactNode } from 'react';

import { makeNamespacedComponent } from '@/ui/utils';
import { EditDrawer, EditDrawerItemProps, Layout, EditNavbar, PageBody } from '../../atoms';
import { PageVariantDeprecated as PageVariant } from '@/features/course/admin/types/page';
import { RLearningType } from '@/store/v2';

export type EditModularLayoutVariant = RLearningType.PracticalAssessment | RLearningType.Course;

type EditLayoutProps = {
  title: string;
  onBackClick: () => void;
  onSettingsClick: () => void;
  onSaveClick: () => void;
  onAddItemClick: (type: PageVariant) => void;
  viewHref?: string;
  content: ReactNode;
  drawerItems: EditDrawerItemProps[];
  onReorderPages: (options: { sourceIndex: number; destinationIndex: number }) => void;
  layoutVariant: EditModularLayoutVariant;
};
export const _EditLayout = ({
  title,
  onBackClick,
  viewHref,
  onSaveClick,
  onSettingsClick,
  onAddItemClick,
  onReorderPages,
  content,
  drawerItems,
  layoutVariant,
}: EditLayoutProps): JSX.Element => {
  return (
    <Layout
      navbar={
        <EditNavbar
          title={title}
          onBackClick={onBackClick}
          viewHref={viewHref}
          onSettingsClick={onSettingsClick}
          onSaveClick={onSaveClick}
        />
      }
      content={<PageBody>{content}</PageBody>}
      drawer={
        <EditDrawer
          onAddClick={onAddItemClick}
          items={drawerItems}
          onReorder={onReorderPages}
          creationOptionsVariant={layoutVariant}
        />
      }
      isDrawerOpen
    />
  );
};

const LoadingState = (): JSX.Element => <Layout.LoadingState />;

export const EditLayout = makeNamespacedComponent(_EditLayout, {
  LoadingState,
});
