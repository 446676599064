import { Modal } from '@/ui/Modal';
import { HelpItem, HelpWrapper, Icon, IconBox } from './styles';

type HelpModalProps = {
  open: boolean;
  onClose: () => void;
};

export const HelpModal = ({ open, onClose }: HelpModalProps): JSX.Element => (
  <Modal open={open} onClose={onClose} size="mobileLandscape">
    <Modal.Contents>
      <HelpWrapper>
        <HelpItem>
          <IconBox>
            <Icon icon="calendar" fixedWidth />
          </IconBox>
          <p>A new learning week starts every Monday and ends on Sunday</p>
        </HelpItem>
        <HelpItem>
          <IconBox>
            <Icon icon={['far', 'hourglass']} fixedWidth />
          </IconBox>
          <p>
            Learning time is based on progress and completion of all learning types from all partners, including added learning
            time
          </p>
        </HelpItem>
        <HelpItem>
          <IconBox>
            <Icon icon="loader" fixedWidth />
          </IconBox>
          <p>Time updates can be delayed up to 72 hours due to technical dependencies</p>
        </HelpItem>
        <HelpItem>
          <IconBox>
            <Icon icon="list-check" fixedWidth />
          </IconBox>
          <p>Review and reflect at the end of each week </p>
        </HelpItem>
      </HelpWrapper>
    </Modal.Contents>
    <Modal.Actions>
      <Modal.Action action={onClose}>Close</Modal.Action>
    </Modal.Actions>
  </Modal>
);
