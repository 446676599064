import styled from 'styled-components';

import { Button } from '@/ui/Button';
import { Icon } from '@/ui/Icon';
import { Select } from '@/ui/Select';
import { PageVariantDeprecated as PageVariant } from '@/features/course/admin/types/page';

const SelectListStyled = styled(Select.List)`
  width: 304px;
`;

const SelectOptionStyled = styled(Select.Option)`
  padding: 16px;

  & div {
    max-height: 72px;
  }
`;

const OptionTitleStyled = styled.div`
  ${({ theme }) => theme.typography.body3};
  color: ${({ theme }) => theme.colors.neutral.onBackground};
`;

const OptionCaptionStyled = styled.div`
  ${({ theme }) => theme.typography.caption};
  color: ${({ theme }) => theme.colors.neutral.outline2};
`;

export const AddAssessmentPageButton = ({ onAddClick }: { onAddClick: (type: PageVariant) => void }): JSX.Element => {
  return (
    <Select
      value=""
      onChange={(type) => {
        onAddClick(type as PageVariant);
      }}
    >
      <Select.Header>
        {({ toggleShowOptions }) => (
          <Button variant="neutral" onClick={toggleShowOptions}>
            <Icon icon="plus" size="lg" />
          </Button>
        )}
      </Select.Header>
      <SelectListStyled>
        <SelectOptionStyled value={PageVariant.PRACTICAL_ASSESSMENT_TEXT} prefix={<Icon icon={['far', 'pen-line']} />}>
          <div>
            <OptionTitleStyled>Free text question</OptionTitleStyled>
            <OptionCaptionStyled>Participant writes free text to complete</OptionCaptionStyled>
          </div>
        </SelectOptionStyled>
        <SelectOptionStyled value={PageVariant.PRACTICAL_ASSESSMENT_DOCUMENT} prefix={<Icon icon={['far', 'file-upload']} />}>
          <div>
            <OptionTitleStyled>Document upload</OptionTitleStyled>
            <OptionCaptionStyled>Participant uploads document to complete</OptionCaptionStyled>
          </div>
        </SelectOptionStyled>
      </SelectListStyled>
    </Select>
  );
};
