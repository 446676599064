import { ColumnDeclaration } from '@/ui/AutoTable';
import { LearningItemType } from '@/features/journey/admin/hooks/useLazyLearningItems';
import { JourneyInsightsTabsLearningItemsTypeFormatter } from '@/features/journey/admin/atoms/JourneyInsightsTabsLearningItemsTypeFormatter';
import { JourneyInsightsLearningItemTitleCell } from '@/features/journey/admin/atoms/JourneyInsightsLearningItemTitleCell';

export type SortBy = 'title' | 'type' | 'not_started_count' | 'in_progress_count' | 'completed_count' | 'completion_rate';

export const useLearningItemsColumnsDeclaration = (): ColumnDeclaration<LearningItemType, SortBy> => {
  return [
    {
      header: 'title',
      component: JourneyInsightsLearningItemTitleCell,
      sortKey: 'title',
    },
    {
      header: 'learning type',
      component: JourneyInsightsTabsLearningItemsTypeFormatter,
      sortKey: 'type',
    },
    {
      header: 'not started',
      dataKey: 'not_started_count',
      sortKey: 'not_started_count',
      cellFormatter: 'notNullableString',
      align: 'right',
    },
    {
      header: 'in progress',
      dataKey: 'in_progress_count',
      sortKey: 'in_progress_count',
      cellFormatter: 'notNullableString',
      align: 'right',
    },
    {
      header: 'completed',
      dataKey: 'completed_count',
      sortKey: 'completed_count',
      cellFormatter: 'notNullableString',
      align: 'right',
    },
    {
      header: 'completion rate',
      dataKey: 'completion_rate',
      sortKey: 'completion_rate',
      cellFormatter: 'progress',
      align: 'right',
    },
  ];
};
