import styled, { css } from 'styled-components';

import { AvatarSize } from '.';

export const RootStyled = styled.div<{ size: AvatarSize; outlined?: boolean; image?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.typography.caption};
  color: ${({ theme }) => theme.tones.neutral[0]};
  background: ${({ theme }) => theme.colors.neutral.container};
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  ${({ size }) => {
    switch (size) {
      case 'small':
        return css`
          width: 24px;
          height: 24px;
        `;

      case 'medium-small':
        return css`
          width: 32px;
          height: 32px;
        `;

      case 'medium':
      default:
        return css`
          width: 40px;
          height: 40px;
        `;
    }
  }}

  ${({ outlined, theme }) =>
    outlined &&
    css`
      border: 1px solid ${theme.colors.neutral.background};
    `};

  ${({ image }) =>
    image &&
    css`
      background-image: url(${image});
      background-size: cover;
    `};
  overflow: hidden;
`;

export const AvatarsGroup = styled.div`
  display: inline-flex;

  & > :not(:first-child) {
    margin-left: -4px;
  }
`;
