/** NOTE: This file has been autogenerated. Do not edit this file.
 *
 * To update this file, perform following steps:
 *  1. ensure that your local cls-api and fapi are working
 *  2. run `npm run generate` in scripts directory.
 */

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | undefined;
export type InputMaybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateTime: { input: Date; output: Date };
  JSON: { input: any; output: any };
  Second: { input: any; output: any };
  Time: { input: Date; output: Date };
  URL: { input: any; output: any };
  URN: { input: string; output: string };
  URNV2: { input: any; output: any };
  UUID: { input: any; output: any };
  Upload: { input: any; output: any };
  UserID: { input: any; output: any };
  bigint: { input: any; output: any };
  catalog_item_type: { input: any; output: any };
  float8: { input: any; output: any };
  json: { input: any; output: any };
  jsonb: { input: any; output: any };
  learning_journey_completion_criteria: { input: any; output: any };
  learning_journey_status: { input: any; output: any };
  learning_status: { input: any; output: any };
  learning_type: { input: any; output: any };
  learning_visibility: { input: any; output: any };
  metrics_dimension: { input: any; output: any };
  numeric: { input: any; output: any };
  reaction_value: { input: any; output: any };
  timestamptz: { input: any; output: any };
};

export enum RAccountStatus {
  Deactivated = 'deactivated',
  Incomplete = 'incomplete',
  Pending = 'pending',
  Registered = 'registered',
}

export type RAddAssignmentUserSurveyResult = {
  __typename?: 'AddAssignmentUserSurveyResult';
  success: Scalars['Boolean']['output'];
};

export type RAddSelfTrackedLearningToLearningLoopInput = {
  description: Scalars['String']['input'];
  effort: Scalars['Int']['input'];
  learningLoopId: Scalars['String']['input'];
  link: Scalars['String']['input'];
  title: Scalars['String']['input'];
  type: RSelfTrackedLearningType;
  userId: Scalars['Int']['input'];
};

export type RAddSelfTrackedLearningToLearningLoopResult = {
  __typename?: 'AddSelfTrackedLearningToLearningLoopResult';
  learningLoop: RLearningLoop;
  learningSelfTracked: RSelfTrackedLearning;
  success: Scalars['Boolean']['output'];
};

export type RAggregation = {
  __typename?: 'Aggregation';
  data?: Maybe<Scalars['JSON']['output']>;
};

export type RAggregationNameInput = {
  name: Scalars['String']['input'];
};

export type RAnalyticsProgress = {
  __typename?: 'AnalyticsProgress';
  is_completed?: Maybe<Scalars['Boolean']['output']>;
  learning_id?: Maybe<Scalars['Int']['output']>;
};

export enum RAnalyticsUserSortField {
  Completed = 'COMPLETED',
  CompletedNotExpired = 'COMPLETED_NOT_EXPIRED',
  Effort = 'EFFORT',
  Started = 'STARTED',
}

export type RAnalyticsUserSortInput = {
  field: RAnalyticsUserSortField;
  order?: RSortOrder;
};

export type RArticleRedirect = {
  __typename?: 'ArticleRedirect';
  learningId?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type RAssignmentAssignmentsV3UsersFilter = {
  searchPhrase?: InputMaybe<Scalars['String']['input']>;
  statusIN?: InputMaybe<Array<RAssignmentUserCompletionStatus>>;
  userIdIN?: InputMaybe<Array<Scalars['Int']['input']>>;
  userTeamIdIN?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type RAssignmentStandardV3 = RAssignmentV3 & {
  __typename?: 'AssignmentStandardV3';
  automated: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  learnings: RAssignmentV3LearningPage;
  name: Scalars['String']['output'];
  progress: RAssignmentV3Progress;
  rules?: Maybe<RAssignmentsV3Rule>;
  spaceId: Scalars['Int']['output'];
  status?: Maybe<RAssignmentV3Status>;
  surveyId?: Maybe<Scalars['String']['output']>;
  teaser: Scalars['String']['output'];
  templateId?: Maybe<Scalars['Int']['output']>;
  type: RAssignmentV3Type;
  updatedAt: Scalars['DateTime']['output'];
  users: RAssignmentV3UsersPage;
};

export type RAssignmentStandardV3LearningsArgs = {
  pagination?: InputMaybe<RPagination>;
};

export type RAssignmentStandardV3ProgressArgs = {
  filter: RAssignmentV3ProgressFilter;
};

export type RAssignmentStandardV3UsersArgs = {
  filter?: InputMaybe<RAssignmentAssignmentsV3UsersFilter>;
  pagination?: InputMaybe<RPagination>;
  sort?: InputMaybe<RAssignmentUsersV3Sort>;
};

export type RAssignmentStandardV3Learning = RAssignmentV3Learning & {
  __typename?: 'AssignmentStandardV3Learning';
  assignmentGroupId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  learningId?: Maybe<Scalars['Int']['output']>;
  learningPathId?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  type: RAssignmentStandardV3LearningType;
};

export type RAssignmentStandardV3LearningPage = RAssignmentV3LearningPage & {
  __typename?: 'AssignmentStandardV3LearningPage';
  data: Array<RAssignmentStandardV3Learning>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum RAssignmentStandardV3LearningType {
  Mandatory = 'MANDATORY',
  Suggested = 'SUGGESTED',
}

export type RAssignmentStandardV3User = RAssignmentV3User & {
  __typename?: 'AssignmentStandardV3User';
  agent: RAssignmentV3UserAgent;
  assignmentId: Scalars['Int']['output'];
  order?: Maybe<Scalars['Int']['output']>;
  progress: RAssignmentStandardV3UserProgress;
  user: RUserV2;
  userId: Scalars['Int']['output'];
};

export type RAssignmentStandardV3UserParticipation = {
  __typename?: 'AssignmentStandardV3UserParticipation';
  assignmentLearningId: Scalars['Int']['output'];
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  expiredAt?: Maybe<Scalars['DateTime']['output']>;
  participationId: Scalars['Int']['output'];
  progress: Scalars['Float']['output'];
};

export type RAssignmentStandardV3UserProgress = {
  __typename?: 'AssignmentStandardV3UserProgress';
  completed: Scalars['Boolean']['output'];
  estimatedEffort: Scalars['Float']['output'];
  participations: Array<RAssignmentStandardV3UserParticipation>;
  requiredItemsCompletedCount: Scalars['Float']['output'];
  requiredItemsCount: Scalars['Float']['output'];
};

export type RAssignmentStandardV3UsersPage = RAssignmentV3UsersPage & {
  __typename?: 'AssignmentStandardV3UsersPage';
  data: Array<RAssignmentStandardV3User>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type RAssignmentStrategicV3 = RAssignmentV3 & {
  __typename?: 'AssignmentStrategicV3';
  automated: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  endAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  learningLoopRecommendedEffort: Scalars['Int']['output'];
  learnings: RAssignmentV3LearningPage;
  name: Scalars['String']['output'];
  progress: RAssignmentStrategicV3Progress;
  rules?: Maybe<RAssignmentsV3Rule>;
  spaceId: Scalars['Int']['output'];
  startAt: Scalars['DateTime']['output'];
  status?: Maybe<RAssignmentV3Status>;
  surveyId?: Maybe<Scalars['String']['output']>;
  teaser: Scalars['String']['output'];
  templateId?: Maybe<Scalars['Int']['output']>;
  totalThresholdEffort: Scalars['Int']['output'];
  type: RAssignmentV3Type;
  updatedAt: Scalars['DateTime']['output'];
  users: RAssignmentV3UsersPage;
};

export type RAssignmentStrategicV3LearningsArgs = {
  pagination?: InputMaybe<RPagination>;
};

export type RAssignmentStrategicV3ProgressArgs = {
  filter: RAssignmentStrategicV3ProgressFilter;
};

export type RAssignmentStrategicV3UsersArgs = {
  filter?: InputMaybe<RAssignmentAssignmentsV3UsersFilter>;
  pagination?: InputMaybe<RPagination>;
  sort?: InputMaybe<RAssignmentUsersV3Sort>;
};

export type RAssignmentStrategicV3LearnersProgress = {
  __typename?: 'AssignmentStrategicV3LearnersProgress';
  activeCount: Scalars['Int']['output'];
  completedCount: Scalars['Int']['output'];
};

export type RAssignmentStrategicV3Learning = RAssignmentV3Learning & {
  __typename?: 'AssignmentStrategicV3Learning';
  assignmentGroupId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  learningId?: Maybe<Scalars['Int']['output']>;
  learningPathId?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
};

export type RAssignmentStrategicV3LearningPage = RAssignmentV3LearningPage & {
  __typename?: 'AssignmentStrategicV3LearningPage';
  data: Array<RAssignmentStrategicV3Learning>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type RAssignmentStrategicV3Progress = {
  __typename?: 'AssignmentStrategicV3Progress';
  estimatedEffort: Scalars['Float']['output'];
  learners: RAssignmentStrategicV3LearnersProgress;
  reflections: RAssignmentStrategicV3ReflectionsProgress;
};

export type RAssignmentStrategicV3ProgressFilter = {
  periodIN?: InputMaybe<Array<RPeriodFilter>>;
  statusIN?: InputMaybe<Array<RAssignmentUserCompletionStatus>>;
  userTeamIdIN?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type RAssignmentStrategicV3ReflectionsProgress = {
  __typename?: 'AssignmentStrategicV3ReflectionsProgress';
  completedCount: Scalars['Int']['output'];
  completedThreshold: Scalars['Int']['output'];
};

export type RAssignmentStrategicV3User = RAssignmentV3User & {
  __typename?: 'AssignmentStrategicV3User';
  agent: RAssignmentV3UserAgent;
  assignmentId: Scalars['Int']['output'];
  order?: Maybe<Scalars['Int']['output']>;
  progress: RAssignmentStrategicV3UserProgress;
  user: RUserV2;
  userId: Scalars['Int']['output'];
};

export type RAssignmentStrategicV3UserProgressArgs = {
  filter?: InputMaybe<RAssignmentsV3UserStrategicProgressFilter>;
};

export type RAssignmentStrategicV3UserProgress = {
  __typename?: 'AssignmentStrategicV3UserProgress';
  active: Scalars['Boolean']['output'];
  competencyAssessment: RAssignmentStrategicV3UserSurveyProgress;
  /** @deprecated Use status field */
  completed: Scalars['Boolean']['output'];
  estimatedEffort: Scalars['Float']['output'];
  estimatedEffortProgress: Scalars['Float']['output'];
  reflectionsCompleted: Scalars['Int']['output'];
  reflectionsLimit: Scalars['Int']['output'];
  userCompletionStatus: RAssignmentUserCompletionStatus;
  weeklyCompletion: Scalars['Boolean']['output'];
};

export type RAssignmentStrategicV3UserSurveyProgress = {
  __typename?: 'AssignmentStrategicV3UserSurveyProgress';
  canTake: Scalars['Boolean']['output'];
  isTakenAtEnd: Scalars['Boolean']['output'];
  isTakenAtStart: Scalars['Boolean']['output'];
};

export type RAssignmentStrategicV3UsersPage = RAssignmentV3UsersPage & {
  __typename?: 'AssignmentStrategicV3UsersPage';
  data: Array<RAssignmentStrategicV3User>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type RAssignmentTemplate = {
  __typename?: 'AssignmentTemplate';
  id: Scalars['Int']['output'];
  image: Scalars['String']['output'];
  name: Scalars['String']['output'];
  teaser: Scalars['String']['output'];
};

export enum RAssignmentUserCompletionStatus {
  Completed = 'COMPLETED',
  Expired = 'EXPIRED',
  NotCompleted = 'NOT_COMPLETED',
}

export type RAssignmentUserSurveyInput = {
  assignmentId: Scalars['Int']['input'];
  surveyId: Scalars['String']['input'];
  surveyResponseId: Scalars['String']['input'];
  userId: Scalars['Int']['input'];
};

export type RAssignmentUsersV3Sort = {
  field?: InputMaybe<RAssignmentUsersV3SortField>;
  order?: InputMaybe<RSortOrder>;
};

export enum RAssignmentUsersV3SortField {
  Id = 'ID',
  Name = 'NAME',
}

export type RAssignmentV3 = {
  automated: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  learnings: RAssignmentV3LearningPage;
  name: Scalars['String']['output'];
  rules?: Maybe<RAssignmentsV3Rule>;
  spaceId: Scalars['Int']['output'];
  status?: Maybe<RAssignmentV3Status>;
  surveyId?: Maybe<Scalars['String']['output']>;
  teaser: Scalars['String']['output'];
  templateId?: Maybe<Scalars['Int']['output']>;
  type: RAssignmentV3Type;
  updatedAt: Scalars['DateTime']['output'];
  users: RAssignmentV3UsersPage;
};

export type RAssignmentV3LearningsArgs = {
  pagination?: InputMaybe<RPagination>;
};

export type RAssignmentV3UsersArgs = {
  filter?: InputMaybe<RAssignmentAssignmentsV3UsersFilter>;
  pagination?: InputMaybe<RPagination>;
  sort?: InputMaybe<RAssignmentUsersV3Sort>;
};

export type RAssignmentV3Learning = {
  assignmentGroupId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  learningId?: Maybe<Scalars['Int']['output']>;
  learningPathId?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
};

export type RAssignmentV3LearningPage = {
  data: Array<RAssignmentV3Learning>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type RAssignmentV3Progress = {
  __typename?: 'AssignmentV3Progress';
  completedCount: Scalars['Int']['output'];
  completionRate: Scalars['Float']['output'];
};

export type RAssignmentV3ProgressFilter = {
  userTeamIdIN?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export enum RAssignmentV3Status {
  Published = 'PUBLISHED',
  Unpublished = 'UNPUBLISHED',
}

export enum RAssignmentV3Type {
  Standard = 'STANDARD',
  Strategic = 'STRATEGIC',
}

export type RAssignmentV3User = {
  agent: RAssignmentV3UserAgent;
  assignmentId: Scalars['Int']['output'];
  order?: Maybe<Scalars['Int']['output']>;
  user: RUserV2;
  userId: Scalars['Int']['output'];
};

export enum RAssignmentV3UserAgent {
  Manual = 'MANUAL',
  Rule = 'RULE',
}

export type RAssignmentV3UsersFilter = {
  assignmentGroupId: Scalars['Int']['input'];
  searchPhrase?: InputMaybe<Scalars['String']['input']>;
  statusIN?: InputMaybe<Array<RAssignmentUserCompletionStatus>>;
  userIdIN?: InputMaybe<Array<Scalars['Int']['input']>>;
  userTeamIdIN?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type RAssignmentV3UsersPage = {
  data: Array<RAssignmentV3User>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type RAssignmentsV3Filter = {
  idIN?: InputMaybe<Array<Scalars['Int']['input']>>;
  searchPhrase?: InputMaybe<Scalars['String']['input']>;
  spacedIdsIN?: InputMaybe<Array<Scalars['Int']['input']>>;
  status?: InputMaybe<RAssignmentV3Status>;
  type?: InputMaybe<RAssignmentV3Type>;
  userIdIN?: InputMaybe<Array<Scalars['Int']['input']>>;
  userTeamIdIN?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type RAssignmentsV3Page = {
  __typename?: 'AssignmentsV3Page';
  data: Array<RAssignmentV3>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type RAssignmentsV3Result = {
  __typename?: 'AssignmentsV3Result';
  assignment?: Maybe<RAssignmentV3>;
  success: Scalars['Boolean']['output'];
};

export type RAssignmentsV3Rule = {
  __typename?: 'AssignmentsV3Rule';
  expressions: Array<RAssignmentsV3RuleExpression>;
  operator: RAssignmentsV3RuleOperator;
};

export enum RAssignmentsV3RuleCompareOperator {
  Equal = 'EQUAL',
  GreaterOrEqual = 'GREATER_OR_EQUAL',
  NotEqual = 'NOT_EQUAL',
}

export type RAssignmentsV3RuleCondition = {
  __typename?: 'AssignmentsV3RuleCondition';
  column: RAssignmentsV3RuleField;
  compare: RAssignmentsV3RuleCompareOperator;
  selections?: Maybe<Array<Scalars['String']['output']>>;
  value?: Maybe<Scalars['String']['output']>;
};

export type RAssignmentsV3RuleConditionInput = {
  column: RAssignmentsV3RuleField;
  compare: RAssignmentsV3RuleCompareOperator;
  selections?: InputMaybe<Array<Scalars['String']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type RAssignmentsV3RuleExpression = {
  __typename?: 'AssignmentsV3RuleExpression';
  conditions: Array<RAssignmentsV3RuleCondition>;
  operator: RAssignmentsV3RuleOperator;
};

export type RAssignmentsV3RuleExpressionInput = {
  conditions: Array<RAssignmentsV3RuleConditionInput>;
  operator: RAssignmentsV3RuleOperator;
};

export enum RAssignmentsV3RuleField {
  BusinessArea = 'BUSINESS_AREA',
  BusinessUnit = 'BUSINESS_UNIT',
  CompanyId = 'COMPANY_ID',
  Country = 'COUNTRY',
  DepartmentId = 'DEPARTMENT_ID',
  DepartmentName = 'DEPARTMENT_NAME',
  DeptId = 'DEPT_ID',
  Division = 'DIVISION',
  Indicator = 'INDICATOR',
  JobCode = 'JOB_CODE',
  JobEntryDate = 'JOB_ENTRY_DATE',
  JobFamily = 'JOB_FAMILY',
  JobFunct = 'JOB_FUNCT',
  MgrEmail = 'MGR_EMAIL',
  OrgRelation = 'ORG_RELATION',
  PayStatus = 'PAY_STATUS',
  Product = 'PRODUCT',
  ProductArea = 'PRODUCT_AREA',
  StartDate = 'START_DATE',
  Subfunction = 'SUBFUNCTION',
  SupervisorLvl = 'SUPERVISOR_LVL',
}

export type RAssignmentsV3RuleInput = {
  expressions: Array<RAssignmentsV3RuleExpressionInput>;
  operator: RAssignmentsV3RuleOperator;
};

export enum RAssignmentsV3RuleOperator {
  And = 'AND',
  Or = 'OR',
}

export type RAssignmentsV3Sort = {
  field?: InputMaybe<RAssignmentsV3SortField>;
  order?: InputMaybe<RSortOrder>;
};

export enum RAssignmentsV3SortField {
  Id = 'ID',
  Name = 'NAME',
  TypeV2 = 'TYPE_V2',
}

export type RAssignmentsV3UserRuleField = {
  __typename?: 'AssignmentsV3UserRuleField';
  field: Scalars['String']['output'];
  values: Array<Maybe<Scalars['String']['output']>>;
};

export type RAssignmentsV3UserRuleFieldFilter = {
  rules?: InputMaybe<RAssignmentsV3RuleInput>;
  spaceId: Scalars['Int']['input'];
};

export type RAssignmentsV3UserRulePreview = {
  __typename?: 'AssignmentsV3UserRulePreview';
  users: Array<RAssignmentsV3UserRulePreviewUser>;
};

export type RAssignmentsV3UserRulePreviewFilter = {
  rules: RAssignmentsV3RuleInput;
  spaceId: Scalars['Int']['input'];
};

export type RAssignmentsV3UserRulePreviewUser = {
  __typename?: 'AssignmentsV3UserRulePreviewUser';
  email: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  profileImage?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type RAssignmentsV3UserStrategicProgressFilter = {
  periodIN?: InputMaybe<Array<RPeriodFilter>>;
};

export enum RAttendedType {
  Attended = 'ATTENDED',
  Enrolled = 'ENROLLED',
  NotAttended = 'NOT_ATTENDED',
  Waitlisted = 'WAITLISTED',
}

export type RBabelonNotification = {
  __typename?: 'BabelonNotification';
  id: Scalars['ID']['output'];
  notificationType?: Maybe<RBabelonNotificationType>;
  postId?: Maybe<Scalars['Int']['output']>;
  productId?: Maybe<Scalars['Int']['output']>;
  productTitle?: Maybe<Scalars['String']['output']>;
  replyId?: Maybe<Scalars['Int']['output']>;
  respondentName?: Maybe<Scalars['String']['output']>;
  sectionPath?: Maybe<Scalars['String']['output']>;
  sectionTitle?: Maybe<Scalars['String']['output']>;
  topLevelPostId?: Maybe<Scalars['Int']['output']>;
  topLevelPostTitle?: Maybe<Scalars['String']['output']>;
};

export enum RBabelonNotificationType {
  Like = 'LIKE',
  Reply = 'REPLY',
}

export type RBatchEnrollmentResponse = {
  __typename?: 'BatchEnrollmentResponse';
  created?: Maybe<Array<Maybe<RLocationEnrollment>>>;
  skipped?: Maybe<Array<Maybe<RFailedEnrollmentMessage>>>;
};

export type RBlock = {
  __typename?: 'Block';
  created?: Maybe<Scalars['String']['output']>;
  data?: Maybe<Scalars['JSON']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  pageId?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated?: Maybe<Scalars['String']['output']>;
};

export type RBlockInput = {
  created?: InputMaybe<Scalars['String']['input']>;
  data?: InputMaybe<Scalars['JSON']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  pageId?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated?: InputMaybe<Scalars['String']['input']>;
};

export type RBlockV2 = {
  __typename?: 'BlockV2';
  data: Scalars['JSON']['output'];
  id: Scalars['Int']['output'];
  order: Scalars['Int']['output'];
  pageId: Scalars['Int']['output'];
  type: RBlockV2Type;
  urn: Scalars['String']['output'];
};

export type RBlockV2Input = {
  data: Scalars['JSON']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  order: Scalars['Int']['input'];
  pageId: Scalars['Int']['input'];
  type: RBlockV2Type;
};

export enum RBlockV2Type {
  Audio = 'AUDIO',
  Image = 'IMAGE',
  PracticalAssessmentTask = 'PRACTICAL_ASSESSMENT_TASK',
  Question = 'QUESTION',
  Reflection = 'REFLECTION',
  Resource = 'RESOURCE',
  Text = 'TEXT',
  Video = 'VIDEO',
  VideoLink = 'VIDEO_LINK',
}

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type RBoolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']['input']>;
  _gt?: InputMaybe<Scalars['Boolean']['input']>;
  _gte?: InputMaybe<Scalars['Boolean']['input']>;
  _in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Boolean']['input']>;
  _lte?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Scalars['Boolean']['input']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

export type RCatalog = {
  __typename?: 'Catalog';
  cards?: Maybe<Array<Maybe<RCourseCard>>>;
};

export enum RCatalogCardType {
  Collection = 'collection',
  Journey = 'journey',
  Learning = 'learning',
  Product = 'product',
}

export type RCatalogItems = {
  entity?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type RCategory = {
  __typename?: 'Category';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  subjects?: Maybe<Array<Maybe<RSubject>>>;
  urlKey?: Maybe<Scalars['String']['output']>;
};

export type RCloneLearningInput = {
  learningId: Scalars['Int']['input'];
  spaceId: Scalars['Int']['input'];
};

export type RCollectionCard = RCourseCard & {
  __typename?: 'CollectionCard';
  archived?: Maybe<Scalars['Boolean']['output']>;
  available?: Maybe<Scalars['Boolean']['output']>;
  canEdit?: Maybe<Scalars['Boolean']['output']>;
  entity: Scalars['String']['output'];
  entityId: Scalars['Int']['output'];
  featured?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  items?: Maybe<Array<Maybe<RCollectionItemShort>>>;
  language?: Maybe<Scalars['String']['output']>;
  languageId?: Maybe<Scalars['Int']['output']>;
  participation?: Maybe<RParticipation>;
  provider?: Maybe<Scalars['String']['output']>;
  spaceId?: Maybe<Scalars['Int']['output']>;
  subjects?: Maybe<Array<Maybe<RLearningSubject>>>;
  teaser?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
  visibility?: Maybe<RLearningVisibility>;
};

export type RCollectionItemInput = {
  learningId?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  productId?: InputMaybe<Scalars['Int']['input']>;
  visibility?: InputMaybe<RLearningVisibility>;
};

export type RCollectionItemShort = {
  __typename?: 'CollectionItemShort';
  learningId?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  productId?: Maybe<Scalars['Int']['output']>;
  visibility?: Maybe<RLearningVisibility>;
};

export type RCompetence = {
  __typename?: 'Competence';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  spaceId: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  urnV2: Scalars['String']['output'];
};

export type RCompetencePage = {
  __typename?: 'CompetencePage';
  data: Array<RCompetence>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type RCompetenceResult = {
  __typename?: 'CompetenceResult';
  competence?: Maybe<RCompetence>;
  success: Scalars['Boolean']['output'];
};

export type RCompetencesFilter = {
  idIN?: InputMaybe<Array<Scalars['Int']['input']>>;
  searchPhrase?: InputMaybe<Scalars['String']['input']>;
  spaceIdIN?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type RCompetencesSort = {
  order?: InputMaybe<RSortOrder>;
};

export type RCompletionPerElearning = {
  __typename?: 'CompletionPerElearning';
  completed: Scalars['Boolean']['output'];
  learning_id: Scalars['Int']['output'];
};

export type RCompletionRatePerCourse = {
  __typename?: 'CompletionRatePerCourse';
  completed?: Maybe<Scalars['Int']['output']>;
  completion_rate?: Maybe<Scalars['Float']['output']>;
  object_id?: Maybe<Scalars['String']['output']>;
  object_name?: Maybe<Scalars['String']['output']>;
  object_type?: Maybe<Scalars['String']['output']>;
  progressed?: Maybe<Scalars['Int']['output']>;
};

export type RCourseCard = {
  archived?: Maybe<Scalars['Boolean']['output']>;
  available?: Maybe<Scalars['Boolean']['output']>;
  canEdit?: Maybe<Scalars['Boolean']['output']>;
  entity: Scalars['String']['output'];
  entityId: Scalars['Int']['output'];
  featured?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  languageId?: Maybe<Scalars['Int']['output']>;
  participation?: Maybe<RParticipation>;
  provider?: Maybe<Scalars['String']['output']>;
  spaceId?: Maybe<Scalars['Int']['output']>;
  subjects?: Maybe<Array<Maybe<RLearningSubject>>>;
  teaser?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
  visibility?: Maybe<RLearningVisibility>;
};

export type RCreateAssignmentFromTemplateInput = {
  assignmentTemplateId: Scalars['Int']['input'];
  createExportWebinar: Scalars['Boolean']['input'];
  createGetStartedLearning: Scalars['Boolean']['input'];
  createKickOffWebinar: Scalars['Boolean']['input'];
  createManagerCheckIn: Scalars['Boolean']['input'];
  createTeamsChannel: Scalars['Boolean']['input'];
  endAt: Scalars['DateTime']['input'];
  spaceId: Scalars['Int']['input'];
  startAt: Scalars['DateTime']['input'];
};

export type RCreateCollection = {
  contentOwner?: InputMaybe<Scalars['Int']['input']>;
  creator?: InputMaybe<Scalars['Int']['input']>;
  imageUrl: Scalars['String']['input'];
  languageId: Scalars['Int']['input'];
  spaceId: Scalars['Int']['input'];
  status: RLearningStatus;
  subjectId: Scalars['Int']['input'];
  teaser: Scalars['String']['input'];
  title: Scalars['String']['input'];
  visibility: RLearningVisibility;
};

export type RCreateCompetenceInput = {
  spaceId: Scalars['Int']['input'];
  title: Scalars['String']['input'];
};

export type RCreateLearningJourneyFromTemplateInput = {
  engagementLearnings?: InputMaybe<REngagementLearnings>;
  sourceTemplateId: Scalars['Int']['input'];
  spaceId: Scalars['Int']['input'];
  title: Scalars['String']['input'];
};

export type RCreateLearningJourneyInput = {
  spaceId: Scalars['Int']['input'];
  title: Scalars['String']['input'];
};

export type RCreateLearningJourneyLiveEventInput = {
  bannerURL?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  locations: Array<RCreateLearningJourneyLiveEventLocationInput>;
  plannedDuration: Scalars['Int']['input'];
  spaceId: Scalars['Int']['input'];
  timezone: Scalars['Int']['input'];
  title: Scalars['String']['input'];
};

export type RCreateLearningJourneyLiveEventLocationInput = {
  address: Scalars['String']['input'];
  endDate: Scalars['DateTime']['input'];
  startDate: Scalars['DateTime']['input'];
  type: RLocationType;
};

export type RCreateLearningJourneyPracticalAssessmentsInput = {
  practicalAssessmentTemplateIdsIN: Array<Scalars['Int']['input']>;
  spaceId: Scalars['Int']['input'];
};

export type RCreateLearningJourneySurveysInput = {
  spaceId: Scalars['Int']['input'];
  surveyIdsIN: Array<Scalars['Int']['input']>;
};

export type RCreateLearningLoopReflectionInput = {
  applied: RLearningLoopReflectionApplied;
  description: Scalars['String']['input'];
  learningLoopId: Scalars['String']['input'];
};

export type RCreateLearningLoopReflectionResult = {
  __typename?: 'CreateLearningLoopReflectionResult';
  learningLoop?: Maybe<RLearningLoopReflection>;
  success: Scalars['Boolean']['output'];
};

export type RCreateSectionInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  path: Array<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type RCreateSelfTrackedLearningInput = {
  description: Scalars['String']['input'];
  effort: Scalars['Int']['input'];
  link: Scalars['String']['input'];
  title: Scalars['String']['input'];
  type: RSelfTrackedLearningType;
  userId: Scalars['Int']['input'];
};

export type RCreateSpaceV2Input = {
  label: Scalars['String']['input'];
  name: Scalars['String']['input'];
  realm: Scalars['String']['input'];
};

export type RCreateSpaceV2RoleAssignInput = {
  externalId?: InputMaybe<Scalars['String']['input']>;
  realm?: InputMaybe<Scalars['String']['input']>;
  role: RSpaceV2Role;
  roleDisplayName?: InputMaybe<Scalars['String']['input']>;
  spaceId: Scalars['Int']['input'];
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type RCreateTourCompleteUserInput = {
  tourId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};

export type RCreateUser = {
  email: Scalars['String']['input'];
  realm: Scalars['String']['input'];
};

export type RCreateUserInput = {
  sendInvites?: InputMaybe<Scalars['Boolean']['input']>;
  users: Array<RCreateUser>;
};

export type RCreateUserLearningSubmissionInput = {
  data: Scalars['JSON']['input'];
  learningId: Scalars['Int']['input'];
  pageId: Scalars['Int']['input'];
  type: RUserLearningSubmissionType;
  userId: Scalars['Int']['input'];
};

export type RCreateUserLearningSubmissionReviewInput = {
  data: Scalars['JSON']['input'];
  pageId: Scalars['Int']['input'];
  reviewerId: Scalars['Int']['input'];
  submissionId: Scalars['Int']['input'];
  type: RUserLearningSubmissionReviewType;
};

export type RCreateUsersResponse = {
  __typename?: 'CreateUsersResponse';
  realm: Scalars['String']['output'];
  userId: Scalars['Int']['output'];
};

export type RCurationFilter = {
  searchPhrase?: InputMaybe<Scalars['String']['input']>;
  spacedIdsIN: Array<Scalars['Int']['input']>;
};

export type RDataItem = {
  __typename?: 'DataItem';
  name: Scalars['String']['output'];
  value: Scalars['Int']['output'];
};

export type RDeleteEnrollmentInput = {
  enrollmentId: Scalars['Int']['input'];
};

export type REffortForRealm = {
  __typename?: 'EffortForRealm';
  average_effort_per_user?: Maybe<Scalars['Float']['output']>;
  p50?: Maybe<Scalars['Float']['output']>;
  p60?: Maybe<Scalars['Float']['output']>;
  p70?: Maybe<Scalars['Float']['output']>;
  p80?: Maybe<Scalars['Float']['output']>;
  p90?: Maybe<Scalars['Float']['output']>;
  total_effort_for_realm?: Maybe<Scalars['Float']['output']>;
};

export type REffortForRealmOverTime = {
  __typename?: 'EffortForRealmOverTime';
  name: Scalars['String']['output'];
  value?: Maybe<Scalars['Float']['output']>;
};

export type REffortPerProvider = {
  __typename?: 'EffortPerProvider';
  effort: Scalars['Float']['output'];
  provider: Scalars['String']['output'];
};

export type REngagementLearnings = {
  expertWebinar?: InputMaybe<Scalars['Boolean']['input']>;
  getStarted?: InputMaybe<Scalars['Boolean']['input']>;
  kickOffWebinar?: InputMaybe<Scalars['Boolean']['input']>;
  managerCheckin?: InputMaybe<Scalars['Boolean']['input']>;
  teamsChannel?: InputMaybe<Scalars['Boolean']['input']>;
};

export type REnrollByEmailInput = {
  emails: Array<Scalars['String']['input']>;
  locationId: Scalars['Int']['input'];
};

export type REnrollSelfInput = {
  locationId: Scalars['Int']['input'];
};

export type REnrollment = {
  __typename?: 'Enrollment';
  attended: RAttendedType;
  enrolledAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  locationId: Scalars['Int']['output'];
  user?: Maybe<RUserV2>;
  userId: Scalars['Int']['output'];
};

export type REnrollmentsInput = {
  locationIds: Array<Scalars['Int']['input']>;
};

export type REventEnrollment = {
  __typename?: 'EventEnrollment';
  address: Scalars['String']['output'];
  endDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  learningId: Scalars['Int']['output'];
  locationId: Scalars['Int']['output'];
  room?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  teaser: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type RFailedEnrollmentMessage = {
  __typename?: 'FailedEnrollmentMessage';
  email?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type RFeatureToggle = {
  __typename?: 'FeatureToggle';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  enabledRealms: Array<RFeatureToggleRealm>;
  enabledUsers: Array<RFeatureToggleUser>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  public: Scalars['Boolean']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type RFeatureToggleEnableRealmInput = {
  featureId: Scalars['Int']['input'];
  realmId: Scalars['Int']['input'];
};

export type RFeatureToggleEnableUserInput = {
  featureId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};

export type RFeatureToggleFilter = {
  idIN?: InputMaybe<Array<Scalars['Int']['input']>>;
  publicEQ?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RFeatureTogglePage = {
  __typename?: 'FeatureTogglePage';
  data: Array<RFeatureToggle>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type RFeatureToggleRealm = {
  __typename?: 'FeatureToggleRealm';
  createdAt: Scalars['DateTime']['output'];
  featureId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  realm: RRealmV2;
  realmId: Scalars['Float']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type RFeatureToggleSort = {
  field: RFindFtSortField;
  order: Scalars['String']['input'];
};

export type RFeatureToggleUser = {
  __typename?: 'FeatureToggleUser';
  createdAt: Scalars['DateTime']['output'];
  featureId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  user: RUserV2;
  userId: Scalars['Float']['output'];
};

export type RFile = {
  __typename?: 'File';
  path: Scalars['String']['output'];
};

export type RFilteredOrdersFlatResult = {
  __typename?: 'FilteredOrdersFlatResult';
  productOrders?: Maybe<Array<Maybe<ROrderRowFlat>>>;
  total?: Maybe<Scalars['Int']['output']>;
  totalPages?: Maybe<Scalars['Int']['output']>;
};

export enum RFindFtSortField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  Name = 'NAME',
  UpdatedAt = 'UPDATED_AT',
}

export type RHistoricCompletedCourse = {
  __typename?: 'HistoricCompletedCourse';
  completed?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type RIdResponse = {
  __typename?: 'IdResponse';
  id: Scalars['Int']['output'];
};

export type RImportLearningContentToSpaceInput = {
  learningContentIdIN: Array<Scalars['URN']['input']>;
  originId: Scalars['URN']['input'];
  spaceId: Scalars['Int']['input'];
};

export type RImportLearningContentToSpaceResult = {
  __typename?: 'ImportLearningContentToSpaceResult';
  content?: Maybe<Array<RLearningContent>>;
  failedIds?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type RInt_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']['input']>;
  _gt?: InputMaybe<Scalars['Int']['input']>;
  _gte?: InputMaybe<Scalars['Int']['input']>;
  _in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Int']['input']>;
  _lte?: InputMaybe<Scalars['Int']['input']>;
  _neq?: InputMaybe<Scalars['Int']['input']>;
  _nin?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type RInventory = {
  __typename?: 'Inventory';
  completed?: Maybe<Array<Maybe<RInventoryItem>>>;
  ongoing?: Maybe<Array<Maybe<RInventoryItem>>>;
};

export type RInventoryItem = {
  __typename?: 'InventoryItem';
  accessLink?: Maybe<Scalars['String']['output']>;
  archived?: Maybe<Scalars['Boolean']['output']>;
  customLink?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  isApprovalRequired?: Maybe<Scalars['Boolean']['output']>;
  isCancellable?: Maybe<Scalars['Boolean']['output']>;
  isExternal?: Maybe<Scalars['Boolean']['output']>;
  journeyId?: Maybe<Scalars['Int']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  mandatory?: Maybe<Scalars['Boolean']['output']>;
  meta?: Maybe<RProductMeta>;
  name?: Maybe<Scalars['String']['output']>;
  objectId?: Maybe<Scalars['String']['output']>;
  orderId?: Maybe<Scalars['Int']['output']>;
  participation?: Maybe<RParticipation>;
  productId?: Maybe<Scalars['Int']['output']>;
  provider?: Maybe<Scalars['String']['output']>;
  providerAccess?: Maybe<Scalars['String']['output']>;
  services?: Maybe<Array<Maybe<RService>>>;
  spaceId?: Maybe<Scalars['Int']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  subjects?: Maybe<Array<Maybe<RLearningSubject>>>;
  teaser?: Maybe<Scalars['String']['output']>;
  totalEffort?: Maybe<Scalars['Int']['output']>;
  totalLearningsCount?: Maybe<Scalars['Int']['output']>;
  userSelfTrackedLearningId?: Maybe<Scalars['Int']['output']>;
  variant?: Maybe<RVariant>;
};

export type RIssuedLicense = {
  __typename?: 'IssuedLicense';
  businessArea?: Maybe<Scalars['String']['output']>;
  businessUnit?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  division?: Maybe<Scalars['String']['output']>;
  employeeId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  lastRequestedAt?: Maybe<Scalars['String']['output']>;
  orgLicenseId?: Maybe<Scalars['Int']['output']>;
  sId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<RLicenseStatus>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  user?: Maybe<RUser>;
  userId?: Maybe<Scalars['Int']['output']>;
};

export type RJourneyCard = RCourseCard & {
  __typename?: 'JourneyCard';
  archived?: Maybe<Scalars['Boolean']['output']>;
  available?: Maybe<Scalars['Boolean']['output']>;
  canEdit?: Maybe<Scalars['Boolean']['output']>;
  entity: Scalars['String']['output'];
  entityId: Scalars['Int']['output'];
  featured?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  languageId?: Maybe<Scalars['Int']['output']>;
  participation?: Maybe<RParticipation>;
  provider?: Maybe<Scalars['String']['output']>;
  spaceId?: Maybe<Scalars['Int']['output']>;
  subjects?: Maybe<Array<Maybe<RLearningSubject>>>;
  teaser?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  totalEffort?: Maybe<Scalars['Int']['output']>;
  totalLearningsCount?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  visibility?: Maybe<RLearningVisibility>;
};

export type RLanguage = {
  __typename?: 'Language';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type RLanguageV2 = {
  __typename?: 'LanguageV2';
  isoCode: Scalars['String']['output'];
  languageId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  nativeName: Scalars['String']['output'];
  visible: Scalars['Boolean']['output'];
};

export type RLaunchUrl = {
  __typename?: 'LaunchURL';
  baseURL?: Maybe<Scalars['String']['output']>;
  path?: Maybe<Scalars['String']['output']>;
  rawURL?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export type RLearnifierCourse = {
  __typename?: 'LearnifierCourse';
  adminLink?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  host?: Maybe<Scalars['String']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['String']['output']>;
  picture?: Maybe<Scalars['String']['output']>;
  projectId?: Maybe<Scalars['String']['output']>;
  resource?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type RLearnifierCourseInput = {
  adminLink?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  host?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  picture?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['String']['input']>;
  resource?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type RLearning = {
  __typename?: 'Learning';
  archived?: Maybe<Scalars['String']['output']>;
  canEdit?: Maybe<Scalars['Boolean']['output']>;
  contentOwner?: Maybe<Scalars['Int']['output']>;
  created?: Maybe<Scalars['String']['output']>;
  creatorEmail?: Maybe<Scalars['String']['output']>;
  effort?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  languageId?: Maybe<Scalars['Int']['output']>;
  locations?: Maybe<Array<Maybe<RLocation>>>;
  meta?: Maybe<Scalars['JSON']['output']>;
  modules?: Maybe<Array<Maybe<RModule>>>;
  parents: Array<RLearningContent>;
  preamble?: Maybe<Scalars['String']['output']>;
  progress?: Maybe<RLearningProgress>;
  spaceId?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<RLearningStatus>;
  subjects?: Maybe<Array<Maybe<RLearningSubject>>>;
  teaser?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<RLearningType>;
  updated?: Maybe<Scalars['String']['output']>;
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  urnV2?: Maybe<Scalars['String']['output']>;
  visibility?: Maybe<RLearningVisibility>;
};

export type RLearningParentsArgs = {
  filter: RLearningParentsFilter;
};

export type RLearningActivityDto = {
  __typename?: 'LearningActivityDto';
  createdAt: Scalars['DateTime']['output'];
  data: Scalars['JSON']['output'];
  id: Scalars['Int']['output'];
  learningId?: Maybe<Scalars['Int']['output']>;
  order: Scalars['Int']['output'];
  parentActivityId?: Maybe<Scalars['Int']['output']>;
  type: RLearningActivityType;
  updatedAt: Scalars['DateTime']['output'];
  urn: Scalars['String']['output'];
};

export type RLearningActivityInput = {
  data: Scalars['JSON']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  learningId?: InputMaybe<Scalars['Int']['input']>;
  order: Scalars['Int']['input'];
  parentActivityId?: InputMaybe<Scalars['Int']['input']>;
  type: RLearningActivityType;
};

export type RLearningActivityInputDto = {
  children: Array<RLearningActivityInput>;
  parent?: InputMaybe<RLearningActivityInput>;
};

export enum RLearningActivityType {
  Module = 'MODULE',
  Page = 'PAGE',
  PracticalAssessmentTask = 'PRACTICAL_ASSESSMENT_TASK',
  Question = 'QUESTION',
  Quiz = 'QUIZ',
  Reflection = 'REFLECTION',
}

export type RLearningCard = RCourseCard & {
  __typename?: 'LearningCard';
  archived?: Maybe<Scalars['Boolean']['output']>;
  attributes?: Maybe<Scalars['JSON']['output']>;
  available?: Maybe<Scalars['Boolean']['output']>;
  canEdit?: Maybe<Scalars['Boolean']['output']>;
  entity: Scalars['String']['output'];
  entityId: Scalars['Int']['output'];
  featured?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  languageId?: Maybe<Scalars['Int']['output']>;
  level?: Maybe<Scalars['String']['output']>;
  locations?: Maybe<Array<Maybe<RLearningCardLocation>>>;
  participation?: Maybe<RParticipation>;
  provider?: Maybe<Scalars['String']['output']>;
  spaceId?: Maybe<Scalars['Int']['output']>;
  subjects?: Maybe<Array<Maybe<RLearningSubject>>>;
  teaser?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  totalEffort?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  visibility?: Maybe<RLearningVisibility>;
};

export type RLearningCardLocation = {
  __typename?: 'LearningCardLocation';
  endDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  learningId: Scalars['Int']['output'];
  startDate?: Maybe<Scalars['String']['output']>;
};

export type RLearningCollection = {
  __typename?: 'LearningCollection';
  canEdit?: Maybe<Scalars['Boolean']['output']>;
  categoryId?: Maybe<Scalars['Int']['output']>;
  contentOwner?: Maybe<Scalars['Int']['output']>;
  created?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<Scalars['Int']['output']>;
  creatorEmail?: Maybe<Scalars['String']['output']>;
  editLink?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  items?: Maybe<Array<Maybe<RCourseCard>>>;
  languageId?: Maybe<Scalars['Int']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  previewLink?: Maybe<Scalars['String']['output']>;
  spaceId?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<RLearningStatus>;
  subjectId?: Maybe<Scalars['Int']['output']>;
  teaser?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<RLearningType>;
  updated?: Maybe<Scalars['String']['output']>;
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  visibility?: Maybe<RLearningVisibility>;
};

export type RLearningCollectionFilter = {
  categoryIdIN?: InputMaybe<Array<Scalars['Int']['input']>>;
  idIN?: InputMaybe<Array<Scalars['Int']['input']>>;
  spaceIdIN?: InputMaybe<Array<Scalars['Int']['input']>>;
  statusIN?: InputMaybe<Array<RLearningV2Status>>;
  subjectIdIN?: InputMaybe<Array<Scalars['Int']['input']>>;
  titleLIKE?: InputMaybe<Scalars['String']['input']>;
  visibilityIN?: InputMaybe<Array<RLearningV2Visibility>>;
};

export type RLearningCollectionItem = {
  __typename?: 'LearningCollectionItem';
  collectionId: Scalars['Int']['output'];
  content?: Maybe<RLearningContent>;
  contentURNV1: Scalars['String']['output'];
  curationURNV1: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  order: Scalars['Int']['output'];
};

export type RLearningCollectionItemPage = {
  __typename?: 'LearningCollectionItemPage';
  data: Array<RLearningCollectionItem>;
  limit?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  total: Scalars['Int']['output'];
};

export type RLearningCollectionPage = {
  __typename?: 'LearningCollectionPage';
  data: Array<RLearningCollectionV2>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type RLearningCollectionSort = {
  field: RLearningCollectionSortField;
  order: RSortOrder;
};

export enum RLearningCollectionSortField {
  Id = 'ID',
  SpaceId = 'SPACE_ID',
  SpaceName = 'SPACE_NAME',
  Status = 'STATUS',
  Title = 'TITLE',
  TotalCount = 'TOTAL_COUNT',
  Visibility = 'VISIBILITY',
}

export type RLearningCollectionV2 = {
  __typename?: 'LearningCollectionV2';
  contentOwnerId: Scalars['Int']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  creatorId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  imageURL?: Maybe<Scalars['String']['output']>;
  items: RLearningCollectionItemPage;
  languageId: Scalars['Int']['output'];
  space?: Maybe<RSpaceV2>;
  spaceId: Scalars['Int']['output'];
  status: RLearningV2Status;
  subject?: Maybe<RSubjectCategoryV2>;
  subjectId?: Maybe<Scalars['Int']['output']>;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedById?: Maybe<Scalars['Int']['output']>;
  updatedByUser?: Maybe<RUserV2>;
  urn: Scalars['String']['output'];
  visibility: RLearningV2Visibility;
};

export type RLearningContent = {
  __typename?: 'LearningContent';
  curations: Array<RLearningCuration>;
  description: Scalars['String']['output'];
  id: Scalars['URN']['output'];
  idV1: Scalars['String']['output'];
  imageURL?: Maybe<Scalars['String']['output']>;
  launchURL?: Maybe<Scalars['String']['output']>;
  locations?: Maybe<Array<RLearningContentLocationData>>;
  metadata?: Maybe<RLearningContentMetadata>;
  /** @deprecated Use origins instead */
  originIds: Array<Scalars['URN']['output']>;
  origins?: Maybe<Array<ROrigin>>;
  plannedDuration?: Maybe<Scalars['Int']['output']>;
  plannedLearningsCount?: Maybe<Scalars['Int']['output']>;
  provider: RLearningContentProvider;
  title: Scalars['String']['output'];
  userLastParticipation?: Maybe<RParticipationV2>;
};

export type RLearningContentCurationsArgs = {
  filter: RLearningCurationFilter;
};

export type RLearningContentUserLastParticipationArgs = {
  userId: Scalars['Int']['input'];
};

export enum RLearningContentContentType {
  Article = 'ARTICLE',
  Audio = 'AUDIO',
  Collection = 'COLLECTION',
  Course = 'COURSE',
  ELearning = 'E_LEARNING',
  Journey = 'JOURNEY',
  LiveEvent = 'LIVE_EVENT',
  OnlineLearning = 'ONLINE_LEARNING',
  Path = 'PATH',
  PracticalAssessment = 'PRACTICAL_ASSESSMENT',
  Quiz = 'QUIZ',
  Reflection = 'REFLECTION',
  Resource = 'RESOURCE',
  Seminar = 'SEMINAR',
  Survey = 'SURVEY',
  Video = 'VIDEO',
  Webinar = 'WEBINAR',
}

export type RLearningContentLocationData = {
  __typename?: 'LearningContentLocationData';
  address?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  type?: Maybe<RLocationType>;
};

export type RLearningContentMetadata = {
  __typename?: 'LearningContentMetadata';
  language?: Maybe<Scalars['String']['output']>;
  level?: Maybe<Scalars['String']['output']>;
  systemGenerated?: Maybe<Scalars['Boolean']['output']>;
  timezone?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<RLearningContentContentType>;
};

export enum RLearningContentProvider {
  Collegial = 'COLLEGIAL',
  Coursera = 'COURSERA',
  CustomLink = 'CUSTOM_LINK',
  Finanskompetens = 'FINANSKOMPETENS',
  Imd = 'IMD',
  Imparta = 'IMPARTA',
  Learnifier = 'LEARNIFIER',
  Linkedin = 'LINKEDIN',
  Lumesse = 'LUMESSE',
  Pluralsight = 'PLURALSIGHT',
}

export type RLearningContentsPage = {
  __typename?: 'LearningContentsPage';
  data: Array<RLearningContent>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type RLearningCuration = {
  __typename?: 'LearningCuration';
  /** @deprecated Temporary measure to get the editPathURL relevant content type */
  contentType?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  editURLPath?: Maybe<Scalars['String']['output']>;
  id: Scalars['URN']['output'];
  imageURL?: Maybe<Scalars['String']['output']>;
  launchURL?: Maybe<Scalars['String']['output']>;
  launchURLPath?: Maybe<Scalars['String']['output']>;
  learningContentId: Scalars['URN']['output'];
  /** available values are COLLECTION, LEARNING, LINKED_CONTENT, LEARNING_JOURNEY */
  source: Scalars['String']['output'];
  space?: Maybe<RSpaceV2>;
  spaceId: Scalars['Int']['output'];
  title?: Maybe<Scalars['String']['output']>;
  updateByUser?: Maybe<RUserV2>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedById?: Maybe<Scalars['Int']['output']>;
};

export type RLearningCurationLaunchUrlArgs = {
  realm?: InputMaybe<Scalars['String']['input']>;
};

export type RLearningCurationLaunchUrlPathArgs = {
  realm?: InputMaybe<Scalars['String']['input']>;
};

export type RLearningCurationFilter = {
  spacedIdsIN?: InputMaybe<Array<Scalars['Int']['input']>>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type RLearningInput = {
  archived?: InputMaybe<Scalars['String']['input']>;
  contentOwner?: InputMaybe<Scalars['Int']['input']>;
  created?: InputMaybe<Scalars['String']['input']>;
  effort: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  languageId?: InputMaybe<Scalars['Int']['input']>;
  locations?: InputMaybe<Array<InputMaybe<RLocationInput>>>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  modules?: InputMaybe<Array<InputMaybe<RModuleInput>>>;
  preamble?: InputMaybe<Scalars['String']['input']>;
  spaceId: Scalars['Int']['input'];
  subjects: Array<Scalars['Int']['input']>;
  teaser: Scalars['String']['input'];
  title: Scalars['String']['input'];
  type: RLearningType;
  updated?: InputMaybe<Scalars['String']['input']>;
  visibility: RLearningVisibility;
};

export type RLearningItemCard = RLearningCard | RProductCard;

export type RLearningJourney = {
  __typename?: 'LearningJourney';
  bannerURL?: Maybe<Scalars['String']['output']>;
  businessGoal?: Maybe<Scalars['String']['output']>;
  cohortTeams?: Maybe<Array<RTeam>>;
  competences: RLearningJourneyCompetencePage;
  completionCriteria?: Maybe<RLearningJourneyCompletionStatus>;
  completionInvestTimeSec?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  discussionEnabled: Scalars['Boolean']['output'];
  discussionForumSectionPath: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  impactSurveysEnabled: Scalars['Boolean']['output'];
  isLinear: Scalars['Boolean']['output'];
  items: RLearningJourneyItemPage;
  sourceTemplateId?: Maybe<Scalars['Int']['output']>;
  spaceFeatured: Scalars['Boolean']['output'];
  spaceId: Scalars['Int']['output'];
  status: RLearningJourneyStatus;
  subject?: Maybe<RSubjectCategoryV2>;
  subjectId?: Maybe<Scalars['Int']['output']>;
  title: Scalars['String']['output'];
  updateByUser?: Maybe<RUserV2>;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['Int']['output']>;
  urn: Scalars['String']['output'];
};

export type RLearningJourneyCompetencesArgs = {
  pagination?: InputMaybe<RPagination>;
};

export type RLearningJourneyItemsArgs = {
  pagination?: InputMaybe<RPagination>;
};

export type RLearningJourneyCohort = {
  __typename?: 'LearningJourneyCohort';
  learners: Array<RLearningJourneyLearner>;
};

export type RLearningJourneyCompetence = {
  __typename?: 'LearningJourneyCompetence';
  competence: RCompetence;
  competenceId: Scalars['Int']['output'];
  journeyId: Scalars['Int']['output'];
};

export type RLearningJourneyCompetencePage = {
  __typename?: 'LearningJourneyCompetencePage';
  data: Array<RLearningJourneyCompetence>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum RLearningJourneyCompletionStatus {
  CompleteAllItems = 'COMPLETE_ALL_ITEMS',
  InvestTime = 'INVEST_TIME',
}

export type RLearningJourneyFilter = {
  searchPhrase?: InputMaybe<Scalars['String']['input']>;
  spaceIdIN?: InputMaybe<Array<Scalars['Int']['input']>>;
  statusIN?: InputMaybe<Array<RLearningJourneyStatus>>;
};

export type RLearningJourneyItem = {
  __typename?: 'LearningJourneyItem';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  isLocked: Scalars['Boolean']['output'];
  journeyId: Scalars['Int']['output'];
  learningItem?: Maybe<RLearningContent>;
  learningItemURNV2: Scalars['String']['output'];
  order: Scalars['Int']['output'];
};

export type RLearningJourneyItemIsLockedArgs = {
  userId: Scalars['Int']['input'];
};

export type RLearningJourneyItemPage = {
  __typename?: 'LearningJourneyItemPage';
  data: Array<RLearningJourneyItem>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type RLearningJourneyItemsCatalogFilter = {
  id: Scalars['Int']['input'];
  searchPhrase?: InputMaybe<Scalars['String']['input']>;
};

export type RLearningJourneyLearner = {
  __typename?: 'LearningJourneyLearner';
  progress?: Maybe<RParticipationV2>;
  sessionId: Scalars['Int']['output'];
  user: RUserV2;
  userId: Scalars['Int']['output'];
};

export type RLearningJourneyPage = {
  __typename?: 'LearningJourneyPage';
  data: Array<RLearningJourney>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type RLearningJourneyResult = {
  __typename?: 'LearningJourneyResult';
  journey?: Maybe<RLearningJourney>;
  success: Scalars['Boolean']['output'];
};

export type RLearningJourneySession = {
  __typename?: 'LearningJourneySession';
  cohort: RLearningJourneyCohort;
  id: Scalars['Int']['output'];
  journey?: Maybe<RLearningJourney>;
  journeyId: Scalars['Int']['output'];
  registration: Scalars['String']['output'];
  spaceId: Scalars['Int']['output'];
  startedAt: Scalars['DateTime']['output'];
  urn: Scalars['String']['output'];
};

export type RLearningJourneySessionPage = {
  __typename?: 'LearningJourneySessionPage';
  data: Array<RLearningJourneySession>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type RLearningJourneySessionResult = {
  __typename?: 'LearningJourneySessionResult';
  session?: Maybe<RLearningJourneySession>;
  success: Scalars['Boolean']['output'];
};

export type RLearningJourneySort = {
  field?: InputMaybe<RLearningJourneySortField>;
  order?: InputMaybe<RSortOrder>;
};

export enum RLearningJourneySortField {
  Id = 'ID',
  SpaceId = 'SPACE_ID',
  Status = 'STATUS',
  Title = 'TITLE',
}

export enum RLearningJourneyStatus {
  Deleted = 'DELETED',
  Published = 'PUBLISHED',
  Unpublished = 'UNPUBLISHED',
}

export type RLearningJourneyTemplateDto = {
  __typename?: 'LearningJourneyTemplateDto';
  bannerURL: Scalars['String']['output'];
  businessGoal: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  teaser: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type RLearningLoop = {
  __typename?: 'LearningLoop';
  activitiesAggregated: Array<RLearningLoopActivityAggregated>;
  assignmentId: Scalars['Int']['output'];
  canReflect: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  endAt: Scalars['DateTime']['output'];
  estimatedEffort: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  recommendedEffort: Scalars['Int']['output'];
  reflection?: Maybe<RLearningLoopReflection>;
  startAt: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['Int']['output'];
};

export type RLearningLoopActivityAggregated = {
  __typename?: 'LearningLoopActivityAggregated';
  estimatedEffortSum: Scalars['Int']['output'];
  participation: RParticipationV2;
};

export type RLearningLoopFilter = {
  assignmentId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};

export type RLearningLoopPage = {
  __typename?: 'LearningLoopPage';
  data: Array<RLearningLoop>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type RLearningLoopReflection = {
  __typename?: 'LearningLoopReflection';
  applied: RLearningLoopReflectionApplied;
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  learningLoopId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export enum RLearningLoopReflectionApplied {
  Applied = 'APPLIED',
  NotSure = 'NOT_SURE',
  PlannedNextMonth = 'PLANNED_NEXT_MONTH',
  PlannedNextSixMonths = 'PLANNED_NEXT_SIX_MONTHS',
}

export type RLearningParentCurationsFilter = {
  spaceIdIN: Array<Scalars['Int']['input']>;
};

export type RLearningParentsFilter = {
  curation: RLearningParentCurationsFilter;
};

export type RLearningPinned = {
  __typename?: 'LearningPinned';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  learningCollectionId?: Maybe<Scalars['Int']['output']>;
  learningId?: Maybe<Scalars['Int']['output']>;
  learningJourneyId?: Maybe<Scalars['Int']['output']>;
  learningPathId?: Maybe<Scalars['Int']['output']>;
  position: Scalars['Int']['output'];
  realm: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type RLearningPinnedUpdateInput = {
  entity: RCatalogCardType;
  entityId: Scalars['Int']['input'];
  realm: Scalars['String']['input'];
};

export type RLearningProgress = {
  __typename?: 'LearningProgress';
  completed?: Maybe<Scalars['Boolean']['output']>;
  completedPercentage?: Maybe<Scalars['Float']['output']>;
  expiredAt?: Maybe<Scalars['String']['output']>;
  historical?: Maybe<Scalars['Boolean']['output']>;
};

export enum RLearningStatus {
  Archived = 'ARCHIVED',
  Available = 'AVAILABLE',
  Deleted = 'DELETED',
}

export type RLearningSubject = {
  __typename?: 'LearningSubject';
  category?: Maybe<Scalars['String']['output']>;
  categoryId?: Maybe<Scalars['Int']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  subjectId?: Maybe<Scalars['Int']['output']>;
};

export enum RLearningType {
  Article = 'ARTICLE',
  Audio = 'AUDIO',
  Course = 'COURSE',
  ELearning = 'E_LEARNING',
  LearningPath = 'LEARNING_PATH',
  LiveEvent = 'LIVE_EVENT',
  OnlineLearning = 'ONLINE_LEARNING',
  PracticalAssessment = 'PRACTICAL_ASSESSMENT',
  Resource = 'RESOURCE',
  Seminar = 'SEMINAR',
  Survey = 'SURVEY',
  Video = 'VIDEO',
  Webinar = 'WEBINAR',
}

export type RLearningV2 = {
  __typename?: 'LearningV2';
  activities?: Maybe<Array<RLearningActivityDto>>;
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  contentOwner?: Maybe<RUserV2>;
  contentOwnerId?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  creator?: Maybe<RUserV2>;
  creatorId?: Maybe<Scalars['Int']['output']>;
  effort?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  language?: Maybe<RLanguageV2>;
  languageId?: Maybe<Scalars['Int']['output']>;
  meta?: Maybe<Scalars['JSON']['output']>;
  modules: Array<RModuleV2>;
  preamble: Scalars['String']['output'];
  spaceId: Scalars['Int']['output'];
  status: RLearningV2Status;
  subjects?: Maybe<Array<RSubjectCategory>>;
  teaser: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: RLearningV2Type;
  updateByUser?: Maybe<RUserV2>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedByUserId?: Maybe<Scalars['Int']['output']>;
  urn: Scalars['String']['output'];
  userLastParticipation?: Maybe<RParticipationV2>;
  visibility?: Maybe<RLearningV2Visibility>;
};

export type RLearningV2UserLastParticipationArgs = {
  userId: Scalars['Int']['input'];
};

export type RLearningV2Input = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  contentOwnerId?: InputMaybe<Scalars['Int']['input']>;
  creatorId?: InputMaybe<Scalars['Int']['input']>;
  effort?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  languageId: Scalars['Int']['input'];
  meta?: InputMaybe<Scalars['JSON']['input']>;
  preamble: Scalars['String']['input'];
  spaceId: Scalars['Int']['input'];
  status: RLearningV2Status;
  teaser: Scalars['String']['input'];
  title: Scalars['String']['input'];
  type: RLearningV2Type;
  updatedByUserId?: InputMaybe<Scalars['Int']['input']>;
  visibility?: InputMaybe<RLearningV2Visibility>;
};

export enum RLearningV2Status {
  Archived = 'ARCHIVED',
  Available = 'AVAILABLE',
  Deleted = 'DELETED',
}

export enum RLearningV2Type {
  Article = 'ARTICLE',
  Audio = 'AUDIO',
  Course = 'COURSE',
  ELearning = 'E_LEARNING',
  LiveEvent = 'LIVE_EVENT',
  OnlineLearning = 'ONLINE_LEARNING',
  PracticalAssessment = 'PRACTICAL_ASSESSMENT',
  Resource = 'RESOURCE',
  Seminar = 'SEMINAR',
  Survey = 'SURVEY',
  Video = 'VIDEO',
  Webinar = 'WEBINAR',
}

export enum RLearningV2Visibility {
  Featured = 'FEATURED',
  Hidden = 'HIDDEN',
  Visible = 'VISIBLE',
}

export enum RLearningVisibility {
  Featured = 'FEATURED',
  Hidden = 'HIDDEN',
  Visible = 'VISIBLE',
}

export enum RLicenseStatus {
  Approved = 'APPROVED',
  NotApproved = 'NOT_APPROVED',
  PendingApproval = 'PENDING_APPROVAL',
}

export type RLocation = {
  __typename?: 'Location';
  address?: Maybe<Scalars['String']['output']>;
  capacity?: Maybe<Scalars['Int']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  learning?: Maybe<RLearning>;
  learningId: Scalars['Int']['output'];
  myEnrollment?: Maybe<REnrollment>;
  room?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  stats?: Maybe<RLocationStats>;
  type?: Maybe<RLocationType>;
  updated: Scalars['DateTime']['output'];
  waitlistType: RWaitlistType;
};

export type RLocationEnrollment = {
  __typename?: 'LocationEnrollment';
  attended?: Maybe<RAttendedType>;
  email?: Maybe<Scalars['String']['output']>;
  enrolledAt?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  locationId?: Maybe<Scalars['Int']['output']>;
  userId?: Maybe<Scalars['Int']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type RLocationInput = {
  address: Scalars['String']['input'];
  archived?: InputMaybe<Scalars['String']['input']>;
  capacity?: InputMaybe<Scalars['Int']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  learningId?: InputMaybe<Scalars['Int']['input']>;
  room?: InputMaybe<Scalars['String']['input']>;
  startDate: Scalars['String']['input'];
  type?: InputMaybe<RLocationType>;
  waitlistType?: InputMaybe<RWaitlistType>;
};

export type RLocationStats = {
  __typename?: 'LocationStats';
  attendedCount: Scalars['Int']['output'];
  enrolledCount: Scalars['Int']['output'];
  isFinished: Scalars['Boolean']['output'];
  notAttendedCount: Scalars['Int']['output'];
  waitlistedCount: Scalars['Int']['output'];
};

export enum RLocationType {
  InPerson = 'IN_PERSON',
  Online = 'ONLINE',
}

export type RLocationV2Input = {
  address: Scalars['String']['input'];
  endDate: Scalars['DateTime']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  startDate: Scalars['DateTime']['input'];
  type: RLocationType;
};

export type RMe = {
  __typename?: 'Me';
  acceptedExtraTerms: Scalars['Boolean']['output'];
  acceptedGdpr: Scalars['Boolean']['output'];
  acceptedTos: Scalars['Boolean']['output'];
  email: Scalars['String']['output'];
  firstname?: Maybe<Scalars['String']['output']>;
  hasPassword: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  isDebugging: Scalars['Boolean']['output'];
  language?: Maybe<Scalars['String']['output']>;
  lastname?: Maybe<Scalars['String']['output']>;
  passwordExpiryDate?: Maybe<Scalars['DateTime']['output']>;
  permissions: RMePermissions;
  profileImage?: Maybe<Scalars['String']['output']>;
  realm: Scalars['String']['output'];
  status: RUserStatus;
  username?: Maybe<Scalars['String']['output']>;
};

export type RMePermissions = {
  __typename?: 'MePermissions';
  hasViewAccessToAdminPanel: Scalars['Boolean']['output'];
  hasViewAccessToManagerDashboard: Scalars['Boolean']['output'];
  hasWriteAccessToInternals: Scalars['Boolean']['output'];
};

export type RModule = {
  __typename?: 'Module';
  created?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  learningId?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  pages?: Maybe<Array<Maybe<RPage>>>;
  type?: Maybe<Scalars['String']['output']>;
  updated?: Maybe<Scalars['String']['output']>;
};

export type RModuleInput = {
  created?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  learningId?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  pages?: InputMaybe<Array<InputMaybe<RPageInput>>>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated?: InputMaybe<Scalars['String']['input']>;
};

export type RModuleV2 = {
  __typename?: 'ModuleV2';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  learningId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  pages: Array<RPageV2>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  urn: Scalars['String']['output'];
};

export type RModuleV2Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  learningId: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  order: Scalars['Int']['input'];
  pages: Array<RPageV2Input>;
};

/** mutation root */
export type RMutation = {
  __typename?: 'Mutation';
  _blank?: Maybe<Scalars['Boolean']['output']>;
  acceptExtraTerms?: Maybe<Scalars['ID']['output']>;
  acceptGdpr?: Maybe<Scalars['ID']['output']>;
  acceptTos?: Maybe<Scalars['ID']['output']>;
  addAssignmentUserSurvey: RAddAssignmentUserSurveyResult;
  addSelfTrackedLearningToLearningLoop: RAddSelfTrackedLearningToLearningLoopResult;
  addUsersToLearningJourneyCohort: RLearningJourneySessionResult;
  applyOrderRules?: Maybe<RUpdateResponse>;
  approveLicense?: Maybe<Scalars['Boolean']['output']>;
  archiveLearning?: Maybe<RLearning>;
  cancel?: Maybe<RReturnData>;
  changePassword?: Maybe<Scalars['ID']['output']>;
  cloneLearning: RLearningV2;
  completeLearning: Scalars['Boolean']['output'];
  completeLearningPath?: Maybe<Scalars['Boolean']['output']>;
  completeObject?: Maybe<Scalars['ID']['output']>;
  createAssignmentFromTemplate: RAssignmentsV3Result;
  createCollection?: Maybe<RLearningCollection>;
  createCompetence: RCompetenceResult;
  createLearningJourney: RLearningJourneyResult;
  createLearningJourneyFromTemplate: RLearningJourneyResult;
  createLearningJourneyLiveEvent: RLearningV2;
  createLearningJourneyPracticalAssessments: Array<RLearningV2>;
  /** @deprecated survey creation is disabled */
  createLearningJourneySurveys: Array<RLearningV2>;
  createLearningLoopReflection: RCreateLearningLoopReflectionResult;
  createOrUpdate?: Maybe<RProduct>;
  createPost?: Maybe<RPost>;
  createSection?: Maybe<RSection>;
  createSelfTrackedLearning: RSelfTrackedLearningResult;
  createSpace: RSpaceV2Result;
  createSpaceRoleAssignment: RSpaceV2RoleAssignResultDto;
  createUserLearningSubmission: RUserLearningSubmissionResult;
  createUserLearningSubmissionReview: RUserLearningSubmissionReviewResult;
  createUsers?: Maybe<Array<Maybe<RCreateUsersResponse>>>;
  createVariant?: Maybe<RVariant>;
  deleteAssignmentV3: RAssignmentsV3Result;
  deleteEnrollment: Scalars['Boolean']['output'];
  deleteFeatureToggle: RFeatureToggle;
  deleteLearning?: Maybe<RLearning>;
  deleteLearningJourneys: Array<RLearningJourney>;
  deletePost?: Maybe<RPost>;
  deleteSpaceRoleAssignment: RSpaceV2RoleAssignResultDto;
  deleteXAPIState?: Maybe<Scalars['ID']['output']>;
  /** delete data from the table: "clsapi.learning" */
  delete_clsapi_learning?: Maybe<RClsapi_Learning_Mutation_Response>;
  /** delete single row from the table: "clsapi.learning" */
  delete_clsapi_learning_by_pk?: Maybe<RClsapi_Learning>;
  /** delete data from the table: "clsapi.learning_journeys" */
  delete_clsapi_learning_journeys?: Maybe<RClsapi_Learning_Journeys_Mutation_Response>;
  /** delete single row from the table: "clsapi.learning_journeys" */
  delete_clsapi_learning_journeys_by_pk?: Maybe<RClsapi_Learning_Journeys>;
  disableFeatureToggleForRealm: RFeatureToggleRealm;
  disableFeatureToggleForUser: RFeatureToggleUser;
  enableFeatureToggleForRealm: RFeatureToggleRealm;
  enableFeatureToggleForUser: RFeatureToggleUser;
  enrollSelf: REnrollment;
  enrollmentCreateByEmails: Array<REnrollment>;
  importLearningContentToSpace: RImportLearningContentToSpaceResult;
  /** insert data into the table: "clsapi.learning" */
  insert_clsapi_learning?: Maybe<RClsapi_Learning_Mutation_Response>;
  /** insert data into the table: "clsapi.learning_journeys" */
  insert_clsapi_learning_journeys?: Maybe<RClsapi_Learning_Journeys_Mutation_Response>;
  /** insert a single row into the table: "clsapi.learning_journeys" */
  insert_clsapi_learning_journeys_one?: Maybe<RClsapi_Learning_Journeys>;
  /** insert a single row into the table: "clsapi.learning" */
  insert_clsapi_learning_one?: Maybe<RClsapi_Learning>;
  joinLearningJourneyCohort: RLearningJourneySessionResult;
  learningPinnedUpdate: Array<RLearningPinned>;
  markAllNotificationsAsSeen?: Maybe<Array<Maybe<RBabelonNotification>>>;
  markNotificationAsSeen?: Maybe<Array<Maybe<RBabelonNotification>>>;
  migrateOnDemandLearningToCourses: RLearningV2;
  purchase?: Maybe<RPurchaseResponse>;
  registerToLearning: RXapiSession;
  removeSelfTrackedLearning: RSelfTrackedLearningResult;
  removeUsersFromLearningJourneyCohort: RLearningJourneySessionResult;
  removeVariant?: Maybe<RVariantResponse>;
  requestLicense?: Maybe<Scalars['Boolean']['output']>;
  restoreLearning?: Maybe<RLearning>;
  revokeLicense?: Maybe<Scalars['Boolean']['output']>;
  sadminPurchase?: Maybe<RSadminPurchaseResponse>;
  saveLearning?: Maybe<RLearning>;
  setBulkEnrollmentAttendance: Array<REnrollment>;
  setEnrollmentAttendance: REnrollment;
  setLanguage?: Maybe<Scalars['ID']['output']>;
  setProgressPerCourseByUser?: Maybe<Scalars['Boolean']['output']>;
  setUserStatus?: Maybe<Scalars['ID']['output']>;
  setUsername?: Maybe<Scalars['ID']['output']>;
  submitLearningActivityQuizAnswers: RLearningActivityDto;
  teamCreate: RTeam;
  teamDelete: Scalars['Boolean']['output'];
  teamUpdate: RTeam;
  teamUserChangeRole: RTeamUser;
  teamUserRemove: Scalars['Boolean']['output'];
  teamUsersAdd: Array<RTeamUser>;
  toggleAction?: Maybe<RPost>;
  unlockUserLearningJourneyItem: RLearningJourneyResult;
  updateCollection?: Maybe<RLearningCollection>;
  updateCollectionItems?: Maybe<RLearningCollection>;
  updateCompetence: RCompetenceResult;
  updateLearningJourney: RLearningJourneyResult;
  updateMeta?: Maybe<RUserMeta>;
  updateOrder?: Maybe<RUpdateResponse>;
  updatePost?: Maybe<RPost>;
  updateSection?: Maybe<RSection>;
  updateSelfTrackedLearning: RSelfTrackedLearningResult;
  updateSpace: RSpaceV2Result;
  updateSpaceRoleAssignment: RSpaceV2RoleAssignResultDto;
  updateUser: RUserV2;
  updateVariant?: Maybe<RVariant>;
  /** update data of the table: "clsapi.learning" */
  update_clsapi_learning?: Maybe<RClsapi_Learning_Mutation_Response>;
  /** update single row of the table: "clsapi.learning" */
  update_clsapi_learning_by_pk?: Maybe<RClsapi_Learning>;
  /** update data of the table: "clsapi.learning_journeys" */
  update_clsapi_learning_journeys?: Maybe<RClsapi_Learning_Journeys_Mutation_Response>;
  /** update single row of the table: "clsapi.learning_journeys" */
  update_clsapi_learning_journeys_by_pk?: Maybe<RClsapi_Learning_Journeys>;
  /** update multiples rows of table: "clsapi.learning_journeys" */
  update_clsapi_learning_journeys_many?: Maybe<Array<Maybe<RClsapi_Learning_Journeys_Mutation_Response>>>;
  /** update multiples rows of table: "clsapi.learning" */
  update_clsapi_learning_many?: Maybe<Array<Maybe<RClsapi_Learning_Mutation_Response>>>;
  uploadProfileImage: RFile;
  upsertAssignmentV3: RAssignmentsV3Result;
  upsertFeatureToggle: RFeatureToggle;
  upsertLearning: RLearningV2;
  upsertLearningJourneyCohorts: RLearningJourneySessionResult;
  upsertLearningJourneyCompetences: RLearningJourneyResult;
  upsertLearningJourneyItems: RLearningJourneyResult;
  userChangeRealmRole: RUserV2;
  userCompleteTour: RTourUserCompletions;
  voidObject?: Maybe<Scalars['ID']['output']>;
};

/** mutation root */
export type RMutationAddAssignmentUserSurveyArgs = {
  input: RAssignmentUserSurveyInput;
};

/** mutation root */
export type RMutationAddSelfTrackedLearningToLearningLoopArgs = {
  input: RAddSelfTrackedLearningToLearningLoopInput;
};

/** mutation root */
export type RMutationAddUsersToLearningJourneyCohortArgs = {
  journeyId: Scalars['Int']['input'];
  userIds: Array<Scalars['Int']['input']>;
};

/** mutation root */
export type RMutationApplyOrderRulesArgs = {
  orderId: Scalars['Int']['input'];
};

/** mutation root */
export type RMutationApproveLicenseArgs = {
  licenseId?: InputMaybe<Scalars['Int']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  realm?: InputMaybe<Scalars['String']['input']>;
};

/** mutation root */
export type RMutationArchiveLearningArgs = {
  learningId: Scalars['Int']['input'];
};

/** mutation root */
export type RMutationCancelArgs = {
  orderId: Scalars['Int']['input'];
};

/** mutation root */
export type RMutationCloneLearningArgs = {
  input: RCloneLearningInput;
};

/** mutation root */
export type RMutationCompleteLearningArgs = {
  learningId: Scalars['Int']['input'];
};

/** mutation root */
export type RMutationCompleteLearningPathArgs = {
  learningPathId: Scalars['Int']['input'];
};

/** mutation root */
export type RMutationCompleteObjectArgs = {
  objectId: Scalars['String']['input'];
  registration: Scalars['String']['input'];
  targetUserId: Scalars['Int']['input'];
};

/** mutation root */
export type RMutationCreateAssignmentFromTemplateArgs = {
  input: RCreateAssignmentFromTemplateInput;
};

/** mutation root */
export type RMutationCreateCollectionArgs = {
  payload: RCreateCollection;
};

/** mutation root */
export type RMutationCreateCompetenceArgs = {
  input: RCreateCompetenceInput;
};

/** mutation root */
export type RMutationCreateLearningJourneyArgs = {
  input: RCreateLearningJourneyInput;
};

/** mutation root */
export type RMutationCreateLearningJourneyFromTemplateArgs = {
  input: RCreateLearningJourneyFromTemplateInput;
};

/** mutation root */
export type RMutationCreateLearningJourneyLiveEventArgs = {
  input: RCreateLearningJourneyLiveEventInput;
};

/** mutation root */
export type RMutationCreateLearningJourneyPracticalAssessmentsArgs = {
  input: RCreateLearningJourneyPracticalAssessmentsInput;
};

/** mutation root */
export type RMutationCreateLearningJourneySurveysArgs = {
  input: RCreateLearningJourneySurveysInput;
};

/** mutation root */
export type RMutationCreateLearningLoopReflectionArgs = {
  input: RCreateLearningLoopReflectionInput;
};

/** mutation root */
export type RMutationCreateOrUpdateArgs = {
  product: RProductInput;
};

/** mutation root */
export type RMutationCreatePostArgs = {
  path: Array<Scalars['String']['input']>;
  payload?: InputMaybe<RPostInput>;
};

/** mutation root */
export type RMutationCreateSectionArgs = {
  payload?: InputMaybe<RCreateSectionInput>;
};

/** mutation root */
export type RMutationCreateSelfTrackedLearningArgs = {
  input: RCreateSelfTrackedLearningInput;
};

/** mutation root */
export type RMutationCreateSpaceArgs = {
  input: RCreateSpaceV2Input;
};

/** mutation root */
export type RMutationCreateSpaceRoleAssignmentArgs = {
  input: RCreateSpaceV2RoleAssignInput;
};

/** mutation root */
export type RMutationCreateUserLearningSubmissionArgs = {
  input: RCreateUserLearningSubmissionInput;
};

/** mutation root */
export type RMutationCreateUserLearningSubmissionReviewArgs = {
  input: RCreateUserLearningSubmissionReviewInput;
};

/** mutation root */
export type RMutationCreateUsersArgs = {
  payload?: InputMaybe<RCreateUserInput>;
};

/** mutation root */
export type RMutationCreateVariantArgs = {
  productId: Scalars['Int']['input'];
  variant: RVariantInput;
};

/** mutation root */
export type RMutationDeleteAssignmentV3Args = {
  id: Scalars['Int']['input'];
};

/** mutation root */
export type RMutationDeleteEnrollmentArgs = {
  input: RDeleteEnrollmentInput;
};

/** mutation root */
export type RMutationDeleteFeatureToggleArgs = {
  id: Scalars['Int']['input'];
};

/** mutation root */
export type RMutationDeleteLearningArgs = {
  learningId: Scalars['Int']['input'];
};

/** mutation root */
export type RMutationDeleteLearningJourneysArgs = {
  journeyIds: Array<Scalars['Int']['input']>;
};

/** mutation root */
export type RMutationDeletePostArgs = {
  path: Array<Scalars['String']['input']>;
  postId?: InputMaybe<Scalars['Int']['input']>;
};

/** mutation root */
export type RMutationDeleteSpaceRoleAssignmentArgs = {
  input: Scalars['Int']['input'];
};

/** mutation root */
export type RMutationDeleteXapiStateArgs = {
  objectId: Scalars['String']['input'];
  registration: Scalars['String']['input'];
  targetUserId: Scalars['Int']['input'];
};

/** mutation root */
export type RMutationDelete_Clsapi_LearningArgs = {
  where: RClsapi_Learning_Bool_Exp;
};

/** mutation root */
export type RMutationDelete_Clsapi_Learning_By_PkArgs = {
  id: Scalars['Int']['input'];
};

/** mutation root */
export type RMutationDelete_Clsapi_Learning_JourneysArgs = {
  where: RClsapi_Learning_Journeys_Bool_Exp;
};

/** mutation root */
export type RMutationDelete_Clsapi_Learning_Journeys_By_PkArgs = {
  id: Scalars['Int']['input'];
};

/** mutation root */
export type RMutationDisableFeatureToggleForRealmArgs = {
  input: RFeatureToggleEnableRealmInput;
};

/** mutation root */
export type RMutationDisableFeatureToggleForUserArgs = {
  input: RFeatureToggleEnableUserInput;
};

/** mutation root */
export type RMutationEnableFeatureToggleForRealmArgs = {
  input: RFeatureToggleEnableRealmInput;
};

/** mutation root */
export type RMutationEnableFeatureToggleForUserArgs = {
  input: RFeatureToggleEnableUserInput;
};

/** mutation root */
export type RMutationEnrollSelfArgs = {
  input: REnrollSelfInput;
};

/** mutation root */
export type RMutationEnrollmentCreateByEmailsArgs = {
  input: REnrollByEmailInput;
};

/** mutation root */
export type RMutationImportLearningContentToSpaceArgs = {
  input: RImportLearningContentToSpaceInput;
};

/** mutation root */
export type RMutationInsert_Clsapi_LearningArgs = {
  objects: Array<RClsapi_Learning_Insert_Input>;
  on_conflict?: InputMaybe<RClsapi_Learning_On_Conflict>;
};

/** mutation root */
export type RMutationInsert_Clsapi_Learning_JourneysArgs = {
  objects: Array<RClsapi_Learning_Journeys_Insert_Input>;
  on_conflict?: InputMaybe<RClsapi_Learning_Journeys_On_Conflict>;
};

/** mutation root */
export type RMutationInsert_Clsapi_Learning_Journeys_OneArgs = {
  object: RClsapi_Learning_Journeys_Insert_Input;
  on_conflict?: InputMaybe<RClsapi_Learning_Journeys_On_Conflict>;
};

/** mutation root */
export type RMutationInsert_Clsapi_Learning_OneArgs = {
  object: RClsapi_Learning_Insert_Input;
  on_conflict?: InputMaybe<RClsapi_Learning_On_Conflict>;
};

/** mutation root */
export type RMutationJoinLearningJourneyCohortArgs = {
  sessionId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};

/** mutation root */
export type RMutationLearningPinnedUpdateArgs = {
  input: Array<RLearningPinnedUpdateInput>;
  realm: Scalars['String']['input'];
};

/** mutation root */
export type RMutationMarkNotificationAsSeenArgs = {
  postId: Scalars['Int']['input'];
};

/** mutation root */
export type RMutationMigrateOnDemandLearningToCoursesArgs = {
  learningId: Scalars['Int']['input'];
};

/** mutation root */
export type RMutationPurchaseArgs = {
  note?: InputMaybe<Scalars['String']['input']>;
  products: Array<InputMaybe<RPurchaseProduct>>;
};

/** mutation root */
export type RMutationRegisterToLearningArgs = {
  learningId: Scalars['Int']['input'];
};

/** mutation root */
export type RMutationRemoveSelfTrackedLearningArgs = {
  id: Scalars['Int']['input'];
};

/** mutation root */
export type RMutationRemoveUsersFromLearningJourneyCohortArgs = {
  journeyId: Scalars['Int']['input'];
  userIds: Array<Scalars['Int']['input']>;
};

/** mutation root */
export type RMutationRemoveVariantArgs = {
  productId: Scalars['Int']['input'];
  variantId: Scalars['Int']['input'];
};

/** mutation root */
export type RMutationRequestLicenseArgs = {
  provider?: InputMaybe<Scalars['String']['input']>;
  realm?: InputMaybe<Scalars['String']['input']>;
};

/** mutation root */
export type RMutationRestoreLearningArgs = {
  learningId: Scalars['Int']['input'];
};

/** mutation root */
export type RMutationRevokeLicenseArgs = {
  licenseId?: InputMaybe<Scalars['Int']['input']>;
};

/** mutation root */
export type RMutationSadminPurchaseArgs = {
  emails: Array<InputMaybe<Scalars['String']['input']>>;
  note?: InputMaybe<Scalars['String']['input']>;
  product: RSadminPurchaseProduct;
};

/** mutation root */
export type RMutationSaveLearningArgs = {
  payload: RLearningInput;
};

/** mutation root */
export type RMutationSetBulkEnrollmentAttendanceArgs = {
  input: Array<RSetEnrollmentAttendanceInput>;
};

/** mutation root */
export type RMutationSetEnrollmentAttendanceArgs = {
  input: RSetEnrollmentAttendanceInput;
};

/** mutation root */
export type RMutationSetLanguageArgs = {
  payload?: InputMaybe<RSetLanguageInput>;
};

/** mutation root */
export type RMutationSetProgressPerCourseByUserArgs = {
  learningId: Scalars['String']['input'];
};

/** mutation root */
export type RMutationSetUserStatusArgs = {
  payload?: InputMaybe<RSetUserStatusInput>;
};

/** mutation root */
export type RMutationSetUsernameArgs = {
  payload?: InputMaybe<RSetUsernameInput>;
};

/** mutation root */
export type RMutationSubmitLearningActivityQuizAnswersArgs = {
  input: RUserLearningActivityQuizSubmissionInput;
};

/** mutation root */
export type RMutationTeamCreateArgs = {
  input: RTeamCreateInput;
};

/** mutation root */
export type RMutationTeamDeleteArgs = {
  id: Scalars['Int']['input'];
};

/** mutation root */
export type RMutationTeamUpdateArgs = {
  input: RTeamUpdateInput;
};

/** mutation root */
export type RMutationTeamUserChangeRoleArgs = {
  input: RTeamUserChangeRoleInput;
};

/** mutation root */
export type RMutationTeamUserRemoveArgs = {
  input: RTeamUserRemoveInput;
};

/** mutation root */
export type RMutationTeamUsersAddArgs = {
  input: Array<RTeamUsersAddInput>;
};

/** mutation root */
export type RMutationToggleActionArgs = {
  action?: InputMaybe<Scalars['String']['input']>;
  path: Array<Scalars['String']['input']>;
  payload?: InputMaybe<RPostActionInput>;
  postId?: InputMaybe<Scalars['Int']['input']>;
};

/** mutation root */
export type RMutationUnlockUserLearningJourneyItemArgs = {
  itemURN: Scalars['URN']['input'];
  learningJourneyId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};

/** mutation root */
export type RMutationUpdateCollectionArgs = {
  payload: RUpdateCollection;
};

/** mutation root */
export type RMutationUpdateCollectionItemsArgs = {
  payload: RUpdateCollectionItems;
};

/** mutation root */
export type RMutationUpdateCompetenceArgs = {
  input: RUpdateCompetenceInput;
};

/** mutation root */
export type RMutationUpdateLearningJourneyArgs = {
  input: RUpdateLearningJourneyInput;
};

/** mutation root */
export type RMutationUpdateMetaArgs = {
  meta: Scalars['JSON']['input'];
  type: Scalars['String']['input'];
};

/** mutation root */
export type RMutationUpdateOrderArgs = {
  orderId: Scalars['Int']['input'];
  stateId: Scalars['Int']['input'];
};

/** mutation root */
export type RMutationUpdatePostArgs = {
  path: Array<Scalars['String']['input']>;
  payload?: InputMaybe<RPostInput>;
  postId?: InputMaybe<Scalars['Int']['input']>;
};

/** mutation root */
export type RMutationUpdateSectionArgs = {
  payload?: InputMaybe<RUpdateSectionInput>;
};

/** mutation root */
export type RMutationUpdateSelfTrackedLearningArgs = {
  input: RUpdateSelfTrackedLearningInput;
};

/** mutation root */
export type RMutationUpdateSpaceArgs = {
  input: RUpdateSpaceV2Input;
};

/** mutation root */
export type RMutationUpdateSpaceRoleAssignmentArgs = {
  input: RUpdateSpaceV2RoleAssignInput;
};

/** mutation root */
export type RMutationUpdateUserArgs = {
  input: RUserChangeInput;
};

/** mutation root */
export type RMutationUpdateVariantArgs = {
  productId: Scalars['Int']['input'];
  variant: RVariantInput;
};

/** mutation root */
export type RMutationUpdate_Clsapi_LearningArgs = {
  _inc?: InputMaybe<RClsapi_Learning_Inc_Input>;
  _set?: InputMaybe<RClsapi_Learning_Set_Input>;
  where: RClsapi_Learning_Bool_Exp;
};

/** mutation root */
export type RMutationUpdate_Clsapi_Learning_By_PkArgs = {
  _inc?: InputMaybe<RClsapi_Learning_Inc_Input>;
  _set?: InputMaybe<RClsapi_Learning_Set_Input>;
  pk_columns: RClsapi_Learning_Pk_Columns_Input;
};

/** mutation root */
export type RMutationUpdate_Clsapi_Learning_JourneysArgs = {
  _inc?: InputMaybe<RClsapi_Learning_Journeys_Inc_Input>;
  _set?: InputMaybe<RClsapi_Learning_Journeys_Set_Input>;
  where: RClsapi_Learning_Journeys_Bool_Exp;
};

/** mutation root */
export type RMutationUpdate_Clsapi_Learning_Journeys_By_PkArgs = {
  _inc?: InputMaybe<RClsapi_Learning_Journeys_Inc_Input>;
  _set?: InputMaybe<RClsapi_Learning_Journeys_Set_Input>;
  pk_columns: RClsapi_Learning_Journeys_Pk_Columns_Input;
};

/** mutation root */
export type RMutationUpdate_Clsapi_Learning_Journeys_ManyArgs = {
  updates: Array<RClsapi_Learning_Journeys_Updates>;
};

/** mutation root */
export type RMutationUpdate_Clsapi_Learning_ManyArgs = {
  updates: Array<RClsapi_Learning_Updates>;
};

/** mutation root */
export type RMutationUploadProfileImageArgs = {
  file: Scalars['Upload']['input'];
};

/** mutation root */
export type RMutationUpsertAssignmentV3Args = {
  input: RUpsertAssignmentInput;
};

/** mutation root */
export type RMutationUpsertFeatureToggleArgs = {
  input: RUpsertFeatureToggleInput;
};

/** mutation root */
export type RMutationUpsertLearningArgs = {
  input: RUpsertLearningInput;
};

/** mutation root */
export type RMutationUpsertLearningJourneyCohortsArgs = {
  input: RUpsertLearningJourneyCohortsInput;
};

/** mutation root */
export type RMutationUpsertLearningJourneyCompetencesArgs = {
  input: RUpsertLearningJourneyCompetencesInput;
};

/** mutation root */
export type RMutationUpsertLearningJourneyItemsArgs = {
  input: RUpsertLearningJourneyItemsInput;
};

/** mutation root */
export type RMutationUserChangeRealmRoleArgs = {
  input: RUserChangeRealmRoleInput;
};

/** mutation root */
export type RMutationUserCompleteTourArgs = {
  input: RCreateTourCompleteUserInput;
};

/** mutation root */
export type RMutationVoidObjectArgs = {
  objectId: Scalars['String']['input'];
  registration: Scalars['String']['input'];
  targetUserId: Scalars['Int']['input'];
};

export type RMutationResponse = {
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type RMyLocationEnrollment = {
  __typename?: 'MyLocationEnrollment';
  enrolled?: Maybe<Scalars['Boolean']['output']>;
  enrollmentStatus?: Maybe<RAttendedType>;
};

export type ROrder = {
  __typename?: 'Order';
  created?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  products?: Maybe<Array<Maybe<RProductRow>>>;
  rowHistory?: Maybe<Array<Maybe<ROrderRow>>>;
  stateName?: Maybe<Scalars['String']['output']>;
  user?: Maybe<RUser>;
};

export enum ROrderDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type ROrderHistory = {
  __typename?: 'OrderHistory';
  error?: Maybe<Scalars['String']['output']>;
  orderRowId?: Maybe<Scalars['String']['output']>;
  stateId?: Maybe<Scalars['String']['output']>;
  stateName?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<RUser>;
};

export type ROrderRow = {
  __typename?: 'OrderRow';
  product?: Maybe<RProductRow>;
  state?: Maybe<RState>;
  stateName?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<RUser>;
};

export type ROrderRowFlat = {
  __typename?: 'OrderRowFlat';
  created?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  orderId?: Maybe<Scalars['Int']['output']>;
  productId?: Maybe<Scalars['Int']['output']>;
  productVariantId?: Maybe<Scalars['Int']['output']>;
  products?: Maybe<RProductRow>;
  spaceId?: Maybe<Scalars['Int']['output']>;
  stateId?: Maybe<Scalars['Int']['output']>;
  stateName?: Maybe<Scalars['String']['output']>;
  user?: Maybe<RUser>;
  userId?: Maybe<Scalars['Int']['output']>;
};

export type ROrderSearch = {
  __typename?: 'OrderSearch';
  orders?: Maybe<Array<Maybe<ROrder>>>;
  total?: Maybe<Scalars['Int']['output']>;
  totalPages?: Maybe<Scalars['Int']['output']>;
};

export type ROrderSearchQuery = {
  emails?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  products?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  states?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ROrderSearchTotal = {
  __typename?: 'OrderSearchTotal';
  total?: Maybe<Scalars['Int']['output']>;
};

export type ROrigin = {
  __typename?: 'Origin';
  id: Scalars['URN']['output'];
  launchURL?: Maybe<RLaunchUrl>;
};

export type RPage = {
  __typename?: 'Page';
  blocks?: Maybe<Array<Maybe<RBlock>>>;
  created?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  moduleId?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated?: Maybe<Scalars['String']['output']>;
};

export type RPageInfo = {
  __typename?: 'PageInfo';
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  size: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type RPageInput = {
  blocks?: InputMaybe<Array<InputMaybe<RBlockInput>>>;
  created?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  moduleId?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated?: InputMaybe<Scalars['String']['input']>;
};

export type RPageV2 = {
  __typename?: 'PageV2';
  blocks: Array<RBlockV2>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  moduleId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  urn: Scalars['String']['output'];
};

export type RPageV2Input = {
  blocks: RBlockV2Input;
  id?: InputMaybe<Scalars['Int']['input']>;
  moduleId: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  order: Scalars['Int']['input'];
};

export type RPagination = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type RPaginationInput = {
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
};

export type RParticipant = {
  __typename?: 'Participant';
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  profileImage?: Maybe<Scalars['String']['output']>;
  realm?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type RParticipantsResult = {
  __typename?: 'ParticipantsResult';
  participants?: Maybe<Array<Maybe<RParticipant>>>;
  total?: Maybe<Scalars['Int']['output']>;
  totalPages?: Maybe<Scalars['Int']['output']>;
};

export type RParticipation = {
  __typename?: 'Participation';
  catalogObjectId: Scalars['String']['output'];
  completedAt?: Maybe<Scalars['Time']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  expiredAt?: Maybe<Scalars['Time']['output']>;
  firstActivity?: Maybe<Scalars['Time']['output']>;
  firstStatement: Scalars['Time']['output'];
  id: Scalars['Int']['output'];
  isCompleted: Scalars['Boolean']['output'];
  lastActivity?: Maybe<Scalars['Time']['output']>;
  lastStatement: Scalars['Time']['output'];
  objectId: Scalars['String']['output'];
  objectName?: Maybe<Scalars['String']['output']>;
  objectType?: Maybe<Scalars['String']['output']>;
  plannedDuration?: Maybe<Scalars['Int']['output']>;
  platform: Scalars['String']['output'];
  progress: Scalars['Float']['output'];
  registeredAt?: Maybe<Scalars['Time']['output']>;
  registration: Scalars['String']['output'];
  userEffort?: Maybe<Scalars['Int']['output']>;
  userId: Scalars['Int']['output'];
};

export type RParticipationAggregate = {
  __typename?: 'ParticipationAggregate';
  completed: Scalars['Int']['output'];
  completedNotExpired: Scalars['Int']['output'];
  effort: Scalars['Second']['output'];
  firstActivity?: Maybe<Scalars['Time']['output']>;
  lastActivity?: Maybe<Scalars['Time']['output']>;
  started: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type RParticipationFilterInput = {
  activityFrom?: InputMaybe<Scalars['Time']['input']>;
  activityTo?: InputMaybe<Scalars['Time']['input']>;
  objectIds?: InputMaybe<Array<Scalars['URN']['input']>>;
  platforms?: InputMaybe<Array<RPlatform>>;
};

export type RParticipationGroup = {
  __typename?: 'ParticipationGroup';
  catalogObjectId: Scalars['String']['output'];
  completedAt?: Maybe<Scalars['String']['output']>;
  expiredAt?: Maybe<Scalars['String']['output']>;
  firstActivity?: Maybe<Scalars['String']['output']>;
  firstStatement: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  isCompleted: Scalars['Boolean']['output'];
  lastActivity?: Maybe<Scalars['String']['output']>;
  lastStatement: Scalars['String']['output'];
  objectId: Scalars['String']['output'];
  objectName?: Maybe<Scalars['String']['output']>;
  objectType?: Maybe<Scalars['String']['output']>;
  plannedDuration?: Maybe<Scalars['Int']['output']>;
  platform: Scalars['String']['output'];
  previousParticipations?: Maybe<Array<RParticipation>>;
  progress: Scalars['Float']['output'];
  registration: Scalars['String']['output'];
  userEffort?: Maybe<Scalars['Int']['output']>;
  userId: Scalars['Int']['output'];
};

export type RParticipationResult = {
  __typename?: 'ParticipationResult';
  data: Array<RParticipation>;
  pageInfo: RPageInfo;
};

export enum RParticipationSortField {
  FirstActivity = 'FIRST_ACTIVITY',
  LastActivity = 'LAST_ACTIVITY',
  ObjectId = 'OBJECT_ID',
  Platform = 'PLATFORM',
}

export type RParticipationSortInput = {
  field: RParticipationSortField;
  order?: RSortOrder;
};

export type RParticipationV2 = {
  __typename?: 'ParticipationV2';
  catalogObjectId?: Maybe<Scalars['URN']['output']>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  endAt?: Maybe<Scalars['DateTime']['output']>;
  expiryAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isCompleted: Scalars['Boolean']['output'];
  lastActivity?: Maybe<Scalars['DateTime']['output']>;
  objectId: Scalars['URN']['output'];
  plannedDuration?: Maybe<Scalars['Int']['output']>;
  plannedLearningsCount?: Maybe<Scalars['Int']['output']>;
  progress: Scalars['Float']['output'];
  userEffort?: Maybe<Scalars['Int']['output']>;
  userId: Scalars['Int']['output'];
};

export type RParticipationsInput = {
  from?: InputMaybe<Scalars['String']['input']>;
  includeCatalogData?: InputMaybe<Scalars['Boolean']['input']>;
  isCompletedEq?: InputMaybe<Scalars['Boolean']['input']>;
  objectIdIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  objectTypeIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  objectTypeNin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['String']['input']>;
  userIdEq?: InputMaybe<Scalars['Int']['input']>;
  userTeamIdIn?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  usersPool?: InputMaybe<RUsersPool>;
};

export type RPeriodFilter = {
  from: Scalars['DateTime']['input'];
  to: Scalars['DateTime']['input'];
};

export type RPeriodInput = {
  from: Scalars['Time']['input'];
  to: Scalars['Time']['input'];
};

export type RPermissionsRole = {
  __typename?: 'PermissionsRole';
  admin?: Maybe<Scalars['Boolean']['output']>;
  guest?: Maybe<Scalars['Boolean']['output']>;
  labels?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  member?: Maybe<Scalars['Boolean']['output']>;
  moderator?: Maybe<Scalars['Boolean']['output']>;
  path?: Maybe<Scalars['String']['output']>;
  permissions?: Maybe<RSectionPermissions>;
};

export enum RPlatform {
  Collegial = 'collegial',
  Coursera = 'coursera',
  Finanskompetens = 'finanskompetens',
  Learnifier = 'learnifier',
  Linkedin = 'linkedin',
  Lumesse = 'lumesse',
  Pluralsight = 'pluralsight',
}

export type RPost = {
  __typename?: 'Post';
  ancestors?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  content?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['String']['output']>;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  depth?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  locked?: Maybe<Scalars['Boolean']['output']>;
  media?: Maybe<RPostMedia>;
  path?: Maybe<Scalars['String']['output']>;
  permissions?: Maybe<RPostPermissions>;
  publishDate?: Maybe<Scalars['String']['output']>;
  replies?: Maybe<Array<Maybe<RPost>>>;
  section?: Maybe<RSection>;
  stats?: Maybe<RStats>;
  sticky?: Maybe<Scalars['Boolean']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated?: Maybe<Scalars['String']['output']>;
  user?: Maybe<RPostUser>;
  you?: Maybe<RYou>;
};

export type RPostActionInput = {
  scalar?: InputMaybe<Scalars['Int']['input']>;
};

export type RPostInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  locked?: InputMaybe<Scalars['Boolean']['input']>;
  media?: InputMaybe<RPostMediaInput>;
  originProductId?: InputMaybe<Scalars['Int']['input']>;
  pid?: InputMaybe<Scalars['Int']['input']>;
  propagates?: InputMaybe<Scalars['Boolean']['input']>;
  publishDate?: InputMaybe<Scalars['String']['input']>;
  shouldSendNotification?: InputMaybe<Scalars['Boolean']['input']>;
  sticky?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type RPostMedia = {
  __typename?: 'PostMedia';
  type?: Maybe<RPostMediaType>;
  url?: Maybe<Scalars['String']['output']>;
};

export type RPostMediaInput = {
  type?: InputMaybe<RPostMediaType>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export enum RPostMediaType {
  Image = 'image',
  Resource = 'resource',
  Video = 'video',
}

export type RPostPermissions = {
  __typename?: 'PostPermissions';
  canDelete?: Maybe<Scalars['Boolean']['output']>;
  canEdit?: Maybe<Scalars['Boolean']['output']>;
  canLock?: Maybe<Scalars['Boolean']['output']>;
  canPurge?: Maybe<Scalars['Boolean']['output']>;
  canReply?: Maybe<Scalars['Boolean']['output']>;
  canRestore?: Maybe<Scalars['Boolean']['output']>;
  canSticky?: Maybe<Scalars['Boolean']['output']>;
  canUnlock?: Maybe<Scalars['Boolean']['output']>;
  canUnsticky?: Maybe<Scalars['Boolean']['output']>;
  canViewReflectionReplies?: Maybe<Scalars['Boolean']['output']>;
};

export type RPostUser = {
  __typename?: 'PostUser';
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  profileImage?: Maybe<Scalars['String']['output']>;
  realm?: Maybe<Scalars['String']['output']>;
  role?: Maybe<RPostUserRole>;
  username?: Maybe<Scalars['String']['output']>;
};

export type RPostUserRole = {
  __typename?: 'PostUserRole';
  labels?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type RPostsMeta = {
  __typename?: 'PostsMeta';
  items?: Maybe<Array<Maybe<RPost>>>;
  lastUpdate?: Maybe<Scalars['String']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  pages?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type RProduct = {
  __typename?: 'Product';
  contentOwner?: Maybe<Scalars['Int']['output']>;
  costCenter?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<Scalars['Int']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  flags?: Maybe<RProductFlags>;
  id?: Maybe<Scalars['Int']['output']>;
  isPending?: Maybe<Scalars['Boolean']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  learnifierCourseName?: Maybe<Scalars['String']['output']>;
  learningContentUrnV2?: Maybe<Scalars['URNV2']['output']>;
  legalEntity?: Maybe<Scalars['String']['output']>;
  meta?: Maybe<RProductMeta>;
  name?: Maybe<Scalars['String']['output']>;
  orderId?: Maybe<Scalars['Int']['output']>;
  parents: Array<RLearningContent>;
  price?: Maybe<Scalars['Int']['output']>;
  productVariantId?: Maybe<Scalars['Int']['output']>;
  rowId?: Maybe<Scalars['Int']['output']>;
  services?: Maybe<Array<Maybe<RService>>>;
  space?: Maybe<RSpace>;
  state?: Maybe<RState>;
  status?: Maybe<RLearningStatus>;
  stocks?: Maybe<Array<Maybe<RStock>>>;
  subjects?: Maybe<Array<Maybe<RLearningSubject>>>;
  timestamp?: Maybe<Scalars['String']['output']>;
  variants?: Maybe<Array<Maybe<RVariant>>>;
};

export type RProductParentsArgs = {
  filter: RProductParentsFilter;
};

export type RProductCard = RCourseCard & {
  __typename?: 'ProductCard';
  archived?: Maybe<Scalars['Boolean']['output']>;
  attributes?: Maybe<Scalars['JSON']['output']>;
  available?: Maybe<Scalars['Boolean']['output']>;
  canEdit?: Maybe<Scalars['Boolean']['output']>;
  customLink?: Maybe<Scalars['String']['output']>;
  entity: Scalars['String']['output'];
  entityId: Scalars['Int']['output'];
  featured?: Maybe<Scalars['Boolean']['output']>;
  format?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  isApprovalRequired?: Maybe<Scalars['Boolean']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  languageId?: Maybe<Scalars['Int']['output']>;
  level?: Maybe<Scalars['String']['output']>;
  objectId?: Maybe<Scalars['String']['output']>;
  participation?: Maybe<RParticipation>;
  provider?: Maybe<Scalars['String']['output']>;
  spaceId?: Maybe<Scalars['Int']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  subjects?: Maybe<Array<Maybe<RLearningSubject>>>;
  teaser?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  totalEffort?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  visibility?: Maybe<RLearningVisibility>;
};

export type RProductFlags = {
  __typename?: 'ProductFlags';
  approvalRequired?: Maybe<Scalars['Boolean']['output']>;
  available?: Maybe<Scalars['Boolean']['output']>;
  canEdit?: Maybe<Scalars['Boolean']['output']>;
  enrollmentDisabled?: Maybe<Scalars['Boolean']['output']>;
  featured?: Maybe<Scalars['Boolean']['output']>;
  globalMandatoryTraining?: Maybe<Scalars['Boolean']['output']>;
  hasTeams?: Maybe<Scalars['Boolean']['output']>;
  reflectionsEnabled?: Maybe<Scalars['Boolean']['output']>;
  requiresApplication?: Maybe<Scalars['Boolean']['output']>;
  socialFeaturesEnabled?: Maybe<Scalars['Boolean']['output']>;
};

export type RProductFlagsInput = {
  approvalRequired?: InputMaybe<Scalars['Boolean']['input']>;
  available?: InputMaybe<Scalars['Boolean']['input']>;
  canEdit?: InputMaybe<Scalars['Boolean']['input']>;
  enrollmentDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  featured?: InputMaybe<Scalars['Boolean']['input']>;
  globalMandatoryTraining?: InputMaybe<Scalars['Boolean']['input']>;
  hasTeams?: InputMaybe<Scalars['Boolean']['input']>;
  reflectionsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  requiresApplication?: InputMaybe<Scalars['Boolean']['input']>;
  socialFeaturesEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RProductInput = {
  contentOwner?: InputMaybe<Scalars['Int']['input']>;
  costCenter?: InputMaybe<Scalars['String']['input']>;
  creator?: InputMaybe<Scalars['Int']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  flags?: InputMaybe<RProductFlagsInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  learnifierCourseName?: InputMaybe<Scalars['String']['input']>;
  legalEntity?: InputMaybe<Scalars['String']['input']>;
  meta?: InputMaybe<RProductMetaInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  orderId?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['Int']['input']>;
  rowId?: InputMaybe<Scalars['Int']['input']>;
  services?: InputMaybe<Array<InputMaybe<RServiceInput>>>;
  space?: InputMaybe<RSpaceInput>;
  state?: InputMaybe<RStateInput>;
  status?: InputMaybe<RLearningStatus>;
  stocks?: InputMaybe<Array<InputMaybe<RStockInput>>>;
  subjectIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  timestamp?: InputMaybe<Scalars['String']['input']>;
  variants?: InputMaybe<Array<InputMaybe<RVariantInput>>>;
};

export type RProductLabel = {
  __typename?: 'ProductLabel';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type RProductMeta = {
  __typename?: 'ProductMeta';
  ATTRIBUTES?: Maybe<Scalars['String']['output']>;
  CONFIRM_MESSAGE?: Maybe<Scalars['String']['output']>;
  CONNECTIONS?: Maybe<Scalars['String']['output']>;
  CUSTOM_LINK?: Maybe<Scalars['String']['output']>;
  DESCRIPTION?: Maybe<Scalars['String']['output']>;
  DISCUSSION_SECTION?: Maybe<Scalars['String']['output']>;
  EFFORT?: Maybe<Scalars['String']['output']>;
  ENROLL_BUTTON_TEXT?: Maybe<Scalars['String']['output']>;
  FORMAT?: Maybe<Scalars['String']['output']>;
  IMAGE?: Maybe<Scalars['String']['output']>;
  INSTITUTION?: Maybe<Scalars['String']['output']>;
  INSTRUCTORS?: Maybe<Scalars['String']['output']>;
  LANGUAGE?: Maybe<Scalars['String']['output']>;
  LENGTH?: Maybe<Scalars['String']['output']>;
  LEVEL?: Maybe<Scalars['String']['output']>;
  LOCATION?: Maybe<Scalars['String']['output']>;
  METHOD?: Maybe<Scalars['String']['output']>;
  OBJECT_ID?: Maybe<Scalars['String']['output']>;
  PROVIDER?: Maybe<Scalars['String']['output']>;
  PROVIDER_ACCESS?: Maybe<Scalars['String']['output']>;
  REFLECTION_SECTION?: Maybe<Scalars['String']['output']>;
  TEAMS?: Maybe<Scalars['String']['output']>;
  TEAMSIZE?: Maybe<Scalars['String']['output']>;
  TEASER?: Maybe<Scalars['String']['output']>;
  TOTAL_EFFORT?: Maybe<Scalars['String']['output']>;
};

export type RProductMetaInput = {
  ATTRIBUTES?: InputMaybe<Scalars['String']['input']>;
  CATEGORY?: InputMaybe<Scalars['String']['input']>;
  CONFIRM_MESSAGE?: InputMaybe<Scalars['String']['input']>;
  CONNECTIONS?: InputMaybe<Scalars['String']['input']>;
  CUSTOM_LINK?: InputMaybe<Scalars['String']['input']>;
  DESCRIPTION?: InputMaybe<Scalars['String']['input']>;
  DISCUSSION_SECTION?: InputMaybe<Scalars['String']['input']>;
  EFFORT?: InputMaybe<Scalars['String']['input']>;
  ENROLL_BUTTON_TEXT?: InputMaybe<Scalars['String']['input']>;
  FORMAT?: InputMaybe<Scalars['String']['input']>;
  IMAGE?: InputMaybe<Scalars['String']['input']>;
  INSTITUTION?: InputMaybe<Scalars['String']['input']>;
  INSTRUCTORS?: InputMaybe<Scalars['String']['input']>;
  LANGUAGE?: InputMaybe<Scalars['String']['input']>;
  LENGTH?: InputMaybe<Scalars['String']['input']>;
  LEVEL?: InputMaybe<Scalars['String']['input']>;
  LOCATION?: InputMaybe<Scalars['String']['input']>;
  METHOD?: InputMaybe<Scalars['String']['input']>;
  OBJECT_ID?: InputMaybe<Scalars['String']['input']>;
  PROVIDER?: InputMaybe<Scalars['String']['input']>;
  PROVIDER_ACCESS?: InputMaybe<Scalars['String']['input']>;
  REFLECTION_SECTION?: InputMaybe<Scalars['String']['input']>;
  SUBJECT?: InputMaybe<Scalars['String']['input']>;
  TEAMS?: InputMaybe<Scalars['String']['input']>;
  TEAMSIZE?: InputMaybe<Scalars['String']['input']>;
  TEASER?: InputMaybe<Scalars['String']['input']>;
  TOTAL_EFFORT?: InputMaybe<Scalars['String']['input']>;
};

export type RProductOrder = {
  __typename?: 'ProductOrder';
  created?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  products?: Maybe<Array<Maybe<RProductRow>>>;
  rows?: Maybe<Array<Maybe<RProductOrderRow>>>;
  user?: Maybe<RProductOrderUser>;
};

export type RProductOrderRow = {
  __typename?: 'ProductOrderRow';
  active?: Maybe<Scalars['Boolean']['output']>;
  created?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['Int']['output']>;
  orderId?: Maybe<Scalars['Int']['output']>;
  product?: Maybe<RProductOrderRowProduct>;
  quantity?: Maybe<Scalars['Int']['output']>;
  state?: Maybe<RProductOrderState>;
};

export type RProductOrderRowProduct = {
  __typename?: 'ProductOrderRowProduct';
  id?: Maybe<Scalars['Int']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  variant?: Maybe<RVariant>;
};

export type RProductOrderState = {
  __typename?: 'ProductOrderState';
  drawStock?: Maybe<Scalars['Boolean']['output']>;
  endState?: Maybe<Scalars['Boolean']['output']>;
  entitlement?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type RProductOrderUser = {
  __typename?: 'ProductOrderUser';
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  realm?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type RProductOrdersFlat = {
  __typename?: 'ProductOrdersFlat';
  email?: Maybe<Scalars['String']['output']>;
  orderId?: Maybe<Scalars['Int']['output']>;
  productId?: Maybe<Scalars['Int']['output']>;
  productVariantId?: Maybe<Scalars['Int']['output']>;
  profileImage?: Maybe<Scalars['String']['output']>;
  realm?: Maybe<Scalars['String']['output']>;
  spaceId?: Maybe<Scalars['Int']['output']>;
  stateId?: Maybe<Scalars['Int']['output']>;
  userId?: Maybe<Scalars['Int']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type RProductOrdersFlatResult = {
  __typename?: 'ProductOrdersFlatResult';
  productOrders?: Maybe<Array<Maybe<RProductOrdersFlat>>>;
  total?: Maybe<Scalars['Int']['output']>;
  totalPages?: Maybe<Scalars['Int']['output']>;
};

export type RProductParentCurationsFilter = {
  spaceIdIN: Array<Scalars['Int']['input']>;
};

export type RProductParentsFilter = {
  curation: RProductParentCurationsFilter;
};

export type RProductRow = {
  __typename?: 'ProductRow';
  id?: Maybe<Scalars['Int']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  productId?: Maybe<Scalars['Int']['output']>;
  state?: Maybe<RState>;
  variant?: Maybe<RVariantRow>;
};

export type RProgressPerCourse = {
  __typename?: 'ProgressPerCourse';
  course_id?: Maybe<Scalars['String']['output']>;
  course_name?: Maybe<Scalars['String']['output']>;
  progress?: Maybe<Scalars['String']['output']>;
};

export type RProvider = {
  __typename?: 'Provider';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  type: RLearningContentProvider;
  updatedAt: Scalars['DateTime']['output'];
};

export type RProviderContentOrigin = {
  __typename?: 'ProviderContentOrigin';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  metadata: RProviderContentOriginMetadata;
  provider: RProvider;
  providerId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type RProviderContentOriginMetadata = {
  __typename?: 'ProviderContentOriginMetadata';
  programId?: Maybe<Scalars['String']['output']>;
  programSlug?: Maybe<Scalars['String']['output']>;
};

export type RProviderContentOriginPage = {
  __typename?: 'ProviderContentOriginPage';
  data: Array<RProviderContentOrigin>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type RPurchaseProduct = {
  id: Scalars['Int']['input'];
  productVariantId?: InputMaybe<Scalars['Int']['input']>;
};

export type RPurchaseResponse = {
  __typename?: 'PurchaseResponse';
  orderId: Scalars['Int']['output'];
};

export type RQuery = {
  __typename?: 'Query';
  _blank?: Maybe<Scalars['Boolean']['output']>;
  articleRedirect?: Maybe<RArticleRedirect>;
  assignmentTemplates: Array<RAssignmentTemplate>;
  assignmentV3?: Maybe<RAssignmentV3>;
  assignmentV3Learnings: RAssignmentV3LearningPage;
  assignmentV3Users: RAssignmentV3UsersPage;
  assignmentsV3: RAssignmentsV3Page;
  assignmentsV3UserRuleFields: Array<RAssignmentsV3UserRuleField>;
  assignmentsV3UserRulePreview: RAssignmentsV3UserRulePreview;
  blockV2: RBlockV2;
  catalog?: Maybe<RCatalog>;
  catalogJSON?: Maybe<Scalars['JSON']['output']>;
  /** fetch data from the table: "clsapi.learning" */
  clsapi_learning: Array<RClsapi_Learning>;
  /** fetch aggregated fields from the table: "clsapi.learning" */
  clsapi_learning_aggregate: RClsapi_Learning_Aggregate;
  /** fetch data from the table: "clsapi.learning" using primary key columns */
  clsapi_learning_by_pk?: Maybe<RClsapi_Learning>;
  /** fetch data from the table: "clsapi.learning_journeys" */
  clsapi_learning_journeys: Array<RClsapi_Learning_Journeys>;
  /** fetch aggregated fields from the table: "clsapi.learning_journeys" */
  clsapi_learning_journeys_aggregate: RClsapi_Learning_Journeys_Aggregate;
  /** fetch data from the table: "clsapi.learning_journeys" using primary key columns */
  clsapi_learning_journeys_by_pk?: Maybe<RClsapi_Learning_Journeys>;
  competence: RCompetence;
  competences: RCompetencePage;
  enrollments: Array<REnrollment>;
  exportLicenses?: Maybe<Array<Maybe<RIssuedLicense>>>;
  featureToggle: RFeatureToggle;
  featureToggles: RFeatureTogglePage;
  getAggregationByName?: Maybe<RAggregation>;
  getAllOrdersByState?: Maybe<Array<Maybe<ROrderRowFlat>>>;
  getAllOrdersFiltered?: Maybe<RFilteredOrdersFlatResult>;
  getAllProductsShort?: Maybe<Array<Maybe<RProductLabel>>>;
  getApprovalsFiltered?: Maybe<RFilteredOrdersFlatResult>;
  getCategories?: Maybe<Array<Maybe<RCategory>>>;
  getCompletionRatePerCourse?: Maybe<Array<RCompletionRatePerCourse>>;
  getCompletionsOverTime?: Maybe<Array<Maybe<RDataItem>>>;
  getCurrentUserFeatureToggles?: Maybe<Array<RUserFeatureToggle>>;
  getEffortForRealm?: Maybe<Array<Maybe<REffortForRealm>>>;
  getEffortForRealmOverTime?: Maybe<Array<Maybe<REffortForRealmOverTime>>>;
  getElearningCompletionPerLearningIdByUserId?: Maybe<Array<Maybe<RCompletionPerElearning>>>;
  getHistoricCompletedCourses?: Maybe<Array<Maybe<RHistoricCompletedCourse>>>;
  getInventory?: Maybe<RInventory>;
  getLanguages?: Maybe<Array<Maybe<RLanguage>>>;
  getLearnifierCourses?: Maybe<Array<Maybe<RLearnifierCourse>>>;
  getLearningProgress?: Maybe<Array<Maybe<RAnalyticsProgress>>>;
  getMeta?: Maybe<RUserMeta>;
  getOrderHistoryById?: Maybe<Array<Maybe<ROrderHistory>>>;
  getOrders?: Maybe<ROrderSearch>;
  getParticipants?: Maybe<RParticipantsResult>;
  getParticipations: Array<RParticipationGroup>;
  getPost?: Maybe<RPost>;
  getProduct?: Maybe<RProduct>;
  getProductOrdersFlat?: Maybe<RProductOrdersFlatResult>;
  getProducts?: Maybe<Array<Maybe<RProduct>>>;
  getProgressPerCourseByUser?: Maybe<Array<Maybe<RProgressPerCourse>>>;
  getReplies?: Maybe<RReplies>;
  getSebUsersEmpAndActive?: Maybe<RSebUsersEmpAndActive>;
  getSection?: Maybe<RSection>;
  getSectionConversation?: Maybe<RSection>;
  getSpaces?: Maybe<Array<Maybe<RSpace>>>;
  getTotalTimeSpentPerProvider?: Maybe<Array<Maybe<REffortPerProvider>>>;
  getUserActivitySummary?: Maybe<Array<Maybe<RUserActivitySummary>>>;
  getUserById?: Maybe<RUser>;
  getUserEffort: RUserEffort;
  getUserEffortForRealmOverTime?: Maybe<RUserEffortOverTime>;
  getUserReplies?: Maybe<RReplies>;
  getUserStatuses?: Maybe<Array<Maybe<RDataItem>>>;
  getUsersByEmail?: Maybe<Array<Maybe<RUser>>>;
  getUsersPerVerb?: Maybe<Array<Maybe<RDataItem>>>;
  getVariants?: Maybe<Array<Maybe<RVariant>>>;
  getXAPISession: RXapiSession;
  isLearningCompleted: Scalars['Boolean']['output'];
  isLearningPathCompleted?: Maybe<Scalars['Boolean']['output']>;
  issuedLicense?: Maybe<RIssuedLicense>;
  issuedLicenses?: Maybe<Array<Maybe<RIssuedLicense>>>;
  learning?: Maybe<RLearning>;
  learningCollection?: Maybe<RLearningCollection>;
  learningCollectionV2: RLearningCollectionV2;
  learningCollections?: Maybe<Array<Maybe<RLearningCollection>>>;
  learningCollectionsV2: RLearningCollectionPage;
  learningContentById: RLearningContent;
  learningContentByIdV1: RLearningContent;
  learningContentByIds: Array<RLearningContent>;
  learningContentByIdsV1: Array<RLearningContent>;
  learningContentLearnerInsightsCSV: Scalars['String']['output'];
  learningInsightsCSV: Scalars['String']['output'];
  learningJourney: RLearningJourney;
  learningJourneyContentInsightsCSV: Scalars['String']['output'];
  learningJourneyItemsCatalog: RLearningContentsPage;
  learningJourneyParticipantInsightsCSV: Scalars['String']['output'];
  learningJourneyParticipantsByUserIds: Array<RLearningJourneyLearner>;
  learningJourneySession: RLearningJourneySession;
  learningJourneySessions: RLearningJourneySessionPage;
  learningJourneyTemplates: Array<RLearningJourneyTemplateDto>;
  learningJourneys: RLearningJourneyPage;
  learningLoop: RLearningLoop;
  learningLoopReflectionByLearningLoopId?: Maybe<RLearningLoopReflection>;
  learningLoops: RLearningLoopPage;
  learningQuizInsightsCSV: Scalars['String']['output'];
  learningV2: RLearningV2;
  learningsPinned: Array<RLearningPinned>;
  me: RMe;
  moduleV2: RModuleV2;
  modules?: Maybe<Array<Maybe<RModule>>>;
  myEnrollments: Array<REventEnrollment>;
  myLearningActivityQuizSubmissions: Array<RUserLearningActivityQuizSubmission>;
  mySpaces: RSpaceV2Page;
  notifications?: Maybe<Array<Maybe<RBabelonNotification>>>;
  overLicenseLimit?: Maybe<Scalars['Boolean']['output']>;
  pageV2: RPageV2;
  pages?: Maybe<Array<Maybe<RPage>>>;
  providerAccessProductId?: Maybe<RIdResponse>;
  realmConfig?: Maybe<RRealmConfig>;
  realmFeatureToggles?: Maybe<Array<RRealmFeatureToggle>>;
  realmProviderLicensesSummary?: Maybe<RRealmProviderLicenseSummary>;
  realmV2?: Maybe<RRealmV2>;
  realmV2BySlug?: Maybe<RRealmV2>;
  realms: Array<RRealm>;
  realmsV2: RRealmsV2Page;
  /** fetch data from the table: "reporting.mv_aggregation_effort_for_realm" */
  reportingAggregationEffortForRealm: Array<RReportingAggregationEffortForRealm>;
  /** fetch aggregated fields from the table: "reporting.mv_aggregation_effort_for_realm" */
  reportingAggregationEffortForRealmAggregate: RReportingAggregationEffortForRealm_Aggregate;
  /** fetch data from the table: "reporting.mv_learning_activities_insights" */
  reportingLearningActivitiesInsights: Array<RReportingLearningActivitiesInsights>;
  /** fetch aggregated fields from the table: "reporting.mv_learning_activities_insights" */
  reportingLearningActivitiesInsightsAggregate: RReportingLearningActivitiesInsights_Aggregate;
  /** fetch data from the table: "reporting.mv_learning_content_summary_insights" */
  reportingLearningContentSummaryInsights: Array<RReportingLearningContentSummaryInsights>;
  /** fetch aggregated fields from the table: "reporting.mv_learning_content_summary_insights" */
  reportingLearningContentSummaryInsightsAggregate: RReportingLearningContentSummaryInsights_Aggregate;
  /** fetch data from the table: "reporting.mv_learning_journey_content_insights" */
  reportingLearningJourneyContentInsights: Array<RReportingLearningJourneyContentInsights>;
  /** fetch aggregated fields from the table: "reporting.mv_learning_journey_content_insights" */
  reportingLearningJourneyContentInsightsAggregate: RReportingLearningJourneyContentInsights_Aggregate;
  reportingLearningJourneysItems: Array<RLearning_Journeys_Items>;
  reportingLearningJourneysItemsInsights: Array<RLearning_Journeys_Items_Insights>;
  reportingLearningJourneysItemsTotalCount: Array<RTotal_Count>;
  reportingLearnings: Array<RLearnings>;
  reportingLearningsInsights: Array<RLearningsInsights>;
  reportingLearningsTotalCount: Array<RTotal_Count>;
  reportingMyLearningJourneysInsightsV2: Array<RLearning_Journeys_Insights_V2>;
  reportingMyLearningJourneysLearners: Array<RLearners>;
  reportingMyLearningJourneysLearnersTotalCount: Array<RTotal_Count>;
  reportingMyLearningJourneysTotalCount: Array<RTotal_Count>;
  reportingMyLearningJourneysV2: Array<RLearning_Journeys_V2>;
  reportingMyLearningsLearners: Array<RLearners>;
  reportingMyLearningsLearnersTotalCount: Array<RTotal_Count>;
  reportingMyLearningsPracticalAssessment: Array<RPractical_Assessment>;
  reportingMyLearningsQuiz: Array<RQuiz>;
  reportingMyLearningsReactions: Array<RReactions>;
  reportingMyLearningsSurvey: Array<RSurvey>;
  reportingMyLiveLearningsLearners: Array<RLive_Learnings_Learners>;
  reportingMyLiveLearningsLearnersTotalCount: Array<RTotal_Count>;
  /** fetch data from the table: "reporting.mv_participants_insights" */
  reportingParticipantsInsights: Array<RReportingParticipantsInsights>;
  /** fetch aggregated fields from the table: "reporting.mv_participants_insights" */
  reportingParticipantsInsightsAggregate: RReportingParticipantsInsights_Aggregate;
  /** fetch data from the table: "reporting.mv_practical_assessment_insights" */
  reportingPracticalAssessmentInsights: Array<RReportingPracticalAssessmentInsights>;
  /** fetch aggregated fields from the table: "reporting.mv_practical_assessment_insights" */
  reportingPracticalAssessmentInsightsAggregate: RReportingPracticalAssessmentInsights_Aggregate;
  /** fetch data from the table: "reporting.mv_quiz_questions_insights" */
  reportingQuizQuestionsInsights: Array<RReportingQuizQuestionsInsights>;
  /** fetch aggregated fields from the table: "reporting.mv_quiz_questions_insights" */
  reportingQuizQuestionsInsightsAggregate: RReportingQuizQuestionsInsights_Aggregate;
  /** fetch data from the table: "reporting.mv_quiz_summary_insights" */
  reportingQuizSummaryInsights: Array<RReportingQuizSummaryInsights>;
  /** fetch aggregated fields from the table: "reporting.mv_quiz_summary_insights" */
  reportingQuizSummaryInsightsAggregate: RReportingQuizSummaryInsights_Aggregate;
  /** fetch data from the table: "reporting.mv_reactions_insights" */
  reportingReactionsInsights: Array<RReportingReactionsInsights>;
  /** fetch aggregated fields from the table: "reporting.mv_reactions_insights" */
  reportingReactionsInsightsAggregate: RReportingReactionsInsights_Aggregate;
  /** fetch data from the table: "reporting.mv_survey_answers" */
  reportingSurveyAnswers: Array<RReportingSurveyAnswers>;
  /** fetch aggregated fields from the table: "reporting.mv_survey_answers" */
  reportingSurveyAnswersAggregate: RReportingSurveyAnswers_Aggregate;
  searchLearningContent: RLearningContentsPage;
  searchLearningContentByCurations: RLearningContentsPage;
  searchSubordinateUsersByEmail?: Maybe<Array<Maybe<RUser>>>;
  selfTrackedLearning: RSelfTrackedLearning;
  selfTrackedLearnings: RSelfTrackedLearningsPage;
  space: RSpaceV2;
  spaceMembers: RSpaceMemberPage;
  spaces: RSpaceV2Page;
  surveyAnswerInsightsCSV: Scalars['String']['output'];
  team?: Maybe<RTeam>;
  teamUsers: RTeamUsersPage;
  teamUsersList: RUserList;
  teams: RTeamsPage;
  user?: Maybe<RUserV2>;
  userLearningContentLastParticipation?: Maybe<RParticipationV2>;
  userLearningContentLastParticipations: Array<RParticipationV2>;
  userLearningSubmissions: Array<RUserLearningSubmission>;
  userPendingTours: Array<RTour>;
  userTeams: RTeamUsersPage;
  users: RUsersPage;
};

export type RQueryArticleRedirectArgs = {
  articleId: Scalars['Int']['input'];
};

export type RQueryAssignmentV3Args = {
  id: Scalars['Int']['input'];
};

export type RQueryAssignmentV3LearningsArgs = {
  id: Scalars['Int']['input'];
  pagination?: InputMaybe<RPagination>;
};

export type RQueryAssignmentV3UsersArgs = {
  filter: RAssignmentV3UsersFilter;
  pagination?: InputMaybe<RPagination>;
  sort?: InputMaybe<RAssignmentUsersV3Sort>;
};

export type RQueryAssignmentsV3Args = {
  filter?: InputMaybe<RAssignmentsV3Filter>;
  pagination?: InputMaybe<RPagination>;
  sort?: InputMaybe<RAssignmentsV3Sort>;
};

export type RQueryAssignmentsV3UserRuleFieldsArgs = {
  filter: RAssignmentsV3UserRuleFieldFilter;
};

export type RQueryAssignmentsV3UserRulePreviewArgs = {
  filter: RAssignmentsV3UserRulePreviewFilter;
};

export type RQueryBlockV2Args = {
  id: Scalars['Int']['input'];
};

export type RQueryCatalogArgs = {
  items?: InputMaybe<Array<InputMaybe<RCatalogItems>>>;
  showArchived?: InputMaybe<Scalars['Boolean']['input']>;
  showHidden?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RQueryCatalogJsonArgs = {
  items?: InputMaybe<Array<InputMaybe<RCatalogItems>>>;
  showArchived?: InputMaybe<Scalars['Boolean']['input']>;
  showHidden?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RQueryClsapi_LearningArgs = {
  distinct_on?: InputMaybe<Array<RClsapi_Learning_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RClsapi_Learning_Order_By>>;
  where?: InputMaybe<RClsapi_Learning_Bool_Exp>;
};

export type RQueryClsapi_Learning_AggregateArgs = {
  distinct_on?: InputMaybe<Array<RClsapi_Learning_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RClsapi_Learning_Order_By>>;
  where?: InputMaybe<RClsapi_Learning_Bool_Exp>;
};

export type RQueryClsapi_Learning_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type RQueryClsapi_Learning_JourneysArgs = {
  distinct_on?: InputMaybe<Array<RClsapi_Learning_Journeys_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RClsapi_Learning_Journeys_Order_By>>;
  where?: InputMaybe<RClsapi_Learning_Journeys_Bool_Exp>;
};

export type RQueryClsapi_Learning_Journeys_AggregateArgs = {
  distinct_on?: InputMaybe<Array<RClsapi_Learning_Journeys_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RClsapi_Learning_Journeys_Order_By>>;
  where?: InputMaybe<RClsapi_Learning_Journeys_Bool_Exp>;
};

export type RQueryClsapi_Learning_Journeys_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type RQueryCompetenceArgs = {
  id: Scalars['Int']['input'];
};

export type RQueryCompetencesArgs = {
  filter: RCompetencesFilter;
  pagination?: InputMaybe<RPagination>;
  sort?: InputMaybe<RCompetencesSort>;
};

export type RQueryEnrollmentsArgs = {
  input: REnrollmentsInput;
};

export type RQueryExportLicensesArgs = {
  realm?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type RQueryFeatureToggleArgs = {
  id: Scalars['Int']['input'];
};

export type RQueryFeatureTogglesArgs = {
  filter?: InputMaybe<RFeatureToggleFilter>;
  pagination?: InputMaybe<RPagination>;
  sort?: InputMaybe<RFeatureToggleSort>;
};

export type RQueryGetAggregationByNameArgs = {
  name: Scalars['String']['input'];
};

export type RQueryGetAllOrdersByStateArgs = {
  stateId?: InputMaybe<Scalars['Int']['input']>;
};

export type RQueryGetAllOrdersFilteredArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  productId?: InputMaybe<Scalars['Int']['input']>;
  stateId?: InputMaybe<Scalars['Int']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type RQueryGetApprovalsFilteredArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  productId?: InputMaybe<Scalars['Int']['input']>;
  stateId?: InputMaybe<Scalars['Int']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type RQueryGetCompletionRatePerCourseArgs = {
  userTeamIdIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  usersPool?: InputMaybe<RUsersPool>;
};

export type RQueryGetCompletionsOverTimeArgs = {
  from?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['String']['input']>;
  usersPool: RUsersPool;
};

export type RQueryGetCurrentUserFeatureTogglesArgs = {
  realm: Scalars['String']['input'];
};

export type RQueryGetEffortForRealmArgs = {
  from?: InputMaybe<Scalars['String']['input']>;
  select?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['String']['input']>;
  usersPool: RUsersPool;
};

export type RQueryGetEffortForRealmOverTimeArgs = {
  from?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['String']['input']>;
  usersPool: RUsersPool;
};

export type RQueryGetElearningCompletionPerLearningIdByUserIdArgs = {
  user_id: Scalars['Int']['input'];
};

export type RQueryGetMetaArgs = {
  type: Scalars['String']['input'];
};

export type RQueryGetOrderHistoryByIdArgs = {
  orderId?: InputMaybe<Scalars['Int']['input']>;
};

export type RQueryGetOrdersArgs = {
  orderBy: Scalars['String']['input'];
  orderByDirection: Scalars['String']['input'];
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  query: ROrderSearchQuery;
};

export type RQueryGetParticipantsArgs = {
  orderDirection?: InputMaybe<ROrderDirection>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  productId: Scalars['Int']['input'];
  stateIds: Array<InputMaybe<Scalars['Int']['input']>>;
};

export type RQueryGetParticipationsArgs = {
  input: RParticipationsInput;
};

export type RQueryGetPostArgs = {
  path: Array<Scalars['String']['input']>;
  postId?: InputMaybe<Scalars['Int']['input']>;
};

export type RQueryGetProductArgs = {
  fetchAllVariants?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['Int']['input'];
};

export type RQueryGetProductOrdersFlatArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  productId: Scalars['Int']['input'];
  stateIds: Array<InputMaybe<Scalars['Int']['input']>>;
};

export type RQueryGetProgressPerCourseByUserArgs = {
  email: Scalars['String']['input'];
};

export type RQueryGetRepliesArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  path: Array<Scalars['String']['input']>;
  postId?: InputMaybe<Scalars['Int']['input']>;
};

export type RQueryGetSebUsersEmpAndActiveArgs = {
  usersPool: RUsersPool;
};

export type RQueryGetSectionArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  path: Array<Scalars['String']['input']>;
};

export type RQueryGetSectionConversationArgs = {
  depth?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  path: Array<Scalars['String']['input']>;
  postId: Scalars['Int']['input'];
  replies?: InputMaybe<Scalars['Int']['input']>;
};

export type RQueryGetUserActivitySummaryArgs = {
  usersPool: RUsersPool;
};

export type RQueryGetUserByIdArgs = {
  userId: Scalars['Int']['input'];
};

export type RQueryGetUserEffortArgs = {
  from?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['String']['input']>;
};

export type RQueryGetUserEffortForRealmOverTimeArgs = {
  from?: InputMaybe<Scalars['String']['input']>;
  groupBy?: InputMaybe<Scalars['String']['input']>;
  realm?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['String']['input']>;
  userIdIn?: InputMaybe<Scalars['Int']['input']>;
  usersPool: RUsersPool;
};

export type RQueryGetUserRepliesArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  path: Array<Scalars['String']['input']>;
  postId?: InputMaybe<Scalars['Int']['input']>;
};

export type RQueryGetUserStatusesArgs = {
  usersPool: RUsersPool;
};

export type RQueryGetUsersByEmailArgs = {
  emails?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type RQueryGetUsersPerVerbArgs = {
  from?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['String']['input']>;
  usersPool: RUsersPool;
};

export type RQueryGetVariantsArgs = {
  productId: Scalars['Int']['input'];
};

export type RQueryGetXapiSessionArgs = {
  learningId: Scalars['Int']['input'];
};

export type RQueryIsLearningCompletedArgs = {
  learningId: Scalars['Int']['input'];
};

export type RQueryIsLearningPathCompletedArgs = {
  learningPathId: Scalars['Int']['input'];
};

export type RQueryIssuedLicenseArgs = {
  provider?: InputMaybe<Scalars['String']['input']>;
  realm?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type RQueryIssuedLicensesArgs = {
  provider?: InputMaybe<Scalars['String']['input']>;
  realm?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type RQueryLearningArgs = {
  id: Scalars['ID']['input'];
};

export type RQueryLearningCollectionArgs = {
  id: Scalars['Int']['input'];
};

export type RQueryLearningCollectionV2Args = {
  id: Scalars['Int']['input'];
};

export type RQueryLearningCollectionsV2Args = {
  filter: RLearningCollectionFilter;
  pagination?: InputMaybe<RPagination>;
  sort?: InputMaybe<RLearningCollectionSort>;
};

export type RQueryLearningContentByIdArgs = {
  urn: Scalars['URN']['input'];
};

export type RQueryLearningContentByIdV1Args = {
  urnV1: Scalars['String']['input'];
};

export type RQueryLearningContentByIdsArgs = {
  input: Array<Scalars['URN']['input']>;
  pagination?: InputMaybe<RPagination>;
};

export type RQueryLearningContentByIdsV1Args = {
  input: Array<Scalars['String']['input']>;
  pagination?: InputMaybe<RPagination>;
};

export type RQueryLearningContentLearnerInsightsCsvArgs = {
  urn: Scalars['String']['input'];
};

export type RQueryLearningInsightsCsvArgs = {
  learningId: Scalars['Int']['input'];
};

export type RQueryLearningJourneyArgs = {
  id: Scalars['Int']['input'];
};

export type RQueryLearningJourneyContentInsightsCsvArgs = {
  id: Scalars['Int']['input'];
};

export type RQueryLearningJourneyItemsCatalogArgs = {
  filter: RLearningJourneyItemsCatalogFilter;
  pagination?: InputMaybe<RPagination>;
};

export type RQueryLearningJourneyParticipantInsightsCsvArgs = {
  id: Scalars['Int']['input'];
};

export type RQueryLearningJourneyParticipantsByUserIdsArgs = {
  journeyId: Scalars['Int']['input'];
  userIds: Array<Scalars['Int']['input']>;
};

export type RQueryLearningJourneySessionArgs = {
  id: Scalars['Int']['input'];
};

export type RQueryLearningJourneySessionsArgs = {
  spaceId: Scalars['Int']['input'];
};

export type RQueryLearningJourneysArgs = {
  filter: RLearningJourneyFilter;
  pagination?: InputMaybe<RPagination>;
  sort?: InputMaybe<RLearningJourneySort>;
};

export type RQueryLearningLoopArgs = {
  id: Scalars['String']['input'];
};

export type RQueryLearningLoopReflectionByLearningLoopIdArgs = {
  learningLoopId: Scalars['String']['input'];
};

export type RQueryLearningLoopsArgs = {
  filter: RLearningLoopFilter;
  pagination?: InputMaybe<RPagination>;
};

export type RQueryLearningQuizInsightsCsvArgs = {
  activityId: Scalars['Int']['input'];
};

export type RQueryLearningV2Args = {
  id: Scalars['Int']['input'];
};

export type RQueryLearningsPinnedArgs = {
  realm: Scalars['String']['input'];
};

export type RQueryModuleV2Args = {
  id: Scalars['Int']['input'];
};

export type RQueryModulesArgs = {
  learningId: Scalars['ID']['input'];
};

export type RQueryMyLearningActivityQuizSubmissionsArgs = {
  input: RUserLearningActivityQuizSubmissionFilterInput;
};

export type RQueryMySpacesArgs = {
  pagination?: InputMaybe<RPagination>;
};

export type RQueryOverLicenseLimitArgs = {
  realm?: InputMaybe<Scalars['String']['input']>;
};

export type RQueryPageV2Args = {
  id: Scalars['Int']['input'];
};

export type RQueryPagesArgs = {
  moduleId: Scalars['ID']['input'];
};

export type RQueryProviderAccessProductIdArgs = {
  provider?: InputMaybe<Scalars['String']['input']>;
  realm?: InputMaybe<Scalars['String']['input']>;
};

export type RQueryRealmConfigArgs = {
  realm: Scalars['String']['input'];
};

export type RQueryRealmFeatureTogglesArgs = {
  realm: Scalars['String']['input'];
};

export type RQueryRealmProviderLicensesSummaryArgs = {
  provider?: InputMaybe<Scalars['String']['input']>;
  realm?: InputMaybe<Scalars['String']['input']>;
};

export type RQueryRealmV2Args = {
  id: Scalars['Float']['input'];
};

export type RQueryRealmV2BySlugArgs = {
  slug: Scalars['String']['input'];
};

export type RQueryRealmsV2Args = {
  filter?: InputMaybe<RRealmsFilter>;
  pagination?: InputMaybe<RPagination>;
};

export type RQueryReportingAggregationEffortForRealmArgs = {
  distinct_on?: InputMaybe<Array<RReportingAggregationEffortForRealm_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RReportingAggregationEffortForRealm_Order_By>>;
  where?: InputMaybe<RReportingAggregationEffortForRealm_Bool_Exp>;
};

export type RQueryReportingAggregationEffortForRealmAggregateArgs = {
  distinct_on?: InputMaybe<Array<RReportingAggregationEffortForRealm_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RReportingAggregationEffortForRealm_Order_By>>;
  where?: InputMaybe<RReportingAggregationEffortForRealm_Bool_Exp>;
};

export type RQueryReportingLearningActivitiesInsightsArgs = {
  distinct_on?: InputMaybe<Array<RReportingLearningActivitiesInsights_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RReportingLearningActivitiesInsights_Order_By>>;
  where?: InputMaybe<RReportingLearningActivitiesInsights_Bool_Exp>;
};

export type RQueryReportingLearningActivitiesInsightsAggregateArgs = {
  distinct_on?: InputMaybe<Array<RReportingLearningActivitiesInsights_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RReportingLearningActivitiesInsights_Order_By>>;
  where?: InputMaybe<RReportingLearningActivitiesInsights_Bool_Exp>;
};

export type RQueryReportingLearningContentSummaryInsightsArgs = {
  distinct_on?: InputMaybe<Array<RReportingLearningContentSummaryInsights_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RReportingLearningContentSummaryInsights_Order_By>>;
  where?: InputMaybe<RReportingLearningContentSummaryInsights_Bool_Exp>;
};

export type RQueryReportingLearningContentSummaryInsightsAggregateArgs = {
  distinct_on?: InputMaybe<Array<RReportingLearningContentSummaryInsights_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RReportingLearningContentSummaryInsights_Order_By>>;
  where?: InputMaybe<RReportingLearningContentSummaryInsights_Bool_Exp>;
};

export type RQueryReportingLearningJourneyContentInsightsArgs = {
  distinct_on?: InputMaybe<Array<RReportingLearningJourneyContentInsights_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RReportingLearningJourneyContentInsights_Order_By>>;
  where?: InputMaybe<RReportingLearningJourneyContentInsights_Bool_Exp>;
};

export type RQueryReportingLearningJourneyContentInsightsAggregateArgs = {
  distinct_on?: InputMaybe<Array<RReportingLearningJourneyContentInsights_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RReportingLearningJourneyContentInsights_Order_By>>;
  where?: InputMaybe<RReportingLearningJourneyContentInsights_Bool_Exp>;
};

export type RQueryReportingLearningJourneysItemsArgs = {
  args: RReportingLearningJourneysItems_Arguments;
  distinct_on?: InputMaybe<Array<RLearning_Journeys_Items_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RLearning_Journeys_Items_Order_By>>;
  where?: InputMaybe<RLearning_Journeys_Items_Bool_Exp_Bool_Exp>;
};

export type RQueryReportingLearningJourneysItemsInsightsArgs = {
  args: RReportingLearningJourneysItemsInsights_Arguments;
  distinct_on?: InputMaybe<Array<RLearning_Journeys_Items_Insights_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RLearning_Journeys_Items_Insights_Order_By>>;
  where?: InputMaybe<RLearning_Journeys_Items_Insights_Bool_Exp_Bool_Exp>;
};

export type RQueryReportingLearningJourneysItemsTotalCountArgs = {
  args: RReportingLearningJourneysItemsTotalCount_Arguments;
  distinct_on?: InputMaybe<Array<RTotal_Count_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RTotal_Count_Order_By>>;
  where?: InputMaybe<RTotal_Count_Bool_Exp_Bool_Exp>;
};

export type RQueryReportingLearningsArgs = {
  args: RReportingLearnings_Arguments;
  distinct_on?: InputMaybe<Array<RLearnings_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RLearnings_Order_By>>;
  where?: InputMaybe<RLearnings_Bool_Exp_Bool_Exp>;
};

export type RQueryReportingLearningsInsightsArgs = {
  args: RReportingLearningsInsights_Arguments;
  distinct_on?: InputMaybe<Array<RLearningsInsights_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RLearningsInsights_Order_By>>;
  where?: InputMaybe<RLearningsInsights_Bool_Exp_Bool_Exp>;
};

export type RQueryReportingLearningsTotalCountArgs = {
  args: RReportingLearningsTotalCount_Arguments;
  distinct_on?: InputMaybe<Array<RTotal_Count_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RTotal_Count_Order_By>>;
  where?: InputMaybe<RTotal_Count_Bool_Exp_Bool_Exp>;
};

export type RQueryReportingMyLearningJourneysInsightsV2Args = {
  args: RReportingMyLearningJourneysInsightsV2_Arguments;
  distinct_on?: InputMaybe<Array<RLearning_Journeys_Insights_V2_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RLearning_Journeys_Insights_V2_Order_By>>;
  where?: InputMaybe<RLearning_Journeys_Insights_V2_Bool_Exp_Bool_Exp>;
};

export type RQueryReportingMyLearningJourneysLearnersArgs = {
  args: RReportingMyLearningJourneysLearners_Arguments;
  distinct_on?: InputMaybe<Array<RLearners_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RLearners_Order_By>>;
  where?: InputMaybe<RLearners_Bool_Exp_Bool_Exp>;
};

export type RQueryReportingMyLearningJourneysLearnersTotalCountArgs = {
  args: RReportingMyLearningJourneysLearnersTotalCount_Arguments;
  distinct_on?: InputMaybe<Array<RTotal_Count_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RTotal_Count_Order_By>>;
  where?: InputMaybe<RTotal_Count_Bool_Exp_Bool_Exp>;
};

export type RQueryReportingMyLearningJourneysTotalCountArgs = {
  args: RReportingMyLearningJourneysTotalCount_Arguments;
  distinct_on?: InputMaybe<Array<RTotal_Count_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RTotal_Count_Order_By>>;
  where?: InputMaybe<RTotal_Count_Bool_Exp_Bool_Exp>;
};

export type RQueryReportingMyLearningJourneysV2Args = {
  args: RReportingMyLearningJourneysV2_Arguments;
  distinct_on?: InputMaybe<Array<RLearning_Journeys_V2_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RLearning_Journeys_V2_Order_By>>;
  where?: InputMaybe<RLearning_Journeys_V2_Bool_Exp_Bool_Exp>;
};

export type RQueryReportingMyLearningsLearnersArgs = {
  args: RReportingMyLearningsLearners_Arguments;
  distinct_on?: InputMaybe<Array<RLearners_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RLearners_Order_By>>;
  where?: InputMaybe<RLearners_Bool_Exp_Bool_Exp>;
};

export type RQueryReportingMyLearningsLearnersTotalCountArgs = {
  args: RReportingMyLearningsLearnersTotalCount_Arguments;
  distinct_on?: InputMaybe<Array<RTotal_Count_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RTotal_Count_Order_By>>;
  where?: InputMaybe<RTotal_Count_Bool_Exp_Bool_Exp>;
};

export type RQueryReportingMyLearningsPracticalAssessmentArgs = {
  args: RReportingMyLearningsPracticalAssessment_Arguments;
  distinct_on?: InputMaybe<Array<RPractical_Assessment_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RPractical_Assessment_Order_By>>;
  where?: InputMaybe<RPractical_Assessment_Bool_Exp_Bool_Exp>;
};

export type RQueryReportingMyLearningsQuizArgs = {
  args: RReportingMyLearningsQuiz_Arguments;
  distinct_on?: InputMaybe<Array<RQuiz_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RQuiz_Order_By>>;
  where?: InputMaybe<RQuiz_Bool_Exp_Bool_Exp>;
};

export type RQueryReportingMyLearningsReactionsArgs = {
  args: RReportingMyLearningsReactions_Arguments;
  distinct_on?: InputMaybe<Array<RReactions_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RReactions_Order_By>>;
  where?: InputMaybe<RReactions_Bool_Exp_Bool_Exp>;
};

export type RQueryReportingMyLearningsSurveyArgs = {
  args: RReportingMyLearningsSurvey_Arguments;
  distinct_on?: InputMaybe<Array<RSurvey_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RSurvey_Order_By>>;
  where?: InputMaybe<RSurvey_Bool_Exp_Bool_Exp>;
};

export type RQueryReportingMyLiveLearningsLearnersArgs = {
  args: RReportingMyLiveLearningsLearners_Arguments;
  distinct_on?: InputMaybe<Array<RLive_Learnings_Learners_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RLive_Learnings_Learners_Order_By>>;
  where?: InputMaybe<RLive_Learnings_Learners_Bool_Exp_Bool_Exp>;
};

export type RQueryReportingMyLiveLearningsLearnersTotalCountArgs = {
  args: RReportingMyLiveLearningsLearnersTotalCount_Arguments;
  distinct_on?: InputMaybe<Array<RTotal_Count_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RTotal_Count_Order_By>>;
  where?: InputMaybe<RTotal_Count_Bool_Exp_Bool_Exp>;
};

export type RQueryReportingParticipantsInsightsArgs = {
  distinct_on?: InputMaybe<Array<RReportingParticipantsInsights_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RReportingParticipantsInsights_Order_By>>;
  where?: InputMaybe<RReportingParticipantsInsights_Bool_Exp>;
};

export type RQueryReportingParticipantsInsightsAggregateArgs = {
  distinct_on?: InputMaybe<Array<RReportingParticipantsInsights_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RReportingParticipantsInsights_Order_By>>;
  where?: InputMaybe<RReportingParticipantsInsights_Bool_Exp>;
};

export type RQueryReportingPracticalAssessmentInsightsArgs = {
  distinct_on?: InputMaybe<Array<RReportingPracticalAssessmentInsights_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RReportingPracticalAssessmentInsights_Order_By>>;
  where?: InputMaybe<RReportingPracticalAssessmentInsights_Bool_Exp>;
};

export type RQueryReportingPracticalAssessmentInsightsAggregateArgs = {
  distinct_on?: InputMaybe<Array<RReportingPracticalAssessmentInsights_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RReportingPracticalAssessmentInsights_Order_By>>;
  where?: InputMaybe<RReportingPracticalAssessmentInsights_Bool_Exp>;
};

export type RQueryReportingQuizQuestionsInsightsArgs = {
  distinct_on?: InputMaybe<Array<RReportingQuizQuestionsInsights_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RReportingQuizQuestionsInsights_Order_By>>;
  where?: InputMaybe<RReportingQuizQuestionsInsights_Bool_Exp>;
};

export type RQueryReportingQuizQuestionsInsightsAggregateArgs = {
  distinct_on?: InputMaybe<Array<RReportingQuizQuestionsInsights_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RReportingQuizQuestionsInsights_Order_By>>;
  where?: InputMaybe<RReportingQuizQuestionsInsights_Bool_Exp>;
};

export type RQueryReportingQuizSummaryInsightsArgs = {
  distinct_on?: InputMaybe<Array<RReportingQuizSummaryInsights_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RReportingQuizSummaryInsights_Order_By>>;
  where?: InputMaybe<RReportingQuizSummaryInsights_Bool_Exp>;
};

export type RQueryReportingQuizSummaryInsightsAggregateArgs = {
  distinct_on?: InputMaybe<Array<RReportingQuizSummaryInsights_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RReportingQuizSummaryInsights_Order_By>>;
  where?: InputMaybe<RReportingQuizSummaryInsights_Bool_Exp>;
};

export type RQueryReportingReactionsInsightsArgs = {
  distinct_on?: InputMaybe<Array<RReportingReactionsInsights_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RReportingReactionsInsights_Order_By>>;
  where?: InputMaybe<RReportingReactionsInsights_Bool_Exp>;
};

export type RQueryReportingReactionsInsightsAggregateArgs = {
  distinct_on?: InputMaybe<Array<RReportingReactionsInsights_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RReportingReactionsInsights_Order_By>>;
  where?: InputMaybe<RReportingReactionsInsights_Bool_Exp>;
};

export type RQueryReportingSurveyAnswersArgs = {
  distinct_on?: InputMaybe<Array<RReportingSurveyAnswers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RReportingSurveyAnswers_Order_By>>;
  where?: InputMaybe<RReportingSurveyAnswers_Bool_Exp>;
};

export type RQueryReportingSurveyAnswersAggregateArgs = {
  distinct_on?: InputMaybe<Array<RReportingSurveyAnswers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RReportingSurveyAnswers_Order_By>>;
  where?: InputMaybe<RReportingSurveyAnswers_Bool_Exp>;
};

export type RQuerySearchLearningContentArgs = {
  filter: RSearchLearningContentFilter;
  pagination?: InputMaybe<RPagination>;
};

export type RQuerySearchLearningContentByCurationsArgs = {
  filter: RCurationFilter;
  pagination?: InputMaybe<RPagination>;
};

export type RQuerySearchSubordinateUsersByEmailArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  payload?: InputMaybe<RSearchSubordinateUsersInput>;
};

export type RQuerySelfTrackedLearningArgs = {
  id: Scalars['Int']['input'];
};

export type RQuerySelfTrackedLearningsArgs = {
  filter: RSelfTrackedLearningsFilter;
  pagination?: InputMaybe<RPagination>;
};

export type RQuerySpaceArgs = {
  id: Scalars['Int']['input'];
};

export type RQuerySpaceMembersArgs = {
  filter: RSpaceMemberFilter;
  pagination?: InputMaybe<RPagination>;
};

export type RQuerySpacesArgs = {
  pagination?: InputMaybe<RPagination>;
};

export type RQuerySurveyAnswerInsightsCsvArgs = {
  id: Scalars['Int']['input'];
};

export type RQueryTeamArgs = {
  id: Scalars['Int']['input'];
};

export type RQueryTeamUsersArgs = {
  filter: RTeamUsersFilter;
  pagination?: InputMaybe<RPagination>;
  sort?: InputMaybe<RTeamUsersSort>;
};

export type RQueryTeamUsersListArgs = {
  filter: RTeamUsersFilter;
};

export type RQueryTeamsArgs = {
  filter?: InputMaybe<RTeamsFilter>;
  pagination?: InputMaybe<RPagination>;
  sort?: InputMaybe<RTeamsSort>;
};

export type RQueryUserArgs = {
  id: Scalars['Float']['input'];
};

export type RQueryUserLearningContentLastParticipationArgs = {
  learningContentId: Scalars['URN']['input'];
  userId: Scalars['Int']['input'];
};

export type RQueryUserLearningContentLastParticipationsArgs = {
  learningContentIdIN: Array<Scalars['URN']['input']>;
  userId: Scalars['Int']['input'];
};

export type RQueryUserLearningSubmissionsArgs = {
  input: RUserLearningSubmissionsFilter;
};

export type RQueryUserTeamsArgs = {
  filter: RUserTeamsFilter;
  pagination?: InputMaybe<RPagination>;
  sort?: InputMaybe<RTeamUsersSort>;
};

export type RQueryUsersArgs = {
  filter?: InputMaybe<RUsersFilter>;
  pagination?: InputMaybe<RPagination>;
  sort?: InputMaybe<RUsersSort>;
};

export type RQueryResponse = {
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type RRealm = {
  __typename?: 'Realm';
  name: Scalars['String']['output'];
  realm: Scalars['String']['output'];
};

export type RRealmConfig = {
  __typename?: 'RealmConfig';
  heroImageUrl?: Maybe<Scalars['String']['output']>;
  heroVideoUrl?: Maybe<Scalars['String']['output']>;
  languageDefault?: Maybe<Scalars['String']['output']>;
};

export type RRealmFeatureToggle = {
  __typename?: 'RealmFeatureToggle';
  name: Scalars['String']['output'];
};

export type RRealmProviderLicenseSummary = {
  __typename?: 'RealmProviderLicenseSummary';
  catalogUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  pendingLicenses: Scalars['Int']['output'];
  provider: Scalars['String']['output'];
  realmId: Scalars['Int']['output'];
};

export enum RRealmRole {
  Admin = 'admin',
  User = 'user',
}

export type RRealmV2 = {
  __typename?: 'RealmV2';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

export type RRealmsFilter = {
  idNIN?: InputMaybe<Array<Scalars['Int']['input']>>;
  slugIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type RRealmsV2Page = {
  __typename?: 'RealmsV2Page';
  data: Array<RRealmV2>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type RReplies = {
  __typename?: 'Replies';
  post?: Maybe<RPost>;
  replies?: Maybe<RPostsMeta>;
};

export type RReturnData = {
  __typename?: 'ReturnData';
  value?: Maybe<Scalars['String']['output']>;
};

export type RRole = {
  __typename?: 'Role';
  label?: Maybe<Scalars['String']['output']>;
};

export type RSadminPurchaseProduct = {
  id: Scalars['Int']['input'];
  productVariantId?: InputMaybe<Scalars['Int']['input']>;
  spaceId: Scalars['Int']['input'];
};

export type RSadminPurchaseResponse = RMutationResponse & {
  __typename?: 'SadminPurchaseResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  result?: Maybe<Array<Maybe<RSadminPurchaseResult>>>;
  success: Scalars['Boolean']['output'];
};

export type RSadminPurchaseResult = {
  __typename?: 'SadminPurchaseResult';
  email?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  orderId?: Maybe<Scalars['Int']['output']>;
};

export type RSearchLearningContentFilter = {
  idIN?: InputMaybe<Array<Scalars['String']['input']>>;
  originIdIN?: InputMaybe<Array<Scalars['String']['input']>>;
  providerIn?: InputMaybe<Array<RLearningContentProvider>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type RSearchSubordinateUsersInput = {
  email?: InputMaybe<Scalars['String']['input']>;
};

export type RSebUsersEmpAndActive = {
  __typename?: 'SebUsersEmpAndActive';
  count?: Maybe<Scalars['Int']['output']>;
};

export type RSection = {
  __typename?: 'Section';
  access?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isCorporeal?: Maybe<Scalars['Boolean']['output']>;
  isOpen?: Maybe<Scalars['Boolean']['output']>;
  path?: Maybe<Scalars['String']['output']>;
  post?: Maybe<RPost>;
  postCount?: Maybe<Scalars['Int']['output']>;
  posts?: Maybe<RPostsMeta>;
  role?: Maybe<RPermissionsRole>;
  stickyPosts?: Maybe<Array<Maybe<RPost>>>;
  subsections?: Maybe<Array<Maybe<RSubSection>>>;
  title?: Maybe<Scalars['String']['output']>;
};

export type RSectionPermissions = {
  __typename?: 'SectionPermissions';
  canAddReflections?: Maybe<Scalars['Boolean']['output']>;
  canDelete?: Maybe<Scalars['Boolean']['output']>;
  canEdit?: Maybe<Scalars['Boolean']['output']>;
  canEditSection?: Maybe<Scalars['Boolean']['output']>;
  canLock?: Maybe<Scalars['Boolean']['output']>;
  canPost?: Maybe<Scalars['Boolean']['output']>;
  canPostSticky?: Maybe<Scalars['Boolean']['output']>;
  canPurge?: Maybe<Scalars['Boolean']['output']>;
  canReply?: Maybe<Scalars['Boolean']['output']>;
  canRestore?: Maybe<Scalars['Boolean']['output']>;
  canView?: Maybe<Scalars['Boolean']['output']>;
  canViewReflections?: Maybe<Scalars['Boolean']['output']>;
};

export type RSelfTrackedLearning = {
  __typename?: 'SelfTrackedLearning';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  effort: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  link?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  type: RSelfTrackedLearningType;
  updatedAt: Scalars['DateTime']['output'];
};

export type RSelfTrackedLearningResult = {
  __typename?: 'SelfTrackedLearningResult';
  selfTrackedLearning?: Maybe<RSelfTrackedLearning>;
  success: Scalars['Boolean']['output'];
};

export enum RSelfTrackedLearningType {
  Article = 'ARTICLE',
  Book = 'BOOK',
  LiveEvent = 'LIVE_EVENT',
  Other = 'OTHER',
  Podcast = 'PODCAST',
  Program = 'PROGRAM',
  Video = 'VIDEO',
  Workshop = 'WORKSHOP',
}

export type RSelfTrackedLearningsFilter = {
  idIN?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type RSelfTrackedLearningsPage = {
  __typename?: 'SelfTrackedLearningsPage';
  data: Array<RSelfTrackedLearning>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type RService = {
  __typename?: 'Service';
  id?: Maybe<Scalars['Int']['output']>;
  meta?: Maybe<RLearnifierCourse>;
  provider?: Maybe<Scalars['String']['output']>;
  resource?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type RServiceInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  meta?: InputMaybe<RLearnifierCourseInput>;
  provider?: InputMaybe<Scalars['String']['input']>;
  resource?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type RSetEnrollmentAttendanceInput = {
  attended: RAttendedType;
  enrollmentId: Scalars['Int']['input'];
};

export type RSetLanguageInput = {
  language: Scalars['String']['input'];
};

export type RSetUserStatusInput = {
  status?: InputMaybe<RAccountStatus>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type RSetUsernameInput = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

export enum RSortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type RSpace = {
  __typename?: 'Space';
  id: Scalars['Int']['output'];
  label?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  permissions: RSpacePermissions;
  realm?: Maybe<Scalars['String']['output']>;
};

export type RSpaceInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type RSpaceMember = {
  __typename?: 'SpaceMember';
  email: Scalars['String']['output'];
  firstname?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  lastname?: Maybe<Scalars['String']['output']>;
  profileImage?: Maybe<Scalars['String']['output']>;
  realm: Scalars['String']['output'];
  spaceId: Scalars['Int']['output'];
  username?: Maybe<Scalars['String']['output']>;
};

export type RSpaceMemberFilter = {
  emailIN?: InputMaybe<Array<Scalars['String']['input']>>;
  idIN?: InputMaybe<Array<Scalars['Int']['input']>>;
  includeGlobalAdmins?: InputMaybe<Scalars['Boolean']['input']>;
  searchPhrase?: InputMaybe<Scalars['String']['input']>;
  spaceIdIN: Array<Scalars['Int']['input']>;
};

export type RSpaceMemberPage = {
  __typename?: 'SpaceMemberPage';
  data: Array<RSpaceMember>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type RSpacePermissions = {
  __typename?: 'SpacePermissions';
  hasWriteAccessToLearningContent?: Maybe<Scalars['Boolean']['output']>;
};

export type RSpaceV2 = {
  __typename?: 'SpaceV2';
  contentOrigins: RProviderContentOriginPage;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  label: Scalars['String']['output'];
  name: Scalars['String']['output'];
  permissions: RSpacePermissions;
  realm?: Maybe<Scalars['String']['output']>;
  roleAssignments: RSpaceV2RoleAssignmentPage;
  updatedAt: Scalars['DateTime']['output'];
};

export type RSpaceV2ContentOriginsArgs = {
  pagination?: InputMaybe<RPagination>;
};

export type RSpaceV2RoleAssignmentsArgs = {
  pagination?: InputMaybe<RPagination>;
};

export type RSpaceV2Page = {
  __typename?: 'SpaceV2Page';
  data: Array<RSpaceV2>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type RSpaceV2Result = {
  __typename?: 'SpaceV2Result';
  space?: Maybe<RSpaceV2>;
  success: Scalars['Boolean']['output'];
};

export enum RSpaceV2Role {
  Admin = 'admin',
  DiscussionsModerator = 'discussions_moderator',
  Learner = 'learner',
}

export type RSpaceV2RoleAssignResultDto = {
  __typename?: 'SpaceV2RoleAssignResultDto';
  spaceRoleAssignment?: Maybe<RSpaceV2RoleAssignment>;
  success: Scalars['Boolean']['output'];
};

export type RSpaceV2RoleAssignment = {
  __typename?: 'SpaceV2RoleAssignment';
  createdAt: Scalars['DateTime']['output'];
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  realm?: Maybe<Scalars['String']['output']>;
  role?: Maybe<RSpaceV2Role>;
  roleDisplayName?: Maybe<Scalars['String']['output']>;
  spaceId: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<RUserV2>;
  userId?: Maybe<Scalars['Int']['output']>;
};

export type RSpaceV2RoleAssignmentPage = {
  __typename?: 'SpaceV2RoleAssignmentPage';
  data: Array<RSpaceV2RoleAssignment>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type RState = {
  __typename?: 'State';
  flags?: Maybe<RStateFlag>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type RStateFlag = {
  __typename?: 'StateFlag';
  drawStock?: Maybe<Scalars['Boolean']['output']>;
  endState?: Maybe<Scalars['Boolean']['output']>;
  entitlement?: Maybe<Scalars['Boolean']['output']>;
};

export type RStateFlagInput = {
  drawStock?: InputMaybe<Scalars['Boolean']['input']>;
  endState?: InputMaybe<Scalars['Boolean']['input']>;
  entitlement?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RStateInput = {
  flags?: InputMaybe<RStateFlagInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type RStats = {
  __typename?: 'Stats';
  likes?: Maybe<Scalars['Int']['output']>;
  replies?: Maybe<Scalars['Int']['output']>;
};

export type RStock = {
  __typename?: 'Stock';
  enabled?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  reserved?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type RStockInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  reserved?: InputMaybe<Scalars['Int']['input']>;
  total?: InputMaybe<Scalars['Int']['input']>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type RString_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']['input']>;
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']['input']>;
};

export type RSubSection = {
  __typename?: 'SubSection';
  access?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isCorporeal?: Maybe<Scalars['Boolean']['output']>;
  isOpen?: Maybe<Scalars['Boolean']['output']>;
  path?: Maybe<Scalars['String']['output']>;
  posts?: Maybe<Scalars['Int']['output']>;
  role?: Maybe<RPermissionsRole>;
  title?: Maybe<Scalars['String']['output']>;
};

export type RSubject = {
  __typename?: 'Subject';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  urlKey?: Maybe<Scalars['String']['output']>;
};

export type RSubjectCategory = {
  __typename?: 'SubjectCategory';
  category: Scalars['String']['output'];
  categoryId: Scalars['Int']['output'];
  learningId?: Maybe<Scalars['Int']['output']>;
  subject: Scalars['String']['output'];
  subjectId: Scalars['Int']['output'];
};

export type RSubjectCategoryV2 = {
  __typename?: 'SubjectCategoryV2';
  category: Scalars['String']['output'];
  categoryId: Scalars['Int']['output'];
  subject: Scalars['String']['output'];
  subjectId: Scalars['Int']['output'];
};

export type RSubscription = {
  __typename?: 'Subscription';
  /** fetch data from the table: "clsapi.learning" */
  clsapi_learning: Array<RClsapi_Learning>;
  /** fetch aggregated fields from the table: "clsapi.learning" */
  clsapi_learning_aggregate: RClsapi_Learning_Aggregate;
  /** fetch data from the table: "clsapi.learning" using primary key columns */
  clsapi_learning_by_pk?: Maybe<RClsapi_Learning>;
  /** fetch data from the table: "clsapi.learning_journeys" */
  clsapi_learning_journeys: Array<RClsapi_Learning_Journeys>;
  /** fetch aggregated fields from the table: "clsapi.learning_journeys" */
  clsapi_learning_journeys_aggregate: RClsapi_Learning_Journeys_Aggregate;
  /** fetch data from the table: "clsapi.learning_journeys" using primary key columns */
  clsapi_learning_journeys_by_pk?: Maybe<RClsapi_Learning_Journeys>;
  /** fetch data from the table in a streaming manner: "clsapi.learning_journeys" */
  clsapi_learning_journeys_stream: Array<RClsapi_Learning_Journeys>;
  /** fetch data from the table in a streaming manner: "clsapi.learning" */
  clsapi_learning_stream: Array<RClsapi_Learning>;
  /** fetch data from the table: "reporting.mv_aggregation_effort_for_realm" */
  reportingAggregationEffortForRealm: Array<RReportingAggregationEffortForRealm>;
  /** fetch aggregated fields from the table: "reporting.mv_aggregation_effort_for_realm" */
  reportingAggregationEffortForRealmAggregate: RReportingAggregationEffortForRealm_Aggregate;
  /** fetch data from the table in a streaming manner: "reporting.mv_aggregation_effort_for_realm" */
  reportingAggregationEffortForRealm_stream: Array<RReportingAggregationEffortForRealm>;
  /** fetch data from the table: "reporting.mv_learning_activities_insights" */
  reportingLearningActivitiesInsights: Array<RReportingLearningActivitiesInsights>;
  /** fetch aggregated fields from the table: "reporting.mv_learning_activities_insights" */
  reportingLearningActivitiesInsightsAggregate: RReportingLearningActivitiesInsights_Aggregate;
  /** fetch data from the table in a streaming manner: "reporting.mv_learning_activities_insights" */
  reportingLearningActivitiesInsights_stream: Array<RReportingLearningActivitiesInsights>;
  /** fetch data from the table: "reporting.mv_learning_content_summary_insights" */
  reportingLearningContentSummaryInsights: Array<RReportingLearningContentSummaryInsights>;
  /** fetch aggregated fields from the table: "reporting.mv_learning_content_summary_insights" */
  reportingLearningContentSummaryInsightsAggregate: RReportingLearningContentSummaryInsights_Aggregate;
  /** fetch data from the table in a streaming manner: "reporting.mv_learning_content_summary_insights" */
  reportingLearningContentSummaryInsights_stream: Array<RReportingLearningContentSummaryInsights>;
  /** fetch data from the table: "reporting.mv_learning_journey_content_insights" */
  reportingLearningJourneyContentInsights: Array<RReportingLearningJourneyContentInsights>;
  /** fetch aggregated fields from the table: "reporting.mv_learning_journey_content_insights" */
  reportingLearningJourneyContentInsightsAggregate: RReportingLearningJourneyContentInsights_Aggregate;
  /** fetch data from the table in a streaming manner: "reporting.mv_learning_journey_content_insights" */
  reportingLearningJourneyContentInsights_stream: Array<RReportingLearningJourneyContentInsights>;
  reportingLearningJourneysItems: Array<RLearning_Journeys_Items>;
  reportingLearningJourneysItemsInsights: Array<RLearning_Journeys_Items_Insights>;
  reportingLearningJourneysItemsTotalCount: Array<RTotal_Count>;
  reportingLearnings: Array<RLearnings>;
  reportingLearningsInsights: Array<RLearningsInsights>;
  reportingLearningsTotalCount: Array<RTotal_Count>;
  reportingMyLearningJourneysInsightsV2: Array<RLearning_Journeys_Insights_V2>;
  reportingMyLearningJourneysLearners: Array<RLearners>;
  reportingMyLearningJourneysLearnersTotalCount: Array<RTotal_Count>;
  reportingMyLearningJourneysTotalCount: Array<RTotal_Count>;
  reportingMyLearningJourneysV2: Array<RLearning_Journeys_V2>;
  reportingMyLearningsLearners: Array<RLearners>;
  reportingMyLearningsLearnersTotalCount: Array<RTotal_Count>;
  reportingMyLearningsPracticalAssessment: Array<RPractical_Assessment>;
  reportingMyLearningsQuiz: Array<RQuiz>;
  reportingMyLearningsReactions: Array<RReactions>;
  reportingMyLearningsSurvey: Array<RSurvey>;
  reportingMyLiveLearningsLearners: Array<RLive_Learnings_Learners>;
  reportingMyLiveLearningsLearnersTotalCount: Array<RTotal_Count>;
  /** fetch data from the table: "reporting.mv_participants_insights" */
  reportingParticipantsInsights: Array<RReportingParticipantsInsights>;
  /** fetch aggregated fields from the table: "reporting.mv_participants_insights" */
  reportingParticipantsInsightsAggregate: RReportingParticipantsInsights_Aggregate;
  /** fetch data from the table in a streaming manner: "reporting.mv_participants_insights" */
  reportingParticipantsInsights_stream: Array<RReportingParticipantsInsights>;
  /** fetch data from the table: "reporting.mv_practical_assessment_insights" */
  reportingPracticalAssessmentInsights: Array<RReportingPracticalAssessmentInsights>;
  /** fetch aggregated fields from the table: "reporting.mv_practical_assessment_insights" */
  reportingPracticalAssessmentInsightsAggregate: RReportingPracticalAssessmentInsights_Aggregate;
  /** fetch data from the table in a streaming manner: "reporting.mv_practical_assessment_insights" */
  reportingPracticalAssessmentInsights_stream: Array<RReportingPracticalAssessmentInsights>;
  /** fetch data from the table: "reporting.mv_quiz_questions_insights" */
  reportingQuizQuestionsInsights: Array<RReportingQuizQuestionsInsights>;
  /** fetch aggregated fields from the table: "reporting.mv_quiz_questions_insights" */
  reportingQuizQuestionsInsightsAggregate: RReportingQuizQuestionsInsights_Aggregate;
  /** fetch data from the table in a streaming manner: "reporting.mv_quiz_questions_insights" */
  reportingQuizQuestionsInsights_stream: Array<RReportingQuizQuestionsInsights>;
  /** fetch data from the table: "reporting.mv_quiz_summary_insights" */
  reportingQuizSummaryInsights: Array<RReportingQuizSummaryInsights>;
  /** fetch aggregated fields from the table: "reporting.mv_quiz_summary_insights" */
  reportingQuizSummaryInsightsAggregate: RReportingQuizSummaryInsights_Aggregate;
  /** fetch data from the table in a streaming manner: "reporting.mv_quiz_summary_insights" */
  reportingQuizSummaryInsights_stream: Array<RReportingQuizSummaryInsights>;
  /** fetch data from the table: "reporting.mv_reactions_insights" */
  reportingReactionsInsights: Array<RReportingReactionsInsights>;
  /** fetch aggregated fields from the table: "reporting.mv_reactions_insights" */
  reportingReactionsInsightsAggregate: RReportingReactionsInsights_Aggregate;
  /** fetch data from the table in a streaming manner: "reporting.mv_reactions_insights" */
  reportingReactionsInsights_stream: Array<RReportingReactionsInsights>;
  /** fetch data from the table: "reporting.mv_survey_answers" */
  reportingSurveyAnswers: Array<RReportingSurveyAnswers>;
  /** fetch aggregated fields from the table: "reporting.mv_survey_answers" */
  reportingSurveyAnswersAggregate: RReportingSurveyAnswers_Aggregate;
  /** fetch data from the table in a streaming manner: "reporting.mv_survey_answers" */
  reportingSurveyAnswers_stream: Array<RReportingSurveyAnswers>;
};

export type RSubscriptionClsapi_LearningArgs = {
  distinct_on?: InputMaybe<Array<RClsapi_Learning_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RClsapi_Learning_Order_By>>;
  where?: InputMaybe<RClsapi_Learning_Bool_Exp>;
};

export type RSubscriptionClsapi_Learning_AggregateArgs = {
  distinct_on?: InputMaybe<Array<RClsapi_Learning_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RClsapi_Learning_Order_By>>;
  where?: InputMaybe<RClsapi_Learning_Bool_Exp>;
};

export type RSubscriptionClsapi_Learning_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type RSubscriptionClsapi_Learning_JourneysArgs = {
  distinct_on?: InputMaybe<Array<RClsapi_Learning_Journeys_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RClsapi_Learning_Journeys_Order_By>>;
  where?: InputMaybe<RClsapi_Learning_Journeys_Bool_Exp>;
};

export type RSubscriptionClsapi_Learning_Journeys_AggregateArgs = {
  distinct_on?: InputMaybe<Array<RClsapi_Learning_Journeys_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RClsapi_Learning_Journeys_Order_By>>;
  where?: InputMaybe<RClsapi_Learning_Journeys_Bool_Exp>;
};

export type RSubscriptionClsapi_Learning_Journeys_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type RSubscriptionClsapi_Learning_Journeys_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<RClsapi_Learning_Journeys_Stream_Cursor_Input>>;
  where?: InputMaybe<RClsapi_Learning_Journeys_Bool_Exp>;
};

export type RSubscriptionClsapi_Learning_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<RClsapi_Learning_Stream_Cursor_Input>>;
  where?: InputMaybe<RClsapi_Learning_Bool_Exp>;
};

export type RSubscriptionReportingAggregationEffortForRealmArgs = {
  distinct_on?: InputMaybe<Array<RReportingAggregationEffortForRealm_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RReportingAggregationEffortForRealm_Order_By>>;
  where?: InputMaybe<RReportingAggregationEffortForRealm_Bool_Exp>;
};

export type RSubscriptionReportingAggregationEffortForRealmAggregateArgs = {
  distinct_on?: InputMaybe<Array<RReportingAggregationEffortForRealm_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RReportingAggregationEffortForRealm_Order_By>>;
  where?: InputMaybe<RReportingAggregationEffortForRealm_Bool_Exp>;
};

export type RSubscriptionReportingAggregationEffortForRealm_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<RReportingAggregationEffortForRealm_Stream_Cursor_Input>>;
  where?: InputMaybe<RReportingAggregationEffortForRealm_Bool_Exp>;
};

export type RSubscriptionReportingLearningActivitiesInsightsArgs = {
  distinct_on?: InputMaybe<Array<RReportingLearningActivitiesInsights_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RReportingLearningActivitiesInsights_Order_By>>;
  where?: InputMaybe<RReportingLearningActivitiesInsights_Bool_Exp>;
};

export type RSubscriptionReportingLearningActivitiesInsightsAggregateArgs = {
  distinct_on?: InputMaybe<Array<RReportingLearningActivitiesInsights_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RReportingLearningActivitiesInsights_Order_By>>;
  where?: InputMaybe<RReportingLearningActivitiesInsights_Bool_Exp>;
};

export type RSubscriptionReportingLearningActivitiesInsights_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<RReportingLearningActivitiesInsights_Stream_Cursor_Input>>;
  where?: InputMaybe<RReportingLearningActivitiesInsights_Bool_Exp>;
};

export type RSubscriptionReportingLearningContentSummaryInsightsArgs = {
  distinct_on?: InputMaybe<Array<RReportingLearningContentSummaryInsights_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RReportingLearningContentSummaryInsights_Order_By>>;
  where?: InputMaybe<RReportingLearningContentSummaryInsights_Bool_Exp>;
};

export type RSubscriptionReportingLearningContentSummaryInsightsAggregateArgs = {
  distinct_on?: InputMaybe<Array<RReportingLearningContentSummaryInsights_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RReportingLearningContentSummaryInsights_Order_By>>;
  where?: InputMaybe<RReportingLearningContentSummaryInsights_Bool_Exp>;
};

export type RSubscriptionReportingLearningContentSummaryInsights_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<RReportingLearningContentSummaryInsights_Stream_Cursor_Input>>;
  where?: InputMaybe<RReportingLearningContentSummaryInsights_Bool_Exp>;
};

export type RSubscriptionReportingLearningJourneyContentInsightsArgs = {
  distinct_on?: InputMaybe<Array<RReportingLearningJourneyContentInsights_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RReportingLearningJourneyContentInsights_Order_By>>;
  where?: InputMaybe<RReportingLearningJourneyContentInsights_Bool_Exp>;
};

export type RSubscriptionReportingLearningJourneyContentInsightsAggregateArgs = {
  distinct_on?: InputMaybe<Array<RReportingLearningJourneyContentInsights_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RReportingLearningJourneyContentInsights_Order_By>>;
  where?: InputMaybe<RReportingLearningJourneyContentInsights_Bool_Exp>;
};

export type RSubscriptionReportingLearningJourneyContentInsights_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<RReportingLearningJourneyContentInsights_Stream_Cursor_Input>>;
  where?: InputMaybe<RReportingLearningJourneyContentInsights_Bool_Exp>;
};

export type RSubscriptionReportingLearningJourneysItemsArgs = {
  args: RReportingLearningJourneysItems_Arguments;
  distinct_on?: InputMaybe<Array<RLearning_Journeys_Items_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RLearning_Journeys_Items_Order_By>>;
  where?: InputMaybe<RLearning_Journeys_Items_Bool_Exp_Bool_Exp>;
};

export type RSubscriptionReportingLearningJourneysItemsInsightsArgs = {
  args: RReportingLearningJourneysItemsInsights_Arguments;
  distinct_on?: InputMaybe<Array<RLearning_Journeys_Items_Insights_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RLearning_Journeys_Items_Insights_Order_By>>;
  where?: InputMaybe<RLearning_Journeys_Items_Insights_Bool_Exp_Bool_Exp>;
};

export type RSubscriptionReportingLearningJourneysItemsTotalCountArgs = {
  args: RReportingLearningJourneysItemsTotalCount_Arguments;
  distinct_on?: InputMaybe<Array<RTotal_Count_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RTotal_Count_Order_By>>;
  where?: InputMaybe<RTotal_Count_Bool_Exp_Bool_Exp>;
};

export type RSubscriptionReportingLearningsArgs = {
  args: RReportingLearnings_Arguments;
  distinct_on?: InputMaybe<Array<RLearnings_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RLearnings_Order_By>>;
  where?: InputMaybe<RLearnings_Bool_Exp_Bool_Exp>;
};

export type RSubscriptionReportingLearningsInsightsArgs = {
  args: RReportingLearningsInsights_Arguments;
  distinct_on?: InputMaybe<Array<RLearningsInsights_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RLearningsInsights_Order_By>>;
  where?: InputMaybe<RLearningsInsights_Bool_Exp_Bool_Exp>;
};

export type RSubscriptionReportingLearningsTotalCountArgs = {
  args: RReportingLearningsTotalCount_Arguments;
  distinct_on?: InputMaybe<Array<RTotal_Count_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RTotal_Count_Order_By>>;
  where?: InputMaybe<RTotal_Count_Bool_Exp_Bool_Exp>;
};

export type RSubscriptionReportingMyLearningJourneysInsightsV2Args = {
  args: RReportingMyLearningJourneysInsightsV2_Arguments;
  distinct_on?: InputMaybe<Array<RLearning_Journeys_Insights_V2_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RLearning_Journeys_Insights_V2_Order_By>>;
  where?: InputMaybe<RLearning_Journeys_Insights_V2_Bool_Exp_Bool_Exp>;
};

export type RSubscriptionReportingMyLearningJourneysLearnersArgs = {
  args: RReportingMyLearningJourneysLearners_Arguments;
  distinct_on?: InputMaybe<Array<RLearners_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RLearners_Order_By>>;
  where?: InputMaybe<RLearners_Bool_Exp_Bool_Exp>;
};

export type RSubscriptionReportingMyLearningJourneysLearnersTotalCountArgs = {
  args: RReportingMyLearningJourneysLearnersTotalCount_Arguments;
  distinct_on?: InputMaybe<Array<RTotal_Count_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RTotal_Count_Order_By>>;
  where?: InputMaybe<RTotal_Count_Bool_Exp_Bool_Exp>;
};

export type RSubscriptionReportingMyLearningJourneysTotalCountArgs = {
  args: RReportingMyLearningJourneysTotalCount_Arguments;
  distinct_on?: InputMaybe<Array<RTotal_Count_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RTotal_Count_Order_By>>;
  where?: InputMaybe<RTotal_Count_Bool_Exp_Bool_Exp>;
};

export type RSubscriptionReportingMyLearningJourneysV2Args = {
  args: RReportingMyLearningJourneysV2_Arguments;
  distinct_on?: InputMaybe<Array<RLearning_Journeys_V2_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RLearning_Journeys_V2_Order_By>>;
  where?: InputMaybe<RLearning_Journeys_V2_Bool_Exp_Bool_Exp>;
};

export type RSubscriptionReportingMyLearningsLearnersArgs = {
  args: RReportingMyLearningsLearners_Arguments;
  distinct_on?: InputMaybe<Array<RLearners_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RLearners_Order_By>>;
  where?: InputMaybe<RLearners_Bool_Exp_Bool_Exp>;
};

export type RSubscriptionReportingMyLearningsLearnersTotalCountArgs = {
  args: RReportingMyLearningsLearnersTotalCount_Arguments;
  distinct_on?: InputMaybe<Array<RTotal_Count_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RTotal_Count_Order_By>>;
  where?: InputMaybe<RTotal_Count_Bool_Exp_Bool_Exp>;
};

export type RSubscriptionReportingMyLearningsPracticalAssessmentArgs = {
  args: RReportingMyLearningsPracticalAssessment_Arguments;
  distinct_on?: InputMaybe<Array<RPractical_Assessment_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RPractical_Assessment_Order_By>>;
  where?: InputMaybe<RPractical_Assessment_Bool_Exp_Bool_Exp>;
};

export type RSubscriptionReportingMyLearningsQuizArgs = {
  args: RReportingMyLearningsQuiz_Arguments;
  distinct_on?: InputMaybe<Array<RQuiz_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RQuiz_Order_By>>;
  where?: InputMaybe<RQuiz_Bool_Exp_Bool_Exp>;
};

export type RSubscriptionReportingMyLearningsReactionsArgs = {
  args: RReportingMyLearningsReactions_Arguments;
  distinct_on?: InputMaybe<Array<RReactions_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RReactions_Order_By>>;
  where?: InputMaybe<RReactions_Bool_Exp_Bool_Exp>;
};

export type RSubscriptionReportingMyLearningsSurveyArgs = {
  args: RReportingMyLearningsSurvey_Arguments;
  distinct_on?: InputMaybe<Array<RSurvey_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RSurvey_Order_By>>;
  where?: InputMaybe<RSurvey_Bool_Exp_Bool_Exp>;
};

export type RSubscriptionReportingMyLiveLearningsLearnersArgs = {
  args: RReportingMyLiveLearningsLearners_Arguments;
  distinct_on?: InputMaybe<Array<RLive_Learnings_Learners_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RLive_Learnings_Learners_Order_By>>;
  where?: InputMaybe<RLive_Learnings_Learners_Bool_Exp_Bool_Exp>;
};

export type RSubscriptionReportingMyLiveLearningsLearnersTotalCountArgs = {
  args: RReportingMyLiveLearningsLearnersTotalCount_Arguments;
  distinct_on?: InputMaybe<Array<RTotal_Count_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RTotal_Count_Order_By>>;
  where?: InputMaybe<RTotal_Count_Bool_Exp_Bool_Exp>;
};

export type RSubscriptionReportingParticipantsInsightsArgs = {
  distinct_on?: InputMaybe<Array<RReportingParticipantsInsights_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RReportingParticipantsInsights_Order_By>>;
  where?: InputMaybe<RReportingParticipantsInsights_Bool_Exp>;
};

export type RSubscriptionReportingParticipantsInsightsAggregateArgs = {
  distinct_on?: InputMaybe<Array<RReportingParticipantsInsights_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RReportingParticipantsInsights_Order_By>>;
  where?: InputMaybe<RReportingParticipantsInsights_Bool_Exp>;
};

export type RSubscriptionReportingParticipantsInsights_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<RReportingParticipantsInsights_Stream_Cursor_Input>>;
  where?: InputMaybe<RReportingParticipantsInsights_Bool_Exp>;
};

export type RSubscriptionReportingPracticalAssessmentInsightsArgs = {
  distinct_on?: InputMaybe<Array<RReportingPracticalAssessmentInsights_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RReportingPracticalAssessmentInsights_Order_By>>;
  where?: InputMaybe<RReportingPracticalAssessmentInsights_Bool_Exp>;
};

export type RSubscriptionReportingPracticalAssessmentInsightsAggregateArgs = {
  distinct_on?: InputMaybe<Array<RReportingPracticalAssessmentInsights_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RReportingPracticalAssessmentInsights_Order_By>>;
  where?: InputMaybe<RReportingPracticalAssessmentInsights_Bool_Exp>;
};

export type RSubscriptionReportingPracticalAssessmentInsights_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<RReportingPracticalAssessmentInsights_Stream_Cursor_Input>>;
  where?: InputMaybe<RReportingPracticalAssessmentInsights_Bool_Exp>;
};

export type RSubscriptionReportingQuizQuestionsInsightsArgs = {
  distinct_on?: InputMaybe<Array<RReportingQuizQuestionsInsights_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RReportingQuizQuestionsInsights_Order_By>>;
  where?: InputMaybe<RReportingQuizQuestionsInsights_Bool_Exp>;
};

export type RSubscriptionReportingQuizQuestionsInsightsAggregateArgs = {
  distinct_on?: InputMaybe<Array<RReportingQuizQuestionsInsights_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RReportingQuizQuestionsInsights_Order_By>>;
  where?: InputMaybe<RReportingQuizQuestionsInsights_Bool_Exp>;
};

export type RSubscriptionReportingQuizQuestionsInsights_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<RReportingQuizQuestionsInsights_Stream_Cursor_Input>>;
  where?: InputMaybe<RReportingQuizQuestionsInsights_Bool_Exp>;
};

export type RSubscriptionReportingQuizSummaryInsightsArgs = {
  distinct_on?: InputMaybe<Array<RReportingQuizSummaryInsights_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RReportingQuizSummaryInsights_Order_By>>;
  where?: InputMaybe<RReportingQuizSummaryInsights_Bool_Exp>;
};

export type RSubscriptionReportingQuizSummaryInsightsAggregateArgs = {
  distinct_on?: InputMaybe<Array<RReportingQuizSummaryInsights_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RReportingQuizSummaryInsights_Order_By>>;
  where?: InputMaybe<RReportingQuizSummaryInsights_Bool_Exp>;
};

export type RSubscriptionReportingQuizSummaryInsights_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<RReportingQuizSummaryInsights_Stream_Cursor_Input>>;
  where?: InputMaybe<RReportingQuizSummaryInsights_Bool_Exp>;
};

export type RSubscriptionReportingReactionsInsightsArgs = {
  distinct_on?: InputMaybe<Array<RReportingReactionsInsights_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RReportingReactionsInsights_Order_By>>;
  where?: InputMaybe<RReportingReactionsInsights_Bool_Exp>;
};

export type RSubscriptionReportingReactionsInsightsAggregateArgs = {
  distinct_on?: InputMaybe<Array<RReportingReactionsInsights_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RReportingReactionsInsights_Order_By>>;
  where?: InputMaybe<RReportingReactionsInsights_Bool_Exp>;
};

export type RSubscriptionReportingReactionsInsights_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<RReportingReactionsInsights_Stream_Cursor_Input>>;
  where?: InputMaybe<RReportingReactionsInsights_Bool_Exp>;
};

export type RSubscriptionReportingSurveyAnswersArgs = {
  distinct_on?: InputMaybe<Array<RReportingSurveyAnswers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RReportingSurveyAnswers_Order_By>>;
  where?: InputMaybe<RReportingSurveyAnswers_Bool_Exp>;
};

export type RSubscriptionReportingSurveyAnswersAggregateArgs = {
  distinct_on?: InputMaybe<Array<RReportingSurveyAnswers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RReportingSurveyAnswers_Order_By>>;
  where?: InputMaybe<RReportingSurveyAnswers_Bool_Exp>;
};

export type RSubscriptionReportingSurveyAnswers_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<RReportingSurveyAnswers_Stream_Cursor_Input>>;
  where?: InputMaybe<RReportingSurveyAnswers_Bool_Exp>;
};

export type RTeam = {
  __typename?: 'Team';
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  parentTeamId?: Maybe<Scalars['Int']['output']>;
  parentTeams: RTeamsPage;
  realm: Scalars['String']['output'];
  subTeams: RTeamsPage;
  teamUsers: RTeamUsersPage;
  updatedAt: Scalars['DateTime']['output'];
};

export type RTeamParentTeamsArgs = {
  filter?: InputMaybe<RTeamRelsInput>;
  pagination?: InputMaybe<RPagination>;
  sort?: InputMaybe<RTeamsSort>;
};

export type RTeamSubTeamsArgs = {
  filter?: InputMaybe<RTeamRelsInput>;
  pagination?: InputMaybe<RPagination>;
  sort?: InputMaybe<RTeamsSort>;
};

export type RTeamTeamUsersArgs = {
  filter?: InputMaybe<RTeamsTeamUsersFilter>;
  pagination?: InputMaybe<RPagination>;
  sort?: InputMaybe<RTeamUsersSort>;
};

export type RTeamCreateInput = {
  name: Scalars['String']['input'];
  parentTeamId?: InputMaybe<Scalars['Int']['input']>;
  realm: Scalars['String']['input'];
};

export type RTeamRelsInput = {
  indirect?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum RTeamRole {
  Manager = 'manager',
  Member = 'member',
}

export type RTeamUpdateInput = {
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  parentTeamId?: InputMaybe<Scalars['Int']['input']>;
  realm: Scalars['String']['input'];
};

export type RTeamUser = {
  __typename?: 'TeamUser';
  createdAt: Scalars['DateTime']['output'];
  role: RTeamRole;
  team: RTeam;
  teamId: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: RUserV2;
  userId: Scalars['Int']['output'];
};

export type RTeamUserChangeRoleInput = {
  role: RTeamRole;
  teamId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};

export type RTeamUserRemoveInput = {
  teamId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};

export type RTeamUsersAddInput = {
  teamId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};

export type RTeamUsersFilter = {
  searchPhrase?: InputMaybe<Scalars['String']['input']>;
  teamIdIN: Array<Scalars['Int']['input']>;
  withSubteams?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RTeamUsersPage = {
  __typename?: 'TeamUsersPage';
  data: Array<RTeamUser>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type RTeamUsersSort = {
  field?: InputMaybe<RTeamUsersSortField>;
  order?: InputMaybe<RSortOrder>;
};

export enum RTeamUsersSortField {
  Email = 'EMAIL',
  Id = 'ID',
  Status = 'STATUS',
  Teamname = 'TEAMNAME',
  Username = 'USERNAME',
}

export type RTeamsFilter = {
  parentTeamEQ?: InputMaybe<RTeamsParentEqFilter>;
  realm?: InputMaybe<Scalars['String']['input']>;
  searchPhrase?: InputMaybe<Scalars['String']['input']>;
  teamNEQ?: InputMaybe<RTeamsTeamNeqFilter>;
};

export type RTeamsPage = {
  __typename?: 'TeamsPage';
  data: Array<RTeam>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type RTeamsParentEqFilter = {
  id?: InputMaybe<Scalars['Int']['input']>;
  indirect?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RTeamsSort = {
  field?: InputMaybe<RTeamsSortField>;
  order?: InputMaybe<RSortOrder>;
};

export enum RTeamsSortField {
  Id = 'ID',
  Name = 'NAME',
}

export type RTeamsTeamNeqFilter = {
  excludeAllSubteams?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type RTeamsTeamUsersFilter = {
  role?: InputMaybe<RTeamRole>;
  searchPhrase?: InputMaybe<Scalars['String']['input']>;
  withSubteams?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RTour = {
  __typename?: 'Tour';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type RTourUserCompletions = {
  __typename?: 'TourUserCompletions';
  createdAt: Scalars['DateTime']['output'];
  tourId: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userId: Scalars['Int']['output'];
};

export type RUpdateCollection = {
  contentOwner?: InputMaybe<Scalars['Int']['input']>;
  creator?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['ID']['input'];
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  languageId?: InputMaybe<Scalars['Int']['input']>;
  spaceId?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<RLearningStatus>;
  subjectId?: InputMaybe<Scalars['Int']['input']>;
  teaser?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  visibility?: InputMaybe<RLearningVisibility>;
};

export type RUpdateCollectionItems = {
  id: Scalars['ID']['input'];
  items: Array<InputMaybe<RCollectionItemInput>>;
};

export type RUpdateCompetenceInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type RUpdateLearningJourneyInput = {
  bannerURL?: InputMaybe<Scalars['String']['input']>;
  businessGoal?: InputMaybe<Scalars['String']['input']>;
  completionCriteria?: InputMaybe<RLearningJourneyCompletionStatus>;
  completionInvestTimeSec?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  discussionEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['Int']['input'];
  impactSurveysEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isLinear?: InputMaybe<Scalars['Boolean']['input']>;
  spaceFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<RLearningJourneyStatus>;
  subjectId?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type RUpdateResponse = {
  __typename?: 'UpdateResponse';
  orderId?: Maybe<Scalars['Int']['output']>;
};

export type RUpdateSectionInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  path: Array<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type RUpdateSelfTrackedLearningInput = {
  description: Scalars['String']['input'];
  effort: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
  link: Scalars['String']['input'];
  title: Scalars['String']['input'];
  type: RSelfTrackedLearningType;
};

export type RUpdateSpaceV2Input = {
  id: Scalars['Int']['input'];
  label: Scalars['String']['input'];
  name: Scalars['String']['input'];
  realm: Scalars['String']['input'];
};

export type RUpdateSpaceV2RoleAssignInput = {
  externalId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  role: RSpaceV2Role;
  roleDisplayName?: InputMaybe<Scalars['String']['input']>;
};

export type RUpsertAssignmentInput = {
  description: Scalars['String']['input'];
  endAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  learningLoopRecommendedEffort?: InputMaybe<Scalars['Int']['input']>;
  learnings?: InputMaybe<Array<RUpsertAssignmentLearningsInput>>;
  name: Scalars['String']['input'];
  rules?: InputMaybe<RAssignmentsV3RuleInput>;
  spaceId: Scalars['Int']['input'];
  startAt?: InputMaybe<Scalars['DateTime']['input']>;
  status: RAssignmentV3Status;
  surveyId?: InputMaybe<Scalars['String']['input']>;
  teaser: Scalars['String']['input'];
  totalThresholdEffort?: InputMaybe<Scalars['Int']['input']>;
  type: RAssignmentV3Type;
  users?: InputMaybe<Array<RUpsertAssignmentUsersInput>>;
};

export type RUpsertAssignmentLearningsInput = {
  learningId?: InputMaybe<Scalars['Int']['input']>;
  learningPathId?: InputMaybe<Scalars['Int']['input']>;
  order: Scalars['Int']['input'];
  type?: InputMaybe<RAssignmentStandardV3LearningType>;
};

export type RUpsertAssignmentUsersInput = {
  userId: Scalars['Int']['input'];
};

export type RUpsertFeatureToggleInput = {
  description: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  public: Scalars['Boolean']['input'];
};

export type RUpsertLearningInput = {
  activities?: InputMaybe<Array<RLearningActivityInputDto>>;
  learning: RLearningV2Input;
  locations?: InputMaybe<Array<RLocationV2Input>>;
  modules?: InputMaybe<Array<RModuleV2Input>>;
  subjects?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type RUpsertLearningJourneyCohortsInput = {
  journeyId: Scalars['Int']['input'];
  learnerIds: Array<Scalars['Int']['input']>;
};

export type RUpsertLearningJourneyCompetencesInput = {
  competenceIds: Array<Scalars['Int']['input']>;
  journeyId: Scalars['Int']['input'];
};

export type RUpsertLearningJourneyItemsInput = {
  itemIds: Array<Scalars['String']['input']>;
  journeyId: Scalars['Int']['input'];
};

export type RUser = {
  __typename?: 'User';
  acceptedExtraTerms?: Maybe<Scalars['Boolean']['output']>;
  acceptedGdpr?: Maybe<Scalars['Boolean']['output']>;
  acceptedTos?: Maybe<Scalars['Boolean']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  groups?: Maybe<Array<Maybe<RUserGroup>>>;
  hasPassword?: Maybe<Scalars['Boolean']['output']>;
  hasViewAccessToAdminPanel?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isDebugging?: Maybe<Scalars['Boolean']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  lastActive?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  passwordExpiryDate?: Maybe<Scalars['String']['output']>;
  profileImage?: Maybe<Scalars['String']['output']>;
  realm?: Maybe<Scalars['String']['output']>;
  status?: Maybe<RAccountStatus>;
  timestamp?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type RUserActivitySummary = {
  __typename?: 'UserActivitySummary';
  company?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  first_activity?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  last_activity?: Maybe<Scalars['String']['output']>;
  participations_completed?: Maybe<Scalars['Int']['output']>;
  participations_registered?: Maybe<Scalars['Int']['output']>;
  participations_started?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  total_effort_hours?: Maybe<Scalars['Float']['output']>;
};

export type RUserChangeInput = {
  acceptedExtraTerms?: InputMaybe<Scalars['Boolean']['input']>;
  acceptedGdpr?: InputMaybe<Scalars['Boolean']['input']>;
  acceptedTos?: InputMaybe<Scalars['Boolean']['input']>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  language?: InputMaybe<Scalars['String']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  profileImage?: InputMaybe<Scalars['Upload']['input']>;
  statusV2?: InputMaybe<RUserStatusV2>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type RUserChangeRealmRoleInput = {
  realm?: InputMaybe<Scalars['String']['input']>;
  role: RRealmRole;
  userId: Scalars['Int']['input'];
};

export type RUserEffort = {
  __typename?: 'UserEffort';
  total: Scalars['Float']['output'];
};

export type RUserEffortOverTime = {
  __typename?: 'UserEffortOverTime';
  average_effort_per_user_in_seconds?: Maybe<Scalars['Float']['output']>;
  completed?: Maybe<Scalars['Int']['output']>;
  completed_not_expired?: Maybe<Scalars['Int']['output']>;
  distinct_completed?: Maybe<Scalars['Int']['output']>;
  distinct_completed_not_expired?: Maybe<Scalars['Int']['output']>;
  distinct_users?: Maybe<Scalars['Int']['output']>;
  expired?: Maybe<Scalars['Int']['output']>;
  in_progress?: Maybe<Scalars['Int']['output']>;
  total_effort_in_seconds?: Maybe<Scalars['Int']['output']>;
};

export type RUserFeatureToggle = {
  __typename?: 'UserFeatureToggle';
  name: Scalars['String']['output'];
};

export type RUserGroup = {
  __typename?: 'UserGroup';
  id?: Maybe<Scalars['Int']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type RUserLearningActivityQuizQuestionAnswers = {
  questionId: Scalars['String']['input'];
  selectedOptionIds: Array<Scalars['String']['input']>;
};

export type RUserLearningActivityQuizSubmission = {
  __typename?: 'UserLearningActivityQuizSubmission';
  answers: Scalars['JSON']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  learningActivityId: Scalars['Int']['output'];
  quiz: Scalars['JSON']['output'];
  score: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['Int']['output'];
  version: Scalars['String']['output'];
};

export type RUserLearningActivityQuizSubmissionFilterInput = {
  includeOlderVersions: Scalars['Boolean']['input'];
  learningActivityQuizIdsIN: Array<Scalars['Int']['input']>;
};

export type RUserLearningActivityQuizSubmissionInput = {
  answers: Array<RUserLearningActivityQuizQuestionAnswers>;
  learningActivityId: Scalars['Int']['input'];
  version: Scalars['String']['input'];
};

export type RUserLearningSubmission = {
  __typename?: 'UserLearningSubmission';
  createdAt: Scalars['DateTime']['output'];
  data: Scalars['JSON']['output'];
  id: Scalars['Int']['output'];
  learningURNV1: Scalars['String']['output'];
  learningURNV2: Scalars['String']['output'];
  pageURNV1: Scalars['String']['output'];
  pageURNV2: Scalars['String']['output'];
  reviewAssignments: Array<RUserLearningSubmissionReviewAssignment>;
  reviews: Array<RUserLearningSubmissionReview>;
  type: RUserLearningSubmissionType;
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['Int']['output'];
};

export type RUserLearningSubmissionResult = {
  __typename?: 'UserLearningSubmissionResult';
  submission?: Maybe<RUserLearningSubmission>;
  success: Scalars['Boolean']['output'];
};

export type RUserLearningSubmissionReview = {
  __typename?: 'UserLearningSubmissionReview';
  createdAt: Scalars['DateTime']['output'];
  data: Scalars['JSON']['output'];
  id: Scalars['Int']['output'];
  reviewerId: Scalars['Int']['output'];
  submissionId: Scalars['Int']['output'];
  type: RUserLearningSubmissionReviewType;
  updatedAt: Scalars['DateTime']['output'];
};

export type RUserLearningSubmissionReviewAssignment = {
  __typename?: 'UserLearningSubmissionReviewAssignment';
  createdAt: Scalars['DateTime']['output'];
  expiryAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  reviewerId: Scalars['Int']['output'];
  submission: RUserLearningSubmission;
  submissionId: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type RUserLearningSubmissionReviewResult = {
  __typename?: 'UserLearningSubmissionReviewResult';
  review?: Maybe<RUserLearningSubmissionReview>;
  success: Scalars['Boolean']['output'];
};

export enum RUserLearningSubmissionReviewType {
  PracticalAssessmentReviewText = 'PRACTICAL_ASSESSMENT_REVIEW_TEXT',
}

export enum RUserLearningSubmissionType {
  PracticalAssessmentDocument = 'PRACTICAL_ASSESSMENT_DOCUMENT',
  PracticalAssessmentText = 'PRACTICAL_ASSESSMENT_TEXT',
}

export type RUserLearningSubmissionsFilter = {
  learningIdsIN: Array<Scalars['Int']['input']>;
  pageIdsIN: Array<Scalars['Int']['input']>;
  userIdsIN: Array<Scalars['Int']['input']>;
};

export type RUserList = {
  __typename?: 'UserList';
  /** Sorts and paginates the UserList based on analytics data. */
  analyticsSort: RUsersResult;
  /** Returns aggregated data on filtered participations for all users in the list. */
  participationAggregate: RParticipationAggregate;
  total: Scalars['Int']['output'];
  userIds: Array<Scalars['Int']['output']>;
};

export type RUserListAnalyticsSortArgs = {
  filter?: InputMaybe<RParticipationFilterInput>;
  pagination?: InputMaybe<RPaginationInput>;
  sort: RAnalyticsUserSortInput;
};

export type RUserListParticipationAggregateArgs = {
  filter?: InputMaybe<RParticipationFilterInput>;
};

export type RUserMeta = {
  __typename?: 'UserMeta';
  meta?: Maybe<Scalars['JSON']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['Int']['output']>;
};

export type RUserRealmRoleAssignment = {
  __typename?: 'UserRealmRoleAssignment';
  realm?: Maybe<Scalars['String']['output']>;
  role: RRealmRole;
};

export type RUserStats = {
  __typename?: 'UserStats';
  liked?: Maybe<Scalars['Int']['output']>;
};

export enum RUserStatus {
  Deactivated = 'deactivated',
  Incomplete = 'incomplete',
  Pending = 'pending',
  Registered = 'registered',
}

export enum RUserStatusV2 {
  Active = 'active',
  Deleted = 'deleted',
  Inactive = 'inactive',
}

export type RUserTeamsFilter = {
  role?: InputMaybe<RTeamRole>;
  searchPhrase?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['Int']['input'];
  withSubteams?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RUserV2 = {
  __typename?: 'UserV2';
  acceptedExtraTerms: Scalars['Boolean']['output'];
  acceptedGdpr: Scalars['Boolean']['output'];
  acceptedTos: Scalars['Boolean']['output'];
  email: Scalars['String']['output'];
  firstname?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  language?: Maybe<Scalars['String']['output']>;
  lastActive: Scalars['DateTime']['output'];
  lastPasswordUpdate: Scalars['DateTime']['output'];
  lastname?: Maybe<Scalars['String']['output']>;
  /** Returns aggregated data on filtered participations for the user. */
  participationAggregate: RParticipationAggregate;
  /** Sorts and paginates filtered participations for the user. */
  participations: RParticipationResult;
  profileImage?: Maybe<Scalars['String']['output']>;
  realm: Scalars['String']['output'];
  realmRoleAssignments: Array<RUserRealmRoleAssignment>;
  status: RUserStatus;
  statusV2: RUserStatusV2;
  teams: RTeamUsersPage;
  timestamp: Scalars['DateTime']['output'];
  username?: Maybe<Scalars['String']['output']>;
};

export type RUserV2ParticipationAggregateArgs = {
  filter?: InputMaybe<RParticipationFilterInput>;
};

export type RUserV2ParticipationsArgs = {
  filter?: InputMaybe<RParticipationFilterInput>;
  pagination?: InputMaybe<RPaginationInput>;
  sort?: InputMaybe<RParticipationSortInput>;
};

export type RUserV2TeamsArgs = {
  filter?: InputMaybe<RUsersUserTeams>;
  pagination?: InputMaybe<RPagination>;
  sort?: InputMaybe<RTeamUsersSort>;
};

export type RUsersFilter = {
  emailIN?: InputMaybe<Array<Scalars['String']['input']>>;
  idNIN?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  realm?: InputMaybe<Scalars['String']['input']>;
  realmRole?: InputMaybe<RRealmRole>;
  searchPhrase?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<InputMaybe<RUserStatus>>>;
  teamIdIN?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type RUsersPage = {
  __typename?: 'UsersPage';
  data: Array<RUserV2>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum RUsersPool {
  Realm = 'REALM',
  Subordinates = 'SUBORDINATES',
}

export type RUsersResult = {
  __typename?: 'UsersResult';
  data: Array<RUserV2>;
  pageInfo: RPageInfo;
};

export type RUsersSort = {
  field?: InputMaybe<RUsersSortField>;
  order?: InputMaybe<RSortOrder>;
};

export enum RUsersSortField {
  Email = 'EMAIL',
  Id = 'ID',
  Status = 'STATUS',
  Username = 'USERNAME',
}

export type RUsersUserTeams = {
  role?: InputMaybe<RTeamRole>;
  teamIdIN?: InputMaybe<Array<Scalars['Int']['input']>>;
  withSubteams?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RVariant = {
  __typename?: 'Variant';
  currency?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  flags?: Maybe<RProductFlags>;
  id?: Maybe<Scalars['Int']['output']>;
  meta?: Maybe<RProductMeta>;
  name: Scalars['String']['output'];
  price?: Maybe<Scalars['Int']['output']>;
  validFrom?: Maybe<Scalars['String']['output']>;
  validTo?: Maybe<Scalars['String']['output']>;
};

export type RVariantInput = {
  currency?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  flags?: InputMaybe<RProductFlagsInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
  meta?: InputMaybe<RProductMetaInput>;
  name: Scalars['String']['input'];
  price?: InputMaybe<Scalars['Int']['input']>;
  validFrom?: InputMaybe<Scalars['String']['input']>;
  validTo?: InputMaybe<Scalars['String']['input']>;
};

export type RVariantResponse = {
  __typename?: 'VariantResponse';
  value?: Maybe<Scalars['Boolean']['output']>;
};

export type RVariantRow = {
  __typename?: 'VariantRow';
  currency?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  flags?: Maybe<RProductFlags>;
  id?: Maybe<Scalars['Int']['output']>;
  meta?: Maybe<RProductMeta>;
  name: Scalars['String']['output'];
  price?: Maybe<Scalars['Int']['output']>;
  validFrom?: Maybe<Scalars['String']['output']>;
  validTo?: Maybe<Scalars['String']['output']>;
};

export enum RWaitlistType {
  Automatic = 'AUTOMATIC',
  Disabled = 'DISABLED',
}

export type RXapiSession = {
  __typename?: 'XAPISession';
  queryString?: Maybe<Scalars['String']['output']>;
  registration?: Maybe<Scalars['String']['output']>;
};

export type RYou = {
  __typename?: 'You';
  have?: Maybe<RUserStats>;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type RBigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']['input']>;
  _gt?: InputMaybe<Scalars['bigint']['input']>;
  _gte?: InputMaybe<Scalars['bigint']['input']>;
  _in?: InputMaybe<Array<Scalars['bigint']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['bigint']['input']>;
  _lte?: InputMaybe<Scalars['bigint']['input']>;
  _neq?: InputMaybe<Scalars['bigint']['input']>;
  _nin?: InputMaybe<Array<Scalars['bigint']['input']>>;
};

/** Boolean expression to compare columns of type "catalog_item_type". All fields are combined with logical 'AND'. */
export type RCatalog_Item_Type_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['catalog_item_type']['input']>;
  _gt?: InputMaybe<Scalars['catalog_item_type']['input']>;
  _gte?: InputMaybe<Scalars['catalog_item_type']['input']>;
  _in?: InputMaybe<Array<Scalars['catalog_item_type']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['catalog_item_type']['input']>;
  _lte?: InputMaybe<Scalars['catalog_item_type']['input']>;
  _neq?: InputMaybe<Scalars['catalog_item_type']['input']>;
  _nin?: InputMaybe<Array<Scalars['catalog_item_type']['input']>>;
};

/** columns and relationships of "clsapi.learning" */
export type RClsapi_Learning = {
  __typename?: 'clsapi_learning';
  archived?: Maybe<Scalars['timestamptz']['output']>;
  content_owner?: Maybe<Scalars['Int']['output']>;
  created?: Maybe<Scalars['timestamptz']['output']>;
  creator?: Maybe<Scalars['Int']['output']>;
  effort?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  language_id?: Maybe<Scalars['Int']['output']>;
  meta?: Maybe<Scalars['json']['output']>;
  preamble: Scalars['String']['output'];
  space_id?: Maybe<Scalars['Int']['output']>;
  status: Scalars['learning_status']['output'];
  teaser: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: Scalars['learning_type']['output'];
  updated?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['Int']['output']>;
  urn_v1?: Maybe<Scalars['String']['output']>;
  urn_v2?: Maybe<Scalars['String']['output']>;
  visibility?: Maybe<Scalars['learning_visibility']['output']>;
};

/** columns and relationships of "clsapi.learning" */
export type RClsapi_LearningMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "clsapi.learning" */
export type RClsapi_Learning_Aggregate = {
  __typename?: 'clsapi_learning_aggregate';
  aggregate?: Maybe<RClsapi_Learning_Aggregate_Fields>;
  nodes: Array<RClsapi_Learning>;
};

/** aggregate fields of "clsapi.learning" */
export type RClsapi_Learning_Aggregate_Fields = {
  __typename?: 'clsapi_learning_aggregate_fields';
  avg?: Maybe<RClsapi_Learning_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<RClsapi_Learning_Max_Fields>;
  min?: Maybe<RClsapi_Learning_Min_Fields>;
  stddev?: Maybe<RClsapi_Learning_Stddev_Fields>;
  stddev_pop?: Maybe<RClsapi_Learning_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<RClsapi_Learning_Stddev_Samp_Fields>;
  sum?: Maybe<RClsapi_Learning_Sum_Fields>;
  var_pop?: Maybe<RClsapi_Learning_Var_Pop_Fields>;
  var_samp?: Maybe<RClsapi_Learning_Var_Samp_Fields>;
  variance?: Maybe<RClsapi_Learning_Variance_Fields>;
};

/** aggregate fields of "clsapi.learning" */
export type RClsapi_Learning_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<RClsapi_Learning_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type RClsapi_Learning_Avg_Fields = {
  __typename?: 'clsapi_learning_avg_fields';
  content_owner?: Maybe<Scalars['Float']['output']>;
  creator?: Maybe<Scalars['Float']['output']>;
  effort?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  space_id?: Maybe<Scalars['Float']['output']>;
  updated_by?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "clsapi.learning". All fields are combined with a logical 'AND'. */
export type RClsapi_Learning_Bool_Exp = {
  _and?: InputMaybe<Array<RClsapi_Learning_Bool_Exp>>;
  _not?: InputMaybe<RClsapi_Learning_Bool_Exp>;
  _or?: InputMaybe<Array<RClsapi_Learning_Bool_Exp>>;
  archived?: InputMaybe<RTimestamptz_Comparison_Exp>;
  content_owner?: InputMaybe<RInt_Comparison_Exp>;
  created?: InputMaybe<RTimestamptz_Comparison_Exp>;
  creator?: InputMaybe<RInt_Comparison_Exp>;
  effort?: InputMaybe<RInt_Comparison_Exp>;
  id?: InputMaybe<RInt_Comparison_Exp>;
  image?: InputMaybe<RString_Comparison_Exp>;
  language_id?: InputMaybe<RInt_Comparison_Exp>;
  meta?: InputMaybe<RJson_Comparison_Exp>;
  preamble?: InputMaybe<RString_Comparison_Exp>;
  space_id?: InputMaybe<RInt_Comparison_Exp>;
  status?: InputMaybe<RLearning_Status_Comparison_Exp>;
  teaser?: InputMaybe<RString_Comparison_Exp>;
  title?: InputMaybe<RString_Comparison_Exp>;
  type?: InputMaybe<RLearning_Type_Comparison_Exp>;
  updated?: InputMaybe<RTimestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<RInt_Comparison_Exp>;
  urn_v1?: InputMaybe<RString_Comparison_Exp>;
  urn_v2?: InputMaybe<RString_Comparison_Exp>;
  visibility?: InputMaybe<RLearning_Visibility_Comparison_Exp>;
};

/** unique or primary key constraints on table "clsapi.learning" */
export enum RClsapi_Learning_Constraint {
  /** unique or primary key constraint on columns "id" */
  LearningPkey = 'learning_pkey',
  /** unique or primary key constraint on columns "urn_v1" */
  LearningUrnV1Idx = 'learning_urn_v1_idx',
  /** unique or primary key constraint on columns "urn_v2" */
  LearningUrnV2Idx = 'learning_urn_v2_idx',
}

/** input type for incrementing numeric columns in table "clsapi.learning" */
export type RClsapi_Learning_Inc_Input = {
  content_owner?: InputMaybe<Scalars['Int']['input']>;
  creator?: InputMaybe<Scalars['Int']['input']>;
  effort?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  language_id?: InputMaybe<Scalars['Int']['input']>;
  space_id?: InputMaybe<Scalars['Int']['input']>;
  updated_by?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "clsapi.learning" */
export type RClsapi_Learning_Insert_Input = {
  archived?: InputMaybe<Scalars['timestamptz']['input']>;
  content_owner?: InputMaybe<Scalars['Int']['input']>;
  created?: InputMaybe<Scalars['timestamptz']['input']>;
  creator?: InputMaybe<Scalars['Int']['input']>;
  effort?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  language_id?: InputMaybe<Scalars['Int']['input']>;
  meta?: InputMaybe<Scalars['json']['input']>;
  preamble?: InputMaybe<Scalars['String']['input']>;
  space_id?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['learning_status']['input']>;
  teaser?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['learning_type']['input']>;
  updated?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['Int']['input']>;
  visibility?: InputMaybe<Scalars['learning_visibility']['input']>;
};

/** columns and relationships of "clsapi.learning_journeys" */
export type RClsapi_Learning_Journeys = {
  __typename?: 'clsapi_learning_journeys';
  banner_url: Scalars['String']['output'];
  business_goal: Scalars['String']['output'];
  completion_criteria: Scalars['learning_journey_completion_criteria']['output'];
  completion_invest_time?: Maybe<Scalars['Int']['output']>;
  created_at: Scalars['timestamptz']['output'];
  description: Scalars['String']['output'];
  discussion_enabled: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  impact_surveys_enabled: Scalars['Boolean']['output'];
  is_linear: Scalars['Boolean']['output'];
  source_template_id?: Maybe<Scalars['Int']['output']>;
  space_featured: Scalars['Boolean']['output'];
  space_id: Scalars['Int']['output'];
  status: Scalars['learning_journey_status']['output'];
  subject_id?: Maybe<Scalars['Int']['output']>;
  title: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
  updated_by?: Maybe<Scalars['Int']['output']>;
  urn?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "clsapi.learning_journeys" */
export type RClsapi_Learning_Journeys_Aggregate = {
  __typename?: 'clsapi_learning_journeys_aggregate';
  aggregate?: Maybe<RClsapi_Learning_Journeys_Aggregate_Fields>;
  nodes: Array<RClsapi_Learning_Journeys>;
};

/** aggregate fields of "clsapi.learning_journeys" */
export type RClsapi_Learning_Journeys_Aggregate_Fields = {
  __typename?: 'clsapi_learning_journeys_aggregate_fields';
  avg?: Maybe<RClsapi_Learning_Journeys_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<RClsapi_Learning_Journeys_Max_Fields>;
  min?: Maybe<RClsapi_Learning_Journeys_Min_Fields>;
  stddev?: Maybe<RClsapi_Learning_Journeys_Stddev_Fields>;
  stddev_pop?: Maybe<RClsapi_Learning_Journeys_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<RClsapi_Learning_Journeys_Stddev_Samp_Fields>;
  sum?: Maybe<RClsapi_Learning_Journeys_Sum_Fields>;
  var_pop?: Maybe<RClsapi_Learning_Journeys_Var_Pop_Fields>;
  var_samp?: Maybe<RClsapi_Learning_Journeys_Var_Samp_Fields>;
  variance?: Maybe<RClsapi_Learning_Journeys_Variance_Fields>;
};

/** aggregate fields of "clsapi.learning_journeys" */
export type RClsapi_Learning_Journeys_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<RClsapi_Learning_Journeys_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type RClsapi_Learning_Journeys_Avg_Fields = {
  __typename?: 'clsapi_learning_journeys_avg_fields';
  completion_invest_time?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  source_template_id?: Maybe<Scalars['Float']['output']>;
  space_id?: Maybe<Scalars['Float']['output']>;
  subject_id?: Maybe<Scalars['Float']['output']>;
  updated_by?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "clsapi.learning_journeys". All fields are combined with a logical 'AND'. */
export type RClsapi_Learning_Journeys_Bool_Exp = {
  _and?: InputMaybe<Array<RClsapi_Learning_Journeys_Bool_Exp>>;
  _not?: InputMaybe<RClsapi_Learning_Journeys_Bool_Exp>;
  _or?: InputMaybe<Array<RClsapi_Learning_Journeys_Bool_Exp>>;
  banner_url?: InputMaybe<RString_Comparison_Exp>;
  business_goal?: InputMaybe<RString_Comparison_Exp>;
  completion_criteria?: InputMaybe<RLearning_Journey_Completion_Criteria_Comparison_Exp>;
  completion_invest_time?: InputMaybe<RInt_Comparison_Exp>;
  created_at?: InputMaybe<RTimestamptz_Comparison_Exp>;
  description?: InputMaybe<RString_Comparison_Exp>;
  discussion_enabled?: InputMaybe<RBoolean_Comparison_Exp>;
  id?: InputMaybe<RInt_Comparison_Exp>;
  impact_surveys_enabled?: InputMaybe<RBoolean_Comparison_Exp>;
  is_linear?: InputMaybe<RBoolean_Comparison_Exp>;
  source_template_id?: InputMaybe<RInt_Comparison_Exp>;
  space_featured?: InputMaybe<RBoolean_Comparison_Exp>;
  space_id?: InputMaybe<RInt_Comparison_Exp>;
  status?: InputMaybe<RLearning_Journey_Status_Comparison_Exp>;
  subject_id?: InputMaybe<RInt_Comparison_Exp>;
  title?: InputMaybe<RString_Comparison_Exp>;
  updated_at?: InputMaybe<RTimestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<RInt_Comparison_Exp>;
  urn?: InputMaybe<RString_Comparison_Exp>;
};

/** unique or primary key constraints on table "clsapi.learning_journeys" */
export enum RClsapi_Learning_Journeys_Constraint {
  /** unique or primary key constraint on columns "urn" */
  IdxLearningJourneysUrn = 'idx_learning_journeys_urn',
  /** unique or primary key constraint on columns "id" */
  LearningJourneysPk = 'learning_journeys_pk',
}

/** input type for incrementing numeric columns in table "clsapi.learning_journeys" */
export type RClsapi_Learning_Journeys_Inc_Input = {
  completion_invest_time?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  source_template_id?: InputMaybe<Scalars['Int']['input']>;
  space_id?: InputMaybe<Scalars['Int']['input']>;
  subject_id?: InputMaybe<Scalars['Int']['input']>;
  updated_by?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "clsapi.learning_journeys" */
export type RClsapi_Learning_Journeys_Insert_Input = {
  banner_url?: InputMaybe<Scalars['String']['input']>;
  business_goal?: InputMaybe<Scalars['String']['input']>;
  completion_criteria?: InputMaybe<Scalars['learning_journey_completion_criteria']['input']>;
  completion_invest_time?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  discussion_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  impact_surveys_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  is_linear?: InputMaybe<Scalars['Boolean']['input']>;
  source_template_id?: InputMaybe<Scalars['Int']['input']>;
  space_featured?: InputMaybe<Scalars['Boolean']['input']>;
  space_id?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['learning_journey_status']['input']>;
  subject_id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type RClsapi_Learning_Journeys_Max_Fields = {
  __typename?: 'clsapi_learning_journeys_max_fields';
  banner_url?: Maybe<Scalars['String']['output']>;
  business_goal?: Maybe<Scalars['String']['output']>;
  completion_criteria?: Maybe<Scalars['learning_journey_completion_criteria']['output']>;
  completion_invest_time?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  source_template_id?: Maybe<Scalars['Int']['output']>;
  space_id?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['learning_journey_status']['output']>;
  subject_id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['Int']['output']>;
  urn?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type RClsapi_Learning_Journeys_Min_Fields = {
  __typename?: 'clsapi_learning_journeys_min_fields';
  banner_url?: Maybe<Scalars['String']['output']>;
  business_goal?: Maybe<Scalars['String']['output']>;
  completion_criteria?: Maybe<Scalars['learning_journey_completion_criteria']['output']>;
  completion_invest_time?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  source_template_id?: Maybe<Scalars['Int']['output']>;
  space_id?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['learning_journey_status']['output']>;
  subject_id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['Int']['output']>;
  urn?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "clsapi.learning_journeys" */
export type RClsapi_Learning_Journeys_Mutation_Response = {
  __typename?: 'clsapi_learning_journeys_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<RClsapi_Learning_Journeys>;
};

/** on_conflict condition type for table "clsapi.learning_journeys" */
export type RClsapi_Learning_Journeys_On_Conflict = {
  constraint: RClsapi_Learning_Journeys_Constraint;
  update_columns?: Array<RClsapi_Learning_Journeys_Update_Column>;
  where?: InputMaybe<RClsapi_Learning_Journeys_Bool_Exp>;
};

/** Ordering options when selecting data from "clsapi.learning_journeys". */
export type RClsapi_Learning_Journeys_Order_By = {
  banner_url?: InputMaybe<ROrder_By>;
  business_goal?: InputMaybe<ROrder_By>;
  completion_criteria?: InputMaybe<ROrder_By>;
  completion_invest_time?: InputMaybe<ROrder_By>;
  created_at?: InputMaybe<ROrder_By>;
  description?: InputMaybe<ROrder_By>;
  discussion_enabled?: InputMaybe<ROrder_By>;
  id?: InputMaybe<ROrder_By>;
  impact_surveys_enabled?: InputMaybe<ROrder_By>;
  is_linear?: InputMaybe<ROrder_By>;
  source_template_id?: InputMaybe<ROrder_By>;
  space_featured?: InputMaybe<ROrder_By>;
  space_id?: InputMaybe<ROrder_By>;
  status?: InputMaybe<ROrder_By>;
  subject_id?: InputMaybe<ROrder_By>;
  title?: InputMaybe<ROrder_By>;
  updated_at?: InputMaybe<ROrder_By>;
  updated_by?: InputMaybe<ROrder_By>;
  urn?: InputMaybe<ROrder_By>;
};

/** primary key columns input for table: clsapi.learning_journeys */
export type RClsapi_Learning_Journeys_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "clsapi.learning_journeys" */
export enum RClsapi_Learning_Journeys_Select_Column {
  /** column name */
  BannerUrl = 'banner_url',
  /** column name */
  BusinessGoal = 'business_goal',
  /** column name */
  CompletionCriteria = 'completion_criteria',
  /** column name */
  CompletionInvestTime = 'completion_invest_time',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  DiscussionEnabled = 'discussion_enabled',
  /** column name */
  Id = 'id',
  /** column name */
  ImpactSurveysEnabled = 'impact_surveys_enabled',
  /** column name */
  IsLinear = 'is_linear',
  /** column name */
  SourceTemplateId = 'source_template_id',
  /** column name */
  SpaceFeatured = 'space_featured',
  /** column name */
  SpaceId = 'space_id',
  /** column name */
  Status = 'status',
  /** column name */
  SubjectId = 'subject_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Urn = 'urn',
}

/** input type for updating data in table "clsapi.learning_journeys" */
export type RClsapi_Learning_Journeys_Set_Input = {
  banner_url?: InputMaybe<Scalars['String']['input']>;
  business_goal?: InputMaybe<Scalars['String']['input']>;
  completion_criteria?: InputMaybe<Scalars['learning_journey_completion_criteria']['input']>;
  completion_invest_time?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  discussion_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  impact_surveys_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  is_linear?: InputMaybe<Scalars['Boolean']['input']>;
  source_template_id?: InputMaybe<Scalars['Int']['input']>;
  space_featured?: InputMaybe<Scalars['Boolean']['input']>;
  space_id?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['learning_journey_status']['input']>;
  subject_id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type RClsapi_Learning_Journeys_Stddev_Fields = {
  __typename?: 'clsapi_learning_journeys_stddev_fields';
  completion_invest_time?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  source_template_id?: Maybe<Scalars['Float']['output']>;
  space_id?: Maybe<Scalars['Float']['output']>;
  subject_id?: Maybe<Scalars['Float']['output']>;
  updated_by?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type RClsapi_Learning_Journeys_Stddev_Pop_Fields = {
  __typename?: 'clsapi_learning_journeys_stddev_pop_fields';
  completion_invest_time?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  source_template_id?: Maybe<Scalars['Float']['output']>;
  space_id?: Maybe<Scalars['Float']['output']>;
  subject_id?: Maybe<Scalars['Float']['output']>;
  updated_by?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type RClsapi_Learning_Journeys_Stddev_Samp_Fields = {
  __typename?: 'clsapi_learning_journeys_stddev_samp_fields';
  completion_invest_time?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  source_template_id?: Maybe<Scalars['Float']['output']>;
  space_id?: Maybe<Scalars['Float']['output']>;
  subject_id?: Maybe<Scalars['Float']['output']>;
  updated_by?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "clsapi_learning_journeys" */
export type RClsapi_Learning_Journeys_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: RClsapi_Learning_Journeys_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<RCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type RClsapi_Learning_Journeys_Stream_Cursor_Value_Input = {
  banner_url?: InputMaybe<Scalars['String']['input']>;
  business_goal?: InputMaybe<Scalars['String']['input']>;
  completion_criteria?: InputMaybe<Scalars['learning_journey_completion_criteria']['input']>;
  completion_invest_time?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  discussion_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  impact_surveys_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  is_linear?: InputMaybe<Scalars['Boolean']['input']>;
  source_template_id?: InputMaybe<Scalars['Int']['input']>;
  space_featured?: InputMaybe<Scalars['Boolean']['input']>;
  space_id?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['learning_journey_status']['input']>;
  subject_id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['Int']['input']>;
  urn?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type RClsapi_Learning_Journeys_Sum_Fields = {
  __typename?: 'clsapi_learning_journeys_sum_fields';
  completion_invest_time?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  source_template_id?: Maybe<Scalars['Int']['output']>;
  space_id?: Maybe<Scalars['Int']['output']>;
  subject_id?: Maybe<Scalars['Int']['output']>;
  updated_by?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "clsapi.learning_journeys" */
export enum RClsapi_Learning_Journeys_Update_Column {
  /** column name */
  BannerUrl = 'banner_url',
  /** column name */
  BusinessGoal = 'business_goal',
  /** column name */
  CompletionCriteria = 'completion_criteria',
  /** column name */
  CompletionInvestTime = 'completion_invest_time',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  DiscussionEnabled = 'discussion_enabled',
  /** column name */
  Id = 'id',
  /** column name */
  ImpactSurveysEnabled = 'impact_surveys_enabled',
  /** column name */
  IsLinear = 'is_linear',
  /** column name */
  SourceTemplateId = 'source_template_id',
  /** column name */
  SpaceFeatured = 'space_featured',
  /** column name */
  SpaceId = 'space_id',
  /** column name */
  Status = 'status',
  /** column name */
  SubjectId = 'subject_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
}

export type RClsapi_Learning_Journeys_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<RClsapi_Learning_Journeys_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RClsapi_Learning_Journeys_Set_Input>;
  /** filter the rows which have to be updated */
  where: RClsapi_Learning_Journeys_Bool_Exp;
};

/** aggregate var_pop on columns */
export type RClsapi_Learning_Journeys_Var_Pop_Fields = {
  __typename?: 'clsapi_learning_journeys_var_pop_fields';
  completion_invest_time?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  source_template_id?: Maybe<Scalars['Float']['output']>;
  space_id?: Maybe<Scalars['Float']['output']>;
  subject_id?: Maybe<Scalars['Float']['output']>;
  updated_by?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type RClsapi_Learning_Journeys_Var_Samp_Fields = {
  __typename?: 'clsapi_learning_journeys_var_samp_fields';
  completion_invest_time?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  source_template_id?: Maybe<Scalars['Float']['output']>;
  space_id?: Maybe<Scalars['Float']['output']>;
  subject_id?: Maybe<Scalars['Float']['output']>;
  updated_by?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type RClsapi_Learning_Journeys_Variance_Fields = {
  __typename?: 'clsapi_learning_journeys_variance_fields';
  completion_invest_time?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  source_template_id?: Maybe<Scalars['Float']['output']>;
  space_id?: Maybe<Scalars['Float']['output']>;
  subject_id?: Maybe<Scalars['Float']['output']>;
  updated_by?: Maybe<Scalars['Float']['output']>;
};

/** aggregate max on columns */
export type RClsapi_Learning_Max_Fields = {
  __typename?: 'clsapi_learning_max_fields';
  archived?: Maybe<Scalars['timestamptz']['output']>;
  content_owner?: Maybe<Scalars['Int']['output']>;
  created?: Maybe<Scalars['timestamptz']['output']>;
  creator?: Maybe<Scalars['Int']['output']>;
  effort?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  language_id?: Maybe<Scalars['Int']['output']>;
  preamble?: Maybe<Scalars['String']['output']>;
  space_id?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['learning_status']['output']>;
  teaser?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['learning_type']['output']>;
  updated?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['Int']['output']>;
  urn_v1?: Maybe<Scalars['String']['output']>;
  urn_v2?: Maybe<Scalars['String']['output']>;
  visibility?: Maybe<Scalars['learning_visibility']['output']>;
};

/** aggregate min on columns */
export type RClsapi_Learning_Min_Fields = {
  __typename?: 'clsapi_learning_min_fields';
  archived?: Maybe<Scalars['timestamptz']['output']>;
  content_owner?: Maybe<Scalars['Int']['output']>;
  created?: Maybe<Scalars['timestamptz']['output']>;
  creator?: Maybe<Scalars['Int']['output']>;
  effort?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  language_id?: Maybe<Scalars['Int']['output']>;
  preamble?: Maybe<Scalars['String']['output']>;
  space_id?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['learning_status']['output']>;
  teaser?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['learning_type']['output']>;
  updated?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['Int']['output']>;
  urn_v1?: Maybe<Scalars['String']['output']>;
  urn_v2?: Maybe<Scalars['String']['output']>;
  visibility?: Maybe<Scalars['learning_visibility']['output']>;
};

/** response of any mutation on the table "clsapi.learning" */
export type RClsapi_Learning_Mutation_Response = {
  __typename?: 'clsapi_learning_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<RClsapi_Learning>;
};

/** on_conflict condition type for table "clsapi.learning" */
export type RClsapi_Learning_On_Conflict = {
  constraint: RClsapi_Learning_Constraint;
  update_columns?: Array<RClsapi_Learning_Update_Column>;
  where?: InputMaybe<RClsapi_Learning_Bool_Exp>;
};

/** Ordering options when selecting data from "clsapi.learning". */
export type RClsapi_Learning_Order_By = {
  archived?: InputMaybe<ROrder_By>;
  content_owner?: InputMaybe<ROrder_By>;
  created?: InputMaybe<ROrder_By>;
  creator?: InputMaybe<ROrder_By>;
  effort?: InputMaybe<ROrder_By>;
  id?: InputMaybe<ROrder_By>;
  image?: InputMaybe<ROrder_By>;
  language_id?: InputMaybe<ROrder_By>;
  meta?: InputMaybe<ROrder_By>;
  preamble?: InputMaybe<ROrder_By>;
  space_id?: InputMaybe<ROrder_By>;
  status?: InputMaybe<ROrder_By>;
  teaser?: InputMaybe<ROrder_By>;
  title?: InputMaybe<ROrder_By>;
  type?: InputMaybe<ROrder_By>;
  updated?: InputMaybe<ROrder_By>;
  updated_by?: InputMaybe<ROrder_By>;
  urn_v1?: InputMaybe<ROrder_By>;
  urn_v2?: InputMaybe<ROrder_By>;
  visibility?: InputMaybe<ROrder_By>;
};

/** primary key columns input for table: clsapi.learning */
export type RClsapi_Learning_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "clsapi.learning" */
export enum RClsapi_Learning_Select_Column {
  /** column name */
  Archived = 'archived',
  /** column name */
  ContentOwner = 'content_owner',
  /** column name */
  Created = 'created',
  /** column name */
  Creator = 'creator',
  /** column name */
  Effort = 'effort',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  LanguageId = 'language_id',
  /** column name */
  Meta = 'meta',
  /** column name */
  Preamble = 'preamble',
  /** column name */
  SpaceId = 'space_id',
  /** column name */
  Status = 'status',
  /** column name */
  Teaser = 'teaser',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  Updated = 'updated',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  UrnV1 = 'urn_v1',
  /** column name */
  UrnV2 = 'urn_v2',
  /** column name */
  Visibility = 'visibility',
}

/** input type for updating data in table "clsapi.learning" */
export type RClsapi_Learning_Set_Input = {
  archived?: InputMaybe<Scalars['timestamptz']['input']>;
  content_owner?: InputMaybe<Scalars['Int']['input']>;
  created?: InputMaybe<Scalars['timestamptz']['input']>;
  creator?: InputMaybe<Scalars['Int']['input']>;
  effort?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  language_id?: InputMaybe<Scalars['Int']['input']>;
  meta?: InputMaybe<Scalars['json']['input']>;
  preamble?: InputMaybe<Scalars['String']['input']>;
  space_id?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['learning_status']['input']>;
  teaser?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['learning_type']['input']>;
  updated?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['Int']['input']>;
  visibility?: InputMaybe<Scalars['learning_visibility']['input']>;
};

/** aggregate stddev on columns */
export type RClsapi_Learning_Stddev_Fields = {
  __typename?: 'clsapi_learning_stddev_fields';
  content_owner?: Maybe<Scalars['Float']['output']>;
  creator?: Maybe<Scalars['Float']['output']>;
  effort?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  space_id?: Maybe<Scalars['Float']['output']>;
  updated_by?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type RClsapi_Learning_Stddev_Pop_Fields = {
  __typename?: 'clsapi_learning_stddev_pop_fields';
  content_owner?: Maybe<Scalars['Float']['output']>;
  creator?: Maybe<Scalars['Float']['output']>;
  effort?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  space_id?: Maybe<Scalars['Float']['output']>;
  updated_by?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type RClsapi_Learning_Stddev_Samp_Fields = {
  __typename?: 'clsapi_learning_stddev_samp_fields';
  content_owner?: Maybe<Scalars['Float']['output']>;
  creator?: Maybe<Scalars['Float']['output']>;
  effort?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  space_id?: Maybe<Scalars['Float']['output']>;
  updated_by?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "clsapi_learning" */
export type RClsapi_Learning_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: RClsapi_Learning_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<RCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type RClsapi_Learning_Stream_Cursor_Value_Input = {
  archived?: InputMaybe<Scalars['timestamptz']['input']>;
  content_owner?: InputMaybe<Scalars['Int']['input']>;
  created?: InputMaybe<Scalars['timestamptz']['input']>;
  creator?: InputMaybe<Scalars['Int']['input']>;
  effort?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  language_id?: InputMaybe<Scalars['Int']['input']>;
  meta?: InputMaybe<Scalars['json']['input']>;
  preamble?: InputMaybe<Scalars['String']['input']>;
  space_id?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['learning_status']['input']>;
  teaser?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['learning_type']['input']>;
  updated?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['Int']['input']>;
  urn_v1?: InputMaybe<Scalars['String']['input']>;
  urn_v2?: InputMaybe<Scalars['String']['input']>;
  visibility?: InputMaybe<Scalars['learning_visibility']['input']>;
};

/** aggregate sum on columns */
export type RClsapi_Learning_Sum_Fields = {
  __typename?: 'clsapi_learning_sum_fields';
  content_owner?: Maybe<Scalars['Int']['output']>;
  creator?: Maybe<Scalars['Int']['output']>;
  effort?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  language_id?: Maybe<Scalars['Int']['output']>;
  space_id?: Maybe<Scalars['Int']['output']>;
  updated_by?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "clsapi.learning" */
export enum RClsapi_Learning_Update_Column {
  /** column name */
  Archived = 'archived',
  /** column name */
  ContentOwner = 'content_owner',
  /** column name */
  Created = 'created',
  /** column name */
  Creator = 'creator',
  /** column name */
  Effort = 'effort',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  LanguageId = 'language_id',
  /** column name */
  Meta = 'meta',
  /** column name */
  Preamble = 'preamble',
  /** column name */
  SpaceId = 'space_id',
  /** column name */
  Status = 'status',
  /** column name */
  Teaser = 'teaser',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  Updated = 'updated',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Visibility = 'visibility',
}

export type RClsapi_Learning_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<RClsapi_Learning_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RClsapi_Learning_Set_Input>;
  /** filter the rows which have to be updated */
  where: RClsapi_Learning_Bool_Exp;
};

/** aggregate var_pop on columns */
export type RClsapi_Learning_Var_Pop_Fields = {
  __typename?: 'clsapi_learning_var_pop_fields';
  content_owner?: Maybe<Scalars['Float']['output']>;
  creator?: Maybe<Scalars['Float']['output']>;
  effort?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  space_id?: Maybe<Scalars['Float']['output']>;
  updated_by?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type RClsapi_Learning_Var_Samp_Fields = {
  __typename?: 'clsapi_learning_var_samp_fields';
  content_owner?: Maybe<Scalars['Float']['output']>;
  creator?: Maybe<Scalars['Float']['output']>;
  effort?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  space_id?: Maybe<Scalars['Float']['output']>;
  updated_by?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type RClsapi_Learning_Variance_Fields = {
  __typename?: 'clsapi_learning_variance_fields';
  content_owner?: Maybe<Scalars['Float']['output']>;
  creator?: Maybe<Scalars['Float']['output']>;
  effort?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  space_id?: Maybe<Scalars['Float']['output']>;
  updated_by?: Maybe<Scalars['Float']['output']>;
};

/** ordering argument of a cursor */
export enum RCursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC',
}

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type RFloat8_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['float8']['input']>;
  _gt?: InputMaybe<Scalars['float8']['input']>;
  _gte?: InputMaybe<Scalars['float8']['input']>;
  _in?: InputMaybe<Array<Scalars['float8']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['float8']['input']>;
  _lte?: InputMaybe<Scalars['float8']['input']>;
  _neq?: InputMaybe<Scalars['float8']['input']>;
  _nin?: InputMaybe<Array<Scalars['float8']['input']>>;
};

/** Boolean expression to compare columns of type "json". All fields are combined with logical 'AND'. */
export type RJson_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['json']['input']>;
  _gt?: InputMaybe<Scalars['json']['input']>;
  _gte?: InputMaybe<Scalars['json']['input']>;
  _in?: InputMaybe<Array<Scalars['json']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['json']['input']>;
  _lte?: InputMaybe<Scalars['json']['input']>;
  _neq?: InputMaybe<Scalars['json']['input']>;
  _nin?: InputMaybe<Array<Scalars['json']['input']>>;
};

export type RJsonb_Cast_Exp = {
  String?: InputMaybe<RString_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type RJsonb_Comparison_Exp = {
  _cast?: InputMaybe<RJsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']['input']>;
  _eq?: InputMaybe<Scalars['jsonb']['input']>;
  _gt?: InputMaybe<Scalars['jsonb']['input']>;
  _gte?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']['input']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']['input']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']['input']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['jsonb']['input']>;
  _lte?: InputMaybe<Scalars['jsonb']['input']>;
  _neq?: InputMaybe<Scalars['jsonb']['input']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']['input']>>;
};

export type RLearners = {
  __typename?: 'learners';
  completion_time?: Maybe<Scalars['timestamptz']['output']>;
  email: Scalars['String']['output'];
  is_completed: Scalars['Boolean']['output'];
  join_time?: Maybe<Scalars['timestamptz']['output']>;
  last_activity_time?: Maybe<Scalars['timestamptz']['output']>;
  profile_image?: Maybe<Scalars['String']['output']>;
  progress?: Maybe<Scalars['numeric']['output']>;
  space_ids: Scalars['jsonb']['output'];
  start_time?: Maybe<Scalars['timestamptz']['output']>;
  team_ids?: Maybe<Scalars['jsonb']['output']>;
  user_id: Scalars['Int']['output'];
  username: Scalars['String']['output'];
};

export type RLearnersSpace_IdsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

export type RLearnersTeam_IdsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the logical model for "learners". All fields are combined with a logical 'AND'. */
export type RLearners_Bool_Exp_Bool_Exp = {
  _and?: InputMaybe<Array<RLearners_Bool_Exp_Bool_Exp>>;
  _not?: InputMaybe<RLearners_Bool_Exp_Bool_Exp>;
  _or?: InputMaybe<Array<RLearners_Bool_Exp_Bool_Exp>>;
  completion_time?: InputMaybe<RTimestamptz_Comparison_Exp>;
  email?: InputMaybe<RString_Comparison_Exp>;
  is_completed?: InputMaybe<RBoolean_Comparison_Exp>;
  join_time?: InputMaybe<RTimestamptz_Comparison_Exp>;
  last_activity_time?: InputMaybe<RTimestamptz_Comparison_Exp>;
  profile_image?: InputMaybe<RString_Comparison_Exp>;
  progress?: InputMaybe<RNumeric_Comparison_Exp>;
  space_ids?: InputMaybe<RJsonb_Comparison_Exp>;
  start_time?: InputMaybe<RTimestamptz_Comparison_Exp>;
  team_ids?: InputMaybe<RJsonb_Comparison_Exp>;
  user_id?: InputMaybe<RInt_Comparison_Exp>;
  username?: InputMaybe<RString_Comparison_Exp>;
};

export enum RLearners_Enum_Name {
  /** column name */
  CompletionTime = 'completion_time',
  /** column name */
  Email = 'email',
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  JoinTime = 'join_time',
  /** column name */
  LastActivityTime = 'last_activity_time',
  /** column name */
  ProfileImage = 'profile_image',
  /** column name */
  Progress = 'progress',
  /** column name */
  SpaceIds = 'space_ids',
  /** column name */
  StartTime = 'start_time',
  /** column name */
  TeamIds = 'team_ids',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Username = 'username',
}

/** Ordering options when selecting data from "learners". */
export type RLearners_Order_By = {
  completion_time?: InputMaybe<ROrder_By>;
  email?: InputMaybe<ROrder_By>;
  is_completed?: InputMaybe<ROrder_By>;
  join_time?: InputMaybe<ROrder_By>;
  last_activity_time?: InputMaybe<ROrder_By>;
  profile_image?: InputMaybe<ROrder_By>;
  progress?: InputMaybe<ROrder_By>;
  space_ids?: InputMaybe<ROrder_By>;
  start_time?: InputMaybe<ROrder_By>;
  team_ids?: InputMaybe<ROrder_By>;
  user_id?: InputMaybe<ROrder_By>;
  username?: InputMaybe<ROrder_By>;
};

/** Boolean expression to compare columns of type "learning_journey_completion_criteria". All fields are combined with logical 'AND'. */
export type RLearning_Journey_Completion_Criteria_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['learning_journey_completion_criteria']['input']>;
  _gt?: InputMaybe<Scalars['learning_journey_completion_criteria']['input']>;
  _gte?: InputMaybe<Scalars['learning_journey_completion_criteria']['input']>;
  _in?: InputMaybe<Array<Scalars['learning_journey_completion_criteria']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['learning_journey_completion_criteria']['input']>;
  _lte?: InputMaybe<Scalars['learning_journey_completion_criteria']['input']>;
  _neq?: InputMaybe<Scalars['learning_journey_completion_criteria']['input']>;
  _nin?: InputMaybe<Array<Scalars['learning_journey_completion_criteria']['input']>>;
};

/** Boolean expression to compare columns of type "learning_journey_status". All fields are combined with logical 'AND'. */
export type RLearning_Journey_Status_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['learning_journey_status']['input']>;
  _gt?: InputMaybe<Scalars['learning_journey_status']['input']>;
  _gte?: InputMaybe<Scalars['learning_journey_status']['input']>;
  _in?: InputMaybe<Array<Scalars['learning_journey_status']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['learning_journey_status']['input']>;
  _lte?: InputMaybe<Scalars['learning_journey_status']['input']>;
  _neq?: InputMaybe<Scalars['learning_journey_status']['input']>;
  _nin?: InputMaybe<Array<Scalars['learning_journey_status']['input']>>;
};

export type RLearning_Journeys_Insights_V2 = {
  __typename?: 'learning_journeys_insights_v2';
  average_progress: Scalars['numeric']['output'];
  completed_count: Scalars['bigint']['output'];
  completion_rate: Scalars['numeric']['output'];
  learners_count: Scalars['bigint']['output'];
  space_id: Scalars['Int']['output'];
  team_ids?: Maybe<Scalars['jsonb']['output']>;
  urn: Scalars['String']['output'];
};

export type RLearning_Journeys_Insights_V2Team_IdsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the logical model for "learning_journeys_insights_v2". All fields are combined with a logical 'AND'. */
export type RLearning_Journeys_Insights_V2_Bool_Exp_Bool_Exp = {
  _and?: InputMaybe<Array<RLearning_Journeys_Insights_V2_Bool_Exp_Bool_Exp>>;
  _not?: InputMaybe<RLearning_Journeys_Insights_V2_Bool_Exp_Bool_Exp>;
  _or?: InputMaybe<Array<RLearning_Journeys_Insights_V2_Bool_Exp_Bool_Exp>>;
  average_progress?: InputMaybe<RNumeric_Comparison_Exp>;
  completed_count?: InputMaybe<RBigint_Comparison_Exp>;
  completion_rate?: InputMaybe<RNumeric_Comparison_Exp>;
  learners_count?: InputMaybe<RBigint_Comparison_Exp>;
  space_id?: InputMaybe<RInt_Comparison_Exp>;
  team_ids?: InputMaybe<RJsonb_Comparison_Exp>;
  urn?: InputMaybe<RString_Comparison_Exp>;
};

export enum RLearning_Journeys_Insights_V2_Enum_Name {
  /** column name */
  AverageProgress = 'average_progress',
  /** column name */
  CompletedCount = 'completed_count',
  /** column name */
  CompletionRate = 'completion_rate',
  /** column name */
  LearnersCount = 'learners_count',
  /** column name */
  SpaceId = 'space_id',
  /** column name */
  TeamIds = 'team_ids',
  /** column name */
  Urn = 'urn',
}

/** Ordering options when selecting data from "learning_journeys_insights_v2". */
export type RLearning_Journeys_Insights_V2_Order_By = {
  average_progress?: InputMaybe<ROrder_By>;
  completed_count?: InputMaybe<ROrder_By>;
  completion_rate?: InputMaybe<ROrder_By>;
  learners_count?: InputMaybe<ROrder_By>;
  space_id?: InputMaybe<ROrder_By>;
  team_ids?: InputMaybe<ROrder_By>;
  urn?: InputMaybe<ROrder_By>;
};

export type RLearning_Journeys_Items = {
  __typename?: 'learning_journeys_items';
  partner: Scalars['String']['output'];
  planned_duration: Scalars['Int']['output'];
  space_id: Scalars['Int']['output'];
  team_ids: Scalars['jsonb']['output'];
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
  urn: Scalars['String']['output'];
};

export type RLearning_Journeys_ItemsTeam_IdsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the logical model for "learning_journeys_items". All fields are combined with a logical 'AND'. */
export type RLearning_Journeys_Items_Bool_Exp_Bool_Exp = {
  _and?: InputMaybe<Array<RLearning_Journeys_Items_Bool_Exp_Bool_Exp>>;
  _not?: InputMaybe<RLearning_Journeys_Items_Bool_Exp_Bool_Exp>;
  _or?: InputMaybe<Array<RLearning_Journeys_Items_Bool_Exp_Bool_Exp>>;
  partner?: InputMaybe<RString_Comparison_Exp>;
  planned_duration?: InputMaybe<RInt_Comparison_Exp>;
  space_id?: InputMaybe<RInt_Comparison_Exp>;
  team_ids?: InputMaybe<RJsonb_Comparison_Exp>;
  title?: InputMaybe<RString_Comparison_Exp>;
  type?: InputMaybe<RString_Comparison_Exp>;
  urn?: InputMaybe<RString_Comparison_Exp>;
};

export enum RLearning_Journeys_Items_Enum_Name {
  /** column name */
  Partner = 'partner',
  /** column name */
  PlannedDuration = 'planned_duration',
  /** column name */
  SpaceId = 'space_id',
  /** column name */
  TeamIds = 'team_ids',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  Urn = 'urn',
}

export type RLearning_Journeys_Items_Insights = {
  __typename?: 'learning_journeys_items_insights';
  completed_count: Scalars['bigint']['output'];
  completion_rate: Scalars['numeric']['output'];
  in_progress_count: Scalars['bigint']['output'];
  not_started_count: Scalars['bigint']['output'];
  space_id: Scalars['Int']['output'];
  team_ids?: Maybe<Scalars['jsonb']['output']>;
  urn: Scalars['String']['output'];
};

export type RLearning_Journeys_Items_InsightsTeam_IdsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the logical model for "learning_journeys_items_insights". All fields are combined with a logical 'AND'. */
export type RLearning_Journeys_Items_Insights_Bool_Exp_Bool_Exp = {
  _and?: InputMaybe<Array<RLearning_Journeys_Items_Insights_Bool_Exp_Bool_Exp>>;
  _not?: InputMaybe<RLearning_Journeys_Items_Insights_Bool_Exp_Bool_Exp>;
  _or?: InputMaybe<Array<RLearning_Journeys_Items_Insights_Bool_Exp_Bool_Exp>>;
  completed_count?: InputMaybe<RBigint_Comparison_Exp>;
  completion_rate?: InputMaybe<RNumeric_Comparison_Exp>;
  in_progress_count?: InputMaybe<RBigint_Comparison_Exp>;
  not_started_count?: InputMaybe<RBigint_Comparison_Exp>;
  space_id?: InputMaybe<RInt_Comparison_Exp>;
  team_ids?: InputMaybe<RJsonb_Comparison_Exp>;
  urn?: InputMaybe<RString_Comparison_Exp>;
};

export enum RLearning_Journeys_Items_Insights_Enum_Name {
  /** column name */
  CompletedCount = 'completed_count',
  /** column name */
  CompletionRate = 'completion_rate',
  /** column name */
  InProgressCount = 'in_progress_count',
  /** column name */
  NotStartedCount = 'not_started_count',
  /** column name */
  SpaceId = 'space_id',
  /** column name */
  TeamIds = 'team_ids',
  /** column name */
  Urn = 'urn',
}

/** Ordering options when selecting data from "learning_journeys_items_insights". */
export type RLearning_Journeys_Items_Insights_Order_By = {
  completed_count?: InputMaybe<ROrder_By>;
  completion_rate?: InputMaybe<ROrder_By>;
  in_progress_count?: InputMaybe<ROrder_By>;
  not_started_count?: InputMaybe<ROrder_By>;
  space_id?: InputMaybe<ROrder_By>;
  team_ids?: InputMaybe<ROrder_By>;
  urn?: InputMaybe<ROrder_By>;
};

/** Ordering options when selecting data from "learning_journeys_items". */
export type RLearning_Journeys_Items_Order_By = {
  partner?: InputMaybe<ROrder_By>;
  planned_duration?: InputMaybe<ROrder_By>;
  space_id?: InputMaybe<ROrder_By>;
  team_ids?: InputMaybe<ROrder_By>;
  title?: InputMaybe<ROrder_By>;
  type?: InputMaybe<ROrder_By>;
  urn?: InputMaybe<ROrder_By>;
};

export type RLearning_Journeys_V2 = {
  __typename?: 'learning_journeys_v2';
  learning_items_count: Scalars['bigint']['output'];
  planned_duration: Scalars['Int']['output'];
  space_id: Scalars['Int']['output'];
  space_name: Scalars['String']['output'];
  status: Scalars['String']['output'];
  team_ids?: Maybe<Scalars['jsonb']['output']>;
  title: Scalars['String']['output'];
  urn: Scalars['String']['output'];
};

export type RLearning_Journeys_V2Team_IdsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the logical model for "learning_journeys_v2". All fields are combined with a logical 'AND'. */
export type RLearning_Journeys_V2_Bool_Exp_Bool_Exp = {
  _and?: InputMaybe<Array<RLearning_Journeys_V2_Bool_Exp_Bool_Exp>>;
  _not?: InputMaybe<RLearning_Journeys_V2_Bool_Exp_Bool_Exp>;
  _or?: InputMaybe<Array<RLearning_Journeys_V2_Bool_Exp_Bool_Exp>>;
  learning_items_count?: InputMaybe<RBigint_Comparison_Exp>;
  planned_duration?: InputMaybe<RInt_Comparison_Exp>;
  space_id?: InputMaybe<RInt_Comparison_Exp>;
  space_name?: InputMaybe<RString_Comparison_Exp>;
  status?: InputMaybe<RString_Comparison_Exp>;
  team_ids?: InputMaybe<RJsonb_Comparison_Exp>;
  title?: InputMaybe<RString_Comparison_Exp>;
  urn?: InputMaybe<RString_Comparison_Exp>;
};

export enum RLearning_Journeys_V2_Enum_Name {
  /** column name */
  LearningItemsCount = 'learning_items_count',
  /** column name */
  PlannedDuration = 'planned_duration',
  /** column name */
  SpaceId = 'space_id',
  /** column name */
  SpaceName = 'space_name',
  /** column name */
  Status = 'status',
  /** column name */
  TeamIds = 'team_ids',
  /** column name */
  Title = 'title',
  /** column name */
  Urn = 'urn',
}

/** Ordering options when selecting data from "learning_journeys_v2". */
export type RLearning_Journeys_V2_Order_By = {
  learning_items_count?: InputMaybe<ROrder_By>;
  planned_duration?: InputMaybe<ROrder_By>;
  space_id?: InputMaybe<ROrder_By>;
  space_name?: InputMaybe<ROrder_By>;
  status?: InputMaybe<ROrder_By>;
  team_ids?: InputMaybe<ROrder_By>;
  title?: InputMaybe<ROrder_By>;
  urn?: InputMaybe<ROrder_By>;
};

/** Boolean expression to compare columns of type "learning_status". All fields are combined with logical 'AND'. */
export type RLearning_Status_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['learning_status']['input']>;
  _gt?: InputMaybe<Scalars['learning_status']['input']>;
  _gte?: InputMaybe<Scalars['learning_status']['input']>;
  _in?: InputMaybe<Array<Scalars['learning_status']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['learning_status']['input']>;
  _lte?: InputMaybe<Scalars['learning_status']['input']>;
  _neq?: InputMaybe<Scalars['learning_status']['input']>;
  _nin?: InputMaybe<Array<Scalars['learning_status']['input']>>;
};

/** Boolean expression to compare columns of type "learning_type". All fields are combined with logical 'AND'. */
export type RLearning_Type_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['learning_type']['input']>;
  _gt?: InputMaybe<Scalars['learning_type']['input']>;
  _gte?: InputMaybe<Scalars['learning_type']['input']>;
  _in?: InputMaybe<Array<Scalars['learning_type']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['learning_type']['input']>;
  _lte?: InputMaybe<Scalars['learning_type']['input']>;
  _neq?: InputMaybe<Scalars['learning_type']['input']>;
  _nin?: InputMaybe<Array<Scalars['learning_type']['input']>>;
};

/** Boolean expression to compare columns of type "learning_visibility". All fields are combined with logical 'AND'. */
export type RLearning_Visibility_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['learning_visibility']['input']>;
  _gt?: InputMaybe<Scalars['learning_visibility']['input']>;
  _gte?: InputMaybe<Scalars['learning_visibility']['input']>;
  _in?: InputMaybe<Array<Scalars['learning_visibility']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['learning_visibility']['input']>;
  _lte?: InputMaybe<Scalars['learning_visibility']['input']>;
  _neq?: InputMaybe<Scalars['learning_visibility']['input']>;
  _nin?: InputMaybe<Array<Scalars['learning_visibility']['input']>>;
};

export type RLearnings = {
  __typename?: 'learnings';
  category?: Maybe<Scalars['String']['output']>;
  partner?: Maybe<Scalars['String']['output']>;
  planned_duration: Scalars['Int']['output'];
  space_ids?: Maybe<Scalars['jsonb']['output']>;
  space_names?: Maybe<Scalars['jsonb']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  team_ids?: Maybe<Scalars['jsonb']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  urn?: Maybe<Scalars['String']['output']>;
  visibility?: Maybe<Scalars['String']['output']>;
};

export type RLearningsSpace_IdsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

export type RLearningsSpace_NamesArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

export type RLearningsTeam_IdsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

export type RLearningsInsights = {
  __typename?: 'learningsInsights';
  completion_rate?: Maybe<Scalars['numeric']['output']>;
  learners_count?: Maybe<Scalars['bigint']['output']>;
  space_ids?: Maybe<Scalars['jsonb']['output']>;
  team_ids?: Maybe<Scalars['jsonb']['output']>;
  urn?: Maybe<Scalars['String']['output']>;
};

export type RLearningsInsightsSpace_IdsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

export type RLearningsInsightsTeam_IdsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the logical model for "learningsInsights". All fields are combined with a logical 'AND'. */
export type RLearningsInsights_Bool_Exp_Bool_Exp = {
  _and?: InputMaybe<Array<RLearningsInsights_Bool_Exp_Bool_Exp>>;
  _not?: InputMaybe<RLearningsInsights_Bool_Exp_Bool_Exp>;
  _or?: InputMaybe<Array<RLearningsInsights_Bool_Exp_Bool_Exp>>;
  completion_rate?: InputMaybe<RNumeric_Comparison_Exp>;
  learners_count?: InputMaybe<RBigint_Comparison_Exp>;
  space_ids?: InputMaybe<RJsonb_Comparison_Exp>;
  team_ids?: InputMaybe<RJsonb_Comparison_Exp>;
  urn?: InputMaybe<RString_Comparison_Exp>;
};

export enum RLearningsInsights_Enum_Name {
  /** column name */
  CompletionRate = 'completion_rate',
  /** column name */
  LearnersCount = 'learners_count',
  /** column name */
  SpaceIds = 'space_ids',
  /** column name */
  TeamIds = 'team_ids',
  /** column name */
  Urn = 'urn',
}

/** Ordering options when selecting data from "learningsInsights". */
export type RLearningsInsights_Order_By = {
  completion_rate?: InputMaybe<ROrder_By>;
  learners_count?: InputMaybe<ROrder_By>;
  space_ids?: InputMaybe<ROrder_By>;
  team_ids?: InputMaybe<ROrder_By>;
  urn?: InputMaybe<ROrder_By>;
};

/** Boolean expression to filter rows from the logical model for "learnings". All fields are combined with a logical 'AND'. */
export type RLearnings_Bool_Exp_Bool_Exp = {
  _and?: InputMaybe<Array<RLearnings_Bool_Exp_Bool_Exp>>;
  _not?: InputMaybe<RLearnings_Bool_Exp_Bool_Exp>;
  _or?: InputMaybe<Array<RLearnings_Bool_Exp_Bool_Exp>>;
  category?: InputMaybe<RString_Comparison_Exp>;
  partner?: InputMaybe<RString_Comparison_Exp>;
  planned_duration?: InputMaybe<RInt_Comparison_Exp>;
  space_ids?: InputMaybe<RJsonb_Comparison_Exp>;
  space_names?: InputMaybe<RJsonb_Comparison_Exp>;
  subject?: InputMaybe<RString_Comparison_Exp>;
  team_ids?: InputMaybe<RJsonb_Comparison_Exp>;
  title?: InputMaybe<RString_Comparison_Exp>;
  type?: InputMaybe<RString_Comparison_Exp>;
  urn?: InputMaybe<RString_Comparison_Exp>;
  visibility?: InputMaybe<RString_Comparison_Exp>;
};

export enum RLearnings_Enum_Name {
  /** column name */
  Category = 'category',
  /** column name */
  Partner = 'partner',
  /** column name */
  PlannedDuration = 'planned_duration',
  /** column name */
  SpaceIds = 'space_ids',
  /** column name */
  SpaceNames = 'space_names',
  /** column name */
  Subject = 'subject',
  /** column name */
  TeamIds = 'team_ids',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  Urn = 'urn',
  /** column name */
  Visibility = 'visibility',
}

/** Ordering options when selecting data from "learnings". */
export type RLearnings_Order_By = {
  category?: InputMaybe<ROrder_By>;
  partner?: InputMaybe<ROrder_By>;
  planned_duration?: InputMaybe<ROrder_By>;
  space_ids?: InputMaybe<ROrder_By>;
  space_names?: InputMaybe<ROrder_By>;
  subject?: InputMaybe<ROrder_By>;
  team_ids?: InputMaybe<ROrder_By>;
  title?: InputMaybe<ROrder_By>;
  type?: InputMaybe<ROrder_By>;
  urn?: InputMaybe<ROrder_By>;
  visibility?: InputMaybe<ROrder_By>;
};

export type RLive_Learnings_Learners = {
  __typename?: 'live_learnings_learners';
  attendance: Scalars['String']['output'];
  completion_time?: Maybe<Scalars['timestamptz']['output']>;
  email: Scalars['String']['output'];
  is_completed: Scalars['Boolean']['output'];
  profile_image?: Maybe<Scalars['String']['output']>;
  session_time: Scalars['timestamptz']['output'];
  space_ids: Scalars['jsonb']['output'];
  team_ids?: Maybe<Scalars['jsonb']['output']>;
  user_id: Scalars['Int']['output'];
  username: Scalars['String']['output'];
};

export type RLive_Learnings_LearnersSpace_IdsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

export type RLive_Learnings_LearnersTeam_IdsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the logical model for "live_learnings_learners". All fields are combined with a logical 'AND'. */
export type RLive_Learnings_Learners_Bool_Exp_Bool_Exp = {
  _and?: InputMaybe<Array<RLive_Learnings_Learners_Bool_Exp_Bool_Exp>>;
  _not?: InputMaybe<RLive_Learnings_Learners_Bool_Exp_Bool_Exp>;
  _or?: InputMaybe<Array<RLive_Learnings_Learners_Bool_Exp_Bool_Exp>>;
  attendance?: InputMaybe<RString_Comparison_Exp>;
  completion_time?: InputMaybe<RTimestamptz_Comparison_Exp>;
  email?: InputMaybe<RString_Comparison_Exp>;
  is_completed?: InputMaybe<RBoolean_Comparison_Exp>;
  profile_image?: InputMaybe<RString_Comparison_Exp>;
  session_time?: InputMaybe<RTimestamptz_Comparison_Exp>;
  space_ids?: InputMaybe<RJsonb_Comparison_Exp>;
  team_ids?: InputMaybe<RJsonb_Comparison_Exp>;
  user_id?: InputMaybe<RInt_Comparison_Exp>;
  username?: InputMaybe<RString_Comparison_Exp>;
};

export enum RLive_Learnings_Learners_Enum_Name {
  /** column name */
  Attendance = 'attendance',
  /** column name */
  CompletionTime = 'completion_time',
  /** column name */
  Email = 'email',
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  ProfileImage = 'profile_image',
  /** column name */
  SessionTime = 'session_time',
  /** column name */
  SpaceIds = 'space_ids',
  /** column name */
  TeamIds = 'team_ids',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Username = 'username',
}

/** Ordering options when selecting data from "live_learnings_learners". */
export type RLive_Learnings_Learners_Order_By = {
  attendance?: InputMaybe<ROrder_By>;
  completion_time?: InputMaybe<ROrder_By>;
  email?: InputMaybe<ROrder_By>;
  is_completed?: InputMaybe<ROrder_By>;
  profile_image?: InputMaybe<ROrder_By>;
  session_time?: InputMaybe<ROrder_By>;
  space_ids?: InputMaybe<ROrder_By>;
  team_ids?: InputMaybe<ROrder_By>;
  user_id?: InputMaybe<ROrder_By>;
  username?: InputMaybe<ROrder_By>;
};

/** Boolean expression to compare columns of type "metrics_dimension". All fields are combined with logical 'AND'. */
export type RMetrics_Dimension_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['metrics_dimension']['input']>;
  _gt?: InputMaybe<Scalars['metrics_dimension']['input']>;
  _gte?: InputMaybe<Scalars['metrics_dimension']['input']>;
  _in?: InputMaybe<Array<Scalars['metrics_dimension']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['metrics_dimension']['input']>;
  _lte?: InputMaybe<Scalars['metrics_dimension']['input']>;
  _neq?: InputMaybe<Scalars['metrics_dimension']['input']>;
  _nin?: InputMaybe<Array<Scalars['metrics_dimension']['input']>>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type RNumeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']['input']>;
  _gt?: InputMaybe<Scalars['numeric']['input']>;
  _gte?: InputMaybe<Scalars['numeric']['input']>;
  _in?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['numeric']['input']>;
  _lte?: InputMaybe<Scalars['numeric']['input']>;
  _neq?: InputMaybe<Scalars['numeric']['input']>;
  _nin?: InputMaybe<Array<Scalars['numeric']['input']>>;
};

/** column ordering options */
export enum ROrder_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last',
}

export type RPractical_Assessment = {
  __typename?: 'practical_assessment';
  page_urn: Scalars['String']['output'];
  question_title: Scalars['String']['output'];
  responses_count: Scalars['Int']['output'];
  reviews_count: Scalars['Int']['output'];
  space_id: Scalars['Int']['output'];
  team_ids: Scalars['jsonb']['output'];
  title: Scalars['String']['output'];
  urn: Scalars['String']['output'];
};

export type RPractical_AssessmentTeam_IdsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the logical model for "practical_assessment". All fields are combined with a logical 'AND'. */
export type RPractical_Assessment_Bool_Exp_Bool_Exp = {
  _and?: InputMaybe<Array<RPractical_Assessment_Bool_Exp_Bool_Exp>>;
  _not?: InputMaybe<RPractical_Assessment_Bool_Exp_Bool_Exp>;
  _or?: InputMaybe<Array<RPractical_Assessment_Bool_Exp_Bool_Exp>>;
  page_urn?: InputMaybe<RString_Comparison_Exp>;
  question_title?: InputMaybe<RString_Comparison_Exp>;
  responses_count?: InputMaybe<RInt_Comparison_Exp>;
  reviews_count?: InputMaybe<RInt_Comparison_Exp>;
  space_id?: InputMaybe<RInt_Comparison_Exp>;
  team_ids?: InputMaybe<RJsonb_Comparison_Exp>;
  title?: InputMaybe<RString_Comparison_Exp>;
  urn?: InputMaybe<RString_Comparison_Exp>;
};

export enum RPractical_Assessment_Enum_Name {
  /** column name */
  PageUrn = 'page_urn',
  /** column name */
  QuestionTitle = 'question_title',
  /** column name */
  ResponsesCount = 'responses_count',
  /** column name */
  ReviewsCount = 'reviews_count',
  /** column name */
  SpaceId = 'space_id',
  /** column name */
  TeamIds = 'team_ids',
  /** column name */
  Title = 'title',
  /** column name */
  Urn = 'urn',
}

/** Ordering options when selecting data from "practical_assessment". */
export type RPractical_Assessment_Order_By = {
  page_urn?: InputMaybe<ROrder_By>;
  question_title?: InputMaybe<ROrder_By>;
  responses_count?: InputMaybe<ROrder_By>;
  reviews_count?: InputMaybe<ROrder_By>;
  space_id?: InputMaybe<ROrder_By>;
  team_ids?: InputMaybe<ROrder_By>;
  title?: InputMaybe<ROrder_By>;
  urn?: InputMaybe<ROrder_By>;
};

export type RQuiz = {
  __typename?: 'quiz';
  activity_urn: Scalars['String']['output'];
  all_attempt_ratio: Scalars['numeric']['output'];
  correct_answers: Scalars['Int']['output'];
  incorrect_answers: Scalars['Int']['output'];
  latest_attempt_ratio: Scalars['numeric']['output'];
  latest_correct_answers: Scalars['Int']['output'];
  latest_incorrect_answers: Scalars['Int']['output'];
  question_id: Scalars['String']['output'];
  question_title: Scalars['String']['output'];
  space_id: Scalars['Int']['output'];
  team_ids: Scalars['jsonb']['output'];
  total_answers: Scalars['Int']['output'];
  total_unique_user_answers: Scalars['Int']['output'];
  urn: Scalars['String']['output'];
};

export type RQuizTeam_IdsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the logical model for "quiz". All fields are combined with a logical 'AND'. */
export type RQuiz_Bool_Exp_Bool_Exp = {
  _and?: InputMaybe<Array<RQuiz_Bool_Exp_Bool_Exp>>;
  _not?: InputMaybe<RQuiz_Bool_Exp_Bool_Exp>;
  _or?: InputMaybe<Array<RQuiz_Bool_Exp_Bool_Exp>>;
  activity_urn?: InputMaybe<RString_Comparison_Exp>;
  all_attempt_ratio?: InputMaybe<RNumeric_Comparison_Exp>;
  correct_answers?: InputMaybe<RInt_Comparison_Exp>;
  incorrect_answers?: InputMaybe<RInt_Comparison_Exp>;
  latest_attempt_ratio?: InputMaybe<RNumeric_Comparison_Exp>;
  latest_correct_answers?: InputMaybe<RInt_Comparison_Exp>;
  latest_incorrect_answers?: InputMaybe<RInt_Comparison_Exp>;
  question_id?: InputMaybe<RString_Comparison_Exp>;
  question_title?: InputMaybe<RString_Comparison_Exp>;
  space_id?: InputMaybe<RInt_Comparison_Exp>;
  team_ids?: InputMaybe<RJsonb_Comparison_Exp>;
  total_answers?: InputMaybe<RInt_Comparison_Exp>;
  total_unique_user_answers?: InputMaybe<RInt_Comparison_Exp>;
  urn?: InputMaybe<RString_Comparison_Exp>;
};

export enum RQuiz_Enum_Name {
  /** column name */
  ActivityUrn = 'activity_urn',
  /** column name */
  AllAttemptRatio = 'all_attempt_ratio',
  /** column name */
  CorrectAnswers = 'correct_answers',
  /** column name */
  IncorrectAnswers = 'incorrect_answers',
  /** column name */
  LatestAttemptRatio = 'latest_attempt_ratio',
  /** column name */
  LatestCorrectAnswers = 'latest_correct_answers',
  /** column name */
  LatestIncorrectAnswers = 'latest_incorrect_answers',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  QuestionTitle = 'question_title',
  /** column name */
  SpaceId = 'space_id',
  /** column name */
  TeamIds = 'team_ids',
  /** column name */
  TotalAnswers = 'total_answers',
  /** column name */
  TotalUniqueUserAnswers = 'total_unique_user_answers',
  /** column name */
  Urn = 'urn',
}

/** Ordering options when selecting data from "quiz". */
export type RQuiz_Order_By = {
  activity_urn?: InputMaybe<ROrder_By>;
  all_attempt_ratio?: InputMaybe<ROrder_By>;
  correct_answers?: InputMaybe<ROrder_By>;
  incorrect_answers?: InputMaybe<ROrder_By>;
  latest_attempt_ratio?: InputMaybe<ROrder_By>;
  latest_correct_answers?: InputMaybe<ROrder_By>;
  latest_incorrect_answers?: InputMaybe<ROrder_By>;
  question_id?: InputMaybe<ROrder_By>;
  question_title?: InputMaybe<ROrder_By>;
  space_id?: InputMaybe<ROrder_By>;
  team_ids?: InputMaybe<ROrder_By>;
  total_answers?: InputMaybe<ROrder_By>;
  total_unique_user_answers?: InputMaybe<ROrder_By>;
  urn?: InputMaybe<ROrder_By>;
};

/** Boolean expression to compare columns of type "reaction_value". All fields are combined with logical 'AND'. */
export type RReaction_Value_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['reaction_value']['input']>;
  _gt?: InputMaybe<Scalars['reaction_value']['input']>;
  _gte?: InputMaybe<Scalars['reaction_value']['input']>;
  _in?: InputMaybe<Array<Scalars['reaction_value']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['reaction_value']['input']>;
  _lte?: InputMaybe<Scalars['reaction_value']['input']>;
  _neq?: InputMaybe<Scalars['reaction_value']['input']>;
  _nin?: InputMaybe<Array<Scalars['reaction_value']['input']>>;
};

export type RReactions = {
  __typename?: 'reactions';
  percent_apply: Scalars['numeric']['output'];
  percent_change_topic: Scalars['numeric']['output'];
  percent_continue_learning: Scalars['numeric']['output'];
  percent_discuss: Scalars['numeric']['output'];
  percent_dont_know: Scalars['numeric']['output'];
  percent_not_answered: Scalars['numeric']['output'];
  space_ids: Scalars['jsonb']['output'];
  team_ids?: Maybe<Scalars['jsonb']['output']>;
  total: Scalars['bigint']['output'];
  total_apply: Scalars['bigint']['output'];
  total_change_topic: Scalars['bigint']['output'];
  total_continue_learning: Scalars['bigint']['output'];
  total_discuss: Scalars['bigint']['output'];
  total_dont_know: Scalars['bigint']['output'];
  total_not_answered: Scalars['bigint']['output'];
  urn: Scalars['String']['output'];
};

export type RReactionsSpace_IdsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

export type RReactionsTeam_IdsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the logical model for "reactions". All fields are combined with a logical 'AND'. */
export type RReactions_Bool_Exp_Bool_Exp = {
  _and?: InputMaybe<Array<RReactions_Bool_Exp_Bool_Exp>>;
  _not?: InputMaybe<RReactions_Bool_Exp_Bool_Exp>;
  _or?: InputMaybe<Array<RReactions_Bool_Exp_Bool_Exp>>;
  percent_apply?: InputMaybe<RNumeric_Comparison_Exp>;
  percent_change_topic?: InputMaybe<RNumeric_Comparison_Exp>;
  percent_continue_learning?: InputMaybe<RNumeric_Comparison_Exp>;
  percent_discuss?: InputMaybe<RNumeric_Comparison_Exp>;
  percent_dont_know?: InputMaybe<RNumeric_Comparison_Exp>;
  percent_not_answered?: InputMaybe<RNumeric_Comparison_Exp>;
  space_ids?: InputMaybe<RJsonb_Comparison_Exp>;
  team_ids?: InputMaybe<RJsonb_Comparison_Exp>;
  total?: InputMaybe<RBigint_Comparison_Exp>;
  total_apply?: InputMaybe<RBigint_Comparison_Exp>;
  total_change_topic?: InputMaybe<RBigint_Comparison_Exp>;
  total_continue_learning?: InputMaybe<RBigint_Comparison_Exp>;
  total_discuss?: InputMaybe<RBigint_Comparison_Exp>;
  total_dont_know?: InputMaybe<RBigint_Comparison_Exp>;
  total_not_answered?: InputMaybe<RBigint_Comparison_Exp>;
  urn?: InputMaybe<RString_Comparison_Exp>;
};

export enum RReactions_Enum_Name {
  /** column name */
  PercentApply = 'percent_apply',
  /** column name */
  PercentChangeTopic = 'percent_change_topic',
  /** column name */
  PercentContinueLearning = 'percent_continue_learning',
  /** column name */
  PercentDiscuss = 'percent_discuss',
  /** column name */
  PercentDontKnow = 'percent_dont_know',
  /** column name */
  PercentNotAnswered = 'percent_not_answered',
  /** column name */
  SpaceIds = 'space_ids',
  /** column name */
  TeamIds = 'team_ids',
  /** column name */
  Total = 'total',
  /** column name */
  TotalApply = 'total_apply',
  /** column name */
  TotalChangeTopic = 'total_change_topic',
  /** column name */
  TotalContinueLearning = 'total_continue_learning',
  /** column name */
  TotalDiscuss = 'total_discuss',
  /** column name */
  TotalDontKnow = 'total_dont_know',
  /** column name */
  TotalNotAnswered = 'total_not_answered',
  /** column name */
  Urn = 'urn',
}

/** Ordering options when selecting data from "reactions". */
export type RReactions_Order_By = {
  percent_apply?: InputMaybe<ROrder_By>;
  percent_change_topic?: InputMaybe<ROrder_By>;
  percent_continue_learning?: InputMaybe<ROrder_By>;
  percent_discuss?: InputMaybe<ROrder_By>;
  percent_dont_know?: InputMaybe<ROrder_By>;
  percent_not_answered?: InputMaybe<ROrder_By>;
  space_ids?: InputMaybe<ROrder_By>;
  team_ids?: InputMaybe<ROrder_By>;
  total?: InputMaybe<ROrder_By>;
  total_apply?: InputMaybe<ROrder_By>;
  total_change_topic?: InputMaybe<ROrder_By>;
  total_continue_learning?: InputMaybe<ROrder_By>;
  total_discuss?: InputMaybe<ROrder_By>;
  total_dont_know?: InputMaybe<ROrder_By>;
  total_not_answered?: InputMaybe<ROrder_By>;
  urn?: InputMaybe<ROrder_By>;
};

/** columns and relationships of "reporting.mv_aggregation_effort_for_realm" */
export type RReportingAggregationEffortForRealm = {
  __typename?: 'reportingAggregationEffortForRealm';
  averageEffort?: Maybe<Scalars['bigint']['output']>;
  fromTime?: Maybe<Scalars['timestamptz']['output']>;
  meta_update_time?: Maybe<Scalars['timestamptz']['output']>;
  p50Effort?: Maybe<Scalars['float8']['output']>;
  p60Effort?: Maybe<Scalars['float8']['output']>;
  p70Effort?: Maybe<Scalars['float8']['output']>;
  p80Effort?: Maybe<Scalars['float8']['output']>;
  p90Effort?: Maybe<Scalars['float8']['output']>;
  realm?: Maybe<Scalars['String']['output']>;
  toTime?: Maybe<Scalars['timestamptz']['output']>;
  totalEffort?: Maybe<Scalars['bigint']['output']>;
};

/** aggregated selection of "reporting.mv_aggregation_effort_for_realm" */
export type RReportingAggregationEffortForRealm_Aggregate = {
  __typename?: 'reportingAggregationEffortForRealm_aggregate';
  aggregate?: Maybe<RReportingAggregationEffortForRealm_Aggregate_Fields>;
  nodes: Array<RReportingAggregationEffortForRealm>;
};

/** aggregate fields of "reporting.mv_aggregation_effort_for_realm" */
export type RReportingAggregationEffortForRealm_Aggregate_Fields = {
  __typename?: 'reportingAggregationEffortForRealm_aggregate_fields';
  avg?: Maybe<RReportingAggregationEffortForRealm_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<RReportingAggregationEffortForRealm_Max_Fields>;
  min?: Maybe<RReportingAggregationEffortForRealm_Min_Fields>;
  stddev?: Maybe<RReportingAggregationEffortForRealm_Stddev_Fields>;
  stddev_pop?: Maybe<RReportingAggregationEffortForRealm_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<RReportingAggregationEffortForRealm_Stddev_Samp_Fields>;
  sum?: Maybe<RReportingAggregationEffortForRealm_Sum_Fields>;
  var_pop?: Maybe<RReportingAggregationEffortForRealm_Var_Pop_Fields>;
  var_samp?: Maybe<RReportingAggregationEffortForRealm_Var_Samp_Fields>;
  variance?: Maybe<RReportingAggregationEffortForRealm_Variance_Fields>;
};

/** aggregate fields of "reporting.mv_aggregation_effort_for_realm" */
export type RReportingAggregationEffortForRealm_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<RReportingAggregationEffortForRealm_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type RReportingAggregationEffortForRealm_Avg_Fields = {
  __typename?: 'reportingAggregationEffortForRealm_avg_fields';
  averageEffort?: Maybe<Scalars['Float']['output']>;
  p50Effort?: Maybe<Scalars['Float']['output']>;
  p60Effort?: Maybe<Scalars['Float']['output']>;
  p70Effort?: Maybe<Scalars['Float']['output']>;
  p80Effort?: Maybe<Scalars['Float']['output']>;
  p90Effort?: Maybe<Scalars['Float']['output']>;
  totalEffort?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "reporting.mv_aggregation_effort_for_realm". All fields are combined with a logical 'AND'. */
export type RReportingAggregationEffortForRealm_Bool_Exp = {
  _and?: InputMaybe<Array<RReportingAggregationEffortForRealm_Bool_Exp>>;
  _not?: InputMaybe<RReportingAggregationEffortForRealm_Bool_Exp>;
  _or?: InputMaybe<Array<RReportingAggregationEffortForRealm_Bool_Exp>>;
  averageEffort?: InputMaybe<RBigint_Comparison_Exp>;
  fromTime?: InputMaybe<RTimestamptz_Comparison_Exp>;
  meta_update_time?: InputMaybe<RTimestamptz_Comparison_Exp>;
  p50Effort?: InputMaybe<RFloat8_Comparison_Exp>;
  p60Effort?: InputMaybe<RFloat8_Comparison_Exp>;
  p70Effort?: InputMaybe<RFloat8_Comparison_Exp>;
  p80Effort?: InputMaybe<RFloat8_Comparison_Exp>;
  p90Effort?: InputMaybe<RFloat8_Comparison_Exp>;
  realm?: InputMaybe<RString_Comparison_Exp>;
  toTime?: InputMaybe<RTimestamptz_Comparison_Exp>;
  totalEffort?: InputMaybe<RBigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type RReportingAggregationEffortForRealm_Max_Fields = {
  __typename?: 'reportingAggregationEffortForRealm_max_fields';
  averageEffort?: Maybe<Scalars['bigint']['output']>;
  fromTime?: Maybe<Scalars['timestamptz']['output']>;
  meta_update_time?: Maybe<Scalars['timestamptz']['output']>;
  p50Effort?: Maybe<Scalars['float8']['output']>;
  p60Effort?: Maybe<Scalars['float8']['output']>;
  p70Effort?: Maybe<Scalars['float8']['output']>;
  p80Effort?: Maybe<Scalars['float8']['output']>;
  p90Effort?: Maybe<Scalars['float8']['output']>;
  realm?: Maybe<Scalars['String']['output']>;
  toTime?: Maybe<Scalars['timestamptz']['output']>;
  totalEffort?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate min on columns */
export type RReportingAggregationEffortForRealm_Min_Fields = {
  __typename?: 'reportingAggregationEffortForRealm_min_fields';
  averageEffort?: Maybe<Scalars['bigint']['output']>;
  fromTime?: Maybe<Scalars['timestamptz']['output']>;
  meta_update_time?: Maybe<Scalars['timestamptz']['output']>;
  p50Effort?: Maybe<Scalars['float8']['output']>;
  p60Effort?: Maybe<Scalars['float8']['output']>;
  p70Effort?: Maybe<Scalars['float8']['output']>;
  p80Effort?: Maybe<Scalars['float8']['output']>;
  p90Effort?: Maybe<Scalars['float8']['output']>;
  realm?: Maybe<Scalars['String']['output']>;
  toTime?: Maybe<Scalars['timestamptz']['output']>;
  totalEffort?: Maybe<Scalars['bigint']['output']>;
};

/** Ordering options when selecting data from "reporting.mv_aggregation_effort_for_realm". */
export type RReportingAggregationEffortForRealm_Order_By = {
  averageEffort?: InputMaybe<ROrder_By>;
  fromTime?: InputMaybe<ROrder_By>;
  meta_update_time?: InputMaybe<ROrder_By>;
  p50Effort?: InputMaybe<ROrder_By>;
  p60Effort?: InputMaybe<ROrder_By>;
  p70Effort?: InputMaybe<ROrder_By>;
  p80Effort?: InputMaybe<ROrder_By>;
  p90Effort?: InputMaybe<ROrder_By>;
  realm?: InputMaybe<ROrder_By>;
  toTime?: InputMaybe<ROrder_By>;
  totalEffort?: InputMaybe<ROrder_By>;
};

/** select columns of table "reporting.mv_aggregation_effort_for_realm" */
export enum RReportingAggregationEffortForRealm_Select_Column {
  /** column name */
  AverageEffort = 'averageEffort',
  /** column name */
  FromTime = 'fromTime',
  /** column name */
  MetaUpdateTime = 'meta_update_time',
  /** column name */
  P50Effort = 'p50Effort',
  /** column name */
  P60Effort = 'p60Effort',
  /** column name */
  P70Effort = 'p70Effort',
  /** column name */
  P80Effort = 'p80Effort',
  /** column name */
  P90Effort = 'p90Effort',
  /** column name */
  Realm = 'realm',
  /** column name */
  ToTime = 'toTime',
  /** column name */
  TotalEffort = 'totalEffort',
}

/** aggregate stddev on columns */
export type RReportingAggregationEffortForRealm_Stddev_Fields = {
  __typename?: 'reportingAggregationEffortForRealm_stddev_fields';
  averageEffort?: Maybe<Scalars['Float']['output']>;
  p50Effort?: Maybe<Scalars['Float']['output']>;
  p60Effort?: Maybe<Scalars['Float']['output']>;
  p70Effort?: Maybe<Scalars['Float']['output']>;
  p80Effort?: Maybe<Scalars['Float']['output']>;
  p90Effort?: Maybe<Scalars['Float']['output']>;
  totalEffort?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type RReportingAggregationEffortForRealm_Stddev_Pop_Fields = {
  __typename?: 'reportingAggregationEffortForRealm_stddev_pop_fields';
  averageEffort?: Maybe<Scalars['Float']['output']>;
  p50Effort?: Maybe<Scalars['Float']['output']>;
  p60Effort?: Maybe<Scalars['Float']['output']>;
  p70Effort?: Maybe<Scalars['Float']['output']>;
  p80Effort?: Maybe<Scalars['Float']['output']>;
  p90Effort?: Maybe<Scalars['Float']['output']>;
  totalEffort?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type RReportingAggregationEffortForRealm_Stddev_Samp_Fields = {
  __typename?: 'reportingAggregationEffortForRealm_stddev_samp_fields';
  averageEffort?: Maybe<Scalars['Float']['output']>;
  p50Effort?: Maybe<Scalars['Float']['output']>;
  p60Effort?: Maybe<Scalars['Float']['output']>;
  p70Effort?: Maybe<Scalars['Float']['output']>;
  p80Effort?: Maybe<Scalars['Float']['output']>;
  p90Effort?: Maybe<Scalars['Float']['output']>;
  totalEffort?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "reportingAggregationEffortForRealm" */
export type RReportingAggregationEffortForRealm_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: RReportingAggregationEffortForRealm_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<RCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type RReportingAggregationEffortForRealm_Stream_Cursor_Value_Input = {
  averageEffort?: InputMaybe<Scalars['bigint']['input']>;
  fromTime?: InputMaybe<Scalars['timestamptz']['input']>;
  meta_update_time?: InputMaybe<Scalars['timestamptz']['input']>;
  p50Effort?: InputMaybe<Scalars['float8']['input']>;
  p60Effort?: InputMaybe<Scalars['float8']['input']>;
  p70Effort?: InputMaybe<Scalars['float8']['input']>;
  p80Effort?: InputMaybe<Scalars['float8']['input']>;
  p90Effort?: InputMaybe<Scalars['float8']['input']>;
  realm?: InputMaybe<Scalars['String']['input']>;
  toTime?: InputMaybe<Scalars['timestamptz']['input']>;
  totalEffort?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type RReportingAggregationEffortForRealm_Sum_Fields = {
  __typename?: 'reportingAggregationEffortForRealm_sum_fields';
  averageEffort?: Maybe<Scalars['bigint']['output']>;
  p50Effort?: Maybe<Scalars['float8']['output']>;
  p60Effort?: Maybe<Scalars['float8']['output']>;
  p70Effort?: Maybe<Scalars['float8']['output']>;
  p80Effort?: Maybe<Scalars['float8']['output']>;
  p90Effort?: Maybe<Scalars['float8']['output']>;
  totalEffort?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate var_pop on columns */
export type RReportingAggregationEffortForRealm_Var_Pop_Fields = {
  __typename?: 'reportingAggregationEffortForRealm_var_pop_fields';
  averageEffort?: Maybe<Scalars['Float']['output']>;
  p50Effort?: Maybe<Scalars['Float']['output']>;
  p60Effort?: Maybe<Scalars['Float']['output']>;
  p70Effort?: Maybe<Scalars['Float']['output']>;
  p80Effort?: Maybe<Scalars['Float']['output']>;
  p90Effort?: Maybe<Scalars['Float']['output']>;
  totalEffort?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type RReportingAggregationEffortForRealm_Var_Samp_Fields = {
  __typename?: 'reportingAggregationEffortForRealm_var_samp_fields';
  averageEffort?: Maybe<Scalars['Float']['output']>;
  p50Effort?: Maybe<Scalars['Float']['output']>;
  p60Effort?: Maybe<Scalars['Float']['output']>;
  p70Effort?: Maybe<Scalars['Float']['output']>;
  p80Effort?: Maybe<Scalars['Float']['output']>;
  p90Effort?: Maybe<Scalars['Float']['output']>;
  totalEffort?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type RReportingAggregationEffortForRealm_Variance_Fields = {
  __typename?: 'reportingAggregationEffortForRealm_variance_fields';
  averageEffort?: Maybe<Scalars['Float']['output']>;
  p50Effort?: Maybe<Scalars['Float']['output']>;
  p60Effort?: Maybe<Scalars['Float']['output']>;
  p70Effort?: Maybe<Scalars['Float']['output']>;
  p80Effort?: Maybe<Scalars['Float']['output']>;
  p90Effort?: Maybe<Scalars['Float']['output']>;
  totalEffort?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "reporting.mv_learning_activities_insights" */
export type RReportingLearningActivitiesInsights = {
  __typename?: 'reportingLearningActivitiesInsights';
  activityType?: Maybe<Scalars['String']['output']>;
  activityURN?: Maybe<Scalars['String']['output']>;
  completedCount?: Maybe<Scalars['bigint']['output']>;
  completionRate?: Maybe<Scalars['numeric']['output']>;
  inProgressCount?: Maybe<Scalars['bigint']['output']>;
  learningURN?: Maybe<Scalars['String']['output']>;
  metaUpdateTime?: Maybe<Scalars['timestamptz']['output']>;
  notStartedCount?: Maybe<Scalars['bigint']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  plannedDuration?: Maybe<Scalars['Int']['output']>;
  progress?: Maybe<Scalars['float8']['output']>;
  startedCount?: Maybe<Scalars['bigint']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  totalCount?: Maybe<Scalars['bigint']['output']>;
  totalEffortInSeconds?: Maybe<Scalars['numeric']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "reporting.mv_learning_activities_insights" */
export type RReportingLearningActivitiesInsights_Aggregate = {
  __typename?: 'reportingLearningActivitiesInsights_aggregate';
  aggregate?: Maybe<RReportingLearningActivitiesInsights_Aggregate_Fields>;
  nodes: Array<RReportingLearningActivitiesInsights>;
};

/** aggregate fields of "reporting.mv_learning_activities_insights" */
export type RReportingLearningActivitiesInsights_Aggregate_Fields = {
  __typename?: 'reportingLearningActivitiesInsights_aggregate_fields';
  avg?: Maybe<RReportingLearningActivitiesInsights_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<RReportingLearningActivitiesInsights_Max_Fields>;
  min?: Maybe<RReportingLearningActivitiesInsights_Min_Fields>;
  stddev?: Maybe<RReportingLearningActivitiesInsights_Stddev_Fields>;
  stddev_pop?: Maybe<RReportingLearningActivitiesInsights_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<RReportingLearningActivitiesInsights_Stddev_Samp_Fields>;
  sum?: Maybe<RReportingLearningActivitiesInsights_Sum_Fields>;
  var_pop?: Maybe<RReportingLearningActivitiesInsights_Var_Pop_Fields>;
  var_samp?: Maybe<RReportingLearningActivitiesInsights_Var_Samp_Fields>;
  variance?: Maybe<RReportingLearningActivitiesInsights_Variance_Fields>;
};

/** aggregate fields of "reporting.mv_learning_activities_insights" */
export type RReportingLearningActivitiesInsights_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<RReportingLearningActivitiesInsights_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type RReportingLearningActivitiesInsights_Avg_Fields = {
  __typename?: 'reportingLearningActivitiesInsights_avg_fields';
  completedCount?: Maybe<Scalars['Float']['output']>;
  completionRate?: Maybe<Scalars['Float']['output']>;
  inProgressCount?: Maybe<Scalars['Float']['output']>;
  notStartedCount?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
  plannedDuration?: Maybe<Scalars['Float']['output']>;
  progress?: Maybe<Scalars['Float']['output']>;
  startedCount?: Maybe<Scalars['Float']['output']>;
  totalCount?: Maybe<Scalars['Float']['output']>;
  totalEffortInSeconds?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "reporting.mv_learning_activities_insights". All fields are combined with a logical 'AND'. */
export type RReportingLearningActivitiesInsights_Bool_Exp = {
  _and?: InputMaybe<Array<RReportingLearningActivitiesInsights_Bool_Exp>>;
  _not?: InputMaybe<RReportingLearningActivitiesInsights_Bool_Exp>;
  _or?: InputMaybe<Array<RReportingLearningActivitiesInsights_Bool_Exp>>;
  activityType?: InputMaybe<RString_Comparison_Exp>;
  activityURN?: InputMaybe<RString_Comparison_Exp>;
  completedCount?: InputMaybe<RBigint_Comparison_Exp>;
  completionRate?: InputMaybe<RNumeric_Comparison_Exp>;
  inProgressCount?: InputMaybe<RBigint_Comparison_Exp>;
  learningURN?: InputMaybe<RString_Comparison_Exp>;
  metaUpdateTime?: InputMaybe<RTimestamptz_Comparison_Exp>;
  notStartedCount?: InputMaybe<RBigint_Comparison_Exp>;
  order?: InputMaybe<RInt_Comparison_Exp>;
  plannedDuration?: InputMaybe<RInt_Comparison_Exp>;
  progress?: InputMaybe<RFloat8_Comparison_Exp>;
  startedCount?: InputMaybe<RBigint_Comparison_Exp>;
  title?: InputMaybe<RString_Comparison_Exp>;
  totalCount?: InputMaybe<RBigint_Comparison_Exp>;
  totalEffortInSeconds?: InputMaybe<RNumeric_Comparison_Exp>;
  type?: InputMaybe<RString_Comparison_Exp>;
};

/** aggregate max on columns */
export type RReportingLearningActivitiesInsights_Max_Fields = {
  __typename?: 'reportingLearningActivitiesInsights_max_fields';
  activityType?: Maybe<Scalars['String']['output']>;
  activityURN?: Maybe<Scalars['String']['output']>;
  completedCount?: Maybe<Scalars['bigint']['output']>;
  completionRate?: Maybe<Scalars['numeric']['output']>;
  inProgressCount?: Maybe<Scalars['bigint']['output']>;
  learningURN?: Maybe<Scalars['String']['output']>;
  metaUpdateTime?: Maybe<Scalars['timestamptz']['output']>;
  notStartedCount?: Maybe<Scalars['bigint']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  plannedDuration?: Maybe<Scalars['Int']['output']>;
  progress?: Maybe<Scalars['float8']['output']>;
  startedCount?: Maybe<Scalars['bigint']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  totalCount?: Maybe<Scalars['bigint']['output']>;
  totalEffortInSeconds?: Maybe<Scalars['numeric']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type RReportingLearningActivitiesInsights_Min_Fields = {
  __typename?: 'reportingLearningActivitiesInsights_min_fields';
  activityType?: Maybe<Scalars['String']['output']>;
  activityURN?: Maybe<Scalars['String']['output']>;
  completedCount?: Maybe<Scalars['bigint']['output']>;
  completionRate?: Maybe<Scalars['numeric']['output']>;
  inProgressCount?: Maybe<Scalars['bigint']['output']>;
  learningURN?: Maybe<Scalars['String']['output']>;
  metaUpdateTime?: Maybe<Scalars['timestamptz']['output']>;
  notStartedCount?: Maybe<Scalars['bigint']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  plannedDuration?: Maybe<Scalars['Int']['output']>;
  progress?: Maybe<Scalars['float8']['output']>;
  startedCount?: Maybe<Scalars['bigint']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  totalCount?: Maybe<Scalars['bigint']['output']>;
  totalEffortInSeconds?: Maybe<Scalars['numeric']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "reporting.mv_learning_activities_insights". */
export type RReportingLearningActivitiesInsights_Order_By = {
  activityType?: InputMaybe<ROrder_By>;
  activityURN?: InputMaybe<ROrder_By>;
  completedCount?: InputMaybe<ROrder_By>;
  completionRate?: InputMaybe<ROrder_By>;
  inProgressCount?: InputMaybe<ROrder_By>;
  learningURN?: InputMaybe<ROrder_By>;
  metaUpdateTime?: InputMaybe<ROrder_By>;
  notStartedCount?: InputMaybe<ROrder_By>;
  order?: InputMaybe<ROrder_By>;
  plannedDuration?: InputMaybe<ROrder_By>;
  progress?: InputMaybe<ROrder_By>;
  startedCount?: InputMaybe<ROrder_By>;
  title?: InputMaybe<ROrder_By>;
  totalCount?: InputMaybe<ROrder_By>;
  totalEffortInSeconds?: InputMaybe<ROrder_By>;
  type?: InputMaybe<ROrder_By>;
};

/** select columns of table "reporting.mv_learning_activities_insights" */
export enum RReportingLearningActivitiesInsights_Select_Column {
  /** column name */
  ActivityType = 'activityType',
  /** column name */
  ActivityUrn = 'activityURN',
  /** column name */
  CompletedCount = 'completedCount',
  /** column name */
  CompletionRate = 'completionRate',
  /** column name */
  InProgressCount = 'inProgressCount',
  /** column name */
  LearningUrn = 'learningURN',
  /** column name */
  MetaUpdateTime = 'metaUpdateTime',
  /** column name */
  NotStartedCount = 'notStartedCount',
  /** column name */
  Order = 'order',
  /** column name */
  PlannedDuration = 'plannedDuration',
  /** column name */
  Progress = 'progress',
  /** column name */
  StartedCount = 'startedCount',
  /** column name */
  Title = 'title',
  /** column name */
  TotalCount = 'totalCount',
  /** column name */
  TotalEffortInSeconds = 'totalEffortInSeconds',
  /** column name */
  Type = 'type',
}

/** aggregate stddev on columns */
export type RReportingLearningActivitiesInsights_Stddev_Fields = {
  __typename?: 'reportingLearningActivitiesInsights_stddev_fields';
  completedCount?: Maybe<Scalars['Float']['output']>;
  completionRate?: Maybe<Scalars['Float']['output']>;
  inProgressCount?: Maybe<Scalars['Float']['output']>;
  notStartedCount?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
  plannedDuration?: Maybe<Scalars['Float']['output']>;
  progress?: Maybe<Scalars['Float']['output']>;
  startedCount?: Maybe<Scalars['Float']['output']>;
  totalCount?: Maybe<Scalars['Float']['output']>;
  totalEffortInSeconds?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type RReportingLearningActivitiesInsights_Stddev_Pop_Fields = {
  __typename?: 'reportingLearningActivitiesInsights_stddev_pop_fields';
  completedCount?: Maybe<Scalars['Float']['output']>;
  completionRate?: Maybe<Scalars['Float']['output']>;
  inProgressCount?: Maybe<Scalars['Float']['output']>;
  notStartedCount?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
  plannedDuration?: Maybe<Scalars['Float']['output']>;
  progress?: Maybe<Scalars['Float']['output']>;
  startedCount?: Maybe<Scalars['Float']['output']>;
  totalCount?: Maybe<Scalars['Float']['output']>;
  totalEffortInSeconds?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type RReportingLearningActivitiesInsights_Stddev_Samp_Fields = {
  __typename?: 'reportingLearningActivitiesInsights_stddev_samp_fields';
  completedCount?: Maybe<Scalars['Float']['output']>;
  completionRate?: Maybe<Scalars['Float']['output']>;
  inProgressCount?: Maybe<Scalars['Float']['output']>;
  notStartedCount?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
  plannedDuration?: Maybe<Scalars['Float']['output']>;
  progress?: Maybe<Scalars['Float']['output']>;
  startedCount?: Maybe<Scalars['Float']['output']>;
  totalCount?: Maybe<Scalars['Float']['output']>;
  totalEffortInSeconds?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "reportingLearningActivitiesInsights" */
export type RReportingLearningActivitiesInsights_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: RReportingLearningActivitiesInsights_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<RCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type RReportingLearningActivitiesInsights_Stream_Cursor_Value_Input = {
  activityType?: InputMaybe<Scalars['String']['input']>;
  activityURN?: InputMaybe<Scalars['String']['input']>;
  completedCount?: InputMaybe<Scalars['bigint']['input']>;
  completionRate?: InputMaybe<Scalars['numeric']['input']>;
  inProgressCount?: InputMaybe<Scalars['bigint']['input']>;
  learningURN?: InputMaybe<Scalars['String']['input']>;
  metaUpdateTime?: InputMaybe<Scalars['timestamptz']['input']>;
  notStartedCount?: InputMaybe<Scalars['bigint']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  plannedDuration?: InputMaybe<Scalars['Int']['input']>;
  progress?: InputMaybe<Scalars['float8']['input']>;
  startedCount?: InputMaybe<Scalars['bigint']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  totalCount?: InputMaybe<Scalars['bigint']['input']>;
  totalEffortInSeconds?: InputMaybe<Scalars['numeric']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type RReportingLearningActivitiesInsights_Sum_Fields = {
  __typename?: 'reportingLearningActivitiesInsights_sum_fields';
  completedCount?: Maybe<Scalars['bigint']['output']>;
  completionRate?: Maybe<Scalars['numeric']['output']>;
  inProgressCount?: Maybe<Scalars['bigint']['output']>;
  notStartedCount?: Maybe<Scalars['bigint']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  plannedDuration?: Maybe<Scalars['Int']['output']>;
  progress?: Maybe<Scalars['float8']['output']>;
  startedCount?: Maybe<Scalars['bigint']['output']>;
  totalCount?: Maybe<Scalars['bigint']['output']>;
  totalEffortInSeconds?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate var_pop on columns */
export type RReportingLearningActivitiesInsights_Var_Pop_Fields = {
  __typename?: 'reportingLearningActivitiesInsights_var_pop_fields';
  completedCount?: Maybe<Scalars['Float']['output']>;
  completionRate?: Maybe<Scalars['Float']['output']>;
  inProgressCount?: Maybe<Scalars['Float']['output']>;
  notStartedCount?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
  plannedDuration?: Maybe<Scalars['Float']['output']>;
  progress?: Maybe<Scalars['Float']['output']>;
  startedCount?: Maybe<Scalars['Float']['output']>;
  totalCount?: Maybe<Scalars['Float']['output']>;
  totalEffortInSeconds?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type RReportingLearningActivitiesInsights_Var_Samp_Fields = {
  __typename?: 'reportingLearningActivitiesInsights_var_samp_fields';
  completedCount?: Maybe<Scalars['Float']['output']>;
  completionRate?: Maybe<Scalars['Float']['output']>;
  inProgressCount?: Maybe<Scalars['Float']['output']>;
  notStartedCount?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
  plannedDuration?: Maybe<Scalars['Float']['output']>;
  progress?: Maybe<Scalars['Float']['output']>;
  startedCount?: Maybe<Scalars['Float']['output']>;
  totalCount?: Maybe<Scalars['Float']['output']>;
  totalEffortInSeconds?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type RReportingLearningActivitiesInsights_Variance_Fields = {
  __typename?: 'reportingLearningActivitiesInsights_variance_fields';
  completedCount?: Maybe<Scalars['Float']['output']>;
  completionRate?: Maybe<Scalars['Float']['output']>;
  inProgressCount?: Maybe<Scalars['Float']['output']>;
  notStartedCount?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
  plannedDuration?: Maybe<Scalars['Float']['output']>;
  progress?: Maybe<Scalars['Float']['output']>;
  startedCount?: Maybe<Scalars['Float']['output']>;
  totalCount?: Maybe<Scalars['Float']['output']>;
  totalEffortInSeconds?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "reporting.mv_learning_content_summary_insights" */
export type RReportingLearningContentSummaryInsights = {
  __typename?: 'reportingLearningContentSummaryInsights';
  averageProgress?: Maybe<Scalars['numeric']['output']>;
  completedCount?: Maybe<Scalars['bigint']['output']>;
  completionRate?: Maybe<Scalars['numeric']['output']>;
  metaUpdateTime?: Maybe<Scalars['timestamptz']['output']>;
  notStartedCount?: Maybe<Scalars['bigint']['output']>;
  startedCount?: Maybe<Scalars['bigint']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['catalog_item_type']['output']>;
  urn?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "reporting.mv_learning_content_summary_insights" */
export type RReportingLearningContentSummaryInsights_Aggregate = {
  __typename?: 'reportingLearningContentSummaryInsights_aggregate';
  aggregate?: Maybe<RReportingLearningContentSummaryInsights_Aggregate_Fields>;
  nodes: Array<RReportingLearningContentSummaryInsights>;
};

/** aggregate fields of "reporting.mv_learning_content_summary_insights" */
export type RReportingLearningContentSummaryInsights_Aggregate_Fields = {
  __typename?: 'reportingLearningContentSummaryInsights_aggregate_fields';
  avg?: Maybe<RReportingLearningContentSummaryInsights_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<RReportingLearningContentSummaryInsights_Max_Fields>;
  min?: Maybe<RReportingLearningContentSummaryInsights_Min_Fields>;
  stddev?: Maybe<RReportingLearningContentSummaryInsights_Stddev_Fields>;
  stddev_pop?: Maybe<RReportingLearningContentSummaryInsights_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<RReportingLearningContentSummaryInsights_Stddev_Samp_Fields>;
  sum?: Maybe<RReportingLearningContentSummaryInsights_Sum_Fields>;
  var_pop?: Maybe<RReportingLearningContentSummaryInsights_Var_Pop_Fields>;
  var_samp?: Maybe<RReportingLearningContentSummaryInsights_Var_Samp_Fields>;
  variance?: Maybe<RReportingLearningContentSummaryInsights_Variance_Fields>;
};

/** aggregate fields of "reporting.mv_learning_content_summary_insights" */
export type RReportingLearningContentSummaryInsights_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<RReportingLearningContentSummaryInsights_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type RReportingLearningContentSummaryInsights_Avg_Fields = {
  __typename?: 'reportingLearningContentSummaryInsights_avg_fields';
  averageProgress?: Maybe<Scalars['Float']['output']>;
  completedCount?: Maybe<Scalars['Float']['output']>;
  completionRate?: Maybe<Scalars['Float']['output']>;
  notStartedCount?: Maybe<Scalars['Float']['output']>;
  startedCount?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "reporting.mv_learning_content_summary_insights". All fields are combined with a logical 'AND'. */
export type RReportingLearningContentSummaryInsights_Bool_Exp = {
  _and?: InputMaybe<Array<RReportingLearningContentSummaryInsights_Bool_Exp>>;
  _not?: InputMaybe<RReportingLearningContentSummaryInsights_Bool_Exp>;
  _or?: InputMaybe<Array<RReportingLearningContentSummaryInsights_Bool_Exp>>;
  averageProgress?: InputMaybe<RNumeric_Comparison_Exp>;
  completedCount?: InputMaybe<RBigint_Comparison_Exp>;
  completionRate?: InputMaybe<RNumeric_Comparison_Exp>;
  metaUpdateTime?: InputMaybe<RTimestamptz_Comparison_Exp>;
  notStartedCount?: InputMaybe<RBigint_Comparison_Exp>;
  startedCount?: InputMaybe<RBigint_Comparison_Exp>;
  title?: InputMaybe<RString_Comparison_Exp>;
  type?: InputMaybe<RCatalog_Item_Type_Comparison_Exp>;
  urn?: InputMaybe<RString_Comparison_Exp>;
};

/** aggregate max on columns */
export type RReportingLearningContentSummaryInsights_Max_Fields = {
  __typename?: 'reportingLearningContentSummaryInsights_max_fields';
  averageProgress?: Maybe<Scalars['numeric']['output']>;
  completedCount?: Maybe<Scalars['bigint']['output']>;
  completionRate?: Maybe<Scalars['numeric']['output']>;
  metaUpdateTime?: Maybe<Scalars['timestamptz']['output']>;
  notStartedCount?: Maybe<Scalars['bigint']['output']>;
  startedCount?: Maybe<Scalars['bigint']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['catalog_item_type']['output']>;
  urn?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type RReportingLearningContentSummaryInsights_Min_Fields = {
  __typename?: 'reportingLearningContentSummaryInsights_min_fields';
  averageProgress?: Maybe<Scalars['numeric']['output']>;
  completedCount?: Maybe<Scalars['bigint']['output']>;
  completionRate?: Maybe<Scalars['numeric']['output']>;
  metaUpdateTime?: Maybe<Scalars['timestamptz']['output']>;
  notStartedCount?: Maybe<Scalars['bigint']['output']>;
  startedCount?: Maybe<Scalars['bigint']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['catalog_item_type']['output']>;
  urn?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "reporting.mv_learning_content_summary_insights". */
export type RReportingLearningContentSummaryInsights_Order_By = {
  averageProgress?: InputMaybe<ROrder_By>;
  completedCount?: InputMaybe<ROrder_By>;
  completionRate?: InputMaybe<ROrder_By>;
  metaUpdateTime?: InputMaybe<ROrder_By>;
  notStartedCount?: InputMaybe<ROrder_By>;
  startedCount?: InputMaybe<ROrder_By>;
  title?: InputMaybe<ROrder_By>;
  type?: InputMaybe<ROrder_By>;
  urn?: InputMaybe<ROrder_By>;
};

/** select columns of table "reporting.mv_learning_content_summary_insights" */
export enum RReportingLearningContentSummaryInsights_Select_Column {
  /** column name */
  AverageProgress = 'averageProgress',
  /** column name */
  CompletedCount = 'completedCount',
  /** column name */
  CompletionRate = 'completionRate',
  /** column name */
  MetaUpdateTime = 'metaUpdateTime',
  /** column name */
  NotStartedCount = 'notStartedCount',
  /** column name */
  StartedCount = 'startedCount',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  Urn = 'urn',
}

/** aggregate stddev on columns */
export type RReportingLearningContentSummaryInsights_Stddev_Fields = {
  __typename?: 'reportingLearningContentSummaryInsights_stddev_fields';
  averageProgress?: Maybe<Scalars['Float']['output']>;
  completedCount?: Maybe<Scalars['Float']['output']>;
  completionRate?: Maybe<Scalars['Float']['output']>;
  notStartedCount?: Maybe<Scalars['Float']['output']>;
  startedCount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type RReportingLearningContentSummaryInsights_Stddev_Pop_Fields = {
  __typename?: 'reportingLearningContentSummaryInsights_stddev_pop_fields';
  averageProgress?: Maybe<Scalars['Float']['output']>;
  completedCount?: Maybe<Scalars['Float']['output']>;
  completionRate?: Maybe<Scalars['Float']['output']>;
  notStartedCount?: Maybe<Scalars['Float']['output']>;
  startedCount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type RReportingLearningContentSummaryInsights_Stddev_Samp_Fields = {
  __typename?: 'reportingLearningContentSummaryInsights_stddev_samp_fields';
  averageProgress?: Maybe<Scalars['Float']['output']>;
  completedCount?: Maybe<Scalars['Float']['output']>;
  completionRate?: Maybe<Scalars['Float']['output']>;
  notStartedCount?: Maybe<Scalars['Float']['output']>;
  startedCount?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "reportingLearningContentSummaryInsights" */
export type RReportingLearningContentSummaryInsights_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: RReportingLearningContentSummaryInsights_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<RCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type RReportingLearningContentSummaryInsights_Stream_Cursor_Value_Input = {
  averageProgress?: InputMaybe<Scalars['numeric']['input']>;
  completedCount?: InputMaybe<Scalars['bigint']['input']>;
  completionRate?: InputMaybe<Scalars['numeric']['input']>;
  metaUpdateTime?: InputMaybe<Scalars['timestamptz']['input']>;
  notStartedCount?: InputMaybe<Scalars['bigint']['input']>;
  startedCount?: InputMaybe<Scalars['bigint']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['catalog_item_type']['input']>;
  urn?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type RReportingLearningContentSummaryInsights_Sum_Fields = {
  __typename?: 'reportingLearningContentSummaryInsights_sum_fields';
  averageProgress?: Maybe<Scalars['numeric']['output']>;
  completedCount?: Maybe<Scalars['bigint']['output']>;
  completionRate?: Maybe<Scalars['numeric']['output']>;
  notStartedCount?: Maybe<Scalars['bigint']['output']>;
  startedCount?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate var_pop on columns */
export type RReportingLearningContentSummaryInsights_Var_Pop_Fields = {
  __typename?: 'reportingLearningContentSummaryInsights_var_pop_fields';
  averageProgress?: Maybe<Scalars['Float']['output']>;
  completedCount?: Maybe<Scalars['Float']['output']>;
  completionRate?: Maybe<Scalars['Float']['output']>;
  notStartedCount?: Maybe<Scalars['Float']['output']>;
  startedCount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type RReportingLearningContentSummaryInsights_Var_Samp_Fields = {
  __typename?: 'reportingLearningContentSummaryInsights_var_samp_fields';
  averageProgress?: Maybe<Scalars['Float']['output']>;
  completedCount?: Maybe<Scalars['Float']['output']>;
  completionRate?: Maybe<Scalars['Float']['output']>;
  notStartedCount?: Maybe<Scalars['Float']['output']>;
  startedCount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type RReportingLearningContentSummaryInsights_Variance_Fields = {
  __typename?: 'reportingLearningContentSummaryInsights_variance_fields';
  averageProgress?: Maybe<Scalars['Float']['output']>;
  completedCount?: Maybe<Scalars['Float']['output']>;
  completionRate?: Maybe<Scalars['Float']['output']>;
  notStartedCount?: Maybe<Scalars['Float']['output']>;
  startedCount?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "reporting.mv_learning_journey_content_insights" */
export type RReportingLearningJourneyContentInsights = {
  __typename?: 'reportingLearningJourneyContentInsights';
  averageProgress?: Maybe<Scalars['numeric']['output']>;
  completedCount?: Maybe<Scalars['bigint']['output']>;
  completionRate?: Maybe<Scalars['numeric']['output']>;
  learningContentTitle?: Maybe<Scalars['String']['output']>;
  learningContentURN?: Maybe<Scalars['String']['output']>;
  learningJourneyTitle?: Maybe<Scalars['String']['output']>;
  learningJourneyURN?: Maybe<Scalars['String']['output']>;
  learningType?: Maybe<Scalars['String']['output']>;
  metaUpdateTime?: Maybe<Scalars['timestamptz']['output']>;
  notStartedCount?: Maybe<Scalars['bigint']['output']>;
  plannedDuration?: Maybe<Scalars['Int']['output']>;
  startedCount?: Maybe<Scalars['bigint']['output']>;
  totalEffortInSeconds?: Maybe<Scalars['numeric']['output']>;
};

/** aggregated selection of "reporting.mv_learning_journey_content_insights" */
export type RReportingLearningJourneyContentInsights_Aggregate = {
  __typename?: 'reportingLearningJourneyContentInsights_aggregate';
  aggregate?: Maybe<RReportingLearningJourneyContentInsights_Aggregate_Fields>;
  nodes: Array<RReportingLearningJourneyContentInsights>;
};

/** aggregate fields of "reporting.mv_learning_journey_content_insights" */
export type RReportingLearningJourneyContentInsights_Aggregate_Fields = {
  __typename?: 'reportingLearningJourneyContentInsights_aggregate_fields';
  avg?: Maybe<RReportingLearningJourneyContentInsights_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<RReportingLearningJourneyContentInsights_Max_Fields>;
  min?: Maybe<RReportingLearningJourneyContentInsights_Min_Fields>;
  stddev?: Maybe<RReportingLearningJourneyContentInsights_Stddev_Fields>;
  stddev_pop?: Maybe<RReportingLearningJourneyContentInsights_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<RReportingLearningJourneyContentInsights_Stddev_Samp_Fields>;
  sum?: Maybe<RReportingLearningJourneyContentInsights_Sum_Fields>;
  var_pop?: Maybe<RReportingLearningJourneyContentInsights_Var_Pop_Fields>;
  var_samp?: Maybe<RReportingLearningJourneyContentInsights_Var_Samp_Fields>;
  variance?: Maybe<RReportingLearningJourneyContentInsights_Variance_Fields>;
};

/** aggregate fields of "reporting.mv_learning_journey_content_insights" */
export type RReportingLearningJourneyContentInsights_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<RReportingLearningJourneyContentInsights_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type RReportingLearningJourneyContentInsights_Avg_Fields = {
  __typename?: 'reportingLearningJourneyContentInsights_avg_fields';
  averageProgress?: Maybe<Scalars['Float']['output']>;
  completedCount?: Maybe<Scalars['Float']['output']>;
  completionRate?: Maybe<Scalars['Float']['output']>;
  notStartedCount?: Maybe<Scalars['Float']['output']>;
  plannedDuration?: Maybe<Scalars['Float']['output']>;
  startedCount?: Maybe<Scalars['Float']['output']>;
  totalEffortInSeconds?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "reporting.mv_learning_journey_content_insights". All fields are combined with a logical 'AND'. */
export type RReportingLearningJourneyContentInsights_Bool_Exp = {
  _and?: InputMaybe<Array<RReportingLearningJourneyContentInsights_Bool_Exp>>;
  _not?: InputMaybe<RReportingLearningJourneyContentInsights_Bool_Exp>;
  _or?: InputMaybe<Array<RReportingLearningJourneyContentInsights_Bool_Exp>>;
  averageProgress?: InputMaybe<RNumeric_Comparison_Exp>;
  completedCount?: InputMaybe<RBigint_Comparison_Exp>;
  completionRate?: InputMaybe<RNumeric_Comparison_Exp>;
  learningContentTitle?: InputMaybe<RString_Comparison_Exp>;
  learningContentURN?: InputMaybe<RString_Comparison_Exp>;
  learningJourneyTitle?: InputMaybe<RString_Comparison_Exp>;
  learningJourneyURN?: InputMaybe<RString_Comparison_Exp>;
  learningType?: InputMaybe<RString_Comparison_Exp>;
  metaUpdateTime?: InputMaybe<RTimestamptz_Comparison_Exp>;
  notStartedCount?: InputMaybe<RBigint_Comparison_Exp>;
  plannedDuration?: InputMaybe<RInt_Comparison_Exp>;
  startedCount?: InputMaybe<RBigint_Comparison_Exp>;
  totalEffortInSeconds?: InputMaybe<RNumeric_Comparison_Exp>;
};

/** aggregate max on columns */
export type RReportingLearningJourneyContentInsights_Max_Fields = {
  __typename?: 'reportingLearningJourneyContentInsights_max_fields';
  averageProgress?: Maybe<Scalars['numeric']['output']>;
  completedCount?: Maybe<Scalars['bigint']['output']>;
  completionRate?: Maybe<Scalars['numeric']['output']>;
  learningContentTitle?: Maybe<Scalars['String']['output']>;
  learningContentURN?: Maybe<Scalars['String']['output']>;
  learningJourneyTitle?: Maybe<Scalars['String']['output']>;
  learningJourneyURN?: Maybe<Scalars['String']['output']>;
  learningType?: Maybe<Scalars['String']['output']>;
  metaUpdateTime?: Maybe<Scalars['timestamptz']['output']>;
  notStartedCount?: Maybe<Scalars['bigint']['output']>;
  plannedDuration?: Maybe<Scalars['Int']['output']>;
  startedCount?: Maybe<Scalars['bigint']['output']>;
  totalEffortInSeconds?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate min on columns */
export type RReportingLearningJourneyContentInsights_Min_Fields = {
  __typename?: 'reportingLearningJourneyContentInsights_min_fields';
  averageProgress?: Maybe<Scalars['numeric']['output']>;
  completedCount?: Maybe<Scalars['bigint']['output']>;
  completionRate?: Maybe<Scalars['numeric']['output']>;
  learningContentTitle?: Maybe<Scalars['String']['output']>;
  learningContentURN?: Maybe<Scalars['String']['output']>;
  learningJourneyTitle?: Maybe<Scalars['String']['output']>;
  learningJourneyURN?: Maybe<Scalars['String']['output']>;
  learningType?: Maybe<Scalars['String']['output']>;
  metaUpdateTime?: Maybe<Scalars['timestamptz']['output']>;
  notStartedCount?: Maybe<Scalars['bigint']['output']>;
  plannedDuration?: Maybe<Scalars['Int']['output']>;
  startedCount?: Maybe<Scalars['bigint']['output']>;
  totalEffortInSeconds?: Maybe<Scalars['numeric']['output']>;
};

/** Ordering options when selecting data from "reporting.mv_learning_journey_content_insights". */
export type RReportingLearningJourneyContentInsights_Order_By = {
  averageProgress?: InputMaybe<ROrder_By>;
  completedCount?: InputMaybe<ROrder_By>;
  completionRate?: InputMaybe<ROrder_By>;
  learningContentTitle?: InputMaybe<ROrder_By>;
  learningContentURN?: InputMaybe<ROrder_By>;
  learningJourneyTitle?: InputMaybe<ROrder_By>;
  learningJourneyURN?: InputMaybe<ROrder_By>;
  learningType?: InputMaybe<ROrder_By>;
  metaUpdateTime?: InputMaybe<ROrder_By>;
  notStartedCount?: InputMaybe<ROrder_By>;
  plannedDuration?: InputMaybe<ROrder_By>;
  startedCount?: InputMaybe<ROrder_By>;
  totalEffortInSeconds?: InputMaybe<ROrder_By>;
};

/** select columns of table "reporting.mv_learning_journey_content_insights" */
export enum RReportingLearningJourneyContentInsights_Select_Column {
  /** column name */
  AverageProgress = 'averageProgress',
  /** column name */
  CompletedCount = 'completedCount',
  /** column name */
  CompletionRate = 'completionRate',
  /** column name */
  LearningContentTitle = 'learningContentTitle',
  /** column name */
  LearningContentUrn = 'learningContentURN',
  /** column name */
  LearningJourneyTitle = 'learningJourneyTitle',
  /** column name */
  LearningJourneyUrn = 'learningJourneyURN',
  /** column name */
  LearningType = 'learningType',
  /** column name */
  MetaUpdateTime = 'metaUpdateTime',
  /** column name */
  NotStartedCount = 'notStartedCount',
  /** column name */
  PlannedDuration = 'plannedDuration',
  /** column name */
  StartedCount = 'startedCount',
  /** column name */
  TotalEffortInSeconds = 'totalEffortInSeconds',
}

/** aggregate stddev on columns */
export type RReportingLearningJourneyContentInsights_Stddev_Fields = {
  __typename?: 'reportingLearningJourneyContentInsights_stddev_fields';
  averageProgress?: Maybe<Scalars['Float']['output']>;
  completedCount?: Maybe<Scalars['Float']['output']>;
  completionRate?: Maybe<Scalars['Float']['output']>;
  notStartedCount?: Maybe<Scalars['Float']['output']>;
  plannedDuration?: Maybe<Scalars['Float']['output']>;
  startedCount?: Maybe<Scalars['Float']['output']>;
  totalEffortInSeconds?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type RReportingLearningJourneyContentInsights_Stddev_Pop_Fields = {
  __typename?: 'reportingLearningJourneyContentInsights_stddev_pop_fields';
  averageProgress?: Maybe<Scalars['Float']['output']>;
  completedCount?: Maybe<Scalars['Float']['output']>;
  completionRate?: Maybe<Scalars['Float']['output']>;
  notStartedCount?: Maybe<Scalars['Float']['output']>;
  plannedDuration?: Maybe<Scalars['Float']['output']>;
  startedCount?: Maybe<Scalars['Float']['output']>;
  totalEffortInSeconds?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type RReportingLearningJourneyContentInsights_Stddev_Samp_Fields = {
  __typename?: 'reportingLearningJourneyContentInsights_stddev_samp_fields';
  averageProgress?: Maybe<Scalars['Float']['output']>;
  completedCount?: Maybe<Scalars['Float']['output']>;
  completionRate?: Maybe<Scalars['Float']['output']>;
  notStartedCount?: Maybe<Scalars['Float']['output']>;
  plannedDuration?: Maybe<Scalars['Float']['output']>;
  startedCount?: Maybe<Scalars['Float']['output']>;
  totalEffortInSeconds?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "reportingLearningJourneyContentInsights" */
export type RReportingLearningJourneyContentInsights_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: RReportingLearningJourneyContentInsights_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<RCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type RReportingLearningJourneyContentInsights_Stream_Cursor_Value_Input = {
  averageProgress?: InputMaybe<Scalars['numeric']['input']>;
  completedCount?: InputMaybe<Scalars['bigint']['input']>;
  completionRate?: InputMaybe<Scalars['numeric']['input']>;
  learningContentTitle?: InputMaybe<Scalars['String']['input']>;
  learningContentURN?: InputMaybe<Scalars['String']['input']>;
  learningJourneyTitle?: InputMaybe<Scalars['String']['input']>;
  learningJourneyURN?: InputMaybe<Scalars['String']['input']>;
  learningType?: InputMaybe<Scalars['String']['input']>;
  metaUpdateTime?: InputMaybe<Scalars['timestamptz']['input']>;
  notStartedCount?: InputMaybe<Scalars['bigint']['input']>;
  plannedDuration?: InputMaybe<Scalars['Int']['input']>;
  startedCount?: InputMaybe<Scalars['bigint']['input']>;
  totalEffortInSeconds?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate sum on columns */
export type RReportingLearningJourneyContentInsights_Sum_Fields = {
  __typename?: 'reportingLearningJourneyContentInsights_sum_fields';
  averageProgress?: Maybe<Scalars['numeric']['output']>;
  completedCount?: Maybe<Scalars['bigint']['output']>;
  completionRate?: Maybe<Scalars['numeric']['output']>;
  notStartedCount?: Maybe<Scalars['bigint']['output']>;
  plannedDuration?: Maybe<Scalars['Int']['output']>;
  startedCount?: Maybe<Scalars['bigint']['output']>;
  totalEffortInSeconds?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate var_pop on columns */
export type RReportingLearningJourneyContentInsights_Var_Pop_Fields = {
  __typename?: 'reportingLearningJourneyContentInsights_var_pop_fields';
  averageProgress?: Maybe<Scalars['Float']['output']>;
  completedCount?: Maybe<Scalars['Float']['output']>;
  completionRate?: Maybe<Scalars['Float']['output']>;
  notStartedCount?: Maybe<Scalars['Float']['output']>;
  plannedDuration?: Maybe<Scalars['Float']['output']>;
  startedCount?: Maybe<Scalars['Float']['output']>;
  totalEffortInSeconds?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type RReportingLearningJourneyContentInsights_Var_Samp_Fields = {
  __typename?: 'reportingLearningJourneyContentInsights_var_samp_fields';
  averageProgress?: Maybe<Scalars['Float']['output']>;
  completedCount?: Maybe<Scalars['Float']['output']>;
  completionRate?: Maybe<Scalars['Float']['output']>;
  notStartedCount?: Maybe<Scalars['Float']['output']>;
  plannedDuration?: Maybe<Scalars['Float']['output']>;
  startedCount?: Maybe<Scalars['Float']['output']>;
  totalEffortInSeconds?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type RReportingLearningJourneyContentInsights_Variance_Fields = {
  __typename?: 'reportingLearningJourneyContentInsights_variance_fields';
  averageProgress?: Maybe<Scalars['Float']['output']>;
  completedCount?: Maybe<Scalars['Float']['output']>;
  completionRate?: Maybe<Scalars['Float']['output']>;
  notStartedCount?: Maybe<Scalars['Float']['output']>;
  plannedDuration?: Maybe<Scalars['Float']['output']>;
  startedCount?: Maybe<Scalars['Float']['output']>;
  totalEffortInSeconds?: Maybe<Scalars['Float']['output']>;
};

/** reportingLearningJourneysItemsInsightsNative Query Arguments */
export type RReportingLearningJourneysItemsInsights_Arguments = {
  learning_journey_urn: Scalars['String']['input'];
  learning_urns?: InputMaybe<Scalars['jsonb']['input']>;
  space_id?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
  team_ids?: InputMaybe<Scalars['jsonb']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** reportingLearningJourneysItemsTotalCountNative Query Arguments */
export type RReportingLearningJourneysItemsTotalCount_Arguments = {
  learning_journey_urn: Scalars['String']['input'];
  learning_urns?: InputMaybe<Scalars['jsonb']['input']>;
  space_id?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
  team_ids?: InputMaybe<Scalars['jsonb']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** reportingLearningJourneysItemsNative Query Arguments */
export type RReportingLearningJourneysItems_Arguments = {
  learning_journey_urn: Scalars['String']['input'];
  learning_urns?: InputMaybe<Scalars['jsonb']['input']>;
  space_id?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
  team_ids?: InputMaybe<Scalars['jsonb']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** reportingLearningsInsightsNative Query Arguments */
export type RReportingLearningsInsights_Arguments = {
  category?: InputMaybe<Scalars['String']['input']>;
  learning_urns?: InputMaybe<Scalars['jsonb']['input']>;
  partner?: InputMaybe<Scalars['String']['input']>;
  space_id?: InputMaybe<Scalars['Int']['input']>;
  status: Scalars['String']['input'];
  subject?: InputMaybe<Scalars['String']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
  team_ids?: InputMaybe<Scalars['jsonb']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  visibility?: InputMaybe<Scalars['String']['input']>;
};

/** reportingLearningsTotalCountNative Query Arguments */
export type RReportingLearningsTotalCount_Arguments = {
  category?: InputMaybe<Scalars['String']['input']>;
  learning_urns?: InputMaybe<Scalars['jsonb']['input']>;
  partner?: InputMaybe<Scalars['String']['input']>;
  space_id?: InputMaybe<Scalars['Int']['input']>;
  status: Scalars['String']['input'];
  subject?: InputMaybe<Scalars['String']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
  team_ids?: InputMaybe<Scalars['jsonb']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  visibility?: InputMaybe<Scalars['String']['input']>;
};

/** reportingLearningsNative Query Arguments */
export type RReportingLearnings_Arguments = {
  category?: InputMaybe<Scalars['String']['input']>;
  learning_urns?: InputMaybe<Scalars['jsonb']['input']>;
  partner?: InputMaybe<Scalars['String']['input']>;
  space_id?: InputMaybe<Scalars['Int']['input']>;
  status: Scalars['String']['input'];
  subject?: InputMaybe<Scalars['String']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
  team_ids?: InputMaybe<Scalars['jsonb']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  visibility?: InputMaybe<Scalars['String']['input']>;
};

/** reportingMyLearningJourneysInsightsV2Native Query Arguments */
export type RReportingMyLearningJourneysInsightsV2_Arguments = {
  learning_journey_urns?: InputMaybe<Scalars['jsonb']['input']>;
  space_id?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
  team_ids?: InputMaybe<Scalars['jsonb']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** reportingMyLearningJourneysLearnersTotalCountNative Query Arguments */
export type RReportingMyLearningJourneysLearnersTotalCount_Arguments = {
  is_complete?: InputMaybe<Scalars['Boolean']['input']>;
  learning_journey_urn: Scalars['String']['input'];
  space_id?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
  team_ids?: InputMaybe<Scalars['jsonb']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** reportingMyLearningJourneysLearnersNative Query Arguments */
export type RReportingMyLearningJourneysLearners_Arguments = {
  is_complete?: InputMaybe<Scalars['Boolean']['input']>;
  learning_journey_urn: Scalars['String']['input'];
  space_id?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
  team_ids?: InputMaybe<Scalars['jsonb']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** reportingMyLearningJourneysTotalCountNative Query Arguments */
export type RReportingMyLearningJourneysTotalCount_Arguments = {
  learning_journey_urns?: InputMaybe<Scalars['jsonb']['input']>;
  space_id?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
  team_ids?: InputMaybe<Scalars['jsonb']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** reportingMyLearningJourneysV2Native Query Arguments */
export type RReportingMyLearningJourneysV2_Arguments = {
  learning_journey_urns?: InputMaybe<Scalars['jsonb']['input']>;
  space_id?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
  team_ids?: InputMaybe<Scalars['jsonb']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** reportingMyLearningsLearnersTotalCountNative Query Arguments */
export type RReportingMyLearningsLearnersTotalCount_Arguments = {
  expected_user_ids?: InputMaybe<Scalars['jsonb']['input']>;
  is_complete?: InputMaybe<Scalars['Boolean']['input']>;
  learning_urn: Scalars['String']['input'];
  space_id?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
  team_ids?: InputMaybe<Scalars['jsonb']['input']>;
  user_ids?: InputMaybe<Scalars['jsonb']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** reportingMyLearningsLearnersNative Query Arguments */
export type RReportingMyLearningsLearners_Arguments = {
  expected_user_ids?: InputMaybe<Scalars['jsonb']['input']>;
  is_complete?: InputMaybe<Scalars['Boolean']['input']>;
  learning_urn: Scalars['String']['input'];
  space_id?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
  team_ids?: InputMaybe<Scalars['jsonb']['input']>;
  user_ids?: InputMaybe<Scalars['jsonb']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** reportingMyLearningsPracticalAssessmentNative Query Arguments */
export type RReportingMyLearningsPracticalAssessment_Arguments = {
  learning_urn: Scalars['String']['input'];
  space_id?: InputMaybe<Scalars['Int']['input']>;
  team_ids?: InputMaybe<Scalars['jsonb']['input']>;
};

/** reportingMyLearningsQuizNative Query Arguments */
export type RReportingMyLearningsQuiz_Arguments = {
  learning_urn: Scalars['String']['input'];
  space_id?: InputMaybe<Scalars['Int']['input']>;
  team_ids?: InputMaybe<Scalars['jsonb']['input']>;
};

/** reportingMyLearningsReactionsNative Query Arguments */
export type RReportingMyLearningsReactions_Arguments = {
  learning_urn: Scalars['String']['input'];
  space_id?: InputMaybe<Scalars['Int']['input']>;
  team_ids?: InputMaybe<Scalars['jsonb']['input']>;
};

/** reportingMyLearningsSurveyNative Query Arguments */
export type RReportingMyLearningsSurvey_Arguments = {
  learning_urn: Scalars['String']['input'];
  space_id?: InputMaybe<Scalars['Int']['input']>;
  team_ids?: InputMaybe<Scalars['jsonb']['input']>;
};

/** reportingMyLiveLearningsLearnersTotalCountNative Query Arguments */
export type RReportingMyLiveLearningsLearnersTotalCount_Arguments = {
  learning_urn: Scalars['String']['input'];
  space_id?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
  team_ids?: InputMaybe<Scalars['jsonb']['input']>;
  user_ids?: InputMaybe<Scalars['jsonb']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** reportingMyLiveLearningsLearnersNative Query Arguments */
export type RReportingMyLiveLearningsLearners_Arguments = {
  learning_urn: Scalars['String']['input'];
  space_id?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
  team_ids?: InputMaybe<Scalars['jsonb']['input']>;
  user_ids?: InputMaybe<Scalars['jsonb']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "reporting.mv_participants_insights" */
export type RReportingParticipantsInsights = {
  __typename?: 'reportingParticipantsInsights';
  email?: Maybe<Scalars['String']['output']>;
  firstStatement?: Maybe<Scalars['timestamptz']['output']>;
  metaUpdateTime?: Maybe<Scalars['timestamptz']['output']>;
  profileImage?: Maybe<Scalars['String']['output']>;
  progress?: Maybe<Scalars['float8']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  urn?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['Int']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "reporting.mv_participants_insights" */
export type RReportingParticipantsInsights_Aggregate = {
  __typename?: 'reportingParticipantsInsights_aggregate';
  aggregate?: Maybe<RReportingParticipantsInsights_Aggregate_Fields>;
  nodes: Array<RReportingParticipantsInsights>;
};

/** aggregate fields of "reporting.mv_participants_insights" */
export type RReportingParticipantsInsights_Aggregate_Fields = {
  __typename?: 'reportingParticipantsInsights_aggregate_fields';
  avg?: Maybe<RReportingParticipantsInsights_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<RReportingParticipantsInsights_Max_Fields>;
  min?: Maybe<RReportingParticipantsInsights_Min_Fields>;
  stddev?: Maybe<RReportingParticipantsInsights_Stddev_Fields>;
  stddev_pop?: Maybe<RReportingParticipantsInsights_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<RReportingParticipantsInsights_Stddev_Samp_Fields>;
  sum?: Maybe<RReportingParticipantsInsights_Sum_Fields>;
  var_pop?: Maybe<RReportingParticipantsInsights_Var_Pop_Fields>;
  var_samp?: Maybe<RReportingParticipantsInsights_Var_Samp_Fields>;
  variance?: Maybe<RReportingParticipantsInsights_Variance_Fields>;
};

/** aggregate fields of "reporting.mv_participants_insights" */
export type RReportingParticipantsInsights_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<RReportingParticipantsInsights_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type RReportingParticipantsInsights_Avg_Fields = {
  __typename?: 'reportingParticipantsInsights_avg_fields';
  progress?: Maybe<Scalars['Float']['output']>;
  userId?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "reporting.mv_participants_insights". All fields are combined with a logical 'AND'. */
export type RReportingParticipantsInsights_Bool_Exp = {
  _and?: InputMaybe<Array<RReportingParticipantsInsights_Bool_Exp>>;
  _not?: InputMaybe<RReportingParticipantsInsights_Bool_Exp>;
  _or?: InputMaybe<Array<RReportingParticipantsInsights_Bool_Exp>>;
  email?: InputMaybe<RString_Comparison_Exp>;
  firstStatement?: InputMaybe<RTimestamptz_Comparison_Exp>;
  metaUpdateTime?: InputMaybe<RTimestamptz_Comparison_Exp>;
  profileImage?: InputMaybe<RString_Comparison_Exp>;
  progress?: InputMaybe<RFloat8_Comparison_Exp>;
  status?: InputMaybe<RString_Comparison_Exp>;
  urn?: InputMaybe<RString_Comparison_Exp>;
  userId?: InputMaybe<RInt_Comparison_Exp>;
  username?: InputMaybe<RString_Comparison_Exp>;
};

/** aggregate max on columns */
export type RReportingParticipantsInsights_Max_Fields = {
  __typename?: 'reportingParticipantsInsights_max_fields';
  email?: Maybe<Scalars['String']['output']>;
  firstStatement?: Maybe<Scalars['timestamptz']['output']>;
  metaUpdateTime?: Maybe<Scalars['timestamptz']['output']>;
  profileImage?: Maybe<Scalars['String']['output']>;
  progress?: Maybe<Scalars['float8']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  urn?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['Int']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type RReportingParticipantsInsights_Min_Fields = {
  __typename?: 'reportingParticipantsInsights_min_fields';
  email?: Maybe<Scalars['String']['output']>;
  firstStatement?: Maybe<Scalars['timestamptz']['output']>;
  metaUpdateTime?: Maybe<Scalars['timestamptz']['output']>;
  profileImage?: Maybe<Scalars['String']['output']>;
  progress?: Maybe<Scalars['float8']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  urn?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['Int']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "reporting.mv_participants_insights". */
export type RReportingParticipantsInsights_Order_By = {
  email?: InputMaybe<ROrder_By>;
  firstStatement?: InputMaybe<ROrder_By>;
  metaUpdateTime?: InputMaybe<ROrder_By>;
  profileImage?: InputMaybe<ROrder_By>;
  progress?: InputMaybe<ROrder_By>;
  status?: InputMaybe<ROrder_By>;
  urn?: InputMaybe<ROrder_By>;
  userId?: InputMaybe<ROrder_By>;
  username?: InputMaybe<ROrder_By>;
};

/** select columns of table "reporting.mv_participants_insights" */
export enum RReportingParticipantsInsights_Select_Column {
  /** column name */
  Email = 'email',
  /** column name */
  FirstStatement = 'firstStatement',
  /** column name */
  MetaUpdateTime = 'metaUpdateTime',
  /** column name */
  ProfileImage = 'profileImage',
  /** column name */
  Progress = 'progress',
  /** column name */
  Status = 'status',
  /** column name */
  Urn = 'urn',
  /** column name */
  UserId = 'userId',
  /** column name */
  Username = 'username',
}

/** aggregate stddev on columns */
export type RReportingParticipantsInsights_Stddev_Fields = {
  __typename?: 'reportingParticipantsInsights_stddev_fields';
  progress?: Maybe<Scalars['Float']['output']>;
  userId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type RReportingParticipantsInsights_Stddev_Pop_Fields = {
  __typename?: 'reportingParticipantsInsights_stddev_pop_fields';
  progress?: Maybe<Scalars['Float']['output']>;
  userId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type RReportingParticipantsInsights_Stddev_Samp_Fields = {
  __typename?: 'reportingParticipantsInsights_stddev_samp_fields';
  progress?: Maybe<Scalars['Float']['output']>;
  userId?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "reportingParticipantsInsights" */
export type RReportingParticipantsInsights_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: RReportingParticipantsInsights_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<RCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type RReportingParticipantsInsights_Stream_Cursor_Value_Input = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstStatement?: InputMaybe<Scalars['timestamptz']['input']>;
  metaUpdateTime?: InputMaybe<Scalars['timestamptz']['input']>;
  profileImage?: InputMaybe<Scalars['String']['input']>;
  progress?: InputMaybe<Scalars['float8']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  urn?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type RReportingParticipantsInsights_Sum_Fields = {
  __typename?: 'reportingParticipantsInsights_sum_fields';
  progress?: Maybe<Scalars['float8']['output']>;
  userId?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type RReportingParticipantsInsights_Var_Pop_Fields = {
  __typename?: 'reportingParticipantsInsights_var_pop_fields';
  progress?: Maybe<Scalars['Float']['output']>;
  userId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type RReportingParticipantsInsights_Var_Samp_Fields = {
  __typename?: 'reportingParticipantsInsights_var_samp_fields';
  progress?: Maybe<Scalars['Float']['output']>;
  userId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type RReportingParticipantsInsights_Variance_Fields = {
  __typename?: 'reportingParticipantsInsights_variance_fields';
  progress?: Maybe<Scalars['Float']['output']>;
  userId?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "reporting.mv_practical_assessment_insights" */
export type RReportingPracticalAssessmentInsights = {
  __typename?: 'reportingPracticalAssessmentInsights';
  learningContentURN?: Maybe<Scalars['String']['output']>;
  learningJourneyURN?: Maybe<Scalars['String']['output']>;
  learning_content_title?: Maybe<Scalars['String']['output']>;
  learning_journey_title?: Maybe<Scalars['String']['output']>;
  pageId?: Maybe<Scalars['Int']['output']>;
  pageURN?: Maybe<Scalars['String']['output']>;
  responsesCount?: Maybe<Scalars['bigint']['output']>;
  reviewsCount?: Maybe<Scalars['bigint']['output']>;
};

/** aggregated selection of "reporting.mv_practical_assessment_insights" */
export type RReportingPracticalAssessmentInsights_Aggregate = {
  __typename?: 'reportingPracticalAssessmentInsights_aggregate';
  aggregate?: Maybe<RReportingPracticalAssessmentInsights_Aggregate_Fields>;
  nodes: Array<RReportingPracticalAssessmentInsights>;
};

/** aggregate fields of "reporting.mv_practical_assessment_insights" */
export type RReportingPracticalAssessmentInsights_Aggregate_Fields = {
  __typename?: 'reportingPracticalAssessmentInsights_aggregate_fields';
  avg?: Maybe<RReportingPracticalAssessmentInsights_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<RReportingPracticalAssessmentInsights_Max_Fields>;
  min?: Maybe<RReportingPracticalAssessmentInsights_Min_Fields>;
  stddev?: Maybe<RReportingPracticalAssessmentInsights_Stddev_Fields>;
  stddev_pop?: Maybe<RReportingPracticalAssessmentInsights_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<RReportingPracticalAssessmentInsights_Stddev_Samp_Fields>;
  sum?: Maybe<RReportingPracticalAssessmentInsights_Sum_Fields>;
  var_pop?: Maybe<RReportingPracticalAssessmentInsights_Var_Pop_Fields>;
  var_samp?: Maybe<RReportingPracticalAssessmentInsights_Var_Samp_Fields>;
  variance?: Maybe<RReportingPracticalAssessmentInsights_Variance_Fields>;
};

/** aggregate fields of "reporting.mv_practical_assessment_insights" */
export type RReportingPracticalAssessmentInsights_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<RReportingPracticalAssessmentInsights_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type RReportingPracticalAssessmentInsights_Avg_Fields = {
  __typename?: 'reportingPracticalAssessmentInsights_avg_fields';
  pageId?: Maybe<Scalars['Float']['output']>;
  responsesCount?: Maybe<Scalars['Float']['output']>;
  reviewsCount?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "reporting.mv_practical_assessment_insights". All fields are combined with a logical 'AND'. */
export type RReportingPracticalAssessmentInsights_Bool_Exp = {
  _and?: InputMaybe<Array<RReportingPracticalAssessmentInsights_Bool_Exp>>;
  _not?: InputMaybe<RReportingPracticalAssessmentInsights_Bool_Exp>;
  _or?: InputMaybe<Array<RReportingPracticalAssessmentInsights_Bool_Exp>>;
  learningContentURN?: InputMaybe<RString_Comparison_Exp>;
  learningJourneyURN?: InputMaybe<RString_Comparison_Exp>;
  learning_content_title?: InputMaybe<RString_Comparison_Exp>;
  learning_journey_title?: InputMaybe<RString_Comparison_Exp>;
  pageId?: InputMaybe<RInt_Comparison_Exp>;
  pageURN?: InputMaybe<RString_Comparison_Exp>;
  responsesCount?: InputMaybe<RBigint_Comparison_Exp>;
  reviewsCount?: InputMaybe<RBigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type RReportingPracticalAssessmentInsights_Max_Fields = {
  __typename?: 'reportingPracticalAssessmentInsights_max_fields';
  learningContentURN?: Maybe<Scalars['String']['output']>;
  learningJourneyURN?: Maybe<Scalars['String']['output']>;
  learning_content_title?: Maybe<Scalars['String']['output']>;
  learning_journey_title?: Maybe<Scalars['String']['output']>;
  pageId?: Maybe<Scalars['Int']['output']>;
  pageURN?: Maybe<Scalars['String']['output']>;
  responsesCount?: Maybe<Scalars['bigint']['output']>;
  reviewsCount?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate min on columns */
export type RReportingPracticalAssessmentInsights_Min_Fields = {
  __typename?: 'reportingPracticalAssessmentInsights_min_fields';
  learningContentURN?: Maybe<Scalars['String']['output']>;
  learningJourneyURN?: Maybe<Scalars['String']['output']>;
  learning_content_title?: Maybe<Scalars['String']['output']>;
  learning_journey_title?: Maybe<Scalars['String']['output']>;
  pageId?: Maybe<Scalars['Int']['output']>;
  pageURN?: Maybe<Scalars['String']['output']>;
  responsesCount?: Maybe<Scalars['bigint']['output']>;
  reviewsCount?: Maybe<Scalars['bigint']['output']>;
};

/** Ordering options when selecting data from "reporting.mv_practical_assessment_insights". */
export type RReportingPracticalAssessmentInsights_Order_By = {
  learningContentURN?: InputMaybe<ROrder_By>;
  learningJourneyURN?: InputMaybe<ROrder_By>;
  learning_content_title?: InputMaybe<ROrder_By>;
  learning_journey_title?: InputMaybe<ROrder_By>;
  pageId?: InputMaybe<ROrder_By>;
  pageURN?: InputMaybe<ROrder_By>;
  responsesCount?: InputMaybe<ROrder_By>;
  reviewsCount?: InputMaybe<ROrder_By>;
};

/** select columns of table "reporting.mv_practical_assessment_insights" */
export enum RReportingPracticalAssessmentInsights_Select_Column {
  /** column name */
  LearningContentUrn = 'learningContentURN',
  /** column name */
  LearningJourneyUrn = 'learningJourneyURN',
  /** column name */
  LearningContentTitle = 'learning_content_title',
  /** column name */
  LearningJourneyTitle = 'learning_journey_title',
  /** column name */
  PageId = 'pageId',
  /** column name */
  PageUrn = 'pageURN',
  /** column name */
  ResponsesCount = 'responsesCount',
  /** column name */
  ReviewsCount = 'reviewsCount',
}

/** aggregate stddev on columns */
export type RReportingPracticalAssessmentInsights_Stddev_Fields = {
  __typename?: 'reportingPracticalAssessmentInsights_stddev_fields';
  pageId?: Maybe<Scalars['Float']['output']>;
  responsesCount?: Maybe<Scalars['Float']['output']>;
  reviewsCount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type RReportingPracticalAssessmentInsights_Stddev_Pop_Fields = {
  __typename?: 'reportingPracticalAssessmentInsights_stddev_pop_fields';
  pageId?: Maybe<Scalars['Float']['output']>;
  responsesCount?: Maybe<Scalars['Float']['output']>;
  reviewsCount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type RReportingPracticalAssessmentInsights_Stddev_Samp_Fields = {
  __typename?: 'reportingPracticalAssessmentInsights_stddev_samp_fields';
  pageId?: Maybe<Scalars['Float']['output']>;
  responsesCount?: Maybe<Scalars['Float']['output']>;
  reviewsCount?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "reportingPracticalAssessmentInsights" */
export type RReportingPracticalAssessmentInsights_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: RReportingPracticalAssessmentInsights_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<RCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type RReportingPracticalAssessmentInsights_Stream_Cursor_Value_Input = {
  learningContentURN?: InputMaybe<Scalars['String']['input']>;
  learningJourneyURN?: InputMaybe<Scalars['String']['input']>;
  learning_content_title?: InputMaybe<Scalars['String']['input']>;
  learning_journey_title?: InputMaybe<Scalars['String']['input']>;
  pageId?: InputMaybe<Scalars['Int']['input']>;
  pageURN?: InputMaybe<Scalars['String']['input']>;
  responsesCount?: InputMaybe<Scalars['bigint']['input']>;
  reviewsCount?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type RReportingPracticalAssessmentInsights_Sum_Fields = {
  __typename?: 'reportingPracticalAssessmentInsights_sum_fields';
  pageId?: Maybe<Scalars['Int']['output']>;
  responsesCount?: Maybe<Scalars['bigint']['output']>;
  reviewsCount?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate var_pop on columns */
export type RReportingPracticalAssessmentInsights_Var_Pop_Fields = {
  __typename?: 'reportingPracticalAssessmentInsights_var_pop_fields';
  pageId?: Maybe<Scalars['Float']['output']>;
  responsesCount?: Maybe<Scalars['Float']['output']>;
  reviewsCount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type RReportingPracticalAssessmentInsights_Var_Samp_Fields = {
  __typename?: 'reportingPracticalAssessmentInsights_var_samp_fields';
  pageId?: Maybe<Scalars['Float']['output']>;
  responsesCount?: Maybe<Scalars['Float']['output']>;
  reviewsCount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type RReportingPracticalAssessmentInsights_Variance_Fields = {
  __typename?: 'reportingPracticalAssessmentInsights_variance_fields';
  pageId?: Maybe<Scalars['Float']['output']>;
  responsesCount?: Maybe<Scalars['Float']['output']>;
  reviewsCount?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "reporting.mv_quiz_questions_insights" */
export type RReportingQuizQuestionsInsights = {
  __typename?: 'reportingQuizQuestionsInsights';
  activityUrn?: Maybe<Scalars['String']['output']>;
  correctAnswers?: Maybe<Scalars['bigint']['output']>;
  incorrectAnswers?: Maybe<Scalars['bigint']['output']>;
  learningUrn?: Maybe<Scalars['String']['output']>;
  metaUpdateTime?: Maybe<Scalars['timestamptz']['output']>;
  questionId?: Maybe<Scalars['String']['output']>;
  questionIdx?: Maybe<Scalars['String']['output']>;
  questionTitle?: Maybe<Scalars['String']['output']>;
  quizId?: Maybe<Scalars['Int']['output']>;
  ratio?: Maybe<Scalars['numeric']['output']>;
  totalAnswers?: Maybe<Scalars['bigint']['output']>;
};

/** aggregated selection of "reporting.mv_quiz_questions_insights" */
export type RReportingQuizQuestionsInsights_Aggregate = {
  __typename?: 'reportingQuizQuestionsInsights_aggregate';
  aggregate?: Maybe<RReportingQuizQuestionsInsights_Aggregate_Fields>;
  nodes: Array<RReportingQuizQuestionsInsights>;
};

/** aggregate fields of "reporting.mv_quiz_questions_insights" */
export type RReportingQuizQuestionsInsights_Aggregate_Fields = {
  __typename?: 'reportingQuizQuestionsInsights_aggregate_fields';
  avg?: Maybe<RReportingQuizQuestionsInsights_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<RReportingQuizQuestionsInsights_Max_Fields>;
  min?: Maybe<RReportingQuizQuestionsInsights_Min_Fields>;
  stddev?: Maybe<RReportingQuizQuestionsInsights_Stddev_Fields>;
  stddev_pop?: Maybe<RReportingQuizQuestionsInsights_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<RReportingQuizQuestionsInsights_Stddev_Samp_Fields>;
  sum?: Maybe<RReportingQuizQuestionsInsights_Sum_Fields>;
  var_pop?: Maybe<RReportingQuizQuestionsInsights_Var_Pop_Fields>;
  var_samp?: Maybe<RReportingQuizQuestionsInsights_Var_Samp_Fields>;
  variance?: Maybe<RReportingQuizQuestionsInsights_Variance_Fields>;
};

/** aggregate fields of "reporting.mv_quiz_questions_insights" */
export type RReportingQuizQuestionsInsights_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<RReportingQuizQuestionsInsights_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type RReportingQuizQuestionsInsights_Avg_Fields = {
  __typename?: 'reportingQuizQuestionsInsights_avg_fields';
  correctAnswers?: Maybe<Scalars['Float']['output']>;
  incorrectAnswers?: Maybe<Scalars['Float']['output']>;
  quizId?: Maybe<Scalars['Float']['output']>;
  ratio?: Maybe<Scalars['Float']['output']>;
  totalAnswers?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "reporting.mv_quiz_questions_insights". All fields are combined with a logical 'AND'. */
export type RReportingQuizQuestionsInsights_Bool_Exp = {
  _and?: InputMaybe<Array<RReportingQuizQuestionsInsights_Bool_Exp>>;
  _not?: InputMaybe<RReportingQuizQuestionsInsights_Bool_Exp>;
  _or?: InputMaybe<Array<RReportingQuizQuestionsInsights_Bool_Exp>>;
  activityUrn?: InputMaybe<RString_Comparison_Exp>;
  correctAnswers?: InputMaybe<RBigint_Comparison_Exp>;
  incorrectAnswers?: InputMaybe<RBigint_Comparison_Exp>;
  learningUrn?: InputMaybe<RString_Comparison_Exp>;
  metaUpdateTime?: InputMaybe<RTimestamptz_Comparison_Exp>;
  questionId?: InputMaybe<RString_Comparison_Exp>;
  questionIdx?: InputMaybe<RString_Comparison_Exp>;
  questionTitle?: InputMaybe<RString_Comparison_Exp>;
  quizId?: InputMaybe<RInt_Comparison_Exp>;
  ratio?: InputMaybe<RNumeric_Comparison_Exp>;
  totalAnswers?: InputMaybe<RBigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type RReportingQuizQuestionsInsights_Max_Fields = {
  __typename?: 'reportingQuizQuestionsInsights_max_fields';
  activityUrn?: Maybe<Scalars['String']['output']>;
  correctAnswers?: Maybe<Scalars['bigint']['output']>;
  incorrectAnswers?: Maybe<Scalars['bigint']['output']>;
  learningUrn?: Maybe<Scalars['String']['output']>;
  metaUpdateTime?: Maybe<Scalars['timestamptz']['output']>;
  questionId?: Maybe<Scalars['String']['output']>;
  questionIdx?: Maybe<Scalars['String']['output']>;
  questionTitle?: Maybe<Scalars['String']['output']>;
  quizId?: Maybe<Scalars['Int']['output']>;
  ratio?: Maybe<Scalars['numeric']['output']>;
  totalAnswers?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate min on columns */
export type RReportingQuizQuestionsInsights_Min_Fields = {
  __typename?: 'reportingQuizQuestionsInsights_min_fields';
  activityUrn?: Maybe<Scalars['String']['output']>;
  correctAnswers?: Maybe<Scalars['bigint']['output']>;
  incorrectAnswers?: Maybe<Scalars['bigint']['output']>;
  learningUrn?: Maybe<Scalars['String']['output']>;
  metaUpdateTime?: Maybe<Scalars['timestamptz']['output']>;
  questionId?: Maybe<Scalars['String']['output']>;
  questionIdx?: Maybe<Scalars['String']['output']>;
  questionTitle?: Maybe<Scalars['String']['output']>;
  quizId?: Maybe<Scalars['Int']['output']>;
  ratio?: Maybe<Scalars['numeric']['output']>;
  totalAnswers?: Maybe<Scalars['bigint']['output']>;
};

/** Ordering options when selecting data from "reporting.mv_quiz_questions_insights". */
export type RReportingQuizQuestionsInsights_Order_By = {
  activityUrn?: InputMaybe<ROrder_By>;
  correctAnswers?: InputMaybe<ROrder_By>;
  incorrectAnswers?: InputMaybe<ROrder_By>;
  learningUrn?: InputMaybe<ROrder_By>;
  metaUpdateTime?: InputMaybe<ROrder_By>;
  questionId?: InputMaybe<ROrder_By>;
  questionIdx?: InputMaybe<ROrder_By>;
  questionTitle?: InputMaybe<ROrder_By>;
  quizId?: InputMaybe<ROrder_By>;
  ratio?: InputMaybe<ROrder_By>;
  totalAnswers?: InputMaybe<ROrder_By>;
};

/** select columns of table "reporting.mv_quiz_questions_insights" */
export enum RReportingQuizQuestionsInsights_Select_Column {
  /** column name */
  ActivityUrn = 'activityUrn',
  /** column name */
  CorrectAnswers = 'correctAnswers',
  /** column name */
  IncorrectAnswers = 'incorrectAnswers',
  /** column name */
  LearningUrn = 'learningUrn',
  /** column name */
  MetaUpdateTime = 'metaUpdateTime',
  /** column name */
  QuestionId = 'questionId',
  /** column name */
  QuestionIdx = 'questionIdx',
  /** column name */
  QuestionTitle = 'questionTitle',
  /** column name */
  QuizId = 'quizId',
  /** column name */
  Ratio = 'ratio',
  /** column name */
  TotalAnswers = 'totalAnswers',
}

/** aggregate stddev on columns */
export type RReportingQuizQuestionsInsights_Stddev_Fields = {
  __typename?: 'reportingQuizQuestionsInsights_stddev_fields';
  correctAnswers?: Maybe<Scalars['Float']['output']>;
  incorrectAnswers?: Maybe<Scalars['Float']['output']>;
  quizId?: Maybe<Scalars['Float']['output']>;
  ratio?: Maybe<Scalars['Float']['output']>;
  totalAnswers?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type RReportingQuizQuestionsInsights_Stddev_Pop_Fields = {
  __typename?: 'reportingQuizQuestionsInsights_stddev_pop_fields';
  correctAnswers?: Maybe<Scalars['Float']['output']>;
  incorrectAnswers?: Maybe<Scalars['Float']['output']>;
  quizId?: Maybe<Scalars['Float']['output']>;
  ratio?: Maybe<Scalars['Float']['output']>;
  totalAnswers?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type RReportingQuizQuestionsInsights_Stddev_Samp_Fields = {
  __typename?: 'reportingQuizQuestionsInsights_stddev_samp_fields';
  correctAnswers?: Maybe<Scalars['Float']['output']>;
  incorrectAnswers?: Maybe<Scalars['Float']['output']>;
  quizId?: Maybe<Scalars['Float']['output']>;
  ratio?: Maybe<Scalars['Float']['output']>;
  totalAnswers?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "reportingQuizQuestionsInsights" */
export type RReportingQuizQuestionsInsights_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: RReportingQuizQuestionsInsights_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<RCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type RReportingQuizQuestionsInsights_Stream_Cursor_Value_Input = {
  activityUrn?: InputMaybe<Scalars['String']['input']>;
  correctAnswers?: InputMaybe<Scalars['bigint']['input']>;
  incorrectAnswers?: InputMaybe<Scalars['bigint']['input']>;
  learningUrn?: InputMaybe<Scalars['String']['input']>;
  metaUpdateTime?: InputMaybe<Scalars['timestamptz']['input']>;
  questionId?: InputMaybe<Scalars['String']['input']>;
  questionIdx?: InputMaybe<Scalars['String']['input']>;
  questionTitle?: InputMaybe<Scalars['String']['input']>;
  quizId?: InputMaybe<Scalars['Int']['input']>;
  ratio?: InputMaybe<Scalars['numeric']['input']>;
  totalAnswers?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type RReportingQuizQuestionsInsights_Sum_Fields = {
  __typename?: 'reportingQuizQuestionsInsights_sum_fields';
  correctAnswers?: Maybe<Scalars['bigint']['output']>;
  incorrectAnswers?: Maybe<Scalars['bigint']['output']>;
  quizId?: Maybe<Scalars['Int']['output']>;
  ratio?: Maybe<Scalars['numeric']['output']>;
  totalAnswers?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate var_pop on columns */
export type RReportingQuizQuestionsInsights_Var_Pop_Fields = {
  __typename?: 'reportingQuizQuestionsInsights_var_pop_fields';
  correctAnswers?: Maybe<Scalars['Float']['output']>;
  incorrectAnswers?: Maybe<Scalars['Float']['output']>;
  quizId?: Maybe<Scalars['Float']['output']>;
  ratio?: Maybe<Scalars['Float']['output']>;
  totalAnswers?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type RReportingQuizQuestionsInsights_Var_Samp_Fields = {
  __typename?: 'reportingQuizQuestionsInsights_var_samp_fields';
  correctAnswers?: Maybe<Scalars['Float']['output']>;
  incorrectAnswers?: Maybe<Scalars['Float']['output']>;
  quizId?: Maybe<Scalars['Float']['output']>;
  ratio?: Maybe<Scalars['Float']['output']>;
  totalAnswers?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type RReportingQuizQuestionsInsights_Variance_Fields = {
  __typename?: 'reportingQuizQuestionsInsights_variance_fields';
  correctAnswers?: Maybe<Scalars['Float']['output']>;
  incorrectAnswers?: Maybe<Scalars['Float']['output']>;
  quizId?: Maybe<Scalars['Float']['output']>;
  ratio?: Maybe<Scalars['Float']['output']>;
  totalAnswers?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "reporting.mv_quiz_summary_insights" */
export type RReportingQuizSummaryInsights = {
  __typename?: 'reportingQuizSummaryInsights';
  activityUrn?: Maybe<Scalars['String']['output']>;
  avgRatio?: Maybe<Scalars['numeric']['output']>;
  learningUrn?: Maybe<Scalars['String']['output']>;
  metaUpdateTime?: Maybe<Scalars['timestamptz']['output']>;
  totalParticipants?: Maybe<Scalars['bigint']['output']>;
  totalQuestions?: Maybe<Scalars['Int']['output']>;
  totalResponses?: Maybe<Scalars['bigint']['output']>;
};

/** aggregated selection of "reporting.mv_quiz_summary_insights" */
export type RReportingQuizSummaryInsights_Aggregate = {
  __typename?: 'reportingQuizSummaryInsights_aggregate';
  aggregate?: Maybe<RReportingQuizSummaryInsights_Aggregate_Fields>;
  nodes: Array<RReportingQuizSummaryInsights>;
};

/** aggregate fields of "reporting.mv_quiz_summary_insights" */
export type RReportingQuizSummaryInsights_Aggregate_Fields = {
  __typename?: 'reportingQuizSummaryInsights_aggregate_fields';
  avg?: Maybe<RReportingQuizSummaryInsights_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<RReportingQuizSummaryInsights_Max_Fields>;
  min?: Maybe<RReportingQuizSummaryInsights_Min_Fields>;
  stddev?: Maybe<RReportingQuizSummaryInsights_Stddev_Fields>;
  stddev_pop?: Maybe<RReportingQuizSummaryInsights_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<RReportingQuizSummaryInsights_Stddev_Samp_Fields>;
  sum?: Maybe<RReportingQuizSummaryInsights_Sum_Fields>;
  var_pop?: Maybe<RReportingQuizSummaryInsights_Var_Pop_Fields>;
  var_samp?: Maybe<RReportingQuizSummaryInsights_Var_Samp_Fields>;
  variance?: Maybe<RReportingQuizSummaryInsights_Variance_Fields>;
};

/** aggregate fields of "reporting.mv_quiz_summary_insights" */
export type RReportingQuizSummaryInsights_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<RReportingQuizSummaryInsights_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type RReportingQuizSummaryInsights_Avg_Fields = {
  __typename?: 'reportingQuizSummaryInsights_avg_fields';
  avgRatio?: Maybe<Scalars['Float']['output']>;
  totalParticipants?: Maybe<Scalars['Float']['output']>;
  totalQuestions?: Maybe<Scalars['Float']['output']>;
  totalResponses?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "reporting.mv_quiz_summary_insights". All fields are combined with a logical 'AND'. */
export type RReportingQuizSummaryInsights_Bool_Exp = {
  _and?: InputMaybe<Array<RReportingQuizSummaryInsights_Bool_Exp>>;
  _not?: InputMaybe<RReportingQuizSummaryInsights_Bool_Exp>;
  _or?: InputMaybe<Array<RReportingQuizSummaryInsights_Bool_Exp>>;
  activityUrn?: InputMaybe<RString_Comparison_Exp>;
  avgRatio?: InputMaybe<RNumeric_Comparison_Exp>;
  learningUrn?: InputMaybe<RString_Comparison_Exp>;
  metaUpdateTime?: InputMaybe<RTimestamptz_Comparison_Exp>;
  totalParticipants?: InputMaybe<RBigint_Comparison_Exp>;
  totalQuestions?: InputMaybe<RInt_Comparison_Exp>;
  totalResponses?: InputMaybe<RBigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type RReportingQuizSummaryInsights_Max_Fields = {
  __typename?: 'reportingQuizSummaryInsights_max_fields';
  activityUrn?: Maybe<Scalars['String']['output']>;
  avgRatio?: Maybe<Scalars['numeric']['output']>;
  learningUrn?: Maybe<Scalars['String']['output']>;
  metaUpdateTime?: Maybe<Scalars['timestamptz']['output']>;
  totalParticipants?: Maybe<Scalars['bigint']['output']>;
  totalQuestions?: Maybe<Scalars['Int']['output']>;
  totalResponses?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate min on columns */
export type RReportingQuizSummaryInsights_Min_Fields = {
  __typename?: 'reportingQuizSummaryInsights_min_fields';
  activityUrn?: Maybe<Scalars['String']['output']>;
  avgRatio?: Maybe<Scalars['numeric']['output']>;
  learningUrn?: Maybe<Scalars['String']['output']>;
  metaUpdateTime?: Maybe<Scalars['timestamptz']['output']>;
  totalParticipants?: Maybe<Scalars['bigint']['output']>;
  totalQuestions?: Maybe<Scalars['Int']['output']>;
  totalResponses?: Maybe<Scalars['bigint']['output']>;
};

/** Ordering options when selecting data from "reporting.mv_quiz_summary_insights". */
export type RReportingQuizSummaryInsights_Order_By = {
  activityUrn?: InputMaybe<ROrder_By>;
  avgRatio?: InputMaybe<ROrder_By>;
  learningUrn?: InputMaybe<ROrder_By>;
  metaUpdateTime?: InputMaybe<ROrder_By>;
  totalParticipants?: InputMaybe<ROrder_By>;
  totalQuestions?: InputMaybe<ROrder_By>;
  totalResponses?: InputMaybe<ROrder_By>;
};

/** select columns of table "reporting.mv_quiz_summary_insights" */
export enum RReportingQuizSummaryInsights_Select_Column {
  /** column name */
  ActivityUrn = 'activityUrn',
  /** column name */
  AvgRatio = 'avgRatio',
  /** column name */
  LearningUrn = 'learningUrn',
  /** column name */
  MetaUpdateTime = 'metaUpdateTime',
  /** column name */
  TotalParticipants = 'totalParticipants',
  /** column name */
  TotalQuestions = 'totalQuestions',
  /** column name */
  TotalResponses = 'totalResponses',
}

/** aggregate stddev on columns */
export type RReportingQuizSummaryInsights_Stddev_Fields = {
  __typename?: 'reportingQuizSummaryInsights_stddev_fields';
  avgRatio?: Maybe<Scalars['Float']['output']>;
  totalParticipants?: Maybe<Scalars['Float']['output']>;
  totalQuestions?: Maybe<Scalars['Float']['output']>;
  totalResponses?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type RReportingQuizSummaryInsights_Stddev_Pop_Fields = {
  __typename?: 'reportingQuizSummaryInsights_stddev_pop_fields';
  avgRatio?: Maybe<Scalars['Float']['output']>;
  totalParticipants?: Maybe<Scalars['Float']['output']>;
  totalQuestions?: Maybe<Scalars['Float']['output']>;
  totalResponses?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type RReportingQuizSummaryInsights_Stddev_Samp_Fields = {
  __typename?: 'reportingQuizSummaryInsights_stddev_samp_fields';
  avgRatio?: Maybe<Scalars['Float']['output']>;
  totalParticipants?: Maybe<Scalars['Float']['output']>;
  totalQuestions?: Maybe<Scalars['Float']['output']>;
  totalResponses?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "reportingQuizSummaryInsights" */
export type RReportingQuizSummaryInsights_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: RReportingQuizSummaryInsights_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<RCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type RReportingQuizSummaryInsights_Stream_Cursor_Value_Input = {
  activityUrn?: InputMaybe<Scalars['String']['input']>;
  avgRatio?: InputMaybe<Scalars['numeric']['input']>;
  learningUrn?: InputMaybe<Scalars['String']['input']>;
  metaUpdateTime?: InputMaybe<Scalars['timestamptz']['input']>;
  totalParticipants?: InputMaybe<Scalars['bigint']['input']>;
  totalQuestions?: InputMaybe<Scalars['Int']['input']>;
  totalResponses?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type RReportingQuizSummaryInsights_Sum_Fields = {
  __typename?: 'reportingQuizSummaryInsights_sum_fields';
  avgRatio?: Maybe<Scalars['numeric']['output']>;
  totalParticipants?: Maybe<Scalars['bigint']['output']>;
  totalQuestions?: Maybe<Scalars['Int']['output']>;
  totalResponses?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate var_pop on columns */
export type RReportingQuizSummaryInsights_Var_Pop_Fields = {
  __typename?: 'reportingQuizSummaryInsights_var_pop_fields';
  avgRatio?: Maybe<Scalars['Float']['output']>;
  totalParticipants?: Maybe<Scalars['Float']['output']>;
  totalQuestions?: Maybe<Scalars['Float']['output']>;
  totalResponses?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type RReportingQuizSummaryInsights_Var_Samp_Fields = {
  __typename?: 'reportingQuizSummaryInsights_var_samp_fields';
  avgRatio?: Maybe<Scalars['Float']['output']>;
  totalParticipants?: Maybe<Scalars['Float']['output']>;
  totalQuestions?: Maybe<Scalars['Float']['output']>;
  totalResponses?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type RReportingQuizSummaryInsights_Variance_Fields = {
  __typename?: 'reportingQuizSummaryInsights_variance_fields';
  avgRatio?: Maybe<Scalars['Float']['output']>;
  totalParticipants?: Maybe<Scalars['Float']['output']>;
  totalQuestions?: Maybe<Scalars['Float']['output']>;
  totalResponses?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "reporting.mv_reactions_insights" */
export type RReportingReactionsInsights = {
  __typename?: 'reportingReactionsInsights';
  answer?: Maybe<Scalars['reaction_value']['output']>;
  answerCount?: Maybe<Scalars['bigint']['output']>;
  answerRatio?: Maybe<Scalars['numeric']['output']>;
  answerText?: Maybe<Scalars['String']['output']>;
  answerTotal?: Maybe<Scalars['bigint']['output']>;
  metaUpdateTime?: Maybe<Scalars['timestamptz']['output']>;
  urn?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "reporting.mv_reactions_insights" */
export type RReportingReactionsInsights_Aggregate = {
  __typename?: 'reportingReactionsInsights_aggregate';
  aggregate?: Maybe<RReportingReactionsInsights_Aggregate_Fields>;
  nodes: Array<RReportingReactionsInsights>;
};

/** aggregate fields of "reporting.mv_reactions_insights" */
export type RReportingReactionsInsights_Aggregate_Fields = {
  __typename?: 'reportingReactionsInsights_aggregate_fields';
  avg?: Maybe<RReportingReactionsInsights_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<RReportingReactionsInsights_Max_Fields>;
  min?: Maybe<RReportingReactionsInsights_Min_Fields>;
  stddev?: Maybe<RReportingReactionsInsights_Stddev_Fields>;
  stddev_pop?: Maybe<RReportingReactionsInsights_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<RReportingReactionsInsights_Stddev_Samp_Fields>;
  sum?: Maybe<RReportingReactionsInsights_Sum_Fields>;
  var_pop?: Maybe<RReportingReactionsInsights_Var_Pop_Fields>;
  var_samp?: Maybe<RReportingReactionsInsights_Var_Samp_Fields>;
  variance?: Maybe<RReportingReactionsInsights_Variance_Fields>;
};

/** aggregate fields of "reporting.mv_reactions_insights" */
export type RReportingReactionsInsights_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<RReportingReactionsInsights_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type RReportingReactionsInsights_Avg_Fields = {
  __typename?: 'reportingReactionsInsights_avg_fields';
  answerCount?: Maybe<Scalars['Float']['output']>;
  answerRatio?: Maybe<Scalars['Float']['output']>;
  answerTotal?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "reporting.mv_reactions_insights". All fields are combined with a logical 'AND'. */
export type RReportingReactionsInsights_Bool_Exp = {
  _and?: InputMaybe<Array<RReportingReactionsInsights_Bool_Exp>>;
  _not?: InputMaybe<RReportingReactionsInsights_Bool_Exp>;
  _or?: InputMaybe<Array<RReportingReactionsInsights_Bool_Exp>>;
  answer?: InputMaybe<RReaction_Value_Comparison_Exp>;
  answerCount?: InputMaybe<RBigint_Comparison_Exp>;
  answerRatio?: InputMaybe<RNumeric_Comparison_Exp>;
  answerText?: InputMaybe<RString_Comparison_Exp>;
  answerTotal?: InputMaybe<RBigint_Comparison_Exp>;
  metaUpdateTime?: InputMaybe<RTimestamptz_Comparison_Exp>;
  urn?: InputMaybe<RString_Comparison_Exp>;
};

/** aggregate max on columns */
export type RReportingReactionsInsights_Max_Fields = {
  __typename?: 'reportingReactionsInsights_max_fields';
  answer?: Maybe<Scalars['reaction_value']['output']>;
  answerCount?: Maybe<Scalars['bigint']['output']>;
  answerRatio?: Maybe<Scalars['numeric']['output']>;
  answerText?: Maybe<Scalars['String']['output']>;
  answerTotal?: Maybe<Scalars['bigint']['output']>;
  metaUpdateTime?: Maybe<Scalars['timestamptz']['output']>;
  urn?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type RReportingReactionsInsights_Min_Fields = {
  __typename?: 'reportingReactionsInsights_min_fields';
  answer?: Maybe<Scalars['reaction_value']['output']>;
  answerCount?: Maybe<Scalars['bigint']['output']>;
  answerRatio?: Maybe<Scalars['numeric']['output']>;
  answerText?: Maybe<Scalars['String']['output']>;
  answerTotal?: Maybe<Scalars['bigint']['output']>;
  metaUpdateTime?: Maybe<Scalars['timestamptz']['output']>;
  urn?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "reporting.mv_reactions_insights". */
export type RReportingReactionsInsights_Order_By = {
  answer?: InputMaybe<ROrder_By>;
  answerCount?: InputMaybe<ROrder_By>;
  answerRatio?: InputMaybe<ROrder_By>;
  answerText?: InputMaybe<ROrder_By>;
  answerTotal?: InputMaybe<ROrder_By>;
  metaUpdateTime?: InputMaybe<ROrder_By>;
  urn?: InputMaybe<ROrder_By>;
};

/** select columns of table "reporting.mv_reactions_insights" */
export enum RReportingReactionsInsights_Select_Column {
  /** column name */
  Answer = 'answer',
  /** column name */
  AnswerCount = 'answerCount',
  /** column name */
  AnswerRatio = 'answerRatio',
  /** column name */
  AnswerText = 'answerText',
  /** column name */
  AnswerTotal = 'answerTotal',
  /** column name */
  MetaUpdateTime = 'metaUpdateTime',
  /** column name */
  Urn = 'urn',
}

/** aggregate stddev on columns */
export type RReportingReactionsInsights_Stddev_Fields = {
  __typename?: 'reportingReactionsInsights_stddev_fields';
  answerCount?: Maybe<Scalars['Float']['output']>;
  answerRatio?: Maybe<Scalars['Float']['output']>;
  answerTotal?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type RReportingReactionsInsights_Stddev_Pop_Fields = {
  __typename?: 'reportingReactionsInsights_stddev_pop_fields';
  answerCount?: Maybe<Scalars['Float']['output']>;
  answerRatio?: Maybe<Scalars['Float']['output']>;
  answerTotal?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type RReportingReactionsInsights_Stddev_Samp_Fields = {
  __typename?: 'reportingReactionsInsights_stddev_samp_fields';
  answerCount?: Maybe<Scalars['Float']['output']>;
  answerRatio?: Maybe<Scalars['Float']['output']>;
  answerTotal?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "reportingReactionsInsights" */
export type RReportingReactionsInsights_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: RReportingReactionsInsights_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<RCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type RReportingReactionsInsights_Stream_Cursor_Value_Input = {
  answer?: InputMaybe<Scalars['reaction_value']['input']>;
  answerCount?: InputMaybe<Scalars['bigint']['input']>;
  answerRatio?: InputMaybe<Scalars['numeric']['input']>;
  answerText?: InputMaybe<Scalars['String']['input']>;
  answerTotal?: InputMaybe<Scalars['bigint']['input']>;
  metaUpdateTime?: InputMaybe<Scalars['timestamptz']['input']>;
  urn?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type RReportingReactionsInsights_Sum_Fields = {
  __typename?: 'reportingReactionsInsights_sum_fields';
  answerCount?: Maybe<Scalars['bigint']['output']>;
  answerRatio?: Maybe<Scalars['numeric']['output']>;
  answerTotal?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate var_pop on columns */
export type RReportingReactionsInsights_Var_Pop_Fields = {
  __typename?: 'reportingReactionsInsights_var_pop_fields';
  answerCount?: Maybe<Scalars['Float']['output']>;
  answerRatio?: Maybe<Scalars['Float']['output']>;
  answerTotal?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type RReportingReactionsInsights_Var_Samp_Fields = {
  __typename?: 'reportingReactionsInsights_var_samp_fields';
  answerCount?: Maybe<Scalars['Float']['output']>;
  answerRatio?: Maybe<Scalars['Float']['output']>;
  answerTotal?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type RReportingReactionsInsights_Variance_Fields = {
  __typename?: 'reportingReactionsInsights_variance_fields';
  answerCount?: Maybe<Scalars['Float']['output']>;
  answerRatio?: Maybe<Scalars['Float']['output']>;
  answerTotal?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "reporting.mv_survey_answers" */
export type RReportingSurveyAnswers = {
  __typename?: 'reportingSurveyAnswers';
  answer?: Maybe<Scalars['String']['output']>;
  answerCount?: Maybe<Scalars['bigint']['output']>;
  answerPercent?: Maybe<Scalars['numeric']['output']>;
  answerTotal?: Maybe<Scalars['bigint']['output']>;
  dimension?: Maybe<Scalars['metrics_dimension']['output']>;
  metaUpdateTime?: Maybe<Scalars['timestamptz']['output']>;
  pageId?: Maybe<Scalars['Int']['output']>;
  questionTitle?: Maybe<Scalars['String']['output']>;
  questionURN?: Maybe<Scalars['String']['output']>;
  rangeMax?: Maybe<Scalars['Int']['output']>;
  rangeMin?: Maybe<Scalars['Int']['output']>;
  scoreRaw?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  urn?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "reporting.mv_survey_answers" */
export type RReportingSurveyAnswers_Aggregate = {
  __typename?: 'reportingSurveyAnswers_aggregate';
  aggregate?: Maybe<RReportingSurveyAnswers_Aggregate_Fields>;
  nodes: Array<RReportingSurveyAnswers>;
};

/** aggregate fields of "reporting.mv_survey_answers" */
export type RReportingSurveyAnswers_Aggregate_Fields = {
  __typename?: 'reportingSurveyAnswers_aggregate_fields';
  avg?: Maybe<RReportingSurveyAnswers_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<RReportingSurveyAnswers_Max_Fields>;
  min?: Maybe<RReportingSurveyAnswers_Min_Fields>;
  stddev?: Maybe<RReportingSurveyAnswers_Stddev_Fields>;
  stddev_pop?: Maybe<RReportingSurveyAnswers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<RReportingSurveyAnswers_Stddev_Samp_Fields>;
  sum?: Maybe<RReportingSurveyAnswers_Sum_Fields>;
  var_pop?: Maybe<RReportingSurveyAnswers_Var_Pop_Fields>;
  var_samp?: Maybe<RReportingSurveyAnswers_Var_Samp_Fields>;
  variance?: Maybe<RReportingSurveyAnswers_Variance_Fields>;
};

/** aggregate fields of "reporting.mv_survey_answers" */
export type RReportingSurveyAnswers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<RReportingSurveyAnswers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type RReportingSurveyAnswers_Avg_Fields = {
  __typename?: 'reportingSurveyAnswers_avg_fields';
  answerCount?: Maybe<Scalars['Float']['output']>;
  answerPercent?: Maybe<Scalars['Float']['output']>;
  answerTotal?: Maybe<Scalars['Float']['output']>;
  pageId?: Maybe<Scalars['Float']['output']>;
  rangeMax?: Maybe<Scalars['Float']['output']>;
  rangeMin?: Maybe<Scalars['Float']['output']>;
  scoreRaw?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "reporting.mv_survey_answers". All fields are combined with a logical 'AND'. */
export type RReportingSurveyAnswers_Bool_Exp = {
  _and?: InputMaybe<Array<RReportingSurveyAnswers_Bool_Exp>>;
  _not?: InputMaybe<RReportingSurveyAnswers_Bool_Exp>;
  _or?: InputMaybe<Array<RReportingSurveyAnswers_Bool_Exp>>;
  answer?: InputMaybe<RString_Comparison_Exp>;
  answerCount?: InputMaybe<RBigint_Comparison_Exp>;
  answerPercent?: InputMaybe<RNumeric_Comparison_Exp>;
  answerTotal?: InputMaybe<RBigint_Comparison_Exp>;
  dimension?: InputMaybe<RMetrics_Dimension_Comparison_Exp>;
  metaUpdateTime?: InputMaybe<RTimestamptz_Comparison_Exp>;
  pageId?: InputMaybe<RInt_Comparison_Exp>;
  questionTitle?: InputMaybe<RString_Comparison_Exp>;
  questionURN?: InputMaybe<RString_Comparison_Exp>;
  rangeMax?: InputMaybe<RInt_Comparison_Exp>;
  rangeMin?: InputMaybe<RInt_Comparison_Exp>;
  scoreRaw?: InputMaybe<RInt_Comparison_Exp>;
  title?: InputMaybe<RString_Comparison_Exp>;
  urn?: InputMaybe<RString_Comparison_Exp>;
};

/** aggregate max on columns */
export type RReportingSurveyAnswers_Max_Fields = {
  __typename?: 'reportingSurveyAnswers_max_fields';
  answer?: Maybe<Scalars['String']['output']>;
  answerCount?: Maybe<Scalars['bigint']['output']>;
  answerPercent?: Maybe<Scalars['numeric']['output']>;
  answerTotal?: Maybe<Scalars['bigint']['output']>;
  dimension?: Maybe<Scalars['metrics_dimension']['output']>;
  metaUpdateTime?: Maybe<Scalars['timestamptz']['output']>;
  pageId?: Maybe<Scalars['Int']['output']>;
  questionTitle?: Maybe<Scalars['String']['output']>;
  questionURN?: Maybe<Scalars['String']['output']>;
  rangeMax?: Maybe<Scalars['Int']['output']>;
  rangeMin?: Maybe<Scalars['Int']['output']>;
  scoreRaw?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  urn?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type RReportingSurveyAnswers_Min_Fields = {
  __typename?: 'reportingSurveyAnswers_min_fields';
  answer?: Maybe<Scalars['String']['output']>;
  answerCount?: Maybe<Scalars['bigint']['output']>;
  answerPercent?: Maybe<Scalars['numeric']['output']>;
  answerTotal?: Maybe<Scalars['bigint']['output']>;
  dimension?: Maybe<Scalars['metrics_dimension']['output']>;
  metaUpdateTime?: Maybe<Scalars['timestamptz']['output']>;
  pageId?: Maybe<Scalars['Int']['output']>;
  questionTitle?: Maybe<Scalars['String']['output']>;
  questionURN?: Maybe<Scalars['String']['output']>;
  rangeMax?: Maybe<Scalars['Int']['output']>;
  rangeMin?: Maybe<Scalars['Int']['output']>;
  scoreRaw?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  urn?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "reporting.mv_survey_answers". */
export type RReportingSurveyAnswers_Order_By = {
  answer?: InputMaybe<ROrder_By>;
  answerCount?: InputMaybe<ROrder_By>;
  answerPercent?: InputMaybe<ROrder_By>;
  answerTotal?: InputMaybe<ROrder_By>;
  dimension?: InputMaybe<ROrder_By>;
  metaUpdateTime?: InputMaybe<ROrder_By>;
  pageId?: InputMaybe<ROrder_By>;
  questionTitle?: InputMaybe<ROrder_By>;
  questionURN?: InputMaybe<ROrder_By>;
  rangeMax?: InputMaybe<ROrder_By>;
  rangeMin?: InputMaybe<ROrder_By>;
  scoreRaw?: InputMaybe<ROrder_By>;
  title?: InputMaybe<ROrder_By>;
  urn?: InputMaybe<ROrder_By>;
};

/** select columns of table "reporting.mv_survey_answers" */
export enum RReportingSurveyAnswers_Select_Column {
  /** column name */
  Answer = 'answer',
  /** column name */
  AnswerCount = 'answerCount',
  /** column name */
  AnswerPercent = 'answerPercent',
  /** column name */
  AnswerTotal = 'answerTotal',
  /** column name */
  Dimension = 'dimension',
  /** column name */
  MetaUpdateTime = 'metaUpdateTime',
  /** column name */
  PageId = 'pageId',
  /** column name */
  QuestionTitle = 'questionTitle',
  /** column name */
  QuestionUrn = 'questionURN',
  /** column name */
  RangeMax = 'rangeMax',
  /** column name */
  RangeMin = 'rangeMin',
  /** column name */
  ScoreRaw = 'scoreRaw',
  /** column name */
  Title = 'title',
  /** column name */
  Urn = 'urn',
}

/** aggregate stddev on columns */
export type RReportingSurveyAnswers_Stddev_Fields = {
  __typename?: 'reportingSurveyAnswers_stddev_fields';
  answerCount?: Maybe<Scalars['Float']['output']>;
  answerPercent?: Maybe<Scalars['Float']['output']>;
  answerTotal?: Maybe<Scalars['Float']['output']>;
  pageId?: Maybe<Scalars['Float']['output']>;
  rangeMax?: Maybe<Scalars['Float']['output']>;
  rangeMin?: Maybe<Scalars['Float']['output']>;
  scoreRaw?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type RReportingSurveyAnswers_Stddev_Pop_Fields = {
  __typename?: 'reportingSurveyAnswers_stddev_pop_fields';
  answerCount?: Maybe<Scalars['Float']['output']>;
  answerPercent?: Maybe<Scalars['Float']['output']>;
  answerTotal?: Maybe<Scalars['Float']['output']>;
  pageId?: Maybe<Scalars['Float']['output']>;
  rangeMax?: Maybe<Scalars['Float']['output']>;
  rangeMin?: Maybe<Scalars['Float']['output']>;
  scoreRaw?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type RReportingSurveyAnswers_Stddev_Samp_Fields = {
  __typename?: 'reportingSurveyAnswers_stddev_samp_fields';
  answerCount?: Maybe<Scalars['Float']['output']>;
  answerPercent?: Maybe<Scalars['Float']['output']>;
  answerTotal?: Maybe<Scalars['Float']['output']>;
  pageId?: Maybe<Scalars['Float']['output']>;
  rangeMax?: Maybe<Scalars['Float']['output']>;
  rangeMin?: Maybe<Scalars['Float']['output']>;
  scoreRaw?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "reportingSurveyAnswers" */
export type RReportingSurveyAnswers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: RReportingSurveyAnswers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<RCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type RReportingSurveyAnswers_Stream_Cursor_Value_Input = {
  answer?: InputMaybe<Scalars['String']['input']>;
  answerCount?: InputMaybe<Scalars['bigint']['input']>;
  answerPercent?: InputMaybe<Scalars['numeric']['input']>;
  answerTotal?: InputMaybe<Scalars['bigint']['input']>;
  dimension?: InputMaybe<Scalars['metrics_dimension']['input']>;
  metaUpdateTime?: InputMaybe<Scalars['timestamptz']['input']>;
  pageId?: InputMaybe<Scalars['Int']['input']>;
  questionTitle?: InputMaybe<Scalars['String']['input']>;
  questionURN?: InputMaybe<Scalars['String']['input']>;
  rangeMax?: InputMaybe<Scalars['Int']['input']>;
  rangeMin?: InputMaybe<Scalars['Int']['input']>;
  scoreRaw?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  urn?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type RReportingSurveyAnswers_Sum_Fields = {
  __typename?: 'reportingSurveyAnswers_sum_fields';
  answerCount?: Maybe<Scalars['bigint']['output']>;
  answerPercent?: Maybe<Scalars['numeric']['output']>;
  answerTotal?: Maybe<Scalars['bigint']['output']>;
  pageId?: Maybe<Scalars['Int']['output']>;
  rangeMax?: Maybe<Scalars['Int']['output']>;
  rangeMin?: Maybe<Scalars['Int']['output']>;
  scoreRaw?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type RReportingSurveyAnswers_Var_Pop_Fields = {
  __typename?: 'reportingSurveyAnswers_var_pop_fields';
  answerCount?: Maybe<Scalars['Float']['output']>;
  answerPercent?: Maybe<Scalars['Float']['output']>;
  answerTotal?: Maybe<Scalars['Float']['output']>;
  pageId?: Maybe<Scalars['Float']['output']>;
  rangeMax?: Maybe<Scalars['Float']['output']>;
  rangeMin?: Maybe<Scalars['Float']['output']>;
  scoreRaw?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type RReportingSurveyAnswers_Var_Samp_Fields = {
  __typename?: 'reportingSurveyAnswers_var_samp_fields';
  answerCount?: Maybe<Scalars['Float']['output']>;
  answerPercent?: Maybe<Scalars['Float']['output']>;
  answerTotal?: Maybe<Scalars['Float']['output']>;
  pageId?: Maybe<Scalars['Float']['output']>;
  rangeMax?: Maybe<Scalars['Float']['output']>;
  rangeMin?: Maybe<Scalars['Float']['output']>;
  scoreRaw?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type RReportingSurveyAnswers_Variance_Fields = {
  __typename?: 'reportingSurveyAnswers_variance_fields';
  answerCount?: Maybe<Scalars['Float']['output']>;
  answerPercent?: Maybe<Scalars['Float']['output']>;
  answerTotal?: Maybe<Scalars['Float']['output']>;
  pageId?: Maybe<Scalars['Float']['output']>;
  rangeMax?: Maybe<Scalars['Float']['output']>;
  rangeMin?: Maybe<Scalars['Float']['output']>;
  scoreRaw?: Maybe<Scalars['Float']['output']>;
};

export type RSurvey = {
  __typename?: 'survey';
  answer: Scalars['String']['output'];
  answer_count: Scalars['Int']['output'];
  answer_percent: Scalars['numeric']['output'];
  answer_total: Scalars['Int']['output'];
  question_title: Scalars['String']['output'];
  question_urn: Scalars['String']['output'];
  score_max: Scalars['Int']['output'];
  score_min: Scalars['Int']['output'];
  score_raw: Scalars['Int']['output'];
  score_scaled: Scalars['numeric']['output'];
  space_id: Scalars['Int']['output'];
  team_ids?: Maybe<Scalars['jsonb']['output']>;
  title: Scalars['String']['output'];
  urn: Scalars['String']['output'];
};

export type RSurveyTeam_IdsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the logical model for "survey". All fields are combined with a logical 'AND'. */
export type RSurvey_Bool_Exp_Bool_Exp = {
  _and?: InputMaybe<Array<RSurvey_Bool_Exp_Bool_Exp>>;
  _not?: InputMaybe<RSurvey_Bool_Exp_Bool_Exp>;
  _or?: InputMaybe<Array<RSurvey_Bool_Exp_Bool_Exp>>;
  answer?: InputMaybe<RString_Comparison_Exp>;
  answer_count?: InputMaybe<RInt_Comparison_Exp>;
  answer_percent?: InputMaybe<RNumeric_Comparison_Exp>;
  answer_total?: InputMaybe<RInt_Comparison_Exp>;
  question_title?: InputMaybe<RString_Comparison_Exp>;
  question_urn?: InputMaybe<RString_Comparison_Exp>;
  score_max?: InputMaybe<RInt_Comparison_Exp>;
  score_min?: InputMaybe<RInt_Comparison_Exp>;
  score_raw?: InputMaybe<RInt_Comparison_Exp>;
  score_scaled?: InputMaybe<RNumeric_Comparison_Exp>;
  space_id?: InputMaybe<RInt_Comparison_Exp>;
  team_ids?: InputMaybe<RJsonb_Comparison_Exp>;
  title?: InputMaybe<RString_Comparison_Exp>;
  urn?: InputMaybe<RString_Comparison_Exp>;
};

export enum RSurvey_Enum_Name {
  /** column name */
  Answer = 'answer',
  /** column name */
  AnswerCount = 'answer_count',
  /** column name */
  AnswerPercent = 'answer_percent',
  /** column name */
  AnswerTotal = 'answer_total',
  /** column name */
  QuestionTitle = 'question_title',
  /** column name */
  QuestionUrn = 'question_urn',
  /** column name */
  ScoreMax = 'score_max',
  /** column name */
  ScoreMin = 'score_min',
  /** column name */
  ScoreRaw = 'score_raw',
  /** column name */
  ScoreScaled = 'score_scaled',
  /** column name */
  SpaceId = 'space_id',
  /** column name */
  TeamIds = 'team_ids',
  /** column name */
  Title = 'title',
  /** column name */
  Urn = 'urn',
}

/** Ordering options when selecting data from "survey". */
export type RSurvey_Order_By = {
  answer?: InputMaybe<ROrder_By>;
  answer_count?: InputMaybe<ROrder_By>;
  answer_percent?: InputMaybe<ROrder_By>;
  answer_total?: InputMaybe<ROrder_By>;
  question_title?: InputMaybe<ROrder_By>;
  question_urn?: InputMaybe<ROrder_By>;
  score_max?: InputMaybe<ROrder_By>;
  score_min?: InputMaybe<ROrder_By>;
  score_raw?: InputMaybe<ROrder_By>;
  score_scaled?: InputMaybe<ROrder_By>;
  space_id?: InputMaybe<ROrder_By>;
  team_ids?: InputMaybe<ROrder_By>;
  title?: InputMaybe<ROrder_By>;
  urn?: InputMaybe<ROrder_By>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type RTimestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']['input']>;
  _gt?: InputMaybe<Scalars['timestamptz']['input']>;
  _gte?: InputMaybe<Scalars['timestamptz']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamptz']['input']>;
  _lte?: InputMaybe<Scalars['timestamptz']['input']>;
  _neq?: InputMaybe<Scalars['timestamptz']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
};

export type RTotal_Count = {
  __typename?: 'total_count';
  space_id: Scalars['Int']['output'];
  space_ids: Scalars['jsonb']['output'];
  team_ids?: Maybe<Scalars['jsonb']['output']>;
  total_count: Scalars['bigint']['output'];
};

export type RTotal_CountSpace_IdsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

export type RTotal_CountTeam_IdsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the logical model for "total_count". All fields are combined with a logical 'AND'. */
export type RTotal_Count_Bool_Exp_Bool_Exp = {
  _and?: InputMaybe<Array<RTotal_Count_Bool_Exp_Bool_Exp>>;
  _not?: InputMaybe<RTotal_Count_Bool_Exp_Bool_Exp>;
  _or?: InputMaybe<Array<RTotal_Count_Bool_Exp_Bool_Exp>>;
  space_id?: InputMaybe<RInt_Comparison_Exp>;
  space_ids?: InputMaybe<RJsonb_Comparison_Exp>;
  team_ids?: InputMaybe<RJsonb_Comparison_Exp>;
  total_count?: InputMaybe<RBigint_Comparison_Exp>;
};

export enum RTotal_Count_Enum_Name {
  /** column name */
  SpaceId = 'space_id',
  /** column name */
  SpaceIds = 'space_ids',
  /** column name */
  TeamIds = 'team_ids',
  /** column name */
  TotalCount = 'total_count',
}

/** Ordering options when selecting data from "total_count". */
export type RTotal_Count_Order_By = {
  space_id?: InputMaybe<ROrder_By>;
  space_ids?: InputMaybe<ROrder_By>;
  team_ids?: InputMaybe<ROrder_By>;
  total_count?: InputMaybe<ROrder_By>;
};

export type RAssignmentV3UsersQueryVariables = Exact<{
  filter: RAssignmentV3UsersFilter;
  pagination?: InputMaybe<RPagination>;
  sort?: InputMaybe<RAssignmentUsersV3Sort>;
  includeProgress: Scalars['Boolean']['input'];
}>;

export type RAssignmentV3UsersQuery = {
  __typename?: 'Query';
  assignmentV3Users:
    | {
        __typename?: 'AssignmentStandardV3UsersPage';
        total: number;
        data: Array<{
          __typename?: 'AssignmentStandardV3User';
          userId: number;
          assignmentId: number;
          progress?: {
            __typename: 'AssignmentStandardV3UserProgress';
            participations: Array<{
              __typename?: 'AssignmentStandardV3UserParticipation';
              assignmentLearningId: number;
              participationId: number;
              progress: number;
              completedAt?: Date | undefined;
            }>;
          };
          user: {
            __typename?: 'UserV2';
            id: number;
            email: string;
            profileImage?: string | undefined;
            username?: string | undefined;
            status: RUserStatus;
          };
        }>;
      }
    | {
        __typename?: 'AssignmentStrategicV3UsersPage';
        total: number;
        data: Array<{
          __typename?: 'AssignmentStrategicV3User';
          userId: number;
          assignmentId: number;
          progress?: { __typename?: 'AssignmentStrategicV3UserProgress'; completed: boolean; estimatedEffort: number };
          user: {
            __typename?: 'UserV2';
            id: number;
            email: string;
            profileImage?: string | undefined;
            username?: string | undefined;
            status: RUserStatus;
          };
        }>;
      };
};

export type RUserLearningContentLastParticipationQueryVariables = Exact<{
  userId: Scalars['Int']['input'];
  learningContentId: Scalars['URN']['input'];
}>;

export type RUserLearningContentLastParticipationQuery = {
  __typename?: 'Query';
  userLearningContentLastParticipation?:
    | {
        __typename?: 'ParticipationV2';
        id?: number | undefined;
        objectId: string;
        userId: number;
        progress: number;
        isCompleted: boolean;
        completedAt?: Date | undefined;
        plannedDuration?: number | undefined;
        plannedLearningsCount?: number | undefined;
        userEffort?: number | undefined;
        expiryAt?: Date | undefined;
      }
    | undefined;
};

export type RUserLearningContentLastParticipationsQueryVariables = Exact<{
  userId: Scalars['Int']['input'];
  learningContentIdIn: Array<Scalars['URN']['input']> | Scalars['URN']['input'];
}>;

export type RUserLearningContentLastParticipationsQuery = {
  __typename?: 'Query';
  userLearningContentLastParticipations: Array<{
    __typename?: 'ParticipationV2';
    id?: number | undefined;
    objectId: string;
    userId: number;
    progress: number;
    isCompleted: boolean;
    completedAt?: Date | undefined;
    plannedDuration?: number | undefined;
    plannedLearningsCount?: number | undefined;
    userEffort?: number | undefined;
    expiryAt?: Date | undefined;
  }>;
};

export type RLearningCollectionsV2QueryVariables = Exact<{
  filter: RLearningCollectionFilter;
  pagination?: InputMaybe<RPagination>;
  sort?: InputMaybe<RLearningCollectionSort>;
}>;

export type RLearningCollectionsV2Query = {
  __typename?: 'Query';
  learningCollectionsV2: {
    __typename?: 'LearningCollectionPage';
    limit: number;
    page: number;
    total: number;
    data: Array<{
      __typename?: 'LearningCollectionV2';
      id: number;
      title: string;
      visibility: RLearningV2Visibility;
      status: RLearningV2Status;
      space?: { __typename?: 'SpaceV2'; id: number; name: string } | undefined;
      subject?:
        | { __typename?: 'SubjectCategoryV2'; subjectId: number; subject: string; categoryId: number; category: string }
        | undefined;
      items: { __typename?: 'LearningCollectionItemPage'; total: number };
    }>;
  };
};

export type RLearningCollectionV2QueryVariables = Exact<{
  id: Scalars['Int']['input'];
  spaceIds: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;

export type RLearningCollectionV2Query = {
  __typename?: 'Query';
  learningCollectionV2: {
    __typename?: 'LearningCollectionV2';
    id: number;
    urn: string;
    title: string;
    imageURL?: string | undefined;
    visibility: RLearningV2Visibility;
    status: RLearningV2Status;
    updatedAt?: Date | undefined;
    updatedByUser?:
      | { __typename?: 'UserV2'; id: number; firstname?: string | undefined; lastname?: string | undefined }
      | undefined;
    space?: { __typename?: 'SpaceV2'; id: number; name: string } | undefined;
    subject?:
      | { __typename?: 'SubjectCategoryV2'; subjectId: number; subject: string; categoryId: number; category: string }
      | undefined;
    items: {
      __typename?: 'LearningCollectionItemPage';
      total: number;
      data: Array<{
        __typename?: 'LearningCollectionItem';
        collectionId: number;
        contentURNV1: string;
        curationURNV1: string;
        id: number;
        order: number;
        content?:
          | {
              __typename?: 'LearningContent';
              id: string;
              title: string;
              description: string;
              imageURL?: string | undefined;
              provider: RLearningContentProvider;
              plannedDuration?: number | undefined;
              metadata?:
                | {
                    __typename?: 'LearningContentMetadata';
                    language?: string | undefined;
                    level?: string | undefined;
                    systemGenerated?: boolean | undefined;
                    type?: RLearningContentContentType | undefined;
                    timezone?: number | undefined;
                  }
                | undefined;
              curations: Array<{
                __typename?: 'LearningCuration';
                id: string;
                spaceId: number;
                launchURLPath?: string | undefined;
                title?: string | undefined;
                description?: string | undefined;
                imageURL?: string | undefined;
                editURLPath?: string | undefined;
              }>;
            }
          | undefined;
      }>;
    };
  };
};

export type RLearningContentFragment = {
  __typename?: 'LearningContent';
  id: string;
  idV1: string;
  title: string;
  description: string;
  imageURL?: string | undefined;
  provider: RLearningContentProvider;
  plannedDuration?: number | undefined;
  plannedLearningsCount?: number | undefined;
  metadata?:
    | {
        __typename?: 'LearningContentMetadata';
        language?: string | undefined;
        level?: string | undefined;
        systemGenerated?: boolean | undefined;
        type?: RLearningContentContentType | undefined;
        timezone?: number | undefined;
      }
    | undefined;
  locations?:
    | Array<{
        __typename?: 'LearningContentLocationData';
        id?: number | undefined;
        endDate?: Date | undefined;
        startDate?: Date | undefined;
        type?: RLocationType | undefined;
        address?: string | undefined;
      }>
    | undefined;
  curations: Array<{
    __typename?: 'LearningCuration';
    id: string;
    spaceId: number;
    launchURLPath?: string | undefined;
    title?: string | undefined;
    description?: string | undefined;
    imageURL?: string | undefined;
    editURLPath?: string | undefined;
  }>;
};

export type RParticipationFragment = {
  __typename?: 'ParticipationV2';
  id?: number | undefined;
  objectId: string;
  userId: number;
  progress: number;
  isCompleted: boolean;
  completedAt?: Date | undefined;
  plannedDuration?: number | undefined;
  plannedLearningsCount?: number | undefined;
  userEffort?: number | undefined;
  expiryAt?: Date | undefined;
};

export type RReportingLearningContentSummaryInsightsQueryVariables = Exact<{
  where?: InputMaybe<RReportingLearningContentSummaryInsights_Bool_Exp>;
  order_by?: InputMaybe<
    Array<RReportingLearningContentSummaryInsights_Order_By> | RReportingLearningContentSummaryInsights_Order_By
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  distinct_on?: InputMaybe<
    Array<RReportingLearningContentSummaryInsights_Select_Column> | RReportingLearningContentSummaryInsights_Select_Column
  >;
}>;

export type RReportingLearningContentSummaryInsightsQuery = {
  __typename?: 'Query';
  reportingLearningContentSummaryInsights: Array<{
    __typename?: 'reportingLearningContentSummaryInsights';
    urn?: string | undefined;
    startedCount?: any | undefined;
    completedCount?: any | undefined;
    averageProgress?: any | undefined;
    completionRate?: any | undefined;
    metaUpdateTime?: any | undefined;
  }>;
};

export type RReportingReactionsInsightsQueryVariables = Exact<{
  where?: InputMaybe<RReportingReactionsInsights_Bool_Exp>;
  order_by?: InputMaybe<Array<RReportingReactionsInsights_Order_By> | RReportingReactionsInsights_Order_By>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  distinct_on?: InputMaybe<Array<RReportingReactionsInsights_Select_Column> | RReportingReactionsInsights_Select_Column>;
}>;

export type RReportingReactionsInsightsQuery = {
  __typename?: 'Query';
  reportingReactionsInsights: Array<{
    __typename?: 'reportingReactionsInsights';
    urn?: string | undefined;
    answer?: any | undefined;
    answerCount?: any | undefined;
    answerRatio?: any | undefined;
    answerText?: string | undefined;
    answerTotal?: any | undefined;
    metaUpdateTime?: any | undefined;
  }>;
};

export type RReportingLearningActivitiesInsightsQueryVariables = Exact<{
  where?: InputMaybe<RReportingLearningActivitiesInsights_Bool_Exp>;
  order_by?: InputMaybe<Array<RReportingLearningActivitiesInsights_Order_By> | RReportingLearningActivitiesInsights_Order_By>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  distinct_on?: InputMaybe<
    Array<RReportingLearningActivitiesInsights_Select_Column> | RReportingLearningActivitiesInsights_Select_Column
  >;
  whereAggregate?: InputMaybe<RReportingLearningActivitiesInsights_Bool_Exp>;
}>;

export type RReportingLearningActivitiesInsightsQuery = {
  __typename?: 'Query';
  reportingLearningActivitiesInsights: Array<{
    __typename?: 'reportingLearningActivitiesInsights';
    activityURN?: string | undefined;
    completedCount?: any | undefined;
    completionRate?: any | undefined;
    type?: string | undefined;
    totalEffortInSeconds?: any | undefined;
    title?: string | undefined;
    startedCount?: any | undefined;
    totalCount?: any | undefined;
    inProgressCount?: any | undefined;
    progress?: any | undefined;
    order?: number | undefined;
    plannedDuration?: number | undefined;
    notStartedCount?: any | undefined;
    metaUpdateTime?: any | undefined;
    learningURN?: string | undefined;
  }>;
  filteredTotal: {
    __typename?: 'reportingLearningActivitiesInsights_aggregate';
    aggregate?: { __typename?: 'reportingLearningActivitiesInsights_aggregate_fields'; count: number } | undefined;
  };
  total: {
    __typename?: 'reportingLearningActivitiesInsights_aggregate';
    aggregate?: { __typename?: 'reportingLearningActivitiesInsights_aggregate_fields'; count: number } | undefined;
  };
};

export type RReportingParticipantsInsightsQueryVariables = Exact<{
  where?: InputMaybe<RReportingParticipantsInsights_Bool_Exp>;
  order_by?: InputMaybe<Array<RReportingParticipantsInsights_Order_By> | RReportingParticipantsInsights_Order_By>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  distinct_on?: InputMaybe<Array<RReportingParticipantsInsights_Select_Column> | RReportingParticipantsInsights_Select_Column>;
  whereAggregate?: InputMaybe<RReportingParticipantsInsights_Bool_Exp>;
}>;

export type RReportingParticipantsInsightsQuery = {
  __typename?: 'Query';
  reportingParticipantsInsights: Array<{
    __typename?: 'reportingParticipantsInsights';
    status?: string | undefined;
    email?: string | undefined;
    progress?: any | undefined;
    firstStatement?: any | undefined;
    profileImage?: string | undefined;
    urn?: string | undefined;
    userId?: number | undefined;
    username?: string | undefined;
  }>;
  total: {
    __typename?: 'reportingParticipantsInsights_aggregate';
    aggregate?: { __typename?: 'reportingParticipantsInsights_aggregate_fields'; count: number } | undefined;
  };
};

export type RReportingQuizInsightsQueryVariables = Exact<{
  urn: Scalars['String']['input'];
}>;

export type RReportingQuizInsightsQuery = {
  __typename?: 'Query';
  questionInsights: Array<{
    __typename?: 'reportingQuizQuestionsInsights';
    activityUrn?: string | undefined;
    learningUrn?: string | undefined;
    quizId?: number | undefined;
    questionId?: string | undefined;
    questionIdx?: string | undefined;
    questionTitle?: string | undefined;
    correctAnswers?: any | undefined;
    incorrectAnswers?: any | undefined;
    ratio?: any | undefined;
    totalAnswers?: any | undefined;
    metaUpdateTime?: any | undefined;
  }>;
  summary: Array<{
    __typename?: 'reportingQuizSummaryInsights';
    activityUrn?: string | undefined;
    learningUrn?: string | undefined;
    totalResponses?: any | undefined;
    totalQuestions?: number | undefined;
    totalParticipants?: any | undefined;
    avgRatio?: any | undefined;
    metaUpdateTime?: any | undefined;
  }>;
};

export type RReportingMyLearningsQuizQueryVariables = Exact<{
  args: RReportingMyLearningsQuiz_Arguments;
}>;

export type RReportingMyLearningsQuizQuery = {
  __typename?: 'Query';
  reportingMyLearningsQuiz: Array<{
    __typename?: 'quiz';
    urn: string;
    activity_urn: string;
    question_id: string;
    question_title: string;
    total_answers: number;
    correct_answers: number;
    incorrect_answers: number;
    all_attempt_ratio: any;
    total_unique_user_answers: number;
    latest_correct_answers: number;
    latest_incorrect_answers: number;
    latest_attempt_ratio: any;
  }>;
};

export type RLearningQuizInsightsCsvQueryVariables = Exact<{
  activityId: Scalars['Int']['input'];
}>;

export type RLearningQuizInsightsCsvQuery = { __typename?: 'Query'; learningQuizInsightsCSV: string };

export type RLearningWithActivitiesQueryVariables = Exact<{
  learningV2Id: Scalars['Int']['input'];
}>;

export type RLearningWithActivitiesQuery = {
  __typename?: 'Query';
  learningV2: {
    __typename?: 'LearningV2';
    id: number;
    title: string;
    activities?:
      | Array<{ __typename?: 'LearningActivityDto'; id: number; urn: string; data: any; learningId?: number | undefined }>
      | undefined;
  };
};

export type RLearningJourneyTemplatesQueryVariables = Exact<{ [key: string]: never }>;

export type RLearningJourneyTemplatesQuery = {
  __typename?: 'Query';
  learningJourneyTemplates: Array<{
    __typename?: 'LearningJourneyTemplateDto';
    id: number;
    bannerURL: string;
    businessGoal: string;
    description: string;
    teaser: string;
    title: string;
  }>;
};

export type RCreateLearningJourneyFromTemplateMutationVariables = Exact<{
  input: RCreateLearningJourneyFromTemplateInput;
}>;

export type RCreateLearningJourneyFromTemplateMutation = {
  __typename?: 'Mutation';
  createLearningJourneyFromTemplate: {
    __typename?: 'LearningJourneyResult';
    success: boolean;
    journey?: { __typename?: 'LearningJourney'; id: number } | undefined;
  };
};

export type RLearningJourneyQueryVariables = Exact<{
  id: Scalars['Int']['input'];
  includeItems: Scalars['Boolean']['input'];
  spaceIds?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
  userId: Scalars['Int']['input'];
  includeCohortTeams: Scalars['Boolean']['input'];
  includeUpdateByUser: Scalars['Boolean']['input'];
}>;

export type RLearningJourneyQuery = {
  __typename?: 'Query';
  learningJourney: {
    __typename?: 'LearningJourney';
    id: number;
    urn: string;
    spaceId: number;
    title: string;
    bannerURL?: string | undefined;
    businessGoal?: string | undefined;
    impactSurveysEnabled: boolean;
    description?: string | undefined;
    status: RLearningJourneyStatus;
    spaceFeatured: boolean;
    completionCriteria?: RLearningJourneyCompletionStatus | undefined;
    completionInvestTimeSec?: number | undefined;
    isLinear: boolean;
    discussionEnabled: boolean;
    discussionForumSectionPath: string;
    subjectId?: number | undefined;
    createdAt: Date;
    updatedAt: Date;
    subject?:
      | { __typename?: 'SubjectCategoryV2'; subjectId: number; subject: string; categoryId: number; category: string }
      | undefined;
    cohortTeams?: Array<{ __typename?: 'Team'; id: number; name: string }> | undefined;
    updateByUser?:
      | { __typename?: 'UserV2'; id: number; firstname?: string | undefined; lastname?: string | undefined }
      | undefined;
    items?: {
      __typename?: 'LearningJourneyItemPage';
      data: Array<{
        __typename?: 'LearningJourneyItem';
        id: number;
        order: number;
        isLocked: boolean;
        contentId: string;
        content?:
          | {
              __typename?: 'LearningContent';
              id: string;
              idV1: string;
              title: string;
              description: string;
              imageURL?: string | undefined;
              provider: RLearningContentProvider;
              plannedDuration?: number | undefined;
              plannedLearningsCount?: number | undefined;
              metadata?:
                | {
                    __typename?: 'LearningContentMetadata';
                    language?: string | undefined;
                    level?: string | undefined;
                    systemGenerated?: boolean | undefined;
                    type?: RLearningContentContentType | undefined;
                    timezone?: number | undefined;
                  }
                | undefined;
              locations?:
                | Array<{
                    __typename?: 'LearningContentLocationData';
                    id?: number | undefined;
                    endDate?: Date | undefined;
                    startDate?: Date | undefined;
                    type?: RLocationType | undefined;
                    address?: string | undefined;
                  }>
                | undefined;
              curations: Array<{
                __typename?: 'LearningCuration';
                id: string;
                spaceId: number;
                launchURLPath?: string | undefined;
                title?: string | undefined;
                description?: string | undefined;
                imageURL?: string | undefined;
                editURLPath?: string | undefined;
              }>;
            }
          | undefined;
      }>;
    };
  };
};

export type RLearningJourneysQueryVariables = Exact<{
  filter: RLearningJourneyFilter;
  pagination?: InputMaybe<RPagination>;
}>;

export type RLearningJourneysQuery = {
  __typename?: 'Query';
  learningJourneys: {
    __typename?: 'LearningJourneyPage';
    total: number;
    data: Array<{
      __typename?: 'LearningJourney';
      id: number;
      spaceId: number;
      subjectId?: number | undefined;
      title: string;
      status: RLearningJourneyStatus;
      subject?:
        | { __typename?: 'SubjectCategoryV2'; subjectId: number; subject: string; categoryId: number; category: string }
        | undefined;
      items: { __typename?: 'LearningJourneyItemPage'; total: number };
    }>;
  };
};

export type RLearningJourneySessionQueryVariables = Exact<{
  id: Scalars['Int']['input'];
  includeProgress: Scalars['Boolean']['input'];
}>;

export type RLearningJourneySessionQuery = {
  __typename?: 'Query';
  learningJourneySession: {
    __typename?: 'LearningJourneySession';
    id: number;
    startedAt: Date;
    cohort: {
      __typename?: 'LearningJourneyCohort';
      learners: Array<{
        __typename?: 'LearningJourneyLearner';
        userId: number;
        sessionId: number;
        user: {
          __typename?: 'UserV2';
          id: number;
          email: string;
          username?: string | undefined;
          profileImage?: string | undefined;
        };
        progress?: { __typename?: 'ParticipationV2'; isCompleted: boolean; progress: number } | undefined;
      }>;
    };
  };
};

export type RLearningJourneyItemsCatalogQueryVariables = Exact<{
  id: Scalars['Int']['input'];
  spaceIds: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
  searchPhrase?: InputMaybe<Scalars['String']['input']>;
  pagination: RPagination;
}>;

export type RLearningJourneyItemsCatalogQuery = {
  __typename?: 'Query';
  learningJourneyItemsCatalog: {
    __typename?: 'LearningContentsPage';
    limit: number;
    page: number;
    data: Array<{
      __typename?: 'LearningContent';
      id: string;
      idV1: string;
      title: string;
      description: string;
      imageURL?: string | undefined;
      provider: RLearningContentProvider;
      plannedDuration?: number | undefined;
      plannedLearningsCount?: number | undefined;
      metadata?:
        | {
            __typename?: 'LearningContentMetadata';
            language?: string | undefined;
            level?: string | undefined;
            systemGenerated?: boolean | undefined;
            type?: RLearningContentContentType | undefined;
            timezone?: number | undefined;
          }
        | undefined;
      locations?:
        | Array<{
            __typename?: 'LearningContentLocationData';
            id?: number | undefined;
            endDate?: Date | undefined;
            startDate?: Date | undefined;
            type?: RLocationType | undefined;
            address?: string | undefined;
          }>
        | undefined;
      curations: Array<{
        __typename?: 'LearningCuration';
        id: string;
        spaceId: number;
        launchURLPath?: string | undefined;
        title?: string | undefined;
        description?: string | undefined;
        imageURL?: string | undefined;
        editURLPath?: string | undefined;
      }>;
    }>;
  };
};

export type RReportingLearningJourneyContentInsightsQueryVariables = Exact<{
  where?: InputMaybe<RReportingLearningJourneyContentInsights_Bool_Exp>;
  orderBy?: InputMaybe<
    Array<RReportingLearningJourneyContentInsights_Order_By> | RReportingLearningJourneyContentInsights_Order_By
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  whereAggregate?: InputMaybe<RReportingLearningJourneyContentInsights_Bool_Exp>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;

export type RReportingLearningJourneyContentInsightsQuery = {
  __typename?: 'Query';
  reportingLearningJourneyContentInsights: Array<{
    __typename?: 'reportingLearningJourneyContentInsights';
    learningContentURN?: string | undefined;
    learningContentTitle?: string | undefined;
    learningType?: string | undefined;
    startedCount?: any | undefined;
    completedCount?: any | undefined;
    completionRate?: any | undefined;
    notStartedCount?: any | undefined;
  }>;
  filteredTotal: {
    __typename?: 'reportingLearningJourneyContentInsights_aggregate';
    aggregate?: { __typename?: 'reportingLearningJourneyContentInsights_aggregate_fields'; count: number } | undefined;
  };
  total: {
    __typename?: 'reportingLearningJourneyContentInsights_aggregate';
    aggregate?: { __typename?: 'reportingLearningJourneyContentInsights_aggregate_fields'; count: number } | undefined;
  };
};

export type RReportingLearningJourneysItemsQueryVariables = Exact<{
  args: RReportingLearningJourneysItems_Arguments;
  where?: InputMaybe<RLearning_Journeys_Items_Bool_Exp_Bool_Exp>;
  orderBy?: InputMaybe<Array<RLearning_Journeys_Items_Order_By> | RLearning_Journeys_Items_Order_By>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;

export type RReportingLearningJourneysItemsQuery = {
  __typename?: 'Query';
  reportingLearningJourneysItems: Array<{
    __typename?: 'learning_journeys_items';
    urn: string;
    title: string;
    type: string;
    partner: string;
  }>;
};

export type RReportingLearningJourneysItemsInsightsQueryVariables = Exact<{
  args: RReportingLearningJourneysItemsInsights_Arguments;
  where?: InputMaybe<RLearning_Journeys_Items_Insights_Bool_Exp_Bool_Exp>;
  orderBy?: InputMaybe<Array<RLearning_Journeys_Items_Insights_Order_By> | RLearning_Journeys_Items_Insights_Order_By>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;

export type RReportingLearningJourneysItemsInsightsQuery = {
  __typename?: 'Query';
  reportingLearningJourneysItemsInsights: Array<{
    __typename?: 'learning_journeys_items_insights';
    urn: string;
    not_started_count: any;
    in_progress_count: any;
    completed_count: any;
    completion_rate: any;
  }>;
};

export type RReportingLearningJourneysItemsTotalCountQueryVariables = Exact<{
  args: RReportingLearningJourneysItemsTotalCount_Arguments;
  where?: InputMaybe<RTotal_Count_Bool_Exp_Bool_Exp>;
  orderBy?: InputMaybe<Array<RTotal_Count_Order_By> | RTotal_Count_Order_By>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;

export type RReportingLearningJourneysItemsTotalCountQuery = {
  __typename?: 'Query';
  reportingLearningJourneysItemsTotalCount: Array<{ __typename?: 'total_count'; total_count: any }>;
};

export type RReportingSurveyAnswersQueryVariables = Exact<{
  where?: InputMaybe<RReportingSurveyAnswers_Bool_Exp>;
}>;

export type RReportingSurveyAnswersQuery = {
  __typename?: 'Query';
  reportingSurveyAnswers: Array<{
    __typename?: 'reportingSurveyAnswers';
    answer?: string | undefined;
    answerCount?: any | undefined;
    answerPercent?: any | undefined;
    answerTotal?: any | undefined;
    questionURN?: string | undefined;
    urn?: string | undefined;
    scoreRaw?: number | undefined;
  }>;
};

export type RReportingMyLearningsSurveyQueryVariables = Exact<{
  args: RReportingMyLearningsSurvey_Arguments;
}>;

export type RReportingMyLearningsSurveyQuery = {
  __typename?: 'Query';
  reportingMyLearningsSurvey: Array<{
    __typename?: 'survey';
    urn: string;
    title: string;
    question_urn: string;
    question_title: string;
    answer: string;
    score_raw: number;
    score_min: number;
    score_max: number;
    score_scaled: any;
    answer_count: number;
    answer_percent: any;
    answer_total: number;
  }>;
};

export type RSurveyAnswerInsightsCsvQueryVariables = Exact<{
  surveyAnswerInsightsCsvId: Scalars['Int']['input'];
}>;

export type RSurveyAnswerInsightsCsvQuery = { __typename?: 'Query'; surveyAnswerInsightsCSV: string };

export type RCreateLearningJourneyMutationVariables = Exact<{
  input: RCreateLearningJourneyInput;
}>;

export type RCreateLearningJourneyMutation = {
  __typename?: 'Mutation';
  createLearningJourney: {
    __typename?: 'LearningJourneyResult';
    success: boolean;
    journey?: { __typename?: 'LearningJourney'; id: number } | undefined;
  };
};

export type RUpsertLearningJourneyItemsMutationVariables = Exact<{
  input: RUpsertLearningJourneyItemsInput;
}>;

export type RUpsertLearningJourneyItemsMutation = {
  __typename?: 'Mutation';
  upsertLearningJourneyItems: {
    __typename?: 'LearningJourneyResult';
    success: boolean;
    journey?: { __typename?: 'LearningJourney'; id: number } | undefined;
  };
};

export type RUpdateLearningJourneyMutationVariables = Exact<{
  input: RUpdateLearningJourneyInput;
}>;

export type RUpdateLearningJourneyMutation = {
  __typename?: 'Mutation';
  updateLearningJourney: {
    __typename?: 'LearningJourneyResult';
    success: boolean;
    journey?:
      | {
          __typename?: 'LearningJourney';
          id: number;
          subjectId?: number | undefined;
          businessGoal?: string | undefined;
          title: string;
          description?: string | undefined;
          bannerURL?: string | undefined;
          status: RLearningJourneyStatus;
          completionCriteria?: RLearningJourneyCompletionStatus | undefined;
          completionInvestTimeSec?: number | undefined;
          subject?:
            | { __typename?: 'SubjectCategoryV2'; subjectId: number; subject: string; categoryId: number; category: string }
            | undefined;
        }
      | undefined;
  };
};

export type RUpsertLearningJourneyCohortsMutationVariables = Exact<{
  input: RUpsertLearningJourneyCohortsInput;
}>;

export type RUpsertLearningJourneyCohortsMutation = {
  __typename?: 'Mutation';
  upsertLearningJourneyCohorts: { __typename?: 'LearningJourneySessionResult'; success: boolean };
};

export type RJoinLearningJourneyCohortsMutationVariables = Exact<{
  userId: Scalars['Int']['input'];
  sessionId: Scalars['Int']['input'];
}>;

export type RJoinLearningJourneyCohortsMutation = {
  __typename?: 'Mutation';
  joinLearningJourneyCohort: { __typename?: 'LearningJourneySessionResult'; success: boolean };
};

export type RUnlockUserLearningJourneyItemMutationVariables = Exact<{
  userId: Scalars['Int']['input'];
  learningJourneyId: Scalars['Int']['input'];
  itemURN: Scalars['URN']['input'];
}>;

export type RUnlockUserLearningJourneyItemMutation = {
  __typename?: 'Mutation';
  unlockUserLearningJourneyItem: { __typename?: 'LearningJourneyResult'; success: boolean };
};

export type RCreateLearningJourneySurveysMutationVariables = Exact<{
  input: RCreateLearningJourneySurveysInput;
}>;

export type RCreateLearningJourneySurveysMutation = {
  __typename?: 'Mutation';
  createLearningJourneySurveys: Array<{
    __typename?: 'LearningV2';
    id: number;
    title: string;
    urn: string;
    type: RLearningV2Type;
    image?: string | undefined;
    effort?: number | undefined;
  }>;
};

export type RCreateLearningJourneyPracticalAssessmentsMutationVariables = Exact<{
  input: RCreateLearningJourneyPracticalAssessmentsInput;
}>;

export type RCreateLearningJourneyPracticalAssessmentsMutation = {
  __typename?: 'Mutation';
  createLearningJourneyPracticalAssessments: Array<{
    __typename?: 'LearningV2';
    id: number;
    title: string;
    urn: string;
    type: RLearningV2Type;
    image?: string | undefined;
    effort?: number | undefined;
  }>;
};

export type RCreateUserLearningSubmissionMutationVariables = Exact<{
  input: RCreateUserLearningSubmissionInput;
}>;

export type RCreateUserLearningSubmissionMutation = {
  __typename?: 'Mutation';
  createUserLearningSubmission: {
    __typename?: 'UserLearningSubmissionResult';
    success: boolean;
    submission?:
      | {
          __typename?: 'UserLearningSubmission';
          data: any;
          id: number;
          createdAt: Date;
          userId: number;
          type: RUserLearningSubmissionType;
          pageURNV2: string;
          learningURNV2: string;
        }
      | undefined;
  };
};

export type RUserLearningSubmissionsQueryVariables = Exact<{
  input: RUserLearningSubmissionsFilter;
}>;

export type RUserLearningSubmissionsQuery = {
  __typename?: 'Query';
  userLearningSubmissions: Array<{
    __typename?: 'UserLearningSubmission';
    createdAt: Date;
    data: any;
    id: number;
    pageURNV2: string;
    learningURNV2: string;
    type: RUserLearningSubmissionType;
    updatedAt: Date;
    userId: number;
    reviews: Array<{
      __typename?: 'UserLearningSubmissionReview';
      createdAt: Date;
      data: any;
      id: number;
      reviewerId: number;
      submissionId: number;
      type: RUserLearningSubmissionReviewType;
      updatedAt: Date;
    }>;
    reviewAssignments: Array<{
      __typename?: 'UserLearningSubmissionReviewAssignment';
      createdAt: Date;
      expiryAt: Date;
      id: number;
      reviewerId: number;
      submissionId: number;
      updatedAt: Date;
      submission: {
        __typename?: 'UserLearningSubmission';
        data: any;
        id: number;
        createdAt: Date;
        pageURNV2: string;
        type: RUserLearningSubmissionType;
        userId: number;
        updatedAt: Date;
        reviews: Array<{
          __typename?: 'UserLearningSubmissionReview';
          reviewerId: number;
          createdAt: Date;
          data: any;
          id: number;
        }>;
      };
    }>;
  }>;
};

export type RCreateUserLearningSubmissionReviewMutationVariables = Exact<{
  input: RCreateUserLearningSubmissionReviewInput;
}>;

export type RCreateUserLearningSubmissionReviewMutation = {
  __typename?: 'Mutation';
  createUserLearningSubmissionReview: { __typename?: 'UserLearningSubmissionReviewResult'; success: boolean };
};

export type RReportingPracticalAssessmentInsightsQueryVariables = Exact<{
  where?: InputMaybe<RReportingPracticalAssessmentInsights_Bool_Exp>;
}>;

export type RReportingPracticalAssessmentInsightsQuery = {
  __typename?: 'Query';
  reportingPracticalAssessmentInsights: Array<{
    __typename?: 'reportingPracticalAssessmentInsights';
    learningContentURN?: string | undefined;
    learningJourneyURN?: string | undefined;
    pageId?: number | undefined;
    pageURN?: string | undefined;
    responsesCount?: any | undefined;
    reviewsCount?: any | undefined;
  }>;
};

export type RCreateLearningJourneyLiveEventMutationVariables = Exact<{
  input: RCreateLearningJourneyLiveEventInput;
}>;

export type RCreateLearningJourneyLiveEventMutation = {
  __typename?: 'Mutation';
  createLearningJourneyLiveEvent: {
    __typename?: 'LearningV2';
    id: number;
    title: string;
    type: RLearningV2Type;
    urn: string;
    effort?: number | undefined;
    image?: string | undefined;
  };
};

export type RSearchLearningJourneyContentQueryVariables = Exact<{
  filter: RSearchLearningContentFilter;
  pagination: RPagination;
  spaceIds?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
}>;

export type RSearchLearningJourneyContentQuery = {
  __typename?: 'Query';
  searchLearningContent: {
    __typename?: 'LearningContentsPage';
    limit: number;
    page: number;
    data: Array<{
      __typename?: 'LearningContent';
      id: string;
      idV1: string;
      title: string;
      description: string;
      imageURL?: string | undefined;
      provider: RLearningContentProvider;
      plannedDuration?: number | undefined;
      plannedLearningsCount?: number | undefined;
      metadata?:
        | {
            __typename?: 'LearningContentMetadata';
            language?: string | undefined;
            level?: string | undefined;
            systemGenerated?: boolean | undefined;
            type?: RLearningContentContentType | undefined;
            timezone?: number | undefined;
          }
        | undefined;
      locations?:
        | Array<{
            __typename?: 'LearningContentLocationData';
            id?: number | undefined;
            endDate?: Date | undefined;
            startDate?: Date | undefined;
            type?: RLocationType | undefined;
            address?: string | undefined;
          }>
        | undefined;
      curations: Array<{
        __typename?: 'LearningCuration';
        id: string;
        spaceId: number;
        launchURLPath?: string | undefined;
        title?: string | undefined;
        description?: string | undefined;
        imageURL?: string | undefined;
        editURLPath?: string | undefined;
      }>;
    }>;
  };
};

export type RDeleteLearningJourneysMutationVariables = Exact<{
  journeyIds: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;

export type RDeleteLearningJourneysMutation = {
  __typename?: 'Mutation';
  deleteLearningJourneys: Array<{ __typename?: 'LearningJourney'; id: number; status: RLearningJourneyStatus }>;
};

export type RLearningJourneyContentInsightsCsvQueryVariables = Exact<{
  learningJourneyId: Scalars['Int']['input'];
}>;

export type RLearningJourneyContentInsightsCsvQuery = { __typename?: 'Query'; learningJourneyContentInsightsCSV: string };

export type RLearningJourneyParticipantInsightsCsvQueryVariables = Exact<{
  learningJourneyId: Scalars['Int']['input'];
}>;

export type RLearningJourneyParticipantInsightsCsvQuery = { __typename?: 'Query'; learningJourneyParticipantInsightsCSV: string };

export type RReportingMyLearningJourneysLearnersTotalCountQueryVariables = Exact<{
  args: RReportingMyLearningJourneysLearnersTotalCount_Arguments;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;

export type RReportingMyLearningJourneysLearnersTotalCountQuery = {
  __typename?: 'Query';
  reportingMyLearningJourneysLearnersTotalCount: Array<{ __typename?: 'total_count'; total_count: any }>;
};

export type RReportingMyLearningJourneysLearnersQueryVariables = Exact<{
  args: RReportingMyLearningJourneysLearners_Arguments;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RLearners_Order_By> | RLearners_Order_By>;
}>;

export type RReportingMyLearningJourneysLearnersQuery = {
  __typename?: 'Query';
  reportingMyLearningJourneysLearners: Array<{
    __typename?: 'learners';
    user_id: number;
    username: string;
    email: string;
    profile_image?: string | undefined;
    join_time?: any | undefined;
    progress?: any | undefined;
    is_completed: boolean;
    completion_time?: any | undefined;
    last_activity_time?: any | undefined;
  }>;
};

export type RReportingMyLearningJourneysV2QueryVariables = Exact<{
  args: RReportingMyLearningJourneysV2_Arguments;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RLearning_Journeys_V2_Order_By> | RLearning_Journeys_V2_Order_By>;
}>;

export type RReportingMyLearningJourneysV2Query = {
  __typename?: 'Query';
  reportingMyLearningJourneysV2: Array<{
    __typename?: 'learning_journeys_v2';
    urn: string;
    title: string;
    status: string;
    space_name: string;
    space_id: number;
    learning_items_count: any;
  }>;
};

export type RReportingMyLearningsLearnersQueryVariables = Exact<{
  args: RReportingMyLearningsLearners_Arguments;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RLearners_Order_By> | RLearners_Order_By>;
  where?: InputMaybe<RLearners_Bool_Exp_Bool_Exp>;
}>;

export type RReportingMyLearningsLearnersQuery = {
  __typename?: 'Query';
  reportingMyLearningsLearners: Array<{
    __typename?: 'learners';
    user_id: number;
    username: string;
    email: string;
    profile_image?: string | undefined;
    join_time?: any | undefined;
    progress?: any | undefined;
    completion_time?: any | undefined;
    is_completed: boolean;
    last_activity_time?: any | undefined;
  }>;
};

export type RReportingMyLiveLearningsLearnersQueryVariables = Exact<{
  args: RReportingMyLiveLearningsLearners_Arguments;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RLive_Learnings_Learners_Order_By> | RLive_Learnings_Learners_Order_By>;
  where?: InputMaybe<RLive_Learnings_Learners_Bool_Exp_Bool_Exp>;
}>;

export type RReportingMyLiveLearningsLearnersQuery = {
  __typename?: 'Query';
  reportingMyLiveLearningsLearners: Array<{
    __typename?: 'live_learnings_learners';
    user_id: number;
    username: string;
    email: string;
    profile_image?: string | undefined;
    session_time: any;
    attendance: string;
    completion_time?: any | undefined;
    is_completed: boolean;
  }>;
};

export type RReportingMyLearningsLearnersTotalCountQueryVariables = Exact<{
  args: RReportingMyLearningsLearnersTotalCount_Arguments;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RTotal_Count_Order_By> | RTotal_Count_Order_By>;
  where?: InputMaybe<RTotal_Count_Bool_Exp_Bool_Exp>;
}>;

export type RReportingMyLearningsLearnersTotalCountQuery = {
  __typename?: 'Query';
  reportingMyLearningsLearnersTotalCount: Array<{ __typename?: 'total_count'; space_id: number; total_count: any }>;
};

export type RReportingMyLiveLearningsLearnersTotalCountQueryVariables = Exact<{
  args: RReportingMyLiveLearningsLearnersTotalCount_Arguments;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RTotal_Count_Order_By> | RTotal_Count_Order_By>;
  where?: InputMaybe<RTotal_Count_Bool_Exp_Bool_Exp>;
}>;

export type RReportingMyLiveLearningsLearnersTotalCountQuery = {
  __typename?: 'Query';
  reportingMyLiveLearningsLearnersTotalCount: Array<{ __typename?: 'total_count'; space_id: number; total_count: any }>;
};

export type RReportingMyLearningJourneysInsightsV2QueryVariables = Exact<{
  args: RReportingMyLearningJourneysInsightsV2_Arguments;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RLearning_Journeys_Insights_V2_Order_By> | RLearning_Journeys_Insights_V2_Order_By>;
}>;

export type RReportingMyLearningJourneysInsightsV2Query = {
  __typename?: 'Query';
  reportingMyLearningJourneysInsightsV2: Array<{
    __typename?: 'learning_journeys_insights_v2';
    urn: string;
    average_progress: any;
    learners_count: any;
    completed_count: any;
    completion_rate: any;
  }>;
};

export type RReportingMyLearningJourneysTotalCountQueryVariables = Exact<{
  args: RReportingMyLearningJourneysTotalCount_Arguments;
}>;

export type RReportingMyLearningJourneysTotalCountQuery = {
  __typename?: 'Query';
  reportingMyLearningJourneysTotalCount: Array<{ __typename?: 'total_count'; total_count: any; space_id: number }>;
};

export type RLearningContentByIdFragment = {
  __typename?: 'LearningContent';
  id: string;
  idV1: string;
  title: string;
  description: string;
  imageURL?: string | undefined;
  provider: RLearningContentProvider;
  plannedDuration?: number | undefined;
  plannedLearningsCount?: number | undefined;
  metadata?:
    | {
        __typename?: 'LearningContentMetadata';
        language?: string | undefined;
        level?: string | undefined;
        systemGenerated?: boolean | undefined;
        type?: RLearningContentContentType | undefined;
        timezone?: number | undefined;
      }
    | undefined;
  curations: Array<{
    __typename?: 'LearningCuration';
    id: string;
    spaceId: number;
    launchURLPath?: string | undefined;
    title?: string | undefined;
    description?: string | undefined;
    imageURL?: string | undefined;
    editURLPath?: string | undefined;
    updatedAt?: Date | undefined;
    space?: { __typename?: 'SpaceV2'; id: number; name: string; label: string } | undefined;
    updateByUser?:
      | {
          __typename?: 'UserV2';
          id: number;
          firstname?: string | undefined;
          lastname?: string | undefined;
          username?: string | undefined;
        }
      | undefined;
  }>;
};

export type RLearningContentByIdsQueryVariables = Exact<{
  contentURNs: Array<Scalars['URN']['input']> | Scalars['URN']['input'];
  spaceIds: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;

export type RLearningContentByIdsQuery = {
  __typename?: 'Query';
  learningContentByIds: Array<{
    __typename?: 'LearningContent';
    id: string;
    idV1: string;
    title: string;
    description: string;
    imageURL?: string | undefined;
    provider: RLearningContentProvider;
    plannedDuration?: number | undefined;
    plannedLearningsCount?: number | undefined;
    metadata?:
      | {
          __typename?: 'LearningContentMetadata';
          language?: string | undefined;
          level?: string | undefined;
          systemGenerated?: boolean | undefined;
          type?: RLearningContentContentType | undefined;
          timezone?: number | undefined;
        }
      | undefined;
    curations: Array<{
      __typename?: 'LearningCuration';
      id: string;
      spaceId: number;
      launchURLPath?: string | undefined;
      title?: string | undefined;
      description?: string | undefined;
      imageURL?: string | undefined;
      editURLPath?: string | undefined;
      updatedAt?: Date | undefined;
      space?: { __typename?: 'SpaceV2'; id: number; name: string; label: string } | undefined;
      updateByUser?:
        | {
            __typename?: 'UserV2';
            id: number;
            firstname?: string | undefined;
            lastname?: string | undefined;
            username?: string | undefined;
          }
        | undefined;
    }>;
  }>;
};

export type RLearningContentByIdV1QueryVariables = Exact<{
  urn: Scalars['String']['input'];
  spaceIds: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;

export type RLearningContentByIdV1Query = {
  __typename?: 'Query';
  learningContentByIdV1: {
    __typename?: 'LearningContent';
    id: string;
    idV1: string;
    title: string;
    description: string;
    imageURL?: string | undefined;
    provider: RLearningContentProvider;
    plannedDuration?: number | undefined;
    plannedLearningsCount?: number | undefined;
    metadata?:
      | {
          __typename?: 'LearningContentMetadata';
          language?: string | undefined;
          level?: string | undefined;
          systemGenerated?: boolean | undefined;
          type?: RLearningContentContentType | undefined;
          timezone?: number | undefined;
        }
      | undefined;
    curations: Array<{
      __typename?: 'LearningCuration';
      id: string;
      spaceId: number;
      launchURLPath?: string | undefined;
      title?: string | undefined;
      description?: string | undefined;
      imageURL?: string | undefined;
      editURLPath?: string | undefined;
      updatedAt?: Date | undefined;
      space?: { __typename?: 'SpaceV2'; id: number; name: string; label: string } | undefined;
      updateByUser?:
        | {
            __typename?: 'UserV2';
            id: number;
            firstname?: string | undefined;
            lastname?: string | undefined;
            username?: string | undefined;
          }
        | undefined;
    }>;
  };
};

export type RLearningContentLearnerInsightsCsvQueryVariables = Exact<{
  urn: Scalars['String']['input'];
}>;

export type RLearningContentLearnerInsightsCsvQuery = { __typename?: 'Query'; learningContentLearnerInsightsCSV: string };

export type RLearningContentByIdsV1QueryVariables = Exact<{
  contentURNs: Array<Scalars['String']['input']> | Scalars['String']['input'];
  spaceIds: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;

export type RLearningContentByIdsV1Query = {
  __typename?: 'Query';
  learningContentByIdsV1: Array<{
    __typename?: 'LearningContent';
    id: string;
    idV1: string;
    title: string;
    description: string;
    imageURL?: string | undefined;
    provider: RLearningContentProvider;
    plannedDuration?: number | undefined;
    plannedLearningsCount?: number | undefined;
    metadata?:
      | {
          __typename?: 'LearningContentMetadata';
          language?: string | undefined;
          level?: string | undefined;
          systemGenerated?: boolean | undefined;
          type?: RLearningContentContentType | undefined;
          timezone?: number | undefined;
        }
      | undefined;
    curations: Array<{
      __typename?: 'LearningCuration';
      id: string;
      spaceId: number;
      launchURLPath?: string | undefined;
      title?: string | undefined;
      description?: string | undefined;
      imageURL?: string | undefined;
      editURLPath?: string | undefined;
      updatedAt?: Date | undefined;
      space?: { __typename?: 'SpaceV2'; id: number; name: string; label: string } | undefined;
      updateByUser?:
        | {
            __typename?: 'UserV2';
            id: number;
            firstname?: string | undefined;
            lastname?: string | undefined;
            username?: string | undefined;
          }
        | undefined;
    }>;
  }>;
};

export type RLearningFragmentFragment = {
  __typename?: 'Learning';
  id: number;
  title?: string | undefined;
  spaceId?: number | undefined;
  languageId?: number | undefined;
  teaser?: string | undefined;
  effort?: number | undefined;
  image?: string | undefined;
  visibility?: RLearningVisibility | undefined;
  meta?: any | undefined;
  status?: RLearningStatus | undefined;
  type?: RLearningType | undefined;
  urnV2?: string | undefined;
};

export type RLearningV2FragmentFragment = {
  __typename?: 'LearningV2';
  id: number;
  contentOwnerId?: number | undefined;
  title: string;
  spaceId: number;
  languageId?: number | undefined;
  teaser: string;
  effort?: number | undefined;
  image?: string | undefined;
  visibility?: RLearningV2Visibility | undefined;
  meta?: any | undefined;
  status: RLearningV2Status;
  type: RLearningV2Type;
  urn: string;
  updatedAt?: Date | undefined;
  subjects?: Array<{ __typename?: 'SubjectCategory'; subjectId: number }> | undefined;
};

export type RActivityFragmentFragment = {
  __typename?: 'LearningActivityDto';
  createdAt: Date;
  data: any;
  id: number;
  learningId?: number | undefined;
  order: number;
  parentActivityId?: number | undefined;
  type: RLearningActivityType;
  updatedAt: Date;
};

export type RLearningModulesDeepFragmentFragment = {
  __typename?: 'Module';
  id?: number | undefined;
  learningId?: number | undefined;
  pages?:
    | Array<
        | {
            __typename?: 'Page';
            id?: number | undefined;
            name?: string | undefined;
            order?: number | undefined;
            moduleId?: number | undefined;
            blocks?:
              | Array<
                  | {
                      __typename?: 'Block';
                      id?: number | undefined;
                      pageId?: number | undefined;
                      type?: string | undefined;
                      data?: any | undefined;
                    }
                  | undefined
                >
              | undefined;
          }
        | undefined
      >
    | undefined;
};

export type RLearningProgressFragmentFragment = {
  __typename?: 'LearningProgress';
  completed?: boolean | undefined;
  completedPercentage?: number | undefined;
  expiredAt?: string | undefined;
  historical?: boolean | undefined;
};

export type RLocationsFragmentFragment = {
  __typename?: 'Location';
  id: number;
  type?: RLocationType | undefined;
  room?: string | undefined;
  address?: string | undefined;
  endDate?: Date | undefined;
  startDate?: Date | undefined;
  myEnrollment?: { __typename?: 'Enrollment'; id: number; attended: RAttendedType } | undefined;
};

export type RLearningByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  includeProgress: Scalars['Boolean']['input'];
  includeLocations: Scalars['Boolean']['input'];
}>;

export type RLearningByIdQuery = {
  __typename?: 'Query';
  learning?:
    | {
        __typename?: 'Learning';
        id: number;
        title?: string | undefined;
        spaceId?: number | undefined;
        languageId?: number | undefined;
        teaser?: string | undefined;
        effort?: number | undefined;
        image?: string | undefined;
        visibility?: RLearningVisibility | undefined;
        meta?: any | undefined;
        status?: RLearningStatus | undefined;
        type?: RLearningType | undefined;
        urnV2?: string | undefined;
        modules?:
          | Array<
              | {
                  __typename?: 'Module';
                  id?: number | undefined;
                  learningId?: number | undefined;
                  pages?:
                    | Array<
                        | {
                            __typename?: 'Page';
                            id?: number | undefined;
                            name?: string | undefined;
                            order?: number | undefined;
                            moduleId?: number | undefined;
                            blocks?:
                              | Array<
                                  | {
                                      __typename?: 'Block';
                                      id?: number | undefined;
                                      pageId?: number | undefined;
                                      type?: string | undefined;
                                      data?: any | undefined;
                                    }
                                  | undefined
                                >
                              | undefined;
                          }
                        | undefined
                      >
                    | undefined;
                }
              | undefined
            >
          | undefined;
        progress?:
          | {
              __typename?: 'LearningProgress';
              completed?: boolean | undefined;
              completedPercentage?: number | undefined;
              expiredAt?: string | undefined;
              historical?: boolean | undefined;
            }
          | undefined;
        locations?:
          | Array<
              | {
                  __typename?: 'Location';
                  id: number;
                  type?: RLocationType | undefined;
                  room?: string | undefined;
                  address?: string | undefined;
                  endDate?: Date | undefined;
                  startDate?: Date | undefined;
                  myEnrollment?: { __typename?: 'Enrollment'; id: number; attended: RAttendedType } | undefined;
                }
              | undefined
            >
          | undefined;
      }
    | undefined;
};

export type RLearningV2ByIdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
}>;

export type RLearningV2ByIdQuery = {
  __typename?: 'Query';
  learningV2: {
    __typename?: 'LearningV2';
    id: number;
    contentOwnerId?: number | undefined;
    title: string;
    spaceId: number;
    languageId?: number | undefined;
    teaser: string;
    effort?: number | undefined;
    image?: string | undefined;
    visibility?: RLearningV2Visibility | undefined;
    meta?: any | undefined;
    status: RLearningV2Status;
    type: RLearningV2Type;
    urn: string;
    updatedAt?: Date | undefined;
    activities?:
      | Array<{
          __typename?: 'LearningActivityDto';
          createdAt: Date;
          data: any;
          id: number;
          learningId?: number | undefined;
          order: number;
          parentActivityId?: number | undefined;
          type: RLearningActivityType;
          updatedAt: Date;
        }>
      | undefined;
    userLastParticipation?:
      | { __typename?: 'ParticipationV2'; id?: number | undefined; expiryAt?: Date | undefined; isCompleted: boolean }
      | undefined;
    updateByUser?:
      | { __typename?: 'UserV2'; id: number; firstname?: string | undefined; lastname?: string | undefined }
      | undefined;
    subjects?: Array<{ __typename?: 'SubjectCategory'; subjectId: number }> | undefined;
  };
};

export type RSaveLearningMutationVariables = Exact<{
  input: RLearningInput;
}>;

export type RSaveLearningMutation = {
  __typename?: 'Mutation';
  saveLearning?: { __typename?: 'Learning'; id: number; title?: string | undefined } | undefined;
};

export type RUpsertLearningMutationVariables = Exact<{
  input: RUpsertLearningInput;
}>;

export type RUpsertLearningMutation = {
  __typename?: 'Mutation';
  upsertLearning: { __typename?: 'LearningV2'; id: number; title: string };
};

export type RDeleteLearningMutationVariables = Exact<{
  learningId: Scalars['Int']['input'];
}>;

export type RDeleteLearningMutation = {
  __typename?: 'Mutation';
  deleteLearning?: { __typename?: 'Learning'; id: number } | undefined;
};

export type RLearningTypeByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type RLearningTypeByIdQuery = {
  __typename?: 'Query';
  learning?: { __typename?: 'Learning'; id: number; type?: RLearningType | undefined } | undefined;
};

export type RLearningParentsByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  filter: RLearningParentsFilter;
  userId: Scalars['Int']['input'];
}>;

export type RLearningParentsByIdQuery = {
  __typename?: 'Query';
  learning?:
    | {
        __typename?: 'Learning';
        id: number;
        urnV2?: string | undefined;
        parents: Array<{
          __typename?: 'LearningContent';
          id: string;
          title: string;
          userLastParticipation?: { __typename?: 'ParticipationV2'; id?: number | undefined } | undefined;
        }>;
      }
    | undefined;
};

export type RSearchLearningContentQueryVariables = Exact<{
  filter: RSearchLearningContentFilter;
  pagination: RPagination;
  spaceIds: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;

export type RSearchLearningContentQuery = {
  __typename?: 'Query';
  searchLearningContent: {
    __typename?: 'LearningContentsPage';
    limit: number;
    page: number;
    total: number;
    data: Array<{
      __typename?: 'LearningContent';
      id: string;
      idV1: string;
      title: string;
      description: string;
      imageURL?: string | undefined;
      provider: RLearningContentProvider;
      plannedDuration?: number | undefined;
      plannedLearningsCount?: number | undefined;
      metadata?:
        | {
            __typename?: 'LearningContentMetadata';
            language?: string | undefined;
            level?: string | undefined;
            systemGenerated?: boolean | undefined;
            type?: RLearningContentContentType | undefined;
            timezone?: number | undefined;
          }
        | undefined;
      locations?:
        | Array<{
            __typename?: 'LearningContentLocationData';
            id?: number | undefined;
            endDate?: Date | undefined;
            startDate?: Date | undefined;
            type?: RLocationType | undefined;
            address?: string | undefined;
          }>
        | undefined;
      curations: Array<{
        __typename?: 'LearningCuration';
        id: string;
        spaceId: number;
        launchURLPath?: string | undefined;
        title?: string | undefined;
        description?: string | undefined;
        imageURL?: string | undefined;
        editURLPath?: string | undefined;
      }>;
    }>;
  };
};

export type RImportLearningContentToSpaceMutationVariables = Exact<{
  input: RImportLearningContentToSpaceInput;
}>;

export type RImportLearningContentToSpaceMutation = {
  __typename?: 'Mutation';
  importLearningContentToSpace: {
    __typename?: 'ImportLearningContentToSpaceResult';
    success: boolean;
    failedIds?: Array<string> | undefined;
    content?: Array<{ __typename?: 'LearningContent'; id: string; title: string }> | undefined;
  };
};

export type RSubmitLearningActivityQuizAnswersMutationVariables = Exact<{
  input: RUserLearningActivityQuizSubmissionInput;
}>;

export type RSubmitLearningActivityQuizAnswersMutation = {
  __typename?: 'Mutation';
  submitLearningActivityQuizAnswers: { __typename?: 'LearningActivityDto'; id: number };
};

export type RMyLearningActivityQuizSubmissionsQueryVariables = Exact<{
  input: RUserLearningActivityQuizSubmissionFilterInput;
}>;

export type RMyLearningActivityQuizSubmissionsQuery = {
  __typename?: 'Query';
  myLearningActivityQuizSubmissions: Array<{
    __typename?: 'UserLearningActivityQuizSubmission';
    answers: any;
    id: number;
    score: number;
  }>;
};

export type RMigrateOnDemandLearningToCoursesMutationVariables = Exact<{
  learningId: Scalars['Int']['input'];
}>;

export type RMigrateOnDemandLearningToCoursesMutation = {
  __typename?: 'Mutation';
  migrateOnDemandLearningToCourses: { __typename?: 'LearningV2'; id: number; title: string };
};

export type RCloneLearningMutationVariables = Exact<{
  input: RCloneLearningInput;
}>;

export type RCloneLearningMutation = {
  __typename?: 'Mutation';
  cloneLearning: { __typename?: 'LearningV2'; id: number; type: RLearningV2Type; title: string };
};

export type RReportingLearningsQueryVariables = Exact<{
  args: RReportingLearnings_Arguments;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RLearnings_Order_By> | RLearnings_Order_By>;
}>;

export type RReportingLearningsQuery = {
  __typename?: 'Query';
  reportingLearnings: Array<{
    __typename?: 'learnings';
    partner?: string | undefined;
    space_ids?: any | undefined;
    space_names?: any | undefined;
    team_ids?: any | undefined;
    title?: string | undefined;
    type?: string | undefined;
    urn?: string | undefined;
    visibility?: string | undefined;
    category?: string | undefined;
    subject?: string | undefined;
    planned_duration: number;
  }>;
};

export type RReportingLearningsTotalCountQueryVariables = Exact<{
  args: RReportingLearningsTotalCount_Arguments;
}>;

export type RReportingLearningsTotalCountQuery = {
  __typename?: 'Query';
  reportingLearningsTotalCount: Array<{ __typename?: 'total_count'; total_count: any }>;
};

export type RReportingLearningsInsightsQueryVariables = Exact<{
  args: RReportingLearningsInsights_Arguments;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RLearningsInsights_Order_By> | RLearningsInsights_Order_By>;
}>;

export type RReportingLearningsInsightsQuery = {
  __typename?: 'Query';
  reportingLearningsInsights: Array<{
    __typename?: 'learningsInsights';
    completion_rate?: any | undefined;
    learners_count?: any | undefined;
    space_ids?: any | undefined;
    team_ids?: any | undefined;
    urn?: string | undefined;
  }>;
};

export type RGetLearningXapiSessionQueryVariables = Exact<{
  learningId: Scalars['Int']['input'];
}>;

export type RGetLearningXapiSessionQuery = {
  __typename?: 'Query';
  getXAPISession: { __typename?: 'XAPISession'; queryString?: string | undefined; registration?: string | undefined };
};

export type RRegisterToLearningMutationVariables = Exact<{
  learningId: Scalars['Int']['input'];
}>;

export type RRegisterToLearningMutation = {
  __typename?: 'Mutation';
  registerToLearning: { __typename?: 'XAPISession'; queryString?: string | undefined; registration?: string | undefined };
};

export type RProductParentsByIdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
  filter: RProductParentsFilter;
  userId: Scalars['Int']['input'];
}>;

export type RProductParentsByIdQuery = {
  __typename?: 'Query';
  getProduct?:
    | {
        __typename?: 'Product';
        id?: number | undefined;
        learningContentUrnV2?: any | undefined;
        parents: Array<{
          __typename?: 'LearningContent';
          id: string;
          title: string;
          userLastParticipation?: { __typename?: 'ParticipationV2'; id?: number | undefined } | undefined;
        }>;
      }
    | undefined;
};

export type REffortForRealmQueryVariables = Exact<{
  realm: Scalars['String']['input'];
  fromTime: Scalars['timestamptz']['input'];
  toTime: Scalars['timestamptz']['input'];
}>;

export type REffortForRealmQuery = {
  __typename?: 'Query';
  reportingAggregationEffortForRealm: Array<{
    __typename?: 'reportingAggregationEffortForRealm';
    totalEffort?: any | undefined;
    averageEffort?: any | undefined;
    p90Effort?: any | undefined;
    p80Effort?: any | undefined;
    p70Effort?: any | undefined;
    p60Effort?: any | undefined;
    p50Effort?: any | undefined;
    fromTime?: any | undefined;
    toTime?: any | undefined;
  }>;
};

export type RSpaceMembersQueryVariables = Exact<{
  filter: RSpaceMemberFilter;
  pagination: RPagination;
}>;

export type RSpaceMembersQuery = {
  __typename?: 'Query';
  spaceMembers: {
    __typename?: 'SpaceMemberPage';
    data: Array<{
      __typename?: 'SpaceMember';
      id: number;
      email: string;
      realm: string;
      spaceId: number;
      firstname?: string | undefined;
      lastname?: string | undefined;
      username?: string | undefined;
      profileImage?: string | undefined;
    }>;
  };
};

export type RSpacesQueryVariables = Exact<{
  withPermissions: Scalars['Boolean']['input'];
  withContentOrigins: Scalars['Boolean']['input'];
}>;

export type RSpacesQuery = {
  __typename?: 'Query';
  spaces: {
    __typename?: 'SpaceV2Page';
    data: Array<{
      __typename?: 'SpaceV2';
      id: number;
      realm?: string | undefined;
      label: string;
      name: string;
      permissions?: { __typename?: 'SpacePermissions'; hasWriteAccessToLearningContent?: boolean | undefined };
      contentOrigins?: {
        __typename?: 'ProviderContentOriginPage';
        data: Array<{
          __typename?: 'ProviderContentOrigin';
          id: string;
          provider: { __typename?: 'Provider'; id: string; type: RLearningContentProvider };
        }>;
      };
    }>;
  };
};

export type RUpdateUsersMutationVariables = Exact<{
  input: RUserChangeInput;
}>;

export type RUpdateUsersMutation = { __typename?: 'Mutation'; updateUser: { __typename?: 'UserV2'; id: number } };

export const LearningContentFragmentDoc = gql`
  fragment LearningContent on LearningContent {
    id
    idV1
    title
    description
    imageURL
    provider
    plannedDuration
    plannedLearningsCount
    metadata {
      language
      level
      systemGenerated
      type
      timezone
    }
    locations {
      id
      endDate
      startDate
      type
      address
    }
    curations(filter: { spacedIdsIN: $spaceIds }) {
      id
      spaceId
      launchURLPath
      title
      description
      imageURL
      editURLPath
    }
  }
`;
export const ParticipationFragmentDoc = gql`
  fragment Participation on ParticipationV2 {
    id
    objectId
    userId
    progress
    isCompleted
    completedAt
    plannedDuration
    plannedLearningsCount
    userEffort
    expiryAt
  }
`;
export const LearningContentByIdFragmentDoc = gql`
  fragment LearningContentById on LearningContent {
    id
    idV1
    title
    description
    imageURL
    provider
    plannedDuration
    plannedLearningsCount
    metadata {
      language
      level
      systemGenerated
      type
      timezone
    }
    curations(filter: { spacedIdsIN: $spaceIds }) {
      id
      spaceId
      space {
        id
        name
        label
      }
      launchURLPath
      title
      description
      imageURL
      editURLPath
      updatedAt
      updateByUser {
        id
        firstname
        lastname
        username
      }
    }
  }
`;
export const LearningFragmentFragmentDoc = gql`
  fragment LearningFragment on Learning {
    id
    title
    spaceId
    languageId
    teaser
    effort
    image
    visibility
    meta
    status
    type
    urnV2
  }
`;
export const LearningV2FragmentFragmentDoc = gql`
  fragment LearningV2Fragment on LearningV2 {
    id
    contentOwnerId
    title
    spaceId
    languageId
    teaser
    effort
    image
    visibility
    meta
    subjects {
      subjectId
    }
    status
    type
    urn
    updatedAt
  }
`;
export const ActivityFragmentFragmentDoc = gql`
  fragment ActivityFragment on LearningActivityDto {
    createdAt
    data
    id
    learningId
    order
    parentActivityId
    type
    updatedAt
  }
`;
export const LearningModulesDeepFragmentFragmentDoc = gql`
  fragment LearningModulesDeepFragment on Module {
    id
    learningId
    pages {
      id
      name
      order
      moduleId
      blocks {
        id
        pageId
        type
        data
      }
    }
  }
`;
export const LearningProgressFragmentFragmentDoc = gql`
  fragment LearningProgressFragment on LearningProgress {
    completed
    completedPercentage
    expiredAt
    historical
  }
`;
export const LocationsFragmentFragmentDoc = gql`
  fragment LocationsFragment on Location {
    id
    type
    room
    address
    endDate
    startDate
    myEnrollment {
      id
      attended
    }
  }
`;
export const AssignmentV3UsersDocument = gql`
  query assignmentV3Users(
    $filter: AssignmentV3UsersFilter!
    $pagination: Pagination
    $sort: AssignmentUsersV3Sort
    $includeProgress: Boolean!
  ) {
    assignmentV3Users(filter: $filter, pagination: $pagination, sort: $sort) {
      data {
        userId
        assignmentId
        user {
          id
          email
          profileImage
          username
          status
        }
        ... on AssignmentStrategicV3User {
          progress @include(if: $includeProgress) {
            completed
            estimatedEffort
          }
        }
        ... on AssignmentStandardV3User {
          progress @include(if: $includeProgress) {
            __typename
            participations {
              assignmentLearningId
              participationId
              progress
              completedAt
            }
          }
        }
      }
      total
    }
  }
`;

/**
 * __useAssignmentV3UsersQueryRemote__
 *
 * To run a query within a React component, call `useAssignmentV3UsersQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useAssignmentV3UsersQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssignmentV3UsersQueryRemote({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *      includeProgress: // value for 'includeProgress'
 *   },
 * });
 */
export function useAssignmentV3UsersQueryRemote(
  baseOptions: Apollo.QueryHookOptions<RAssignmentV3UsersQuery, RAssignmentV3UsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RAssignmentV3UsersQuery, RAssignmentV3UsersQueryVariables>(AssignmentV3UsersDocument, options);
}
export function useAssignmentV3UsersLazyQueryRemote(
  baseOptions?: Apollo.LazyQueryHookOptions<RAssignmentV3UsersQuery, RAssignmentV3UsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RAssignmentV3UsersQuery, RAssignmentV3UsersQueryVariables>(AssignmentV3UsersDocument, options);
}
export type AssignmentV3UsersQueryRemoteHookResult = ReturnType<typeof useAssignmentV3UsersQueryRemote>;
export type AssignmentV3UsersLazyQueryRemoteHookResult = ReturnType<typeof useAssignmentV3UsersLazyQueryRemote>;
export type AssignmentV3UsersQueryResult = Apollo.QueryResult<RAssignmentV3UsersQuery, RAssignmentV3UsersQueryVariables>;
export const UserLearningContentLastParticipationDocument = gql`
  query userLearningContentLastParticipation($userId: Int!, $learningContentId: URN!) {
    userLearningContentLastParticipation(userId: $userId, learningContentId: $learningContentId) {
      ...Participation
    }
  }
  ${ParticipationFragmentDoc}
`;

/**
 * __useUserLearningContentLastParticipationQueryRemote__
 *
 * To run a query within a React component, call `useUserLearningContentLastParticipationQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useUserLearningContentLastParticipationQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserLearningContentLastParticipationQueryRemote({
 *   variables: {
 *      userId: // value for 'userId'
 *      learningContentId: // value for 'learningContentId'
 *   },
 * });
 */
export function useUserLearningContentLastParticipationQueryRemote(
  baseOptions: Apollo.QueryHookOptions<
    RUserLearningContentLastParticipationQuery,
    RUserLearningContentLastParticipationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RUserLearningContentLastParticipationQuery, RUserLearningContentLastParticipationQueryVariables>(
    UserLearningContentLastParticipationDocument,
    options
  );
}
export function useUserLearningContentLastParticipationLazyQueryRemote(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RUserLearningContentLastParticipationQuery,
    RUserLearningContentLastParticipationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RUserLearningContentLastParticipationQuery, RUserLearningContentLastParticipationQueryVariables>(
    UserLearningContentLastParticipationDocument,
    options
  );
}
export type UserLearningContentLastParticipationQueryRemoteHookResult = ReturnType<
  typeof useUserLearningContentLastParticipationQueryRemote
>;
export type UserLearningContentLastParticipationLazyQueryRemoteHookResult = ReturnType<
  typeof useUserLearningContentLastParticipationLazyQueryRemote
>;
export type UserLearningContentLastParticipationQueryResult = Apollo.QueryResult<
  RUserLearningContentLastParticipationQuery,
  RUserLearningContentLastParticipationQueryVariables
>;
export const UserLearningContentLastParticipationsDocument = gql`
  query userLearningContentLastParticipations($userId: Int!, $learningContentIdIn: [URN!]!) {
    userLearningContentLastParticipations(userId: $userId, learningContentIdIN: $learningContentIdIn) {
      ...Participation
    }
  }
  ${ParticipationFragmentDoc}
`;

/**
 * __useUserLearningContentLastParticipationsQueryRemote__
 *
 * To run a query within a React component, call `useUserLearningContentLastParticipationsQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useUserLearningContentLastParticipationsQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserLearningContentLastParticipationsQueryRemote({
 *   variables: {
 *      userId: // value for 'userId'
 *      learningContentIdIn: // value for 'learningContentIdIn'
 *   },
 * });
 */
export function useUserLearningContentLastParticipationsQueryRemote(
  baseOptions: Apollo.QueryHookOptions<
    RUserLearningContentLastParticipationsQuery,
    RUserLearningContentLastParticipationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RUserLearningContentLastParticipationsQuery, RUserLearningContentLastParticipationsQueryVariables>(
    UserLearningContentLastParticipationsDocument,
    options
  );
}
export function useUserLearningContentLastParticipationsLazyQueryRemote(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RUserLearningContentLastParticipationsQuery,
    RUserLearningContentLastParticipationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RUserLearningContentLastParticipationsQuery, RUserLearningContentLastParticipationsQueryVariables>(
    UserLearningContentLastParticipationsDocument,
    options
  );
}
export type UserLearningContentLastParticipationsQueryRemoteHookResult = ReturnType<
  typeof useUserLearningContentLastParticipationsQueryRemote
>;
export type UserLearningContentLastParticipationsLazyQueryRemoteHookResult = ReturnType<
  typeof useUserLearningContentLastParticipationsLazyQueryRemote
>;
export type UserLearningContentLastParticipationsQueryResult = Apollo.QueryResult<
  RUserLearningContentLastParticipationsQuery,
  RUserLearningContentLastParticipationsQueryVariables
>;
export const LearningCollectionsV2Document = gql`
  query learningCollectionsV2($filter: LearningCollectionFilter!, $pagination: Pagination, $sort: LearningCollectionSort) {
    learningCollectionsV2(filter: $filter, pagination: $pagination, sort: $sort) {
      data {
        id
        title
        visibility
        status
        space {
          id
          name
        }
        subject {
          subjectId
          subject
          categoryId
          category
        }
        items {
          total
        }
      }
      limit
      page
      total
    }
  }
`;

/**
 * __useLearningCollectionsV2QueryRemote__
 *
 * To run a query within a React component, call `useLearningCollectionsV2QueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useLearningCollectionsV2QueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearningCollectionsV2QueryRemote({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useLearningCollectionsV2QueryRemote(
  baseOptions: Apollo.QueryHookOptions<RLearningCollectionsV2Query, RLearningCollectionsV2QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RLearningCollectionsV2Query, RLearningCollectionsV2QueryVariables>(
    LearningCollectionsV2Document,
    options
  );
}
export function useLearningCollectionsV2LazyQueryRemote(
  baseOptions?: Apollo.LazyQueryHookOptions<RLearningCollectionsV2Query, RLearningCollectionsV2QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RLearningCollectionsV2Query, RLearningCollectionsV2QueryVariables>(
    LearningCollectionsV2Document,
    options
  );
}
export type LearningCollectionsV2QueryRemoteHookResult = ReturnType<typeof useLearningCollectionsV2QueryRemote>;
export type LearningCollectionsV2LazyQueryRemoteHookResult = ReturnType<typeof useLearningCollectionsV2LazyQueryRemote>;
export type LearningCollectionsV2QueryResult = Apollo.QueryResult<
  RLearningCollectionsV2Query,
  RLearningCollectionsV2QueryVariables
>;
export const LearningCollectionV2Document = gql`
  query learningCollectionV2($id: Int!, $spaceIds: [Int!]!) {
    learningCollectionV2(id: $id) {
      id
      urn
      title
      imageURL
      visibility
      status
      updatedAt
      updatedByUser {
        id
        firstname
        lastname
      }
      space {
        id
        name
      }
      subject {
        subjectId
        subject
        categoryId
        category
      }
      items {
        data {
          collectionId
          content {
            id
            title
            description
            imageURL
            provider
            plannedDuration
            metadata {
              language
              level
              systemGenerated
              type
              timezone
            }
            curations(filter: { spacedIdsIN: $spaceIds }) {
              id
              spaceId
              launchURLPath
              title
              description
              imageURL
              editURLPath
            }
          }
          contentURNV1
          curationURNV1
          id
          order
        }
        total
      }
    }
  }
`;

/**
 * __useLearningCollectionV2QueryRemote__
 *
 * To run a query within a React component, call `useLearningCollectionV2QueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useLearningCollectionV2QueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearningCollectionV2QueryRemote({
 *   variables: {
 *      id: // value for 'id'
 *      spaceIds: // value for 'spaceIds'
 *   },
 * });
 */
export function useLearningCollectionV2QueryRemote(
  baseOptions: Apollo.QueryHookOptions<RLearningCollectionV2Query, RLearningCollectionV2QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RLearningCollectionV2Query, RLearningCollectionV2QueryVariables>(LearningCollectionV2Document, options);
}
export function useLearningCollectionV2LazyQueryRemote(
  baseOptions?: Apollo.LazyQueryHookOptions<RLearningCollectionV2Query, RLearningCollectionV2QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RLearningCollectionV2Query, RLearningCollectionV2QueryVariables>(
    LearningCollectionV2Document,
    options
  );
}
export type LearningCollectionV2QueryRemoteHookResult = ReturnType<typeof useLearningCollectionV2QueryRemote>;
export type LearningCollectionV2LazyQueryRemoteHookResult = ReturnType<typeof useLearningCollectionV2LazyQueryRemote>;
export type LearningCollectionV2QueryResult = Apollo.QueryResult<RLearningCollectionV2Query, RLearningCollectionV2QueryVariables>;
export const ReportingLearningContentSummaryInsightsDocument = gql`
  query reportingLearningContentSummaryInsights(
    $where: reportingLearningContentSummaryInsights_bool_exp
    $order_by: [reportingLearningContentSummaryInsights_order_by!]
    $limit: Int
    $offset: Int
    $distinct_on: [reportingLearningContentSummaryInsights_select_column!]
  ) {
    reportingLearningContentSummaryInsights(
      where: $where
      order_by: $order_by
      limit: $limit
      offset: $offset
      distinct_on: $distinct_on
    ) {
      urn
      startedCount
      completedCount
      averageProgress
      completionRate
      metaUpdateTime
    }
  }
`;

/**
 * __useReportingLearningContentSummaryInsightsQueryRemote__
 *
 * To run a query within a React component, call `useReportingLearningContentSummaryInsightsQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useReportingLearningContentSummaryInsightsQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportingLearningContentSummaryInsightsQueryRemote({
 *   variables: {
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      distinct_on: // value for 'distinct_on'
 *   },
 * });
 */
export function useReportingLearningContentSummaryInsightsQueryRemote(
  baseOptions?: Apollo.QueryHookOptions<
    RReportingLearningContentSummaryInsightsQuery,
    RReportingLearningContentSummaryInsightsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RReportingLearningContentSummaryInsightsQuery, RReportingLearningContentSummaryInsightsQueryVariables>(
    ReportingLearningContentSummaryInsightsDocument,
    options
  );
}
export function useReportingLearningContentSummaryInsightsLazyQueryRemote(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RReportingLearningContentSummaryInsightsQuery,
    RReportingLearningContentSummaryInsightsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RReportingLearningContentSummaryInsightsQuery,
    RReportingLearningContentSummaryInsightsQueryVariables
  >(ReportingLearningContentSummaryInsightsDocument, options);
}
export type ReportingLearningContentSummaryInsightsQueryRemoteHookResult = ReturnType<
  typeof useReportingLearningContentSummaryInsightsQueryRemote
>;
export type ReportingLearningContentSummaryInsightsLazyQueryRemoteHookResult = ReturnType<
  typeof useReportingLearningContentSummaryInsightsLazyQueryRemote
>;
export type ReportingLearningContentSummaryInsightsQueryResult = Apollo.QueryResult<
  RReportingLearningContentSummaryInsightsQuery,
  RReportingLearningContentSummaryInsightsQueryVariables
>;
export const ReportingReactionsInsightsDocument = gql`
  query reportingReactionsInsights(
    $where: reportingReactionsInsights_bool_exp
    $order_by: [reportingReactionsInsights_order_by!]
    $limit: Int
    $offset: Int
    $distinct_on: [reportingReactionsInsights_select_column!]
  ) {
    reportingReactionsInsights(where: $where, order_by: $order_by, limit: $limit, offset: $offset, distinct_on: $distinct_on) {
      urn
      answer
      answerCount
      answerRatio
      answerText
      answerTotal
      metaUpdateTime
    }
  }
`;

/**
 * __useReportingReactionsInsightsQueryRemote__
 *
 * To run a query within a React component, call `useReportingReactionsInsightsQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useReportingReactionsInsightsQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportingReactionsInsightsQueryRemote({
 *   variables: {
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      distinct_on: // value for 'distinct_on'
 *   },
 * });
 */
export function useReportingReactionsInsightsQueryRemote(
  baseOptions?: Apollo.QueryHookOptions<RReportingReactionsInsightsQuery, RReportingReactionsInsightsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RReportingReactionsInsightsQuery, RReportingReactionsInsightsQueryVariables>(
    ReportingReactionsInsightsDocument,
    options
  );
}
export function useReportingReactionsInsightsLazyQueryRemote(
  baseOptions?: Apollo.LazyQueryHookOptions<RReportingReactionsInsightsQuery, RReportingReactionsInsightsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RReportingReactionsInsightsQuery, RReportingReactionsInsightsQueryVariables>(
    ReportingReactionsInsightsDocument,
    options
  );
}
export type ReportingReactionsInsightsQueryRemoteHookResult = ReturnType<typeof useReportingReactionsInsightsQueryRemote>;
export type ReportingReactionsInsightsLazyQueryRemoteHookResult = ReturnType<typeof useReportingReactionsInsightsLazyQueryRemote>;
export type ReportingReactionsInsightsQueryResult = Apollo.QueryResult<
  RReportingReactionsInsightsQuery,
  RReportingReactionsInsightsQueryVariables
>;
export const ReportingLearningActivitiesInsightsDocument = gql`
  query reportingLearningActivitiesInsights(
    $where: reportingLearningActivitiesInsights_bool_exp
    $order_by: [reportingLearningActivitiesInsights_order_by!]
    $limit: Int
    $offset: Int
    $distinct_on: [reportingLearningActivitiesInsights_select_column!]
    $whereAggregate: reportingLearningActivitiesInsights_bool_exp
  ) {
    reportingLearningActivitiesInsights(
      where: $where
      order_by: $order_by
      limit: $limit
      offset: $offset
      distinct_on: $distinct_on
    ) {
      activityURN
      completedCount
      completionRate
      type
      totalEffortInSeconds
      title
      startedCount
      totalCount
      inProgressCount
      progress
      order
      plannedDuration
      notStartedCount
      metaUpdateTime
      learningURN
    }
    filteredTotal: reportingLearningActivitiesInsightsAggregate(where: $where) {
      aggregate {
        count
      }
    }
    total: reportingLearningActivitiesInsightsAggregate(where: $whereAggregate) {
      aggregate {
        count
      }
    }
  }
`;

/**
 * __useReportingLearningActivitiesInsightsQueryRemote__
 *
 * To run a query within a React component, call `useReportingLearningActivitiesInsightsQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useReportingLearningActivitiesInsightsQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportingLearningActivitiesInsightsQueryRemote({
 *   variables: {
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      distinct_on: // value for 'distinct_on'
 *      whereAggregate: // value for 'whereAggregate'
 *   },
 * });
 */
export function useReportingLearningActivitiesInsightsQueryRemote(
  baseOptions?: Apollo.QueryHookOptions<
    RReportingLearningActivitiesInsightsQuery,
    RReportingLearningActivitiesInsightsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RReportingLearningActivitiesInsightsQuery, RReportingLearningActivitiesInsightsQueryVariables>(
    ReportingLearningActivitiesInsightsDocument,
    options
  );
}
export function useReportingLearningActivitiesInsightsLazyQueryRemote(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RReportingLearningActivitiesInsightsQuery,
    RReportingLearningActivitiesInsightsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RReportingLearningActivitiesInsightsQuery, RReportingLearningActivitiesInsightsQueryVariables>(
    ReportingLearningActivitiesInsightsDocument,
    options
  );
}
export type ReportingLearningActivitiesInsightsQueryRemoteHookResult = ReturnType<
  typeof useReportingLearningActivitiesInsightsQueryRemote
>;
export type ReportingLearningActivitiesInsightsLazyQueryRemoteHookResult = ReturnType<
  typeof useReportingLearningActivitiesInsightsLazyQueryRemote
>;
export type ReportingLearningActivitiesInsightsQueryResult = Apollo.QueryResult<
  RReportingLearningActivitiesInsightsQuery,
  RReportingLearningActivitiesInsightsQueryVariables
>;
export const ReportingParticipantsInsightsDocument = gql`
  query reportingParticipantsInsights(
    $where: reportingParticipantsInsights_bool_exp
    $order_by: [reportingParticipantsInsights_order_by!]
    $limit: Int
    $offset: Int
    $distinct_on: [reportingParticipantsInsights_select_column!]
    $whereAggregate: reportingParticipantsInsights_bool_exp
  ) {
    reportingParticipantsInsights(where: $where, order_by: $order_by, limit: $limit, offset: $offset, distinct_on: $distinct_on) {
      status
      email
      progress
      firstStatement
      profileImage
      urn
      userId
      username
    }
    total: reportingParticipantsInsightsAggregate(where: $whereAggregate) {
      aggregate {
        count
      }
    }
  }
`;

/**
 * __useReportingParticipantsInsightsQueryRemote__
 *
 * To run a query within a React component, call `useReportingParticipantsInsightsQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useReportingParticipantsInsightsQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportingParticipantsInsightsQueryRemote({
 *   variables: {
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      distinct_on: // value for 'distinct_on'
 *      whereAggregate: // value for 'whereAggregate'
 *   },
 * });
 */
export function useReportingParticipantsInsightsQueryRemote(
  baseOptions?: Apollo.QueryHookOptions<RReportingParticipantsInsightsQuery, RReportingParticipantsInsightsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RReportingParticipantsInsightsQuery, RReportingParticipantsInsightsQueryVariables>(
    ReportingParticipantsInsightsDocument,
    options
  );
}
export function useReportingParticipantsInsightsLazyQueryRemote(
  baseOptions?: Apollo.LazyQueryHookOptions<RReportingParticipantsInsightsQuery, RReportingParticipantsInsightsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RReportingParticipantsInsightsQuery, RReportingParticipantsInsightsQueryVariables>(
    ReportingParticipantsInsightsDocument,
    options
  );
}
export type ReportingParticipantsInsightsQueryRemoteHookResult = ReturnType<typeof useReportingParticipantsInsightsQueryRemote>;
export type ReportingParticipantsInsightsLazyQueryRemoteHookResult = ReturnType<
  typeof useReportingParticipantsInsightsLazyQueryRemote
>;
export type ReportingParticipantsInsightsQueryResult = Apollo.QueryResult<
  RReportingParticipantsInsightsQuery,
  RReportingParticipantsInsightsQueryVariables
>;
export const ReportingQuizInsightsDocument = gql`
  query reportingQuizInsights($urn: String!) {
    questionInsights: reportingQuizQuestionsInsights(where: { activityUrn: { _eq: $urn } }) {
      activityUrn
      learningUrn
      quizId
      questionId
      questionIdx
      questionTitle
      correctAnswers
      incorrectAnswers
      ratio
      totalAnswers
      metaUpdateTime
    }
    summary: reportingQuizSummaryInsights(where: { activityUrn: { _eq: $urn } }) {
      activityUrn
      learningUrn
      totalResponses
      totalQuestions
      totalParticipants
      avgRatio
      metaUpdateTime
    }
  }
`;

/**
 * __useReportingQuizInsightsQueryRemote__
 *
 * To run a query within a React component, call `useReportingQuizInsightsQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useReportingQuizInsightsQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportingQuizInsightsQueryRemote({
 *   variables: {
 *      urn: // value for 'urn'
 *   },
 * });
 */
export function useReportingQuizInsightsQueryRemote(
  baseOptions: Apollo.QueryHookOptions<RReportingQuizInsightsQuery, RReportingQuizInsightsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RReportingQuizInsightsQuery, RReportingQuizInsightsQueryVariables>(
    ReportingQuizInsightsDocument,
    options
  );
}
export function useReportingQuizInsightsLazyQueryRemote(
  baseOptions?: Apollo.LazyQueryHookOptions<RReportingQuizInsightsQuery, RReportingQuizInsightsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RReportingQuizInsightsQuery, RReportingQuizInsightsQueryVariables>(
    ReportingQuizInsightsDocument,
    options
  );
}
export type ReportingQuizInsightsQueryRemoteHookResult = ReturnType<typeof useReportingQuizInsightsQueryRemote>;
export type ReportingQuizInsightsLazyQueryRemoteHookResult = ReturnType<typeof useReportingQuizInsightsLazyQueryRemote>;
export type ReportingQuizInsightsQueryResult = Apollo.QueryResult<
  RReportingQuizInsightsQuery,
  RReportingQuizInsightsQueryVariables
>;
export const ReportingMyLearningsQuizDocument = gql`
  query reportingMyLearningsQuiz($args: reportingMyLearningsQuiz_arguments!) {
    reportingMyLearningsQuiz(args: $args) {
      urn
      activity_urn
      question_id
      question_title
      total_answers
      correct_answers
      incorrect_answers
      all_attempt_ratio
      total_unique_user_answers
      latest_correct_answers
      latest_incorrect_answers
      latest_attempt_ratio
    }
  }
`;

/**
 * __useReportingMyLearningsQuizQueryRemote__
 *
 * To run a query within a React component, call `useReportingMyLearningsQuizQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useReportingMyLearningsQuizQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportingMyLearningsQuizQueryRemote({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useReportingMyLearningsQuizQueryRemote(
  baseOptions: Apollo.QueryHookOptions<RReportingMyLearningsQuizQuery, RReportingMyLearningsQuizQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RReportingMyLearningsQuizQuery, RReportingMyLearningsQuizQueryVariables>(
    ReportingMyLearningsQuizDocument,
    options
  );
}
export function useReportingMyLearningsQuizLazyQueryRemote(
  baseOptions?: Apollo.LazyQueryHookOptions<RReportingMyLearningsQuizQuery, RReportingMyLearningsQuizQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RReportingMyLearningsQuizQuery, RReportingMyLearningsQuizQueryVariables>(
    ReportingMyLearningsQuizDocument,
    options
  );
}
export type ReportingMyLearningsQuizQueryRemoteHookResult = ReturnType<typeof useReportingMyLearningsQuizQueryRemote>;
export type ReportingMyLearningsQuizLazyQueryRemoteHookResult = ReturnType<typeof useReportingMyLearningsQuizLazyQueryRemote>;
export type ReportingMyLearningsQuizQueryResult = Apollo.QueryResult<
  RReportingMyLearningsQuizQuery,
  RReportingMyLearningsQuizQueryVariables
>;
export const LearningQuizInsightsCsvDocument = gql`
  query learningQuizInsightsCSV($activityId: Int!) {
    learningQuizInsightsCSV(activityId: $activityId)
  }
`;

/**
 * __useLearningQuizInsightsCsvQueryRemote__
 *
 * To run a query within a React component, call `useLearningQuizInsightsCsvQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useLearningQuizInsightsCsvQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearningQuizInsightsCsvQueryRemote({
 *   variables: {
 *      activityId: // value for 'activityId'
 *   },
 * });
 */
export function useLearningQuizInsightsCsvQueryRemote(
  baseOptions: Apollo.QueryHookOptions<RLearningQuizInsightsCsvQuery, RLearningQuizInsightsCsvQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RLearningQuizInsightsCsvQuery, RLearningQuizInsightsCsvQueryVariables>(
    LearningQuizInsightsCsvDocument,
    options
  );
}
export function useLearningQuizInsightsCsvLazyQueryRemote(
  baseOptions?: Apollo.LazyQueryHookOptions<RLearningQuizInsightsCsvQuery, RLearningQuizInsightsCsvQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RLearningQuizInsightsCsvQuery, RLearningQuizInsightsCsvQueryVariables>(
    LearningQuizInsightsCsvDocument,
    options
  );
}
export type LearningQuizInsightsCsvQueryRemoteHookResult = ReturnType<typeof useLearningQuizInsightsCsvQueryRemote>;
export type LearningQuizInsightsCsvLazyQueryRemoteHookResult = ReturnType<typeof useLearningQuizInsightsCsvLazyQueryRemote>;
export type LearningQuizInsightsCsvQueryResult = Apollo.QueryResult<
  RLearningQuizInsightsCsvQuery,
  RLearningQuizInsightsCsvQueryVariables
>;
export const LearningWithActivitiesDocument = gql`
  query learningWithActivities($learningV2Id: Int!) {
    learningV2(id: $learningV2Id) {
      id
      title
      activities {
        id
        urn
        data
        learningId
      }
    }
  }
`;

/**
 * __useLearningWithActivitiesQueryRemote__
 *
 * To run a query within a React component, call `useLearningWithActivitiesQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useLearningWithActivitiesQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearningWithActivitiesQueryRemote({
 *   variables: {
 *      learningV2Id: // value for 'learningV2Id'
 *   },
 * });
 */
export function useLearningWithActivitiesQueryRemote(
  baseOptions: Apollo.QueryHookOptions<RLearningWithActivitiesQuery, RLearningWithActivitiesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RLearningWithActivitiesQuery, RLearningWithActivitiesQueryVariables>(
    LearningWithActivitiesDocument,
    options
  );
}
export function useLearningWithActivitiesLazyQueryRemote(
  baseOptions?: Apollo.LazyQueryHookOptions<RLearningWithActivitiesQuery, RLearningWithActivitiesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RLearningWithActivitiesQuery, RLearningWithActivitiesQueryVariables>(
    LearningWithActivitiesDocument,
    options
  );
}
export type LearningWithActivitiesQueryRemoteHookResult = ReturnType<typeof useLearningWithActivitiesQueryRemote>;
export type LearningWithActivitiesLazyQueryRemoteHookResult = ReturnType<typeof useLearningWithActivitiesLazyQueryRemote>;
export type LearningWithActivitiesQueryResult = Apollo.QueryResult<
  RLearningWithActivitiesQuery,
  RLearningWithActivitiesQueryVariables
>;
export const LearningJourneyTemplatesDocument = gql`
  query learningJourneyTemplates {
    learningJourneyTemplates {
      id
      bannerURL
      businessGoal
      description
      teaser
      title
    }
  }
`;

/**
 * __useLearningJourneyTemplatesQueryRemote__
 *
 * To run a query within a React component, call `useLearningJourneyTemplatesQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useLearningJourneyTemplatesQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearningJourneyTemplatesQueryRemote({
 *   variables: {
 *   },
 * });
 */
export function useLearningJourneyTemplatesQueryRemote(
  baseOptions?: Apollo.QueryHookOptions<RLearningJourneyTemplatesQuery, RLearningJourneyTemplatesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RLearningJourneyTemplatesQuery, RLearningJourneyTemplatesQueryVariables>(
    LearningJourneyTemplatesDocument,
    options
  );
}
export function useLearningJourneyTemplatesLazyQueryRemote(
  baseOptions?: Apollo.LazyQueryHookOptions<RLearningJourneyTemplatesQuery, RLearningJourneyTemplatesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RLearningJourneyTemplatesQuery, RLearningJourneyTemplatesQueryVariables>(
    LearningJourneyTemplatesDocument,
    options
  );
}
export type LearningJourneyTemplatesQueryRemoteHookResult = ReturnType<typeof useLearningJourneyTemplatesQueryRemote>;
export type LearningJourneyTemplatesLazyQueryRemoteHookResult = ReturnType<typeof useLearningJourneyTemplatesLazyQueryRemote>;
export type LearningJourneyTemplatesQueryResult = Apollo.QueryResult<
  RLearningJourneyTemplatesQuery,
  RLearningJourneyTemplatesQueryVariables
>;
export const CreateLearningJourneyFromTemplateDocument = gql`
  mutation createLearningJourneyFromTemplate($input: CreateLearningJourneyFromTemplateInput!) {
    createLearningJourneyFromTemplate(input: $input) {
      success
      journey {
        id
      }
    }
  }
`;
export type RCreateLearningJourneyFromTemplateMutationFn = Apollo.MutationFunction<
  RCreateLearningJourneyFromTemplateMutation,
  RCreateLearningJourneyFromTemplateMutationVariables
>;

/**
 * __useCreateLearningJourneyFromTemplateMutationRemote__
 *
 * To run a mutation, you first call `useCreateLearningJourneyFromTemplateMutationRemote` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLearningJourneyFromTemplateMutationRemote` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLearningJourneyFromTemplateMutationRemote, { data, loading, error }] = useCreateLearningJourneyFromTemplateMutationRemote({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLearningJourneyFromTemplateMutationRemote(
  baseOptions?: Apollo.MutationHookOptions<
    RCreateLearningJourneyFromTemplateMutation,
    RCreateLearningJourneyFromTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RCreateLearningJourneyFromTemplateMutation, RCreateLearningJourneyFromTemplateMutationVariables>(
    CreateLearningJourneyFromTemplateDocument,
    options
  );
}
export type CreateLearningJourneyFromTemplateMutationRemoteHookResult = ReturnType<
  typeof useCreateLearningJourneyFromTemplateMutationRemote
>;
export type CreateLearningJourneyFromTemplateMutationResult = Apollo.MutationResult<RCreateLearningJourneyFromTemplateMutation>;
export type CreateLearningJourneyFromTemplateMutationOptions = Apollo.BaseMutationOptions<
  RCreateLearningJourneyFromTemplateMutation,
  RCreateLearningJourneyFromTemplateMutationVariables
>;
export const LearningJourneyDocument = gql`
  query learningJourney(
    $id: Int!
    $includeItems: Boolean!
    $spaceIds: [Int!]
    $userId: Int!
    $includeCohortTeams: Boolean!
    $includeUpdateByUser: Boolean!
  ) {
    learningJourney(id: $id) {
      id
      urn
      spaceId
      title
      bannerURL
      businessGoal
      impactSurveysEnabled
      description
      status
      spaceFeatured
      completionCriteria
      completionInvestTimeSec
      isLinear
      discussionEnabled
      discussionForumSectionPath
      subjectId
      subject {
        subjectId
        subject
        categoryId
        category
      }
      createdAt
      updatedAt
      cohortTeams @include(if: $includeCohortTeams) {
        id
        name
      }
      updateByUser @include(if: $includeUpdateByUser) {
        id
        firstname
        lastname
      }
      items(pagination: { limit: -1 }) @include(if: $includeItems) {
        data {
          id
          contentId: learningItemURNV2
          content: learningItem {
            ...LearningContent
          }
          order
          isLocked(userId: $userId)
        }
      }
    }
  }
  ${LearningContentFragmentDoc}
`;

/**
 * __useLearningJourneyQueryRemote__
 *
 * To run a query within a React component, call `useLearningJourneyQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useLearningJourneyQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearningJourneyQueryRemote({
 *   variables: {
 *      id: // value for 'id'
 *      includeItems: // value for 'includeItems'
 *      spaceIds: // value for 'spaceIds'
 *      userId: // value for 'userId'
 *      includeCohortTeams: // value for 'includeCohortTeams'
 *      includeUpdateByUser: // value for 'includeUpdateByUser'
 *   },
 * });
 */
export function useLearningJourneyQueryRemote(
  baseOptions: Apollo.QueryHookOptions<RLearningJourneyQuery, RLearningJourneyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RLearningJourneyQuery, RLearningJourneyQueryVariables>(LearningJourneyDocument, options);
}
export function useLearningJourneyLazyQueryRemote(
  baseOptions?: Apollo.LazyQueryHookOptions<RLearningJourneyQuery, RLearningJourneyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RLearningJourneyQuery, RLearningJourneyQueryVariables>(LearningJourneyDocument, options);
}
export type LearningJourneyQueryRemoteHookResult = ReturnType<typeof useLearningJourneyQueryRemote>;
export type LearningJourneyLazyQueryRemoteHookResult = ReturnType<typeof useLearningJourneyLazyQueryRemote>;
export type LearningJourneyQueryResult = Apollo.QueryResult<RLearningJourneyQuery, RLearningJourneyQueryVariables>;
export const LearningJourneysDocument = gql`
  query learningJourneys($filter: LearningJourneyFilter!, $pagination: Pagination) {
    learningJourneys(filter: $filter, pagination: $pagination) {
      total
      data {
        id
        spaceId
        subjectId
        subject {
          subjectId
          subject
          categoryId
          category
        }
        title
        status
        items(pagination: { limit: -1 }) {
          total
        }
      }
    }
  }
`;

/**
 * __useLearningJourneysQueryRemote__
 *
 * To run a query within a React component, call `useLearningJourneysQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useLearningJourneysQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearningJourneysQueryRemote({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useLearningJourneysQueryRemote(
  baseOptions: Apollo.QueryHookOptions<RLearningJourneysQuery, RLearningJourneysQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RLearningJourneysQuery, RLearningJourneysQueryVariables>(LearningJourneysDocument, options);
}
export function useLearningJourneysLazyQueryRemote(
  baseOptions?: Apollo.LazyQueryHookOptions<RLearningJourneysQuery, RLearningJourneysQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RLearningJourneysQuery, RLearningJourneysQueryVariables>(LearningJourneysDocument, options);
}
export type LearningJourneysQueryRemoteHookResult = ReturnType<typeof useLearningJourneysQueryRemote>;
export type LearningJourneysLazyQueryRemoteHookResult = ReturnType<typeof useLearningJourneysLazyQueryRemote>;
export type LearningJourneysQueryResult = Apollo.QueryResult<RLearningJourneysQuery, RLearningJourneysQueryVariables>;
export const LearningJourneySessionDocument = gql`
  query learningJourneySession($id: Int!, $includeProgress: Boolean!) {
    learningJourneySession(id: $id) {
      id
      startedAt
      cohort {
        learners {
          userId
          sessionId
          user {
            id
            email
            username
            profileImage
          }
          progress @include(if: $includeProgress) {
            isCompleted
            progress
          }
        }
      }
    }
  }
`;

/**
 * __useLearningJourneySessionQueryRemote__
 *
 * To run a query within a React component, call `useLearningJourneySessionQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useLearningJourneySessionQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearningJourneySessionQueryRemote({
 *   variables: {
 *      id: // value for 'id'
 *      includeProgress: // value for 'includeProgress'
 *   },
 * });
 */
export function useLearningJourneySessionQueryRemote(
  baseOptions: Apollo.QueryHookOptions<RLearningJourneySessionQuery, RLearningJourneySessionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RLearningJourneySessionQuery, RLearningJourneySessionQueryVariables>(
    LearningJourneySessionDocument,
    options
  );
}
export function useLearningJourneySessionLazyQueryRemote(
  baseOptions?: Apollo.LazyQueryHookOptions<RLearningJourneySessionQuery, RLearningJourneySessionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RLearningJourneySessionQuery, RLearningJourneySessionQueryVariables>(
    LearningJourneySessionDocument,
    options
  );
}
export type LearningJourneySessionQueryRemoteHookResult = ReturnType<typeof useLearningJourneySessionQueryRemote>;
export type LearningJourneySessionLazyQueryRemoteHookResult = ReturnType<typeof useLearningJourneySessionLazyQueryRemote>;
export type LearningJourneySessionQueryResult = Apollo.QueryResult<
  RLearningJourneySessionQuery,
  RLearningJourneySessionQueryVariables
>;
export const LearningJourneyItemsCatalogDocument = gql`
  query learningJourneyItemsCatalog($id: Int!, $spaceIds: [Int!]!, $searchPhrase: String, $pagination: Pagination!) {
    learningJourneyItemsCatalog(filter: { id: $id, searchPhrase: $searchPhrase }, pagination: $pagination) {
      data {
        ...LearningContent
      }
      limit
      page
    }
  }
  ${LearningContentFragmentDoc}
`;

/**
 * __useLearningJourneyItemsCatalogQueryRemote__
 *
 * To run a query within a React component, call `useLearningJourneyItemsCatalogQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useLearningJourneyItemsCatalogQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearningJourneyItemsCatalogQueryRemote({
 *   variables: {
 *      id: // value for 'id'
 *      spaceIds: // value for 'spaceIds'
 *      searchPhrase: // value for 'searchPhrase'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useLearningJourneyItemsCatalogQueryRemote(
  baseOptions: Apollo.QueryHookOptions<RLearningJourneyItemsCatalogQuery, RLearningJourneyItemsCatalogQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RLearningJourneyItemsCatalogQuery, RLearningJourneyItemsCatalogQueryVariables>(
    LearningJourneyItemsCatalogDocument,
    options
  );
}
export function useLearningJourneyItemsCatalogLazyQueryRemote(
  baseOptions?: Apollo.LazyQueryHookOptions<RLearningJourneyItemsCatalogQuery, RLearningJourneyItemsCatalogQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RLearningJourneyItemsCatalogQuery, RLearningJourneyItemsCatalogQueryVariables>(
    LearningJourneyItemsCatalogDocument,
    options
  );
}
export type LearningJourneyItemsCatalogQueryRemoteHookResult = ReturnType<typeof useLearningJourneyItemsCatalogQueryRemote>;
export type LearningJourneyItemsCatalogLazyQueryRemoteHookResult = ReturnType<
  typeof useLearningJourneyItemsCatalogLazyQueryRemote
>;
export type LearningJourneyItemsCatalogQueryResult = Apollo.QueryResult<
  RLearningJourneyItemsCatalogQuery,
  RLearningJourneyItemsCatalogQueryVariables
>;
export const ReportingLearningJourneyContentInsightsDocument = gql`
  query reportingLearningJourneyContentInsights(
    $where: reportingLearningJourneyContentInsights_bool_exp
    $orderBy: [reportingLearningJourneyContentInsights_order_by!]
    $limit: Int
    $whereAggregate: reportingLearningJourneyContentInsights_bool_exp
    $offset: Int
  ) {
    reportingLearningJourneyContentInsights(where: $where, order_by: $orderBy, limit: $limit, offset: $offset) {
      learningContentURN
      learningContentTitle
      learningType
      startedCount
      completedCount
      completionRate
      notStartedCount
    }
    filteredTotal: reportingLearningJourneyContentInsightsAggregate(where: $where) {
      aggregate {
        count
      }
    }
    total: reportingLearningJourneyContentInsightsAggregate(where: $whereAggregate) {
      aggregate {
        count
      }
    }
  }
`;

/**
 * __useReportingLearningJourneyContentInsightsQueryRemote__
 *
 * To run a query within a React component, call `useReportingLearningJourneyContentInsightsQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useReportingLearningJourneyContentInsightsQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportingLearningJourneyContentInsightsQueryRemote({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *      whereAggregate: // value for 'whereAggregate'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useReportingLearningJourneyContentInsightsQueryRemote(
  baseOptions?: Apollo.QueryHookOptions<
    RReportingLearningJourneyContentInsightsQuery,
    RReportingLearningJourneyContentInsightsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RReportingLearningJourneyContentInsightsQuery, RReportingLearningJourneyContentInsightsQueryVariables>(
    ReportingLearningJourneyContentInsightsDocument,
    options
  );
}
export function useReportingLearningJourneyContentInsightsLazyQueryRemote(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RReportingLearningJourneyContentInsightsQuery,
    RReportingLearningJourneyContentInsightsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RReportingLearningJourneyContentInsightsQuery,
    RReportingLearningJourneyContentInsightsQueryVariables
  >(ReportingLearningJourneyContentInsightsDocument, options);
}
export type ReportingLearningJourneyContentInsightsQueryRemoteHookResult = ReturnType<
  typeof useReportingLearningJourneyContentInsightsQueryRemote
>;
export type ReportingLearningJourneyContentInsightsLazyQueryRemoteHookResult = ReturnType<
  typeof useReportingLearningJourneyContentInsightsLazyQueryRemote
>;
export type ReportingLearningJourneyContentInsightsQueryResult = Apollo.QueryResult<
  RReportingLearningJourneyContentInsightsQuery,
  RReportingLearningJourneyContentInsightsQueryVariables
>;
export const ReportingLearningJourneysItemsDocument = gql`
  query reportingLearningJourneysItems(
    $args: reportingLearningJourneysItems_arguments!
    $where: learning_journeys_items_bool_exp_bool_exp
    $orderBy: [learning_journeys_items_order_by!]
    $limit: Int
    $offset: Int
  ) {
    reportingLearningJourneysItems(args: $args, order_by: $orderBy, where: $where, limit: $limit, offset: $offset) {
      urn
      title
      type
      partner
    }
  }
`;

/**
 * __useReportingLearningJourneysItemsQueryRemote__
 *
 * To run a query within a React component, call `useReportingLearningJourneysItemsQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useReportingLearningJourneysItemsQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportingLearningJourneysItemsQueryRemote({
 *   variables: {
 *      args: // value for 'args'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useReportingLearningJourneysItemsQueryRemote(
  baseOptions: Apollo.QueryHookOptions<RReportingLearningJourneysItemsQuery, RReportingLearningJourneysItemsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RReportingLearningJourneysItemsQuery, RReportingLearningJourneysItemsQueryVariables>(
    ReportingLearningJourneysItemsDocument,
    options
  );
}
export function useReportingLearningJourneysItemsLazyQueryRemote(
  baseOptions?: Apollo.LazyQueryHookOptions<RReportingLearningJourneysItemsQuery, RReportingLearningJourneysItemsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RReportingLearningJourneysItemsQuery, RReportingLearningJourneysItemsQueryVariables>(
    ReportingLearningJourneysItemsDocument,
    options
  );
}
export type ReportingLearningJourneysItemsQueryRemoteHookResult = ReturnType<typeof useReportingLearningJourneysItemsQueryRemote>;
export type ReportingLearningJourneysItemsLazyQueryRemoteHookResult = ReturnType<
  typeof useReportingLearningJourneysItemsLazyQueryRemote
>;
export type ReportingLearningJourneysItemsQueryResult = Apollo.QueryResult<
  RReportingLearningJourneysItemsQuery,
  RReportingLearningJourneysItemsQueryVariables
>;
export const ReportingLearningJourneysItemsInsightsDocument = gql`
  query reportingLearningJourneysItemsInsights(
    $args: reportingLearningJourneysItemsInsights_arguments!
    $where: learning_journeys_items_insights_bool_exp_bool_exp
    $orderBy: [learning_journeys_items_insights_order_by!]
    $limit: Int
    $offset: Int
  ) {
    reportingLearningJourneysItemsInsights(args: $args, order_by: $orderBy, where: $where, limit: $limit, offset: $offset) {
      urn
      not_started_count
      in_progress_count
      completed_count
      completion_rate
    }
  }
`;

/**
 * __useReportingLearningJourneysItemsInsightsQueryRemote__
 *
 * To run a query within a React component, call `useReportingLearningJourneysItemsInsightsQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useReportingLearningJourneysItemsInsightsQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportingLearningJourneysItemsInsightsQueryRemote({
 *   variables: {
 *      args: // value for 'args'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useReportingLearningJourneysItemsInsightsQueryRemote(
  baseOptions: Apollo.QueryHookOptions<
    RReportingLearningJourneysItemsInsightsQuery,
    RReportingLearningJourneysItemsInsightsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RReportingLearningJourneysItemsInsightsQuery, RReportingLearningJourneysItemsInsightsQueryVariables>(
    ReportingLearningJourneysItemsInsightsDocument,
    options
  );
}
export function useReportingLearningJourneysItemsInsightsLazyQueryRemote(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RReportingLearningJourneysItemsInsightsQuery,
    RReportingLearningJourneysItemsInsightsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RReportingLearningJourneysItemsInsightsQuery, RReportingLearningJourneysItemsInsightsQueryVariables>(
    ReportingLearningJourneysItemsInsightsDocument,
    options
  );
}
export type ReportingLearningJourneysItemsInsightsQueryRemoteHookResult = ReturnType<
  typeof useReportingLearningJourneysItemsInsightsQueryRemote
>;
export type ReportingLearningJourneysItemsInsightsLazyQueryRemoteHookResult = ReturnType<
  typeof useReportingLearningJourneysItemsInsightsLazyQueryRemote
>;
export type ReportingLearningJourneysItemsInsightsQueryResult = Apollo.QueryResult<
  RReportingLearningJourneysItemsInsightsQuery,
  RReportingLearningJourneysItemsInsightsQueryVariables
>;
export const ReportingLearningJourneysItemsTotalCountDocument = gql`
  query reportingLearningJourneysItemsTotalCount(
    $args: reportingLearningJourneysItemsTotalCount_arguments!
    $where: total_count_bool_exp_bool_exp
    $orderBy: [total_count_order_by!]
    $limit: Int
    $offset: Int
  ) {
    reportingLearningJourneysItemsTotalCount(args: $args, order_by: $orderBy, where: $where, limit: $limit, offset: $offset) {
      total_count
    }
  }
`;

/**
 * __useReportingLearningJourneysItemsTotalCountQueryRemote__
 *
 * To run a query within a React component, call `useReportingLearningJourneysItemsTotalCountQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useReportingLearningJourneysItemsTotalCountQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportingLearningJourneysItemsTotalCountQueryRemote({
 *   variables: {
 *      args: // value for 'args'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useReportingLearningJourneysItemsTotalCountQueryRemote(
  baseOptions: Apollo.QueryHookOptions<
    RReportingLearningJourneysItemsTotalCountQuery,
    RReportingLearningJourneysItemsTotalCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RReportingLearningJourneysItemsTotalCountQuery, RReportingLearningJourneysItemsTotalCountQueryVariables>(
    ReportingLearningJourneysItemsTotalCountDocument,
    options
  );
}
export function useReportingLearningJourneysItemsTotalCountLazyQueryRemote(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RReportingLearningJourneysItemsTotalCountQuery,
    RReportingLearningJourneysItemsTotalCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RReportingLearningJourneysItemsTotalCountQuery,
    RReportingLearningJourneysItemsTotalCountQueryVariables
  >(ReportingLearningJourneysItemsTotalCountDocument, options);
}
export type ReportingLearningJourneysItemsTotalCountQueryRemoteHookResult = ReturnType<
  typeof useReportingLearningJourneysItemsTotalCountQueryRemote
>;
export type ReportingLearningJourneysItemsTotalCountLazyQueryRemoteHookResult = ReturnType<
  typeof useReportingLearningJourneysItemsTotalCountLazyQueryRemote
>;
export type ReportingLearningJourneysItemsTotalCountQueryResult = Apollo.QueryResult<
  RReportingLearningJourneysItemsTotalCountQuery,
  RReportingLearningJourneysItemsTotalCountQueryVariables
>;
export const ReportingSurveyAnswersDocument = gql`
  query reportingSurveyAnswers($where: reportingSurveyAnswers_bool_exp) {
    reportingSurveyAnswers(where: $where) {
      answer
      answerCount
      answerPercent
      answerTotal
      questionURN
      urn
      scoreRaw
    }
  }
`;

/**
 * __useReportingSurveyAnswersQueryRemote__
 *
 * To run a query within a React component, call `useReportingSurveyAnswersQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useReportingSurveyAnswersQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportingSurveyAnswersQueryRemote({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useReportingSurveyAnswersQueryRemote(
  baseOptions?: Apollo.QueryHookOptions<RReportingSurveyAnswersQuery, RReportingSurveyAnswersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RReportingSurveyAnswersQuery, RReportingSurveyAnswersQueryVariables>(
    ReportingSurveyAnswersDocument,
    options
  );
}
export function useReportingSurveyAnswersLazyQueryRemote(
  baseOptions?: Apollo.LazyQueryHookOptions<RReportingSurveyAnswersQuery, RReportingSurveyAnswersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RReportingSurveyAnswersQuery, RReportingSurveyAnswersQueryVariables>(
    ReportingSurveyAnswersDocument,
    options
  );
}
export type ReportingSurveyAnswersQueryRemoteHookResult = ReturnType<typeof useReportingSurveyAnswersQueryRemote>;
export type ReportingSurveyAnswersLazyQueryRemoteHookResult = ReturnType<typeof useReportingSurveyAnswersLazyQueryRemote>;
export type ReportingSurveyAnswersQueryResult = Apollo.QueryResult<
  RReportingSurveyAnswersQuery,
  RReportingSurveyAnswersQueryVariables
>;
export const ReportingMyLearningsSurveyDocument = gql`
  query reportingMyLearningsSurvey($args: reportingMyLearningsSurvey_arguments!) {
    reportingMyLearningsSurvey(args: $args) {
      urn
      title
      question_urn
      question_title
      answer
      score_raw
      score_min
      score_max
      score_scaled
      answer_count
      answer_percent
      answer_total
    }
  }
`;

/**
 * __useReportingMyLearningsSurveyQueryRemote__
 *
 * To run a query within a React component, call `useReportingMyLearningsSurveyQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useReportingMyLearningsSurveyQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportingMyLearningsSurveyQueryRemote({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useReportingMyLearningsSurveyQueryRemote(
  baseOptions: Apollo.QueryHookOptions<RReportingMyLearningsSurveyQuery, RReportingMyLearningsSurveyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RReportingMyLearningsSurveyQuery, RReportingMyLearningsSurveyQueryVariables>(
    ReportingMyLearningsSurveyDocument,
    options
  );
}
export function useReportingMyLearningsSurveyLazyQueryRemote(
  baseOptions?: Apollo.LazyQueryHookOptions<RReportingMyLearningsSurveyQuery, RReportingMyLearningsSurveyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RReportingMyLearningsSurveyQuery, RReportingMyLearningsSurveyQueryVariables>(
    ReportingMyLearningsSurveyDocument,
    options
  );
}
export type ReportingMyLearningsSurveyQueryRemoteHookResult = ReturnType<typeof useReportingMyLearningsSurveyQueryRemote>;
export type ReportingMyLearningsSurveyLazyQueryRemoteHookResult = ReturnType<typeof useReportingMyLearningsSurveyLazyQueryRemote>;
export type ReportingMyLearningsSurveyQueryResult = Apollo.QueryResult<
  RReportingMyLearningsSurveyQuery,
  RReportingMyLearningsSurveyQueryVariables
>;
export const SurveyAnswerInsightsCsvDocument = gql`
  query surveyAnswerInsightsCSV($surveyAnswerInsightsCsvId: Int!) {
    surveyAnswerInsightsCSV(id: $surveyAnswerInsightsCsvId)
  }
`;

/**
 * __useSurveyAnswerInsightsCsvQueryRemote__
 *
 * To run a query within a React component, call `useSurveyAnswerInsightsCsvQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useSurveyAnswerInsightsCsvQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyAnswerInsightsCsvQueryRemote({
 *   variables: {
 *      surveyAnswerInsightsCsvId: // value for 'surveyAnswerInsightsCsvId'
 *   },
 * });
 */
export function useSurveyAnswerInsightsCsvQueryRemote(
  baseOptions: Apollo.QueryHookOptions<RSurveyAnswerInsightsCsvQuery, RSurveyAnswerInsightsCsvQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RSurveyAnswerInsightsCsvQuery, RSurveyAnswerInsightsCsvQueryVariables>(
    SurveyAnswerInsightsCsvDocument,
    options
  );
}
export function useSurveyAnswerInsightsCsvLazyQueryRemote(
  baseOptions?: Apollo.LazyQueryHookOptions<RSurveyAnswerInsightsCsvQuery, RSurveyAnswerInsightsCsvQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RSurveyAnswerInsightsCsvQuery, RSurveyAnswerInsightsCsvQueryVariables>(
    SurveyAnswerInsightsCsvDocument,
    options
  );
}
export type SurveyAnswerInsightsCsvQueryRemoteHookResult = ReturnType<typeof useSurveyAnswerInsightsCsvQueryRemote>;
export type SurveyAnswerInsightsCsvLazyQueryRemoteHookResult = ReturnType<typeof useSurveyAnswerInsightsCsvLazyQueryRemote>;
export type SurveyAnswerInsightsCsvQueryResult = Apollo.QueryResult<
  RSurveyAnswerInsightsCsvQuery,
  RSurveyAnswerInsightsCsvQueryVariables
>;
export const CreateLearningJourneyDocument = gql`
  mutation createLearningJourney($input: CreateLearningJourneyInput!) {
    createLearningJourney(input: $input) {
      success
      journey {
        id
      }
    }
  }
`;
export type RCreateLearningJourneyMutationFn = Apollo.MutationFunction<
  RCreateLearningJourneyMutation,
  RCreateLearningJourneyMutationVariables
>;

/**
 * __useCreateLearningJourneyMutationRemote__
 *
 * To run a mutation, you first call `useCreateLearningJourneyMutationRemote` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLearningJourneyMutationRemote` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLearningJourneyMutationRemote, { data, loading, error }] = useCreateLearningJourneyMutationRemote({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLearningJourneyMutationRemote(
  baseOptions?: Apollo.MutationHookOptions<RCreateLearningJourneyMutation, RCreateLearningJourneyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RCreateLearningJourneyMutation, RCreateLearningJourneyMutationVariables>(
    CreateLearningJourneyDocument,
    options
  );
}
export type CreateLearningJourneyMutationRemoteHookResult = ReturnType<typeof useCreateLearningJourneyMutationRemote>;
export type CreateLearningJourneyMutationResult = Apollo.MutationResult<RCreateLearningJourneyMutation>;
export type CreateLearningJourneyMutationOptions = Apollo.BaseMutationOptions<
  RCreateLearningJourneyMutation,
  RCreateLearningJourneyMutationVariables
>;
export const UpsertLearningJourneyItemsDocument = gql`
  mutation upsertLearningJourneyItems($input: UpsertLearningJourneyItemsInput!) {
    upsertLearningJourneyItems(input: $input) {
      success
      journey {
        id
      }
    }
  }
`;
export type RUpsertLearningJourneyItemsMutationFn = Apollo.MutationFunction<
  RUpsertLearningJourneyItemsMutation,
  RUpsertLearningJourneyItemsMutationVariables
>;

/**
 * __useUpsertLearningJourneyItemsMutationRemote__
 *
 * To run a mutation, you first call `useUpsertLearningJourneyItemsMutationRemote` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertLearningJourneyItemsMutationRemote` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertLearningJourneyItemsMutationRemote, { data, loading, error }] = useUpsertLearningJourneyItemsMutationRemote({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertLearningJourneyItemsMutationRemote(
  baseOptions?: Apollo.MutationHookOptions<RUpsertLearningJourneyItemsMutation, RUpsertLearningJourneyItemsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RUpsertLearningJourneyItemsMutation, RUpsertLearningJourneyItemsMutationVariables>(
    UpsertLearningJourneyItemsDocument,
    options
  );
}
export type UpsertLearningJourneyItemsMutationRemoteHookResult = ReturnType<typeof useUpsertLearningJourneyItemsMutationRemote>;
export type UpsertLearningJourneyItemsMutationResult = Apollo.MutationResult<RUpsertLearningJourneyItemsMutation>;
export type UpsertLearningJourneyItemsMutationOptions = Apollo.BaseMutationOptions<
  RUpsertLearningJourneyItemsMutation,
  RUpsertLearningJourneyItemsMutationVariables
>;
export const UpdateLearningJourneyDocument = gql`
  mutation updateLearningJourney($input: UpdateLearningJourneyInput!) {
    updateLearningJourney(input: $input) {
      success
      journey {
        id
        subjectId
        subject {
          subjectId
          subject
          categoryId
          category
        }
        businessGoal
        title
        description
        bannerURL
        status
        completionCriteria
        completionInvestTimeSec
      }
    }
  }
`;
export type RUpdateLearningJourneyMutationFn = Apollo.MutationFunction<
  RUpdateLearningJourneyMutation,
  RUpdateLearningJourneyMutationVariables
>;

/**
 * __useUpdateLearningJourneyMutationRemote__
 *
 * To run a mutation, you first call `useUpdateLearningJourneyMutationRemote` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLearningJourneyMutationRemote` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLearningJourneyMutationRemote, { data, loading, error }] = useUpdateLearningJourneyMutationRemote({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLearningJourneyMutationRemote(
  baseOptions?: Apollo.MutationHookOptions<RUpdateLearningJourneyMutation, RUpdateLearningJourneyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RUpdateLearningJourneyMutation, RUpdateLearningJourneyMutationVariables>(
    UpdateLearningJourneyDocument,
    options
  );
}
export type UpdateLearningJourneyMutationRemoteHookResult = ReturnType<typeof useUpdateLearningJourneyMutationRemote>;
export type UpdateLearningJourneyMutationResult = Apollo.MutationResult<RUpdateLearningJourneyMutation>;
export type UpdateLearningJourneyMutationOptions = Apollo.BaseMutationOptions<
  RUpdateLearningJourneyMutation,
  RUpdateLearningJourneyMutationVariables
>;
export const UpsertLearningJourneyCohortsDocument = gql`
  mutation upsertLearningJourneyCohorts($input: UpsertLearningJourneyCohortsInput!) {
    upsertLearningJourneyCohorts(input: $input) {
      success
    }
  }
`;
export type RUpsertLearningJourneyCohortsMutationFn = Apollo.MutationFunction<
  RUpsertLearningJourneyCohortsMutation,
  RUpsertLearningJourneyCohortsMutationVariables
>;

/**
 * __useUpsertLearningJourneyCohortsMutationRemote__
 *
 * To run a mutation, you first call `useUpsertLearningJourneyCohortsMutationRemote` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertLearningJourneyCohortsMutationRemote` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertLearningJourneyCohortsMutationRemote, { data, loading, error }] = useUpsertLearningJourneyCohortsMutationRemote({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertLearningJourneyCohortsMutationRemote(
  baseOptions?: Apollo.MutationHookOptions<RUpsertLearningJourneyCohortsMutation, RUpsertLearningJourneyCohortsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RUpsertLearningJourneyCohortsMutation, RUpsertLearningJourneyCohortsMutationVariables>(
    UpsertLearningJourneyCohortsDocument,
    options
  );
}
export type UpsertLearningJourneyCohortsMutationRemoteHookResult = ReturnType<
  typeof useUpsertLearningJourneyCohortsMutationRemote
>;
export type UpsertLearningJourneyCohortsMutationResult = Apollo.MutationResult<RUpsertLearningJourneyCohortsMutation>;
export type UpsertLearningJourneyCohortsMutationOptions = Apollo.BaseMutationOptions<
  RUpsertLearningJourneyCohortsMutation,
  RUpsertLearningJourneyCohortsMutationVariables
>;
export const JoinLearningJourneyCohortsDocument = gql`
  mutation joinLearningJourneyCohorts($userId: Int!, $sessionId: Int!) {
    joinLearningJourneyCohort(userId: $userId, sessionId: $sessionId) {
      success
    }
  }
`;
export type RJoinLearningJourneyCohortsMutationFn = Apollo.MutationFunction<
  RJoinLearningJourneyCohortsMutation,
  RJoinLearningJourneyCohortsMutationVariables
>;

/**
 * __useJoinLearningJourneyCohortsMutationRemote__
 *
 * To run a mutation, you first call `useJoinLearningJourneyCohortsMutationRemote` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinLearningJourneyCohortsMutationRemote` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinLearningJourneyCohortsMutationRemote, { data, loading, error }] = useJoinLearningJourneyCohortsMutationRemote({
 *   variables: {
 *      userId: // value for 'userId'
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useJoinLearningJourneyCohortsMutationRemote(
  baseOptions?: Apollo.MutationHookOptions<RJoinLearningJourneyCohortsMutation, RJoinLearningJourneyCohortsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RJoinLearningJourneyCohortsMutation, RJoinLearningJourneyCohortsMutationVariables>(
    JoinLearningJourneyCohortsDocument,
    options
  );
}
export type JoinLearningJourneyCohortsMutationRemoteHookResult = ReturnType<typeof useJoinLearningJourneyCohortsMutationRemote>;
export type JoinLearningJourneyCohortsMutationResult = Apollo.MutationResult<RJoinLearningJourneyCohortsMutation>;
export type JoinLearningJourneyCohortsMutationOptions = Apollo.BaseMutationOptions<
  RJoinLearningJourneyCohortsMutation,
  RJoinLearningJourneyCohortsMutationVariables
>;
export const UnlockUserLearningJourneyItemDocument = gql`
  mutation unlockUserLearningJourneyItem($userId: Int!, $learningJourneyId: Int!, $itemURN: URN!) {
    unlockUserLearningJourneyItem(userId: $userId, learningJourneyId: $learningJourneyId, itemURN: $itemURN) {
      success
    }
  }
`;
export type RUnlockUserLearningJourneyItemMutationFn = Apollo.MutationFunction<
  RUnlockUserLearningJourneyItemMutation,
  RUnlockUserLearningJourneyItemMutationVariables
>;

/**
 * __useUnlockUserLearningJourneyItemMutationRemote__
 *
 * To run a mutation, you first call `useUnlockUserLearningJourneyItemMutationRemote` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlockUserLearningJourneyItemMutationRemote` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlockUserLearningJourneyItemMutationRemote, { data, loading, error }] = useUnlockUserLearningJourneyItemMutationRemote({
 *   variables: {
 *      userId: // value for 'userId'
 *      learningJourneyId: // value for 'learningJourneyId'
 *      itemURN: // value for 'itemURN'
 *   },
 * });
 */
export function useUnlockUserLearningJourneyItemMutationRemote(
  baseOptions?: Apollo.MutationHookOptions<
    RUnlockUserLearningJourneyItemMutation,
    RUnlockUserLearningJourneyItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RUnlockUserLearningJourneyItemMutation, RUnlockUserLearningJourneyItemMutationVariables>(
    UnlockUserLearningJourneyItemDocument,
    options
  );
}
export type UnlockUserLearningJourneyItemMutationRemoteHookResult = ReturnType<
  typeof useUnlockUserLearningJourneyItemMutationRemote
>;
export type UnlockUserLearningJourneyItemMutationResult = Apollo.MutationResult<RUnlockUserLearningJourneyItemMutation>;
export type UnlockUserLearningJourneyItemMutationOptions = Apollo.BaseMutationOptions<
  RUnlockUserLearningJourneyItemMutation,
  RUnlockUserLearningJourneyItemMutationVariables
>;
export const CreateLearningJourneySurveysDocument = gql`
  mutation createLearningJourneySurveys($input: CreateLearningJourneySurveysInput!) {
    createLearningJourneySurveys(input: $input) {
      id
      title
      urn
      type
      image
      effort
    }
  }
`;
export type RCreateLearningJourneySurveysMutationFn = Apollo.MutationFunction<
  RCreateLearningJourneySurveysMutation,
  RCreateLearningJourneySurveysMutationVariables
>;

/**
 * __useCreateLearningJourneySurveysMutationRemote__
 *
 * To run a mutation, you first call `useCreateLearningJourneySurveysMutationRemote` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLearningJourneySurveysMutationRemote` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLearningJourneySurveysMutationRemote, { data, loading, error }] = useCreateLearningJourneySurveysMutationRemote({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLearningJourneySurveysMutationRemote(
  baseOptions?: Apollo.MutationHookOptions<RCreateLearningJourneySurveysMutation, RCreateLearningJourneySurveysMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RCreateLearningJourneySurveysMutation, RCreateLearningJourneySurveysMutationVariables>(
    CreateLearningJourneySurveysDocument,
    options
  );
}
export type CreateLearningJourneySurveysMutationRemoteHookResult = ReturnType<
  typeof useCreateLearningJourneySurveysMutationRemote
>;
export type CreateLearningJourneySurveysMutationResult = Apollo.MutationResult<RCreateLearningJourneySurveysMutation>;
export type CreateLearningJourneySurveysMutationOptions = Apollo.BaseMutationOptions<
  RCreateLearningJourneySurveysMutation,
  RCreateLearningJourneySurveysMutationVariables
>;
export const CreateLearningJourneyPracticalAssessmentsDocument = gql`
  mutation createLearningJourneyPracticalAssessments($input: CreateLearningJourneyPracticalAssessmentsInput!) {
    createLearningJourneyPracticalAssessments(input: $input) {
      id
      title
      urn
      type
      image
      effort
    }
  }
`;
export type RCreateLearningJourneyPracticalAssessmentsMutationFn = Apollo.MutationFunction<
  RCreateLearningJourneyPracticalAssessmentsMutation,
  RCreateLearningJourneyPracticalAssessmentsMutationVariables
>;

/**
 * __useCreateLearningJourneyPracticalAssessmentsMutationRemote__
 *
 * To run a mutation, you first call `useCreateLearningJourneyPracticalAssessmentsMutationRemote` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLearningJourneyPracticalAssessmentsMutationRemote` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLearningJourneyPracticalAssessmentsMutationRemote, { data, loading, error }] = useCreateLearningJourneyPracticalAssessmentsMutationRemote({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLearningJourneyPracticalAssessmentsMutationRemote(
  baseOptions?: Apollo.MutationHookOptions<
    RCreateLearningJourneyPracticalAssessmentsMutation,
    RCreateLearningJourneyPracticalAssessmentsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RCreateLearningJourneyPracticalAssessmentsMutation,
    RCreateLearningJourneyPracticalAssessmentsMutationVariables
  >(CreateLearningJourneyPracticalAssessmentsDocument, options);
}
export type CreateLearningJourneyPracticalAssessmentsMutationRemoteHookResult = ReturnType<
  typeof useCreateLearningJourneyPracticalAssessmentsMutationRemote
>;
export type CreateLearningJourneyPracticalAssessmentsMutationResult =
  Apollo.MutationResult<RCreateLearningJourneyPracticalAssessmentsMutation>;
export type CreateLearningJourneyPracticalAssessmentsMutationOptions = Apollo.BaseMutationOptions<
  RCreateLearningJourneyPracticalAssessmentsMutation,
  RCreateLearningJourneyPracticalAssessmentsMutationVariables
>;
export const CreateUserLearningSubmissionDocument = gql`
  mutation createUserLearningSubmission($input: CreateUserLearningSubmissionInput!) {
    createUserLearningSubmission(input: $input) {
      success
      submission {
        data
        id
        createdAt
        userId
        type
        pageURNV2
        learningURNV2
      }
    }
  }
`;
export type RCreateUserLearningSubmissionMutationFn = Apollo.MutationFunction<
  RCreateUserLearningSubmissionMutation,
  RCreateUserLearningSubmissionMutationVariables
>;

/**
 * __useCreateUserLearningSubmissionMutationRemote__
 *
 * To run a mutation, you first call `useCreateUserLearningSubmissionMutationRemote` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserLearningSubmissionMutationRemote` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserLearningSubmissionMutationRemote, { data, loading, error }] = useCreateUserLearningSubmissionMutationRemote({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserLearningSubmissionMutationRemote(
  baseOptions?: Apollo.MutationHookOptions<RCreateUserLearningSubmissionMutation, RCreateUserLearningSubmissionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RCreateUserLearningSubmissionMutation, RCreateUserLearningSubmissionMutationVariables>(
    CreateUserLearningSubmissionDocument,
    options
  );
}
export type CreateUserLearningSubmissionMutationRemoteHookResult = ReturnType<
  typeof useCreateUserLearningSubmissionMutationRemote
>;
export type CreateUserLearningSubmissionMutationResult = Apollo.MutationResult<RCreateUserLearningSubmissionMutation>;
export type CreateUserLearningSubmissionMutationOptions = Apollo.BaseMutationOptions<
  RCreateUserLearningSubmissionMutation,
  RCreateUserLearningSubmissionMutationVariables
>;
export const UserLearningSubmissionsDocument = gql`
  query userLearningSubmissions($input: UserLearningSubmissionsFilter!) {
    userLearningSubmissions(input: $input) {
      createdAt
      data
      id
      pageURNV2
      learningURNV2
      reviews {
        createdAt
        data
        id
        reviewerId
        submissionId
        type
        updatedAt
      }
      reviewAssignments {
        createdAt
        expiryAt
        id
        reviewerId
        submissionId
        updatedAt
        submission {
          data
          id
          createdAt
          pageURNV2
          type
          userId
          updatedAt
          reviews {
            reviewerId
            createdAt
            data
            id
          }
        }
      }
      type
      updatedAt
      userId
    }
  }
`;

/**
 * __useUserLearningSubmissionsQueryRemote__
 *
 * To run a query within a React component, call `useUserLearningSubmissionsQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useUserLearningSubmissionsQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserLearningSubmissionsQueryRemote({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserLearningSubmissionsQueryRemote(
  baseOptions: Apollo.QueryHookOptions<RUserLearningSubmissionsQuery, RUserLearningSubmissionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RUserLearningSubmissionsQuery, RUserLearningSubmissionsQueryVariables>(
    UserLearningSubmissionsDocument,
    options
  );
}
export function useUserLearningSubmissionsLazyQueryRemote(
  baseOptions?: Apollo.LazyQueryHookOptions<RUserLearningSubmissionsQuery, RUserLearningSubmissionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RUserLearningSubmissionsQuery, RUserLearningSubmissionsQueryVariables>(
    UserLearningSubmissionsDocument,
    options
  );
}
export type UserLearningSubmissionsQueryRemoteHookResult = ReturnType<typeof useUserLearningSubmissionsQueryRemote>;
export type UserLearningSubmissionsLazyQueryRemoteHookResult = ReturnType<typeof useUserLearningSubmissionsLazyQueryRemote>;
export type UserLearningSubmissionsQueryResult = Apollo.QueryResult<
  RUserLearningSubmissionsQuery,
  RUserLearningSubmissionsQueryVariables
>;
export const CreateUserLearningSubmissionReviewDocument = gql`
  mutation createUserLearningSubmissionReview($input: CreateUserLearningSubmissionReviewInput!) {
    createUserLearningSubmissionReview(input: $input) {
      success
    }
  }
`;
export type RCreateUserLearningSubmissionReviewMutationFn = Apollo.MutationFunction<
  RCreateUserLearningSubmissionReviewMutation,
  RCreateUserLearningSubmissionReviewMutationVariables
>;

/**
 * __useCreateUserLearningSubmissionReviewMutationRemote__
 *
 * To run a mutation, you first call `useCreateUserLearningSubmissionReviewMutationRemote` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserLearningSubmissionReviewMutationRemote` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserLearningSubmissionReviewMutationRemote, { data, loading, error }] = useCreateUserLearningSubmissionReviewMutationRemote({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserLearningSubmissionReviewMutationRemote(
  baseOptions?: Apollo.MutationHookOptions<
    RCreateUserLearningSubmissionReviewMutation,
    RCreateUserLearningSubmissionReviewMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RCreateUserLearningSubmissionReviewMutation, RCreateUserLearningSubmissionReviewMutationVariables>(
    CreateUserLearningSubmissionReviewDocument,
    options
  );
}
export type CreateUserLearningSubmissionReviewMutationRemoteHookResult = ReturnType<
  typeof useCreateUserLearningSubmissionReviewMutationRemote
>;
export type CreateUserLearningSubmissionReviewMutationResult = Apollo.MutationResult<RCreateUserLearningSubmissionReviewMutation>;
export type CreateUserLearningSubmissionReviewMutationOptions = Apollo.BaseMutationOptions<
  RCreateUserLearningSubmissionReviewMutation,
  RCreateUserLearningSubmissionReviewMutationVariables
>;
export const ReportingPracticalAssessmentInsightsDocument = gql`
  query reportingPracticalAssessmentInsights($where: reportingPracticalAssessmentInsights_bool_exp) {
    reportingPracticalAssessmentInsights(where: $where) {
      learningContentURN
      learningJourneyURN
      pageId
      pageURN
      responsesCount
      reviewsCount
    }
  }
`;

/**
 * __useReportingPracticalAssessmentInsightsQueryRemote__
 *
 * To run a query within a React component, call `useReportingPracticalAssessmentInsightsQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useReportingPracticalAssessmentInsightsQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportingPracticalAssessmentInsightsQueryRemote({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useReportingPracticalAssessmentInsightsQueryRemote(
  baseOptions?: Apollo.QueryHookOptions<
    RReportingPracticalAssessmentInsightsQuery,
    RReportingPracticalAssessmentInsightsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RReportingPracticalAssessmentInsightsQuery, RReportingPracticalAssessmentInsightsQueryVariables>(
    ReportingPracticalAssessmentInsightsDocument,
    options
  );
}
export function useReportingPracticalAssessmentInsightsLazyQueryRemote(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RReportingPracticalAssessmentInsightsQuery,
    RReportingPracticalAssessmentInsightsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RReportingPracticalAssessmentInsightsQuery, RReportingPracticalAssessmentInsightsQueryVariables>(
    ReportingPracticalAssessmentInsightsDocument,
    options
  );
}
export type ReportingPracticalAssessmentInsightsQueryRemoteHookResult = ReturnType<
  typeof useReportingPracticalAssessmentInsightsQueryRemote
>;
export type ReportingPracticalAssessmentInsightsLazyQueryRemoteHookResult = ReturnType<
  typeof useReportingPracticalAssessmentInsightsLazyQueryRemote
>;
export type ReportingPracticalAssessmentInsightsQueryResult = Apollo.QueryResult<
  RReportingPracticalAssessmentInsightsQuery,
  RReportingPracticalAssessmentInsightsQueryVariables
>;
export const CreateLearningJourneyLiveEventDocument = gql`
  mutation createLearningJourneyLiveEvent($input: CreateLearningJourneyLiveEventInput!) {
    createLearningJourneyLiveEvent(input: $input) {
      id
      title
      type
      urn
      effort
      image
    }
  }
`;
export type RCreateLearningJourneyLiveEventMutationFn = Apollo.MutationFunction<
  RCreateLearningJourneyLiveEventMutation,
  RCreateLearningJourneyLiveEventMutationVariables
>;

/**
 * __useCreateLearningJourneyLiveEventMutationRemote__
 *
 * To run a mutation, you first call `useCreateLearningJourneyLiveEventMutationRemote` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLearningJourneyLiveEventMutationRemote` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLearningJourneyLiveEventMutationRemote, { data, loading, error }] = useCreateLearningJourneyLiveEventMutationRemote({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLearningJourneyLiveEventMutationRemote(
  baseOptions?: Apollo.MutationHookOptions<
    RCreateLearningJourneyLiveEventMutation,
    RCreateLearningJourneyLiveEventMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RCreateLearningJourneyLiveEventMutation, RCreateLearningJourneyLiveEventMutationVariables>(
    CreateLearningJourneyLiveEventDocument,
    options
  );
}
export type CreateLearningJourneyLiveEventMutationRemoteHookResult = ReturnType<
  typeof useCreateLearningJourneyLiveEventMutationRemote
>;
export type CreateLearningJourneyLiveEventMutationResult = Apollo.MutationResult<RCreateLearningJourneyLiveEventMutation>;
export type CreateLearningJourneyLiveEventMutationOptions = Apollo.BaseMutationOptions<
  RCreateLearningJourneyLiveEventMutation,
  RCreateLearningJourneyLiveEventMutationVariables
>;
export const SearchLearningJourneyContentDocument = gql`
  query searchLearningJourneyContent($filter: SearchLearningContentFilter!, $pagination: Pagination!, $spaceIds: [Int!]) {
    searchLearningContent(filter: $filter, pagination: $pagination) {
      data {
        ...LearningContent
      }
      limit
      page
    }
  }
  ${LearningContentFragmentDoc}
`;

/**
 * __useSearchLearningJourneyContentQueryRemote__
 *
 * To run a query within a React component, call `useSearchLearningJourneyContentQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useSearchLearningJourneyContentQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchLearningJourneyContentQueryRemote({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      spaceIds: // value for 'spaceIds'
 *   },
 * });
 */
export function useSearchLearningJourneyContentQueryRemote(
  baseOptions: Apollo.QueryHookOptions<RSearchLearningJourneyContentQuery, RSearchLearningJourneyContentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RSearchLearningJourneyContentQuery, RSearchLearningJourneyContentQueryVariables>(
    SearchLearningJourneyContentDocument,
    options
  );
}
export function useSearchLearningJourneyContentLazyQueryRemote(
  baseOptions?: Apollo.LazyQueryHookOptions<RSearchLearningJourneyContentQuery, RSearchLearningJourneyContentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RSearchLearningJourneyContentQuery, RSearchLearningJourneyContentQueryVariables>(
    SearchLearningJourneyContentDocument,
    options
  );
}
export type SearchLearningJourneyContentQueryRemoteHookResult = ReturnType<typeof useSearchLearningJourneyContentQueryRemote>;
export type SearchLearningJourneyContentLazyQueryRemoteHookResult = ReturnType<
  typeof useSearchLearningJourneyContentLazyQueryRemote
>;
export type SearchLearningJourneyContentQueryResult = Apollo.QueryResult<
  RSearchLearningJourneyContentQuery,
  RSearchLearningJourneyContentQueryVariables
>;
export const DeleteLearningJourneysDocument = gql`
  mutation deleteLearningJourneys($journeyIds: [Int!]!) {
    deleteLearningJourneys(journeyIds: $journeyIds) {
      id
      status
    }
  }
`;
export type RDeleteLearningJourneysMutationFn = Apollo.MutationFunction<
  RDeleteLearningJourneysMutation,
  RDeleteLearningJourneysMutationVariables
>;

/**
 * __useDeleteLearningJourneysMutationRemote__
 *
 * To run a mutation, you first call `useDeleteLearningJourneysMutationRemote` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLearningJourneysMutationRemote` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLearningJourneysMutationRemote, { data, loading, error }] = useDeleteLearningJourneysMutationRemote({
 *   variables: {
 *      journeyIds: // value for 'journeyIds'
 *   },
 * });
 */
export function useDeleteLearningJourneysMutationRemote(
  baseOptions?: Apollo.MutationHookOptions<RDeleteLearningJourneysMutation, RDeleteLearningJourneysMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RDeleteLearningJourneysMutation, RDeleteLearningJourneysMutationVariables>(
    DeleteLearningJourneysDocument,
    options
  );
}
export type DeleteLearningJourneysMutationRemoteHookResult = ReturnType<typeof useDeleteLearningJourneysMutationRemote>;
export type DeleteLearningJourneysMutationResult = Apollo.MutationResult<RDeleteLearningJourneysMutation>;
export type DeleteLearningJourneysMutationOptions = Apollo.BaseMutationOptions<
  RDeleteLearningJourneysMutation,
  RDeleteLearningJourneysMutationVariables
>;
export const LearningJourneyContentInsightsCsvDocument = gql`
  query learningJourneyContentInsightsCSV($learningJourneyId: Int!) {
    learningJourneyContentInsightsCSV(id: $learningJourneyId)
  }
`;

/**
 * __useLearningJourneyContentInsightsCsvQueryRemote__
 *
 * To run a query within a React component, call `useLearningJourneyContentInsightsCsvQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useLearningJourneyContentInsightsCsvQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearningJourneyContentInsightsCsvQueryRemote({
 *   variables: {
 *      learningJourneyId: // value for 'learningJourneyId'
 *   },
 * });
 */
export function useLearningJourneyContentInsightsCsvQueryRemote(
  baseOptions: Apollo.QueryHookOptions<RLearningJourneyContentInsightsCsvQuery, RLearningJourneyContentInsightsCsvQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RLearningJourneyContentInsightsCsvQuery, RLearningJourneyContentInsightsCsvQueryVariables>(
    LearningJourneyContentInsightsCsvDocument,
    options
  );
}
export function useLearningJourneyContentInsightsCsvLazyQueryRemote(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RLearningJourneyContentInsightsCsvQuery,
    RLearningJourneyContentInsightsCsvQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RLearningJourneyContentInsightsCsvQuery, RLearningJourneyContentInsightsCsvQueryVariables>(
    LearningJourneyContentInsightsCsvDocument,
    options
  );
}
export type LearningJourneyContentInsightsCsvQueryRemoteHookResult = ReturnType<
  typeof useLearningJourneyContentInsightsCsvQueryRemote
>;
export type LearningJourneyContentInsightsCsvLazyQueryRemoteHookResult = ReturnType<
  typeof useLearningJourneyContentInsightsCsvLazyQueryRemote
>;
export type LearningJourneyContentInsightsCsvQueryResult = Apollo.QueryResult<
  RLearningJourneyContentInsightsCsvQuery,
  RLearningJourneyContentInsightsCsvQueryVariables
>;
export const LearningJourneyParticipantInsightsCsvDocument = gql`
  query learningJourneyParticipantInsightsCSV($learningJourneyId: Int!) {
    learningJourneyParticipantInsightsCSV(id: $learningJourneyId)
  }
`;

/**
 * __useLearningJourneyParticipantInsightsCsvQueryRemote__
 *
 * To run a query within a React component, call `useLearningJourneyParticipantInsightsCsvQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useLearningJourneyParticipantInsightsCsvQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearningJourneyParticipantInsightsCsvQueryRemote({
 *   variables: {
 *      learningJourneyId: // value for 'learningJourneyId'
 *   },
 * });
 */
export function useLearningJourneyParticipantInsightsCsvQueryRemote(
  baseOptions: Apollo.QueryHookOptions<
    RLearningJourneyParticipantInsightsCsvQuery,
    RLearningJourneyParticipantInsightsCsvQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RLearningJourneyParticipantInsightsCsvQuery, RLearningJourneyParticipantInsightsCsvQueryVariables>(
    LearningJourneyParticipantInsightsCsvDocument,
    options
  );
}
export function useLearningJourneyParticipantInsightsCsvLazyQueryRemote(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RLearningJourneyParticipantInsightsCsvQuery,
    RLearningJourneyParticipantInsightsCsvQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RLearningJourneyParticipantInsightsCsvQuery, RLearningJourneyParticipantInsightsCsvQueryVariables>(
    LearningJourneyParticipantInsightsCsvDocument,
    options
  );
}
export type LearningJourneyParticipantInsightsCsvQueryRemoteHookResult = ReturnType<
  typeof useLearningJourneyParticipantInsightsCsvQueryRemote
>;
export type LearningJourneyParticipantInsightsCsvLazyQueryRemoteHookResult = ReturnType<
  typeof useLearningJourneyParticipantInsightsCsvLazyQueryRemote
>;
export type LearningJourneyParticipantInsightsCsvQueryResult = Apollo.QueryResult<
  RLearningJourneyParticipantInsightsCsvQuery,
  RLearningJourneyParticipantInsightsCsvQueryVariables
>;
export const ReportingMyLearningJourneysLearnersTotalCountDocument = gql`
  query reportingMyLearningJourneysLearnersTotalCount(
    $args: reportingMyLearningJourneysLearnersTotalCount_arguments!
    $limit: Int
    $offset: Int
  ) {
    reportingMyLearningJourneysLearnersTotalCount(args: $args, limit: $limit, offset: $offset) {
      total_count
    }
  }
`;

/**
 * __useReportingMyLearningJourneysLearnersTotalCountQueryRemote__
 *
 * To run a query within a React component, call `useReportingMyLearningJourneysLearnersTotalCountQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useReportingMyLearningJourneysLearnersTotalCountQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportingMyLearningJourneysLearnersTotalCountQueryRemote({
 *   variables: {
 *      args: // value for 'args'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useReportingMyLearningJourneysLearnersTotalCountQueryRemote(
  baseOptions: Apollo.QueryHookOptions<
    RReportingMyLearningJourneysLearnersTotalCountQuery,
    RReportingMyLearningJourneysLearnersTotalCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RReportingMyLearningJourneysLearnersTotalCountQuery,
    RReportingMyLearningJourneysLearnersTotalCountQueryVariables
  >(ReportingMyLearningJourneysLearnersTotalCountDocument, options);
}
export function useReportingMyLearningJourneysLearnersTotalCountLazyQueryRemote(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RReportingMyLearningJourneysLearnersTotalCountQuery,
    RReportingMyLearningJourneysLearnersTotalCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RReportingMyLearningJourneysLearnersTotalCountQuery,
    RReportingMyLearningJourneysLearnersTotalCountQueryVariables
  >(ReportingMyLearningJourneysLearnersTotalCountDocument, options);
}
export type ReportingMyLearningJourneysLearnersTotalCountQueryRemoteHookResult = ReturnType<
  typeof useReportingMyLearningJourneysLearnersTotalCountQueryRemote
>;
export type ReportingMyLearningJourneysLearnersTotalCountLazyQueryRemoteHookResult = ReturnType<
  typeof useReportingMyLearningJourneysLearnersTotalCountLazyQueryRemote
>;
export type ReportingMyLearningJourneysLearnersTotalCountQueryResult = Apollo.QueryResult<
  RReportingMyLearningJourneysLearnersTotalCountQuery,
  RReportingMyLearningJourneysLearnersTotalCountQueryVariables
>;
export const ReportingMyLearningJourneysLearnersDocument = gql`
  query reportingMyLearningJourneysLearners(
    $args: reportingMyLearningJourneysLearners_arguments!
    $limit: Int
    $offset: Int
    $order_by: [learners_order_by!]
  ) {
    reportingMyLearningJourneysLearners(args: $args, limit: $limit, offset: $offset, order_by: $order_by) {
      user_id
      username
      email
      profile_image
      join_time
      progress
      is_completed
      completion_time
      last_activity_time
    }
  }
`;

/**
 * __useReportingMyLearningJourneysLearnersQueryRemote__
 *
 * To run a query within a React component, call `useReportingMyLearningJourneysLearnersQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useReportingMyLearningJourneysLearnersQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportingMyLearningJourneysLearnersQueryRemote({
 *   variables: {
 *      args: // value for 'args'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useReportingMyLearningJourneysLearnersQueryRemote(
  baseOptions: Apollo.QueryHookOptions<
    RReportingMyLearningJourneysLearnersQuery,
    RReportingMyLearningJourneysLearnersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RReportingMyLearningJourneysLearnersQuery, RReportingMyLearningJourneysLearnersQueryVariables>(
    ReportingMyLearningJourneysLearnersDocument,
    options
  );
}
export function useReportingMyLearningJourneysLearnersLazyQueryRemote(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RReportingMyLearningJourneysLearnersQuery,
    RReportingMyLearningJourneysLearnersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RReportingMyLearningJourneysLearnersQuery, RReportingMyLearningJourneysLearnersQueryVariables>(
    ReportingMyLearningJourneysLearnersDocument,
    options
  );
}
export type ReportingMyLearningJourneysLearnersQueryRemoteHookResult = ReturnType<
  typeof useReportingMyLearningJourneysLearnersQueryRemote
>;
export type ReportingMyLearningJourneysLearnersLazyQueryRemoteHookResult = ReturnType<
  typeof useReportingMyLearningJourneysLearnersLazyQueryRemote
>;
export type ReportingMyLearningJourneysLearnersQueryResult = Apollo.QueryResult<
  RReportingMyLearningJourneysLearnersQuery,
  RReportingMyLearningJourneysLearnersQueryVariables
>;
export const ReportingMyLearningJourneysV2Document = gql`
  query reportingMyLearningJourneysV2(
    $args: reportingMyLearningJourneysV2_arguments!
    $limit: Int
    $offset: Int
    $orderBy: [learning_journeys_v2_order_by!]
  ) {
    reportingMyLearningJourneysV2(args: $args, limit: $limit, offset: $offset, order_by: $orderBy) {
      urn
      title
      status
      space_name
      space_id
      learning_items_count
    }
  }
`;

/**
 * __useReportingMyLearningJourneysV2QueryRemote__
 *
 * To run a query within a React component, call `useReportingMyLearningJourneysV2QueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useReportingMyLearningJourneysV2QueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportingMyLearningJourneysV2QueryRemote({
 *   variables: {
 *      args: // value for 'args'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useReportingMyLearningJourneysV2QueryRemote(
  baseOptions: Apollo.QueryHookOptions<RReportingMyLearningJourneysV2Query, RReportingMyLearningJourneysV2QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RReportingMyLearningJourneysV2Query, RReportingMyLearningJourneysV2QueryVariables>(
    ReportingMyLearningJourneysV2Document,
    options
  );
}
export function useReportingMyLearningJourneysV2LazyQueryRemote(
  baseOptions?: Apollo.LazyQueryHookOptions<RReportingMyLearningJourneysV2Query, RReportingMyLearningJourneysV2QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RReportingMyLearningJourneysV2Query, RReportingMyLearningJourneysV2QueryVariables>(
    ReportingMyLearningJourneysV2Document,
    options
  );
}
export type ReportingMyLearningJourneysV2QueryRemoteHookResult = ReturnType<typeof useReportingMyLearningJourneysV2QueryRemote>;
export type ReportingMyLearningJourneysV2LazyQueryRemoteHookResult = ReturnType<
  typeof useReportingMyLearningJourneysV2LazyQueryRemote
>;
export type ReportingMyLearningJourneysV2QueryResult = Apollo.QueryResult<
  RReportingMyLearningJourneysV2Query,
  RReportingMyLearningJourneysV2QueryVariables
>;
export const ReportingMyLearningsLearnersDocument = gql`
  query reportingMyLearningsLearners(
    $args: reportingMyLearningsLearners_arguments!
    $limit: Int
    $offset: Int
    $orderBy: [learners_order_by!]
    $where: learners_bool_exp_bool_exp
  ) {
    reportingMyLearningsLearners(args: $args, limit: $limit, offset: $offset, order_by: $orderBy, where: $where) {
      user_id
      username
      email
      profile_image
      join_time
      progress
      completion_time
      is_completed
      last_activity_time
    }
  }
`;

/**
 * __useReportingMyLearningsLearnersQueryRemote__
 *
 * To run a query within a React component, call `useReportingMyLearningsLearnersQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useReportingMyLearningsLearnersQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportingMyLearningsLearnersQueryRemote({
 *   variables: {
 *      args: // value for 'args'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useReportingMyLearningsLearnersQueryRemote(
  baseOptions: Apollo.QueryHookOptions<RReportingMyLearningsLearnersQuery, RReportingMyLearningsLearnersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RReportingMyLearningsLearnersQuery, RReportingMyLearningsLearnersQueryVariables>(
    ReportingMyLearningsLearnersDocument,
    options
  );
}
export function useReportingMyLearningsLearnersLazyQueryRemote(
  baseOptions?: Apollo.LazyQueryHookOptions<RReportingMyLearningsLearnersQuery, RReportingMyLearningsLearnersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RReportingMyLearningsLearnersQuery, RReportingMyLearningsLearnersQueryVariables>(
    ReportingMyLearningsLearnersDocument,
    options
  );
}
export type ReportingMyLearningsLearnersQueryRemoteHookResult = ReturnType<typeof useReportingMyLearningsLearnersQueryRemote>;
export type ReportingMyLearningsLearnersLazyQueryRemoteHookResult = ReturnType<
  typeof useReportingMyLearningsLearnersLazyQueryRemote
>;
export type ReportingMyLearningsLearnersQueryResult = Apollo.QueryResult<
  RReportingMyLearningsLearnersQuery,
  RReportingMyLearningsLearnersQueryVariables
>;
export const ReportingMyLiveLearningsLearnersDocument = gql`
  query reportingMyLiveLearningsLearners(
    $args: reportingMyLiveLearningsLearners_arguments!
    $limit: Int
    $offset: Int
    $orderBy: [live_learnings_learners_order_by!]
    $where: live_learnings_learners_bool_exp_bool_exp
  ) {
    reportingMyLiveLearningsLearners(args: $args, limit: $limit, offset: $offset, order_by: $orderBy, where: $where) {
      user_id
      username
      email
      profile_image
      session_time
      attendance
      completion_time
      is_completed
    }
  }
`;

/**
 * __useReportingMyLiveLearningsLearnersQueryRemote__
 *
 * To run a query within a React component, call `useReportingMyLiveLearningsLearnersQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useReportingMyLiveLearningsLearnersQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportingMyLiveLearningsLearnersQueryRemote({
 *   variables: {
 *      args: // value for 'args'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useReportingMyLiveLearningsLearnersQueryRemote(
  baseOptions: Apollo.QueryHookOptions<RReportingMyLiveLearningsLearnersQuery, RReportingMyLiveLearningsLearnersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RReportingMyLiveLearningsLearnersQuery, RReportingMyLiveLearningsLearnersQueryVariables>(
    ReportingMyLiveLearningsLearnersDocument,
    options
  );
}
export function useReportingMyLiveLearningsLearnersLazyQueryRemote(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RReportingMyLiveLearningsLearnersQuery,
    RReportingMyLiveLearningsLearnersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RReportingMyLiveLearningsLearnersQuery, RReportingMyLiveLearningsLearnersQueryVariables>(
    ReportingMyLiveLearningsLearnersDocument,
    options
  );
}
export type ReportingMyLiveLearningsLearnersQueryRemoteHookResult = ReturnType<
  typeof useReportingMyLiveLearningsLearnersQueryRemote
>;
export type ReportingMyLiveLearningsLearnersLazyQueryRemoteHookResult = ReturnType<
  typeof useReportingMyLiveLearningsLearnersLazyQueryRemote
>;
export type ReportingMyLiveLearningsLearnersQueryResult = Apollo.QueryResult<
  RReportingMyLiveLearningsLearnersQuery,
  RReportingMyLiveLearningsLearnersQueryVariables
>;
export const ReportingMyLearningsLearnersTotalCountDocument = gql`
  query reportingMyLearningsLearnersTotalCount(
    $args: reportingMyLearningsLearnersTotalCount_arguments!
    $limit: Int
    $offset: Int
    $orderBy: [total_count_order_by!]
    $where: total_count_bool_exp_bool_exp
  ) {
    reportingMyLearningsLearnersTotalCount(args: $args, limit: $limit, offset: $offset, order_by: $orderBy, where: $where) {
      space_id
      total_count
    }
  }
`;

/**
 * __useReportingMyLearningsLearnersTotalCountQueryRemote__
 *
 * To run a query within a React component, call `useReportingMyLearningsLearnersTotalCountQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useReportingMyLearningsLearnersTotalCountQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportingMyLearningsLearnersTotalCountQueryRemote({
 *   variables: {
 *      args: // value for 'args'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useReportingMyLearningsLearnersTotalCountQueryRemote(
  baseOptions: Apollo.QueryHookOptions<
    RReportingMyLearningsLearnersTotalCountQuery,
    RReportingMyLearningsLearnersTotalCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RReportingMyLearningsLearnersTotalCountQuery, RReportingMyLearningsLearnersTotalCountQueryVariables>(
    ReportingMyLearningsLearnersTotalCountDocument,
    options
  );
}
export function useReportingMyLearningsLearnersTotalCountLazyQueryRemote(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RReportingMyLearningsLearnersTotalCountQuery,
    RReportingMyLearningsLearnersTotalCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RReportingMyLearningsLearnersTotalCountQuery, RReportingMyLearningsLearnersTotalCountQueryVariables>(
    ReportingMyLearningsLearnersTotalCountDocument,
    options
  );
}
export type ReportingMyLearningsLearnersTotalCountQueryRemoteHookResult = ReturnType<
  typeof useReportingMyLearningsLearnersTotalCountQueryRemote
>;
export type ReportingMyLearningsLearnersTotalCountLazyQueryRemoteHookResult = ReturnType<
  typeof useReportingMyLearningsLearnersTotalCountLazyQueryRemote
>;
export type ReportingMyLearningsLearnersTotalCountQueryResult = Apollo.QueryResult<
  RReportingMyLearningsLearnersTotalCountQuery,
  RReportingMyLearningsLearnersTotalCountQueryVariables
>;
export const ReportingMyLiveLearningsLearnersTotalCountDocument = gql`
  query reportingMyLiveLearningsLearnersTotalCount(
    $args: reportingMyLiveLearningsLearnersTotalCount_arguments!
    $limit: Int
    $offset: Int
    $orderBy: [total_count_order_by!]
    $where: total_count_bool_exp_bool_exp
  ) {
    reportingMyLiveLearningsLearnersTotalCount(args: $args, limit: $limit, offset: $offset, order_by: $orderBy, where: $where) {
      space_id
      total_count
    }
  }
`;

/**
 * __useReportingMyLiveLearningsLearnersTotalCountQueryRemote__
 *
 * To run a query within a React component, call `useReportingMyLiveLearningsLearnersTotalCountQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useReportingMyLiveLearningsLearnersTotalCountQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportingMyLiveLearningsLearnersTotalCountQueryRemote({
 *   variables: {
 *      args: // value for 'args'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useReportingMyLiveLearningsLearnersTotalCountQueryRemote(
  baseOptions: Apollo.QueryHookOptions<
    RReportingMyLiveLearningsLearnersTotalCountQuery,
    RReportingMyLiveLearningsLearnersTotalCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RReportingMyLiveLearningsLearnersTotalCountQuery,
    RReportingMyLiveLearningsLearnersTotalCountQueryVariables
  >(ReportingMyLiveLearningsLearnersTotalCountDocument, options);
}
export function useReportingMyLiveLearningsLearnersTotalCountLazyQueryRemote(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RReportingMyLiveLearningsLearnersTotalCountQuery,
    RReportingMyLiveLearningsLearnersTotalCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RReportingMyLiveLearningsLearnersTotalCountQuery,
    RReportingMyLiveLearningsLearnersTotalCountQueryVariables
  >(ReportingMyLiveLearningsLearnersTotalCountDocument, options);
}
export type ReportingMyLiveLearningsLearnersTotalCountQueryRemoteHookResult = ReturnType<
  typeof useReportingMyLiveLearningsLearnersTotalCountQueryRemote
>;
export type ReportingMyLiveLearningsLearnersTotalCountLazyQueryRemoteHookResult = ReturnType<
  typeof useReportingMyLiveLearningsLearnersTotalCountLazyQueryRemote
>;
export type ReportingMyLiveLearningsLearnersTotalCountQueryResult = Apollo.QueryResult<
  RReportingMyLiveLearningsLearnersTotalCountQuery,
  RReportingMyLiveLearningsLearnersTotalCountQueryVariables
>;
export const ReportingMyLearningJourneysInsightsV2Document = gql`
  query reportingMyLearningJourneysInsightsV2(
    $args: reportingMyLearningJourneysInsightsV2_arguments!
    $limit: Int
    $offset: Int
    $orderBy: [learning_journeys_insights_v2_order_by!]
  ) {
    reportingMyLearningJourneysInsightsV2(args: $args, limit: $limit, offset: $offset, order_by: $orderBy) {
      urn
      average_progress
      learners_count
      completed_count
      completion_rate
    }
  }
`;

/**
 * __useReportingMyLearningJourneysInsightsV2QueryRemote__
 *
 * To run a query within a React component, call `useReportingMyLearningJourneysInsightsV2QueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useReportingMyLearningJourneysInsightsV2QueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportingMyLearningJourneysInsightsV2QueryRemote({
 *   variables: {
 *      args: // value for 'args'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useReportingMyLearningJourneysInsightsV2QueryRemote(
  baseOptions: Apollo.QueryHookOptions<
    RReportingMyLearningJourneysInsightsV2Query,
    RReportingMyLearningJourneysInsightsV2QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RReportingMyLearningJourneysInsightsV2Query, RReportingMyLearningJourneysInsightsV2QueryVariables>(
    ReportingMyLearningJourneysInsightsV2Document,
    options
  );
}
export function useReportingMyLearningJourneysInsightsV2LazyQueryRemote(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RReportingMyLearningJourneysInsightsV2Query,
    RReportingMyLearningJourneysInsightsV2QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RReportingMyLearningJourneysInsightsV2Query, RReportingMyLearningJourneysInsightsV2QueryVariables>(
    ReportingMyLearningJourneysInsightsV2Document,
    options
  );
}
export type ReportingMyLearningJourneysInsightsV2QueryRemoteHookResult = ReturnType<
  typeof useReportingMyLearningJourneysInsightsV2QueryRemote
>;
export type ReportingMyLearningJourneysInsightsV2LazyQueryRemoteHookResult = ReturnType<
  typeof useReportingMyLearningJourneysInsightsV2LazyQueryRemote
>;
export type ReportingMyLearningJourneysInsightsV2QueryResult = Apollo.QueryResult<
  RReportingMyLearningJourneysInsightsV2Query,
  RReportingMyLearningJourneysInsightsV2QueryVariables
>;
export const ReportingMyLearningJourneysTotalCountDocument = gql`
  query reportingMyLearningJourneysTotalCount($args: reportingMyLearningJourneysTotalCount_arguments!) {
    reportingMyLearningJourneysTotalCount(args: $args) {
      total_count
      space_id
    }
  }
`;

/**
 * __useReportingMyLearningJourneysTotalCountQueryRemote__
 *
 * To run a query within a React component, call `useReportingMyLearningJourneysTotalCountQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useReportingMyLearningJourneysTotalCountQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportingMyLearningJourneysTotalCountQueryRemote({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useReportingMyLearningJourneysTotalCountQueryRemote(
  baseOptions: Apollo.QueryHookOptions<
    RReportingMyLearningJourneysTotalCountQuery,
    RReportingMyLearningJourneysTotalCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RReportingMyLearningJourneysTotalCountQuery, RReportingMyLearningJourneysTotalCountQueryVariables>(
    ReportingMyLearningJourneysTotalCountDocument,
    options
  );
}
export function useReportingMyLearningJourneysTotalCountLazyQueryRemote(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RReportingMyLearningJourneysTotalCountQuery,
    RReportingMyLearningJourneysTotalCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RReportingMyLearningJourneysTotalCountQuery, RReportingMyLearningJourneysTotalCountQueryVariables>(
    ReportingMyLearningJourneysTotalCountDocument,
    options
  );
}
export type ReportingMyLearningJourneysTotalCountQueryRemoteHookResult = ReturnType<
  typeof useReportingMyLearningJourneysTotalCountQueryRemote
>;
export type ReportingMyLearningJourneysTotalCountLazyQueryRemoteHookResult = ReturnType<
  typeof useReportingMyLearningJourneysTotalCountLazyQueryRemote
>;
export type ReportingMyLearningJourneysTotalCountQueryResult = Apollo.QueryResult<
  RReportingMyLearningJourneysTotalCountQuery,
  RReportingMyLearningJourneysTotalCountQueryVariables
>;
export const LearningContentByIdsDocument = gql`
  query learningContentByIds($contentURNs: [URN!]!, $spaceIds: [Int!]!) {
    learningContentByIds(input: $contentURNs) {
      ...LearningContentById
    }
  }
  ${LearningContentByIdFragmentDoc}
`;

/**
 * __useLearningContentByIdsQueryRemote__
 *
 * To run a query within a React component, call `useLearningContentByIdsQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useLearningContentByIdsQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearningContentByIdsQueryRemote({
 *   variables: {
 *      contentURNs: // value for 'contentURNs'
 *      spaceIds: // value for 'spaceIds'
 *   },
 * });
 */
export function useLearningContentByIdsQueryRemote(
  baseOptions: Apollo.QueryHookOptions<RLearningContentByIdsQuery, RLearningContentByIdsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RLearningContentByIdsQuery, RLearningContentByIdsQueryVariables>(LearningContentByIdsDocument, options);
}
export function useLearningContentByIdsLazyQueryRemote(
  baseOptions?: Apollo.LazyQueryHookOptions<RLearningContentByIdsQuery, RLearningContentByIdsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RLearningContentByIdsQuery, RLearningContentByIdsQueryVariables>(
    LearningContentByIdsDocument,
    options
  );
}
export type LearningContentByIdsQueryRemoteHookResult = ReturnType<typeof useLearningContentByIdsQueryRemote>;
export type LearningContentByIdsLazyQueryRemoteHookResult = ReturnType<typeof useLearningContentByIdsLazyQueryRemote>;
export type LearningContentByIdsQueryResult = Apollo.QueryResult<RLearningContentByIdsQuery, RLearningContentByIdsQueryVariables>;
export const LearningContentByIdV1Document = gql`
  query learningContentByIdV1($urn: String!, $spaceIds: [Int!]!) {
    learningContentByIdV1(urnV1: $urn) {
      ...LearningContentById
    }
  }
  ${LearningContentByIdFragmentDoc}
`;

/**
 * __useLearningContentByIdV1QueryRemote__
 *
 * To run a query within a React component, call `useLearningContentByIdV1QueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useLearningContentByIdV1QueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearningContentByIdV1QueryRemote({
 *   variables: {
 *      urn: // value for 'urn'
 *      spaceIds: // value for 'spaceIds'
 *   },
 * });
 */
export function useLearningContentByIdV1QueryRemote(
  baseOptions: Apollo.QueryHookOptions<RLearningContentByIdV1Query, RLearningContentByIdV1QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RLearningContentByIdV1Query, RLearningContentByIdV1QueryVariables>(
    LearningContentByIdV1Document,
    options
  );
}
export function useLearningContentByIdV1LazyQueryRemote(
  baseOptions?: Apollo.LazyQueryHookOptions<RLearningContentByIdV1Query, RLearningContentByIdV1QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RLearningContentByIdV1Query, RLearningContentByIdV1QueryVariables>(
    LearningContentByIdV1Document,
    options
  );
}
export type LearningContentByIdV1QueryRemoteHookResult = ReturnType<typeof useLearningContentByIdV1QueryRemote>;
export type LearningContentByIdV1LazyQueryRemoteHookResult = ReturnType<typeof useLearningContentByIdV1LazyQueryRemote>;
export type LearningContentByIdV1QueryResult = Apollo.QueryResult<
  RLearningContentByIdV1Query,
  RLearningContentByIdV1QueryVariables
>;
export const LearningContentLearnerInsightsCsvDocument = gql`
  query learningContentLearnerInsightsCSV($urn: String!) {
    learningContentLearnerInsightsCSV(urn: $urn)
  }
`;

/**
 * __useLearningContentLearnerInsightsCsvQueryRemote__
 *
 * To run a query within a React component, call `useLearningContentLearnerInsightsCsvQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useLearningContentLearnerInsightsCsvQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearningContentLearnerInsightsCsvQueryRemote({
 *   variables: {
 *      urn: // value for 'urn'
 *   },
 * });
 */
export function useLearningContentLearnerInsightsCsvQueryRemote(
  baseOptions: Apollo.QueryHookOptions<RLearningContentLearnerInsightsCsvQuery, RLearningContentLearnerInsightsCsvQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RLearningContentLearnerInsightsCsvQuery, RLearningContentLearnerInsightsCsvQueryVariables>(
    LearningContentLearnerInsightsCsvDocument,
    options
  );
}
export function useLearningContentLearnerInsightsCsvLazyQueryRemote(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RLearningContentLearnerInsightsCsvQuery,
    RLearningContentLearnerInsightsCsvQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RLearningContentLearnerInsightsCsvQuery, RLearningContentLearnerInsightsCsvQueryVariables>(
    LearningContentLearnerInsightsCsvDocument,
    options
  );
}
export type LearningContentLearnerInsightsCsvQueryRemoteHookResult = ReturnType<
  typeof useLearningContentLearnerInsightsCsvQueryRemote
>;
export type LearningContentLearnerInsightsCsvLazyQueryRemoteHookResult = ReturnType<
  typeof useLearningContentLearnerInsightsCsvLazyQueryRemote
>;
export type LearningContentLearnerInsightsCsvQueryResult = Apollo.QueryResult<
  RLearningContentLearnerInsightsCsvQuery,
  RLearningContentLearnerInsightsCsvQueryVariables
>;
export const LearningContentByIdsV1Document = gql`
  query learningContentByIdsV1($contentURNs: [String!]!, $spaceIds: [Int!]!) {
    learningContentByIdsV1(input: $contentURNs) {
      ...LearningContentById
    }
  }
  ${LearningContentByIdFragmentDoc}
`;

/**
 * __useLearningContentByIdsV1QueryRemote__
 *
 * To run a query within a React component, call `useLearningContentByIdsV1QueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useLearningContentByIdsV1QueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearningContentByIdsV1QueryRemote({
 *   variables: {
 *      contentURNs: // value for 'contentURNs'
 *      spaceIds: // value for 'spaceIds'
 *   },
 * });
 */
export function useLearningContentByIdsV1QueryRemote(
  baseOptions: Apollo.QueryHookOptions<RLearningContentByIdsV1Query, RLearningContentByIdsV1QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RLearningContentByIdsV1Query, RLearningContentByIdsV1QueryVariables>(
    LearningContentByIdsV1Document,
    options
  );
}
export function useLearningContentByIdsV1LazyQueryRemote(
  baseOptions?: Apollo.LazyQueryHookOptions<RLearningContentByIdsV1Query, RLearningContentByIdsV1QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RLearningContentByIdsV1Query, RLearningContentByIdsV1QueryVariables>(
    LearningContentByIdsV1Document,
    options
  );
}
export type LearningContentByIdsV1QueryRemoteHookResult = ReturnType<typeof useLearningContentByIdsV1QueryRemote>;
export type LearningContentByIdsV1LazyQueryRemoteHookResult = ReturnType<typeof useLearningContentByIdsV1LazyQueryRemote>;
export type LearningContentByIdsV1QueryResult = Apollo.QueryResult<
  RLearningContentByIdsV1Query,
  RLearningContentByIdsV1QueryVariables
>;
export const LearningByIdDocument = gql`
  query learningById($id: ID!, $includeProgress: Boolean!, $includeLocations: Boolean!) {
    learning(id: $id) {
      ...LearningFragment
      modules {
        ...LearningModulesDeepFragment
      }
      progress @include(if: $includeProgress) {
        ...LearningProgressFragment
      }
      locations @include(if: $includeLocations) {
        ...LocationsFragment
      }
    }
  }
  ${LearningFragmentFragmentDoc}
  ${LearningModulesDeepFragmentFragmentDoc}
  ${LearningProgressFragmentFragmentDoc}
  ${LocationsFragmentFragmentDoc}
`;

/**
 * __useLearningByIdQueryRemote__
 *
 * To run a query within a React component, call `useLearningByIdQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useLearningByIdQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearningByIdQueryRemote({
 *   variables: {
 *      id: // value for 'id'
 *      includeProgress: // value for 'includeProgress'
 *      includeLocations: // value for 'includeLocations'
 *   },
 * });
 */
export function useLearningByIdQueryRemote(
  baseOptions: Apollo.QueryHookOptions<RLearningByIdQuery, RLearningByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RLearningByIdQuery, RLearningByIdQueryVariables>(LearningByIdDocument, options);
}
export function useLearningByIdLazyQueryRemote(
  baseOptions?: Apollo.LazyQueryHookOptions<RLearningByIdQuery, RLearningByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RLearningByIdQuery, RLearningByIdQueryVariables>(LearningByIdDocument, options);
}
export type LearningByIdQueryRemoteHookResult = ReturnType<typeof useLearningByIdQueryRemote>;
export type LearningByIdLazyQueryRemoteHookResult = ReturnType<typeof useLearningByIdLazyQueryRemote>;
export type LearningByIdQueryResult = Apollo.QueryResult<RLearningByIdQuery, RLearningByIdQueryVariables>;
export const LearningV2ByIdDocument = gql`
  query learningV2ById($id: Int!, $userId: Int!) {
    learningV2(id: $id) {
      ...LearningV2Fragment
      activities {
        ...ActivityFragment
      }
      userLastParticipation(userId: $userId) {
        id
        expiryAt
        isCompleted
      }
      updateByUser {
        id
        firstname
        lastname
      }
    }
  }
  ${LearningV2FragmentFragmentDoc}
  ${ActivityFragmentFragmentDoc}
`;

/**
 * __useLearningV2ByIdQueryRemote__
 *
 * To run a query within a React component, call `useLearningV2ByIdQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useLearningV2ByIdQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearningV2ByIdQueryRemote({
 *   variables: {
 *      id: // value for 'id'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useLearningV2ByIdQueryRemote(
  baseOptions: Apollo.QueryHookOptions<RLearningV2ByIdQuery, RLearningV2ByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RLearningV2ByIdQuery, RLearningV2ByIdQueryVariables>(LearningV2ByIdDocument, options);
}
export function useLearningV2ByIdLazyQueryRemote(
  baseOptions?: Apollo.LazyQueryHookOptions<RLearningV2ByIdQuery, RLearningV2ByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RLearningV2ByIdQuery, RLearningV2ByIdQueryVariables>(LearningV2ByIdDocument, options);
}
export type LearningV2ByIdQueryRemoteHookResult = ReturnType<typeof useLearningV2ByIdQueryRemote>;
export type LearningV2ByIdLazyQueryRemoteHookResult = ReturnType<typeof useLearningV2ByIdLazyQueryRemote>;
export type LearningV2ByIdQueryResult = Apollo.QueryResult<RLearningV2ByIdQuery, RLearningV2ByIdQueryVariables>;
export const SaveLearningDocument = gql`
  mutation saveLearning($input: LearningInput!) {
    saveLearning(payload: $input) {
      id
      title
    }
  }
`;
export type RSaveLearningMutationFn = Apollo.MutationFunction<RSaveLearningMutation, RSaveLearningMutationVariables>;

/**
 * __useSaveLearningMutationRemote__
 *
 * To run a mutation, you first call `useSaveLearningMutationRemote` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveLearningMutationRemote` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveLearningMutationRemote, { data, loading, error }] = useSaveLearningMutationRemote({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveLearningMutationRemote(
  baseOptions?: Apollo.MutationHookOptions<RSaveLearningMutation, RSaveLearningMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RSaveLearningMutation, RSaveLearningMutationVariables>(SaveLearningDocument, options);
}
export type SaveLearningMutationRemoteHookResult = ReturnType<typeof useSaveLearningMutationRemote>;
export type SaveLearningMutationResult = Apollo.MutationResult<RSaveLearningMutation>;
export type SaveLearningMutationOptions = Apollo.BaseMutationOptions<RSaveLearningMutation, RSaveLearningMutationVariables>;
export const UpsertLearningDocument = gql`
  mutation upsertLearning($input: UpsertLearningInput!) {
    upsertLearning(input: $input) {
      id
      title
    }
  }
`;
export type RUpsertLearningMutationFn = Apollo.MutationFunction<RUpsertLearningMutation, RUpsertLearningMutationVariables>;

/**
 * __useUpsertLearningMutationRemote__
 *
 * To run a mutation, you first call `useUpsertLearningMutationRemote` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertLearningMutationRemote` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertLearningMutationRemote, { data, loading, error }] = useUpsertLearningMutationRemote({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertLearningMutationRemote(
  baseOptions?: Apollo.MutationHookOptions<RUpsertLearningMutation, RUpsertLearningMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RUpsertLearningMutation, RUpsertLearningMutationVariables>(UpsertLearningDocument, options);
}
export type UpsertLearningMutationRemoteHookResult = ReturnType<typeof useUpsertLearningMutationRemote>;
export type UpsertLearningMutationResult = Apollo.MutationResult<RUpsertLearningMutation>;
export type UpsertLearningMutationOptions = Apollo.BaseMutationOptions<RUpsertLearningMutation, RUpsertLearningMutationVariables>;
export const DeleteLearningDocument = gql`
  mutation deleteLearning($learningId: Int!) {
    deleteLearning(learningId: $learningId) {
      id
    }
  }
`;
export type RDeleteLearningMutationFn = Apollo.MutationFunction<RDeleteLearningMutation, RDeleteLearningMutationVariables>;

/**
 * __useDeleteLearningMutationRemote__
 *
 * To run a mutation, you first call `useDeleteLearningMutationRemote` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLearningMutationRemote` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLearningMutationRemote, { data, loading, error }] = useDeleteLearningMutationRemote({
 *   variables: {
 *      learningId: // value for 'learningId'
 *   },
 * });
 */
export function useDeleteLearningMutationRemote(
  baseOptions?: Apollo.MutationHookOptions<RDeleteLearningMutation, RDeleteLearningMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RDeleteLearningMutation, RDeleteLearningMutationVariables>(DeleteLearningDocument, options);
}
export type DeleteLearningMutationRemoteHookResult = ReturnType<typeof useDeleteLearningMutationRemote>;
export type DeleteLearningMutationResult = Apollo.MutationResult<RDeleteLearningMutation>;
export type DeleteLearningMutationOptions = Apollo.BaseMutationOptions<RDeleteLearningMutation, RDeleteLearningMutationVariables>;
export const LearningTypeByIdDocument = gql`
  query learningTypeById($id: ID!) {
    learning(id: $id) {
      id
      type
    }
  }
`;

/**
 * __useLearningTypeByIdQueryRemote__
 *
 * To run a query within a React component, call `useLearningTypeByIdQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useLearningTypeByIdQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearningTypeByIdQueryRemote({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLearningTypeByIdQueryRemote(
  baseOptions: Apollo.QueryHookOptions<RLearningTypeByIdQuery, RLearningTypeByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RLearningTypeByIdQuery, RLearningTypeByIdQueryVariables>(LearningTypeByIdDocument, options);
}
export function useLearningTypeByIdLazyQueryRemote(
  baseOptions?: Apollo.LazyQueryHookOptions<RLearningTypeByIdQuery, RLearningTypeByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RLearningTypeByIdQuery, RLearningTypeByIdQueryVariables>(LearningTypeByIdDocument, options);
}
export type LearningTypeByIdQueryRemoteHookResult = ReturnType<typeof useLearningTypeByIdQueryRemote>;
export type LearningTypeByIdLazyQueryRemoteHookResult = ReturnType<typeof useLearningTypeByIdLazyQueryRemote>;
export type LearningTypeByIdQueryResult = Apollo.QueryResult<RLearningTypeByIdQuery, RLearningTypeByIdQueryVariables>;
export const LearningParentsByIdDocument = gql`
  query learningParentsById($id: ID!, $filter: LearningParentsFilter!, $userId: Int!) {
    learning(id: $id) {
      id
      urnV2
      parents(filter: $filter) {
        id
        title
        userLastParticipation(userId: $userId) {
          id
        }
      }
    }
  }
`;

/**
 * __useLearningParentsByIdQueryRemote__
 *
 * To run a query within a React component, call `useLearningParentsByIdQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useLearningParentsByIdQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearningParentsByIdQueryRemote({
 *   variables: {
 *      id: // value for 'id'
 *      filter: // value for 'filter'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useLearningParentsByIdQueryRemote(
  baseOptions: Apollo.QueryHookOptions<RLearningParentsByIdQuery, RLearningParentsByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RLearningParentsByIdQuery, RLearningParentsByIdQueryVariables>(LearningParentsByIdDocument, options);
}
export function useLearningParentsByIdLazyQueryRemote(
  baseOptions?: Apollo.LazyQueryHookOptions<RLearningParentsByIdQuery, RLearningParentsByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RLearningParentsByIdQuery, RLearningParentsByIdQueryVariables>(LearningParentsByIdDocument, options);
}
export type LearningParentsByIdQueryRemoteHookResult = ReturnType<typeof useLearningParentsByIdQueryRemote>;
export type LearningParentsByIdLazyQueryRemoteHookResult = ReturnType<typeof useLearningParentsByIdLazyQueryRemote>;
export type LearningParentsByIdQueryResult = Apollo.QueryResult<RLearningParentsByIdQuery, RLearningParentsByIdQueryVariables>;
export const SearchLearningContentDocument = gql`
  query searchLearningContent($filter: SearchLearningContentFilter!, $pagination: Pagination!, $spaceIds: [Int!]!) {
    searchLearningContent(filter: $filter, pagination: $pagination) {
      data {
        ...LearningContent
      }
      limit
      page
      total
    }
  }
  ${LearningContentFragmentDoc}
`;

/**
 * __useSearchLearningContentQueryRemote__
 *
 * To run a query within a React component, call `useSearchLearningContentQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useSearchLearningContentQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchLearningContentQueryRemote({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      spaceIds: // value for 'spaceIds'
 *   },
 * });
 */
export function useSearchLearningContentQueryRemote(
  baseOptions: Apollo.QueryHookOptions<RSearchLearningContentQuery, RSearchLearningContentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RSearchLearningContentQuery, RSearchLearningContentQueryVariables>(
    SearchLearningContentDocument,
    options
  );
}
export function useSearchLearningContentLazyQueryRemote(
  baseOptions?: Apollo.LazyQueryHookOptions<RSearchLearningContentQuery, RSearchLearningContentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RSearchLearningContentQuery, RSearchLearningContentQueryVariables>(
    SearchLearningContentDocument,
    options
  );
}
export type SearchLearningContentQueryRemoteHookResult = ReturnType<typeof useSearchLearningContentQueryRemote>;
export type SearchLearningContentLazyQueryRemoteHookResult = ReturnType<typeof useSearchLearningContentLazyQueryRemote>;
export type SearchLearningContentQueryResult = Apollo.QueryResult<
  RSearchLearningContentQuery,
  RSearchLearningContentQueryVariables
>;
export const ImportLearningContentToSpaceDocument = gql`
  mutation importLearningContentToSpace($input: ImportLearningContentToSpaceInput!) {
    importLearningContentToSpace(input: $input) {
      success
      content {
        id
        title
      }
      failedIds
    }
  }
`;
export type RImportLearningContentToSpaceMutationFn = Apollo.MutationFunction<
  RImportLearningContentToSpaceMutation,
  RImportLearningContentToSpaceMutationVariables
>;

/**
 * __useImportLearningContentToSpaceMutationRemote__
 *
 * To run a mutation, you first call `useImportLearningContentToSpaceMutationRemote` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportLearningContentToSpaceMutationRemote` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importLearningContentToSpaceMutationRemote, { data, loading, error }] = useImportLearningContentToSpaceMutationRemote({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportLearningContentToSpaceMutationRemote(
  baseOptions?: Apollo.MutationHookOptions<RImportLearningContentToSpaceMutation, RImportLearningContentToSpaceMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RImportLearningContentToSpaceMutation, RImportLearningContentToSpaceMutationVariables>(
    ImportLearningContentToSpaceDocument,
    options
  );
}
export type ImportLearningContentToSpaceMutationRemoteHookResult = ReturnType<
  typeof useImportLearningContentToSpaceMutationRemote
>;
export type ImportLearningContentToSpaceMutationResult = Apollo.MutationResult<RImportLearningContentToSpaceMutation>;
export type ImportLearningContentToSpaceMutationOptions = Apollo.BaseMutationOptions<
  RImportLearningContentToSpaceMutation,
  RImportLearningContentToSpaceMutationVariables
>;
export const SubmitLearningActivityQuizAnswersDocument = gql`
  mutation submitLearningActivityQuizAnswers($input: UserLearningActivityQuizSubmissionInput!) {
    submitLearningActivityQuizAnswers(input: $input) {
      id
    }
  }
`;
export type RSubmitLearningActivityQuizAnswersMutationFn = Apollo.MutationFunction<
  RSubmitLearningActivityQuizAnswersMutation,
  RSubmitLearningActivityQuizAnswersMutationVariables
>;

/**
 * __useSubmitLearningActivityQuizAnswersMutationRemote__
 *
 * To run a mutation, you first call `useSubmitLearningActivityQuizAnswersMutationRemote` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitLearningActivityQuizAnswersMutationRemote` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitLearningActivityQuizAnswersMutationRemote, { data, loading, error }] = useSubmitLearningActivityQuizAnswersMutationRemote({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitLearningActivityQuizAnswersMutationRemote(
  baseOptions?: Apollo.MutationHookOptions<
    RSubmitLearningActivityQuizAnswersMutation,
    RSubmitLearningActivityQuizAnswersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RSubmitLearningActivityQuizAnswersMutation, RSubmitLearningActivityQuizAnswersMutationVariables>(
    SubmitLearningActivityQuizAnswersDocument,
    options
  );
}
export type SubmitLearningActivityQuizAnswersMutationRemoteHookResult = ReturnType<
  typeof useSubmitLearningActivityQuizAnswersMutationRemote
>;
export type SubmitLearningActivityQuizAnswersMutationResult = Apollo.MutationResult<RSubmitLearningActivityQuizAnswersMutation>;
export type SubmitLearningActivityQuizAnswersMutationOptions = Apollo.BaseMutationOptions<
  RSubmitLearningActivityQuizAnswersMutation,
  RSubmitLearningActivityQuizAnswersMutationVariables
>;
export const MyLearningActivityQuizSubmissionsDocument = gql`
  query myLearningActivityQuizSubmissions($input: UserLearningActivityQuizSubmissionFilterInput!) {
    myLearningActivityQuizSubmissions(input: $input) {
      answers
      id
      score
    }
  }
`;

/**
 * __useMyLearningActivityQuizSubmissionsQueryRemote__
 *
 * To run a query within a React component, call `useMyLearningActivityQuizSubmissionsQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useMyLearningActivityQuizSubmissionsQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyLearningActivityQuizSubmissionsQueryRemote({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMyLearningActivityQuizSubmissionsQueryRemote(
  baseOptions: Apollo.QueryHookOptions<RMyLearningActivityQuizSubmissionsQuery, RMyLearningActivityQuizSubmissionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RMyLearningActivityQuizSubmissionsQuery, RMyLearningActivityQuizSubmissionsQueryVariables>(
    MyLearningActivityQuizSubmissionsDocument,
    options
  );
}
export function useMyLearningActivityQuizSubmissionsLazyQueryRemote(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RMyLearningActivityQuizSubmissionsQuery,
    RMyLearningActivityQuizSubmissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RMyLearningActivityQuizSubmissionsQuery, RMyLearningActivityQuizSubmissionsQueryVariables>(
    MyLearningActivityQuizSubmissionsDocument,
    options
  );
}
export type MyLearningActivityQuizSubmissionsQueryRemoteHookResult = ReturnType<
  typeof useMyLearningActivityQuizSubmissionsQueryRemote
>;
export type MyLearningActivityQuizSubmissionsLazyQueryRemoteHookResult = ReturnType<
  typeof useMyLearningActivityQuizSubmissionsLazyQueryRemote
>;
export type MyLearningActivityQuizSubmissionsQueryResult = Apollo.QueryResult<
  RMyLearningActivityQuizSubmissionsQuery,
  RMyLearningActivityQuizSubmissionsQueryVariables
>;
export const MigrateOnDemandLearningToCoursesDocument = gql`
  mutation migrateOnDemandLearningToCourses($learningId: Int!) {
    migrateOnDemandLearningToCourses(learningId: $learningId) {
      id
      title
    }
  }
`;
export type RMigrateOnDemandLearningToCoursesMutationFn = Apollo.MutationFunction<
  RMigrateOnDemandLearningToCoursesMutation,
  RMigrateOnDemandLearningToCoursesMutationVariables
>;

/**
 * __useMigrateOnDemandLearningToCoursesMutationRemote__
 *
 * To run a mutation, you first call `useMigrateOnDemandLearningToCoursesMutationRemote` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMigrateOnDemandLearningToCoursesMutationRemote` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [migrateOnDemandLearningToCoursesMutationRemote, { data, loading, error }] = useMigrateOnDemandLearningToCoursesMutationRemote({
 *   variables: {
 *      learningId: // value for 'learningId'
 *   },
 * });
 */
export function useMigrateOnDemandLearningToCoursesMutationRemote(
  baseOptions?: Apollo.MutationHookOptions<
    RMigrateOnDemandLearningToCoursesMutation,
    RMigrateOnDemandLearningToCoursesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RMigrateOnDemandLearningToCoursesMutation, RMigrateOnDemandLearningToCoursesMutationVariables>(
    MigrateOnDemandLearningToCoursesDocument,
    options
  );
}
export type MigrateOnDemandLearningToCoursesMutationRemoteHookResult = ReturnType<
  typeof useMigrateOnDemandLearningToCoursesMutationRemote
>;
export type MigrateOnDemandLearningToCoursesMutationResult = Apollo.MutationResult<RMigrateOnDemandLearningToCoursesMutation>;
export type MigrateOnDemandLearningToCoursesMutationOptions = Apollo.BaseMutationOptions<
  RMigrateOnDemandLearningToCoursesMutation,
  RMigrateOnDemandLearningToCoursesMutationVariables
>;
export const CloneLearningDocument = gql`
  mutation cloneLearning($input: CloneLearningInput!) {
    cloneLearning(input: $input) {
      id
      type
      title
    }
  }
`;
export type RCloneLearningMutationFn = Apollo.MutationFunction<RCloneLearningMutation, RCloneLearningMutationVariables>;

/**
 * __useCloneLearningMutationRemote__
 *
 * To run a mutation, you first call `useCloneLearningMutationRemote` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloneLearningMutationRemote` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cloneLearningMutationRemote, { data, loading, error }] = useCloneLearningMutationRemote({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCloneLearningMutationRemote(
  baseOptions?: Apollo.MutationHookOptions<RCloneLearningMutation, RCloneLearningMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RCloneLearningMutation, RCloneLearningMutationVariables>(CloneLearningDocument, options);
}
export type CloneLearningMutationRemoteHookResult = ReturnType<typeof useCloneLearningMutationRemote>;
export type CloneLearningMutationResult = Apollo.MutationResult<RCloneLearningMutation>;
export type CloneLearningMutationOptions = Apollo.BaseMutationOptions<RCloneLearningMutation, RCloneLearningMutationVariables>;
export const ReportingLearningsDocument = gql`
  query reportingLearnings($args: reportingLearnings_arguments!, $limit: Int, $offset: Int, $orderBy: [learnings_order_by!]) {
    reportingLearnings(args: $args, limit: $limit, offset: $offset, order_by: $orderBy) {
      partner
      space_ids
      space_names
      team_ids
      title
      type
      urn
      visibility
      category
      subject
      planned_duration
    }
  }
`;

/**
 * __useReportingLearningsQueryRemote__
 *
 * To run a query within a React component, call `useReportingLearningsQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useReportingLearningsQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportingLearningsQueryRemote({
 *   variables: {
 *      args: // value for 'args'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useReportingLearningsQueryRemote(
  baseOptions: Apollo.QueryHookOptions<RReportingLearningsQuery, RReportingLearningsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RReportingLearningsQuery, RReportingLearningsQueryVariables>(ReportingLearningsDocument, options);
}
export function useReportingLearningsLazyQueryRemote(
  baseOptions?: Apollo.LazyQueryHookOptions<RReportingLearningsQuery, RReportingLearningsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RReportingLearningsQuery, RReportingLearningsQueryVariables>(ReportingLearningsDocument, options);
}
export type ReportingLearningsQueryRemoteHookResult = ReturnType<typeof useReportingLearningsQueryRemote>;
export type ReportingLearningsLazyQueryRemoteHookResult = ReturnType<typeof useReportingLearningsLazyQueryRemote>;
export type ReportingLearningsQueryResult = Apollo.QueryResult<RReportingLearningsQuery, RReportingLearningsQueryVariables>;
export const ReportingLearningsTotalCountDocument = gql`
  query reportingLearningsTotalCount($args: reportingLearningsTotalCount_arguments!) {
    reportingLearningsTotalCount(args: $args) {
      total_count
    }
  }
`;

/**
 * __useReportingLearningsTotalCountQueryRemote__
 *
 * To run a query within a React component, call `useReportingLearningsTotalCountQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useReportingLearningsTotalCountQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportingLearningsTotalCountQueryRemote({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useReportingLearningsTotalCountQueryRemote(
  baseOptions: Apollo.QueryHookOptions<RReportingLearningsTotalCountQuery, RReportingLearningsTotalCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RReportingLearningsTotalCountQuery, RReportingLearningsTotalCountQueryVariables>(
    ReportingLearningsTotalCountDocument,
    options
  );
}
export function useReportingLearningsTotalCountLazyQueryRemote(
  baseOptions?: Apollo.LazyQueryHookOptions<RReportingLearningsTotalCountQuery, RReportingLearningsTotalCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RReportingLearningsTotalCountQuery, RReportingLearningsTotalCountQueryVariables>(
    ReportingLearningsTotalCountDocument,
    options
  );
}
export type ReportingLearningsTotalCountQueryRemoteHookResult = ReturnType<typeof useReportingLearningsTotalCountQueryRemote>;
export type ReportingLearningsTotalCountLazyQueryRemoteHookResult = ReturnType<
  typeof useReportingLearningsTotalCountLazyQueryRemote
>;
export type ReportingLearningsTotalCountQueryResult = Apollo.QueryResult<
  RReportingLearningsTotalCountQuery,
  RReportingLearningsTotalCountQueryVariables
>;
export const ReportingLearningsInsightsDocument = gql`
  query reportingLearningsInsights(
    $args: reportingLearningsInsights_arguments!
    $limit: Int
    $offset: Int
    $orderBy: [learningsInsights_order_by!]
  ) {
    reportingLearningsInsights(args: $args, limit: $limit, offset: $offset, order_by: $orderBy) {
      completion_rate
      learners_count
      space_ids
      team_ids
      urn
    }
  }
`;

/**
 * __useReportingLearningsInsightsQueryRemote__
 *
 * To run a query within a React component, call `useReportingLearningsInsightsQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useReportingLearningsInsightsQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportingLearningsInsightsQueryRemote({
 *   variables: {
 *      args: // value for 'args'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useReportingLearningsInsightsQueryRemote(
  baseOptions: Apollo.QueryHookOptions<RReportingLearningsInsightsQuery, RReportingLearningsInsightsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RReportingLearningsInsightsQuery, RReportingLearningsInsightsQueryVariables>(
    ReportingLearningsInsightsDocument,
    options
  );
}
export function useReportingLearningsInsightsLazyQueryRemote(
  baseOptions?: Apollo.LazyQueryHookOptions<RReportingLearningsInsightsQuery, RReportingLearningsInsightsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RReportingLearningsInsightsQuery, RReportingLearningsInsightsQueryVariables>(
    ReportingLearningsInsightsDocument,
    options
  );
}
export type ReportingLearningsInsightsQueryRemoteHookResult = ReturnType<typeof useReportingLearningsInsightsQueryRemote>;
export type ReportingLearningsInsightsLazyQueryRemoteHookResult = ReturnType<typeof useReportingLearningsInsightsLazyQueryRemote>;
export type ReportingLearningsInsightsQueryResult = Apollo.QueryResult<
  RReportingLearningsInsightsQuery,
  RReportingLearningsInsightsQueryVariables
>;
export const GetLearningXapiSessionDocument = gql`
  query getLearningXAPISession($learningId: Int!) {
    getXAPISession(learningId: $learningId) {
      queryString
      registration
    }
  }
`;

/**
 * __useGetLearningXapiSessionQueryRemote__
 *
 * To run a query within a React component, call `useGetLearningXapiSessionQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useGetLearningXapiSessionQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLearningXapiSessionQueryRemote({
 *   variables: {
 *      learningId: // value for 'learningId'
 *   },
 * });
 */
export function useGetLearningXapiSessionQueryRemote(
  baseOptions: Apollo.QueryHookOptions<RGetLearningXapiSessionQuery, RGetLearningXapiSessionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RGetLearningXapiSessionQuery, RGetLearningXapiSessionQueryVariables>(
    GetLearningXapiSessionDocument,
    options
  );
}
export function useGetLearningXapiSessionLazyQueryRemote(
  baseOptions?: Apollo.LazyQueryHookOptions<RGetLearningXapiSessionQuery, RGetLearningXapiSessionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RGetLearningXapiSessionQuery, RGetLearningXapiSessionQueryVariables>(
    GetLearningXapiSessionDocument,
    options
  );
}
export type GetLearningXapiSessionQueryRemoteHookResult = ReturnType<typeof useGetLearningXapiSessionQueryRemote>;
export type GetLearningXapiSessionLazyQueryRemoteHookResult = ReturnType<typeof useGetLearningXapiSessionLazyQueryRemote>;
export type GetLearningXapiSessionQueryResult = Apollo.QueryResult<
  RGetLearningXapiSessionQuery,
  RGetLearningXapiSessionQueryVariables
>;
export const RegisterToLearningDocument = gql`
  mutation registerToLearning($learningId: Int!) {
    registerToLearning(learningId: $learningId) {
      queryString
      registration
    }
  }
`;
export type RRegisterToLearningMutationFn = Apollo.MutationFunction<
  RRegisterToLearningMutation,
  RRegisterToLearningMutationVariables
>;

/**
 * __useRegisterToLearningMutationRemote__
 *
 * To run a mutation, you first call `useRegisterToLearningMutationRemote` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterToLearningMutationRemote` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerToLearningMutationRemote, { data, loading, error }] = useRegisterToLearningMutationRemote({
 *   variables: {
 *      learningId: // value for 'learningId'
 *   },
 * });
 */
export function useRegisterToLearningMutationRemote(
  baseOptions?: Apollo.MutationHookOptions<RRegisterToLearningMutation, RRegisterToLearningMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RRegisterToLearningMutation, RRegisterToLearningMutationVariables>(
    RegisterToLearningDocument,
    options
  );
}
export type RegisterToLearningMutationRemoteHookResult = ReturnType<typeof useRegisterToLearningMutationRemote>;
export type RegisterToLearningMutationResult = Apollo.MutationResult<RRegisterToLearningMutation>;
export type RegisterToLearningMutationOptions = Apollo.BaseMutationOptions<
  RRegisterToLearningMutation,
  RRegisterToLearningMutationVariables
>;
export const ProductParentsByIdDocument = gql`
  query productParentsById($id: Int!, $filter: ProductParentsFilter!, $userId: Int!) {
    getProduct(id: $id) {
      id
      learningContentUrnV2
      parents(filter: $filter) {
        id
        title
        userLastParticipation(userId: $userId) {
          id
        }
      }
    }
  }
`;

/**
 * __useProductParentsByIdQueryRemote__
 *
 * To run a query within a React component, call `useProductParentsByIdQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useProductParentsByIdQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductParentsByIdQueryRemote({
 *   variables: {
 *      id: // value for 'id'
 *      filter: // value for 'filter'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useProductParentsByIdQueryRemote(
  baseOptions: Apollo.QueryHookOptions<RProductParentsByIdQuery, RProductParentsByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RProductParentsByIdQuery, RProductParentsByIdQueryVariables>(ProductParentsByIdDocument, options);
}
export function useProductParentsByIdLazyQueryRemote(
  baseOptions?: Apollo.LazyQueryHookOptions<RProductParentsByIdQuery, RProductParentsByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RProductParentsByIdQuery, RProductParentsByIdQueryVariables>(ProductParentsByIdDocument, options);
}
export type ProductParentsByIdQueryRemoteHookResult = ReturnType<typeof useProductParentsByIdQueryRemote>;
export type ProductParentsByIdLazyQueryRemoteHookResult = ReturnType<typeof useProductParentsByIdLazyQueryRemote>;
export type ProductParentsByIdQueryResult = Apollo.QueryResult<RProductParentsByIdQuery, RProductParentsByIdQueryVariables>;
export const EffortForRealmDocument = gql`
  query effortForRealm($realm: String!, $fromTime: timestamptz!, $toTime: timestamptz!) {
    reportingAggregationEffortForRealm(
      where: { realm: { _eq: $realm }, fromTime: { _gte: $fromTime }, toTime: { _gte: $toTime } }
      limit: 1
    ) {
      totalEffort
      averageEffort
      p90Effort
      p80Effort
      p70Effort
      p60Effort
      p50Effort
      fromTime
      toTime
    }
  }
`;

/**
 * __useEffortForRealmQueryRemote__
 *
 * To run a query within a React component, call `useEffortForRealmQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useEffortForRealmQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEffortForRealmQueryRemote({
 *   variables: {
 *      realm: // value for 'realm'
 *      fromTime: // value for 'fromTime'
 *      toTime: // value for 'toTime'
 *   },
 * });
 */
export function useEffortForRealmQueryRemote(
  baseOptions: Apollo.QueryHookOptions<REffortForRealmQuery, REffortForRealmQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<REffortForRealmQuery, REffortForRealmQueryVariables>(EffortForRealmDocument, options);
}
export function useEffortForRealmLazyQueryRemote(
  baseOptions?: Apollo.LazyQueryHookOptions<REffortForRealmQuery, REffortForRealmQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<REffortForRealmQuery, REffortForRealmQueryVariables>(EffortForRealmDocument, options);
}
export type EffortForRealmQueryRemoteHookResult = ReturnType<typeof useEffortForRealmQueryRemote>;
export type EffortForRealmLazyQueryRemoteHookResult = ReturnType<typeof useEffortForRealmLazyQueryRemote>;
export type EffortForRealmQueryResult = Apollo.QueryResult<REffortForRealmQuery, REffortForRealmQueryVariables>;
export const SpaceMembersDocument = gql`
  query spaceMembers($filter: SpaceMemberFilter!, $pagination: Pagination!) {
    spaceMembers(filter: $filter, pagination: $pagination) {
      data {
        id
        email
        realm
        spaceId
        firstname
        lastname
        username
        profileImage
      }
    }
  }
`;

/**
 * __useSpaceMembersQueryRemote__
 *
 * To run a query within a React component, call `useSpaceMembersQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useSpaceMembersQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpaceMembersQueryRemote({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useSpaceMembersQueryRemote(
  baseOptions: Apollo.QueryHookOptions<RSpaceMembersQuery, RSpaceMembersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RSpaceMembersQuery, RSpaceMembersQueryVariables>(SpaceMembersDocument, options);
}
export function useSpaceMembersLazyQueryRemote(
  baseOptions?: Apollo.LazyQueryHookOptions<RSpaceMembersQuery, RSpaceMembersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RSpaceMembersQuery, RSpaceMembersQueryVariables>(SpaceMembersDocument, options);
}
export type SpaceMembersQueryRemoteHookResult = ReturnType<typeof useSpaceMembersQueryRemote>;
export type SpaceMembersLazyQueryRemoteHookResult = ReturnType<typeof useSpaceMembersLazyQueryRemote>;
export type SpaceMembersQueryResult = Apollo.QueryResult<RSpaceMembersQuery, RSpaceMembersQueryVariables>;
export const SpacesDocument = gql`
  query spaces($withPermissions: Boolean!, $withContentOrigins: Boolean!) {
    spaces(pagination: { limit: -1 }) {
      data {
        id
        realm
        label
        name
        permissions @include(if: $withPermissions) {
          hasWriteAccessToLearningContent
        }
        contentOrigins(pagination: { limit: -1 }) @include(if: $withContentOrigins) {
          data {
            id
            provider {
              id
              type
            }
          }
        }
      }
    }
  }
`;

/**
 * __useSpacesQueryRemote__
 *
 * To run a query within a React component, call `useSpacesQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useSpacesQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpacesQueryRemote({
 *   variables: {
 *      withPermissions: // value for 'withPermissions'
 *      withContentOrigins: // value for 'withContentOrigins'
 *   },
 * });
 */
export function useSpacesQueryRemote(baseOptions: Apollo.QueryHookOptions<RSpacesQuery, RSpacesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RSpacesQuery, RSpacesQueryVariables>(SpacesDocument, options);
}
export function useSpacesLazyQueryRemote(baseOptions?: Apollo.LazyQueryHookOptions<RSpacesQuery, RSpacesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RSpacesQuery, RSpacesQueryVariables>(SpacesDocument, options);
}
export type SpacesQueryRemoteHookResult = ReturnType<typeof useSpacesQueryRemote>;
export type SpacesLazyQueryRemoteHookResult = ReturnType<typeof useSpacesLazyQueryRemote>;
export type SpacesQueryResult = Apollo.QueryResult<RSpacesQuery, RSpacesQueryVariables>;
export const UpdateUsersDocument = gql`
  mutation updateUsers($input: UserChangeInput!) {
    updateUser(input: $input) {
      id
    }
  }
`;
export type RUpdateUsersMutationFn = Apollo.MutationFunction<RUpdateUsersMutation, RUpdateUsersMutationVariables>;

/**
 * __useUpdateUsersMutationRemote__
 *
 * To run a mutation, you first call `useUpdateUsersMutationRemote` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUsersMutationRemote` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUsersMutationRemote, { data, loading, error }] = useUpdateUsersMutationRemote({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUsersMutationRemote(
  baseOptions?: Apollo.MutationHookOptions<RUpdateUsersMutation, RUpdateUsersMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RUpdateUsersMutation, RUpdateUsersMutationVariables>(UpdateUsersDocument, options);
}
export type UpdateUsersMutationRemoteHookResult = ReturnType<typeof useUpdateUsersMutationRemote>;
export type UpdateUsersMutationResult = Apollo.MutationResult<RUpdateUsersMutation>;
export type UpdateUsersMutationOptions = Apollo.BaseMutationOptions<RUpdateUsersMutation, RUpdateUsersMutationVariables>;
