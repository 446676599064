import { Trans } from 'react-i18next';
import React from 'react';

import { supportEmails } from '@/config';
import { StyledError } from '@/components/Form/styles';

export const GenericErrorMessage = (): JSX.Element => (
  <StyledError>
    <Trans
      ns="common"
      i18nKey="generic-error-reload-page"
      components={[
        <a
          key="0"
          href="#"
          onClick={() => {
            window.location.href = `mailto:${supportEmails.collegial}`;
            return false;
          }}
        >
          {supportEmails.collegial}
        </a>,
      ]}
    />
  </StyledError>
);
