import styled from 'styled-components';

import { LearningPartner } from '@/features/catalog/admin/constants/learning';
import {
  partnerSVGLogos,
  partnerSVGLogoSize,
  SVGLogoVariant,
} from '@/ui/PartnerSVGLogo/partnerSVGLogoDefinitions/partnerSVGLogoDefinitions';

const SVGLogoWrapperStyled = styled.div<{ size: partnerSVGLogoSize }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`;

const SVGLogoStyled = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

type PartnerSVGLogoProps = {
  partner: LearningPartner;
  size?: partnerSVGLogoSize;
  type: SVGLogoVariant;
};

const EmptySVGLogoBox: React.FC<{ size: partnerSVGLogoSize }> = ({ size }) => {
  return (
    <SVGLogoWrapperStyled size={size}>
      <SVGLogoStyled />
    </SVGLogoWrapperStyled>
  );
};

export const PartnerSVGLogo: React.FC<PartnerSVGLogoProps> = ({ partner, size = partnerSVGLogoSize.SMALL, type }) => {
  const isValidPartner = Object.values(LearningPartner).includes(partner as LearningPartner);

  if (!isValidPartner) {
    return <EmptySVGLogoBox size={size} />;
  }

  const logoData = partnerSVGLogos.find((logo) => logo.partner === partner && logo.size === size && logo.type === type);

  if (!logoData) {
    return <EmptySVGLogoBox size={size} />;
  }

  return (
    <SVGLogoWrapperStyled size={size}>
      <SVGLogoStyled src={logoData.SVGFile} />
    </SVGLogoWrapperStyled>
  );
};
