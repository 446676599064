import React, { useContext } from 'react';
import clsx from 'clsx';

import './sharedSnackbar.scss';
import { Icon } from '@/components/Icon';
import { SharedSnackbarContext } from './SharedSnackbar';

export const SharedSnackbar = (): JSX.Element => {
  const { type, isOpen, message, closeSnackbar, isDismissive } = useContext(SharedSnackbarContext);

  return (
    <div
      className={clsx('snackbar', isOpen && 'active', `is-${type}`)}
      onTransitionEnd={() => {
        if (isOpen) return;
        closeSnackbar;
      }}
    >
      <div className="snackbar-inner">{message}</div>
      {!isDismissive && (
        <div className="close" onClick={() => closeSnackbar()}>
          <Icon icon="times" />
        </div>
      )}
    </div>
  );
};
