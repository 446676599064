import { gql } from '@apollo/client';

import { isRealm } from '@/utils/realms';
import { FEATURE, FeatureName } from '@/feature-toggles';
import { InventoryProduct, LearningCollection } from '@/types/learning/learning-catalog';
import { Card } from '@/types/learning/card';
import { CollegialIconPropType } from '@/ui/Icon/types';

export type Provider =
  | 'pluralsight'
  | 'collegial'
  | 'coursera'
  | 'udacity'
  | 'linkedin'
  | 'finanskompetens'
  | 'imd'
  | 'custom-link'
  | 'engineer-four-point-zero'
  | 'tekniska-college'
  | 'mölk'
  | 'kompetens-express'
  | 'intermezzon'
  | 'user-self-tracked-learning'
  | 'lexicon'
  | 'learnifier'
  | 'autoliv'
  | 'lansforsakringar'
  | 'imparta'
  | '';

export enum ProviderList {
  PLURALSIGHT = 'pluralsight',
  COLLEGIAL = 'collegial',
  COURSERA = 'coursera',
  UDACITY = 'udacity',
  LINKEDIN = 'linkedin',
  FINANSKOMPETENS = 'finanskompetens',
  IMD = 'imd',
  CUSTOM_LINK = 'custom-link',
  ENGINEER_FOUR_POINT_ZERO = 'engineer-four-point-zero',
  TEKNISKA_COLLEGE = 'tekniska-college',
  MOLK = 'mölk',
  KOMPETENS_EXPRESS = 'kompetens-express',
  INTERMEZZON = 'intermezzon',
  USER_SELF_TRACKED_LEARNING = 'user-self-tracked-learning',
  LEXICON = 'lexicon',
  LEARNIFIER = 'learnifier',
  AUTOLIV = 'autoliv',
  LANSFORSAKRINGAR = 'lansforsakringar',
  IMPARTA = 'imparta',
}

export function isProviderAccessProduct(productId: number, inventory: readonly InventoryProduct[]): boolean {
  return inventory.some((product) => product.providerAccess !== null && product.id === productId);
}

export function mapProviderLink(provider: Provider, link: string): string {
  if (provider === ProviderList.PLURALSIGHT) {
    const courseMatch = link?.match(/pluralsight\.com\/(.+)/);
    const isSSOAlreadyPrepended = link?.match(/\/sso\/(collegial|sebgroup)/);

    if (courseMatch && !isSSOAlreadyPrepended) {
      if (isRealm('seb')) {
        return `https://app.pluralsight.com/sso/sebgroup?returnUrl=${courseMatch[1]}`;
      } else if (isRealm(['cls', 'kompetens'])) {
        return `https://app.pluralsight.com/sso/collegial?returnUrl=${courseMatch[1]}`;
      }
    }
  }

  return link;
}

export enum ProviderAccessStatus {
  NO_ACCESS = 'no access',
  PENDING_ACCESS = 'pending approval',
  HAS_ACCESS = 'registered',
  NOT_APPROVED = 'not approved',
}

export function getProviderMeta(provider: string): ProviderMeta | undefined {
  return providers.find((p) => p.provider === provider);
}

const COLLEGIAL_PROVIDER_COLLECTIONS = [2, 3];

export function getCollectionProviderMeta(learningCollection: LearningCollection): ProviderMeta | undefined {
  const overrideCollegialProvider = COLLEGIAL_PROVIDER_COLLECTIONS.includes(learningCollection.id);
  return overrideCollegialProvider ? getProviderMeta(ProviderList.COLLEGIAL) : undefined;
}

export function getCardProviderMeta(card: Card): ProviderMeta | undefined {
  const overrideCollegialProvider = card.type === 'collection' && COLLEGIAL_PROVIDER_COLLECTIONS.includes(card.entityId);
  return overrideCollegialProvider ? getProviderMeta(ProviderList.COLLEGIAL) : getProviderMeta(card.provider as Provider);
}

export function getProviderIcon(provider: string): CollegialIconPropType | undefined {
  return providerIconMap[provider as Provider];
}

const providerIconMap: Partial<Record<Provider, CollegialIconPropType>> = {
  [ProviderList.PLURALSIGHT]: 'c-pluralsight-square',
  [ProviderList.COLLEGIAL]: 'c-collegial-square',
  [ProviderList.COURSERA]: 'c-coursera-square',
  [ProviderList.UDACITY]: 'c-udacity-square',
  [ProviderList.LEARNIFIER]: 'c-collegial-square', // learnifier intentionally uses the collegial logo.
  [ProviderList.LINKEDIN]: 'c-linkedin-square',
  [ProviderList.FINANSKOMPETENS]: 'c-finanskompetens-square',
  [ProviderList.IMD]: 'c-imd-square',
  [ProviderList.ENGINEER_FOUR_POINT_ZERO]: 'c-engineer-four-point-zero-square',
  [ProviderList.TEKNISKA_COLLEGE]: 'c-tekniska-college-square',
  [ProviderList.MOLK]: 'c-mölk-square',
  [ProviderList.KOMPETENS_EXPRESS]: 'c-kompetens-express-square',
  [ProviderList.INTERMEZZON]: 'c-intermezzon-square',
  [ProviderList.LEXICON]: 'c-lexicon-square',
  [ProviderList.AUTOLIV]: 'c-autoliv-square',
  [ProviderList.LANSFORSAKRINGAR]: 'c-lansforsakringar-square',
  [ProviderList.IMPARTA]: 'c-imparta-square',
};

export interface ProviderMeta {
  name: string;
  provider: Provider;
  feature?: FeatureName;
  requiresExplicitRegistration: boolean;
  titleKey: string;
  descriptionKey: string;
  logo: string;
  requiresLink: boolean;
}

export const providers: ProviderMeta[] = [
  {
    name: 'Learnifier',
    provider: ProviderList.LEARNIFIER,
    requiresExplicitRegistration: false,
    titleKey: '',
    descriptionKey: '',
    logo: '',
    requiresLink: false,
  },
  {
    get name(): string {
      // Keeping this check as SEB changes to Pluralsight copy is common
      return isRealm('seb') ? 'Pluralsight' : 'Pluralsight';
    },
    provider: ProviderList.PLURALSIGHT,
    feature: FEATURE.PROVIDER_PLURALSIGHT,
    requiresExplicitRegistration: true,
    titleKey: 'pluralsight-title',
    descriptionKey: '',
    logo: '',
    requiresLink: true,
  },
  {
    name: 'Collegial',
    provider: ProviderList.COLLEGIAL,
    feature: FEATURE.PROVIDER_COLLEGIAL,
    requiresExplicitRegistration: false,
    titleKey: 'Learn continuously with Collegial',
    descriptionKey: 'Personalized learning programs to help you reach your full potential',
    logo: '',
    requiresLink: false,
  },
  {
    name: 'Coursera',
    provider: ProviderList.COURSERA,
    feature: FEATURE.PROVIDER_COURSERA,
    requiresExplicitRegistration: true,
    titleKey: 'coursera-title',
    descriptionKey: '',
    logo: '',
    requiresLink: true,
  },
  {
    name: 'Udacity',
    provider: ProviderList.UDACITY,
    feature: FEATURE.PROVIDER_UDACITY,
    requiresExplicitRegistration: false,
    titleKey: 'udacity-title',
    descriptionKey: 'udacity-description',
    logo: '',
    requiresLink: true,
  },
  {
    name: 'Linkedin',
    provider: ProviderList.LINKEDIN,
    feature: FEATURE.PROVIDER_LINKEDIN,
    requiresExplicitRegistration: false,
    titleKey: 'linkedin-title',
    descriptionKey: 'linkedin-description',
    logo: '',
    requiresLink: true,
  },
  {
    name: 'Finanskompetens',
    provider: ProviderList.FINANSKOMPETENS,
    feature: FEATURE.PROVIDER_FINANSKOMPETENS,
    requiresExplicitRegistration: false,
    titleKey: 'finanskompetens-title',
    descriptionKey: 'finanskompetens-description',
    logo: '',
    requiresLink: false,
  },
  {
    name: 'IMD',
    provider: ProviderList.IMD,
    feature: FEATURE.PROVIDER_IMD,
    requiresExplicitRegistration: false,
    titleKey: 'World-leading executive education by IMD',
    descriptionKey: 'Programs that develop leaders to transform organizations.',
    logo: '',
    requiresLink: false,
  },
  {
    name: 'Custom link',
    provider: ProviderList.CUSTOM_LINK,
    feature: FEATURE.PROVIDER_CUSTOM_LINK,
    requiresExplicitRegistration: false,
    titleKey: 'custom-link-title',
    descriptionKey: '',
    logo: '',
    requiresLink: true,
  },
  {
    name: 'Ingenjör 4.0',
    provider: ProviderList.ENGINEER_FOUR_POINT_ZERO,
    feature: FEATURE.PROVIDER_ENGINEER_FOUR_POINT_ZERO,
    requiresExplicitRegistration: false,
    titleKey: 'engineer-four-point-zero-title',
    descriptionKey: 'engineer-four-point-zero-description',
    logo: '',
    requiresLink: false,
  },
  {
    name: 'Göteborgs Tekniska College',
    provider: ProviderList.TEKNISKA_COLLEGE,
    feature: FEATURE.PROVIDER_TEKNISKA_COLLEGE,
    requiresExplicitRegistration: false,
    titleKey: 'tekniska-college-title',
    descriptionKey: 'tekniska-college-description',
    logo: '',
    requiresLink: false,
  },
  {
    name: 'Mölk',
    provider: ProviderList.MOLK,
    feature: FEATURE.PROVIDER_MOLK,
    requiresExplicitRegistration: false,
    titleKey: 'mölk-title',
    descriptionKey: 'mölk-description',
    logo: '',
    requiresLink: false,
  },
  {
    name: 'Kompetens Express',
    provider: ProviderList.KOMPETENS_EXPRESS,
    feature: FEATURE.PROVIDER_KOMPETENS_EXPRESS,
    requiresExplicitRegistration: false,
    titleKey: 'kompetens-express-title',
    descriptionKey: 'kompetens-express-description',
    logo: '',
    requiresLink: false,
  },
  {
    name: 'Intermezzon',
    provider: ProviderList.INTERMEZZON,
    feature: FEATURE.PROVIDER_INTERMEZZON,
    requiresExplicitRegistration: false,
    titleKey: 'intermezzon-title',
    descriptionKey: 'intermezzon-description',
    logo: '',
    requiresLink: false,
  },
  {
    name: 'Lexicon',
    provider: ProviderList.LEXICON,
    feature: FEATURE.PROVIDER_LEXICON,
    requiresExplicitRegistration: false,
    titleKey: 'lexicon-title',
    descriptionKey: 'lexicon-description',
    logo: '',
    requiresLink: false,
  },
  {
    name: 'Autoliv',
    provider: ProviderList.AUTOLIV,
    feature: FEATURE.PROVIDER_AUTOLIV,
    requiresExplicitRegistration: false,
    titleKey: 'autoliv-title',
    descriptionKey: '',
    logo: '',
    requiresLink: true,
  },
  {
    name: 'Lansforsakringar',
    provider: ProviderList.LANSFORSAKRINGAR,
    feature: FEATURE.PROVIDER_LANSFORSAKRINGAR,
    requiresExplicitRegistration: false,
    titleKey: 'lansforsakringar-title',
    descriptionKey: '',
    logo: '',
    requiresLink: true,
  },
  {
    name: 'Imparta',
    provider: ProviderList.IMPARTA,
    feature: FEATURE.PROVIDER_IMPARTA,
    requiresExplicitRegistration: true,
    titleKey: 'imparta-title',
    descriptionKey: 'imparta-description',
    logo: '',
    requiresLink: true,
  },
];

export const learnifier: Record<string, Array<Provider>> = {
  enabledForProviders: [
    ProviderList.COLLEGIAL,
    ProviderList.ENGINEER_FOUR_POINT_ZERO,
    ProviderList.MOLK,
    ProviderList.TEKNISKA_COLLEGE,
    ProviderList.KOMPETENS_EXPRESS,
    ProviderList.INTERMEZZON,
    ProviderList.LEXICON,
  ],
};

export const GET_LEARNIFIER_COURSES = gql`
  query getLearnifierCourses {
    getLearnifierCourses {
      name
      host
      organizationId
      projectId
      description
      title
      picture
      status
      adminLink
      locale
      timezone
      country
      resource
    }
  }
`;
