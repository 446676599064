import { MetaLabel } from '@/ui/Label';
import { utcToLocalDate } from '@/utils/time';
import { FilterStatus } from '@/management/pages/common/filter/types';

export const LearnerStatus = ({
  row: { status, completedAt },
}: {
  row: { status: FilterStatus; completedAt?: string };
}): JSX.Element => {
  if (status === 'completed')
    return <MetaLabel icon={['far', 'check']} text={completedAt ? utcToLocalDate(new Date(completedAt)) : ''} />;
  if (status === 'not completed') return <>Not completed</>;

  return <>Expired</>;
};
