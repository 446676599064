import { useEffect } from 'react';

import { PageControls, usePageControls } from '@/components/Table/hooks/usePageControls';

export interface PaginationMeta<T> {
  isPaginationEnabled: boolean;
  totalPages: number;
  page: number;
  setPage: (page: number) => void;
  itemsSlice: T[];
}

interface PaginationParams<T> {
  items: readonly T[];
  pageControls?: PageControls;
  onPageChange?: (page: number | undefined) => void;
  pageSize?: number;
  total?: number;
  shouldForceResetPagination?: boolean;
}

export const DEFAULT_DISABLED_PAGINATION_META: PaginationMeta<any> = {
  isPaginationEnabled: false,
  totalPages: 1,
  page: 1,
  setPage: () => undefined,
  itemsSlice: [],
};

export function usePagination<T>({
  items,
  pageControls,
  onPageChange,
  pageSize = 20,
  total,
  shouldForceResetPagination,
}: PaginationParams<T>): PaginationMeta<T> {
  const [page, setPage] = usePageControls({ controls: pageControls, onPageChange });

  useEffect(() => {
    if (!onPageChange && shouldForceResetPagination) {
      setPage(1);
    }
  }, [items]);

  const totalPages = pageSize ? Math.ceil((total ? total : items.length) / pageSize) : 1;
  const isPaginationEnabled = !!(pageSize && totalPages > 1);

  const shouldSliceItems = !onPageChange && pageSize && !pageControls;
  const sliceStart = (page - 1) * pageSize;
  const sliceEnd = (page - 1) * pageSize + pageSize;

  const itemsSlice: Array<T> = shouldSliceItems ? items.slice(sliceStart, sliceEnd) : items.slice();

  return {
    isPaginationEnabled,
    totalPages,
    page,
    setPage,
    itemsSlice,
  };
}
