import styled from 'styled-components';
import { ReactNode } from 'react';

import image from './image.svg';

const Graphics = styled.img.attrs({ src: image })`
  margin-bottom: 40px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Heading = styled.h3`
  ${(p) => p.theme.typography.title3};
  color: ${(p) => p.theme.colors.neutral.onBackground};
  margin-bottom: 8px;
`;

const Subheading = styled.span`
  ${(p) => p.theme.typography.body3};
  color: ${(p) => p.theme.colors.neutral.onBackground};
  margin-bottom: 32px;
`;

export const EmptyStateNoData = ({
  title,
  subtitle,
  action,
}: {
  title: string;
  subtitle: string;
  action: ReactNode;
}): JSX.Element => (
  <Wrapper>
    <Graphics />
    <Heading>{title}</Heading>
    <Subheading>{subtitle}</Subheading>
    <>{action}</>
  </Wrapper>
);
