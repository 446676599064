import { CURRENT_YEAR } from '@/utils/currentYear';

export function checkIsExpired(expiredAt: string | undefined): boolean {
  const currentDate = new Date();
  const expiredDate = !!expiredAt ? new Date(expiredAt) : undefined;
  return !!expiredDate && expiredDate < currentDate;
}

export const getYear = (dateText: string): number => new Date(dateText).getFullYear();

export const getCurrentYear = (): number => CURRENT_YEAR;
