import React, { MutableRefObject, useEffect, useRef, useState } from 'react';

import { useOnClickOutside } from '@/hooks/useOnClickOutside';

export const useCourseDrawerItemEdit = ({
  title,
  onTitleChangeSubmit,
}: {
  onTitleChangeSubmit: (value: string) => void;
  title: string;
}): {
  updatedTitle: string;
  titleEditingEnabled: boolean;
  titleInputRef: MutableRefObject<HTMLInputElement | null>;
  containerRef: MutableRefObject<HTMLDivElement | null>;
  onUpdatedTitleChange: (value: string) => void;
  onEnableTitleEditing: () => void;
  onEditorKeyUp: React.KeyboardEventHandler<HTMLInputElement>;
} => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const titleInputRef = useRef<HTMLInputElement | null>(null);
  const [updatedTitle, setUpdatedTitle] = useState('');
  const [titleEditingEnabled, setTitleEditingEnabled] = useState(false);

  const handleUpdatedTitleChange = (value: string) => {
    setUpdatedTitle(value);
  };

  const handleTitleChangeSubmit = (value: string) => {
    onTitleChangeSubmit(value);
    handleUpdatedTitleChange('');
    setTitleEditingEnabled(false);
  };

  useOnClickOutside(
    titleEditingEnabled,
    () => {
      if (typeof titleInputRef?.current?.value === 'string') {
        // we need to use ref here because we are in a closure of this hook
        handleTitleChangeSubmit(titleInputRef?.current?.value);
      }
    },
    containerRef
  );

  const handleEnableTitleEditing = () => {
    setTitleEditingEnabled(true);
    setUpdatedTitle(title);
  };

  const handleEditorKeyUp: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === 'Enter') {
      handleTitleChangeSubmit(updatedTitle);
    }
  };

  useEffect(() => {
    if (titleEditingEnabled && titleInputRef.current) {
      titleInputRef.current.focus();
    }
  }, [titleInputRef, titleEditingEnabled]);

  return {
    updatedTitle,
    titleEditingEnabled,
    titleInputRef,
    containerRef,
    onUpdatedTitleChange: handleUpdatedTitleChange,
    onEnableTitleEditing: handleEnableTitleEditing,
    onEditorKeyUp: handleEditorKeyUp,
  };
};
