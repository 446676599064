import { calculateFilteredMyLearnings, FilteredMyLearnings } from '@/pages/user/MyLearning/calculateFilteredMyLearnings';
import { useQueryParticipations } from '@/store/analytics/participations';
import { useCardCatalog } from '@/store/catalog';
import { useInventory } from '@/store/inventory';
import { useRootContext } from '@/store/RootContext';
import { useSpaces } from '@/store/spaces';
import { UsersPool } from '@/types/analytics';
import { Card } from '@/types/learning/card';
import { EventEnrollment, ProductType } from '@/types/learning/learning-catalog';

import { AssignmentCard, useAssignmentCards } from '.';

type UseMyLearningCardsHook = (query: { filterType?: ProductType; hideStrategicLearnings?: boolean }) => {
  cardFilterPredicate?: (_: Card) => boolean;
  ongoingCount: number;
  todoCount: number;
  filtered: FilteredMyLearnings;
  inventoryEnrollments: readonly EventEnrollment[];
  loading: boolean;
  featuredCards: Card[];
  assignmentCards: AssignmentCard[];
};

export const useMyLearningCards: UseMyLearningCardsHook = ({ filterType }) => {
  const { inventory, loading: loadingInventory } = useInventory();
  const { catalog, loading: loadingCatalog } = useCardCatalog({ showArchived: true });
  const { currentUser, loading: loadingUser } = useRootContext();
  const userIds = currentUser?.id ? [currentUser.id] : undefined;
  const { spaces, loading: loadingSpaces } = useSpaces();

  const hasLoadedUser = userIds?.length || !loadingUser;
  const hasLoadedSpaces = spaces?.length || !loadingSpaces;

  const { loading: loadingAssignmentCards, cards: assignmentCards } = useAssignmentCards({
    userId: currentUser?.id ?? 0,
    spaces,
    skip: !currentUser?.id,
  });

  const hasLoadedInventory = inventory.allProgress.length || !loadingInventory;
  const hasLoadedCatalog = catalog.cards.length || !loadingCatalog;
  const skip = !hasLoadedUser || !hasLoadedInventory || !hasLoadedCatalog;

  const { data: participationsData, loading: loadingParticipations } = useQueryParticipations(
    {
      userIdEq: currentUser?.id,
      includeCatalogData: true,
      usersPool: UsersPool.REALM,
      objectTypeNin: ['survey'],
    },
    skip
  );

  const hasLoadedAssignments = assignmentCards?.length || !loadingAssignmentCards;
  const hasLoadedParticipations = participationsData?.participations?.length || !loadingParticipations;

  const loading =
    !hasLoadedInventory ||
    !hasLoadedCatalog ||
    !hasLoadedAssignments ||
    !hasLoadedParticipations ||
    !hasLoadedSpaces ||
    !hasLoadedUser;

  if (loading) {
    return {
      loading,
      ongoingCount: 0,
      todoCount: 0,
      featuredCards: [],
      inventoryEnrollments: [],
      filtered: {
        assigned: {
          assignedCards: [],
          otherAssignments: [],
        },
        ongoing: {
          catalog: [],
          inventory: [],
        },
        completed: {
          catalog: [],
          inventory: [],
        },
      },
      assignmentCards: [],
    };
  }

  const { cardFilterPredicate, ongoingCount, filtered } = calculateFilteredMyLearnings({
    filterType,
    catalogCards: catalog.cards,
    inventory,
    serviceManagerCertificateCards: [],
    assignments: [],
    participations: participationsData?.participations,
  });

  const assignmentCount = assignmentCards.filter((x) => x.status !== 'completed' && x.status !== 'ended').length;

  return {
    cardFilterPredicate,
    ongoingCount,
    todoCount: assignmentCount,
    filtered,
    inventoryEnrollments: inventory.eventEnrollments,
    loading,
    featuredCards: catalog.cards.filter((s) => s.featured),
    assignmentCards,
  };
};
