import { RefObject, useRef, useState } from 'react';
import styled from 'styled-components';

import { Chip } from '@/ui/Chip/atoms/Chip';
import { Icon, IconPropType } from '@/ui/Icon';
import { useOnClickOutside } from '@/hooks/useOnClickOutside';
import { Mode } from '@/ui/Chip/atoms/Chip/types';

const ChipContainerStyle = styled.div`
  display: flex;
  position: relative;
`;

const ChipMenuStyled = styled.div`
  z-index: 1;
  background: white;
  position: absolute;
  top: 40px;
  left: 0;
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  border-radius: 4px;
  min-width: 240px;
  ${(p) => p.theme.shadow.primary[8]}
`;

const ChipMenuOptionStyled = styled.div`
  display: flex;
  padding: 8px 16px;
  cursor: pointer;
  align-items: center;
  background: white;

  &:hover {
    background: ${({ theme }) => theme.colors.states.hover1};
  }
`;

const OptionIconStyled = styled(Icon)`
  margin-right: 16px;
  color: ${(p) => p.theme.colors.neutral.onBackground};
`;

const OptionLabelStyled = styled.p`
  ${(p) => p.theme.typography.body3};
  color: ${(p) => p.theme.colors.neutral.onBackground};
`;

export type SelectChipOption = {
  label: string;
  value: string;
  icon?: IconPropType;
};

type SelectChipProps = {
  mode?: Mode;
  children?: JSX.Element | string;
  leadingIcon?: IconPropType;
  trailingIcon?: IconPropType;
  options: SelectChipOption[];
  onMenuItemClick: (option: SelectChipOption) => void;
};

export function SelectChip({
  leadingIcon,
  mode,
  options,
  trailingIcon,
  onMenuItemClick,
  children,
}: SelectChipProps): JSX.Element {
  const menuRef = useRef<HTMLDivElement>();
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  useOnClickOutside(isMenuOpened, () => setIsMenuOpened(false), menuRef as RefObject<HTMLDivElement>);

  const handleMenuButtonClick = () => {
    setIsMenuOpened((prevIsMenuOpened) => !prevIsMenuOpened);
  };

  const handleMenuItemClick = (option: SelectChipOption) => {
    setIsMenuOpened(false);
    onMenuItemClick(option);
  };

  return (
    <ChipContainerStyle>
      <Chip onClick={handleMenuButtonClick} mode={mode} leadingIcon={leadingIcon} trailingIcon={trailingIcon}>
        {children as JSX.Element}
      </Chip>
      {isMenuOpened && (
        <ChipMenuStyled ref={menuRef as RefObject<HTMLDivElement>}>
          {options?.map((option) => {
            return (
              <ChipMenuOptionStyled key={option?.value} onClick={() => handleMenuItemClick(option)}>
                <OptionIconStyled icon={option?.icon as IconPropType} />
                <OptionLabelStyled>{option.label}</OptionLabelStyled>
              </ChipMenuOptionStyled>
            );
          })}
        </ChipMenuStyled>
      )}
    </ChipContainerStyle>
  );
}
