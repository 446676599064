import styled from 'styled-components';

import { Participant as BaseParticipantInfo } from '@/administration/pages/Journey/common/atoms/Participant';

const RootStyled = styled(BaseParticipantInfo)`
  padding: 0;
`;

export const LearnerInfo = ({
  row: { email, profileImage, name },
}: {
  row: { email: string; profileImage?: string; name: string };
}): JSX.Element => {
  return <RootStyled name={name} email={email} profileImage={profileImage} />;
};
