import * as React from 'react';
import { Command as CommandPrimitive } from 'cmdk';
import styled from 'styled-components';

const CommandGroupStyled = styled(CommandPrimitive.Group)`
  overflow: hidden;

  & [cmdk-group-heading] {
    padding: 16px 16px;
    ${({ theme }) => theme.typography.overline};
  }
`;

export const CommandGroup = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Group>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Group>
>(({ className, ...props }, ref) => <CommandGroupStyled ref={ref} className={className} {...props} />);

CommandGroup.displayName = CommandPrimitive.Group.displayName;
