import { EmptyStateAfterProcessed, EmptyStateNoData } from '@/management/components/EmptyState';
import { AutoTable } from '@/ui/AutoTable';
import { AnyDataItem, AutoTableProps } from '@/ui/AutoTable/types';

export const renderTable = <T extends AnyDataItem, TSortBy extends string>({
  total,
  processedTotal,
  tableProps,
  onFiltersClear,
  heading,
  subheading,
  isFiltered,
}: {
  total: number;
  processedTotal: number;
  tableProps: AutoTableProps<T, TSortBy>;
  onFiltersClear: () => void;
  heading?: string;
  subheading?: string;
  isFiltered: boolean;
}): JSX.Element => {
  if (!total && !isFiltered) return <EmptyStateNoData heading={heading} subheading={subheading} />;

  if (!processedTotal && isFiltered) return <EmptyStateAfterProcessed onFiltersClear={onFiltersClear} />;

  return <AutoTable<T, TSortBy> {...tableProps} />;
};
