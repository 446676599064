import { gql, useQuery } from '@apollo/client';
import { ApolloError } from '@apollo/client/errors';

export type FeatureToggles = string[];

interface FeatureToggleRaw {
  name: string;
}

export const realmFeatureTogglesQuery = gql`
  query realmFeatureToggles($realm: String!) {
    realmFeatureToggles(realm: $realm) {
      name
    }
  }
`;

const userFeatureTogglesQuery = gql`
  query userFeatureToggles($realm: String!) {
    getCurrentUserFeatureToggles(realm: $realm) {
      name
    }
  }
`;

/**
 * Fetch realm specific public feature toggles
 * @param realm
 */
export const useRealmFeatureToggles = (
  realm: string,
  isLoggedIn: boolean
): {
  data: FeatureToggles;
  loading: boolean;
  error?: ApolloError;
} => {
  const { data, loading, error } = useQuery<{ realmFeatureToggles: FeatureToggleRaw[] }, { realm: string }>(
    realmFeatureTogglesQuery,
    {
      variables: { realm },
      skip: !!isLoggedIn,
    }
  );

  return {
    data: mapFeatureToggles(data?.realmFeatureToggles || []),
    loading,
    error,
  };
};

/**
 * Fetch user specific feature toggles
 * @param realm
 */
export const useUserFeatureToggles = (
  realm: string,
  isLoggedIn: boolean
): {
  data: FeatureToggles;
  loading: boolean;
  error?: ApolloError;
} => {
  const { data, loading, error } = useQuery<{ getCurrentUserFeatureToggles: FeatureToggleRaw[] }, { realm: string }>(
    userFeatureTogglesQuery,
    {
      variables: { realm },
      skip: !isLoggedIn,
    }
  );

  return {
    data: mapFeatureToggles(data?.getCurrentUserFeatureToggles || []),
    loading,
    error,
  };
};

function mapFeatureToggles(raw: FeatureToggleRaw[]): FeatureToggles {
  return raw.map((item) => item.name);
}
