import React from 'react';
import { Control, Controller, Validate } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Input } from '@/ui/TextField';
import { TextFieldName } from '@/features/course/admin/constants/settingsModal';
import { Course } from '@/features/course/admin/types/course';
import { ButtonGroup } from '@/ui/ButtonGroup';
import {
  ColumnStyled,
  StyledCaption,
  StyledLabel,
  WrapStyled,
} from '@/features/course/admin/molecules/CourseSettingsModalForm/CourseSettingsModalForm';

const ColonStyled = styled.span`
  ${(p) => p.theme.typography.title4};
  color: ${(p) => p.theme.colors.neutral.onBackground};
  padding: 4px 0 0 0;
`;

type CourseSettingsModalFieldTotalEffortProps = {
  control: Control<Course>;
  validate: Validate<string>;
};

export const CourseSettingsModalFieldTotalEffort = ({
  control,
  validate: validateTimeFields,
}: CourseSettingsModalFieldTotalEffortProps): JSX.Element => {
  const { t } = useTranslation('catalog');
  return (
    <WrapStyled>
      <ColumnStyled>
        <ButtonGroup gap="small" direction="vertical">
          <StyledLabel>{t('total-effort')}</StyledLabel>
          <StyledCaption>{t('total-effort-caption')}</StyledCaption>
        </ButtonGroup>
      </ColumnStyled>
      <ColumnStyled>
        <ButtonGroup direction="horizontal" verticalGap="small" align="start">
          <Controller
            control={control}
            name={TextFieldName.EffortHours}
            rules={{
              validate: (value) => {
                const parsedValue = parseInt(value);

                if (parsedValue < 0 || !validateTimeFields(value)) {
                  return false;
                }

                return true;
              },
            }}
            render={({ field: { onChange, name, value }, fieldState: { error } }) => (
              <Input
                onChange={onChange}
                name={name}
                value={String(value) || ''}
                type="number"
                fullWidth
                label={t('hours')}
                min={0}
                error={
                  error && {
                    message: t('hours-error'),
                  }
                }
              />
            )}
          />
          <ColonStyled>:</ColonStyled>
          <Controller
            control={control}
            name={TextFieldName.EffortMinutes}
            rules={{
              validate: (value) => {
                const parsedValue = parseInt(value);

                if (parsedValue < 0 || parsedValue > 59 || !validateTimeFields(value)) {
                  return false;
                }

                return true;
              },
            }}
            render={({ field: { onChange, name, value }, fieldState: { error } }) => (
              <Input
                onChange={onChange}
                name={name}
                value={String(value) || ''}
                type="number"
                fullWidth
                label={t('minutes')}
                min={0}
                max={59}
                error={
                  error && {
                    message: t('minutes-error'),
                  }
                }
              />
            )}
          />
        </ButtonGroup>
      </ColumnStyled>
    </WrapStyled>
  );
};
