/* eslint-disable import/no-unused-modules */
import { useTranslation } from 'react-i18next';

import { FilterSelect, Select } from '@/ui/Select';
import { ProgressRange } from './types';

type StatusFilterProps = {
  selectedProgressRange?: ProgressRange;
  onChange: (progressRange?: ProgressRange) => void;
  onTrailingIconClick?: () => void;
};

export const ProgressFilter = ({ selectedProgressRange, onChange, onTrailingIconClick }: StatusFilterProps): JSX.Element => {
  const { t } = useTranslation('managerDashboard');

  const progressRanges: {
    range: ProgressRange;
    displayValue: string;
  }[] = [
    {
      range: {
        min: 0,
        max: 20,
      },
      displayValue: '0% - 20%',
    },
    {
      range: {
        min: 20,
        max: 40,
      },
      displayValue: '20% - 40%',
    },
    {
      range: {
        min: 40,
        max: 60,
      },
      displayValue: '40% - 60%',
    },
    {
      range: {
        min: 60,
        max: 80,
      },
      displayValue: '60% - 80%',
    },
    {
      range: {
        min: 80,
        max: 100,
      },
      displayValue: '80% - 100%',
    },
  ];

  const selectedStatusDisplay =
    selectedProgressRange &&
    progressRanges.find(({ range: { min, max } }) => selectedProgressRange.min === min && selectedProgressRange.max === max)
      ?.displayValue;

  return (
    <FilterSelect
      placeholder={t('Progress')}
      value={selectedStatusDisplay || ''}
      onChange={(value) => onChange(progressRanges.find((range) => range.displayValue === value)?.range)}
      onTrailingIconClick={onTrailingIconClick}
      variant={'dropdown'}
    >
      <Select.List>
        {progressRanges.map(({ displayValue }) => (
          <Select.Option value={displayValue} key={displayValue}>
            {displayValue}
          </Select.Option>
        ))}
      </Select.List>
    </FilterSelect>
  );
};
