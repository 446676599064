import { createContext, Dispatch, SetStateAction } from 'react';

export type Question = {
  questionId: string;
  answersIds: Set<string>;
};

type QuizViewContextState = {
  learningActivityId: number;
  answeredQuestions: Question[];
  submitted: boolean;
  setAnsweredQuestions: Dispatch<SetStateAction<Question[]>>;
  refetch: () => void;
  retakeModalOpen: boolean;
  setRetakeModalOpen: Dispatch<SetStateAction<boolean>>;
};

export const QuizViewContext = createContext<QuizViewContextState>({
  learningActivityId: 0,
  answeredQuestions: [],
  submitted: false,
  setAnsweredQuestions: () => {},
  refetch: () => {},
  retakeModalOpen: false,
  setRetakeModalOpen: () => {},
});
