import { useSelected } from 'slate-react';
import styled from 'styled-components';
import React, { useState } from 'react';

const QuestionTitleStyled = styled.div`
  margin-bottom: 24px;
`;

const TitleInput = styled.input`
  ${({ theme }) => theme.typography.title3};
  height: 36px;
  background: transparent;
  width: 100%;
  border-radius: 4px;
  outline: none;
  border: none;
  padding: 0;

  &:focus {
    outline: 2px solid ${({ theme }) => theme.colors.primary.outline};
  }

  ::placeholder {
    color: ${({ theme }) => theme.colors.neutral.disabled};
    opacity: 1; /* Firefox */
  }
`;

const TitleText = styled.div`
  display: flex;
  align-items: center;
  height: 36px;
  color: ${({ theme }) => theme.colors.neutral.onBackground};
  ${({ theme }) => theme.typography.title3};
`;

type QuestionTitleProps = {
  title?: string;
  onTitleChange: (newTitle: string) => void;
};

export function QuestionTitle({ title, onTitleChange }: QuestionTitleProps): JSX.Element {
  const selected = useSelected();
  /**
   * Triggering onTitle change directly will cause global re-rendering of the node,
   * leading to input focus loosing it's actual position.
   *
   * The workaround is to keep the "inner state" for the input, and trigger onTitleChange only onBlur
   */
  const [inputValue, setInputValue] = useState(title);

  return (
    <QuestionTitleStyled>
      {selected ? (
        <TitleInput
          value={inputValue}
          onChange={(event) => setInputValue(event?.target?.value)}
          onBlur={(event) => {
            event.preventDefault();
            onTitleChange(event?.target?.value);
          }}
          placeholder="Write your question"
        />
      ) : (
        <TitleText>{title}</TitleText>
      )}
    </QuestionTitleStyled>
  );
}
