import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { ManagedTeam } from '@/management/store/managedTeams';
import { FilterSelect, Select } from '@/ui/Select';
import { Checkbox } from '@/ui/Checkbox';

type TeamFilterProps = {
  teams: ManagedTeam[];
  selectedTeamIds: number[];
  onChange: (teamIds: number[]) => void;
  onTrailingIconClick?: () => void;
  singleSelect?: boolean;
};

type Team = ManagedTeam & { subteams: NestedTeams; nestingLevel: number };
type NestedTeams = Team[];

export const TeamFilter = ({
  teams,
  selectedTeamIds,
  onChange,
  onTrailingIconClick,
  singleSelect = false,
}: TeamFilterProps): JSX.Element => {
  const { t } = useTranslation('managerDashboard');

  const teamStructure = buildTeamStructure(cleanParentTeams(teams));
  let label = '';
  if (selectedTeamIds.length > 0) {
    label += teams.find((t) => t.id === selectedTeamIds[0])?.name ?? '';
  }
  if (selectedTeamIds.length > 1) {
    label += ` (+${selectedTeamIds.length - 1} more)`;
  }

  const handleChange = (changedTeamId: number) => {
    const selection = new Set(selectedTeamIds);
    if (singleSelect) {
      selection.clear();
      selection.add(changedTeamId);
    } else if (selectedTeamIds.includes(changedTeamId)) {
      selection.delete(changedTeamId);
    } else {
      selection.add(changedTeamId);
    }
    onChange(Array.from(selection));
  };

  return (
    <FilterSelect
      placeholder={t('Team') ?? ''}
      value={label}
      onChange={(id) => typeof id === 'string' && handleChange(parseInt(id, 10))}
      onTrailingIconClick={onTrailingIconClick}
      variant={'dropdown'}
      autoclose={false}
    >
      <Select.List>
        <Teams teams={teamStructure} selectedTeamIds={selectedTeamIds} />
      </Select.List>
    </FilterSelect>
  );
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
const emptyFn = () => {};

/**
 * Recursively render nested teams.
 */
const Teams = ({ teams, selectedTeamIds }: { teams: NestedTeams; selectedTeamIds: number[] }): JSX.Element => {
  return (
    <>
      {teams.map(({ id, name, subteams }) => (
        <React.Fragment key={id}>
          <TeamOption value={id.toString()} key={id}>
            <Checkbox checked={selectedTeamIds.includes(id)} onChange={emptyFn} />
            <div>{name}</div>
          </TeamOption>
          <Subteams>
            <Teams teams={subteams} selectedTeamIds={selectedTeamIds}></Teams>
          </Subteams>
        </React.Fragment>
      ))}
    </>
  );
};

const TeamOption = styled(Select.Option)`
  display: flex;
  gap: 8px;
  white-space: pre;
`;

const Subteams = styled.div`
  padding-left: 16px;
`;

/**
 * For teams that have parents but those parents are not listed among the teams
 * managed by the user, just remove those parents: assume they're top-level teams.
 */
const cleanParentTeams = (teams: ManagedTeam[]): ManagedTeam[] => {
  return teams.map((team) => ({
    ...team,
    parentTeamId: teams.some((anyTeam) => team.parentTeamId === anyTeam.id) ? team.parentTeamId : null,
  }));
};

/**
 * Recursively create a nested structure of teams and their subteams.
 */
const buildTeamStructure = (teams: ManagedTeam[], parentTeamId: number | null = null, nestingLevel = 0): NestedTeams => {
  teams = teams.map((team) => ({
    ...team,
    parentTeamId: teams.some((anyTeam) => team.parentTeamId === anyTeam.id) ? team.parentTeamId : null,
  }));
  return teams
    .filter((t) => t.parentTeamId === parentTeamId)
    .map((t) => ({
      ...t,
      nestingLevel,
      subteams: buildTeamStructure(teams, t.id, nestingLevel + 1),
    }));
};
