import { config } from '@/config';
import { IconPropType } from '@/ui/Icon';
import { FeatureName } from '@/feature-toggles';
import { AccessLevel } from '@/store/currentUser';

export interface MenuOption {
  label: MENU_LABELS;
  link?: string;
  linkFactory?: (isFeatureEnabled: (f: FeatureName) => boolean) => string;
  icon?: IconPropType;
  admin?: boolean;
  manager?: boolean;
  useNativeLink?: boolean;
  newTab?: boolean;
}

export type MobileMenuItem = MenuOption & {
  children?: MobileMenuItem[];
  count?: boolean;
};

export enum MENU_LABELS {
  LEARNINGS = 'My learning',
  ONGOING = 'Ongoing learnings',
  ASSIGNED = 'Assigned',
  SAVED_BY_ME = 'Saved by me',
  COMPLETED = 'Completed',
  NOTIFICATIONS = 'Notifications',
  PROFILE = 'Profile',
  LOGOUT = 'Logout',
  ORDER_HISTORY = 'Order history',
  CONTENT = 'Content',
  COLLECTIONS = 'Collections',
  GROUPS = 'Groups',
  PEOPLE = 'People',
  ASSIGN = 'Assign',
  APPROVE = 'Approve',
  ANALYZE = 'Analyze',
  EXIT_ADMIN = 'Exit admin',
  EXIT = 'Exit',
  ADMIN = 'Admin',
  MANAGER = 'Manager',
  JOURNEYS = 'Journeys',
}

export enum NAV_LINKS {
  HOME = '/',
  PROFILE = '/user/profile',
  ADMIN = '/administration/catalog',
  MANAGER = '/management',
  ONBOARDING = '/onboarding',
  LOGIN = '/login',
  NOTIFICATIONS = '/notifications',
  ONGOING = '/user/my-learning/ongoing',
  ASSIGNED = '/user/my-learning/assigned',
  SAVED_BY_ME = '/user/my-learning/saved-by-me',
  COMPLETED = '/user/my-learning/completed',
  CONTENT = '/administration/catalog',
  COLLECTIONS = '/administration/collections',
  ORDER_HISTORY = '/administration/orders/history',
  LEARNINGS = '/learning',
  APPROVALS = '/administration/approvals',
  ASSIGN = '/administration/assign',
  GROUPS = '/administration/groups',
  USERS = '/administration/people',
  ANALYTICS = '/administration/analytics/dashboard',
  JOURNEYS = '/administration/journeys',
}

export const LOGOUT_LINK_AUTHV2 = `${config?.authv2?.logout_url}?return_url=${window.location.origin}`;

export const MAX_NOTIFICATIONS = 9;

export const ADMIN_MENU_OPITONS: MenuOption[] = [
  {
    label: MENU_LABELS.ORDER_HISTORY,
    link: NAV_LINKS.ORDER_HISTORY,
    icon: 'user',
    admin: true,
  },
  {
    label: MENU_LABELS.EXIT,
    link: NAV_LINKS.LEARNINGS,
    icon: 'sign-out',
    admin: false,
  },
];

export const getMenuOptions = (maxAccessLevel: AccessLevel): MenuOption[] => {
  return [
    {
      label: MENU_LABELS.PROFILE,
      link: NAV_LINKS.PROFILE,
      icon: 'user',
      admin: false,
    },
    maxAccessLevel === 'manager'
      ? {
          label: MENU_LABELS.ADMIN,
          link: NAV_LINKS.JOURNEYS, // managers are navigated straight to Administration Journeys
          icon: 'cog',
          admin: false,
          manager: true,
          newTab: true,
        }
      : {
          label: MENU_LABELS.ADMIN,
          link: NAV_LINKS.ADMIN, // admins are navigated to default Administration route
          icon: 'cog',
          admin: true,
          manager: false,
          newTab: true,
        },
    {
      label: MENU_LABELS.MANAGER,
      link: NAV_LINKS.MANAGER,
      icon: 'users',
      admin: false,
      manager: true,
      newTab: true,
    },
    {
      label: MENU_LABELS.LOGOUT,
      linkFactory: () => LOGOUT_LINK_AUTHV2,
      icon: 'sign-out',
      admin: false,
      useNativeLink: true,
    },
  ];
};

export const ADMIN_LINKS: (MobileMenuItem & { onlyMobile?: boolean })[] = [
  {
    label: MENU_LABELS.CONTENT,
    link: NAV_LINKS.CONTENT,
  },
  {
    label: MENU_LABELS.COLLECTIONS,
    link: NAV_LINKS.COLLECTIONS,
  },
  {
    label: MENU_LABELS.JOURNEYS,
    link: NAV_LINKS.JOURNEYS,
  },
  {
    label: MENU_LABELS.PEOPLE,
    link: NAV_LINKS.USERS,
  },
  {
    label: MENU_LABELS.ASSIGN,
    link: NAV_LINKS.ASSIGN,
  },
  {
    label: MENU_LABELS.APPROVE,
    count: true,
    link: NAV_LINKS.APPROVALS,
  },
  {
    label: MENU_LABELS.ANALYZE,
    link: NAV_LINKS.ANALYTICS,
  },
  {
    label: MENU_LABELS.ORDER_HISTORY,
    link: NAV_LINKS.ORDER_HISTORY,
    onlyMobile: true,
  },
  {
    label: MENU_LABELS.EXIT_ADMIN,
    icon: 'door-open',
    link: NAV_LINKS.LEARNINGS,
    onlyMobile: true,
  },
];

export const MOBILE_MENU: MobileMenuItem[] = [
  {
    label: MENU_LABELS.LEARNINGS,
    link: NAV_LINKS.ONGOING,
    children: [
      {
        label: MENU_LABELS.ONGOING,
        count: true,
        link: NAV_LINKS.ONGOING,
      },
      {
        label: MENU_LABELS.ASSIGNED,
        count: true,
        link: NAV_LINKS.ASSIGNED,
      },
      {
        label: MENU_LABELS.SAVED_BY_ME,
        link: NAV_LINKS.SAVED_BY_ME,
      },
      {
        label: MENU_LABELS.COMPLETED,
        link: NAV_LINKS.COMPLETED,
      },
    ],
  },
  {
    label: MENU_LABELS.NOTIFICATIONS,
    count: true,
    link: NAV_LINKS.NOTIFICATIONS,
  },
  {
    label: MENU_LABELS.PROFILE,
    icon: 'user',
    link: NAV_LINKS.PROFILE,
  },
  {
    label: MENU_LABELS.ADMIN,
    link: NAV_LINKS.ADMIN,
    icon: 'cog',
    admin: true,
    newTab: true,
  },
  {
    label: MENU_LABELS.MANAGER,
    link: NAV_LINKS.MANAGER,
    icon: 'chart-bar',
    admin: false,
    manager: true,
    newTab: true,
  },
  {
    label: MENU_LABELS.LOGOUT,
    icon: 'door-open',
    linkFactory: () => LOGOUT_LINK_AUTHV2,
    useNativeLink: true,
  },
];
