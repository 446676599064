/* eslint-disable import/no-unused-modules */

import React from 'react';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';

import { Button, IconButton } from '@/ui/Button';
import { Tooltip } from '@/ui/Tooltip';
import { makeNamespacedComponent } from '@/ui/utils';
import { Skeleton } from '@/ui/Skeleton';
import { ActionsStyled, HeadingWrapStyled, RootStyled, TitleStyled, StyledDisabledButton, StyledEnabledButton } from './styles';
import { useCurrentUserAccess } from '@/store/currentUser';
import { ADMIN_ROUTE_PATH } from '@/administration/constants/adminRoutePaths';
import { convertTitle } from '@/utils/misc';

type ViewNavbarProps = {
  onShowHideDrawerClick?: () => void;
  onSaveFavoritesClick?: () => void;
  onCloseClick: () => void;
  title: string;
  isSavedToMyLearning?: boolean;
};

const _ViewNavbar = ({
  title,
  onShowHideDrawerClick,
  onSaveFavoritesClick,
  onCloseClick,
  isSavedToMyLearning,
}: ViewNavbarProps): JSX.Element => {
  const { isAdmin } = useCurrentUserAccess();
  const { t } = useTranslation('catalog');
  const theme = useTheme();
  const navigate = useNavigate();
  const params = useParams();

  const iconColor = theme.tones.neutral[0];

  const handleInsightsClick = () => {
    navigate(
      ADMIN_ROUTE_PATH.COURSES_V2_INSIGHTS.replace(':learningId', convertTitle(params.id)).replace(
        ':name',
        convertTitle(title || '')
      )
    );
  };

  return (
    <RootStyled>
      <HeadingWrapStyled>
        {onShowHideDrawerClick && (
          <Tooltip title={t('list')} placement="bottom-start">
            <IconButton icon="list" color={iconColor} onClick={onShowHideDrawerClick} />
          </Tooltip>
        )}
        <TitleStyled>{title}</TitleStyled>
      </HeadingWrapStyled>
      <ActionsStyled>
        {onSaveFavoritesClick && (
          <>
            {!!isSavedToMyLearning ? (
              <Tooltip title={t('remove-my-learning')} placement="bottom">
                <StyledEnabledButton icon={'heart'} onClick={onSaveFavoritesClick} buttonVariant="dark" />
              </Tooltip>
            ) : (
              <Tooltip title={t('save-my-learning')} placement="bottom">
                <StyledDisabledButton icon={['far', 'heart']} onClick={onSaveFavoritesClick} buttonVariant="dark" />
              </Tooltip>
            )}
          </>
        )}
        {isAdmin && (
          <Tooltip title="Insights" placement="bottom">
            <IconButton icon={['far', 'poll']} color={iconColor} onClick={handleInsightsClick} />
          </Tooltip>
        )}
        <Tooltip title={t('close')} placement="bottom">
          <Button variant="secondary" icon={['far', 'arrow-right-from-bracket']} $iconPosition="end" onClick={onCloseClick}>
            Exit
          </Button>
        </Tooltip>
      </ActionsStyled>
    </RootStyled>
  );
};

const LoadingState = (): JSX.Element => (
  <RootStyled>
    <HeadingWrapStyled>
      <TitleStyled>
        <Skeleton width={244} height={36} />
      </TitleStyled>
      <Skeleton width={36} height={36} />
    </HeadingWrapStyled>
    <ActionsStyled>
      <Skeleton width={36} height={36} />
    </ActionsStyled>
  </RootStyled>
);

export const ViewNavbar = makeNamespacedComponent(_ViewNavbar, {
  LoadingState,
});
