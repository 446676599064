import styled from 'styled-components';

import { shortenName } from '@/utils/text';
import { InputChip } from '@/ui/Chip';
import { Avatar } from '@/ui/Avatar';

const ChipContentOwnerContentStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ChipContentOwnerDisplayedName = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
`;

export type SelectedUser = {
  id: number;
  firstname?: string;
  lastname?: string;
  email: string;
  profileImage?: string;
};

type CourseSettingsModalFieldSelectedUserChipProps = {
  user: SelectedUser;
  onRemoveUserClick: (e: React.MouseEvent<Element, MouseEvent>) => void;
};

export const CourseSettingsModalFieldSelectedUserChip = ({
  user,
  onRemoveUserClick,
}: CourseSettingsModalFieldSelectedUserChipProps): JSX.Element => {
  const username = user?.firstname || user?.lastname ? `${user?.firstname || ''} ${user?.lastname || ''}` : user?.email;
  const shortName = shortenName(username || '');

  return (
    <div key={user.id}>
      <InputChip
        avatar
        label={
          <ChipContentOwnerContentStyled>
            <Avatar text={shortName} size="small" image={user.profileImage} />
            <ChipContentOwnerDisplayedName>{username}</ChipContentOwnerDisplayedName>
          </ChipContentOwnerContentStyled>
        }
        hasCloseIcon
        onTrailingIconClick={onRemoveUserClick}
      />
    </div>
  );
};
