import styled from 'styled-components';

import { UserRoleConnection } from '@/types/learning';
import { ColumnStyled, StyledLabel } from '@/features/course/admin/molecules/CourseSettingsModalForm/CourseSettingsModalForm';
import { Command, CommandEmpty, CommandInput, CommandItem, CommandList, CommandLoading } from '@/ui/Command';
import { ComboboxTrigger } from '@/ui/Combobox';
import { Popover, PopoverContent, PopoverTrigger } from '@/ui/Popover/Popover';
import { CourseSettingsModalFieldContentOwnerRow } from '@/features/course/admin/atoms/CourseSettingsModalFieldContentOwnerRow';
import { CourseSettingsModalFieldSelectedUserChip } from '@/features/course/admin/atoms/CourseSettingsModalFieldSelectedUserChip';
import { useCourseSettingsModalFieldConnectionState } from '@/features/course/admin/molecules/CourseSettingsModalFieldConnectionsRow/useCourseSettingsModalFieldConnectionState';
import { Skeleton } from '@/ui/Skeleton';

const PopoverContentStyled = styled(PopoverContent)`
  width: 336px;
`;

const AttributeFieldRootStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
  align-self: stretch;
`;

const SelectedUsersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export enum ConnectionUserRoleAction {
  Add = 'add',
  Remove = 'remove',
}

export type CourseSettingsModalFieldConnectionsRowProps = {
  label: string;
  selectedUserRolesConnections: UserRoleConnection[];
  spaceId: number;
  onUsersRoleChange: (ops: { userId: number; action: ConnectionUserRoleAction }) => void;
};

export const CourseSettingsModalFieldConnectionsRow = (props: CourseSettingsModalFieldConnectionsRowProps): JSX.Element => {
  const { label } = props;

  const {
    popoverOpen,
    selectedUsers,
    loadingSearchedUsers,
    loadingSavedUsers,
    searchPhrase,
    notSelectedUsers,
    setPopoverOpen,
    onAddUser,
    onSearchPhraseChange,
    onRemoveUser,
  } = useCourseSettingsModalFieldConnectionState(props);

  return (
    <AttributeFieldRootStyled>
      <ColumnStyled>
        <StyledLabel>{label}</StyledLabel>
      </ColumnStyled>
      <ColumnStyled>
        {loadingSavedUsers ? (
          <Skeleton height={44} />
        ) : (
          <Popover open={popoverOpen} onOpenChange={setPopoverOpen}>
            <PopoverTrigger asChild>
              <ComboboxTrigger
                comboboxOpen={popoverOpen}
                label={label}
                onClick={() => {
                  setPopoverOpen(true);
                }}
              >
                {selectedUsers.length ? (
                  <SelectedUsersContainer>
                    {selectedUsers.map((user) => {
                      return (
                        <CourseSettingsModalFieldSelectedUserChip
                          key={user.id}
                          user={user}
                          onRemoveUserClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onRemoveUser(user.id);
                            setPopoverOpen(false);
                          }}
                        />
                      );
                    })}
                  </SelectedUsersContainer>
                ) : null}
              </ComboboxTrigger>
            </PopoverTrigger>
            <PopoverContentStyled align="start">
              <Command shouldFilter={false}>
                <CommandInput
                  value={searchPhrase}
                  onValueChange={(value) => onSearchPhraseChange(value)}
                  placeholder="Search name or email"
                />
                <CommandList>
                  {!loadingSearchedUsers && <CommandEmpty>No results found.</CommandEmpty>}
                  {loadingSearchedUsers && <CommandLoading />}
                  {notSelectedUsers?.map((user) => {
                    return (
                      <CommandItem
                        key={user.id}
                        value={String(user.id)}
                        onSelect={() => {
                          onAddUser(user);
                          setPopoverOpen(false);
                        }}
                        $selected={Boolean(selectedUsers.find((iteratedUser) => iteratedUser.id === user.id))}
                      >
                        <CourseSettingsModalFieldContentOwnerRow
                          email={user.email}
                          firstname={user.firstname}
                          profileImage={user.profileImage}
                          lastname={user.lastname}
                        />
                      </CommandItem>
                    );
                  })}
                </CommandList>
              </Command>
            </PopoverContentStyled>
          </Popover>
        )}
      </ColumnStyled>
    </AttributeFieldRootStyled>
  );
};
