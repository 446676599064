import React, { useContext, forwardRef } from 'react';
import { SimpleTreeItemWrapper } from 'dnd-kit-sortable-tree';
import { useDndContext } from '@dnd-kit/core';
import styled from 'styled-components';

import { RootStyled as ItemContainerStyled } from '@/features/course/admin/molecules/CourseDrawerStandaloneItem/CourseDrawerStandaloneItem';
import { CourseDrawerStandaloneItem } from '@/features/course/admin/molecules/CourseDrawerStandaloneItem';
import { PageVariant } from '@/features/course/admin/types/page';
import { CourseDrawerModuleItem } from '@/features/course/admin/molecules/CourseDrawerModuleItem';
import { CourseDrawerModuleEmptyState } from '@/features/course/admin/atoms/CourseDrawerModuleEmptyState';
import { TreeDrawerContext } from '@/features/course/admin/contexts/TreeDrawerContext';
import { CustomItemComponentProps, ItemData } from '@/features/course/admin/types/treeDrawer';

export const TreeItemStyled = styled.div`
  margin-top: 4px;

  & .dnd-sortable-tree_simple_wrapper {
    padding-left: 0 !important;
  }

  // This selector detects the dragged item container, which is being taken out of regular dom
  & .dnd-sortable-tree_simple_wrapper.dnd-sortable-tree_simple_clone {
    width: 100%;
    // This selector detects the dragged item container content
    & .dnd-sortable-tree_simple_tree-item {
      width: 100%;

      background: ${({ theme }) => theme.tones.neutral[99]};
      ${({ theme }) => theme.shadow.primary[64]};

      & ${ItemContainerStyled} {
        background: ${({ theme }) => theme.tones.neutral[99]} !important;
      }
    }
  }
`;

export const CourseTreeDrawerItem = forwardRef<HTMLDivElement, CustomItemComponentProps<ItemData>>((props, ref) => {
  const { onAddPage, onRemoveItem, onRenameItem, onActivateItem, onHandleCustomCollapse } = useContext(TreeDrawerContext);

  const { active } = useDndContext();
  const isUserDragging = !!active;

  return (
    <TreeItemStyled>
      <SimpleTreeItemWrapper {...props} ref={ref} showDragHandle={false} hideCollapseButton disableCollapseOnItemClick>
        {props.item.canHaveChildren ? (
          <CourseDrawerModuleItem
            id={String(props.item.id)}
            title={props.item.data.value}
            type={PageVariant.MODULE}
            onAddPage={(type, parentActivityId) => {
              onAddPage(type, parentActivityId);
            }}
            onRemoveClick={(id) => {
              onRemoveItem(id);
            }}
            onTitleChange={(name) => {
              onRenameItem(String(props.item.id), name);
            }}
            onCollapaseClick={() => {
              props.onCollapse?.();
              onHandleCustomCollapse({ targetId: props.item.id, customCollapsed: !props.item.customCollapsed });
            }}
            collapsed={props.item.customCollapsed}
          >
            {props.item?.children?.length === 0 && !props.item.customCollapsed && !isUserDragging ? (
              <CourseDrawerModuleEmptyState />
            ) : null}
          </CourseDrawerModuleItem>
        ) : (
          <CourseDrawerStandaloneItem
            id={String(props.item.id)}
            title={props.item.data.value}
            type={props.item.data.type}
            isChild={!!props.item?.parentId || !!props?.parent?.id} // props.parent.id is set for ghost items
            onRemoveClick={(id) => {
              onRemoveItem(id);
            }}
            active={props.item.data.active}
            onSelect={(id) => {
              onActivateItem(id);
            }}
            onTitleChange={(name) => {
              onRenameItem(String(props.item.id), name);
            }}
          />
        )}
      </SimpleTreeItemWrapper>
    </TreeItemStyled>
  );
});

CourseTreeDrawerItem.displayName = 'CourseTreeDrawerItem';
