/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { CellFormatterComponent } from '../types';
import { utcToLocalDateСustomFormat } from '@/utils/time';

const PrettyDate = styled.time`
  ${p => p.theme.typography.body3};
  color: ${p => p.theme.colors.neutral.onBackground};
`;

export const DateCellFormatter: CellFormatterComponent = ({ data }) => {
  if (data && dayjs(data as any).isValid()) {
    const date = new Date(data as any);
    return <PrettyDate>{utcToLocalDateСustomFormat(date, 'YYYY-MM-DD')}</PrettyDate>;
  }
  return null;
};
