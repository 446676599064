import React from 'react';
import { useTranslation } from 'react-i18next';

import { HistoricCompletedCourse, useHistoricCompletedCourses } from '@/store/inventory';
import { LoadingHero } from '@/component/LoadingHero';
import { AutoTable } from '@/components/Table/AutoTable';
import { CellRenderProps, TableDeclaration } from '@/components/Table/types';
import { Icon } from '@/components/Icon';
import '../myLearning.scss';
import { RouterLinkButton } from '@/components/Button/RouterLinkButton';
import { ButtonList } from '@/components/Button/ButtonList';

const renderType = ({ element: course }: CellRenderProps<HistoricCompletedCourse>) => (
  <span className="overline">{course.type}</span>
);
const renderCompletion = ({ element: course }: CellRenderProps<HistoricCompletedCourse>) => (
  <span>
    <Icon icon="check" fixedWidth styleName="check-mark-icon" />
    {course.completed}
  </span>
);

const renderName = ({ element: course }: CellRenderProps<HistoricCompletedCourse>) => (
  <span className="has-text-black">{course.name}</span>
);

export const HistoricCompletedCourses = (): JSX.Element => {
  const { t } = useTranslation('myLearning');
  const { courses, loading: loadingCourses } = useHistoricCompletedCourses();

  if (loadingCourses) {
    return <LoadingHero />;
  }

  const TABLE_DECLARATION: TableDeclaration<HistoricCompletedCourse> = [
    {
      render: renderName,
      title: t('Name', { ns: 'common' }),
    },
    {
      render: renderType,
      title: t('Type'),
    },
    {
      render: renderCompletion,
      title: t('Completed'),
    },
  ];

  return (
    <>
      <section className="hero is-cls is-padded">
        <div className="hero-body">
          <div className="container">
            <div className="grid">
              <div className="gc gc-9-d gc-8-t gc-8-l gc-8-p">
                <ButtonList padded="bottom">
                  <RouterLinkButton to="/user/my-learning/completed" $icon="arrow-left">
                    {t('Back', { ns: 'common' })}
                  </RouterLinkButton>
                </ButtonList>
                <h1 className="header1 has-bottom-margin">{t('Campus Online Data', { ns: 'navbar' })}</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section is-padded has-background-white-ter">
        <div className="container">
          <AutoTable<HistoricCompletedCourse> items={courses} keyProp="id" tableDecl={TABLE_DECLARATION} pageSize={25} />
        </div>
      </section>
    </>
  );
};
