/* eslint-disable import/no-unused-modules */
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useState } from 'react';

import { Space } from '@/types/learning/learning-catalog';
import { ButtonGroup } from '@/ui/ButtonGroup';
import { Modal } from '@/ui/Modal';
import { CourseSettingsModalFieldTeaser } from '@/features/course/admin/atoms/CourseSettingsModalFieldTeaser';
import { CourseSettingsModalFieldTitle } from '@/features/course/admin/atoms/CourseSettingsModalFieldTitle';
import { CourseSettingsModalFieldTotalEffort } from '@/features/course/admin/atoms/CourseSettingsModalFieldTotalEffort';
import { CourseSettingsModalFieldLevel } from '@/features/course/admin/atoms/CourseSettingsModalFieldLevel';
import { CourseSettingsModalFieldCategorySubject } from '@/features/course/admin/atoms/CourseSettingsModalFieldCategorySubject';
import { Course } from '@/features/course/admin/types/course';
import { CourseSettingsModalFieldUserVisibility } from '@/features/course/admin/atoms/CourseSettingsModalFieldUserVisibility';
import { TextFieldName } from '@/features/course/admin/constants/settingsModal';
import { ImageUpload } from '@/component/FileUpload/ImageUpload';
import { useCourseFormTimeValidation } from '@/features/course/admin/hooks/useCourseFormTimeValidation';
import { FormSpace } from '@/administration/components/common/form/FormSpace';
import { CourseSettingsModalDeleteButton } from '@/features/course/admin/atoms/CourseSettingsModalDeleteButton';
import { EditModularLayoutVariant } from '@/features/course/admin/templates/CourseLayout';
import { RLearningType } from '@/store/v2';
import { FEATURE, useFeatureEnabled } from '@/feature-toggles';
import { CourseSettingsModalFieldContentOwner } from '@/features/course/admin/molecules/CourseSettingsModalFieldContentOwner';
import { UnderlinedTabs } from '@/ui/Tabs';
import { CourseSettingsModalFieldAttributes } from '@/features/course/admin/molecules/CourseSettingsModalFieldAttributes';
import { CourseSettingsModalFieldConnections } from '@/features/course/admin/molecules/CourseSettingsModalFieldConnections';

/** TODO Think about shared styles */
export const StyledLabel = styled.span`
  ${(p) => p.theme.typography.overline};
  color: ${(p) => p.theme.colors.neutral.onBackground};
`;

/** TODO Make it a part of a separate layout */
export const StyledCaption = styled.span`
  ${(p) => p.theme.typography.caption};
  color: ${(p) => p.theme.colors.neutral.onBackground};
`;

/** TODO Make it a part of a separate layout */
export const ColumnStyled = styled.div`
  width: 50%;
`;

/** TODO Make it a part of a separate layout */
export const ListStyled = styled.div`
  display: flex;
  gap: 32px;
  flex-direction: column;
  padding-top: 32px;
`;

/** TODO Make it a part of a separate layout */
export const WrapStyled = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
`;

/** TODO Move it to UI package */
export const Divider = styled.div`
  border-top: 1px solid ${(p) => p.theme.colors.neutral.container};
  width: 100%;
`;

const RootStyled = styled.div`
  display: flex;
  gap: 32px;
  flex-direction: row;
  padding: 32px 0;
`;

const TabContentContainerStyled = styled.div`
  height: calc(100vh - 330px);
  max-height: 600px;
  overflow-y: scroll;

  // Handling modal scrollbar padding
  margin-right: -32px;
  padding-right: 32px;
`;

export enum SettingsTab {
  General,
  Attributes,
}

type CourseSettingsModalFormProps = {
  onSubmit: () => void;
  onClose: () => void;
  onDelete: () => void;
  shouldShowSpaceSelect?: boolean;
  spacesToUse: Space[];
  layoutVariant: EditModularLayoutVariant;
};

export const CourseSettingsModalForm = ({
  onSubmit,
  onDelete,
  onClose,
  shouldShowSpaceSelect,
  spacesToUse,
  layoutVariant,
}: CourseSettingsModalFormProps): JSX.Element => {
  const { t } = useTranslation('catalog');
  const isCategorySubjectHidden = useFeatureEnabled(FEATURE.UI_CATEGORY_SUBJECT_HIDDEN);
  const attributesTabEnabled = useFeatureEnabled(FEATURE.UI_LEARNING_EDITOR_ATTRIBUTES_TAB);

  const [activeTab, setActiveTab] = useState(SettingsTab.General);

  const {
    control,
    getValues,
    setValue,
    trigger,
    watch,
    formState: { isSubmitted },
  } = useFormContext<Course>();

  const handleUploaded = (url?: string) =>
    setValue(TextFieldName.Image, url as never, { shouldDirty: true, shouldValidate: true });

  const [image, spaceId] = watch([TextFieldName.Image, TextFieldName.Space]);

  const validateTimeTextField = useCourseFormTimeValidation({
    getValues,
    trigger,
    watch,
    isSubmitted,
  });

  return (
    <>
      <Modal.Contents>
        <form onSubmit={onSubmit}>
          <UnderlinedTabs activeTab={activeTab} onActiveTabChange={setActiveTab}>
            <UnderlinedTabs.TriggersList>
              <UnderlinedTabs.Trigger label="General" value={SettingsTab.General} />
              {attributesTabEnabled && <UnderlinedTabs.Trigger label="Attributes " value={SettingsTab.Attributes} />}
            </UnderlinedTabs.TriggersList>
            <UnderlinedTabs.ContentsList>
              <UnderlinedTabs.Content value={SettingsTab.General}>
                <TabContentContainerStyled>
                  <RootStyled>
                    <ColumnStyled>
                      <ImageUpload setImage={handleUploaded} spaceId={spaceId} image={image} />
                    </ColumnStyled>
                    <ColumnStyled>
                      <ButtonGroup direction="vertical" verticalGap="medium">
                        {shouldShowSpaceSelect && (
                          <FormSpace disabled control={control} spacesToUse={spacesToUse} fieldName={TextFieldName.Space} />
                        )}
                        <CourseSettingsModalFieldTitle control={control} />
                        <CourseSettingsModalFieldTeaser control={control} />
                      </ButtonGroup>
                    </ColumnStyled>
                  </RootStyled>
                  <ListStyled>
                    <Divider />
                    {!isCategorySubjectHidden && (
                      <>
                        <CourseSettingsModalFieldCategorySubject control={control} />
                        <Divider />
                      </>
                    )}
                    <CourseSettingsModalFieldContentOwner spaceId={spaceId} control={control} />
                    <Divider />
                    <CourseSettingsModalFieldTotalEffort control={control} validate={validateTimeTextField} />
                    {layoutVariant === RLearningType.Course && (
                      <>
                        <Divider />
                        <CourseSettingsModalFieldUserVisibility />
                        <Divider />
                        <CourseSettingsModalFieldLevel control={control} />
                        <Divider />
                        <CourseSettingsModalDeleteButton onClick={onDelete} />
                      </>
                    )}
                  </ListStyled>
                </TabContentContainerStyled>
              </UnderlinedTabs.Content>
              <UnderlinedTabs.Content value={SettingsTab.Attributes}>
                <TabContentContainerStyled>
                  <RootStyled>
                    <CourseSettingsModalFieldConnections spaceId={spaceId} control={control} />
                  </RootStyled>
                  <Divider />
                  <RootStyled>
                    <CourseSettingsModalFieldAttributes control={control} />
                  </RootStyled>
                </TabContentContainerStyled>
              </UnderlinedTabs.Content>
            </UnderlinedTabs.ContentsList>
          </UnderlinedTabs>
        </form>
      </Modal.Contents>
      <Modal.Actions>
        <Modal.Action action={onClose}>{t('Cancel', { ns: 'common' })}</Modal.Action>
        <Modal.Action primary action={onSubmit}>
          {t('Save', { ns: 'common' })}
        </Modal.Action>
      </Modal.Actions>
    </>
  );
};
