import { useTranslation } from 'react-i18next';

import { LearningType, LearningTypeLabel } from '@/ui/Label';
import { LearningItemType } from '@/features/journey/admin/hooks/useLazyLearningItems';

const formatLabelLearningType = (learningType: string) => {
  if (learningType === 'practicalAssessment') return 'PRACTICAL_ASSESSMENT';

  return learningType?.replace('-', '_')?.toLocaleUpperCase();
};
export const JourneyInsightsTabsLearningItemsTypeFormatter = ({ row: { type } }: { row: LearningItemType }): JSX.Element => {
  const { t } = useTranslation('productType');

  return (
    <>
      <LearningTypeLabel text={t(type)} type={formatLabelLearningType(type) as LearningType} />
    </>
  );
};
