import React, { useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import { withProps } from '@udecode/cn';
import { Value } from '@udecode/plate-common';
import { ParagraphPlugin, usePlateEditor, TPlateEditor } from '@udecode/plate-common/react';
import { BoldPlugin, ItalicPlugin, UnderlinePlugin, StrikethroughPlugin } from '@udecode/plate-basic-marks/react';
import { HeadingPlugin } from '@udecode/plate-heading/react';
import { ListPlugin, BulletedListPlugin, NumberedListPlugin, ListItemPlugin } from '@udecode/plate-list/react';
import { LinkPlugin } from '@udecode/plate-link/react';
import { HEADING_KEYS } from '@udecode/plate-heading';
import { SlashPlugin, SlashInputPlugin } from '@udecode/plate-slash-command/react';
import { DndPlugin } from '@udecode/plate-dnd';
import { NodeIdPlugin } from '@udecode/plate-node-id';
import { SoftBreakPlugin, ExitBreakPlugin } from '@udecode/plate-break/react';

import { ParagraphElement } from '@/component/customEditorV2/ui/elements/ParagraphElement';
import { HeadingElement } from '@/component/customEditorV2/ui/elements/HeadingElement';
import { ListElement } from '@/component/customEditorV2/ui/elements/ListElement';
import { ListItemElement } from '@/component/customEditorV2/ui/elements/ListItemElement';
import { LinkElement } from '@/component/customEditorV2/ui/elements/LinkElement';
import { EmbeddedDocumentElement } from '@/component/customEditorV2/ui/elements/EmbeddedDocumentElement';
import { AudioElement } from '@/component/customEditorV2/ui/elements/AudioElement/AudioElement';
import { DocumentElement } from '@/component/customEditorV2/ui/elements/DocumentElement';
import { ImageElement } from '@/component/customEditorV2/ui/elements/ImageElement';
import { VideoElement } from '@/component/customEditorV2/ui/elements/Video/VideoElement';
import { BoldLeaf } from '@/component/customEditorV2/ui/leafs/BoldLeaf';
import { ItalicLeaf } from '@/component/customEditorV2/ui/leafs/ItalicLeaf';
import { StrikethroughLeaf } from '@/component/customEditorV2/ui/leafs/StrikethroughLeaf';
import { UnderlineLeaf } from '@/component/customEditorV2/ui/leafs/UnderlineLeaf';
import { LinkFloatingToolbar } from '@/component/customEditorV2/ui/pluginsAddons/LinkFloatingToolbar';
import { DocumentPlugin } from '@/component/customEditorV2/plugins/custom/documentPlugin';
import { VideoPlugin } from '@/component/customEditorV2/plugins/custom/videoPlugin';
import { QuizQuestionPlugin } from '@/component/customEditorV2/plugins/custom/quizQuestionPlugin';
import { QuizActionbarPlugin } from '@/component/customEditorV2/plugins/custom/quizActionbarPlugin';
import { QuizQuestionElement } from '@/component/customEditorV2/ui/elements/QuizQuestionElement/QuizQuestionElement';
import { QuizActionbarElement } from '@/component/customEditorV2/ui/elements/QuizActionbarElement/QuizActionbarElement';
import { SlashInputElement } from '@/component/customEditorV2/ui/elements/SlashInputElement';
import { ImagePlugin } from '@/component/customEditorV2/plugins/custom/imagePlugin';
import { withDraggables } from '@/component/customEditorV2/utils/withDraggables/withDraggables';
import { withPlaceholders } from '@/component/customEditorV2/ui/placeholder';
import { CustomEditorV2ToggledFeatures } from '@/component/customEditorV2';
import { LoggingPlugin } from '@/component/customEditorV2/plugins/custom/loggingPlugin';
import { DeleteBackwardPlugin } from '@/component/customEditorV2/plugins/custom/deleteBackwardPlugin';
import { TrimEndParagraphsPlugin } from '@/component/customEditorV2/plugins/custom/trimEndEmptyParagraphs';
import { EmbeddedDocumentPlugin } from '@/component/customEditorV2/plugins/custom/embeddedDocumentPlugin';
import { AudioPlugin } from '@/component/customEditorV2/plugins/custom/audioPlugin';

export const OVERRIDE_PLUGINS = {
  [ImagePlugin.key]: {
    node: {
      type: 'image',
    },
  },
  [ParagraphPlugin.key]: {
    node: {
      type: 'paragraph',
    },
  },
  [StrikethroughPlugin.key]: {
    node: {
      type: 'lineThrough',
    },
  },
  [HEADING_KEYS.h1]: {
    node: {
      type: 'heading',
    },
  },
  [HEADING_KEYS.h2]: {
    node: {
      type: 'heading2',
    },
  },
  [BulletedListPlugin.key]: {
    node: {
      type: 'bulleted-list',
    },
  },
  [NumberedListPlugin.key]: {
    node: {
      type: 'ordered-list',
    },
  },
  [ListItemPlugin.key]: {
    node: {
      type: 'list-item',
    },
  },
  [LinkPlugin.key]: {
    node: {
      type: 'link',
    },
  },
};

const getPlugins = ({ toggledFeatures }: { toggledFeatures?: CustomEditorV2ToggledFeatures }) => [
  SlashPlugin.configure({
    enabled: Boolean(toggledFeatures?.slashInput),
  }),
  LoggingPlugin.configure({ enabled: Boolean(toggledFeatures?.logging) }),
  TrimEndParagraphsPlugin.configure({ enabled: Boolean(toggledFeatures?.trimEndEmptyParagraphs) }),
  DeleteBackwardPlugin,
  VideoPlugin,
  EmbeddedDocumentPlugin,
  DocumentPlugin,
  ParagraphPlugin,
  HeadingPlugin,
  ImagePlugin,
  ListPlugin,
  AudioPlugin,
  BoldPlugin,
  ItalicPlugin,
  UnderlinePlugin,
  StrikethroughPlugin,
  LinkPlugin.configure({
    // eslint-disable-next-line react/display-name
    render: { afterEditable: () => <LinkFloatingToolbar /> },
    options: {
      /**
       * Here we can define custom links transformations.
       *
       * We need to accept links starting with www instead of https://
       *
       * Like www.notion.so => https://www.notion.so
       *
       * To do so, we need to add custom transformations.
       *
       * Link to feature description from the official PR: https://github.com/udecode/plate/pull/3389/files
       */
      transformInput: (url) => {
        // If the URL starts with "www.", prepend "https://"
        if (url.startsWith('www.')) {
          return `https://${url}`;
        }
        // Otherwise, return the URL as is
        return url;
      },
    },
  }),
  QuizQuestionPlugin,
  QuizActionbarPlugin,
  NodeIdPlugin.configure({
    options: {
      idCreator: uuid,
    },
  }),
  DndPlugin.configure({
    enabled: toggledFeatures?.dragAndDrop,
    options: {
      enableScroller: true,
    },
  }),
  // Allows to continue at the new line withing the same block
  SoftBreakPlugin.configure({
    options: {
      rules: [{ hotkey: 'shift+enter' }],
    },
  }),
  // Allows exiting from the block for certain elements
  ExitBreakPlugin.configure({
    options: {
      rules: [
        {
          hotkey: 'enter',
          query: {
            start: true,
            end: true,
            allow: [OVERRIDE_PLUGINS[HEADING_KEYS.h1].node.type, OVERRIDE_PLUGINS[HEADING_KEYS.h2].node.type],
          },
          relative: true,
          level: 1,
        },
      ],
    },
  }),
];

const OVERRIDE_COMPONENTS = withPlaceholders({
  [SlashInputPlugin.key]: SlashInputElement,
  [QuizQuestionPlugin.key]: withProps(QuizQuestionElement, {}),
  [VideoPlugin.key]: withProps(VideoElement, {}),
  [EmbeddedDocumentPlugin.key]: withProps(EmbeddedDocumentElement, {}),
  [DocumentPlugin.key]: withProps(DocumentElement, {}),
  [ImagePlugin.key]: withProps(ImageElement, {}),
  [AudioPlugin.key]: withProps(AudioElement, {}),
  [LinkPlugin.key]: withProps(LinkElement, { as: 'a' }),
  [NumberedListPlugin.key]: withProps(ListElement, { variant: 'ol' }),
  [BulletedListPlugin.key]: withProps(ListElement, { variant: 'ul' }),
  [ListItemPlugin.key]: withProps(ListItemElement, { as: 'li' }),
  [ParagraphPlugin.key]: ParagraphElement,
  [HEADING_KEYS.h1]: withProps(HeadingElement, { variant: 'h1' }),
  [HEADING_KEYS.h2]: withProps(HeadingElement, { variant: 'h2' }),
  [BoldPlugin.key]: withProps(BoldLeaf, { as: 'strong' }),
  [ItalicPlugin.key]: withProps(ItalicLeaf, { as: 'em' }),
  [StrikethroughPlugin.key]: withProps(StrikethroughLeaf, { as: 's' }),
  [UnderlinePlugin.key]: withProps(UnderlineLeaf, { as: 'u' }),
  [QuizActionbarPlugin.key]: withProps(QuizActionbarElement, {}),
});

const insertActionbarAfterLastQuestion = (items: Value) => {
  const lastQuestionElementIndex = [...items].reverse().findIndex((item) => item.type === QuizQuestionPlugin.key);

  if (lastQuestionElementIndex !== -1) {
    const realIndex = items.length - 1 - lastQuestionElementIndex;
    items.splice(realIndex + 1, 0, {
      type: QuizActionbarPlugin.key,
      id: uuid(),
      children: [{ text: '' }],
    });
  }

  return items;
};

export const useCreateEditor = ({
  initialValue,
  toggledFeatures,
  isViewMode = false,
}: {
  initialValue: Value;
  toggledFeatures?: CustomEditorV2ToggledFeatures;
  isViewMode?: boolean;
}): TPlateEditor => {
  const preparedInitialValue = useMemo(() => {
    if (!initialValue) return [];

    const newItems = initialValue.map((item) => {
      if (!item.id) {
        return { ...item, id: uuid() };
      }

      return item;
    });

    if (isViewMode) {
      return insertActionbarAfterLastQuestion(newItems);
    }

    return newItems;
  }, [initialValue]);

  return usePlateEditor({
    plugins: getPlugins({ toggledFeatures }),
    override: {
      plugins: OVERRIDE_PLUGINS,
      components: withPlaceholders(withDraggables(OVERRIDE_COMPONENTS)),
    },
    value: preparedInitialValue,
  });
};
