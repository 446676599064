import styled from 'styled-components';

import { ScreenSize } from '@/ui/theme/layout';
import { mediaQuery } from '@/ui/utils';

export const CALCULATED_MODAL_BOX_MAX_HEIGHT = `calc(100vh - 200px)`;
export const CALCULATED_MENU_INSIDE_MODAL_MAX_HEIGHT = `calc(100vh - 200px - 80px - 32px)`;

export const MainModalBoxStyled = styled.dialog<{ size?: ScreenSize }>`
  box-sizing: border-box;
  border: none;
  &:focus-visible {
    outline: none;
  }
  margin: 0;
  position: relative;
  background: ${(p) => p.theme.colors.neutral.background};
  ${(p) => p.theme.shadow.primary[8]}
  border-radius: 12px 12px;

  display: flex;
  flex-direction: column;
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }

  ${mediaQuery.until('tablet')} {
    max-width: calc(100vw - 168px);
    max-height: ${CALCULATED_MODAL_BOX_MAX_HEIGHT};
    padding: 80px 16px 0 16px;
  }

  ${mediaQuery.from('desktop')} {
    max-width: calc(100vw - 168px);
    max-height: ${CALCULATED_MODAL_BOX_MAX_HEIGHT};
    padding: 80px 8px 0 8px;
  }

  ${mediaQuery.from('widescreen')} {
    max-width: calc(100vw - 168px);
    max-height: ${CALCULATED_MODAL_BOX_MAX_HEIGHT};
    padding: 80px 60px 0 60px;
  }

  ${mediaQuery.from('fhd')} {
    max-width: calc(100vw - 168px);
    max-height: ${CALCULATED_MODAL_BOX_MAX_HEIGHT};
    padding: 80px 132px 0 132px;
  }
`;