import { Fragment, useState } from 'react';
import styled from 'styled-components';
import { Control, Controller } from 'react-hook-form';

import { ColumnStyled, StyledLabel } from '@/features/course/admin/molecules/CourseSettingsModalForm/CourseSettingsModalForm';
import { useCategories } from '@/store/categories';
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList, CommandSeparator } from '@/ui/Command';
import { Popover, PopoverContent, PopoverTrigger } from '@/ui/Popover/Popover';
import { Course } from '@/features/course/admin/types/course';
import { TextFieldName } from '@/features/course/admin/constants/settingsModal';
import { ComboboxTrigger } from '@/ui/Combobox';
import { InputChip } from '@/ui/Chip';

const PopoverContentStyled = styled(PopoverContent)`
  width: 336px;
`;

const RootStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
  align-self: stretch;
`;

const InputChipLabelStyled = styled.div`
  max-width: 240px;
`;

type CourseSettingsModalFieldCategorySubjectProps = { control: Control<Course> };

export const CourseSettingsModalFieldCategorySubject = ({
  control,
}: CourseSettingsModalFieldCategorySubjectProps): JSX.Element => {
  const { categories } = useCategories();
  const [popoverOpen, setPopoverOpen] = useState(false);

  return (
    <RootStyled onClick={(e) => e.stopPropagation()}>
      <ColumnStyled>
        <StyledLabel>Category/Subject</StyledLabel>
      </ColumnStyled>
      <ColumnStyled>
        <Controller
          control={control}
          name={TextFieldName.Subject}
          render={({ field: { onChange, value } }) => {
            const category = categories.find((category) => category.subjects.some((subject) => subject.id === value));
            const name = category?.subjects.find((subject) => subject.id === value)?.name || '';

            return (
              <Popover open={popoverOpen} onOpenChange={setPopoverOpen}>
                <PopoverTrigger asChild>
                  <ComboboxTrigger
                    comboboxOpen={popoverOpen}
                    label={'Category/Subject'}
                    onClick={() => {
                      setPopoverOpen(true);
                    }}
                  >
                    {name ? (
                      <div>
                        <InputChip
                          label={<InputChipLabelStyled>{name}</InputChipLabelStyled>}
                          hasCloseIcon
                          onTrailingIconClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            onChange(undefined);
                            setPopoverOpen(false);
                          }}
                        />
                      </div>
                    ) : null}
                  </ComboboxTrigger>
                </PopoverTrigger>
                <PopoverContentStyled align="start">
                  <Command>
                    <CommandInput placeholder="Search category or subject" />
                    <CommandList>
                      <CommandEmpty>No results found.</CommandEmpty>
                      {categories.map((category, categroryIndex, categoryArray) => {
                        return (
                          <Fragment key={category.id}>
                            <CommandGroup heading={category.name}>
                              {category.subjects.map((subject) => (
                                <CommandItem
                                  key={subject.urlKey}
                                  keywords={subject.name?.split(' ')}
                                  value={String(subject.id)}
                                  onSelect={(value) => {
                                    onChange(Number(value));
                                    setPopoverOpen(false);
                                  }}
                                  $selected={subject.id === value}
                                >
                                  {subject.name}
                                </CommandItem>
                              ))}
                            </CommandGroup>
                            {categoryArray.length - 1 !== categroryIndex && <CommandSeparator />}
                          </Fragment>
                        );
                      })}
                    </CommandList>
                  </Command>
                </PopoverContentStyled>
              </Popover>
            );
          }}
        />
      </ColumnStyled>
    </RootStyled>
  );
};
