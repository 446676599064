import React, { createContext, CSSProperties, Dispatch, ReactNode, ReactNodeArray, SetStateAction, useState } from 'react';

import { factoryUseContext } from '@/utils/factoryUseContext';
import { ModalSize } from './styles';
import { noop } from '@/utils/noop';
import { useLockModalOverlay } from '@/components/Modal/useLockModalOverlay';
import { trackClosingModal } from '@/utils/tracking/shared';

type Action = (...args: any[]) => unknown;

interface ModalProps {
  size?: ModalSize;
  children: ReactNode | ReactNodeArray;
  customHeaderText?: string;
  customHeaderElement?: ReactNode;
  trackingModalName?: string;
  shouldHideOnClickOutside?: boolean;
  modalStyles?: CSSProperties;
  headerStyles?: CSSProperties;
  bodyStyles?: CSSProperties;
  onClose?: () => void;
}

interface ConfirmModalProps {
  customHeaderText?: string;
  customHeaderElement?: ReactNode;
  children?: ReactNode | ReactNodeArray;
  message?: ReactNode;
  action: Action;
  warning?: boolean;
}

interface ModalContextProps {
  modalTarget: HTMLElement | null;
  show: boolean;
  hideModal: () => void;
  showModal: (updatedModalProps: ModalProps) => void;
  blockHide: boolean;
  setBlockHide: (shouldBlock: boolean) => void;
  setModalProps?: Dispatch<SetStateAction<ModalProps>>;
  modalProps: ModalProps;

  confirmTarget: HTMLElement | null;
  showConfirm: boolean;
  showConfirmationModal: (updatedModalProps: ConfirmModalProps) => void;
  hideConfirmModal: () => void;
  setConfirmProps?: Dispatch<SetStateAction<ConfirmModalProps>>;
  confirmProps: ConfirmModalProps;
}

const initial: ModalContextProps = {
  modalTarget: document.getElementById('modal'),
  show: false,
  hideModal: noop,
  showModal: noop,
  blockHide: false,
  setBlockHide: noop,
  setModalProps: noop,
  modalProps: {
    children: undefined,
    size: 'default',
    customHeaderText: undefined,
    customHeaderElement: null,
    bodyStyles: {},
    headerStyles: {},
    onClose: undefined,
  },
  confirmTarget: document.getElementById('confirm'),
  showConfirmationModal: noop,
  hideConfirmModal: noop,
  showConfirm: false,
  confirmProps: {
    action: noop,
  },
};

const ModalContext = createContext<ModalContextProps>(initial);

export const useModalContext = factoryUseContext('Modal', ModalContext);

export function ModalProvider({ children }: { children: ReactNode }): JSX.Element {
  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [blockHide, setBlockHide] = useState(false);
  const [modalProps, setModalProps] = useState(initial.modalProps);
  const [confirmProps, setConfirmProps] = useState(initial.confirmProps);

  useLockModalOverlay([show, showConfirm]);

  const hideModal = () => {
    setShow(false);
    setModalProps(initial.modalProps);
    if (modalProps.trackingModalName) {
      trackClosingModal(modalProps.trackingModalName);
    }
  };
  const showModal = (updatedModalProps: ModalProps) => {
    setModalProps((prevModalProps) => ({ ...prevModalProps, ...updatedModalProps, type: 'default' }));
    setShow(true);
  };

  const hideConfirmModal = () => {
    setShowConfirm(false);
    setConfirmProps(initial.confirmProps);
  };
  const showConfirmationModal = async ({
    action,
    message,
    customHeaderText,
    customHeaderElement,
    warning,
    children,
  }: ConfirmModalProps) => {
    if (!showConfirm) {
      setConfirmProps((prevModalProps) => ({
        ...prevModalProps,
        action,
        message,
        customHeaderText,
        customHeaderElement,
        warning,
        children,
        size: 'small',
      }));
      setShowConfirm(true);
    } else {
      console.warn('Tried to open modal when modal is already showing, falling back to confirm', modalProps.children, message);
      setConfirmProps((prevModalProps) => ({
        ...prevModalProps,
        message: prevModalProps.message + ' Attempted to open second confirm dialog',
      }));
    }
  };
  return (
    <ModalContext.Provider
      value={{
        ...initial,
        show,
        hideModal,
        showModal,
        blockHide,
        setBlockHide,
        modalProps,
        setModalProps,

        showConfirm,
        showConfirmationModal,
        hideConfirmModal,
        confirmProps,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
}
