import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { inventoryToCourseCards, useCancelOrder } from '@/store/inventory';
import { useCardCatalog } from '@/store/catalog';
import { useSavedLearnings } from '../../user/MyLearning/store';
import { trackShowAllButtonClicked } from '@/utils/tracking/homePage';
import { LayoutProps, SectionBaseLayout } from '../layout/SectionBaseLayout';
import { useMyLearningCards } from '@/pages/user/MyLearning/hooks';
import { getOngoingCards } from './utils/getOngoingCards';
import { getSavedCards } from './utils/getSavedCards';
import { getFeaturedCards } from './utils/getFeaturedCards';
import { generateButton } from './utils/generateButton';
import { generateAssingmentCourseCards } from '@/pages/Homepage/components/utils/generateAssingmentCourseCards';

export const CampusLearningSection = (): JSX.Element => {
  document.documentElement.style.backgroundImage = 'none';
  const { t } = useTranslation('home');
  const trackViewCompletedOngoing = useCallback(() => trackShowAllButtonClicked('ongoing'), []);
  const trackViewCompletedSaved = useCallback(() => trackShowAllButtonClicked('saved-by-me'), []);
  const trackViewCompletedAssigned = useCallback(() => trackShowAllButtonClicked('assigned'), []);
  const trackExploreAllLearningItemsClick = useCallback(() => trackShowAllButtonClicked('featured'), []);

  const { savedLearnings, loading: loadingSavedLearnings } = useSavedLearnings();
  const { catalog: savedCatalog, loading: loadingSavedCatalog } = useCardCatalog({
    items: savedLearnings.meta,
  });
  const [cancelOrder, { loading: loadingCancel }] = useCancelOrder();
  const { cardFilterPredicate, filtered, loading: myLearningCardsLoading, ...props } = useMyLearningCards({});
  const hasLoadedSavedCatalog = savedCatalog.cards.length || !loadingSavedCatalog;
  const loading = myLearningCardsLoading || loadingCancel || loadingSavedLearnings || !hasLoadedSavedCatalog;

  if (loading) {
    return <SectionBaseLayout.LoadingState />;
  }

  const inventoryCourseCards = inventoryToCourseCards(
    filtered.ongoing.inventory,
    cancelOrder,
    loadingCancel,
    status === 'ongoing'
  );

  const { count: assignedCount, cards: assignedCards } = {
    cards: generateAssingmentCourseCards(props.assignmentCards.filter((x) => x.status !== 'completed' && x.status !== 'ended')),
    count: props.todoCount,
  };

  const { count: ongoingCount, cards: ongoingCards } = getOngoingCards(filtered.ongoing.catalog, inventoryCourseCards);
  const { count: savedCount, cards: savedCards } = getSavedCards(cardFilterPredicate, savedCatalog);
  const { count: featuredCount, cards: featuredCards } = getFeaturedCards(props.featuredCards);

  const showAll = t('show-all');
  const sections: LayoutProps[] = [
    {
      title: t('continue-Learning'),
      count: ongoingCount,
      button: generateButton(trackViewCompletedOngoing, '/user/my-learning/ongoing', showAll),
      cards: ongoingCards,
    },
    {
      title: t('assigned'),
      count: assignedCount,
      button: generateButton(trackViewCompletedAssigned, '/user/my-learning/assigned', showAll),
      cards: assignedCards,
      useCustomCardLayout: true,
    },
    {
      title: t('saved'),
      count: savedCount,
      button: generateButton(trackViewCompletedSaved, '/user/my-learning/saved-by-me', showAll),
      cards: savedCards,
    },
    {
      title: t('featured'),
      count: featuredCount,
      button: generateButton(trackExploreAllLearningItemsClick, '/learning', t('explore'), false),
      cards: featuredCards,
      defaultButtonLayout: false,
    },
  ];

  return (
    <div>
      {sections.map((x) => (
        <SectionBaseLayout
          key={x.title}
          title={x.title}
          count={x.count}
          button={x.button}
          cards={x.cards}
          defaultButtonLayout={x.defaultButtonLayout}
          useCustomCardLayout={x.useCustomCardLayout}
        />
      ))}
    </div>
  );
};
