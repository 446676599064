import React, { useContext } from 'react';
import { getParser } from 'bowser';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation, useParams } from 'react-router-dom';

import { useLearning } from '@/store/learning';
import { LoadingHero } from '@/component/LoadingHero';
import { LearningType } from '@/types/learning';
import { SharedSnackbarContext, SnackbarType } from '@/component/SharedSnackbar/SharedSnackbar';
import { useWindowFocus } from '@/hooks/useWindowFocus';
import { useELearningSession } from '@/pages/learning/Learning/schema/queries';

export const ELearningView = (): React.ReactElement => {
  const { id } = useParams<{ id: string }>();
  const parsedId = id ? parseInt(id) : NaN;

  const { t } = useTranslation('learning');
  const location = useLocation();
  const { learning, loading: loadingLearning } = useLearning({ id: parsedId, skip: isNaN(parsedId) });
  const { openSnackbar } = useContext(SharedSnackbarContext);
  const [sessionQueryString, { loading: loadingSession, refetch: refetchSession, error: sessionError }] =
    useELearningSession(parsedId);

  useWindowFocus((visible: boolean) => {
    if (visible && !loadingSession) {
      refetchSession();
    }
  });

  const browser = getParser(window.navigator.userAgent);
  const isIEOrLegacyEdge = ['EdgeHTML', 'Trident'].includes(browser.getEngineName());

  if (isIEOrLegacyEdge && parsedId === 124)
    return <Navigate to={location.pathname.replace(/\/view|\/e-learning/gi, '')} replace />;

  if (loadingLearning || (loadingSession && !sessionQueryString)) {
    return <LoadingHero />;
  }
  if (!learning || learning.type !== LearningType.E_LEARNING) return <Navigate to="/404" />;
  if (sessionError) {
    openSnackbar({
      message: t('registration failed'),
      type: SnackbarType.DANGER,
    });

    return <Navigate to={`/learning/${id}`} />;
  }

  return (
    <iframe
      src={`${learning.meta.eLearningUrl}${sessionQueryString}`}
      frameBorder={0}
      style={{ width: '100%', height: '100%', minHeight: '100vh' }}
      allowFullScreen
      title={learning.type}
    />
  );
};
