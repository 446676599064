import { ApolloCache, ApolloClient } from '@apollo/client';

// taken from https://stackoverflow.com/a/46181
export const isValidEmail = (email: string): boolean => {
  const regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email.toLowerCase());
};

// doesn't handle cases like .tar.gz
export const trimFilenameExtension = (filename: string): string => filename.substr(0, filename.lastIndexOf('.')) || filename;

// through the interface of Coursera, course links don't contain attemptSSOLogin.
// There are also certain views where you can get links with excessive parameters, so we filter them out
export const formatCourseraCustomLink = (link: string): string => {
  const dynamicEssentialParams = ['authProvider', 'productId'];

  const url = new URL(link);

  url.searchParams.forEach((_, key) => {
    if (!dynamicEssentialParams.includes(key)) {
      url.searchParams.delete(key);
    }
  });

  url.searchParams.set('productType', 'course');
  url.searchParams.set('currentTab', 'CATALOG');
  url.searchParams.set('showMiniModal', 'true');
  url.searchParams.set('attemptSSOLogin', 'true');

  return url.href;
};

type Cache = ApolloClient<any> & { data: { data: Record<string, unknown>; delete: (param: string) => void } };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const clearCache = (apolloCache: ApolloCache<any>, template: string): void => {
  const cache = apolloCache as unknown as Cache;
  if (!cache || !cache.data) return;

  Object.keys(cache.data.data).forEach((key) => key.match(new RegExp(`${template}`)) && cache.data.delete(key));
};
