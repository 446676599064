import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { HeaderWithAction } from '@/administration/pages/Journey/common/atoms/Page/HeaderWithAction';
import { SearchPhrase } from '@/management/pages/common/filter';
import { SEARCH_PARAM_KEYS, usePageState } from '@/management/pages/common/usePageState';
import { useLearningItemsColumnsDeclaration, SortBy } from '@/features/journey/admin/hooks/useLearningItemsColumnsDeclaration';
import { AutoTable, AutoTableProps } from '@/ui/AutoTable';
import { JourneyInsightsTabsLearningItemsTypeFilter } from '@/features/journey/admin/atoms/JourneyInsightsTabsLearningItemsTypeFilter';
import { useLazyLearningItems, LearningItemType } from '@/features/journey/admin/hooks/useLazyLearningItems';
import { RLearning_Journeys_Items_Insights_Order_By, useLearningJourneyContentInsightsCsvLazyQueryRemote } from '@/store/v2';
import { useManagedTeams } from '@/management/store/managedTeams/useManagedTeams';
import { useIsManagementContext } from '@/management/context/context';
import { LoadingState } from '@/features/journey/admin/organisms/JourneyInsightsTabsParticipantsContent/JourneyInsightsTabsParticipantsContent';
import { EmptyStateAfterProcessed } from '@/management/components/EmptyState';
import { FEATURE, useFeatureEnabled } from '@/feature-toggles';
import { useQueryNumberArrayState } from '@/hooks/useQueryState';

export type LearningItemRow = LearningItemType & { learningType: string };

const ContainerStyled = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const ActionsStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const ITEMS_LIMIT = 20;

export const JourneyInsightsTabsLearningItems = (): React.ReactElement => {
  const { journeyId } = useParams<{ journeyId: string }>();
  const [selectedTeamIds] = useQueryNumberArrayState(SEARCH_PARAM_KEYS.SELECTED_TEAM_IDS, []);
  const journeyURN = journeyId ? `urn:collegial:self:journey:${journeyId}` : null;
  const columnDeclaration = useLearningItemsColumnsDeclaration();
  const [downloadCsvLink, { loading: downloadCsvLinkLoading }] = useLearningJourneyContentInsightsCsvLazyQueryRemote();
  const isManagementContext = useIsManagementContext();
  const isDownloadCsvEnabled = useFeatureEnabled(FEATURE.UI_INSIGHT_PAGES_DOWNLOAD_CSV_ENABLED);

  const {
    searchPhrase,
    setSearchPhrase,
    sortBy,
    sortOrder,
    onSortChange,
    page,
    setPage,
    onClearFilterState,
    selectedLearningType,
    setSelectedLearningType,
  } = usePageState<SortBy>('title');

  const teams = useManagedTeams({
    skip: !isManagementContext,
  });

  const { lazyLearningItems, totalCount, loading } = useLazyLearningItems({
    limit: ITEMS_LIMIT,
    offset: page * ITEMS_LIMIT,
    sortBy,
    orderBy: [{ [sortBy]: sortOrder }] as RLearning_Journeys_Items_Insights_Order_By,
    args: {
      learning_journey_urn: journeyURN as string,
      ...(!!selectedTeamIds?.length
        ? { team_ids: selectedTeamIds }
        : isManagementContext
        ? { team_ids: teams?.data?.map((team) => team.id) }
        : {}),
      ...(!!searchPhrase ? { title: searchPhrase } : {}),
      ...(!!selectedLearningType ? { type: selectedLearningType?.toLowerCase() } : {}),
    },
  });

  const handleDownloadCSVClick = async () => {
    const { data } = await downloadCsvLink({ variables: { learningJourneyId: Number(journeyId) } });
    const csvLink = data?.learningJourneyContentInsightsCSV;

    if (csvLink) {
      window.open(csvLink, '_blank');
    }
  };

  const tableProps: AutoTableProps<LearningItemRow, SortBy> = {
    rows: lazyLearningItems?.length ? lazyLearningItems?.map((item) => ({ ...item, learningType: item?.type })) : [],
    rowKey: (learningItem) => learningItem.urn,
    columnDeclaration,
    pageCount: Math.ceil((totalCount ?? 0) / ITEMS_LIMIT),
    page,
    onPageChange: (event) => {
      setPage(event);
    },
    currentSortKey: sortBy,
    currentSortOrder: sortOrder,
    onSort: onSortChange,
  };

  return (
    <ContainerStyled>
      <HeaderWithAction
        title="Learning items"
        counter={totalCount || 0}
        actionProps={
          isDownloadCsvEnabled
            ? {
                loading: downloadCsvLinkLoading,
                onClick: handleDownloadCSVClick,
                title: 'Download (.csv)',
                variant: 'secondary',
              }
            : undefined
        }
      />
      <ActionsStyled>
        <SearchPhrase
          value={searchPhrase}
          onChange={(phrase) => {
            setSearchPhrase(phrase);
          }}
          placeholder="Search learning item"
        />
        <JourneyInsightsTabsLearningItemsTypeFilter
          selectedType={selectedLearningType as string}
          onChange={setSelectedLearningType}
          onTrailingIconClick={() => onClearFilterState('learning type')}
        />
      </ActionsStyled>
      {loading && <LoadingState />}
      {!loading && lazyLearningItems?.length > 0 && <AutoTable<LearningItemRow, SortBy> {...tableProps} />}
      {!loading && lazyLearningItems.length === 0 && (
        <EmptyStateAfterProcessed onFiltersClear={() => onClearFilterState('clear all')} />
      )}
    </ContainerStyled>
  );
};
