import { ApolloQueryResult, gql, useQuery } from '@apollo/client';

import { HookResult } from './apolloClient';
import { Language } from '../types/language';

const getLanguagesQuery = gql`
  query getLanguages {
    getLanguages {
      id
      name
    }
  }
`;

interface LanguagesResponse extends HookResult {
  languages: readonly Language[];
  refetch: () => Promise<ApolloQueryResult<{ getLanguages: readonly Language[] }>>;
}

export const useLanguages = (): LanguagesResponse => {
  const { data, error, refetch, loading, networkStatus } = useQuery<{ getLanguages: ReadonlyArray<Language> }>(getLanguagesQuery);

  const languages = (data && data.getLanguages) || [];

  return {
    languages,
    error,
    refetch,
    loading,
    networkStatus,
  };
};
