/* eslint-disable import/no-unused-modules */
import React from 'react';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { Button, IconButton } from '@/ui/Button';
import { Tooltip } from '@/ui/Tooltip';
import { makeNamespacedComponent } from '@/ui/utils';
import { Skeleton } from '@/ui/Skeleton';
import { ADMIN_ROUTE_RELATIVE_PATH } from '@/administration/constants/adminRoutePaths';

const RootStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ActionsStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const HeadingWrapStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
`;

const TitleStyled = styled.div`
  ${({ theme }) => theme.typography.title4};
  color: ${({ theme }) => theme.tones.neutral[0]};
`;

type CourseNavbarProps = {
  onBackClick: () => void;
  onSettingsClick: () => void;
  onSaveClick: () => void;
  viewHref?: string;
  title: string;
};

const _CourseNavbar = ({ title, onBackClick, viewHref, onSettingsClick, onSaveClick }: CourseNavbarProps): JSX.Element => {
  const theme = useTheme();
  const navigate = useNavigate();
  const iconColor = theme.tones.neutral[0];
  const { t } = useTranslation('catalogAdmin');

  const handleInsightsClick = () => {
    navigate(ADMIN_ROUTE_RELATIVE_PATH.COURSE_INSIGHTS);
  };

  return (
    <RootStyled>
      <HeadingWrapStyled>
        <Tooltip title="Back" placement="bottom-start">
          <IconButton icon="arrow-left" color={iconColor} onClick={onBackClick} />
        </Tooltip>
        <TitleStyled>{title}</TitleStyled>
      </HeadingWrapStyled>
      <ActionsStyled>
        <Tooltip title="Insights" placement="bottom">
          <IconButton icon={['far', 'poll']} color={iconColor} onClick={handleInsightsClick} />
        </Tooltip>
        <Tooltip title="Settings" placement="bottom">
          <IconButton icon={['far', 'gear']} color={iconColor} onClick={onSettingsClick} />
        </Tooltip>
        {viewHref && (
          <Button variant="secondary" href={viewHref} target="_blank">
            {t('view')}
          </Button>
        )}
        <Button variant="primary" onClick={onSaveClick}>
          {t('save')}
        </Button>
      </ActionsStyled>
    </RootStyled>
  );
};

const LoadingState = (): JSX.Element => (
  <RootStyled>
    <HeadingWrapStyled>
      <TitleStyled>
        <Skeleton width={244} height={36} />
      </TitleStyled>
    </HeadingWrapStyled>
    <ActionsStyled>
      <Skeleton width={66} height={36} />
      <Skeleton width={87} height={36} />
    </ActionsStyled>
  </RootStyled>
);

export const CourseNavbar = makeNamespacedComponent(_CourseNavbar, {
  LoadingState,
});
