import styled, { useTheme } from 'styled-components';

import { useSelectedElementFloatingToolbar } from '@/component/customEditorV2/hooks/elementFloatingToolbar/useSelectedElementFloatingToolbar';
import { Icon } from '@/ui/Icon';
import { FloatingToolbarButtons } from '@/component/customEditorV2/ui/elements/DocumentElement/Placeholder/FloatingToolbarButtons';
import { DocumentVideoUploadButton } from '@/component/FileUpload/DocumentVideoUploadButton';

const RootStyled = styled.div`
  height: 88px;
  width: 100%;
  background: ${({ theme }) => theme.colors.neutral.container};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  padding: 16px;
`;

const IconBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const TitleStyled = styled.h3`
  ${({ theme }) => theme.typography.title3};
  color: ${({ theme }) => theme.colors.neutral.onBackground};
`;

const UploadButton = styled(DocumentVideoUploadButton)`
  & {
    background: transparent;
  }
`;

type AudioPlaceholderProps = {
  shouldShowToolbar: boolean;
  onUpload: (file: File) => void;
  onRemove: () => void;
};

export const AudioPlaceholder = ({ shouldShowToolbar, onUpload, onRemove }: AudioPlaceholderProps): JSX.Element => {
  const theme = useTheme();
  const { setFloating, setReference, style } = useSelectedElementFloatingToolbar({ selected: shouldShowToolbar });

  return (
    <>
      <RootStyled ref={setReference}>
        <IconBlock>
          <Icon size="3x" icon={['far', 'waveform-lines']} color={theme.colors.neutral.onBackground} />
          <TitleStyled>Add audio</TitleStyled>
        </IconBlock>
        <UploadButton
          icon={['far', 'file-upload']}
          variant="secondary"
          size="large"
          disabled={false}
          uploadFile={onUpload}
          fileType={'document'}
          accept=".mp3"
        >
          Upload
        </UploadButton>
      </RootStyled>
      <div
        ref={setFloating}
        style={{
          ...style,
        }}
      >
        <FloatingToolbarButtons onRemoveClick={onRemove} />
      </div>
    </>
  );
};
