import React from 'react';

import { makeNamespacedComponent } from '@/ui/utils';
import { ListStyled, RootStyled, CounterStyled, LoadMoreStyled } from './styles';
import { Button } from '@/ui/Button';
import { Skeleton } from '@/ui/Skeleton';
import { Participant, ParticipantProps } from '../Participant';

export type ParticipantsProps = {
  participants: ParticipantProps[];
  total: number;
  loadingMoreItems?: boolean;
  onLoadMoreItems?: () => void;
};

const ListLoading = (): JSX.Element => (
  <>
    <Participant.LoadingState />
    <Participant.LoadingState />
    <Participant.LoadingState />
  </>
);

const _Participants = ({ participants, total, onLoadMoreItems, loadingMoreItems }: ParticipantsProps): JSX.Element => {
  const canLoadMore = participants.length < total;
  return (
    <RootStyled>
      <CounterStyled>
        {total} {`learner${total !== 1 ? 's' : ''}`}
      </CounterStyled>
      <ListStyled>
        {participants.map((participantProps) => (
          <Participant {...participantProps} key={participantProps.email} />
        ))}
        {loadingMoreItems && <ListLoading />}
      </ListStyled>
      {canLoadMore && (
        <LoadMoreStyled>
          <Button variant="secondary" onClick={onLoadMoreItems} disabled={loadingMoreItems}>
            Show more
          </Button>
        </LoadMoreStyled>
      )}
    </RootStyled>
  );
};

const LoadingState = (): JSX.Element => (
  <RootStyled>
    <CounterStyled>
      <Skeleton height={20} width={100} />
    </CounterStyled>

    <ListStyled>
      <ListLoading />
    </ListStyled>
  </RootStyled>
);

const Participants = makeNamespacedComponent(_Participants, {
  LoadingState,
});

export { Participants };
