import { useEffect, useState } from 'react';

import { UsersSort, UsersSortField, UsersSortOrder, useUsers } from '@/administration/store/users/users';
import { useUsersTableDataFilter } from '@/administration/pages/UserSearch/hooks/useUsersTableDataFilter';
import { useCurrentUser, useCurrentUserAccess } from '@/store/currentUser';
import { UserWithRole } from '@/administration/types/users';

const PAGE_DEFAULT_LIMIT = 40;
const PAGE_DEFAULT = 1;

export const useParticipantsPickerState = ({
  participantIdsToSkip,
}: {
  participantIdsToSkip: number[];
}): {
  onLoadMore: () => void;
  loading: boolean;
  canShowMore: boolean;
  usersList: UserWithRole[];
  usersTotal: number;
  searchPhrase: string;
  onSearchPhraseChange: (value: string) => void;
} => {
  const { user } = useCurrentUser();
  const { isSuperAdmin } = useCurrentUserAccess();

  const [usersList, setUsersList] = useState<UserWithRole[]>([]);

  const filter = useUsersTableDataFilter({ defaultRealm: isSuperAdmin ? undefined : user?.realm });
  const [page, setPage] = useState<number>(PAGE_DEFAULT);

  const sort: UsersSort = { field: UsersSortField.ID, order: UsersSortOrder.ASC };

  useEffect(() => {
    setPage(PAGE_DEFAULT);
    if (usersList.length) {
      setUsersList([]);
    }
  }, [filter.searchPhrase, filter.realm, filter.realmRole, filter.status]);

  const onLoadMore = () => {
    setPage((p) => p + 1);
  };

  const { loading, users, total } = useUsers(
    {
      searchPhrase: filter.searchPhrase.trim(),
      realm: filter.realm,
      status: filter.status ? [filter.status] : undefined,
      realmRole: filter.realmRole,
    },
    { page, limit: PAGE_DEFAULT_LIMIT },
    sort,
    {
      withRealmRoleAssignments: false,
    }
  );

  useEffect(() => {
    if (users && users.length && !loading) {
      const filteredUsersList = users.filter((user) => !participantIdsToSkip.includes(user.id));
      setUsersList((prev) => [...prev, ...filteredUsersList]);
    }
  }, [users, users.length, loading, setUsersList, participantIdsToSkip]);

  const canShowMore = usersList.length < total;

  return {
    onLoadMore,
    loading,
    usersList,
    usersTotal: total,
    canShowMore,
    searchPhrase: filter.searchPhrase,
    onSearchPhraseChange: filter.setSearchPhrase,
  };
};
