import { ApolloError, gql, useQuery } from '@apollo/client';

import { useCurrentUser } from '@/store/currentUser';
import { ManagedTeam } from './types';

export const userTeamsQuery = gql`
  query userTeams($userId: Int!) {
    userTeams(filter: { userId: $userId, role: manager, withSubteams: true }, pagination: { limit: -1 }) {
      data {
        team {
          id
          name
          parentTeamId
          subTeams(filter: { indirect: true }, pagination: { limit: -1 }) {
            data {
              id
              name
              parentTeamId
            }
          }
        }
      }
    }
  }
`;

export type UserTeamsQueryResult = {
  userTeams: {
    data: Array<{
      team: {
        id: number;
        name: string;
        parentTeamId: number | null;
        subTeams?: {
          data: [
            {
              id: number;
              name: string;
              parentTeamId: number | null;
            }
          ];
        };
      };
    }>;
  };
};

type userTeamsQueryVariables = {
  userId: number;
};

type UseManagedTeamsOptions = {
  skip?: boolean;
};

type UseManagedTeamsHook = (options?: UseManagedTeamsOptions) => {
  data: ManagedTeam[] | undefined;
  loading: boolean;
  error: ApolloError | undefined;
};

const transformResult = (result: UserTeamsQueryResult): ManagedTeam[] => {
  const teams = result.userTeams.data.map((userTeams) => userTeams.team);
  const subTeams = teams.flatMap((team) => team.subTeams?.data ?? []);
  const allTeams = [...subTeams, ...teams]
    // Clean up data
    .map((t) => ({ id: t.id, name: t.name, parentTeamId: t.parentTeamId }))
    // After combining top-level teams with subTeams, there may be duplicates. Filter them out:
    .reduce((teams, team) => (teams.some((t) => t.id === team.id) ? teams : teams.concat(team)), [] as ManagedTeam[]);

  return allTeams;
};

/**
 * Retrieves all the teams where the currently logged in user has the role of `manager`.
 * These include both:
 *
 *   * explicitly managed teams: teams where the user has been assigned the manager role,
 *   * implicitly managed teams: all descendants of the explicitly managed teams.
 *
 * The resulting list is flat and has unique entries, even if a single team
 * is both explicitly and implicitly managed.
 */
export const useManagedTeams: UseManagedTeamsHook = (options) => {
  const { user } = useCurrentUser();
  const userId = user?.id;

  const { data, loading, error } = useQuery<UserTeamsQueryResult, userTeamsQueryVariables>(userTeamsQuery, {
    variables: { userId: userId! },
    skip: options?.skip || userId === undefined,
  });

  const transformedData = data ? transformResult(data) : data;

  return {
    data: transformedData,
    loading,
    error,
  };
};
