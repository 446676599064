import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { cvar } from '@/styles/GlobalStyle';
import { Overline, BodyMd } from '@/components/Typography/Typography';
import { Icon } from '@/components/Icon';
import { BUTTON_HEIGHT_ENUM } from '@/components/Button/components/BaseButton';
import { CALCULATED_MENU_INSIDE_MODAL_MAX_HEIGHT } from '@/administration/pages/Journey/pages/EditJourney/atoms/Preview/MainModalBox';

const EXPLORE_MENU_WIDTH = '808px';
const EXPLORE_MENU_DEFAULT_MAX_HEIGHT = '85vh';


export const ExploreMenu = styled.div<{isInPreviewModal?: boolean}>`
  position: absolute;
  border-radius: 0 0 ${cvar('radius-outer')} ${cvar('radius-outer')};
  background-color: ${cvar('color-background-light')};
  top: ${cvar('navbar-height-desktop')};
  right: 0;
  left: 0;
  cursor: initial;
  z-index: 35;
  margin-left: auto;
  margin-right: auto;
  width: ${EXPLORE_MENU_WIDTH};
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-height: ${({ isInPreviewModal }) => isInPreviewModal ? CALCULATED_MENU_INSIDE_MODAL_MAX_HEIGHT : EXPLORE_MENU_DEFAULT_MAX_HEIGHT};
  overflow-y: auto;
  box-shadow: inset ${cvar('box-shadow-nav')};
`;

export const CategoriesContainer = styled.div`
  background-image: linear-gradient(rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0));
  width: 50%;
  margin-bottom: ${cvar('gap-md')};
`;

export const ProvidersContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: ${cvar('gap-md')};
`;

export const CategoriesContent = styled.div`
  margin: ${cvar('gap-xl')} ${cvar('gap-lg')} 0 ${cvar('gap-xl')};
`;

export const ProviderContent = styled.div`
  margin: ${cvar('gap-xl')} ${cvar('gap-xl')} 0 ${cvar('gap-lg')};
`;

export const Category = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: ${cvar('gap-xs')};
  align-items: center;

  &:hover {
    color: ${cvar('color-black')};
  }
`;

export const SubjectLink = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${cvar('gap-xs')};
  color: ${cvar('color-text')};

  &:hover {
    color: ${cvar('color-black')};
  }
`;

export const OverlineTitle = styled(Overline)<{ isSelected?: boolean }>`
  margin-bottom: ${cvar('gap-sm')};
  color: ${({ isSelected }) => isSelected && cvar('color-black')};
`;

export const Provider = styled(Link)`
  height: ${BUTTON_HEIGHT_ENUM.default}rem;
  display: flex;
  align-items: center;
  color: ${cvar('color-text')};
`;

export const ProvidersBox = styled.div`
  border: ${cvar('border-style')};
  border-radius: ${cvar('radius-inner')} ${cvar('radius-inner')} 0 0;

  ${Provider} + ${Provider} {
    border-top: ${cvar('border-style')};
  }
`;

export const ForwardIconContainer = styled(Icon)`
  margin: 0 ${cvar('gap-xs')};
`;

export const Text = styled(BodyMd)`
  color: inherit;
`;

export const CategoryTitle = styled(Text)<{ isSelected: boolean; useNewStyle?: boolean }>`
  ${({ isSelected }) =>
    isSelected &&
    `
  	color: ${cvar('color-black')};
  `}
  ${({ useNewStyle }) =>
    useNewStyle &&
    `
  color: ${cvar('color-gray')};
`}
`;

export const CategoryTitleV2 = styled(Text)<{ isSelected: boolean }>`
  ${({ isSelected }) =>
    isSelected &&
    `
  	color: ${cvar('color-black')};
  `}

  color: ${cvar('color-gray')};
`;
