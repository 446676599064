import { v4 as uuid } from 'uuid';
import styled from 'styled-components';

import { CustomEditorV2 } from '@/component/customEditorV2';
import { ELEMENT_QUIZ_QUESTION } from '@/component/customEditorV2/plugins/custom/quizQuestionPlugin';
import { QuizQuestionType } from '@/component/customEditorV2/ui/elements/QuizQuestionElement/Edit/QuizQuestionEditElement';
import { QuizBlock } from '@/features/course/admin/types/block';

const RootStyled = styled.div`
  min-height: 100%;
  padding: 0px 28px;
  overflow-y: scroll;

  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: stretch;
  align-items: stretch;
`;

const TextEditorStyled = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  align-items: flex-start;

  @media (min-width: ${(p) => p.theme.breakpoints.desktopLarge.end}px) {
    align-items: center;
  }
`;

type CoursePageContentQuizProps = {
  id: string;
  spaceId: number;
  text: string;
  onTextChange: (text?: string, formattedQuestions?: QuizBlock[]) => void;
};

export const getQuestions = (text?: string): QuizBlock[] => {
  if (!text) return [];

  const parsedText = JSON.parse(text);

  if (Array.isArray(parsedText) && !parsedText?.length) return [];

  const questions = parsedText.filter((block: QuizQuestionType) => {
    return block?.type === ELEMENT_QUIZ_QUESTION;
  });

  const formattedQuestions = questions.map((questionItem: QuizQuestionType, index: number) => {
    return {
      id: questionItem?.id || uuid(),
      title: questionItem?.title,
      type: questionItem?.questionType?.value,
      order: index + 1,
      options: questionItem?.answers?.map((answerItem, index) => {
        return {
          id: answerItem?.id,
          label: answerItem?.label,
          isCorrect: answerItem?.isCorrect,
          order: index + 1,
        };
      }),
    };
  });

  return formattedQuestions;
};

export const CoursePageContentQuiz = ({ id, text, spaceId, onTextChange }: CoursePageContentQuizProps): JSX.Element => {
  return (
    <RootStyled>
      <TextEditorStyled>
        <CustomEditorV2
          key={id}
          value={text}
          onChange={(text) => onTextChange(text, getQuestions(text))}
          style={{ fontSize: 14 }}
          $fullSize
          minimized
          spaceId={spaceId}
          placeholder="Write your content or ‘/’ for options"
          toggledFeatures={{
            dragAndDrop: true,
            draggableInsertHandler: true,
            editorGhostMode: true,
            withQuestions: true,
            floatingToolbar: true,
            slashInput: true,
            mediaFiles: true,
          }}
        />
      </TextEditorStyled>
    </RootStyled>
  );
};
