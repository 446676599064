import { ApolloQueryResult, gql, useQuery } from '@apollo/client';

import { HookResult } from '@/store/apolloClient';
import { Provider } from '@/store/providers';
import { Realm } from '@/types/realm';

export const approveLicenseMutation = gql`
  mutation approveLicense($currentRealm: String, $provider: String, $licenseId: Int) {
    approveLicense(realm: $currentRealm, provider: $provider, licenseId: $licenseId)
  }
`;

export const getIssuedLicenseById = gql`
  query getIssuedLicenseById($realm: String, $provider: String, $userId: Int, $status: String) {
    issuedLicenses(realm: $realm, provider: $provider, userId: $userId, status: $status) {
      id
      userId
      createdAt
      updatedAt
      lastRequestedAt
      status
      division
      sId
      employeeId
      businessArea
      businessUnit
      user {
        email
        username
      }
    }
  }
`;

export const revokeLicenseMutation = gql`
  mutation revokeLicense($licenseId: Int) {
    revokeLicense(licenseId: $licenseId)
  }
`;

export const syncCourseraLicenses = gql`
  query ($realm: String, $status: String) {
    exportLicenses(realm: $realm, status: $status) {
      id
      userId
      status
      createdAt
      updatedAt
      lastRequestedAt
      division
      sId
      employeeId
      businessArea
      businessUnit
      user {
        email
        username
      }
    }
  }
`;

export const providerLicensesQuery = gql`
  query providerLicenses($realm: String, $provider: String) {
    realmProviderLicensesSummary(realm: $realm, provider: $provider) {
      id
      pendingLicenses
    }
  }
`;

interface ProviderLicensesSummary {
  id: number;
  pendingLicenses: number;
}

interface ProviderLicensesSummaryQuery {
  realmProviderLicensesSummary?: ProviderLicensesSummary;
}

interface ProviderLicensesSummaryResponse extends HookResult {
  loading: boolean;
  data?: ProviderLicensesSummary;
  refetch: () => Promise<ApolloQueryResult<ProviderLicensesSummaryQuery>>;
}

export const useProviderLicensesSummary = (provider: Provider, realm: Realm = window.realm): ProviderLicensesSummaryResponse => {
  const { data, loading, networkStatus, error, refetch } = useQuery<ProviderLicensesSummaryQuery>(providerLicensesQuery, {
    variables: { provider, realm },
  });
  return { data: data?.realmProviderLicensesSummary, loading, networkStatus, error, refetch };
};
