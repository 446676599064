import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Input } from '@/ui/TextField';
import { CustomEditorV2 } from '@/component/customEditorV2';
import { Icon } from '@/ui/Icon';
import { Toggle } from '@/ui/Toggle';

const RootStyled = styled.div`
  max-width: 1064px;
  min-height: 100%;
  padding: 72px 84px;

  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: stretch;
  align-items: stretch;
`;

const TextEditorStyled = styled.div`
  height: 100%;
  max-height: 205px;
  display: flex;
  flex-direction: column;
`;

const InformerBannerStyled = styled.div`
  ${({ theme }) => theme.typography.body2};
  color: ${({ theme }) => theme.colors.neutral.onContainer};
  display: flex;
  align-items: center;
  padding: 20px 16px;
  background: ${({ theme }) => theme.colors.neutral.container};
  border-radius: 4px;
  gap: 10px;
`;

const PeerReviewRootStyled = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.outline1};
  border-radius: 8px;
`;

const PeerReviewMetaStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const PeerReviewTitleStyled = styled.div`
  ${({ theme }) => theme.typography.body2};
  color: ${({ theme }) => theme.colors.neutral.onBackground};
`;

const PeerReviewDescriptionStyled = styled.div`
  ${({ theme }) => theme.typography.body3};
  color: ${({ theme }) => theme.colors.neutral.outline2};
`;

type CoursePageContentPracticalAssessmentDocumentProps = {
  id: string;
  spaceId: number;
  description: string;
  peerReviewEnabled: boolean;
  title: string;
  onTitleChange: (title: string) => void;
  onDescriptionChange: (text?: string) => void;
  onPeerReviewEnabledChange: (enabled: boolean) => void;
};

export const CoursePageContentPracticalAssessmentDocument = ({
  id,
  onDescriptionChange,
  description = '',
  title,
  peerReviewEnabled,
  onTitleChange,
  onPeerReviewEnabledChange,
  spaceId,
}: CoursePageContentPracticalAssessmentDocumentProps): JSX.Element => {
  const { t } = useTranslation('catalog');

  return (
    <RootStyled>
      <Input
        value={title}
        onChange={(e) => onTitleChange(e.currentTarget.value)}
        label={t('title')}
        error={!title?.length ? { message: t('enter-title') } : undefined}
        fullWidth
      />
      <TextEditorStyled>
        <CustomEditorV2
          key={id}
          value={description}
          onChange={(description) => onDescriptionChange(description)}
          style={{ fontSize: 14 }}
          $fullSize
          minimized
          spaceId={spaceId}
          toggledFeatures={{
            mediaFiles: true,
          }}
          placeholder="Instructions (optional)"
        />
      </TextEditorStyled>
      <InformerBannerStyled>
        <Icon icon={['far', 'file-upload']} />
        Participants will complete this assessment by uploading a document.
      </InformerBannerStyled>
      <PeerReviewRootStyled>
        <PeerReviewMetaStyled>
          <PeerReviewTitleStyled>Peer review</PeerReviewTitleStyled>
          <PeerReviewDescriptionStyled>
            Participants are required to write 2 reviews and share with their peers to complete the assessment.
          </PeerReviewDescriptionStyled>
        </PeerReviewMetaStyled>
        <Toggle toggled={peerReviewEnabled} onChange={(e) => onPeerReviewEnabledChange(e.target.checked)} />
      </PeerReviewRootStyled>
    </RootStyled>
  );
};
