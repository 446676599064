import React from 'react';

import { Table, TableWrapper } from './styles';
import { BulkAction, ExpandedRowRenderSubComponent, IsRowExpandable, SortHandler, TableDeclaration } from './types';
import { AutoHeader } from './components/AutoHeader';
import { AutoFooter } from './components/AutoFooter';
import { AutoBody } from './components/AutoBody';
import { usePagination } from '@/components/Table/hooks/usePagination';
import { useSelection } from '@/components/Table/hooks/useSelection';
import { useExpanding } from '@/components/Table/hooks/useExpanding';
import { AutoColGroup } from '@/components/Table/components/AutoColumnGroup';
import { PageControls } from '@/components/Table/hooks/usePageControls';

interface Props<T> {
  /**
   * General
   */
  tableDecl: TableDeclaration<T>;
  keyProp: keyof T;
  items: readonly T[];

  loading?: boolean;
  noDataMessage?: string;

  /**
   * Bulk actions
   */
  tableId?: string; // prefix for checkbox ids
  i18nContext?: string;
  bulkActions?: Array<BulkAction<T>>;

  /**
   * Expanding rows with custom subcomponent
   */
  renderExpandedRowSubComponent?: ExpandedRowRenderSubComponent<T>;
  isRowExpandable?: IsRowExpandable<T>;
  rowExpandableSubRows?: (item: T) => T[];

  /**
   * Pagination
   */
  pageControls?: PageControls;
  pageSize?: number;
  total?: number;
  onPageChange?: (page: number | undefined) => void;
  shouldForceResetPagination?: boolean;

  /**
   * Sorting
   */
  onSort?: SortHandler<T>;
}

export function AutoTable<T>({
  tableId = 'default',
  i18nContext,
  loading = false,
  items,
  total,
  keyProp,
  tableDecl,
  pageSize,
  noDataMessage,
  pageControls,
  onPageChange,
  renderExpandedRowSubComponent,
  rowExpandableSubRows,
  isRowExpandable,
  shouldForceResetPagination = true,
  bulkActions,
  onSort,
}: Props<T>): JSX.Element {
  const pagination = usePagination<T>({
    items,
    pageControls,
    onPageChange,
    pageSize,
    total,
    shouldForceResetPagination: shouldForceResetPagination,
  });

  const selection = useSelection<T>({ allItems: items, visibleItems: pagination.itemsSlice, bulkActions });
  const expanding = useExpanding<T>({ subRowsDepth: 0, isRowExpandable, renderExpandedRowSubComponent, rowExpandableSubRows });

  const filteredTableDecl = tableDecl.filter((col) => !col.skip);
  let colSpan = filteredTableDecl.length;

  if (selection.isEnabled) {
    colSpan += 1;
  }

  return (
    <TableWrapper>
      <Table>
        <AutoColGroup tableDecl={filteredTableDecl} />
        <AutoHeader
          tableId={tableId}
          tableDecl={filteredTableDecl}
          context={i18nContext}
          selection={selection}
          pagination={pagination}
          totalItemsCount={items.length}
          onSort={onSort}
        />
        <AutoBody
          tableId={tableId}
          tableDecl={filteredTableDecl}
          keyProp={keyProp}
          noDataMessage={noDataMessage}
          colSpan={colSpan}
          loading={loading}
          itemSlice={pagination.itemsSlice}
          pagination={pagination}
          expanding={expanding}
          selection={selection}
        />
        {pagination.isPaginationEnabled && (
          <AutoFooter
            colSpan={colSpan}
            page={pagination.page}
            totalPages={pagination.totalPages}
            onPageChange={pagination.setPage}
          />
        )}
      </Table>
    </TableWrapper>
  );
}
