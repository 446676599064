import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ButtonGroup } from '@/ui/ButtonGroup';
import {
  WrapStyled,
  ColumnStyled,
  StyledLabel,
  StyledCaption,
} from '@/features/course/admin/molecules/CourseSettingsModalForm/CourseSettingsModalForm';
import { Button } from '@/ui/Button';
import { color } from '@/components/Typography/v2';

const DeleteButtonStyled = styled(Button).attrs({ variant: 'secondary', size: 'large' })`
  border: 1px solid ${(p) => p.theme.colors.error.main};
  width: 100%;

  :hover {
    border: none;
    background-color: ${(p) => p.theme.colors.error.main};
  }

  :focus:not(:active) {
    border: none;
    outline: 1px solid ${(p) => p.theme.colors.error.main};
  }

  :active {
    border: none;
    background: ${(p) => p.theme.colors.neutral.onBackground};
    color: ${(p) => color(p.theme.colors.neutral.outline1)};
  }
`;

type CourseSettingsModalDeleteButtonProps = {
  onClick: VoidFunction;
};

export const CourseSettingsModalDeleteButton = ({ onClick }: CourseSettingsModalDeleteButtonProps): JSX.Element => {
  const { t } = useTranslation('catalog');

  return (
    <WrapStyled>
      <ColumnStyled>
        <ButtonGroup gap="small" direction="vertical">
          <StyledLabel>{t('Delete')}</StyledLabel>
          <StyledCaption>{t('Permanently remove your course')}</StyledCaption>
        </ButtonGroup>
      </ColumnStyled>
      <ColumnStyled>
        <DeleteButtonStyled type="button" onClick={onClick}>
          {t('Delete')}
        </DeleteButtonStyled>
      </ColumnStyled>
    </WrapStyled>
  );
};
