import { useEffect } from 'react';
import { UseFormGetValues, UseFormTrigger, UseFormWatch, Validate } from 'react-hook-form';

import { Course } from '../../../../administration/pages/Course/store/course/types';
import { TextFieldName } from '@/features/course/admin/constants/settingsModal';

export const useCourseFormTimeValidation = ({
  getValues,
  trigger,
  isSubmitted,
  watch,
}: {
  getValues: UseFormGetValues<Course>;
  trigger: UseFormTrigger<Course>;
  isSubmitted: boolean;
  watch: UseFormWatch<Course>;
}): Validate<string> => {
  /**
   * Validation background
   *
   * Should PASS when:
   *
   * 1. Hours > 0 and minutes = 0 or undefined
   * 2. Minutes > 0 and hours = 0 or undefined
   * 3. Both hours and minutes defined and > 0
   *
   * Why do we want that and don't just mark both fields as required?
   *
   * Because UX is better, when user can submit the form, when he just want to trach 2 minutes OR 2 hours,
   * whithout having to fill hours inputs or minutes input with 0 accordingly
   */
  const validateTimeTextField: Validate<string> = () => {
    const [hoursValue = '0', minutesValue = '0'] = getValues([TextFieldName.EffortHours, TextFieldName.EffortMinutes]);

    return Number(hoursValue) > 0 || Number(minutesValue) > 0;
  };

  /**
   * ReValidation background
   *
   * 1. We want to trigger validation of minutes & hours inputs,
   * when they are changed, but only when the user submitted the form at least 1 time
   * 2. We need it, because each of those inputs validation is depandant on the other's value as well as on it's own value
   */
  useEffect(() => {
    const revalidateTimeTextFields = async () => {
      await trigger(TextFieldName.EffortHours);
      await trigger(TextFieldName.EffortMinutes);
    };

    if (isSubmitted) {
      revalidateTimeTextFields();
    }
  }, [watch([TextFieldName.EffortHours, TextFieldName.EffortMinutes]), isSubmitted]);

  return validateTimeTextField;
};
