import { supportEmails, supportUrl as supportUrls } from '@/config';
import { FEATURE, useFeatureEnabled } from '@/feature-toggles';
import { useLanguage } from '@/hooks/useLanguage';
import i18n from '@/i18n/i18n';
import { Lng } from '@/i18n/resources';
import { useRootContext } from '@/store/RootContext';
import { isRealm } from '@/utils/realms';
import { Footer } from '@/component/Footer/Footer';

type FooterContainerProps = {
  isInPreviewModal?: boolean;
}

const FooterContainer = ({isInPreviewModal }: FooterContainerProps): JSX.Element => {
  const { isAuthenticated } = useRootContext();
  const [setLanguage, languageChangePending] = useLanguage();
  const props: React.ComponentProps<typeof Footer> = {
    appVersion: window.REACT_APP_VERSION,
    showLanguagePicker: useFeatureEnabled(FEATURE.UI_LANGUAGE_PICKER) && isAuthenticated,
    showEuLogo: isRealm('kompetens'),
    supportLink: isRealm('seb') ? supportUrls.seb : supportEmails.collegial,
    supportLinkType: isRealm('seb') ? 'url' : 'email',
    currentLanguage: i18n.language === Lng.en ? Lng.en : Lng.sv,
    setLanguage,
    languageChangePending,
    isInPreviewModal
  };

  return <Footer {...props} />;
};

export { FooterContainer as Footer };
