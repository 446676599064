import styled from 'styled-components';

export const ToolTipText = styled.span.attrs((props) => ({
  ...props,
  role: 'tooltip',
}))<{ id: string }>`
  background-color: var(--color-black);
  border-radius: var(--radius-outer);
  bottom: 1.5rem;
  color: var(--color-white);
  font-size: var(--font-size-6);
  left: 50%;
  margin-left: -60px;
  padding: 5px;
  position: absolute;
  text-align: center;
  visibility: hidden;
  z-index: 1;

  :after {
    border-color: var(--color-black) var(--color-transparent) var(--color-transparent) var(--color-transparent);
    border-style: solid;
    border-width: 5px;
    content: '';
    left: 50%;
    margin-left: -5px;
    position: absolute;
    top: 100%;
  }
`;

export const ToolTip = styled.div<{ hint?: boolean; 'aria-describedby': string }>`
  display: inline-block;
  font-size: 0;
  position: relative;

  :hover span {
    visibility: visible;
  }

  ${({ hint }) =>
    hint &&
    `
    border-bottom: 1px dashed black;
  `}
`;
